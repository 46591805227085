/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
[class^=ant-]::-ms-clear,
[class*= ant-]::-ms-clear,
[class^=ant-] input::-ms-clear,
[class*= ant-] input::-ms-clear,
[class^=ant-] input::-ms-reveal,
[class*= ant-] input::-ms-reveal {
  display: none;
}
[class^=ant-],
[class*= ant-],
[class^=ant-] *,
[class*= ant-] *,
[class^=ant-] *::before,
[class*= ant-] *::before,
[class^=ant-] *::after,
[class*= ant-] *::after {
  box-sizing: border-box;
}
/* stylelint-disable at-rule-no-unknown */
html,
body {
  width: 100%;
  height: 100%;
}
input::-ms-clear,
input::-ms-reveal {
  display: none;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
@-ms-viewport {
  width: device-width;
}
body {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-variant: tabular-nums;
  line-height: 1.5715;
  background-color: #fff;
  font-feature-settings: 'tnum';
}
[tabindex='-1']:focus {
  outline: none !important;
}
hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
}
p {
  margin-top: 0;
  margin-bottom: 1em;
}
abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  border-bottom: 0;
  cursor: help;
}
address {
  margin-bottom: 1em;
  font-style: normal;
  line-height: inherit;
}
input[type='text'],
input[type='password'],
input[type='number'],
textarea {
  -webkit-appearance: none;
}
ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1em;
}
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}
dt {
  font-weight: 500;
}
dd {
  margin-bottom: 0.5em;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1em;
}
dfn {
  font-style: italic;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  color: #00441F;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  color: #0d522a;
}
a:active {
  color: #001f0f;
}
a:active,
a:hover {
  text-decoration: none;
  outline: 0;
}
a:focus {
  text-decoration: none;
  outline: 0;
}
a[disabled] {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
  pointer-events: none;
}
pre,
code,
kbd,
samp {
  font-size: 1em;
  font-family: 'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace;
}
pre {
  margin-top: 0;
  margin-bottom: 1em;
  overflow: auto;
}
figure {
  margin: 0 0 1em;
}
img {
  vertical-align: middle;
  border-style: none;
}
svg:not(:root) {
  overflow: hidden;
}
a,
area,
button,
[role='button'],
input:not([type='range']),
label,
select,
summary,
textarea {
  touch-action: manipulation;
}
table {
  border-collapse: collapse;
}
caption {
  padding-top: 0.75em;
  padding-bottom: 0.3em;
  color: rgba(0, 0, 0, 0.45);
  text-align: left;
  caption-side: bottom;
}
th {
  text-align: inherit;
}
input,
button,
select,
optgroup,
textarea {
  margin: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type='radio'],
input[type='checkbox'] {
  box-sizing: border-box;
  padding: 0;
}
input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  min-width: 0;
  margin: 0;
  padding: 0;
  border: 0;
}
legend {
  display: block;
  width: 100%;
  max-width: 100%;
  margin-bottom: 0.5em;
  padding: 0;
  color: inherit;
  font-size: 1.5em;
  line-height: inherit;
  white-space: normal;
}
progress {
  vertical-align: baseline;
}
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}
[type='search'] {
  outline-offset: -2px;
  -webkit-appearance: none;
}
[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
mark {
  padding: 0.2em;
  background-color: #feffe6;
}
::selection {
  color: #fff;
  background: #00441F;
}
.clearfix::before {
  display: table;
  content: '';
}
.clearfix::after {
  display: table;
  clear: both;
  content: '';
}
.anticon {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.anticon > * {
  line-height: 1;
}
.anticon svg {
  display: inline-block;
}
.anticon::before {
  display: none;
}
.anticon .anticon-icon {
  display: block;
}
.anticon[tabindex] {
  cursor: pointer;
}
.anticon-spin::before {
  display: inline-block;
  animation: loadingCircle 1s infinite linear;
}
.anticon-spin {
  display: inline-block;
  animation: loadingCircle 1s infinite linear;
}
.fade-enter,
.fade-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.fade-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.fade-enter.fade-enter-active,
.fade-appear.fade-appear-active {
  animation-name: antFadeIn;
  animation-play-state: running;
}
.fade-leave.fade-leave-active {
  animation-name: antFadeOut;
  animation-play-state: running;
  pointer-events: none;
}
.fade-enter,
.fade-appear {
  opacity: 0;
  animation-timing-function: linear;
}
.fade-leave {
  animation-timing-function: linear;
}
@keyframes antFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes antFadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.move-up-enter,
.move-up-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.move-up-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.move-up-enter.move-up-enter-active,
.move-up-appear.move-up-appear-active {
  animation-name: antMoveUpIn;
  animation-play-state: running;
}
.move-up-leave.move-up-leave-active {
  animation-name: antMoveUpOut;
  animation-play-state: running;
  pointer-events: none;
}
.move-up-enter,
.move-up-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.move-up-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.move-down-enter,
.move-down-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.move-down-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.move-down-enter.move-down-enter-active,
.move-down-appear.move-down-appear-active {
  animation-name: antMoveDownIn;
  animation-play-state: running;
}
.move-down-leave.move-down-leave-active {
  animation-name: antMoveDownOut;
  animation-play-state: running;
  pointer-events: none;
}
.move-down-enter,
.move-down-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.move-down-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.move-left-enter,
.move-left-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.move-left-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.move-left-enter.move-left-enter-active,
.move-left-appear.move-left-appear-active {
  animation-name: antMoveLeftIn;
  animation-play-state: running;
}
.move-left-leave.move-left-leave-active {
  animation-name: antMoveLeftOut;
  animation-play-state: running;
  pointer-events: none;
}
.move-left-enter,
.move-left-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.move-left-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
.move-right-enter,
.move-right-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.move-right-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.move-right-enter.move-right-enter-active,
.move-right-appear.move-right-appear-active {
  animation-name: antMoveRightIn;
  animation-play-state: running;
}
.move-right-leave.move-right-leave-active {
  animation-name: antMoveRightOut;
  animation-play-state: running;
  pointer-events: none;
}
.move-right-enter,
.move-right-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.move-right-leave {
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}
@keyframes antMoveDownIn {
  0% {
    transform: translateY(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveDownOut {
  0% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveLeftIn {
  0% {
    transform: translateX(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveLeftOut {
  0% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveRightIn {
  0% {
    transform: translateX(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveRightOut {
  0% {
    transform: translateX(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes antMoveUpIn {
  0% {
    transform: translateY(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
}
@keyframes antMoveUpOut {
  0% {
    transform: translateY(0%);
    transform-origin: 0 0;
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    transform-origin: 0 0;
    opacity: 0;
  }
}
@keyframes loadingCircle {
  100% {
    transform: rotate(360deg);
  }
}
[ant-click-animating='true'],
[ant-click-animating-without-extra-node='true'] {
  position: relative;
}
html {
  --antd-wave-shadow-color: #00441F;
  --scroll-bar: 0;
}
[ant-click-animating-without-extra-node='true']::after,
.ant-click-animating-node {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  border-radius: inherit;
  box-shadow: 0 0 0 0 #00441F;
  box-shadow: 0 0 0 0 var(--antd-wave-shadow-color);
  opacity: 0.2;
  animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1), waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-fill-mode: forwards;
  content: '';
  pointer-events: none;
}
@keyframes waveEffect {
  100% {
    box-shadow: 0 0 0 #00441F;
    box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
  }
}
@keyframes fadeEffect {
  100% {
    opacity: 0;
  }
}
.slide-up-enter,
.slide-up-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.slide-up-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.slide-up-enter.slide-up-enter-active,
.slide-up-appear.slide-up-appear-active {
  animation-name: antSlideUpIn;
  animation-play-state: running;
}
.slide-up-leave.slide-up-leave-active {
  animation-name: antSlideUpOut;
  animation-play-state: running;
  pointer-events: none;
}
.slide-up-enter,
.slide-up-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.slide-up-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.slide-down-enter,
.slide-down-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.slide-down-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.slide-down-enter.slide-down-enter-active,
.slide-down-appear.slide-down-appear-active {
  animation-name: antSlideDownIn;
  animation-play-state: running;
}
.slide-down-leave.slide-down-leave-active {
  animation-name: antSlideDownOut;
  animation-play-state: running;
  pointer-events: none;
}
.slide-down-enter,
.slide-down-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.slide-down-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.slide-left-enter,
.slide-left-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.slide-left-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.slide-left-enter.slide-left-enter-active,
.slide-left-appear.slide-left-appear-active {
  animation-name: antSlideLeftIn;
  animation-play-state: running;
}
.slide-left-leave.slide-left-leave-active {
  animation-name: antSlideLeftOut;
  animation-play-state: running;
  pointer-events: none;
}
.slide-left-enter,
.slide-left-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.slide-left-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
.slide-right-enter,
.slide-right-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.slide-right-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.slide-right-enter.slide-right-enter-active,
.slide-right-appear.slide-right-appear-active {
  animation-name: antSlideRightIn;
  animation-play-state: running;
}
.slide-right-leave.slide-right-leave-active {
  animation-name: antSlideRightOut;
  animation-play-state: running;
  pointer-events: none;
}
.slide-right-enter,
.slide-right-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.slide-right-leave {
  animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}
@keyframes antSlideUpIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes antSlideUpOut {
  0% {
    transform: scaleY(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}
@keyframes antSlideDownIn {
  0% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
}
@keyframes antSlideDownOut {
  0% {
    transform: scaleY(1);
    transform-origin: 100% 100%;
    opacity: 1;
  }
  100% {
    transform: scaleY(0.8);
    transform-origin: 100% 100%;
    opacity: 0;
  }
}
@keyframes antSlideLeftIn {
  0% {
    transform: scaleX(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleX(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes antSlideLeftOut {
  0% {
    transform: scaleX(1);
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleX(0.8);
    transform-origin: 0% 0%;
    opacity: 0;
  }
}
@keyframes antSlideRightIn {
  0% {
    transform: scaleX(0.8);
    transform-origin: 100% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleX(1);
    transform-origin: 100% 0%;
    opacity: 1;
  }
}
@keyframes antSlideRightOut {
  0% {
    transform: scaleX(1);
    transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    transform: scaleX(0.8);
    transform-origin: 100% 0%;
    opacity: 0;
  }
}
.zoom-enter,
.zoom-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-enter.zoom-enter-active,
.zoom-appear.zoom-appear-active {
  animation-name: antZoomIn;
  animation-play-state: running;
}
.zoom-leave.zoom-leave-active {
  animation-name: antZoomOut;
  animation-play-state: running;
  pointer-events: none;
}
.zoom-enter,
.zoom-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-enter-prepare,
.zoom-appear-prepare {
  transform: none;
}
.zoom-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-big-enter,
.zoom-big-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-big-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-big-enter.zoom-big-enter-active,
.zoom-big-appear.zoom-big-appear-active {
  animation-name: antZoomBigIn;
  animation-play-state: running;
}
.zoom-big-leave.zoom-big-leave-active {
  animation-name: antZoomBigOut;
  animation-play-state: running;
  pointer-events: none;
}
.zoom-big-enter,
.zoom-big-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-big-enter-prepare,
.zoom-big-appear-prepare {
  transform: none;
}
.zoom-big-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-big-fast-enter,
.zoom-big-fast-appear {
  animation-duration: 0.1s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-big-fast-leave {
  animation-duration: 0.1s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-big-fast-enter.zoom-big-fast-enter-active,
.zoom-big-fast-appear.zoom-big-fast-appear-active {
  animation-name: antZoomBigIn;
  animation-play-state: running;
}
.zoom-big-fast-leave.zoom-big-fast-leave-active {
  animation-name: antZoomBigOut;
  animation-play-state: running;
  pointer-events: none;
}
.zoom-big-fast-enter,
.zoom-big-fast-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-big-fast-enter-prepare,
.zoom-big-fast-appear-prepare {
  transform: none;
}
.zoom-big-fast-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-up-enter,
.zoom-up-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-up-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-up-enter.zoom-up-enter-active,
.zoom-up-appear.zoom-up-appear-active {
  animation-name: antZoomUpIn;
  animation-play-state: running;
}
.zoom-up-leave.zoom-up-leave-active {
  animation-name: antZoomUpOut;
  animation-play-state: running;
  pointer-events: none;
}
.zoom-up-enter,
.zoom-up-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-up-enter-prepare,
.zoom-up-appear-prepare {
  transform: none;
}
.zoom-up-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-down-enter,
.zoom-down-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-down-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-down-enter.zoom-down-enter-active,
.zoom-down-appear.zoom-down-appear-active {
  animation-name: antZoomDownIn;
  animation-play-state: running;
}
.zoom-down-leave.zoom-down-leave-active {
  animation-name: antZoomDownOut;
  animation-play-state: running;
  pointer-events: none;
}
.zoom-down-enter,
.zoom-down-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-down-enter-prepare,
.zoom-down-appear-prepare {
  transform: none;
}
.zoom-down-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-left-enter,
.zoom-left-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-left-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-left-enter.zoom-left-enter-active,
.zoom-left-appear.zoom-left-appear-active {
  animation-name: antZoomLeftIn;
  animation-play-state: running;
}
.zoom-left-leave.zoom-left-leave-active {
  animation-name: antZoomLeftOut;
  animation-play-state: running;
  pointer-events: none;
}
.zoom-left-enter,
.zoom-left-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-left-enter-prepare,
.zoom-left-appear-prepare {
  transform: none;
}
.zoom-left-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.zoom-right-enter,
.zoom-right-appear {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-right-leave {
  animation-duration: 0.2s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.zoom-right-enter.zoom-right-enter-active,
.zoom-right-appear.zoom-right-appear-active {
  animation-name: antZoomRightIn;
  animation-play-state: running;
}
.zoom-right-leave.zoom-right-leave-active {
  animation-name: antZoomRightOut;
  animation-play-state: running;
  pointer-events: none;
}
.zoom-right-enter,
.zoom-right-appear {
  transform: scale(0);
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
}
.zoom-right-enter-prepare,
.zoom-right-appear-prepare {
  transform: none;
}
.zoom-right-leave {
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
@keyframes antZoomIn {
  0% {
    transform: scale(0.2);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes antZoomOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.2);
    opacity: 0;
  }
}
@keyframes antZoomBigIn {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes antZoomBigOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
    opacity: 0;
  }
}
@keyframes antZoomUpIn {
  0% {
    transform: scale(0.8);
    transform-origin: 50% 0%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 50% 0%;
  }
}
@keyframes antZoomUpOut {
  0% {
    transform: scale(1);
    transform-origin: 50% 0%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 50% 0%;
    opacity: 0;
  }
}
@keyframes antZoomLeftIn {
  0% {
    transform: scale(0.8);
    transform-origin: 0% 50%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 0% 50%;
  }
}
@keyframes antZoomLeftOut {
  0% {
    transform: scale(1);
    transform-origin: 0% 50%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 0% 50%;
    opacity: 0;
  }
}
@keyframes antZoomRightIn {
  0% {
    transform: scale(0.8);
    transform-origin: 100% 50%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 100% 50%;
  }
}
@keyframes antZoomRightOut {
  0% {
    transform: scale(1);
    transform-origin: 100% 50%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 100% 50%;
    opacity: 0;
  }
}
@keyframes antZoomDownIn {
  0% {
    transform: scale(0.8);
    transform-origin: 50% 100%;
    opacity: 0;
  }
  100% {
    transform: scale(1);
    transform-origin: 50% 100%;
  }
}
@keyframes antZoomDownOut {
  0% {
    transform: scale(1);
    transform-origin: 50% 100%;
  }
  100% {
    transform: scale(0.8);
    transform-origin: 50% 100%;
    opacity: 0;
  }
}
.ant-motion-collapse-legacy {
  overflow: hidden;
}
.ant-motion-collapse-legacy-active {
  transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
.ant-motion-collapse {
  overflow: hidden;
  transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}
.ant-affix {
  position: fixed;
  z-index: 10;
}
.ant-alert {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px 15px;
  word-wrap: break-word;
  border-radius: 20px;
}
.ant-alert-content {
  flex: 1;
  min-width: 0;
}
.ant-alert-icon {
  margin-right: 8px;
}
.ant-alert-description {
  display: none;
  font-size: 14px;
  line-height: 22px;
}
.ant-alert-success {
  background-color: #fafff0;
  border: 1px solid #d0e6b3;
}
.ant-alert-success .ant-alert-icon {
  color: #79be39;
}
.ant-alert-info {
  background-color: #e6f4ff;
  border: 1px solid #91caff;
}
.ant-alert-info .ant-alert-icon {
  color: #1677ff;
}
.ant-alert-warning {
  background-color: #fffde6;
  border: 1px solid #ffec82;
}
.ant-alert-warning .ant-alert-icon {
  color: #FFC107;
}
.ant-alert-error {
  background-color: #fff2f0;
  border: 1px solid #ffd8d4;
}
.ant-alert-error .ant-alert-icon {
  color: #DD4E4E;
}
.ant-alert-error .ant-alert-description > pre {
  margin: 0;
  padding: 0;
}
.ant-alert-action {
  margin-left: 8px;
}
.ant-alert-close-icon {
  margin-left: 8px;
  padding: 0;
  overflow: hidden;
  font-size: 12px;
  line-height: 12px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
.ant-alert-close-icon .anticon-close {
  color: rgba(0, 0, 0, 0.45);
  transition: color 0.3s;
}
.ant-alert-close-icon .anticon-close:hover {
  color: rgba(0, 0, 0, 0.75);
}
.ant-alert-close-text {
  color: rgba(0, 0, 0, 0.45);
  transition: color 0.3s;
}
.ant-alert-close-text:hover {
  color: rgba(0, 0, 0, 0.75);
}
.ant-alert-with-description {
  align-items: flex-start;
  padding: 15px 15px 15px 24px;
}
.ant-alert-with-description.ant-alert-no-icon {
  padding: 15px 15px;
}
.ant-alert-with-description .ant-alert-icon {
  margin-right: 15px;
  font-size: 24px;
}
.ant-alert-with-description .ant-alert-message {
  display: block;
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
}
.ant-alert-message {
  color: rgba(0, 0, 0, 0.85);
}
.ant-alert-with-description .ant-alert-description {
  display: block;
}
.ant-alert.ant-alert-motion-leave {
  overflow: hidden;
  opacity: 1;
  transition: max-height 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), padding-top 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), padding-bottom 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), margin-bottom 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-alert.ant-alert-motion-leave-active {
  max-height: 0;
  margin-bottom: 0 !important;
  padding-top: 0;
  padding-bottom: 0;
  opacity: 0;
}
.ant-alert-banner {
  margin-bottom: 0;
  border: 0;
  border-radius: 0;
}
.ant-alert.ant-alert-rtl {
  direction: rtl;
}
.ant-alert-rtl.ant-alert.ant-alert-no-icon {
  padding: 8px 15px;
}
.ant-alert-rtl .ant-alert-icon {
  margin-right: auto;
  margin-left: 8px;
}
.ant-alert-rtl .ant-alert-action {
  margin-right: 8px;
  margin-left: auto;
}
.ant-alert-rtl .ant-alert-close-icon {
  margin-right: 8px;
  margin-left: auto;
}
.ant-alert-rtl.ant-alert-with-description .ant-alert-icon {
  margin-right: auto;
  margin-left: 15px;
}
.ant-anchor {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  padding-left: 2px;
}
.ant-anchor-wrapper {
  margin-left: -4px;
  padding-left: 4px;
  overflow: auto;
  background-color: #fff;
}
.ant-anchor-ink {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}
.ant-anchor-ink::before {
  position: relative;
  display: block;
  width: 2px;
  height: 100%;
  margin: 0 auto;
  background-color: #f0f0f0;
  content: ' ';
}
.ant-anchor-ink-ball {
  position: absolute;
  left: 50%;
  display: none;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: 2px solid #00441F;
  border-radius: 8px;
  transform: translateX(-50%);
  transition: top 0.3s ease-in-out;
}
.ant-anchor-ink-ball.visible {
  display: inline-block;
}
.ant-anchor.fixed .ant-anchor-ink .ant-anchor-ink-ball {
  display: none;
}
.ant-anchor-link {
  padding: 7px 0 7px 16px;
  line-height: 1.143;
}
.ant-anchor-link-title {
  position: relative;
  display: block;
  margin-bottom: 6px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.3s;
}
.ant-anchor-link-title:only-child {
  margin-bottom: 0;
}
.ant-anchor-link-active > .ant-anchor-link-title {
  color: #00441F;
}
.ant-anchor-link .ant-anchor-link {
  padding-top: 5px;
  padding-bottom: 5px;
}
.ant-anchor-rtl {
  direction: rtl;
}
.ant-anchor-rtl.ant-anchor-wrapper {
  margin-right: -4px;
  margin-left: 0;
  padding-right: 4px;
  padding-left: 0;
}
.ant-anchor-rtl .ant-anchor-ink {
  right: 0;
  left: auto;
}
.ant-anchor-rtl .ant-anchor-ink-ball {
  right: 50%;
  left: 0;
  transform: translateX(50%);
}
.ant-anchor-rtl .ant-anchor-link {
  padding: 7px 16px 7px 0;
}
.ant-select-auto-complete {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
}
.ant-select-auto-complete .ant-select-clear {
  right: 13px;
}
.ant-avatar {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  overflow: hidden;
  color: #fff;
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  background: #ccc;
  width: 32px;
  height: 32px;
  line-height: 32px;
  border-radius: 50%;
}
.ant-avatar-image {
  background: transparent;
}
.ant-avatar .ant-image-img {
  display: block;
}
.ant-avatar-string {
  position: absolute;
  left: 50%;
  transform-origin: 0 center;
}
.ant-avatar.ant-avatar-icon {
  font-size: 18px;
}
.ant-avatar.ant-avatar-icon > .anticon {
  margin: 0;
}
.ant-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
}
.ant-avatar-lg-string {
  position: absolute;
  left: 50%;
  transform-origin: 0 center;
}
.ant-avatar-lg.ant-avatar-icon {
  font-size: 24px;
}
.ant-avatar-lg.ant-avatar-icon > .anticon {
  margin: 0;
}
.ant-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 50%;
}
.ant-avatar-sm-string {
  position: absolute;
  left: 50%;
  transform-origin: 0 center;
}
.ant-avatar-sm.ant-avatar-icon {
  font-size: 14px;
}
.ant-avatar-sm.ant-avatar-icon > .anticon {
  margin: 0;
}
.ant-avatar-square {
  border-radius: 20px;
}
.ant-avatar > img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ant-avatar-group {
  display: inline-flex;
}
.ant-avatar-group .ant-avatar {
  border: 1px solid #fff;
}
.ant-avatar-group .ant-avatar:not(:first-child) {
  margin-left: -8px;
}
.ant-avatar-group-popover .ant-avatar + .ant-avatar {
  margin-left: 3px;
}
.ant-avatar-group-rtl .ant-avatar:not(:first-child) {
  margin-right: -8px;
  margin-left: 0;
}
.ant-avatar-group-popover.ant-popover-rtl .ant-avatar + .ant-avatar {
  margin-right: 3px;
  margin-left: 0;
}
.ant-back-top {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: fixed;
  right: 100px;
  bottom: 50px;
  z-index: 10;
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.ant-back-top:empty {
  display: none;
}
.ant-back-top-rtl {
  right: auto;
  left: 100px;
  direction: rtl;
}
.ant-back-top-content {
  width: 40px;
  height: 40px;
  overflow: hidden;
  color: #fff;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 20px;
  transition: all 0.3s;
}
.ant-back-top-content:hover {
  background-color: rgba(0, 0, 0, 0.85);
  transition: all 0.3s;
}
.ant-back-top-icon {
  font-size: 24px;
  line-height: 40px;
}
@media screen and (max-width: 768px) {
  .ant-back-top {
    right: 60px;
  }
}
@media screen and (max-width: 480px) {
  .ant-back-top {
    right: 20px;
  }
}
.ant-badge {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  line-height: 1;
}
.ant-badge-count {
  z-index: auto;
  min-width: 20px;
  height: 20px;
  padding: 0 6px;
  color: #fff;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  text-align: center;
  background: #d70101;
  border-radius: 10px;
  box-shadow: 0 0 0 1px #fff;
}
.ant-badge-count a,
.ant-badge-count a:hover {
  color: #fff;
}
.ant-badge-count-sm {
  min-width: 14px;
  height: 14px;
  padding: 0;
  font-size: 12px;
  line-height: 14px;
  border-radius: 7px;
}
.ant-badge-multiple-words {
  padding: 0 8px;
}
.ant-badge-dot {
  z-index: auto;
  width: 6px;
  min-width: 6px;
  height: 6px;
  background: #d70101;
  border-radius: 100%;
  box-shadow: 0 0 0 1px #fff;
}
.ant-badge-count,
.ant-badge-dot,
.ant-badge .ant-scroll-number-custom-component {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  transform-origin: 100% 0%;
}
.ant-badge-status {
  line-height: inherit;
  vertical-align: baseline;
}
.ant-badge-status-dot {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 6px;
  height: 6px;
  vertical-align: middle;
  border-radius: 50%;
}
.ant-badge-status-success {
  background-color: #79be39;
}
.ant-badge-status-processing {
  position: relative;
  background-color: #1890ff;
}
.ant-badge-status-processing::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #1890ff;
  border-radius: 50%;
  animation: antStatusProcessing 1.2s infinite ease-in-out;
  content: '';
}
.ant-badge-status-default {
  background-color: #f5f5f5;
}
.ant-badge-status-error {
  background-color: #DD4E4E;
}
.ant-badge-status-warning {
  background-color: #FFC107;
}
.ant-badge-status-pink {
  background: #eb2f96;
}
.ant-badge-status-magenta {
  background: #eb2f96;
}
.ant-badge-status-red {
  background: #f5222d;
}
.ant-badge-status-volcano {
  background: #fa541c;
}
.ant-badge-status-orange {
  background: #fa8c16;
}
.ant-badge-status-yellow {
  background: #fadb14;
}
.ant-badge-status-gold {
  background: #faad14;
}
.ant-badge-status-cyan {
  background: #13c2c2;
}
.ant-badge-status-lime {
  background: #a0d911;
}
.ant-badge-status-green {
  background: #52c41a;
}
.ant-badge-status-blue {
  background: #1890ff;
}
.ant-badge-status-geekblue {
  background: #2f54eb;
}
.ant-badge-status-purple {
  background: #722ed1;
}
.ant-badge-status-text {
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
.ant-badge-zoom-appear,
.ant-badge-zoom-enter {
  animation: antZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  animation-fill-mode: both;
}
.ant-badge-zoom-leave {
  animation: antZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
  animation-fill-mode: both;
}
.ant-badge-not-a-wrapper .ant-badge-zoom-appear,
.ant-badge-not-a-wrapper .ant-badge-zoom-enter {
  animation: antNoWrapperZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
}
.ant-badge-not-a-wrapper .ant-badge-zoom-leave {
  animation: antNoWrapperZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
}
.ant-badge-not-a-wrapper:not(.ant-badge-status) {
  vertical-align: middle;
}
.ant-badge-not-a-wrapper .ant-scroll-number {
  position: relative;
  top: auto;
  display: block;
  transform-origin: 50% 50%;
}
.ant-badge-not-a-wrapper .ant-badge-count {
  transform: none;
}
@keyframes antStatusProcessing {
  0% {
    transform: scale(0.8);
    opacity: 0.5;
  }
  100% {
    transform: scale(2.4);
    opacity: 0;
  }
}
.ant-scroll-number {
  overflow: hidden;
}
.ant-scroll-number-only {
  display: inline-block;
  height: 20px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-scroll-number-only > p.ant-scroll-number-only-unit {
  height: 20px;
  margin: 0;
}
.ant-scroll-number-symbol {
  vertical-align: top;
}
@keyframes antZoomBadgeIn {
  0% {
    transform: scale(0) translate(50%, -50%);
    opacity: 0;
  }
  100% {
    transform: scale(1) translate(50%, -50%);
  }
}
@keyframes antZoomBadgeOut {
  0% {
    transform: scale(1) translate(50%, -50%);
  }
  100% {
    transform: scale(0) translate(50%, -50%);
    opacity: 0;
  }
}
@keyframes antNoWrapperZoomBadgeIn {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
  }
}
@keyframes antNoWrapperZoomBadgeOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}
.ant-ribbon-wrapper {
  position: relative;
}
.ant-ribbon {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: 8px;
  height: 22px;
  padding: 0 8px;
  color: #fff;
  line-height: 22px;
  white-space: nowrap;
  background-color: #00441F;
  border-radius: 20px;
}
.ant-ribbon-text {
  color: #fff;
}
.ant-ribbon-corner {
  position: absolute;
  top: 100%;
  width: 8px;
  height: 8px;
  color: currentColor;
  border: 4px solid;
  transform: scaleY(0.75);
  transform-origin: top;
}
.ant-ribbon-corner::after {
  position: absolute;
  top: -4px;
  left: -4px;
  width: inherit;
  height: inherit;
  color: rgba(0, 0, 0, 0.25);
  border: inherit;
  content: '';
}
.ant-ribbon-color-pink {
  color: #eb2f96;
  background: #eb2f96;
}
.ant-ribbon-color-magenta {
  color: #eb2f96;
  background: #eb2f96;
}
.ant-ribbon-color-red {
  color: #f5222d;
  background: #f5222d;
}
.ant-ribbon-color-volcano {
  color: #fa541c;
  background: #fa541c;
}
.ant-ribbon-color-orange {
  color: #fa8c16;
  background: #fa8c16;
}
.ant-ribbon-color-yellow {
  color: #fadb14;
  background: #fadb14;
}
.ant-ribbon-color-gold {
  color: #faad14;
  background: #faad14;
}
.ant-ribbon-color-cyan {
  color: #13c2c2;
  background: #13c2c2;
}
.ant-ribbon-color-lime {
  color: #a0d911;
  background: #a0d911;
}
.ant-ribbon-color-green {
  color: #52c41a;
  background: #52c41a;
}
.ant-ribbon-color-blue {
  color: #1890ff;
  background: #1890ff;
}
.ant-ribbon-color-geekblue {
  color: #2f54eb;
  background: #2f54eb;
}
.ant-ribbon-color-purple {
  color: #722ed1;
  background: #722ed1;
}
.ant-ribbon.ant-ribbon-placement-end {
  right: -8px;
  border-bottom-right-radius: 0;
}
.ant-ribbon.ant-ribbon-placement-end .ant-ribbon-corner {
  right: 0;
  border-color: currentColor transparent transparent currentColor;
}
.ant-ribbon.ant-ribbon-placement-start {
  left: -8px;
  border-bottom-left-radius: 0;
}
.ant-ribbon.ant-ribbon-placement-start .ant-ribbon-corner {
  left: 0;
  border-color: currentColor currentColor transparent transparent;
}
.ant-badge-rtl {
  direction: rtl;
}
.ant-badge-rtl .ant-badge-count,
.ant-badge-rtl .ant-badge-dot,
.ant-badge-rtl .ant-badge .ant-scroll-number-custom-component {
  right: auto;
  left: 0;
  direction: ltr;
  transform: translate(-50%, -50%);
  transform-origin: 0% 0%;
}
.ant-badge-rtl.ant-badge .ant-scroll-number-custom-component {
  right: auto;
  left: 0;
  transform: translate(-50%, -50%);
  transform-origin: 0% 0%;
}
.ant-badge-rtl .ant-badge-status-text {
  margin-right: 8px;
  margin-left: 0;
}
.ant-badge-rtl .ant-badge-zoom-appear,
.ant-badge-rtl .ant-badge-zoom-enter {
  animation-name: antZoomBadgeInRtl;
}
.ant-badge-rtl .ant-badge-zoom-leave {
  animation-name: antZoomBadgeOutRtl;
}
.ant-badge-not-a-wrapper .ant-badge-count {
  transform: none;
}
.ant-ribbon-rtl {
  direction: rtl;
}
.ant-ribbon-rtl.ant-ribbon-placement-end {
  right: unset;
  left: -8px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 0;
}
.ant-ribbon-rtl.ant-ribbon-placement-end .ant-ribbon-corner {
  right: unset;
  left: 0;
  border-color: currentColor currentColor transparent transparent;
}
.ant-ribbon-rtl.ant-ribbon-placement-end .ant-ribbon-corner::after {
  border-color: currentColor currentColor transparent transparent;
}
.ant-ribbon-rtl.ant-ribbon-placement-start {
  right: -8px;
  left: unset;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 20px;
}
.ant-ribbon-rtl.ant-ribbon-placement-start .ant-ribbon-corner {
  right: 0;
  left: unset;
  border-color: currentColor transparent transparent currentColor;
}
.ant-ribbon-rtl.ant-ribbon-placement-start .ant-ribbon-corner::after {
  border-color: currentColor transparent transparent currentColor;
}
@keyframes antZoomBadgeInRtl {
  0% {
    transform: scale(0) translate(-50%, -50%);
    opacity: 0;
  }
  100% {
    transform: scale(1) translate(-50%, -50%);
  }
}
@keyframes antZoomBadgeOutRtl {
  0% {
    transform: scale(1) translate(-50%, -50%);
  }
  100% {
    transform: scale(0) translate(-50%, -50%);
    opacity: 0;
  }
}
.ant-breadcrumb {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-breadcrumb .anticon {
  font-size: 14px;
}
.ant-breadcrumb a {
  color: rgba(0, 0, 0, 0.45);
  transition: color 0.3s;
}
.ant-breadcrumb a:hover {
  color: #0d522a;
}
.ant-breadcrumb > span:last-child {
  color: rgba(0, 0, 0, 0.85);
}
.ant-breadcrumb > span:last-child a {
  color: rgba(0, 0, 0, 0.85);
}
.ant-breadcrumb > span:last-child .ant-breadcrumb-separator {
  display: none;
}
.ant-breadcrumb-separator {
  margin: 0 8px;
  color: rgba(0, 0, 0, 0.45);
}
.ant-breadcrumb-link > .anticon + span,
.ant-breadcrumb-link > .anticon + a {
  margin-left: 4px;
}
.ant-breadcrumb-overlay-link > .anticon {
  margin-left: 4px;
}
.ant-breadcrumb-rtl {
  direction: rtl;
}
.ant-breadcrumb-rtl::before {
  display: table;
  content: '';
}
.ant-breadcrumb-rtl::after {
  display: table;
  clear: both;
  content: '';
}
.ant-breadcrumb-rtl::before {
  display: table;
  content: '';
}
.ant-breadcrumb-rtl::after {
  display: table;
  clear: both;
  content: '';
}
.ant-breadcrumb-rtl > span {
  float: right;
}
.ant-breadcrumb-rtl .ant-breadcrumb-link > .anticon + span,
.ant-breadcrumb-rtl .ant-breadcrumb-link > .anticon + a {
  margin-right: 4px;
  margin-left: 0;
}
.ant-breadcrumb-rtl .ant-breadcrumb-overlay-link > .anticon {
  margin-right: 4px;
  margin-left: 0;
}
.ant-btn {
  line-height: 1.5715;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  background-image: none;
  border: 1px solid transparent;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
  touch-action: manipulation;
  height: 32px;
  padding: 4px 15px;
  font-size: 14px;
  border-radius: 20px;
  color: #414141;
  background: #00441F;
  border-color: #d9d9d9;
}
.ant-btn > .anticon {
  line-height: 1;
}
.ant-btn,
.ant-btn:active,
.ant-btn:focus {
  outline: 0;
}
.ant-btn:not([disabled]):hover {
  text-decoration: none;
}
.ant-btn:not([disabled]):active {
  outline: 0;
  box-shadow: none;
}
.ant-btn[disabled] {
  cursor: not-allowed;
}
.ant-btn[disabled] > * {
  pointer-events: none;
}
.ant-btn-lg {
  height: 40px;
  padding: 6.4px 15px;
  font-size: 16px;
  border-radius: 20px;
}
.ant-btn-sm {
  height: 24px;
  padding: 0px 7px;
  font-size: 14px;
  border-radius: 20px;
}
.ant-btn > a:only-child {
  color: currentColor;
}
.ant-btn > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn:hover,
.ant-btn:focus {
  color: #0d522a;
  background: #00441F;
  border-color: #0d522a;
}
.ant-btn:hover > a:only-child,
.ant-btn:focus > a:only-child {
  color: currentColor;
}
.ant-btn:hover > a:only-child::after,
.ant-btn:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn:active {
  color: #001f0f;
  background: #00441F;
  border-color: #001f0f;
}
.ant-btn:active > a:only-child {
  color: currentColor;
}
.ant-btn:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn[disabled],
.ant-btn[disabled]:hover,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn[disabled] > a:only-child,
.ant-btn[disabled]:hover > a:only-child,
.ant-btn[disabled]:focus > a:only-child,
.ant-btn[disabled]:active > a:only-child {
  color: currentColor;
}
.ant-btn[disabled] > a:only-child::after,
.ant-btn[disabled]:hover > a:only-child::after,
.ant-btn[disabled]:focus > a:only-child::after,
.ant-btn[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn:hover,
.ant-btn:focus,
.ant-btn:active {
  text-decoration: none;
  background: #00441F;
}
.ant-btn > span {
  display: inline-block;
}
.ant-btn-primary {
  color: #fff;
  background: #00441F;
  border-color: #00441F;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-primary > a:only-child {
  color: currentColor;
}
.ant-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  background: #0d522a;
  border-color: #0d522a;
}
.ant-btn-primary:hover > a:only-child,
.ant-btn-primary:focus > a:only-child {
  color: currentColor;
}
.ant-btn-primary:hover > a:only-child::after,
.ant-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-primary:active {
  color: #fff;
  background: #001f0f;
  border-color: #001f0f;
}
.ant-btn-primary:active > a:only-child {
  color: currentColor;
}
.ant-btn-primary:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-primary[disabled] > a:only-child,
.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-primary[disabled]:active > a:only-child {
  color: currentColor;
}
.ant-btn-primary[disabled] > a:only-child::after,
.ant-btn-primary[disabled]:hover > a:only-child::after,
.ant-btn-primary[disabled]:focus > a:only-child::after,
.ant-btn-primary[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child) {
  border-right-color: #0d522a;
  border-left-color: #0d522a;
}
.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child):disabled {
  border-color: #d9d9d9;
}
.ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
  border-right-color: #0d522a;
}
.ant-btn-group .ant-btn-primary:first-child:not(:last-child)[disabled] {
  border-right-color: #d9d9d9;
}
.ant-btn-group .ant-btn-primary:last-child:not(:first-child),
.ant-btn-group .ant-btn-primary + .ant-btn-primary {
  border-left-color: #0d522a;
}
.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
.ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
  border-left-color: #d9d9d9;
}
.ant-btn-ghost {
  color: rgba(0, 0, 0, 0.85);
  background: transparent;
  border-color: #d9d9d9;
}
.ant-btn-ghost > a:only-child {
  color: currentColor;
}
.ant-btn-ghost > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-ghost:hover,
.ant-btn-ghost:focus {
  color: #0d522a;
  background: transparent;
  border-color: #0d522a;
}
.ant-btn-ghost:hover > a:only-child,
.ant-btn-ghost:focus > a:only-child {
  color: currentColor;
}
.ant-btn-ghost:hover > a:only-child::after,
.ant-btn-ghost:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-ghost:active {
  color: #001f0f;
  background: transparent;
  border-color: #001f0f;
}
.ant-btn-ghost:active > a:only-child {
  color: currentColor;
}
.ant-btn-ghost:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-ghost[disabled],
.ant-btn-ghost[disabled]:hover,
.ant-btn-ghost[disabled]:focus,
.ant-btn-ghost[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-ghost[disabled] > a:only-child,
.ant-btn-ghost[disabled]:hover > a:only-child,
.ant-btn-ghost[disabled]:focus > a:only-child,
.ant-btn-ghost[disabled]:active > a:only-child {
  color: currentColor;
}
.ant-btn-ghost[disabled] > a:only-child::after,
.ant-btn-ghost[disabled]:hover > a:only-child::after,
.ant-btn-ghost[disabled]:focus > a:only-child::after,
.ant-btn-ghost[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dashed {
  color: #414141;
  background: #00441F;
  border-color: #d9d9d9;
  border-style: dashed;
}
.ant-btn-dashed > a:only-child {
  color: currentColor;
}
.ant-btn-dashed > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dashed:hover,
.ant-btn-dashed:focus {
  color: #0d522a;
  background: #00441F;
  border-color: #0d522a;
}
.ant-btn-dashed:hover > a:only-child,
.ant-btn-dashed:focus > a:only-child {
  color: currentColor;
}
.ant-btn-dashed:hover > a:only-child::after,
.ant-btn-dashed:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dashed:active {
  color: #001f0f;
  background: #00441F;
  border-color: #001f0f;
}
.ant-btn-dashed:active > a:only-child {
  color: currentColor;
}
.ant-btn-dashed:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dashed[disabled],
.ant-btn-dashed[disabled]:hover,
.ant-btn-dashed[disabled]:focus,
.ant-btn-dashed[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dashed[disabled] > a:only-child,
.ant-btn-dashed[disabled]:hover > a:only-child,
.ant-btn-dashed[disabled]:focus > a:only-child,
.ant-btn-dashed[disabled]:active > a:only-child {
  color: currentColor;
}
.ant-btn-dashed[disabled] > a:only-child::after,
.ant-btn-dashed[disabled]:hover > a:only-child::after,
.ant-btn-dashed[disabled]:focus > a:only-child::after,
.ant-btn-dashed[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger {
  color: #fff;
  background: #DD4E4E;
  border-color: #DD4E4E;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-danger > a:only-child {
  color: currentColor;
}
.ant-btn-danger > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger:hover,
.ant-btn-danger:focus {
  color: #fff;
  background: #eb7b78;
  border-color: #eb7b78;
}
.ant-btn-danger:hover > a:only-child,
.ant-btn-danger:focus > a:only-child {
  color: currentColor;
}
.ant-btn-danger:hover > a:only-child::after,
.ant-btn-danger:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger:active {
  color: #fff;
  background: #b8373b;
  border-color: #b8373b;
}
.ant-btn-danger:active > a:only-child {
  color: currentColor;
}
.ant-btn-danger:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-danger[disabled],
.ant-btn-danger[disabled]:hover,
.ant-btn-danger[disabled]:focus,
.ant-btn-danger[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-danger[disabled] > a:only-child,
.ant-btn-danger[disabled]:hover > a:only-child,
.ant-btn-danger[disabled]:focus > a:only-child,
.ant-btn-danger[disabled]:active > a:only-child {
  color: currentColor;
}
.ant-btn-danger[disabled] > a:only-child::after,
.ant-btn-danger[disabled]:hover > a:only-child::after,
.ant-btn-danger[disabled]:focus > a:only-child::after,
.ant-btn-danger[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link {
  color: #00441F;
  background: transparent;
  border-color: transparent;
  box-shadow: none;
}
.ant-btn-link > a:only-child {
  color: currentColor;
}
.ant-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link:hover,
.ant-btn-link:focus {
  color: #0d522a;
  background: transparent;
  border-color: #0d522a;
}
.ant-btn-link:hover > a:only-child,
.ant-btn-link:focus > a:only-child {
  color: currentColor;
}
.ant-btn-link:hover > a:only-child::after,
.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link:active {
  color: #001f0f;
  background: transparent;
  border-color: #001f0f;
}
.ant-btn-link:active > a:only-child {
  color: currentColor;
}
.ant-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link[disabled],
.ant-btn-link[disabled]:hover,
.ant-btn-link[disabled]:focus,
.ant-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-link[disabled] > a:only-child,
.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-link[disabled]:active > a:only-child {
  color: currentColor;
}
.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-link:hover {
  background: transparent;
}
.ant-btn-link:hover,
.ant-btn-link:focus,
.ant-btn-link:active {
  border-color: transparent;
}
.ant-btn-link[disabled],
.ant-btn-link[disabled]:hover,
.ant-btn-link[disabled]:focus,
.ant-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  border-color: transparent;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-link[disabled] > a:only-child,
.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-link[disabled]:active > a:only-child {
  color: currentColor;
}
.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-text {
  color: rgba(0, 0, 0, 0.85);
  background: transparent;
  border-color: transparent;
  box-shadow: none;
}
.ant-btn-text > a:only-child {
  color: currentColor;
}
.ant-btn-text > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-text:hover,
.ant-btn-text:focus {
  color: #0d522a;
  background: transparent;
  border-color: #0d522a;
}
.ant-btn-text:hover > a:only-child,
.ant-btn-text:focus > a:only-child {
  color: currentColor;
}
.ant-btn-text:hover > a:only-child::after,
.ant-btn-text:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-text:active {
  color: #001f0f;
  background: transparent;
  border-color: #001f0f;
}
.ant-btn-text:active > a:only-child {
  color: currentColor;
}
.ant-btn-text:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-text[disabled],
.ant-btn-text[disabled]:hover,
.ant-btn-text[disabled]:focus,
.ant-btn-text[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-text[disabled] > a:only-child,
.ant-btn-text[disabled]:hover > a:only-child,
.ant-btn-text[disabled]:focus > a:only-child,
.ant-btn-text[disabled]:active > a:only-child {
  color: currentColor;
}
.ant-btn-text[disabled] > a:only-child::after,
.ant-btn-text[disabled]:hover > a:only-child::after,
.ant-btn-text[disabled]:focus > a:only-child::after,
.ant-btn-text[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-text:hover,
.ant-btn-text:focus {
  color: rgba(0, 0, 0, 0.85);
  background: rgba(0, 0, 0, 0.018);
  border-color: transparent;
}
.ant-btn-text:active {
  color: rgba(0, 0, 0, 0.85);
  background: rgba(0, 0, 0, 0.028);
  border-color: transparent;
}
.ant-btn-text[disabled],
.ant-btn-text[disabled]:hover,
.ant-btn-text[disabled]:focus,
.ant-btn-text[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  border-color: transparent;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-text[disabled] > a:only-child,
.ant-btn-text[disabled]:hover > a:only-child,
.ant-btn-text[disabled]:focus > a:only-child,
.ant-btn-text[disabled]:active > a:only-child {
  color: currentColor;
}
.ant-btn-text[disabled] > a:only-child::after,
.ant-btn-text[disabled]:hover > a:only-child::after,
.ant-btn-text[disabled]:focus > a:only-child::after,
.ant-btn-text[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous {
  color: #DD4E4E;
  background: #00441F;
  border-color: #DD4E4E;
}
.ant-btn-dangerous > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous:hover,
.ant-btn-dangerous:focus {
  color: #eb7b78;
  background: #00441F;
  border-color: #eb7b78;
}
.ant-btn-dangerous:hover > a:only-child,
.ant-btn-dangerous:focus > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous:hover > a:only-child::after,
.ant-btn-dangerous:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous:active {
  color: #b8373b;
  background: #00441F;
  border-color: #b8373b;
}
.ant-btn-dangerous:active > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous[disabled],
.ant-btn-dangerous[disabled]:hover,
.ant-btn-dangerous[disabled]:focus,
.ant-btn-dangerous[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous[disabled] > a:only-child,
.ant-btn-dangerous[disabled]:hover > a:only-child,
.ant-btn-dangerous[disabled]:focus > a:only-child,
.ant-btn-dangerous[disabled]:active > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous[disabled] > a:only-child::after,
.ant-btn-dangerous[disabled]:hover > a:only-child::after,
.ant-btn-dangerous[disabled]:focus > a:only-child::after,
.ant-btn-dangerous[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-primary {
  color: #fff;
  background: #DD4E4E;
  border-color: #DD4E4E;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.ant-btn-dangerous.ant-btn-primary > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-primary:hover,
.ant-btn-dangerous.ant-btn-primary:focus {
  color: #fff;
  background: #eb7b78;
  border-color: #eb7b78;
}
.ant-btn-dangerous.ant-btn-primary:hover > a:only-child,
.ant-btn-dangerous.ant-btn-primary:focus > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-primary:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-primary:active {
  color: #fff;
  background: #b8373b;
  border-color: #b8373b;
}
.ant-btn-dangerous.ant-btn-primary:active > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-primary:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-primary[disabled],
.ant-btn-dangerous.ant-btn-primary[disabled]:hover,
.ant-btn-dangerous.ant-btn-primary[disabled]:focus,
.ant-btn-dangerous.ant-btn-primary[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-primary[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-primary[disabled]:active > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-primary[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-primary[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link {
  color: #DD4E4E;
  background: transparent;
  border-color: transparent;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-link > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link:hover,
.ant-btn-dangerous.ant-btn-link:focus {
  color: #0d522a;
  background: transparent;
  border-color: #0d522a;
}
.ant-btn-dangerous.ant-btn-link:hover > a:only-child,
.ant-btn-dangerous.ant-btn-link:focus > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link:active {
  color: #001f0f;
  background: transparent;
  border-color: #001f0f;
}
.ant-btn-dangerous.ant-btn-link:active > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link[disabled],
.ant-btn-dangerous.ant-btn-link[disabled]:hover,
.ant-btn-dangerous.ant-btn-link[disabled]:focus,
.ant-btn-dangerous.ant-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link:hover,
.ant-btn-dangerous.ant-btn-link:focus {
  color: #eb7b78;
  background: transparent;
  border-color: transparent;
}
.ant-btn-dangerous.ant-btn-link:hover > a:only-child,
.ant-btn-dangerous.ant-btn-link:focus > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link:active {
  color: #b8373b;
  background: transparent;
  border-color: transparent;
}
.ant-btn-dangerous.ant-btn-link:active > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-link[disabled],
.ant-btn-dangerous.ant-btn-link[disabled]:hover,
.ant-btn-dangerous.ant-btn-link[disabled]:focus,
.ant-btn-dangerous.ant-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  border-color: transparent;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text {
  color: #DD4E4E;
  background: transparent;
  border-color: transparent;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-text > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-text > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text:hover,
.ant-btn-dangerous.ant-btn-text:focus {
  color: #0d522a;
  background: transparent;
  border-color: #0d522a;
}
.ant-btn-dangerous.ant-btn-text:hover > a:only-child,
.ant-btn-dangerous.ant-btn-text:focus > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-text:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-text:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text:active {
  color: #001f0f;
  background: transparent;
  border-color: #001f0f;
}
.ant-btn-dangerous.ant-btn-text:active > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-text:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text[disabled],
.ant-btn-dangerous.ant-btn-text[disabled]:hover,
.ant-btn-dangerous.ant-btn-text[disabled]:focus,
.ant-btn-dangerous.ant-btn-text[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-text[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-text[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text:hover,
.ant-btn-dangerous.ant-btn-text:focus {
  color: #eb7b78;
  background: rgba(0, 0, 0, 0.018);
  border-color: transparent;
}
.ant-btn-dangerous.ant-btn-text:hover > a:only-child,
.ant-btn-dangerous.ant-btn-text:focus > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-text:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-text:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text:active {
  color: #b8373b;
  background: rgba(0, 0, 0, 0.028);
  border-color: transparent;
}
.ant-btn-dangerous.ant-btn-text:active > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-text:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-dangerous.ant-btn-text[disabled],
.ant-btn-dangerous.ant-btn-text[disabled]:hover,
.ant-btn-dangerous.ant-btn-text[disabled]:focus,
.ant-btn-dangerous.ant-btn-text[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  border-color: transparent;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-dangerous.ant-btn-text[disabled] > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child {
  color: currentColor;
}
.ant-btn-dangerous.ant-btn-text[disabled] > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:hover > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:focus > a:only-child::after,
.ant-btn-dangerous.ant-btn-text[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-icon-only {
  width: 32px;
  height: 32px;
  padding: 2.4px 0;
  font-size: 16px;
  border-radius: 20px;
  vertical-align: -1px;
}
.ant-btn-icon-only > * {
  font-size: 16px;
}
.ant-btn-icon-only.ant-btn-lg {
  width: 40px;
  height: 40px;
  padding: 4.9px 0;
  font-size: 18px;
  border-radius: 20px;
}
.ant-btn-icon-only.ant-btn-lg > * {
  font-size: 18px;
}
.ant-btn-icon-only.ant-btn-sm {
  width: 24px;
  height: 24px;
  padding: 0px 0;
  font-size: 14px;
  border-radius: 20px;
}
.ant-btn-icon-only.ant-btn-sm > * {
  font-size: 14px;
}
.ant-btn-round {
  height: 32px;
  padding: 4px 16px;
  font-size: 14px;
  border-radius: 32px;
}
.ant-btn-round.ant-btn-lg {
  height: 40px;
  padding: 6.4px 20px;
  font-size: 16px;
  border-radius: 40px;
}
.ant-btn-round.ant-btn-sm {
  height: 24px;
  padding: 0px 12px;
  font-size: 14px;
  border-radius: 24px;
}
.ant-btn-round.ant-btn-icon-only {
  width: auto;
}
.ant-btn-circle {
  min-width: 32px;
  padding-right: 0;
  padding-left: 0;
  text-align: center;
  border-radius: 50%;
}
.ant-btn-circle.ant-btn-lg {
  min-width: 40px;
  border-radius: 50%;
}
.ant-btn-circle.ant-btn-sm {
  min-width: 24px;
  border-radius: 50%;
}
.ant-btn::before {
  position: absolute;
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  z-index: 1;
  display: none;
  background: #fff;
  border-radius: inherit;
  opacity: 0.35;
  transition: opacity 0.2s;
  content: '';
  pointer-events: none;
}
.ant-btn .anticon {
  transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-btn .anticon.anticon-plus > svg,
.ant-btn .anticon.anticon-minus > svg {
  shape-rendering: optimizeSpeed;
}
.ant-btn.ant-btn-loading {
  position: relative;
}
.ant-btn.ant-btn-loading:not([disabled]) {
  pointer-events: none;
}
.ant-btn.ant-btn-loading::before {
  display: block;
}
.ant-btn > .ant-btn-loading-icon {
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-btn > .ant-btn-loading-icon .anticon {
  padding-right: 8px;
  animation: none;
}
.ant-btn > .ant-btn-loading-icon .anticon svg {
  animation: loadingCircle 1s infinite linear;
}
.ant-btn > .ant-btn-loading-icon:only-child .anticon {
  padding-right: 0;
}
.ant-btn-group {
  position: relative;
  display: inline-flex;
}
.ant-btn-group > .ant-btn,
.ant-btn-group > span > .ant-btn {
  position: relative;
}
.ant-btn-group > .ant-btn:hover,
.ant-btn-group > span > .ant-btn:hover,
.ant-btn-group > .ant-btn:focus,
.ant-btn-group > span > .ant-btn:focus,
.ant-btn-group > .ant-btn:active,
.ant-btn-group > span > .ant-btn:active {
  z-index: 2;
}
.ant-btn-group > .ant-btn[disabled],
.ant-btn-group > span > .ant-btn[disabled] {
  z-index: 0;
}
.ant-btn-group .ant-btn-icon-only {
  font-size: 14px;
}
.ant-btn-group-lg > .ant-btn,
.ant-btn-group-lg > span > .ant-btn {
  height: 40px;
  padding: 6.4px 15px;
  font-size: 16px;
  border-radius: 0;
}
.ant-btn-group-lg .ant-btn.ant-btn-icon-only {
  width: 40px;
  height: 40px;
  padding-right: 0;
  padding-left: 0;
}
.ant-btn-group-sm > .ant-btn,
.ant-btn-group-sm > span > .ant-btn {
  height: 24px;
  padding: 0px 7px;
  font-size: 14px;
  border-radius: 0;
}
.ant-btn-group-sm > .ant-btn > .anticon,
.ant-btn-group-sm > span > .ant-btn > .anticon {
  font-size: 14px;
}
.ant-btn-group-sm .ant-btn.ant-btn-icon-only {
  width: 24px;
  height: 24px;
  padding-right: 0;
  padding-left: 0;
}
.ant-btn-group .ant-btn + .ant-btn,
.ant-btn + .ant-btn-group,
.ant-btn-group span + .ant-btn,
.ant-btn-group .ant-btn + span,
.ant-btn-group > span + span,
.ant-btn-group + .ant-btn,
.ant-btn-group + .ant-btn-group {
  margin-left: -1px;
}
.ant-btn-group .ant-btn-primary + .ant-btn:not(.ant-btn-primary):not([disabled]) {
  border-left-color: transparent;
}
.ant-btn-group .ant-btn {
  border-radius: 0;
}
.ant-btn-group > .ant-btn:first-child,
.ant-btn-group > span:first-child > .ant-btn {
  margin-left: 0;
}
.ant-btn-group > .ant-btn:only-child {
  border-radius: 20px;
}
.ant-btn-group > span:only-child > .ant-btn {
  border-radius: 20px;
}
.ant-btn-group > .ant-btn:first-child:not(:last-child),
.ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.ant-btn-group > .ant-btn:last-child:not(:first-child),
.ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.ant-btn-group-sm > .ant-btn:only-child {
  border-radius: 20px;
}
.ant-btn-group-sm > span:only-child > .ant-btn {
  border-radius: 20px;
}
.ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
.ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
.ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.ant-btn-group > .ant-btn-group {
  float: left;
}
.ant-btn-group > .ant-btn-group:not(:first-child):not(:last-child) > .ant-btn {
  border-radius: 0;
}
.ant-btn-group > .ant-btn-group:first-child:not(:last-child) > .ant-btn:last-child {
  padding-right: 8px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-btn-group > .ant-btn-group:last-child:not(:first-child) > .ant-btn:first-child {
  padding-left: 8px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-btn-rtl.ant-btn-group .ant-btn + .ant-btn,
.ant-btn-rtl.ant-btn + .ant-btn-group,
.ant-btn-rtl.ant-btn-group span + .ant-btn,
.ant-btn-rtl.ant-btn-group .ant-btn + span,
.ant-btn-rtl.ant-btn-group > span + span,
.ant-btn-rtl.ant-btn-group + .ant-btn,
.ant-btn-rtl.ant-btn-group + .ant-btn-group,
.ant-btn-group-rtl.ant-btn-group .ant-btn + .ant-btn,
.ant-btn-group-rtl.ant-btn + .ant-btn-group,
.ant-btn-group-rtl.ant-btn-group span + .ant-btn,
.ant-btn-group-rtl.ant-btn-group .ant-btn + span,
.ant-btn-group-rtl.ant-btn-group > span + span,
.ant-btn-group-rtl.ant-btn-group + .ant-btn,
.ant-btn-group-rtl.ant-btn-group + .ant-btn-group {
  margin-right: -1px;
  margin-left: auto;
}
.ant-btn-group.ant-btn-group-rtl {
  direction: rtl;
}
.ant-btn-group-rtl.ant-btn-group > .ant-btn:first-child:not(:last-child),
.ant-btn-group-rtl.ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 0;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 0;
}
.ant-btn-group-rtl.ant-btn-group > .ant-btn:last-child:not(:first-child),
.ant-btn-group-rtl.ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
  border-top-left-radius: 20px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 20px;
}
.ant-btn-group-rtl.ant-btn-group-sm > .ant-btn:first-child:not(:last-child),
.ant-btn-group-rtl.ant-btn-group-sm > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 0;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 0;
}
.ant-btn-group-rtl.ant-btn-group-sm > .ant-btn:last-child:not(:first-child),
.ant-btn-group-rtl.ant-btn-group-sm > span:last-child:not(:first-child) > .ant-btn {
  border-top-left-radius: 20px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 20px;
}
.ant-btn:focus > span,
.ant-btn:active > span {
  position: relative;
}
.ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  margin-left: 8px;
}
.ant-btn-background-ghost {
  color: #fff;
  background: transparent !important;
  border-color: #fff;
}
.ant-btn-background-ghost.ant-btn-primary {
  color: #00441F;
  background: transparent;
  border-color: #00441F;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-primary > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-primary > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-primary:hover,
.ant-btn-background-ghost.ant-btn-primary:focus {
  color: #0d522a;
  background: transparent;
  border-color: #0d522a;
}
.ant-btn-background-ghost.ant-btn-primary:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary:focus > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-primary:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-primary:active {
  color: #001f0f;
  background: transparent;
  border-color: #001f0f;
}
.ant-btn-background-ghost.ant-btn-primary:active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-primary:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-primary[disabled],
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-primary[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-danger {
  color: #DD4E4E;
  background: transparent;
  border-color: #DD4E4E;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-danger > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-danger > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-danger:hover,
.ant-btn-background-ghost.ant-btn-danger:focus {
  color: #eb7b78;
  background: transparent;
  border-color: #eb7b78;
}
.ant-btn-background-ghost.ant-btn-danger:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-danger:focus > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-danger:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-danger:active {
  color: #b8373b;
  background: transparent;
  border-color: #b8373b;
}
.ant-btn-background-ghost.ant-btn-danger:active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-danger:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-danger[disabled],
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-danger[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous {
  color: #DD4E4E;
  background: transparent;
  border-color: #DD4E4E;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-dangerous > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-dangerous > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous:hover,
.ant-btn-background-ghost.ant-btn-dangerous:focus {
  color: #eb7b78;
  background: transparent;
  border-color: #eb7b78;
}
.ant-btn-background-ghost.ant-btn-dangerous:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous:focus > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-dangerous:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous:active {
  color: #b8373b;
  background: transparent;
  border-color: #b8373b;
}
.ant-btn-background-ghost.ant-btn-dangerous:active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-dangerous:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous[disabled],
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-dangerous[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-dangerous[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link {
  color: #DD4E4E;
  background: transparent;
  border-color: transparent;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus {
  color: #eb7b78;
  background: transparent;
  border-color: transparent;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active {
  color: #b8373b;
  background: transparent;
  border-color: transparent;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled],
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child {
  color: currentColor;
}
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled] > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus > a:only-child::after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active > a:only-child::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  content: '';
}
.ant-btn-two-chinese-chars::first-letter {
  letter-spacing: 0.34em;
}
.ant-btn-two-chinese-chars > *:not(.anticon) {
  margin-right: -0.34em;
  letter-spacing: 0.34em;
}
.ant-btn-block {
  width: 100%;
}
.ant-btn:empty {
  display: inline-block;
  width: 0;
  visibility: hidden;
  content: '\a0';
}
a.ant-btn {
  padding-top: 0.1px;
  line-height: 30px;
}
a.ant-btn-lg {
  line-height: 38px;
}
a.ant-btn-sm {
  line-height: 22px;
}
.ant-btn-rtl {
  direction: rtl;
}
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child),
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary {
  border-right-color: #0d522a;
  border-left-color: #d9d9d9;
}
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled],
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary + .ant-btn-primary[disabled] {
  border-right-color: #d9d9d9;
  border-left-color: #0d522a;
}
.ant-btn-rtl.ant-btn > .ant-btn-loading-icon .anticon {
  padding-right: 0;
  padding-left: 8px;
}
.ant-btn > .ant-btn-loading-icon:only-child .anticon {
  padding-right: 0;
  padding-left: 0;
}
.ant-btn-rtl.ant-btn > .anticon + span,
.ant-btn-rtl.ant-btn > span + .anticon {
  margin-right: 8px;
  margin-left: 0;
}
.ant-picker-calendar {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  background: #fff;
}
.ant-picker-calendar-header {
  display: flex;
  justify-content: flex-end;
  padding: 12px 0;
}
.ant-picker-calendar-header .ant-picker-calendar-year-select {
  min-width: 80px;
}
.ant-picker-calendar-header .ant-picker-calendar-month-select {
  min-width: 70px;
  margin-left: 8px;
}
.ant-picker-calendar-header .ant-picker-calendar-mode-switch {
  margin-left: 8px;
}
.ant-picker-calendar .ant-picker-panel {
  background: #fff;
  border: 0;
  border-top: 1px solid #f0f0f0;
  border-radius: 0;
}
.ant-picker-calendar .ant-picker-panel .ant-picker-month-panel,
.ant-picker-calendar .ant-picker-panel .ant-picker-date-panel {
  width: auto;
}
.ant-picker-calendar .ant-picker-panel .ant-picker-body {
  padding: 8px 0;
}
.ant-picker-calendar .ant-picker-panel .ant-picker-content {
  width: 100%;
}
.ant-picker-calendar-mini {
  border-radius: 20px;
}
.ant-picker-calendar-mini .ant-picker-calendar-header {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-picker-calendar-mini .ant-picker-panel {
  border-radius: 0 0 20px 20px;
}
.ant-picker-calendar-mini .ant-picker-content {
  height: 256px;
}
.ant-picker-calendar-mini .ant-picker-content th {
  height: auto;
  padding: 0;
  line-height: 18px;
}
.ant-picker-calendar-full .ant-picker-panel {
  display: block;
  width: 100%;
  text-align: right;
  background: #fff;
  border: 0;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-body td {
  padding: 0;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
  height: auto;
  padding: 0 12px 5px 0;
  line-height: 18px;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell::before {
  display: none;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell:hover .ant-picker-calendar-date {
  background: #f5f5f5;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell .ant-picker-calendar-date-today::before {
  display: none;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today {
  background: #77857b;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date .ant-picker-calendar-date-value,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date .ant-picker-calendar-date-value,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today .ant-picker-calendar-date-value,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
  color: #00441F;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
  display: block;
  width: auto;
  height: auto;
  margin: 0 4px;
  padding: 4px 8px 0;
  border: 0;
  border-top: 2px solid #f0f0f0;
  border-radius: 0;
  transition: background 0.3s;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-value {
  line-height: 24px;
  transition: color 0.3s;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
  position: static;
  width: auto;
  height: 86px;
  overflow-y: auto;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.5715;
  text-align: left;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today {
  border-color: #00441F;
}
.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
  color: rgba(0, 0, 0, 0.85);
}
@media only screen and (max-width: 480px) {
  .ant-picker-calendar-header {
    display: block;
  }
  .ant-picker-calendar-header .ant-picker-calendar-year-select {
    width: 50%;
  }
  .ant-picker-calendar-header .ant-picker-calendar-month-select {
    width: calc(50% - 8px);
  }
  .ant-picker-calendar-header .ant-picker-calendar-mode-switch {
    width: 100%;
    margin-top: 8px;
    margin-left: 0;
  }
  .ant-picker-calendar-header .ant-picker-calendar-mode-switch > label {
    width: 50%;
    text-align: center;
  }
}
.ant-picker-calendar-rtl {
  direction: rtl;
}
.ant-picker-calendar-rtl .ant-picker-calendar-header .ant-picker-calendar-month-select {
  margin-right: 8px;
  margin-left: 0;
}
.ant-picker-calendar-rtl .ant-picker-calendar-header .ant-picker-calendar-mode-switch {
  margin-right: 8px;
  margin-left: 0;
}
.ant-picker-calendar-rtl.ant-picker-calendar-full .ant-picker-panel {
  text-align: left;
}
.ant-picker-calendar-rtl.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
  padding: 0 0 5px 12px;
}
.ant-picker-calendar-rtl.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
  text-align: right;
}
.ant-card {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  background: #fff;
  border-radius: 20px;
}
.ant-card-rtl {
  direction: rtl;
}
.ant-card-hoverable {
  cursor: pointer;
  transition: box-shadow 0.3s, border-color 0.3s;
}
.ant-card-hoverable:hover {
  border-color: transparent;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
}
.ant-card-bordered {
  border: 1px solid #f0f0f0;
}
.ant-card-head {
  min-height: 48px;
  margin-bottom: -1px;
  padding: 0 24px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  background: transparent;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 20px 20px 0 0;
}
.ant-card-head::before {
  display: table;
  content: '';
}
.ant-card-head::after {
  display: table;
  clear: both;
  content: '';
}
.ant-card-head::before {
  display: table;
  content: '';
}
.ant-card-head::after {
  display: table;
  clear: both;
  content: '';
}
.ant-card-head-wrapper {
  display: flex;
  align-items: center;
}
.ant-card-head-title {
  display: inline-block;
  flex: 1;
  padding: 16px 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-card-head-title > .ant-typography,
.ant-card-head-title > .ant-typography-edit-content {
  left: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.ant-card-head .ant-tabs {
  clear: both;
  margin-bottom: -17px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
}
.ant-card-head .ant-tabs-bar {
  border-bottom: 1px solid #f0f0f0;
}
.ant-card-extra {
  float: right;
  margin-left: auto;
  padding: 16px 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
}
.ant-card-rtl .ant-card-extra {
  margin-right: auto;
  margin-left: 0;
}
.ant-card-body {
  padding: 24px;
}
.ant-card-body::before {
  display: table;
  content: '';
}
.ant-card-body::after {
  display: table;
  clear: both;
  content: '';
}
.ant-card-body::before {
  display: table;
  content: '';
}
.ant-card-body::after {
  display: table;
  clear: both;
  content: '';
}
.ant-card-contain-grid:not(.ant-card-loading) .ant-card-body {
  margin: -1px 0 0 -1px;
  padding: 0;
}
.ant-card-grid {
  float: left;
  width: 33.33%;
  padding: 24px;
  border: 0;
  border-radius: 0;
  box-shadow: 1px 0 0 0 #f0f0f0, 0 1px 0 0 #f0f0f0, 1px 1px 0 0 #f0f0f0, 1px 0 0 0 #f0f0f0 inset, 0 1px 0 0 #f0f0f0 inset;
  transition: all 0.3s;
}
.ant-card-rtl .ant-card-grid {
  float: right;
}
.ant-card-grid-hoverable:hover {
  position: relative;
  z-index: 1;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
}
.ant-card-contain-tabs > .ant-card-head .ant-card-head-title {
  min-height: 32px;
  padding-bottom: 0;
}
.ant-card-contain-tabs > .ant-card-head .ant-card-extra {
  padding-bottom: 0;
}
.ant-card-bordered .ant-card-cover {
  margin-top: -1px;
  margin-right: -1px;
  margin-left: -1px;
}
.ant-card-cover > * {
  display: block;
  width: 100%;
}
.ant-card-cover img {
  border-radius: 20px 20px 0 0;
}
.ant-card-actions {
  margin: 0;
  padding: 0;
  list-style: none;
  background: #fff;
  border-top: 1px solid #f0f0f0;
}
.ant-card-actions::before {
  display: table;
  content: '';
}
.ant-card-actions::after {
  display: table;
  clear: both;
  content: '';
}
.ant-card-actions::before {
  display: table;
  content: '';
}
.ant-card-actions::after {
  display: table;
  clear: both;
  content: '';
}
.ant-card-actions > li {
  float: left;
  margin: 12px 0;
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
}
.ant-card-rtl .ant-card-actions > li {
  float: right;
}
.ant-card-actions > li > span {
  position: relative;
  display: block;
  min-width: 32px;
  font-size: 14px;
  line-height: 1.5715;
  cursor: pointer;
}
.ant-card-actions > li > span:hover {
  color: #00441F;
  transition: color 0.3s;
}
.ant-card-actions > li > span a:not(.ant-btn),
.ant-card-actions > li > span > .anticon {
  display: inline-block;
  width: 100%;
  color: rgba(0, 0, 0, 0.45);
  line-height: 22px;
  transition: color 0.3s;
}
.ant-card-actions > li > span a:not(.ant-btn):hover,
.ant-card-actions > li > span > .anticon:hover {
  color: #00441F;
}
.ant-card-actions > li > span > .anticon {
  font-size: 16px;
  line-height: 22px;
}
.ant-card-actions > li:not(:last-child) {
  border-right: 1px solid #f0f0f0;
}
.ant-card-type-inner .ant-card-head {
  padding: 0 24px;
  background: #fafafa;
}
.ant-card-type-inner .ant-card-head-title {
  padding: 12px 0;
  font-size: 14px;
}
.ant-card-type-inner .ant-card-body {
  padding: 16px 24px;
}
.ant-card-type-inner .ant-card-extra {
  padding: 13.5px 0;
}
.ant-card-meta {
  margin: -4px 0;
}
.ant-card-meta::before {
  display: table;
  content: '';
}
.ant-card-meta::after {
  display: table;
  clear: both;
  content: '';
}
.ant-card-meta::before {
  display: table;
  content: '';
}
.ant-card-meta::after {
  display: table;
  clear: both;
  content: '';
}
.ant-card-meta-avatar {
  float: left;
  padding-right: 16px;
}
.ant-card-rtl .ant-card-meta-avatar {
  float: right;
  padding-right: 0;
  padding-left: 16px;
}
.ant-card-meta-detail {
  overflow: hidden;
}
.ant-card-meta-detail > div:not(:last-child) {
  margin-bottom: 8px;
}
.ant-card-meta-title {
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-card-meta-description {
  color: rgba(0, 0, 0, 0.45);
}
.ant-card-loading {
  overflow: hidden;
}
.ant-card-loading .ant-card-body {
  user-select: none;
}
.ant-card-loading-content p {
  margin: 0;
}
.ant-card-loading-block {
  height: 14px;
  margin: 4px 0;
  background: linear-gradient(90deg, rgba(207, 216, 220, 0.2), rgba(207, 216, 220, 0.4), rgba(207, 216, 220, 0.2));
  background-size: 600% 600%;
  border-radius: 20px;
  animation: card-loading 1.4s ease infinite;
}
@keyframes card-loading {
  0%,
  100% {
    background-position: 0 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}
.ant-card-small > .ant-card-head {
  min-height: 36px;
  padding: 0 12px;
  font-size: 14px;
}
.ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-head-title {
  padding: 8px 0;
}
.ant-card-small > .ant-card-head > .ant-card-head-wrapper > .ant-card-extra {
  padding: 8px 0;
  font-size: 14px;
}
.ant-card-small > .ant-card-body {
  padding: 12px;
}
.ant-carousel {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
}
.ant-carousel .slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.ant-carousel .slick-list {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.ant-carousel .slick-list:focus {
  outline: none;
}
.ant-carousel .slick-list.dragging {
  cursor: pointer;
}
.ant-carousel .slick-list .slick-slide {
  pointer-events: none;
}
.ant-carousel .slick-list .slick-slide input.ant-radio-input,
.ant-carousel .slick-list .slick-slide input.ant-checkbox-input {
  visibility: hidden;
}
.ant-carousel .slick-list .slick-slide.slick-active {
  pointer-events: auto;
}
.ant-carousel .slick-list .slick-slide.slick-active input.ant-radio-input,
.ant-carousel .slick-list .slick-slide.slick-active input.ant-checkbox-input {
  visibility: visible;
}
.ant-carousel .slick-list .slick-slide > div > div {
  vertical-align: bottom;
}
.ant-carousel .slick-slider .slick-track,
.ant-carousel .slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
  touch-action: pan-y;
}
.ant-carousel .slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}
.ant-carousel .slick-track::before,
.ant-carousel .slick-track::after {
  display: table;
  content: '';
}
.ant-carousel .slick-track::after {
  clear: both;
}
.slick-loading .ant-carousel .slick-track {
  visibility: hidden;
}
.ant-carousel .slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
.ant-carousel .slick-slide img {
  display: block;
}
.ant-carousel .slick-slide.slick-loading img {
  display: none;
}
.ant-carousel .slick-slide.dragging img {
  pointer-events: none;
}
.ant-carousel .slick-initialized .slick-slide {
  display: block;
}
.ant-carousel .slick-loading .slick-slide {
  visibility: hidden;
}
.ant-carousel .slick-vertical .slick-slide {
  display: block;
  height: auto;
}
.ant-carousel .slick-arrow.slick-hidden {
  display: none;
}
.ant-carousel .slick-prev,
.ant-carousel .slick-next {
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  padding: 0;
  color: transparent;
  font-size: 0;
  line-height: 0;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
}
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
  color: transparent;
  background: transparent;
  outline: none;
}
.ant-carousel .slick-prev:hover::before,
.ant-carousel .slick-next:hover::before,
.ant-carousel .slick-prev:focus::before,
.ant-carousel .slick-next:focus::before {
  opacity: 1;
}
.ant-carousel .slick-prev.slick-disabled::before,
.ant-carousel .slick-next.slick-disabled::before {
  opacity: 0.25;
}
.ant-carousel .slick-prev {
  left: -25px;
}
.ant-carousel .slick-prev::before {
  content: '←';
}
.ant-carousel .slick-next {
  right: -25px;
}
.ant-carousel .slick-next::before {
  content: '→';
}
.ant-carousel .slick-dots {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex !important;
  justify-content: center;
  margin-right: 15%;
  margin-left: 15%;
  padding-left: 0;
  list-style: none;
}
.ant-carousel .slick-dots-bottom {
  bottom: 12px;
}
.ant-carousel .slick-dots-top {
  top: 12px;
}
.ant-carousel .slick-dots li {
  position: relative;
  display: inline-block;
  flex: 0 1 auto;
  box-sizing: content-box;
  width: 16px;
  height: 3px;
  margin: 0 2px;
  margin-right: 3px;
  margin-left: 3px;
  padding: 0;
  text-align: center;
  text-indent: -999px;
  vertical-align: top;
  transition: all 0.5s;
}
.ant-carousel .slick-dots li button {
  display: block;
  width: 100%;
  height: 3px;
  padding: 0;
  color: transparent;
  font-size: 0;
  background: #fff;
  border: 0;
  border-radius: 1px;
  outline: none;
  cursor: pointer;
  opacity: 0.3;
  transition: all 0.5s;
}
.ant-carousel .slick-dots li button:hover,
.ant-carousel .slick-dots li button:focus {
  opacity: 0.75;
}
.ant-carousel .slick-dots li.slick-active {
  width: 24px;
}
.ant-carousel .slick-dots li.slick-active button {
  background: #fff;
  opacity: 1;
}
.ant-carousel .slick-dots li.slick-active:hover,
.ant-carousel .slick-dots li.slick-active:focus {
  opacity: 1;
}
.ant-carousel-vertical .slick-dots {
  top: 50%;
  bottom: auto;
  flex-direction: column;
  width: 3px;
  height: auto;
  margin: 0;
  transform: translateY(-50%);
}
.ant-carousel-vertical .slick-dots-left {
  right: auto;
  left: 12px;
}
.ant-carousel-vertical .slick-dots-right {
  right: 12px;
  left: auto;
}
.ant-carousel-vertical .slick-dots li {
  width: 3px;
  height: 16px;
  margin: 4px 2px;
  vertical-align: baseline;
}
.ant-carousel-vertical .slick-dots li button {
  width: 3px;
  height: 16px;
}
.ant-carousel-vertical .slick-dots li.slick-active {
  width: 3px;
  height: 24px;
}
.ant-carousel-vertical .slick-dots li.slick-active button {
  width: 3px;
  height: 24px;
}
.ant-carousel-rtl {
  direction: rtl;
}
.ant-carousel-rtl .ant-carousel .slick-track {
  right: 0;
  left: auto;
}
.ant-carousel-rtl .ant-carousel .slick-prev {
  right: -25px;
  left: auto;
}
.ant-carousel-rtl .ant-carousel .slick-prev::before {
  content: '→';
}
.ant-carousel-rtl .ant-carousel .slick-next {
  right: auto;
  left: -25px;
}
.ant-carousel-rtl .ant-carousel .slick-next::before {
  content: '←';
}
.ant-carousel-rtl.ant-carousel .slick-dots {
  flex-direction: row-reverse;
}
.ant-carousel-rtl.ant-carousel-vertical .slick-dots {
  flex-direction: column;
}
.ant-cascader {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
}
.ant-cascader-input.ant-input {
  position: static;
  width: 100%;
  padding-right: 24px;
  background-color: transparent !important;
  cursor: pointer;
}
.ant-cascader-picker-show-search .ant-cascader-input.ant-input {
  position: relative;
}
.ant-cascader-picker {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  background-color: #fff;
  border-radius: 20px;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
}
.ant-cascader-picker-with-value .ant-cascader-picker-label {
  color: transparent;
}
.ant-cascader-picker-disabled {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  cursor: not-allowed;
}
.ant-cascader-picker-disabled .ant-cascader-input {
  cursor: not-allowed;
}
.ant-cascader-picker:focus .ant-cascader-input {
  border-color: #0d522a;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(0, 68, 31, 0.2);
}
.ant-input-rtl .ant-cascader-picker:focus .ant-cascader-input {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-cascader-picker-borderless .ant-cascader-input {
  border-color: transparent !important;
  box-shadow: none !important;
}
.ant-cascader-picker-show-search.ant-cascader-picker-focused {
  color: rgba(0, 0, 0, 0.25);
}
.ant-cascader-picker-label {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 20px;
  margin-top: -10px;
  padding: 0 20px 0 12px;
  overflow: hidden;
  line-height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-cascader-picker-clear {
  position: absolute;
  top: 50%;
  right: 12px;
  z-index: 2;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 12px;
  background: #fff;
  cursor: pointer;
  opacity: 0;
  transition: color 0.3s ease, opacity 0.15s ease;
}
.ant-cascader-picker-clear:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-cascader-picker:hover .ant-cascader-picker-clear {
  opacity: 1;
}
.ant-cascader-picker-arrow {
  position: absolute;
  top: 50%;
  right: 12px;
  z-index: 1;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 12px;
}
.ant-cascader-picker-label:hover + .ant-cascader-input:not(.ant-cascader-picker-disabled .ant-cascader-picker-label:hover + .ant-cascader-input) {
  border-color: #0d522a;
  border-right-width: 1px !important;
}
.ant-input-rtl .ant-cascader-picker-label:hover + .ant-cascader-input:not(.ant-cascader-picker-disabled .ant-cascader-picker-label:hover + .ant-cascader-input) {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-cascader-picker-small .ant-cascader-picker-clear,
.ant-cascader-picker-small .ant-cascader-picker-arrow {
  right: 8px;
}
.ant-cascader-menus {
  position: absolute;
  z-index: 1050;
  font-size: 14px;
  white-space: nowrap;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-cascader-menus ul,
.ant-cascader-menus ol {
  margin: 0;
  list-style: none;
}
.ant-cascader-menus-empty,
.ant-cascader-menus-hidden {
  display: none;
}
.ant-cascader-menus.slide-up-enter.slide-up-enter-active.ant-cascader-menus-placement-bottomLeft,
.ant-cascader-menus.slide-up-appear.slide-up-appear-active.ant-cascader-menus-placement-bottomLeft {
  animation-name: antSlideUpIn;
}
.ant-cascader-menus.slide-up-enter.slide-up-enter-active.ant-cascader-menus-placement-topLeft,
.ant-cascader-menus.slide-up-appear.slide-up-appear-active.ant-cascader-menus-placement-topLeft {
  animation-name: antSlideDownIn;
}
.ant-cascader-menus.slide-up-leave.slide-up-leave-active.ant-cascader-menus-placement-bottomLeft {
  animation-name: antSlideUpOut;
}
.ant-cascader-menus.slide-up-leave.slide-up-leave-active.ant-cascader-menus-placement-topLeft {
  animation-name: antSlideDownOut;
}
.ant-cascader-menu {
  display: inline-block;
  min-width: 111px;
  height: 180px;
  margin: 0;
  padding: 4px 0;
  overflow: auto;
  vertical-align: top;
  list-style: none;
  border-right: 1px solid #f0f0f0;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}
.ant-cascader-menu:first-child {
  border-radius: 20px 0 0 20px;
}
.ant-cascader-menu:last-child {
  margin-right: -1px;
  border-right-color: transparent;
  border-radius: 0 20px 20px 0;
}
.ant-cascader-menu:only-child {
  border-radius: 20px;
}
.ant-cascader-menu-item {
  padding: 5px 12px;
  overflow: hidden;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-cascader-menu-item:hover {
  background: #f5f5f5;
}
.ant-cascader-menu-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-cascader-menu-item-disabled:hover {
  background: transparent;
}
.ant-cascader-menu-empty .ant-cascader-menu-item {
  color: rgba(0, 0, 0, 0.25);
  cursor: default;
  pointer-events: none;
}
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
  font-weight: 600;
  background-color: #77857b;
}
.ant-cascader-menu-item-expand {
  position: relative;
  padding-right: 24px;
}
.ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
.ant-cascader-menu-item-loading-icon {
  position: absolute;
  right: 12px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 10px;
}
.ant-cascader-menu-item-disabled.ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
.ant-cascader-menu-item-disabled.ant-cascader-menu-item-loading-icon {
  color: rgba(0, 0, 0, 0.25);
}
.ant-cascader-menu-item .ant-cascader-menu-item-keyword {
  color: #d70101;
}
.ant-cascader-picker-rtl .ant-cascader-input.ant-input {
  padding-right: 11px;
  padding-left: 24px;
  text-align: right;
}
.ant-cascader-picker-rtl {
  direction: rtl;
}
.ant-cascader-picker-rtl .ant-cascader-picker-label {
  padding: 0 12px 0 20px;
  text-align: right;
}
.ant-cascader-picker-rtl .ant-cascader-picker-clear {
  right: auto;
  left: 12px;
}
.ant-cascader-picker-rtl .ant-cascader-picker-arrow {
  right: auto;
  left: 12px;
}
.ant-cascader-picker-rtl.ant-cascader-picker-small .ant-cascader-picker-clear,
.ant-cascader-picker-rtl.ant-cascader-picker-small .ant-cascader-picker-arrow {
  right: auto;
  left: 8px;
}
.ant-cascader-menu-rtl .ant-cascader-menu {
  direction: rtl;
  border-right: none;
  border-left: 1px solid #f0f0f0;
}
.ant-cascader-menu-rtl .ant-cascader-menu:first-child {
  border-radius: 0 20px 20px 0;
}
.ant-cascader-menu-rtl .ant-cascader-menu:last-child {
  margin-right: 0;
  margin-left: -1px;
  border-left-color: transparent;
  border-radius: 20px 0 0 20px;
}
.ant-cascader-menu-rtl .ant-cascader-menu:only-child {
  border-radius: 20px;
}
.ant-cascader-menu-rtl .ant-cascader-menu-item-expand {
  padding-right: 12px;
  padding-left: 24px;
}
.ant-cascader-menu-rtl .ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
.ant-cascader-menu-rtl .ant-cascader-menu-item-loading-icon {
  right: auto;
  left: 12px;
}
.ant-cascader-menu-rtl .ant-cascader-menu-item-loading-icon {
  transform: scaleY(-1);
}
@keyframes antCheckboxEffect {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
.ant-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  top: -0.09em;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  outline: none;
  cursor: pointer;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #00441F;
}
.ant-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #00441F;
  border-radius: 20px;
  visibility: hidden;
  animation: antCheckboxEffect 0.36s ease-in-out;
  animation-fill-mode: backwards;
  content: '';
}
.ant-checkbox:hover::after,
.ant-checkbox-wrapper:hover .ant-checkbox::after {
  visibility: visible;
}
.ant-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  direction: ltr;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 20px;
  border-collapse: separate;
  transition: all 0.3s;
}
.ant-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 22%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
.ant-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.ant-checkbox-checked .ant-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #00441F;
  border-color: #00441F;
}
.ant-checkbox-disabled {
  cursor: not-allowed;
}
.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  animation-name: none;
}
.ant-checkbox-disabled .ant-checkbox-input {
  cursor: not-allowed;
}
.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}
.ant-checkbox-disabled .ant-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  animation-name: none;
}
.ant-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-checkbox-disabled:hover::after,
.ant-checkbox-wrapper:hover .ant-checkbox-disabled::after {
  visibility: hidden;
}
.ant-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
  line-height: unset;
  cursor: pointer;
}
.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
  cursor: not-allowed;
}
.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 8px;
}
.ant-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
}
.ant-checkbox-group-item {
  display: inline-block;
  margin-right: 8px;
}
.ant-checkbox-group-item:last-child {
  margin-right: 0;
}
.ant-checkbox-group-item + .ant-checkbox-group-item {
  margin-left: 0;
}
.ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #00441F;
  border: 0;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
.ant-checkbox-indeterminate.ant-checkbox-disabled .ant-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-checkbox-rtl {
  direction: rtl;
}
.ant-checkbox-group-rtl .ant-checkbox-group-item {
  margin-right: 0;
  margin-left: 8px;
}
.ant-checkbox-group-rtl .ant-checkbox-group-item:last-child {
  margin-left: 0 !important;
}
.ant-checkbox-group-rtl .ant-checkbox-group-item + .ant-checkbox-group-item {
  margin-left: 8px;
}
.ant-collapse {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-bottom: 0;
  border-radius: 20px;
}
.ant-collapse > .ant-collapse-item {
  border-bottom: 1px solid #d9d9d9;
}
.ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  border-radius: 0 0 20px 20px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  position: relative;
  padding: 12px 16px;
  padding-left: 40px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.5715;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header::before {
  display: table;
  content: '';
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header::after {
  display: table;
  clear: both;
  content: '';
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header::before {
  display: table;
  content: '';
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header::after {
  display: table;
  clear: both;
  content: '';
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 5.0005px;
  left: 16px;
  display: inline-block;
  padding: 12px 16px;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  font-size: 12px;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow > * {
  line-height: 1;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
  display: inline-block;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow::before {
  display: none;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow-icon {
  display: block;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
  transition: transform 0.24s;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
  float: right;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header:focus {
  outline: none;
}
.ant-collapse > .ant-collapse-item .ant-collapse-header-collapsible-only {
  cursor: default;
}
.ant-collapse > .ant-collapse-item .ant-collapse-header-collapsible-only .ant-collapse-header-text {
  cursor: pointer;
}
.ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
  padding-left: 12px;
}
.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
  padding: 12px 16px;
  padding-right: 40px;
}
.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  right: 16px;
  left: auto;
}
.ant-collapse-content {
  color: rgba(0, 0, 0, 0.85);
  background-color: #fff;
  border-top: 1px solid #d9d9d9;
}
.ant-collapse-content > .ant-collapse-content-box {
  padding: 16px;
}
.ant-collapse-content-hidden {
  display: none;
}
.ant-collapse-item:last-child > .ant-collapse-content {
  border-radius: 0 0 20px 20px;
}
.ant-collapse-borderless {
  background-color: #fafafa;
  border: 0;
}
.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 1px solid #d9d9d9;
}
.ant-collapse-borderless > .ant-collapse-item:last-child,
.ant-collapse-borderless > .ant-collapse-item:last-child .ant-collapse-header {
  border-radius: 0;
}
.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
  background-color: transparent;
  border-top: 0;
}
.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-top: 4px;
}
.ant-collapse-ghost {
  background-color: transparent;
  border: 0;
}
.ant-collapse-ghost > .ant-collapse-item {
  border-bottom: 0;
}
.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content {
  background-color: transparent;
  border-top: 0;
}
.ant-collapse-ghost > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding-top: 12px;
  padding-bottom: 12px;
}
.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header,
.ant-collapse .ant-collapse-item-disabled > .ant-collapse-header > .arrow {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-collapse-rtl {
  direction: rtl;
}
.ant-collapse-rtl .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 12px 16px;
  padding-right: 40px;
}
.ant-collapse-rtl.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg {
  transform: rotate(180deg);
}
.ant-collapse-rtl.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-extra {
  float: left;
}
.ant-collapse-rtl.ant-collapse > .ant-collapse-item.ant-collapse-no-arrow > .ant-collapse-header {
  padding-right: 12px;
  padding-left: 0;
}
.ant-comment {
  position: relative;
  background-color: inherit;
}
.ant-comment-inner {
  display: flex;
  padding: 16px 0;
}
.ant-comment-avatar {
  position: relative;
  flex-shrink: 0;
  margin-right: 12px;
  cursor: pointer;
}
.ant-comment-avatar img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}
.ant-comment-content {
  position: relative;
  flex: 1 1 auto;
  min-width: 1px;
  font-size: 14px;
  word-wrap: break-word;
}
.ant-comment-content-author {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 4px;
  font-size: 14px;
}
.ant-comment-content-author > a,
.ant-comment-content-author > span {
  padding-right: 8px;
  font-size: 12px;
  line-height: 18px;
}
.ant-comment-content-author-name {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  transition: color 0.3s;
}
.ant-comment-content-author-name > * {
  color: rgba(0, 0, 0, 0.45);
}
.ant-comment-content-author-name > *:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-comment-content-author-time {
  color: #ccc;
  white-space: nowrap;
  cursor: auto;
}
.ant-comment-content-detail p {
  margin-bottom: inherit;
  white-space: pre-wrap;
}
.ant-comment-actions {
  margin-top: 12px;
  margin-bottom: inherit;
  padding-left: 0;
}
.ant-comment-actions > li {
  display: inline-block;
  color: rgba(0, 0, 0, 0.45);
}
.ant-comment-actions > li > span {
  margin-right: 10px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  cursor: pointer;
  transition: color 0.3s;
  user-select: none;
}
.ant-comment-actions > li > span:hover {
  color: #595959;
}
.ant-comment-nested {
  margin-left: 44px;
}
.ant-comment-rtl {
  direction: rtl;
}
.ant-comment-rtl .ant-comment-avatar {
  margin-right: 0;
  margin-left: 12px;
}
.ant-comment-rtl .ant-comment-content-author > a,
.ant-comment-rtl .ant-comment-content-author > span {
  padding-right: 0;
  padding-left: 8px;
}
.ant-comment-rtl .ant-comment-actions {
  padding-right: 0;
}
.ant-comment-rtl .ant-comment-actions > li > span {
  margin-right: 0;
  margin-left: 10px;
}
.ant-comment-rtl .ant-comment-nested {
  margin-right: 44px;
  margin-left: 0;
}
.ant-picker {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  padding: 4px 11px 4px;
  position: relative;
  display: inline-flex;
  align-items: center;
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 20px;
  transition: border 0.3s, box-shadow 0.3s;
}
.ant-picker:hover,
.ant-picker-focused {
  border-color: #0d522a;
  border-right-width: 1px !important;
}
.ant-input-rtl .ant-picker:hover,
.ant-input-rtl .ant-picker-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-picker-focused {
  border-color: #0d522a;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(0, 68, 31, 0.2);
}
.ant-input-rtl .ant-picker-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-picker.ant-picker-disabled {
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-picker.ant-picker-disabled .ant-picker-suffix {
  color: rgba(0, 0, 0, 0.25);
}
.ant-picker.ant-picker-borderless {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}
.ant-picker-input {
  position: relative;
  display: inline-flex;
  align-items: center;
  width: 100%;
}
.ant-picker-input > input {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #AAAAAA;
  border-radius: 20px;
  transition: all 0.3s;
  flex: auto;
  min-width: 1px;
  height: auto;
  padding: 0;
  background: transparent;
  border: 0;
}
.ant-picker-input > input::-moz-placeholder {
  opacity: 1;
}
.ant-picker-input > input::placeholder {
  color: #bfbfbf;
}
.ant-picker-input > input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-picker-input > input:hover {
  border-color: #0d522a;
  border-right-width: 1px !important;
}
.ant-input-rtl .ant-picker-input > input:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-picker-input > input:focus,
.ant-picker-input > input-focused {
  border-color: #0d522a;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(0, 68, 31, 0.2);
}
.ant-input-rtl .ant-picker-input > input:focus,
.ant-input-rtl .ant-picker-input > input-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-picker-input > input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-picker-input > input-disabled:hover {
  border-color: #AAAAAA;
  border-right-width: 1px !important;
}
.ant-picker-input > input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-picker-input > input[disabled]:hover {
  border-color: #AAAAAA;
  border-right-width: 1px !important;
}
.ant-picker-input > input-borderless,
.ant-picker-input > input-borderless:hover,
.ant-picker-input > input-borderless:focus,
.ant-picker-input > input-borderless-focused,
.ant-picker-input > input-borderless-disabled,
.ant-picker-input > input-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ant-picker-input > input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-picker-input > input-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}
.ant-picker-input > input-sm {
  padding: 0px 7px;
}
.ant-picker-input > input-rtl {
  direction: rtl;
}
.ant-picker-input > input:focus {
  box-shadow: none;
}
.ant-picker-input > input[disabled] {
  background: transparent;
}
.ant-picker-input:hover .ant-picker-clear {
  opacity: 1;
}
.ant-picker-input-placeholder > input {
  color: #bfbfbf;
}
.ant-picker-large {
  padding: 6.5px 11px 6.5px;
}
.ant-picker-large .ant-picker-input > input {
  font-size: 16px;
}
.ant-picker-small {
  padding: 0px 7px 0px;
}
.ant-picker-suffix {
  align-self: center;
  margin-left: 4px;
  color: rgba(0, 0, 0, 0.25);
  line-height: 1;
  pointer-events: none;
}
.ant-picker-suffix > * {
  vertical-align: top;
}
.ant-picker-clear {
  position: absolute;
  top: 50%;
  right: 0;
  color: rgba(0, 0, 0, 0.25);
  line-height: 1;
  background: #fff;
  transform: translateY(-50%);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s, color 0.3s;
}
.ant-picker-clear > * {
  vertical-align: top;
}
.ant-picker-clear:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-picker-separator {
  position: relative;
  display: inline-block;
  width: 1em;
  height: 16px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 16px;
  vertical-align: top;
  cursor: default;
}
.ant-picker-focused .ant-picker-separator {
  color: rgba(0, 0, 0, 0.45);
}
.ant-picker-disabled .ant-picker-range-separator .ant-picker-separator {
  cursor: not-allowed;
}
.ant-picker-range {
  position: relative;
  display: inline-flex;
}
.ant-picker-range .ant-picker-clear {
  right: 11px;
}
.ant-picker-range:hover .ant-picker-clear {
  opacity: 1;
}
.ant-picker-range .ant-picker-active-bar {
  bottom: -1px;
  height: 2px;
  margin-left: 11px;
  background: #00441F;
  opacity: 0;
  transition: all 0.3s ease-out;
  pointer-events: none;
}
.ant-picker-range.ant-picker-focused .ant-picker-active-bar {
  opacity: 1;
}
.ant-picker-range-separator {
  align-items: center;
  padding: 0 8px;
  line-height: 1;
}
.ant-picker-range.ant-picker-small .ant-picker-clear {
  right: 7px;
}
.ant-picker-range.ant-picker-small .ant-picker-active-bar {
  margin-left: 7px;
}
.ant-picker-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  z-index: 1050;
}
.ant-picker-dropdown-hidden {
  display: none;
}
.ant-picker-dropdown-placement-bottomLeft .ant-picker-range-arrow {
  top: 1.66666667px;
  display: block;
  transform: rotate(-45deg);
}
.ant-picker-dropdown-placement-topLeft .ant-picker-range-arrow {
  bottom: 1.66666667px;
  display: block;
  transform: rotate(135deg);
}
.ant-picker-dropdown.slide-up-enter.slide-up-enter-active.ant-picker-dropdown-placement-topLeft,
.ant-picker-dropdown.slide-up-enter.slide-up-enter-active.ant-picker-dropdown-placement-topRight,
.ant-picker-dropdown.slide-up-appear.slide-up-appear-active.ant-picker-dropdown-placement-topLeft,
.ant-picker-dropdown.slide-up-appear.slide-up-appear-active.ant-picker-dropdown-placement-topRight {
  animation-name: antSlideDownIn;
}
.ant-picker-dropdown.slide-up-enter.slide-up-enter-active.ant-picker-dropdown-placement-bottomLeft,
.ant-picker-dropdown.slide-up-enter.slide-up-enter-active.ant-picker-dropdown-placement-bottomRight,
.ant-picker-dropdown.slide-up-appear.slide-up-appear-active.ant-picker-dropdown-placement-bottomLeft,
.ant-picker-dropdown.slide-up-appear.slide-up-appear-active.ant-picker-dropdown-placement-bottomRight {
  animation-name: antSlideUpIn;
}
.ant-picker-dropdown.slide-up-leave.slide-up-leave-active.ant-picker-dropdown-placement-topLeft,
.ant-picker-dropdown.slide-up-leave.slide-up-leave-active.ant-picker-dropdown-placement-topRight {
  animation-name: antSlideDownOut;
}
.ant-picker-dropdown.slide-up-leave.slide-up-leave-active.ant-picker-dropdown-placement-bottomLeft,
.ant-picker-dropdown.slide-up-leave.slide-up-leave-active.ant-picker-dropdown-placement-bottomRight {
  animation-name: antSlideUpOut;
}
.ant-picker-dropdown-range {
  padding: 6.66666667px 0;
}
.ant-picker-dropdown-range-hidden {
  display: none;
}
.ant-picker-dropdown .ant-picker-panel > .ant-picker-time-panel {
  padding-top: 4px;
}
.ant-picker-ranges {
  margin-bottom: 0;
  padding: 4px 12px;
  overflow: hidden;
  line-height: 34px;
  text-align: left;
  list-style: none;
}
.ant-picker-ranges > li {
  display: inline-block;
}
.ant-picker-ranges .ant-picker-preset > .ant-tag-blue {
  color: #00441F;
  background: #77857b;
  border-color: #336b47;
  cursor: pointer;
}
.ant-picker-ranges .ant-picker-ok {
  float: right;
  margin-left: 8px;
}
.ant-picker-range-wrapper {
  display: flex;
}
.ant-picker-range-arrow {
  position: absolute;
  z-index: 1;
  display: none;
  width: 10px;
  height: 10px;
  margin-left: 16.5px;
  box-shadow: 2px -2px 6px rgba(0, 0, 0, 0.06);
  transition: left 0.3s ease-out;
}
.ant-picker-range-arrow::after {
  position: absolute;
  top: 1px;
  right: 1px;
  width: 10px;
  height: 10px;
  border: 5px solid #f0f0f0;
  border-color: #fff #fff transparent transparent;
  content: '';
}
.ant-picker-panel-container {
  overflow: hidden;
  vertical-align: top;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  transition: margin 0.3s;
}
.ant-picker-panel-container .ant-picker-panels {
  display: inline-flex;
  flex-wrap: nowrap;
  direction: ltr;
}
.ant-picker-panel-container .ant-picker-panel {
  vertical-align: top;
  background: transparent;
  border-width: 0 0 1px 0;
  border-radius: 0;
}
.ant-picker-panel-container .ant-picker-panel-focused {
  border-color: #f0f0f0;
}
.ant-picker-panel {
  display: inline-flex;
  flex-direction: column;
  text-align: center;
  background: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 20px;
  outline: none;
}
.ant-picker-panel-focused {
  border-color: #00441F;
}
.ant-picker-decade-panel,
.ant-picker-year-panel,
.ant-picker-quarter-panel,
.ant-picker-month-panel,
.ant-picker-week-panel,
.ant-picker-date-panel,
.ant-picker-time-panel {
  display: flex;
  flex-direction: column;
  width: 280px;
}
.ant-picker-header {
  display: flex;
  padding: 0 8px;
  color: rgba(0, 0, 0, 0.85);
  border-bottom: 1px solid #f0f0f0;
}
.ant-picker-header > * {
  flex: none;
}
.ant-picker-header button {
  padding: 0;
  color: rgba(0, 0, 0, 0.25);
  line-height: 40px;
  background: transparent;
  border: 0;
  cursor: pointer;
  transition: color 0.3s;
}
.ant-picker-header > button {
  min-width: 1.6em;
  font-size: 14px;
}
.ant-picker-header > button:hover {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-header-view {
  flex: auto;
  font-weight: 500;
  line-height: 40px;
}
.ant-picker-header-view button {
  color: inherit;
  font-weight: inherit;
}
.ant-picker-header-view button:not(:first-child) {
  margin-left: 8px;
}
.ant-picker-header-view button:hover {
  color: #00441F;
}
.ant-picker-prev-icon,
.ant-picker-next-icon,
.ant-picker-super-prev-icon,
.ant-picker-super-next-icon {
  position: relative;
  display: inline-block;
  width: 7px;
  height: 7px;
}
.ant-picker-prev-icon::before,
.ant-picker-next-icon::before,
.ant-picker-super-prev-icon::before,
.ant-picker-super-next-icon::before {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 7px;
  height: 7px;
  border: 0 solid currentColor;
  border-width: 1.5px 0 0 1.5px;
  content: '';
}
.ant-picker-super-prev-icon::after,
.ant-picker-super-next-icon::after {
  position: absolute;
  top: 4px;
  left: 4px;
  display: inline-block;
  width: 7px;
  height: 7px;
  border: 0 solid currentColor;
  border-width: 1.5px 0 0 1.5px;
  content: '';
}
.ant-picker-prev-icon,
.ant-picker-super-prev-icon {
  transform: rotate(-45deg);
}
.ant-picker-next-icon,
.ant-picker-super-next-icon {
  transform: rotate(135deg);
}
.ant-picker-content {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}
.ant-picker-content th,
.ant-picker-content td {
  position: relative;
  min-width: 24px;
  font-weight: 400;
}
.ant-picker-content th {
  height: 30px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 30px;
}
.ant-picker-cell {
  padding: 3px 0;
  color: rgba(0, 0, 0, 0.25);
  cursor: pointer;
}
.ant-picker-cell-in-view {
  color: rgba(0, 0, 0, 0.85);
}
.ant-picker-cell-disabled {
  cursor: not-allowed;
}
.ant-picker-cell::before {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  z-index: 1;
  height: 24px;
  transform: translateY(-50%);
  content: '';
}
.ant-picker-cell .ant-picker-cell-inner {
  position: relative;
  z-index: 2;
  display: inline-block;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 20px;
  transition: background 0.3s, border 0.3s;
}
.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
  background: #f5f5f5;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  border: 1px solid #00441F;
  border-radius: 20px;
  content: '';
}
.ant-picker-cell-in-view.ant-picker-cell-in-range {
  position: relative;
}
.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: #77857b;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  color: #fff;
  background: #00441F;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  background: #77857b;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start::before {
  left: 50%;
}
.ant-picker-cell-in-view.ant-picker-cell-range-end::before {
  right: 50%;
}
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range)::after {
  position: absolute;
  top: 50%;
  z-index: 0;
  height: 24px;
  border-top: 1px dashed #00aa4e;
  border-bottom: 1px dashed #00aa4e;
  transform: translateY(-50%);
  content: '';
}
.ant-picker-cell-range-hover-start::after,
.ant-picker-cell-range-hover-end::after,
.ant-picker-cell-range-hover::after {
  right: 0;
  left: 2px;
}
.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end::before,
.ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
.ant-picker-panel > :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before {
  background: #00f670;
}
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) .ant-picker-cell-inner {
  border-radius: 20px 0 0 20px;
}
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start) .ant-picker-cell-inner {
  border-radius: 0 20px 20px 0;
}
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after,
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: -1;
  background: #00f670;
  content: '';
}
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after {
  right: -6px;
  left: 0;
}
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
  right: 0;
  left: -6px;
}
.ant-picker-cell-range-hover.ant-picker-cell-range-start::after {
  right: 50%;
}
.ant-picker-cell-range-hover.ant-picker-cell-range-end::after {
  left: 50%;
}
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child::after,
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
.ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
  left: 6px;
  border-left: 1px dashed #00aa4e;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child::after,
tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
.ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
  right: 6px;
  border-right: 1px dashed #00aa4e;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.ant-picker-cell-disabled {
  pointer-events: none;
}
.ant-picker-cell-disabled .ant-picker-cell-inner {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
}
.ant-picker-cell-disabled::before {
  background: #f5f5f5;
}
.ant-picker-cell-disabled.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-picker-decade-panel .ant-picker-content,
.ant-picker-year-panel .ant-picker-content,
.ant-picker-quarter-panel .ant-picker-content,
.ant-picker-month-panel .ant-picker-content {
  height: 264px;
}
.ant-picker-decade-panel .ant-picker-cell-inner,
.ant-picker-year-panel .ant-picker-cell-inner,
.ant-picker-quarter-panel .ant-picker-cell-inner,
.ant-picker-month-panel .ant-picker-cell-inner {
  padding: 0 8px;
}
.ant-picker-decade-panel .ant-picker-cell-disabled .ant-picker-cell-inner,
.ant-picker-year-panel .ant-picker-cell-disabled .ant-picker-cell-inner,
.ant-picker-quarter-panel .ant-picker-cell-disabled .ant-picker-cell-inner,
.ant-picker-month-panel .ant-picker-cell-disabled .ant-picker-cell-inner {
  background: #f5f5f5;
}
.ant-picker-quarter-panel .ant-picker-content {
  height: 56px;
}
.ant-picker-footer {
  width: min-content;
  min-width: 100%;
  line-height: 38px;
  text-align: center;
  border-bottom: 1px solid transparent;
}
.ant-picker-panel .ant-picker-footer {
  border-top: 1px solid #f0f0f0;
}
.ant-picker-footer-extra {
  padding: 0 12px;
  line-height: 38px;
  text-align: left;
}
.ant-picker-footer-extra:not(:last-child) {
  border-bottom: 1px solid #f0f0f0;
}
.ant-picker-now {
  text-align: left;
}
.ant-picker-today-btn {
  color: #00441F;
}
.ant-picker-today-btn:hover {
  color: #0d522a;
}
.ant-picker-today-btn:active {
  color: #001f0f;
}
.ant-picker-today-btn.ant-picker-today-btn-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-picker-decade-panel .ant-picker-cell-inner {
  padding: 0 4px;
}
.ant-picker-decade-panel .ant-picker-cell::before {
  display: none;
}
.ant-picker-year-panel .ant-picker-body,
.ant-picker-quarter-panel .ant-picker-body,
.ant-picker-month-panel .ant-picker-body {
  padding: 0 8px;
}
.ant-picker-year-panel .ant-picker-cell-inner,
.ant-picker-quarter-panel .ant-picker-cell-inner,
.ant-picker-month-panel .ant-picker-cell-inner {
  width: 60px;
}
.ant-picker-year-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-quarter-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-month-panel .ant-picker-cell-range-hover-start::after {
  left: 14px;
  border-left: 1px dashed #00aa4e;
  border-radius: 20px 0 0 20px;
}
.ant-picker-panel-rtl .ant-picker-year-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-panel-rtl .ant-picker-quarter-panel .ant-picker-cell-range-hover-start::after,
.ant-picker-panel-rtl .ant-picker-month-panel .ant-picker-cell-range-hover-start::after {
  right: 14px;
  border-right: 1px dashed #00aa4e;
  border-radius: 0 20px 20px 0;
}
.ant-picker-year-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-quarter-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-month-panel .ant-picker-cell-range-hover-end::after {
  right: 14px;
  border-right: 1px dashed #00aa4e;
  border-radius: 0 20px 20px 0;
}
.ant-picker-panel-rtl .ant-picker-year-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-panel-rtl .ant-picker-quarter-panel .ant-picker-cell-range-hover-end::after,
.ant-picker-panel-rtl .ant-picker-month-panel .ant-picker-cell-range-hover-end::after {
  left: 14px;
  border-left: 1px dashed #00aa4e;
  border-radius: 20px 0 0 20px;
}
.ant-picker-week-panel .ant-picker-body {
  padding: 8px 12px;
}
.ant-picker-week-panel .ant-picker-cell:hover .ant-picker-cell-inner,
.ant-picker-week-panel .ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-week-panel .ant-picker-cell .ant-picker-cell-inner {
  background: transparent !important;
}
.ant-picker-week-panel-row td {
  transition: background 0.3s;
}
.ant-picker-week-panel-row:hover td {
  background: #f5f5f5;
}
.ant-picker-week-panel-row-selected td,
.ant-picker-week-panel-row-selected:hover td {
  background: #00441F;
}
.ant-picker-week-panel-row-selected td.ant-picker-cell-week,
.ant-picker-week-panel-row-selected:hover td.ant-picker-cell-week {
  color: rgba(255, 255, 255, 0.5);
}
.ant-picker-week-panel-row-selected td.ant-picker-cell-today .ant-picker-cell-inner::before,
.ant-picker-week-panel-row-selected:hover td.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-color: #fff;
}
.ant-picker-week-panel-row-selected td .ant-picker-cell-inner,
.ant-picker-week-panel-row-selected:hover td .ant-picker-cell-inner {
  color: #fff;
}
.ant-picker-date-panel .ant-picker-body {
  padding: 8px 12px;
}
.ant-picker-date-panel .ant-picker-content {
  width: 252px;
}
.ant-picker-date-panel .ant-picker-content th {
  width: 36px;
}
.ant-picker-datetime-panel {
  display: flex;
}
.ant-picker-datetime-panel .ant-picker-time-panel {
  border-left: 1px solid #f0f0f0;
}
.ant-picker-datetime-panel .ant-picker-date-panel,
.ant-picker-datetime-panel .ant-picker-time-panel {
  transition: opacity 0.3s;
}
.ant-picker-datetime-panel-active .ant-picker-date-panel,
.ant-picker-datetime-panel-active .ant-picker-time-panel {
  opacity: 0.3;
}
.ant-picker-datetime-panel-active .ant-picker-date-panel-active,
.ant-picker-datetime-panel-active .ant-picker-time-panel-active {
  opacity: 1;
}
.ant-picker-time-panel {
  width: auto;
  min-width: auto;
}
.ant-picker-time-panel .ant-picker-content {
  display: flex;
  flex: auto;
  height: 224px;
}
.ant-picker-time-panel-column {
  flex: 1 0 auto;
  width: 56px;
  margin: 0;
  padding: 0;
  overflow-y: hidden;
  text-align: left;
  list-style: none;
  transition: background 0.3s;
}
.ant-picker-time-panel-column::after {
  display: block;
  height: 196px;
  content: '';
}
.ant-picker-datetime-panel .ant-picker-time-panel-column::after {
  height: 198px;
}
.ant-picker-time-panel-column:not(:first-child) {
  border-left: 1px solid #f0f0f0;
}
.ant-picker-time-panel-column-active {
  background: rgba(119, 133, 123, 0.2);
}
.ant-picker-time-panel-column:hover {
  overflow-y: auto;
}
.ant-picker-time-panel-column > li {
  margin: 0;
  padding: 0;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
  display: block;
  width: 100%;
  height: 28px;
  margin: 0;
  padding: 0 0 0 14px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 28px;
  border-radius: 0;
  cursor: pointer;
  transition: background 0.3s;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
  background: #f5f5f5;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
  background: #77857b;
}
.ant-picker-time-panel-column > li.ant-picker-time-panel-cell-disabled .ant-picker-time-panel-cell-inner {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  cursor: not-allowed;
}
/* stylelint-disable-next-line */
_:-ms-fullscreen .ant-picker-range-wrapper .ant-picker-month-panel .ant-picker-cell,
:root .ant-picker-range-wrapper .ant-picker-month-panel .ant-picker-cell,
_:-ms-fullscreen .ant-picker-range-wrapper .ant-picker-year-panel .ant-picker-cell,
:root .ant-picker-range-wrapper .ant-picker-year-panel .ant-picker-cell {
  padding: 21px 0;
}
.ant-picker-rtl {
  direction: rtl;
}
.ant-picker-rtl .ant-picker-suffix {
  margin-right: 4px;
  margin-left: 0;
}
.ant-picker-rtl .ant-picker-clear {
  right: auto;
  left: 0;
}
.ant-picker-rtl .ant-picker-separator {
  transform: rotate(180deg);
}
.ant-picker-panel-rtl .ant-picker-header-view button:not(:first-child) {
  margin-right: 8px;
  margin-left: 0;
}
.ant-picker-rtl.ant-picker-range .ant-picker-clear {
  right: auto;
  left: 11px;
}
.ant-picker-rtl.ant-picker-range .ant-picker-active-bar {
  margin-right: 11px;
  margin-left: 0;
}
.ant-picker-rtl.ant-picker-range.ant-picker-small .ant-picker-active-bar {
  margin-right: 7px;
}
.ant-picker-dropdown-rtl .ant-picker-ranges {
  text-align: right;
}
.ant-picker-dropdown-rtl .ant-picker-ranges .ant-picker-ok {
  float: left;
  margin-right: 8px;
  margin-left: 0;
}
.ant-picker-panel-rtl {
  direction: rtl;
}
.ant-picker-panel-rtl .ant-picker-prev-icon,
.ant-picker-panel-rtl .ant-picker-super-prev-icon {
  transform: rotate(135deg);
}
.ant-picker-panel-rtl .ant-picker-next-icon,
.ant-picker-panel-rtl .ant-picker-super-next-icon {
  transform: rotate(-45deg);
}
.ant-picker-cell .ant-picker-cell-inner {
  position: relative;
  z-index: 2;
  display: inline-block;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 20px;
  transition: background 0.3s, border 0.3s;
}
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-start::before {
  right: 50%;
  left: 0;
}
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-end::before {
  right: 0;
  left: 50%;
}
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-end::before {
  right: 50%;
  left: 50%;
}
.ant-picker-panel-rtl .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after {
  right: 0;
  left: -6px;
}
.ant-picker-panel-rtl .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
  right: -6px;
  left: 0;
}
.ant-picker-panel-rtl .ant-picker-cell-range-hover.ant-picker-cell-range-start::after {
  right: 0;
  left: 50%;
}
.ant-picker-panel-rtl .ant-picker-cell-range-hover.ant-picker-cell-range-end::after {
  right: 50%;
  left: 0;
}
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) .ant-picker-cell-inner {
  border-radius: 0 20px 20px 0;
}
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start) .ant-picker-cell-inner {
  border-radius: 20px 0 0 20px;
}
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-selected):first-child::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range)::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-start::after {
  right: 6px;
  left: 0;
  border-right: 1px dashed #00aa4e;
  border-left: none;
  border-top-left-radius: 0;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 0;
}
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-selected):last-child::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range)::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-end::after {
  right: 0;
  left: 6px;
  border-right: none;
  border-left: 1px dashed #00aa4e;
  border-top-left-radius: 20px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 20px;
}
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child::after,
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover)::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-end.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover)::after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-start.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover)::after,
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover.ant-picker-cell-range-hover-edge-start:last-child::after,
.ant-picker-panel-rtl tr > .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover.ant-picker-cell-range-hover-edge-end:first-child::after {
  right: 6px;
  left: 6px;
  border-right: 1px dashed #00aa4e;
  border-left: 1px dashed #00aa4e;
  border-radius: 20px;
}
.ant-picker-dropdown-rtl .ant-picker-footer-extra {
  direction: rtl;
  text-align: right;
}
.ant-picker-panel-rtl .ant-picker-time-panel {
  direction: ltr;
}
.ant-descriptions-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.ant-descriptions-title {
  flex: auto;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: bold;
  font-size: 16px;
  line-height: 1.5715;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-descriptions-extra {
  margin-left: auto;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
.ant-descriptions-view {
  width: 100%;
  overflow: hidden;
  border-radius: 20px;
}
.ant-descriptions-view table {
  width: 100%;
  table-layout: fixed;
}
.ant-descriptions-row > th,
.ant-descriptions-row > td {
  padding-bottom: 16px;
}
.ant-descriptions-row:last-child {
  border-bottom: none;
}
.ant-descriptions-item-label {
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5715;
  text-align: start;
}
.ant-descriptions-item-label::after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}
.ant-descriptions-item-label.ant-descriptions-item-no-colon::after {
  content: ' ';
}
.ant-descriptions-item-no-label::after {
  margin: 0;
  content: '';
}
.ant-descriptions-item-content {
  display: table-cell;
  flex: 1;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  word-break: break-word;
  overflow-wrap: break-word;
}
.ant-descriptions-item {
  padding-bottom: 0;
  vertical-align: top;
}
.ant-descriptions-item-container {
  display: flex;
}
.ant-descriptions-item-container .ant-descriptions-item-label,
.ant-descriptions-item-container .ant-descriptions-item-content {
  display: inline-flex;
  align-items: baseline;
}
.ant-descriptions-middle .ant-descriptions-row > th,
.ant-descriptions-middle .ant-descriptions-row > td {
  padding-bottom: 12px;
}
.ant-descriptions-small .ant-descriptions-row > th,
.ant-descriptions-small .ant-descriptions-row > td {
  padding-bottom: 8px;
}
.ant-descriptions-bordered .ant-descriptions-view {
  border: 1px solid #f0f0f0;
}
.ant-descriptions-bordered .ant-descriptions-view > table {
  table-layout: auto;
}
.ant-descriptions-bordered .ant-descriptions-item-label,
.ant-descriptions-bordered .ant-descriptions-item-content {
  padding: 16px 24px;
  border-right: 1px solid #f0f0f0;
}
.ant-descriptions-bordered .ant-descriptions-item-label:last-child,
.ant-descriptions-bordered .ant-descriptions-item-content:last-child {
  border-right: none;
}
.ant-descriptions-bordered .ant-descriptions-item-label {
  background-color: #fafafa;
}
.ant-descriptions-bordered .ant-descriptions-item-label::after {
  display: none;
}
.ant-descriptions-bordered .ant-descriptions-row {
  border-bottom: 1px solid #f0f0f0;
}
.ant-descriptions-bordered .ant-descriptions-row:last-child {
  border-bottom: none;
}
.ant-descriptions-bordered.ant-descriptions-middle .ant-descriptions-item-label,
.ant-descriptions-bordered.ant-descriptions-middle .ant-descriptions-item-content {
  padding: 12px 24px;
}
.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-label,
.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-content {
  padding: 8px 16px;
}
.ant-descriptions-rtl {
  direction: rtl;
}
.ant-descriptions-rtl .ant-descriptions-item-label::after {
  margin: 0 2px 0 8px;
}
.ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-label,
.ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-content {
  border-right: none;
  border-left: 1px solid #f0f0f0;
}
.ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-label:last-child,
.ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-content:last-child {
  border-left: none;
}
.ant-divider {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}
.ant-divider-vertical {
  position: relative;
  top: -0.06em;
  display: inline-block;
  height: 0.9em;
  margin: 0 8px;
  vertical-align: middle;
  border-top: 0;
  border-left: 1px solid rgba(0, 0, 0, 0.06);
}
.ant-divider-horizontal {
  display: flex;
  clear: both;
  width: 100%;
  min-width: 100%;
  margin: 24px 0;
}
.ant-divider-horizontal.ant-divider-with-text {
  display: flex;
  margin: 16px 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  white-space: nowrap;
  text-align: center;
  border-top: 0;
  border-top-color: rgba(0, 0, 0, 0.06);
}
.ant-divider-horizontal.ant-divider-with-text::before,
.ant-divider-horizontal.ant-divider-with-text::after {
  position: relative;
  top: 50%;
  width: 50%;
  border-top: 1px solid transparent;
  border-top-color: inherit;
  border-bottom: 0;
  transform: translateY(50%);
  content: '';
}
.ant-divider-horizontal.ant-divider-with-text-left::before {
  top: 50%;
  width: 5%;
}
.ant-divider-horizontal.ant-divider-with-text-left::after {
  top: 50%;
  width: 95%;
}
.ant-divider-horizontal.ant-divider-with-text-right::before {
  top: 50%;
  width: 95%;
}
.ant-divider-horizontal.ant-divider-with-text-right::after {
  top: 50%;
  width: 5%;
}
.ant-divider-inner-text {
  display: inline-block;
  padding: 0 1em;
}
.ant-divider-dashed {
  background: none;
  border-color: rgba(0, 0, 0, 0.06);
  border-style: dashed;
  border-width: 1px 0 0;
}
.ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed {
  border-top: 0;
}
.ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed::before,
.ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed::after {
  border-style: dashed none none;
}
.ant-divider-vertical.ant-divider-dashed {
  border-width: 0 0 0 1px;
}
.ant-divider-plain.ant-divider-with-text {
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
}
.ant-divider-rtl {
  direction: rtl;
}
.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-left::before {
  width: 95%;
}
.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-left::after {
  width: 5%;
}
.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-right::before {
  width: 5%;
}
.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-right::after {
  width: 95%;
}
.ant-drawer {
  position: fixed;
  z-index: 1000;
  width: 0%;
  height: 100%;
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), height 0s ease 0.3s, width 0s ease 0.3s;
}
.ant-drawer > * {
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1), box-shadow 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.ant-drawer-content-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
}
.ant-drawer .ant-drawer-content {
  width: 100%;
  height: 100%;
}
.ant-drawer-left,
.ant-drawer-right {
  top: 0;
  width: 0%;
  height: 100%;
}
.ant-drawer-left .ant-drawer-content-wrapper,
.ant-drawer-right .ant-drawer-content-wrapper {
  height: 100%;
}
.ant-drawer-left.ant-drawer-open,
.ant-drawer-right.ant-drawer-open {
  width: 100%;
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.ant-drawer-left {
  left: 0;
}
.ant-drawer-left .ant-drawer-content-wrapper {
  left: 0;
}
.ant-drawer-left.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: 6px 0 16px -8px rgba(0, 0, 0, 0.08), 9px 0 28px 0 rgba(0, 0, 0, 0.05), 12px 0 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-right {
  right: 0;
}
.ant-drawer-right .ant-drawer-content-wrapper {
  right: 0;
}
.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: -6px 0 16px -8px rgba(0, 0, 0, 0.08), -9px 0 28px 0 rgba(0, 0, 0, 0.05), -12px 0 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-right.ant-drawer-open.no-mask {
  right: 1px;
  transform: translateX(1px);
}
.ant-drawer-top,
.ant-drawer-bottom {
  left: 0;
  width: 100%;
  height: 0%;
}
.ant-drawer-top .ant-drawer-content-wrapper,
.ant-drawer-bottom .ant-drawer-content-wrapper {
  width: 100%;
}
.ant-drawer-top.ant-drawer-open,
.ant-drawer-bottom.ant-drawer-open {
  height: 100%;
  transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
.ant-drawer-top {
  top: 0;
}
.ant-drawer-top.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: 0 6px 16px -8px rgba(0, 0, 0, 0.08), 0 9px 28px 0 rgba(0, 0, 0, 0.05), 0 12px 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-bottom {
  bottom: 0;
}
.ant-drawer-bottom .ant-drawer-content-wrapper {
  bottom: 0;
}
.ant-drawer-bottom.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: 0 -6px 16px -8px rgba(0, 0, 0, 0.08), 0 -9px 28px 0 rgba(0, 0, 0, 0.05), 0 -12px 48px 16px rgba(0, 0, 0, 0.03);
}
.ant-drawer-bottom.ant-drawer-open.no-mask {
  bottom: 1px;
  transform: translateY(1px);
}
.ant-drawer.ant-drawer-open .ant-drawer-mask {
  height: 100%;
  opacity: 1;
  transition: none;
  animation: antdDrawerFadeIn 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  pointer-events: auto;
}
.ant-drawer-title {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
.ant-drawer-content {
  position: relative;
  z-index: 1;
  overflow: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
}
.ant-drawer-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  display: block;
  padding: 20px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  font-size: 16px;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
  text-rendering: auto;
}
.ant-drawer-close:focus,
.ant-drawer-close:hover {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none;
}
.ant-drawer-header-no-title .ant-drawer-close {
  margin-right: var(--scroll-bar);
  /* stylelint-disable-next-line function-calc-no-invalid */
  padding-right: calc(20px - var(--scroll-bar));
}
.ant-drawer-header {
  position: relative;
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 20px 20px 0 0;
}
.ant-drawer-header-no-title {
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
}
.ant-drawer-wrapper-body {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%;
}
.ant-drawer-body {
  flex-grow: 1;
  padding: 24px;
  overflow: auto;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}
.ant-drawer-footer {
  flex-shrink: 0;
  padding: 10px 10px;
  border-top: 1px solid #f0f0f0;
}
.ant-drawer-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0;
  background-color: rgba(0, 0, 0, 0.45);
  opacity: 0;
  filter: alpha(opacity=45);
  transition: opacity 0.3s linear, height 0s ease 0.3s;
  pointer-events: none;
}
.ant-drawer-open-content {
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-drawer .ant-picker-clear {
  background: #fff;
}
@keyframes antdDrawerFadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.ant-drawer-rtl {
  direction: rtl;
}
.ant-drawer-rtl .ant-drawer-close {
  right: auto;
  left: 0;
}
.ant-dropdown-menu-item.ant-dropdown-menu-item-danger {
  color: #DD4E4E;
}
.ant-dropdown-menu-item.ant-dropdown-menu-item-danger:hover {
  color: #fff;
  background-color: #DD4E4E;
}
.ant-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  display: block;
}
.ant-dropdown::before {
  position: absolute;
  top: -4px;
  right: 0;
  bottom: -4px;
  left: -7px;
  z-index: -9999;
  opacity: 0.0001;
  content: ' ';
}
.ant-dropdown-wrap {
  position: relative;
}
.ant-dropdown-wrap .ant-btn > .anticon-down {
  font-size: 10px;
}
.ant-dropdown-wrap .anticon-down::before {
  transition: transform 0.2s;
}
.ant-dropdown-wrap-open .anticon-down::before {
  transform: rotate(180deg);
}
.ant-dropdown-hidden,
.ant-dropdown-menu-hidden {
  display: none;
}
.ant-dropdown-show-arrow.ant-dropdown-placement-topCenter,
.ant-dropdown-show-arrow.ant-dropdown-placement-topLeft,
.ant-dropdown-show-arrow.ant-dropdown-placement-topRight {
  padding-bottom: 10px;
}
.ant-dropdown-show-arrow.ant-dropdown-placement-bottomCenter,
.ant-dropdown-show-arrow.ant-dropdown-placement-bottomLeft,
.ant-dropdown-show-arrow.ant-dropdown-placement-bottomRight {
  padding-top: 10px;
}
.ant-dropdown-arrow {
  position: absolute;
  z-index: 1;
  display: block;
  width: 8.48528137px;
  height: 8.48528137px;
  background: transparent;
  border-style: solid;
  border-width: 4.24264069px;
  transform: rotate(45deg);
}
.ant-dropdown-placement-topCenter > .ant-dropdown-arrow,
.ant-dropdown-placement-topLeft > .ant-dropdown-arrow,
.ant-dropdown-placement-topRight > .ant-dropdown-arrow {
  bottom: 6.2px;
  border-top-color: transparent;
  border-right-color: #fff;
  border-bottom-color: #fff;
  border-left-color: transparent;
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
}
.ant-dropdown-placement-topCenter > .ant-dropdown-arrow {
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}
.ant-dropdown-placement-topLeft > .ant-dropdown-arrow {
  left: 16px;
}
.ant-dropdown-placement-topRight > .ant-dropdown-arrow {
  right: 16px;
}
.ant-dropdown-placement-bottomCenter > .ant-dropdown-arrow,
.ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow,
.ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
  top: 6px;
  border-top-color: #fff;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: #fff;
  box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);
}
.ant-dropdown-placement-bottomCenter > .ant-dropdown-arrow {
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}
.ant-dropdown-placement-bottomLeft > .ant-dropdown-arrow {
  left: 16px;
}
.ant-dropdown-placement-bottomRight > .ant-dropdown-arrow {
  right: 16px;
}
.ant-dropdown-menu {
  position: relative;
  margin: 0;
  padding: 4px 0;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 20px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-dropdown-menu-item-group-title {
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.45);
  transition: all 0.3s;
}
.ant-dropdown-menu-submenu-popup {
  position: absolute;
  z-index: 1050;
  background: transparent;
  box-shadow: none;
  transform-origin: 0 0;
}
.ant-dropdown-menu-submenu-popup ul,
.ant-dropdown-menu-submenu-popup li {
  list-style: none;
}
.ant-dropdown-menu-submenu-popup ul {
  margin-right: 0.3em;
  margin-left: 0.3em;
}
.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
  clear: both;
  margin: 0;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-dropdown-menu-item > .anticon:first-child,
.ant-dropdown-menu-submenu-title > .anticon:first-child,
.ant-dropdown-menu-item > a > .anticon:first-child,
.ant-dropdown-menu-submenu-title > a > .anticon:first-child,
.ant-dropdown-menu-item > span > .anticon:first-child,
.ant-dropdown-menu-submenu-title > span > .anticon:first-child {
  min-width: 12px;
  margin-right: 8px;
  font-size: 12px;
  vertical-align: -0.1em;
}
.ant-dropdown-menu-item > a,
.ant-dropdown-menu-submenu-title > a {
  display: block;
  margin: -5px -12px;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.85);
  transition: all 0.3s;
}
.ant-dropdown-menu-item > a:hover,
.ant-dropdown-menu-submenu-title > a:hover {
  color: rgba(0, 0, 0, 0.85);
}
.ant-dropdown-menu-item > .anticon + span > a,
.ant-dropdown-menu-submenu-title > .anticon + span > a {
  color: rgba(0, 0, 0, 0.85);
  transition: all 0.3s;
}
.ant-dropdown-menu-item > .anticon + span > a:hover,
.ant-dropdown-menu-submenu-title > .anticon + span > a:hover {
  color: rgba(0, 0, 0, 0.85);
}
.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected,
.ant-dropdown-menu-item-selected > a,
.ant-dropdown-menu-submenu-title-selected > a {
  color: #00441F;
  background-color: #77857b;
}
.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
  background-color: #f5f5f5;
}
.ant-dropdown-menu-item-disabled,
.ant-dropdown-menu-submenu-title-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-dropdown-menu-item-disabled:hover,
.ant-dropdown-menu-submenu-title-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}
.ant-dropdown-menu-item-disabled > a,
.ant-dropdown-menu-submenu-title-disabled > a {
  position: relative;
  color: rgba(0, 0, 0, 0.25);
  pointer-events: none;
}
.ant-dropdown-menu-item-disabled > a::after,
.ant-dropdown-menu-submenu-title-disabled > a::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: not-allowed;
  content: '';
}
.ant-dropdown-menu-item-divider,
.ant-dropdown-menu-submenu-title-divider {
  height: 1px;
  margin: 4px 0;
  overflow: hidden;
  line-height: 0;
  background-color: #f0f0f0;
}
.ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon {
  position: absolute;
  right: 8px;
}
.ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
  margin-right: 0 !important;
  color: rgba(0, 0, 0, 0.45);
  font-size: 10px;
  font-style: normal;
}
.ant-dropdown-menu-item-group-list {
  margin: 0 8px;
  padding: 0;
  list-style: none;
}
.ant-dropdown-menu-submenu-title {
  padding-right: 24px;
}
.ant-dropdown-menu-submenu-vertical {
  position: relative;
}
.ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
  position: absolute;
  top: 0;
  left: 100%;
  min-width: 100%;
  margin-left: 4px;
  transform-origin: 0 0;
}
.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}
.ant-dropdown-menu-submenu-selected .ant-dropdown-menu-submenu-title {
  color: #00441F;
}
.ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomCenter,
.ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomCenter,
.ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomRight,
.ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomRight {
  animation-name: antSlideUpIn;
}
.ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topLeft,
.ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topLeft,
.ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topCenter,
.ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topCenter,
.ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topRight,
.ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topRight {
  animation-name: antSlideDownIn;
}
.ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomCenter,
.ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomRight {
  animation-name: antSlideUpOut;
}
.ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topLeft,
.ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topCenter,
.ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topRight {
  animation-name: antSlideDownOut;
}
.ant-dropdown-trigger > .anticon.anticon-down,
.ant-dropdown-link > .anticon.anticon-down,
.ant-dropdown-button > .anticon.anticon-down {
  font-size: 10px;
  vertical-align: baseline;
}
.ant-dropdown-button {
  white-space: nowrap;
}
.ant-dropdown-button.ant-btn-group > .ant-btn:last-child:not(:first-child):not(.ant-btn-icon-only) {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-dropdown-menu-dark,
.ant-dropdown-menu-dark .ant-dropdown-menu {
  background: #001529;
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a {
  color: rgba(255, 255, 255, 0.65);
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a .ant-dropdown-menu-submenu-arrow::after,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a .ant-dropdown-menu-submenu-arrow::after {
  color: rgba(255, 255, 255, 0.65);
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > a:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item > .anticon + span > a:hover {
  color: #fff;
  background: transparent;
}
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected,
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected > a {
  color: #fff;
  background: #00441F;
}
.ant-dropdown-rtl {
  direction: rtl;
}
.ant-dropdown-rtl.ant-dropdown::before {
  right: -7px;
  left: 0;
}
.ant-dropdown-menu.ant-dropdown-menu-rtl {
  direction: rtl;
  text-align: right;
}
.ant-dropdown-rtl .ant-dropdown-menu-item-group-title {
  direction: rtl;
  text-align: right;
}
.ant-dropdown-menu-submenu-popup.ant-dropdown-menu-submenu-rtl {
  transform-origin: 100% 0;
}
.ant-dropdown-rtl .ant-dropdown-menu-submenu-popup ul,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-popup li {
  text-align: right;
}
.ant-dropdown-rtl .ant-dropdown-menu-item,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title {
  text-align: right;
}
.ant-dropdown-rtl .ant-dropdown-menu-item > .anticon:first-child,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title > .anticon:first-child,
.ant-dropdown-rtl .ant-dropdown-menu-item > span > .anticon:first-child,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title > span > .anticon:first-child {
  margin-right: 0;
  margin-left: 8px;
}
.ant-dropdown-rtl .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow {
  right: auto;
  left: 8px;
}
.ant-dropdown-rtl .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow-icon,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
  margin-left: 0 !important;
  transform: scaleX(-1);
}
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title {
  padding-right: 12px;
  padding-left: 24px;
}
.ant-dropdown-rtl .ant-dropdown-menu-submenu-vertical > .ant-dropdown-menu {
  right: 100%;
  left: 0;
  margin-right: 4px;
  margin-left: 0;
}
.ant-empty {
  margin: 0 8px;
  font-size: 14px;
  line-height: 1.5715;
  text-align: center;
}
.ant-empty-image {
  height: 100px;
  margin-bottom: 8px;
}
.ant-empty-image img {
  height: 100%;
}
.ant-empty-image svg {
  height: 100%;
  margin: auto;
}
.ant-empty-footer {
  margin-top: 16px;
}
.ant-empty-normal {
  margin: 32px 0;
  color: rgba(0, 0, 0, 0.25);
}
.ant-empty-normal .ant-empty-image {
  height: 40px;
}
.ant-empty-small {
  margin: 8px 0;
  color: rgba(0, 0, 0, 0.25);
}
.ant-empty-small .ant-empty-image {
  height: 35px;
}
.ant-empty-img-default-ellipse {
  fill-opacity: 0.8;
  fill: #f5f5f5;
}
.ant-empty-img-default-path-1 {
  fill: #aeb8c2;
}
.ant-empty-img-default-path-2 {
  fill: url(#linearGradient-1);
}
.ant-empty-img-default-path-3 {
  fill: #f5f5f7;
}
.ant-empty-img-default-path-4 {
  fill: #dce0e6;
}
.ant-empty-img-default-path-5 {
  fill: #dce0e6;
}
.ant-empty-img-default-g {
  fill: #fff;
}
.ant-empty-img-simple-ellipse {
  fill: #f5f5f5;
}
.ant-empty-img-simple-g {
  stroke: #d9d9d9;
}
.ant-empty-img-simple-path {
  fill: #fafafa;
}
.ant-empty-rtl {
  direction: rtl;
}
.ant-form-item .ant-mentions,
.ant-form-item textarea.ant-input {
  height: auto;
}
.ant-form-item .ant-upload {
  background: transparent;
}
.ant-form-item .ant-upload.ant-upload-drag {
  background: #fafafa;
}
.ant-form-item input[type='radio'],
.ant-form-item input[type='checkbox'] {
  width: 14px;
  height: 14px;
}
.ant-form-item .ant-radio-inline,
.ant-form-item .ant-checkbox-inline {
  display: inline-block;
  margin-left: 8px;
  font-weight: normal;
  vertical-align: middle;
  cursor: pointer;
}
.ant-form-item .ant-radio-inline:first-child,
.ant-form-item .ant-checkbox-inline:first-child {
  margin-left: 0;
}
.ant-form-item .ant-checkbox-vertical,
.ant-form-item .ant-radio-vertical {
  display: block;
}
.ant-form-item .ant-checkbox-vertical + .ant-checkbox-vertical,
.ant-form-item .ant-radio-vertical + .ant-radio-vertical {
  margin-left: 0;
}
.ant-form-item .ant-input-number + .ant-form-text {
  margin-left: 8px;
}
.ant-form-item .ant-input-number-handler-wrap {
  z-index: 2;
}
.ant-form-item .ant-select,
.ant-form-item .ant-cascader-picker {
  width: 100%;
}
.ant-form-item .ant-input-group .ant-select,
.ant-form-item .ant-input-group .ant-cascader-picker {
  width: auto;
}
.ant-form-inline {
  display: flex;
  flex-wrap: wrap;
}
.ant-form-inline .ant-form-item {
  flex: none;
  flex-wrap: nowrap;
  margin-right: 16px;
  margin-bottom: 0;
}
.ant-form-inline .ant-form-item-with-help {
  margin-bottom: 24px;
}
.ant-form-inline .ant-form-item > .ant-form-item-label,
.ant-form-inline .ant-form-item > .ant-form-item-control {
  display: inline-block;
  vertical-align: top;
}
.ant-form-inline .ant-form-item > .ant-form-item-label {
  flex: none;
}
.ant-form-inline .ant-form-item .ant-form-text {
  display: inline-block;
}
.ant-form-inline .ant-form-item .ant-form-item-has-feedback {
  display: inline-block;
}
.ant-form-horizontal .ant-form-item-label {
  flex-grow: 0;
}
.ant-form-horizontal .ant-form-item-control {
  flex: 1 1 0;
}
.ant-form-vertical .ant-form-item {
  flex-direction: column;
}
.ant-form-vertical .ant-form-item-label > label {
  height: auto;
}
.ant-form-vertical .ant-form-item-label,
.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label {
  padding: 0 0 8px;
  line-height: 1.5715;
  white-space: initial;
  text-align: left;
}
.ant-form-vertical .ant-form-item-label > label,
.ant-col-24.ant-form-item-label > label,
.ant-col-xl-24.ant-form-item-label > label {
  margin: 0;
}
.ant-form-vertical .ant-form-item-label > label::after,
.ant-col-24.ant-form-item-label > label::after,
.ant-col-xl-24.ant-form-item-label > label::after {
  display: none;
}
.ant-form-rtl.ant-form-vertical .ant-form-item-label,
.ant-form-rtl.ant-col-24.ant-form-item-label,
.ant-form-rtl.ant-col-xl-24.ant-form-item-label {
  text-align: right;
}
@media (max-width: 575px) {
  .ant-form-item .ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
  }
  .ant-form-item .ant-form-item-label > label {
    margin: 0;
  }
  .ant-form-item .ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-form-item .ant-form-item-label {
    text-align: right;
  }
  .ant-form .ant-form-item {
    flex-wrap: wrap;
  }
  .ant-form .ant-form-item .ant-form-item-label,
  .ant-form .ant-form-item .ant-form-item-control {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-xs-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
  }
  .ant-col-xs-24.ant-form-item-label > label {
    margin: 0;
  }
  .ant-col-xs-24.ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-col-xs-24.ant-form-item-label {
    text-align: right;
  }
}
@media (max-width: 767px) {
  .ant-col-sm-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
  }
  .ant-col-sm-24.ant-form-item-label > label {
    margin: 0;
  }
  .ant-col-sm-24.ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-col-sm-24.ant-form-item-label {
    text-align: right;
  }
}
@media (max-width: 991px) {
  .ant-col-md-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
  }
  .ant-col-md-24.ant-form-item-label > label {
    margin: 0;
  }
  .ant-col-md-24.ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-col-md-24.ant-form-item-label {
    text-align: right;
  }
}
@media (max-width: 1199px) {
  .ant-col-lg-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
  }
  .ant-col-lg-24.ant-form-item-label > label {
    margin: 0;
  }
  .ant-col-lg-24.ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-col-lg-24.ant-form-item-label {
    text-align: right;
  }
}
@media (max-width: 1599px) {
  .ant-col-xl-24.ant-form-item-label {
    padding: 0 0 8px;
    line-height: 1.5715;
    white-space: initial;
    text-align: left;
  }
  .ant-col-xl-24.ant-form-item-label > label {
    margin: 0;
  }
  .ant-col-xl-24.ant-form-item-label > label::after {
    display: none;
  }
  .ant-form-rtl.ant-col-xl-24.ant-form-item-label {
    text-align: right;
  }
}
.ant-form-item {
  /* Some non-status related component style is in `components.less` */
  /* To support leave along ErrorList. We add additional className to handle explain style */
}
.ant-form-item-explain.ant-form-item-explain-error {
  color: #DD4E4E;
}
.ant-form-item-explain.ant-form-item-explain-warning {
  color: #FFC107;
}
.ant-form-item-has-feedback .ant-input {
  padding-right: 24px;
}
.ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
  padding-right: 18px;
}
.ant-form-item-has-feedback .ant-input-search:not(.ant-input-search-enter-button) .ant-input-suffix {
  right: 28px;
}
.ant-form-item-has-feedback .ant-switch {
  margin: 2px 0 4px;
}
.ant-form-item-has-feedback > .ant-select .ant-select-arrow,
.ant-form-item-has-feedback > .ant-select .ant-select-clear,
.ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-arrow,
.ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-clear {
  right: 32px;
}
.ant-form-item-has-feedback > .ant-select .ant-select-selection-selected-value,
.ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection-selected-value {
  padding-right: 42px;
}
.ant-form-item-has-feedback .ant-cascader-picker-arrow {
  margin-right: 19px;
}
.ant-form-item-has-feedback .ant-cascader-picker-clear {
  right: 32px;
}
.ant-form-item-has-feedback .ant-picker {
  padding-right: 29.2px;
}
.ant-form-item-has-feedback .ant-picker-large {
  padding-right: 29.2px;
}
.ant-form-item-has-feedback .ant-picker-small {
  padding-right: 25.2px;
}
.ant-form-item-has-feedback.ant-form-item-has-success .ant-form-item-children-icon,
.ant-form-item-has-feedback.ant-form-item-has-warning .ant-form-item-children-icon,
.ant-form-item-has-feedback.ant-form-item-has-error .ant-form-item-children-icon,
.ant-form-item-has-feedback.ant-form-item-is-validating .ant-form-item-children-icon {
  position: absolute;
  top: 50%;
  right: 0;
  z-index: 1;
  width: 32px;
  height: 20px;
  margin-top: -10px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  visibility: visible;
  animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
  pointer-events: none;
}
.ant-form-item-has-success.ant-form-item-has-feedback .ant-form-item-children-icon {
  color: #79be39;
  animation-name: diffZoomIn1 !important;
}
.ant-form-item-has-warning .ant-form-item-split {
  color: #FFC107;
}
.ant-form-item-has-warning .ant-input,
.ant-form-item-has-warning .ant-input-affix-wrapper,
.ant-form-item-has-warning .ant-input:hover,
.ant-form-item-has-warning .ant-input-affix-wrapper:hover {
  background-color: #fff;
  border-color: #FFC107;
}
.ant-form-item-has-warning .ant-input:focus,
.ant-form-item-has-warning .ant-input-affix-wrapper:focus,
.ant-form-item-has-warning .ant-input-focused,
.ant-form-item-has-warning .ant-input-affix-wrapper-focused {
  border-color: #ffd230;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 193, 7, 0.2);
}
.ant-form-item-has-warning .ant-input-disabled {
  background-color: #f5f5f5;
  border-color: #AAAAAA;
}
.ant-form-item-has-warning .ant-input-affix-wrapper-disabled {
  background-color: #f5f5f5;
  border-color: #AAAAAA;
}
.ant-form-item-has-warning .ant-input-affix-wrapper-disabled input:focus {
  box-shadow: none !important;
}
.ant-form-item-has-warning .ant-calendar-picker-open .ant-calendar-picker-input {
  border-color: #ffd230;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 193, 7, 0.2);
}
.ant-form-item-has-warning .ant-input-prefix {
  color: #FFC107;
}
.ant-form-item-has-warning .ant-input-group-addon {
  color: #FFC107;
  border-color: #FFC107;
}
.ant-form-item-has-warning .has-feedback {
  color: #FFC107;
}
.ant-form-item-has-warning.ant-form-item-has-feedback .ant-form-item-children-icon {
  color: #FFC107;
  animation-name: diffZoomIn3 !important;
}
.ant-form-item-has-warning .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
  background-color: #fff;
  border-color: #FFC107 !important;
}
.ant-form-item-has-warning .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-open .ant-select-selector,
.ant-form-item-has-warning .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-focused .ant-select-selector {
  border-color: #ffd230;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 193, 7, 0.2);
}
.ant-form-item-has-warning .ant-input-number,
.ant-form-item-has-warning .ant-picker {
  background-color: #fff;
  border-color: #FFC107;
}
.ant-form-item-has-warning .ant-input-number-focused,
.ant-form-item-has-warning .ant-picker-focused,
.ant-form-item-has-warning .ant-input-number:focus,
.ant-form-item-has-warning .ant-picker:focus {
  border-color: #ffd230;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 193, 7, 0.2);
}
.ant-form-item-has-warning .ant-input-number:not([disabled]):hover,
.ant-form-item-has-warning .ant-picker:not([disabled]):hover {
  background-color: #fff;
  border-color: #FFC107;
}
.ant-form-item-has-warning .ant-cascader-picker:focus .ant-cascader-input {
  border-color: #ffd230;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 193, 7, 0.2);
}
.ant-form-item-has-error .ant-form-item-split {
  color: #DD4E4E;
}
.ant-form-item-has-error .ant-input,
.ant-form-item-has-error .ant-input-affix-wrapper,
.ant-form-item-has-error .ant-input:hover,
.ant-form-item-has-error .ant-input-affix-wrapper:hover {
  background-color: #fff;
  border-color: #DD4E4E;
}
.ant-form-item-has-error .ant-input:focus,
.ant-form-item-has-error .ant-input-affix-wrapper:focus,
.ant-form-item-has-error .ant-input-focused,
.ant-form-item-has-error .ant-input-affix-wrapper-focused {
  border-color: #eb7b78;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(221, 78, 78, 0.2);
}
.ant-form-item-has-error .ant-input-disabled {
  background-color: #f5f5f5;
  border-color: #AAAAAA;
}
.ant-form-item-has-error .ant-input-affix-wrapper-disabled {
  background-color: #f5f5f5;
  border-color: #AAAAAA;
}
.ant-form-item-has-error .ant-input-affix-wrapper-disabled input:focus {
  box-shadow: none !important;
}
.ant-form-item-has-error .ant-calendar-picker-open .ant-calendar-picker-input {
  border-color: #eb7b78;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(221, 78, 78, 0.2);
}
.ant-form-item-has-error .ant-input-prefix {
  color: #DD4E4E;
}
.ant-form-item-has-error .ant-input-group-addon {
  color: #DD4E4E;
  border-color: #DD4E4E;
}
.ant-form-item-has-error .has-feedback {
  color: #DD4E4E;
}
.ant-form-item-has-error.ant-form-item-has-feedback .ant-form-item-children-icon {
  color: #DD4E4E;
  animation-name: diffZoomIn2 !important;
}
.ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
  background-color: #fff;
  border-color: #DD4E4E !important;
}
.ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-open .ant-select-selector,
.ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-focused .ant-select-selector {
  border-color: #eb7b78;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(221, 78, 78, 0.2);
}
.ant-form-item-has-error .ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border: 0;
}
.ant-form-item-has-error .ant-select.ant-select-auto-complete .ant-input:focus {
  border-color: #DD4E4E;
}
.ant-form-item-has-error .ant-input-number,
.ant-form-item-has-error .ant-picker {
  background-color: #fff;
  border-color: #DD4E4E;
}
.ant-form-item-has-error .ant-input-number-focused,
.ant-form-item-has-error .ant-picker-focused,
.ant-form-item-has-error .ant-input-number:focus,
.ant-form-item-has-error .ant-picker:focus {
  border-color: #eb7b78;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(221, 78, 78, 0.2);
}
.ant-form-item-has-error .ant-input-number:not([disabled]):hover,
.ant-form-item-has-error .ant-picker:not([disabled]):hover {
  background-color: #fff;
  border-color: #DD4E4E;
}
.ant-form-item-has-error .ant-mention-wrapper .ant-mention-editor,
.ant-form-item-has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):hover {
  background-color: #fff;
  border-color: #DD4E4E;
}
.ant-form-item-has-error .ant-mention-wrapper.ant-mention-active:not([disabled]) .ant-mention-editor,
.ant-form-item-has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):focus {
  border-color: #eb7b78;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(221, 78, 78, 0.2);
}
.ant-form-item-has-error .ant-cascader-picker:focus .ant-cascader-input {
  background-color: #fff;
  border-color: #eb7b78;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(221, 78, 78, 0.2);
}
.ant-form-item-has-error .ant-transfer-list {
  border-color: #DD4E4E;
}
.ant-form-item-has-error .ant-transfer-list-search:not([disabled]) {
  border-color: #AAAAAA;
}
.ant-form-item-has-error .ant-transfer-list-search:not([disabled]):hover {
  border-color: #0d522a;
  border-right-width: 1px !important;
}
.ant-input-rtl .ant-form-item-has-error .ant-transfer-list-search:not([disabled]):hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-form-item-has-error .ant-transfer-list-search:not([disabled]):focus {
  border-color: #0d522a;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(0, 68, 31, 0.2);
}
.ant-input-rtl .ant-form-item-has-error .ant-transfer-list-search:not([disabled]):focus {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-form-item-has-error .ant-radio-button-wrapper {
  border-color: #DD4E4E !important;
}
.ant-form-item-has-error .ant-radio-button-wrapper:not(:first-child)::before {
  background-color: #DD4E4E;
}
.ant-form-item-is-validating.ant-form-item-has-feedback .ant-form-item-children-icon {
  display: inline-block;
  color: #00441F;
}
.ant-form {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
}
.ant-form legend {
  display: block;
  width: 100%;
  margin-bottom: 20px;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-size: 16px;
  line-height: inherit;
  border: 0;
  border-bottom: 1px solid #d9d9d9;
}
.ant-form label {
  font-size: 14px;
}
.ant-form input[type='search'] {
  box-sizing: border-box;
}
.ant-form input[type='radio'],
.ant-form input[type='checkbox'] {
  line-height: normal;
}
.ant-form input[type='file'] {
  display: block;
}
.ant-form input[type='range'] {
  display: block;
  width: 100%;
}
.ant-form select[multiple],
.ant-form select[size] {
  height: auto;
}
.ant-form input[type='file']:focus,
.ant-form input[type='radio']:focus,
.ant-form input[type='checkbox']:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
.ant-form output {
  display: block;
  padding-top: 15px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
}
.ant-form .ant-form-text {
  display: inline-block;
  padding-right: 8px;
}
.ant-form-small .ant-form-item-label > label {
  height: 24px;
}
.ant-form-small .ant-form-item-control-input {
  min-height: 24px;
}
.ant-form-large .ant-form-item-label > label {
  height: 40px;
}
.ant-form-large .ant-form-item-control-input {
  min-height: 40px;
}
.ant-form-item {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  margin-bottom: 24px;
  vertical-align: top;
}
.ant-form-item-with-help {
  margin-bottom: 0;
}
.ant-form-item-hidden,
.ant-form-item-hidden.ant-row {
  display: none;
}
.ant-form-item-label {
  display: inline-block;
  flex-grow: 0;
  overflow: hidden;
  white-space: nowrap;
  text-align: right;
  vertical-align: middle;
}
.ant-form-item-label-left {
  text-align: left;
}
.ant-form-item-label > label {
  position: relative;
  display: inline-flex;
  align-items: center;
  height: 32px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
.ant-form-item-label > label > .anticon {
  font-size: 14px;
  vertical-align: top;
}
.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: inline-block;
  margin-right: 4px;
  color: #d70101;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}
.ant-form-hide-required-mark .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}
.ant-form-item-label > label .ant-form-item-optional {
  display: inline-block;
  margin-left: 4px;
  color: rgba(0, 0, 0, 0.45);
}
.ant-form-hide-required-mark .ant-form-item-label > label .ant-form-item-optional {
  display: none;
}
.ant-form-item-label > label .ant-form-item-tooltip {
  writing-mode: horizontal-tb;
  margin-inline-start: 4px;
  color: rgba(0, 0, 0, 0.45);
}
.ant-form-item-label > label::after {
  content: ':';
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}
.ant-form-item-label > label.ant-form-item-no-colon::after {
  content: ' ';
}
.ant-form-item-control {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.ant-form-item-control:first-child:not([class^='ant-col-']):not([class*=' ant-col-']) {
  width: 100%;
}
.ant-form-item-control-input {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 32px;
}
.ant-form-item-control-input-content {
  flex: auto;
  max-width: 100%;
}
.ant-form-item-explain,
.ant-form-item-extra {
  clear: both;
  min-height: 24px;
  padding-top: 0px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.ant-form-item .ant-input-textarea-show-count::after {
  position: absolute;
  bottom: -22px;
  width: 100%;
}
.show-help-enter,
.show-help-appear {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.show-help-leave {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-play-state: paused;
}
.show-help-enter.show-help-enter-active,
.show-help-appear.show-help-appear-active {
  animation-name: antShowHelpIn;
  animation-play-state: running;
}
.show-help-leave.show-help-leave-active {
  animation-name: antShowHelpOut;
  animation-play-state: running;
  pointer-events: none;
}
.show-help-enter,
.show-help-appear {
  opacity: 0;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}
.show-help-leave {
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}
@keyframes antShowHelpIn {
  0% {
    transform: translateY(-5px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes antShowHelpOut {
  to {
    transform: translateY(-5px);
    opacity: 0;
  }
}
@keyframes diffZoomIn1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes diffZoomIn2 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes diffZoomIn3 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
.ant-form-rtl {
  direction: rtl;
}
.ant-form-rtl .ant-form-item-label {
  text-align: left;
}
.ant-form-rtl .ant-form-item-label > label.ant-form-item-required::before {
  margin-right: 0;
  margin-left: 4px;
}
.ant-form-rtl .ant-form-item-label > label::after {
  margin: 0 2px 0 8px;
}
.ant-form-rtl .ant-form-item-label > label .ant-form-item-optional {
  margin-right: 4px;
  margin-left: 0;
}
.ant-col-rtl .ant-form-item-control:first-child {
  width: 100%;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input {
  padding-right: 11px;
  padding-left: 24px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
  padding-right: 11px;
  padding-left: 18px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input {
  padding: 0;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input-search:not(.ant-input-search-enter-button) .ant-input-suffix {
  right: auto;
  left: 28px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-input-number {
  padding-left: 18px;
}
.ant-form-rtl .ant-form-item-has-feedback > .ant-select .ant-select-arrow,
.ant-form-rtl .ant-form-item-has-feedback > .ant-select .ant-select-clear,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-arrow,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-clear {
  right: auto;
  left: 32px;
}
.ant-form-rtl .ant-form-item-has-feedback > .ant-select .ant-select-selection-selected-value,
.ant-form-rtl .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-selection-selected-value {
  padding-right: 0;
  padding-left: 42px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-cascader-picker-arrow {
  margin-right: 0;
  margin-left: 19px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-cascader-picker-clear {
  right: auto;
  left: 32px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-picker {
  padding-right: 11px;
  padding-left: 29.2px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-picker-large {
  padding-right: 11px;
  padding-left: 29.2px;
}
.ant-form-rtl .ant-form-item-has-feedback .ant-picker-small {
  padding-right: 7px;
  padding-left: 25.2px;
}
.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-success .ant-form-item-children-icon,
.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-warning .ant-form-item-children-icon,
.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-error .ant-form-item-children-icon,
.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-is-validating .ant-form-item-children-icon {
  right: auto;
  left: 0;
}
.ant-form-rtl.ant-form-inline .ant-form-item {
  margin-right: 0;
  margin-left: 16px;
}
.ant-row {
  display: flex;
  flex-flow: row wrap;
}
.ant-row::before,
.ant-row::after {
  display: flex;
}
.ant-row-no-wrap {
  flex-wrap: nowrap;
}
.ant-row-start {
  justify-content: flex-start;
}
.ant-row-center {
  justify-content: center;
}
.ant-row-end {
  justify-content: flex-end;
}
.ant-row-space-between {
  justify-content: space-between;
}
.ant-row-space-around {
  justify-content: space-around;
}
.ant-row-top {
  align-items: flex-start;
}
.ant-row-middle {
  align-items: center;
}
.ant-row-bottom {
  align-items: flex-end;
}
.ant-col {
  position: relative;
  max-width: 100%;
  min-height: 1px;
}
.ant-col-24 {
  display: block;
  flex: 0 0 100%;
  max-width: 100%;
}
.ant-col-push-24 {
  left: 100%;
}
.ant-col-pull-24 {
  right: 100%;
}
.ant-col-offset-24 {
  margin-left: 100%;
}
.ant-col-order-24 {
  order: 24;
}
.ant-col-23 {
  display: block;
  flex: 0 0 95.83333333%;
  max-width: 95.83333333%;
}
.ant-col-push-23 {
  left: 95.83333333%;
}
.ant-col-pull-23 {
  right: 95.83333333%;
}
.ant-col-offset-23 {
  margin-left: 95.83333333%;
}
.ant-col-order-23 {
  order: 23;
}
.ant-col-22 {
  display: block;
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}
.ant-col-push-22 {
  left: 91.66666667%;
}
.ant-col-pull-22 {
  right: 91.66666667%;
}
.ant-col-offset-22 {
  margin-left: 91.66666667%;
}
.ant-col-order-22 {
  order: 22;
}
.ant-col-21 {
  display: block;
  flex: 0 0 87.5%;
  max-width: 87.5%;
}
.ant-col-push-21 {
  left: 87.5%;
}
.ant-col-pull-21 {
  right: 87.5%;
}
.ant-col-offset-21 {
  margin-left: 87.5%;
}
.ant-col-order-21 {
  order: 21;
}
.ant-col-20 {
  display: block;
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}
.ant-col-push-20 {
  left: 83.33333333%;
}
.ant-col-pull-20 {
  right: 83.33333333%;
}
.ant-col-offset-20 {
  margin-left: 83.33333333%;
}
.ant-col-order-20 {
  order: 20;
}
.ant-col-19 {
  display: block;
  flex: 0 0 79.16666667%;
  max-width: 79.16666667%;
}
.ant-col-push-19 {
  left: 79.16666667%;
}
.ant-col-pull-19 {
  right: 79.16666667%;
}
.ant-col-offset-19 {
  margin-left: 79.16666667%;
}
.ant-col-order-19 {
  order: 19;
}
.ant-col-18 {
  display: block;
  flex: 0 0 75%;
  max-width: 75%;
}
.ant-col-push-18 {
  left: 75%;
}
.ant-col-pull-18 {
  right: 75%;
}
.ant-col-offset-18 {
  margin-left: 75%;
}
.ant-col-order-18 {
  order: 18;
}
.ant-col-17 {
  display: block;
  flex: 0 0 70.83333333%;
  max-width: 70.83333333%;
}
.ant-col-push-17 {
  left: 70.83333333%;
}
.ant-col-pull-17 {
  right: 70.83333333%;
}
.ant-col-offset-17 {
  margin-left: 70.83333333%;
}
.ant-col-order-17 {
  order: 17;
}
.ant-col-16 {
  display: block;
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}
.ant-col-push-16 {
  left: 66.66666667%;
}
.ant-col-pull-16 {
  right: 66.66666667%;
}
.ant-col-offset-16 {
  margin-left: 66.66666667%;
}
.ant-col-order-16 {
  order: 16;
}
.ant-col-15 {
  display: block;
  flex: 0 0 62.5%;
  max-width: 62.5%;
}
.ant-col-push-15 {
  left: 62.5%;
}
.ant-col-pull-15 {
  right: 62.5%;
}
.ant-col-offset-15 {
  margin-left: 62.5%;
}
.ant-col-order-15 {
  order: 15;
}
.ant-col-14 {
  display: block;
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}
.ant-col-push-14 {
  left: 58.33333333%;
}
.ant-col-pull-14 {
  right: 58.33333333%;
}
.ant-col-offset-14 {
  margin-left: 58.33333333%;
}
.ant-col-order-14 {
  order: 14;
}
.ant-col-13 {
  display: block;
  flex: 0 0 54.16666667%;
  max-width: 54.16666667%;
}
.ant-col-push-13 {
  left: 54.16666667%;
}
.ant-col-pull-13 {
  right: 54.16666667%;
}
.ant-col-offset-13 {
  margin-left: 54.16666667%;
}
.ant-col-order-13 {
  order: 13;
}
.ant-col-12 {
  display: block;
  flex: 0 0 50%;
  max-width: 50%;
}
.ant-col-push-12 {
  left: 50%;
}
.ant-col-pull-12 {
  right: 50%;
}
.ant-col-offset-12 {
  margin-left: 50%;
}
.ant-col-order-12 {
  order: 12;
}
.ant-col-11 {
  display: block;
  flex: 0 0 45.83333333%;
  max-width: 45.83333333%;
}
.ant-col-push-11 {
  left: 45.83333333%;
}
.ant-col-pull-11 {
  right: 45.83333333%;
}
.ant-col-offset-11 {
  margin-left: 45.83333333%;
}
.ant-col-order-11 {
  order: 11;
}
.ant-col-10 {
  display: block;
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}
.ant-col-push-10 {
  left: 41.66666667%;
}
.ant-col-pull-10 {
  right: 41.66666667%;
}
.ant-col-offset-10 {
  margin-left: 41.66666667%;
}
.ant-col-order-10 {
  order: 10;
}
.ant-col-9 {
  display: block;
  flex: 0 0 37.5%;
  max-width: 37.5%;
}
.ant-col-push-9 {
  left: 37.5%;
}
.ant-col-pull-9 {
  right: 37.5%;
}
.ant-col-offset-9 {
  margin-left: 37.5%;
}
.ant-col-order-9 {
  order: 9;
}
.ant-col-8 {
  display: block;
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}
.ant-col-push-8 {
  left: 33.33333333%;
}
.ant-col-pull-8 {
  right: 33.33333333%;
}
.ant-col-offset-8 {
  margin-left: 33.33333333%;
}
.ant-col-order-8 {
  order: 8;
}
.ant-col-7 {
  display: block;
  flex: 0 0 29.16666667%;
  max-width: 29.16666667%;
}
.ant-col-push-7 {
  left: 29.16666667%;
}
.ant-col-pull-7 {
  right: 29.16666667%;
}
.ant-col-offset-7 {
  margin-left: 29.16666667%;
}
.ant-col-order-7 {
  order: 7;
}
.ant-col-6 {
  display: block;
  flex: 0 0 25%;
  max-width: 25%;
}
.ant-col-push-6 {
  left: 25%;
}
.ant-col-pull-6 {
  right: 25%;
}
.ant-col-offset-6 {
  margin-left: 25%;
}
.ant-col-order-6 {
  order: 6;
}
.ant-col-5 {
  display: block;
  flex: 0 0 20.83333333%;
  max-width: 20.83333333%;
}
.ant-col-push-5 {
  left: 20.83333333%;
}
.ant-col-pull-5 {
  right: 20.83333333%;
}
.ant-col-offset-5 {
  margin-left: 20.83333333%;
}
.ant-col-order-5 {
  order: 5;
}
.ant-col-4 {
  display: block;
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}
.ant-col-push-4 {
  left: 16.66666667%;
}
.ant-col-pull-4 {
  right: 16.66666667%;
}
.ant-col-offset-4 {
  margin-left: 16.66666667%;
}
.ant-col-order-4 {
  order: 4;
}
.ant-col-3 {
  display: block;
  flex: 0 0 12.5%;
  max-width: 12.5%;
}
.ant-col-push-3 {
  left: 12.5%;
}
.ant-col-pull-3 {
  right: 12.5%;
}
.ant-col-offset-3 {
  margin-left: 12.5%;
}
.ant-col-order-3 {
  order: 3;
}
.ant-col-2 {
  display: block;
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}
.ant-col-push-2 {
  left: 8.33333333%;
}
.ant-col-pull-2 {
  right: 8.33333333%;
}
.ant-col-offset-2 {
  margin-left: 8.33333333%;
}
.ant-col-order-2 {
  order: 2;
}
.ant-col-1 {
  display: block;
  flex: 0 0 4.16666667%;
  max-width: 4.16666667%;
}
.ant-col-push-1 {
  left: 4.16666667%;
}
.ant-col-pull-1 {
  right: 4.16666667%;
}
.ant-col-offset-1 {
  margin-left: 4.16666667%;
}
.ant-col-order-1 {
  order: 1;
}
.ant-col-0 {
  display: none;
}
.ant-col-push-0 {
  left: auto;
}
.ant-col-pull-0 {
  right: auto;
}
.ant-col-push-0 {
  left: auto;
}
.ant-col-pull-0 {
  right: auto;
}
.ant-col-offset-0 {
  margin-left: 0;
}
.ant-col-order-0 {
  order: 0;
}
.ant-col-push-0.ant-col-rtl {
  right: auto;
}
.ant-col-pull-0.ant-col-rtl {
  left: auto;
}
.ant-col-push-0.ant-col-rtl {
  right: auto;
}
.ant-col-pull-0.ant-col-rtl {
  left: auto;
}
.ant-col-offset-0.ant-col-rtl {
  margin-right: 0;
}
.ant-col-push-1.ant-col-rtl {
  right: 4.16666667%;
  left: auto;
}
.ant-col-pull-1.ant-col-rtl {
  right: auto;
  left: 4.16666667%;
}
.ant-col-offset-1.ant-col-rtl {
  margin-right: 4.16666667%;
  margin-left: 0;
}
.ant-col-push-2.ant-col-rtl {
  right: 8.33333333%;
  left: auto;
}
.ant-col-pull-2.ant-col-rtl {
  right: auto;
  left: 8.33333333%;
}
.ant-col-offset-2.ant-col-rtl {
  margin-right: 8.33333333%;
  margin-left: 0;
}
.ant-col-push-3.ant-col-rtl {
  right: 12.5%;
  left: auto;
}
.ant-col-pull-3.ant-col-rtl {
  right: auto;
  left: 12.5%;
}
.ant-col-offset-3.ant-col-rtl {
  margin-right: 12.5%;
  margin-left: 0;
}
.ant-col-push-4.ant-col-rtl {
  right: 16.66666667%;
  left: auto;
}
.ant-col-pull-4.ant-col-rtl {
  right: auto;
  left: 16.66666667%;
}
.ant-col-offset-4.ant-col-rtl {
  margin-right: 16.66666667%;
  margin-left: 0;
}
.ant-col-push-5.ant-col-rtl {
  right: 20.83333333%;
  left: auto;
}
.ant-col-pull-5.ant-col-rtl {
  right: auto;
  left: 20.83333333%;
}
.ant-col-offset-5.ant-col-rtl {
  margin-right: 20.83333333%;
  margin-left: 0;
}
.ant-col-push-6.ant-col-rtl {
  right: 25%;
  left: auto;
}
.ant-col-pull-6.ant-col-rtl {
  right: auto;
  left: 25%;
}
.ant-col-offset-6.ant-col-rtl {
  margin-right: 25%;
  margin-left: 0;
}
.ant-col-push-7.ant-col-rtl {
  right: 29.16666667%;
  left: auto;
}
.ant-col-pull-7.ant-col-rtl {
  right: auto;
  left: 29.16666667%;
}
.ant-col-offset-7.ant-col-rtl {
  margin-right: 29.16666667%;
  margin-left: 0;
}
.ant-col-push-8.ant-col-rtl {
  right: 33.33333333%;
  left: auto;
}
.ant-col-pull-8.ant-col-rtl {
  right: auto;
  left: 33.33333333%;
}
.ant-col-offset-8.ant-col-rtl {
  margin-right: 33.33333333%;
  margin-left: 0;
}
.ant-col-push-9.ant-col-rtl {
  right: 37.5%;
  left: auto;
}
.ant-col-pull-9.ant-col-rtl {
  right: auto;
  left: 37.5%;
}
.ant-col-offset-9.ant-col-rtl {
  margin-right: 37.5%;
  margin-left: 0;
}
.ant-col-push-10.ant-col-rtl {
  right: 41.66666667%;
  left: auto;
}
.ant-col-pull-10.ant-col-rtl {
  right: auto;
  left: 41.66666667%;
}
.ant-col-offset-10.ant-col-rtl {
  margin-right: 41.66666667%;
  margin-left: 0;
}
.ant-col-push-11.ant-col-rtl {
  right: 45.83333333%;
  left: auto;
}
.ant-col-pull-11.ant-col-rtl {
  right: auto;
  left: 45.83333333%;
}
.ant-col-offset-11.ant-col-rtl {
  margin-right: 45.83333333%;
  margin-left: 0;
}
.ant-col-push-12.ant-col-rtl {
  right: 50%;
  left: auto;
}
.ant-col-pull-12.ant-col-rtl {
  right: auto;
  left: 50%;
}
.ant-col-offset-12.ant-col-rtl {
  margin-right: 50%;
  margin-left: 0;
}
.ant-col-push-13.ant-col-rtl {
  right: 54.16666667%;
  left: auto;
}
.ant-col-pull-13.ant-col-rtl {
  right: auto;
  left: 54.16666667%;
}
.ant-col-offset-13.ant-col-rtl {
  margin-right: 54.16666667%;
  margin-left: 0;
}
.ant-col-push-14.ant-col-rtl {
  right: 58.33333333%;
  left: auto;
}
.ant-col-pull-14.ant-col-rtl {
  right: auto;
  left: 58.33333333%;
}
.ant-col-offset-14.ant-col-rtl {
  margin-right: 58.33333333%;
  margin-left: 0;
}
.ant-col-push-15.ant-col-rtl {
  right: 62.5%;
  left: auto;
}
.ant-col-pull-15.ant-col-rtl {
  right: auto;
  left: 62.5%;
}
.ant-col-offset-15.ant-col-rtl {
  margin-right: 62.5%;
  margin-left: 0;
}
.ant-col-push-16.ant-col-rtl {
  right: 66.66666667%;
  left: auto;
}
.ant-col-pull-16.ant-col-rtl {
  right: auto;
  left: 66.66666667%;
}
.ant-col-offset-16.ant-col-rtl {
  margin-right: 66.66666667%;
  margin-left: 0;
}
.ant-col-push-17.ant-col-rtl {
  right: 70.83333333%;
  left: auto;
}
.ant-col-pull-17.ant-col-rtl {
  right: auto;
  left: 70.83333333%;
}
.ant-col-offset-17.ant-col-rtl {
  margin-right: 70.83333333%;
  margin-left: 0;
}
.ant-col-push-18.ant-col-rtl {
  right: 75%;
  left: auto;
}
.ant-col-pull-18.ant-col-rtl {
  right: auto;
  left: 75%;
}
.ant-col-offset-18.ant-col-rtl {
  margin-right: 75%;
  margin-left: 0;
}
.ant-col-push-19.ant-col-rtl {
  right: 79.16666667%;
  left: auto;
}
.ant-col-pull-19.ant-col-rtl {
  right: auto;
  left: 79.16666667%;
}
.ant-col-offset-19.ant-col-rtl {
  margin-right: 79.16666667%;
  margin-left: 0;
}
.ant-col-push-20.ant-col-rtl {
  right: 83.33333333%;
  left: auto;
}
.ant-col-pull-20.ant-col-rtl {
  right: auto;
  left: 83.33333333%;
}
.ant-col-offset-20.ant-col-rtl {
  margin-right: 83.33333333%;
  margin-left: 0;
}
.ant-col-push-21.ant-col-rtl {
  right: 87.5%;
  left: auto;
}
.ant-col-pull-21.ant-col-rtl {
  right: auto;
  left: 87.5%;
}
.ant-col-offset-21.ant-col-rtl {
  margin-right: 87.5%;
  margin-left: 0;
}
.ant-col-push-22.ant-col-rtl {
  right: 91.66666667%;
  left: auto;
}
.ant-col-pull-22.ant-col-rtl {
  right: auto;
  left: 91.66666667%;
}
.ant-col-offset-22.ant-col-rtl {
  margin-right: 91.66666667%;
  margin-left: 0;
}
.ant-col-push-23.ant-col-rtl {
  right: 95.83333333%;
  left: auto;
}
.ant-col-pull-23.ant-col-rtl {
  right: auto;
  left: 95.83333333%;
}
.ant-col-offset-23.ant-col-rtl {
  margin-right: 95.83333333%;
  margin-left: 0;
}
.ant-col-push-24.ant-col-rtl {
  right: 100%;
  left: auto;
}
.ant-col-pull-24.ant-col-rtl {
  right: auto;
  left: 100%;
}
.ant-col-offset-24.ant-col-rtl {
  margin-right: 100%;
  margin-left: 0;
}
.ant-col-xs-24 {
  display: block;
  flex: 0 0 100%;
  max-width: 100%;
}
.ant-col-xs-push-24 {
  left: 100%;
}
.ant-col-xs-pull-24 {
  right: 100%;
}
.ant-col-xs-offset-24 {
  margin-left: 100%;
}
.ant-col-xs-order-24 {
  order: 24;
}
.ant-col-xs-23 {
  display: block;
  flex: 0 0 95.83333333%;
  max-width: 95.83333333%;
}
.ant-col-xs-push-23 {
  left: 95.83333333%;
}
.ant-col-xs-pull-23 {
  right: 95.83333333%;
}
.ant-col-xs-offset-23 {
  margin-left: 95.83333333%;
}
.ant-col-xs-order-23 {
  order: 23;
}
.ant-col-xs-22 {
  display: block;
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}
.ant-col-xs-push-22 {
  left: 91.66666667%;
}
.ant-col-xs-pull-22 {
  right: 91.66666667%;
}
.ant-col-xs-offset-22 {
  margin-left: 91.66666667%;
}
.ant-col-xs-order-22 {
  order: 22;
}
.ant-col-xs-21 {
  display: block;
  flex: 0 0 87.5%;
  max-width: 87.5%;
}
.ant-col-xs-push-21 {
  left: 87.5%;
}
.ant-col-xs-pull-21 {
  right: 87.5%;
}
.ant-col-xs-offset-21 {
  margin-left: 87.5%;
}
.ant-col-xs-order-21 {
  order: 21;
}
.ant-col-xs-20 {
  display: block;
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}
.ant-col-xs-push-20 {
  left: 83.33333333%;
}
.ant-col-xs-pull-20 {
  right: 83.33333333%;
}
.ant-col-xs-offset-20 {
  margin-left: 83.33333333%;
}
.ant-col-xs-order-20 {
  order: 20;
}
.ant-col-xs-19 {
  display: block;
  flex: 0 0 79.16666667%;
  max-width: 79.16666667%;
}
.ant-col-xs-push-19 {
  left: 79.16666667%;
}
.ant-col-xs-pull-19 {
  right: 79.16666667%;
}
.ant-col-xs-offset-19 {
  margin-left: 79.16666667%;
}
.ant-col-xs-order-19 {
  order: 19;
}
.ant-col-xs-18 {
  display: block;
  flex: 0 0 75%;
  max-width: 75%;
}
.ant-col-xs-push-18 {
  left: 75%;
}
.ant-col-xs-pull-18 {
  right: 75%;
}
.ant-col-xs-offset-18 {
  margin-left: 75%;
}
.ant-col-xs-order-18 {
  order: 18;
}
.ant-col-xs-17 {
  display: block;
  flex: 0 0 70.83333333%;
  max-width: 70.83333333%;
}
.ant-col-xs-push-17 {
  left: 70.83333333%;
}
.ant-col-xs-pull-17 {
  right: 70.83333333%;
}
.ant-col-xs-offset-17 {
  margin-left: 70.83333333%;
}
.ant-col-xs-order-17 {
  order: 17;
}
.ant-col-xs-16 {
  display: block;
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}
.ant-col-xs-push-16 {
  left: 66.66666667%;
}
.ant-col-xs-pull-16 {
  right: 66.66666667%;
}
.ant-col-xs-offset-16 {
  margin-left: 66.66666667%;
}
.ant-col-xs-order-16 {
  order: 16;
}
.ant-col-xs-15 {
  display: block;
  flex: 0 0 62.5%;
  max-width: 62.5%;
}
.ant-col-xs-push-15 {
  left: 62.5%;
}
.ant-col-xs-pull-15 {
  right: 62.5%;
}
.ant-col-xs-offset-15 {
  margin-left: 62.5%;
}
.ant-col-xs-order-15 {
  order: 15;
}
.ant-col-xs-14 {
  display: block;
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}
.ant-col-xs-push-14 {
  left: 58.33333333%;
}
.ant-col-xs-pull-14 {
  right: 58.33333333%;
}
.ant-col-xs-offset-14 {
  margin-left: 58.33333333%;
}
.ant-col-xs-order-14 {
  order: 14;
}
.ant-col-xs-13 {
  display: block;
  flex: 0 0 54.16666667%;
  max-width: 54.16666667%;
}
.ant-col-xs-push-13 {
  left: 54.16666667%;
}
.ant-col-xs-pull-13 {
  right: 54.16666667%;
}
.ant-col-xs-offset-13 {
  margin-left: 54.16666667%;
}
.ant-col-xs-order-13 {
  order: 13;
}
.ant-col-xs-12 {
  display: block;
  flex: 0 0 50%;
  max-width: 50%;
}
.ant-col-xs-push-12 {
  left: 50%;
}
.ant-col-xs-pull-12 {
  right: 50%;
}
.ant-col-xs-offset-12 {
  margin-left: 50%;
}
.ant-col-xs-order-12 {
  order: 12;
}
.ant-col-xs-11 {
  display: block;
  flex: 0 0 45.83333333%;
  max-width: 45.83333333%;
}
.ant-col-xs-push-11 {
  left: 45.83333333%;
}
.ant-col-xs-pull-11 {
  right: 45.83333333%;
}
.ant-col-xs-offset-11 {
  margin-left: 45.83333333%;
}
.ant-col-xs-order-11 {
  order: 11;
}
.ant-col-xs-10 {
  display: block;
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}
.ant-col-xs-push-10 {
  left: 41.66666667%;
}
.ant-col-xs-pull-10 {
  right: 41.66666667%;
}
.ant-col-xs-offset-10 {
  margin-left: 41.66666667%;
}
.ant-col-xs-order-10 {
  order: 10;
}
.ant-col-xs-9 {
  display: block;
  flex: 0 0 37.5%;
  max-width: 37.5%;
}
.ant-col-xs-push-9 {
  left: 37.5%;
}
.ant-col-xs-pull-9 {
  right: 37.5%;
}
.ant-col-xs-offset-9 {
  margin-left: 37.5%;
}
.ant-col-xs-order-9 {
  order: 9;
}
.ant-col-xs-8 {
  display: block;
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}
.ant-col-xs-push-8 {
  left: 33.33333333%;
}
.ant-col-xs-pull-8 {
  right: 33.33333333%;
}
.ant-col-xs-offset-8 {
  margin-left: 33.33333333%;
}
.ant-col-xs-order-8 {
  order: 8;
}
.ant-col-xs-7 {
  display: block;
  flex: 0 0 29.16666667%;
  max-width: 29.16666667%;
}
.ant-col-xs-push-7 {
  left: 29.16666667%;
}
.ant-col-xs-pull-7 {
  right: 29.16666667%;
}
.ant-col-xs-offset-7 {
  margin-left: 29.16666667%;
}
.ant-col-xs-order-7 {
  order: 7;
}
.ant-col-xs-6 {
  display: block;
  flex: 0 0 25%;
  max-width: 25%;
}
.ant-col-xs-push-6 {
  left: 25%;
}
.ant-col-xs-pull-6 {
  right: 25%;
}
.ant-col-xs-offset-6 {
  margin-left: 25%;
}
.ant-col-xs-order-6 {
  order: 6;
}
.ant-col-xs-5 {
  display: block;
  flex: 0 0 20.83333333%;
  max-width: 20.83333333%;
}
.ant-col-xs-push-5 {
  left: 20.83333333%;
}
.ant-col-xs-pull-5 {
  right: 20.83333333%;
}
.ant-col-xs-offset-5 {
  margin-left: 20.83333333%;
}
.ant-col-xs-order-5 {
  order: 5;
}
.ant-col-xs-4 {
  display: block;
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}
.ant-col-xs-push-4 {
  left: 16.66666667%;
}
.ant-col-xs-pull-4 {
  right: 16.66666667%;
}
.ant-col-xs-offset-4 {
  margin-left: 16.66666667%;
}
.ant-col-xs-order-4 {
  order: 4;
}
.ant-col-xs-3 {
  display: block;
  flex: 0 0 12.5%;
  max-width: 12.5%;
}
.ant-col-xs-push-3 {
  left: 12.5%;
}
.ant-col-xs-pull-3 {
  right: 12.5%;
}
.ant-col-xs-offset-3 {
  margin-left: 12.5%;
}
.ant-col-xs-order-3 {
  order: 3;
}
.ant-col-xs-2 {
  display: block;
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}
.ant-col-xs-push-2 {
  left: 8.33333333%;
}
.ant-col-xs-pull-2 {
  right: 8.33333333%;
}
.ant-col-xs-offset-2 {
  margin-left: 8.33333333%;
}
.ant-col-xs-order-2 {
  order: 2;
}
.ant-col-xs-1 {
  display: block;
  flex: 0 0 4.16666667%;
  max-width: 4.16666667%;
}
.ant-col-xs-push-1 {
  left: 4.16666667%;
}
.ant-col-xs-pull-1 {
  right: 4.16666667%;
}
.ant-col-xs-offset-1 {
  margin-left: 4.16666667%;
}
.ant-col-xs-order-1 {
  order: 1;
}
.ant-col-xs-0 {
  display: none;
}
.ant-col-push-0 {
  left: auto;
}
.ant-col-pull-0 {
  right: auto;
}
.ant-col-xs-push-0 {
  left: auto;
}
.ant-col-xs-pull-0 {
  right: auto;
}
.ant-col-xs-offset-0 {
  margin-left: 0;
}
.ant-col-xs-order-0 {
  order: 0;
}
.ant-col-push-0.ant-col-rtl {
  right: auto;
}
.ant-col-pull-0.ant-col-rtl {
  left: auto;
}
.ant-col-xs-push-0.ant-col-rtl {
  right: auto;
}
.ant-col-xs-pull-0.ant-col-rtl {
  left: auto;
}
.ant-col-xs-offset-0.ant-col-rtl {
  margin-right: 0;
}
.ant-col-xs-push-1.ant-col-rtl {
  right: 4.16666667%;
  left: auto;
}
.ant-col-xs-pull-1.ant-col-rtl {
  right: auto;
  left: 4.16666667%;
}
.ant-col-xs-offset-1.ant-col-rtl {
  margin-right: 4.16666667%;
  margin-left: 0;
}
.ant-col-xs-push-2.ant-col-rtl {
  right: 8.33333333%;
  left: auto;
}
.ant-col-xs-pull-2.ant-col-rtl {
  right: auto;
  left: 8.33333333%;
}
.ant-col-xs-offset-2.ant-col-rtl {
  margin-right: 8.33333333%;
  margin-left: 0;
}
.ant-col-xs-push-3.ant-col-rtl {
  right: 12.5%;
  left: auto;
}
.ant-col-xs-pull-3.ant-col-rtl {
  right: auto;
  left: 12.5%;
}
.ant-col-xs-offset-3.ant-col-rtl {
  margin-right: 12.5%;
  margin-left: 0;
}
.ant-col-xs-push-4.ant-col-rtl {
  right: 16.66666667%;
  left: auto;
}
.ant-col-xs-pull-4.ant-col-rtl {
  right: auto;
  left: 16.66666667%;
}
.ant-col-xs-offset-4.ant-col-rtl {
  margin-right: 16.66666667%;
  margin-left: 0;
}
.ant-col-xs-push-5.ant-col-rtl {
  right: 20.83333333%;
  left: auto;
}
.ant-col-xs-pull-5.ant-col-rtl {
  right: auto;
  left: 20.83333333%;
}
.ant-col-xs-offset-5.ant-col-rtl {
  margin-right: 20.83333333%;
  margin-left: 0;
}
.ant-col-xs-push-6.ant-col-rtl {
  right: 25%;
  left: auto;
}
.ant-col-xs-pull-6.ant-col-rtl {
  right: auto;
  left: 25%;
}
.ant-col-xs-offset-6.ant-col-rtl {
  margin-right: 25%;
  margin-left: 0;
}
.ant-col-xs-push-7.ant-col-rtl {
  right: 29.16666667%;
  left: auto;
}
.ant-col-xs-pull-7.ant-col-rtl {
  right: auto;
  left: 29.16666667%;
}
.ant-col-xs-offset-7.ant-col-rtl {
  margin-right: 29.16666667%;
  margin-left: 0;
}
.ant-col-xs-push-8.ant-col-rtl {
  right: 33.33333333%;
  left: auto;
}
.ant-col-xs-pull-8.ant-col-rtl {
  right: auto;
  left: 33.33333333%;
}
.ant-col-xs-offset-8.ant-col-rtl {
  margin-right: 33.33333333%;
  margin-left: 0;
}
.ant-col-xs-push-9.ant-col-rtl {
  right: 37.5%;
  left: auto;
}
.ant-col-xs-pull-9.ant-col-rtl {
  right: auto;
  left: 37.5%;
}
.ant-col-xs-offset-9.ant-col-rtl {
  margin-right: 37.5%;
  margin-left: 0;
}
.ant-col-xs-push-10.ant-col-rtl {
  right: 41.66666667%;
  left: auto;
}
.ant-col-xs-pull-10.ant-col-rtl {
  right: auto;
  left: 41.66666667%;
}
.ant-col-xs-offset-10.ant-col-rtl {
  margin-right: 41.66666667%;
  margin-left: 0;
}
.ant-col-xs-push-11.ant-col-rtl {
  right: 45.83333333%;
  left: auto;
}
.ant-col-xs-pull-11.ant-col-rtl {
  right: auto;
  left: 45.83333333%;
}
.ant-col-xs-offset-11.ant-col-rtl {
  margin-right: 45.83333333%;
  margin-left: 0;
}
.ant-col-xs-push-12.ant-col-rtl {
  right: 50%;
  left: auto;
}
.ant-col-xs-pull-12.ant-col-rtl {
  right: auto;
  left: 50%;
}
.ant-col-xs-offset-12.ant-col-rtl {
  margin-right: 50%;
  margin-left: 0;
}
.ant-col-xs-push-13.ant-col-rtl {
  right: 54.16666667%;
  left: auto;
}
.ant-col-xs-pull-13.ant-col-rtl {
  right: auto;
  left: 54.16666667%;
}
.ant-col-xs-offset-13.ant-col-rtl {
  margin-right: 54.16666667%;
  margin-left: 0;
}
.ant-col-xs-push-14.ant-col-rtl {
  right: 58.33333333%;
  left: auto;
}
.ant-col-xs-pull-14.ant-col-rtl {
  right: auto;
  left: 58.33333333%;
}
.ant-col-xs-offset-14.ant-col-rtl {
  margin-right: 58.33333333%;
  margin-left: 0;
}
.ant-col-xs-push-15.ant-col-rtl {
  right: 62.5%;
  left: auto;
}
.ant-col-xs-pull-15.ant-col-rtl {
  right: auto;
  left: 62.5%;
}
.ant-col-xs-offset-15.ant-col-rtl {
  margin-right: 62.5%;
  margin-left: 0;
}
.ant-col-xs-push-16.ant-col-rtl {
  right: 66.66666667%;
  left: auto;
}
.ant-col-xs-pull-16.ant-col-rtl {
  right: auto;
  left: 66.66666667%;
}
.ant-col-xs-offset-16.ant-col-rtl {
  margin-right: 66.66666667%;
  margin-left: 0;
}
.ant-col-xs-push-17.ant-col-rtl {
  right: 70.83333333%;
  left: auto;
}
.ant-col-xs-pull-17.ant-col-rtl {
  right: auto;
  left: 70.83333333%;
}
.ant-col-xs-offset-17.ant-col-rtl {
  margin-right: 70.83333333%;
  margin-left: 0;
}
.ant-col-xs-push-18.ant-col-rtl {
  right: 75%;
  left: auto;
}
.ant-col-xs-pull-18.ant-col-rtl {
  right: auto;
  left: 75%;
}
.ant-col-xs-offset-18.ant-col-rtl {
  margin-right: 75%;
  margin-left: 0;
}
.ant-col-xs-push-19.ant-col-rtl {
  right: 79.16666667%;
  left: auto;
}
.ant-col-xs-pull-19.ant-col-rtl {
  right: auto;
  left: 79.16666667%;
}
.ant-col-xs-offset-19.ant-col-rtl {
  margin-right: 79.16666667%;
  margin-left: 0;
}
.ant-col-xs-push-20.ant-col-rtl {
  right: 83.33333333%;
  left: auto;
}
.ant-col-xs-pull-20.ant-col-rtl {
  right: auto;
  left: 83.33333333%;
}
.ant-col-xs-offset-20.ant-col-rtl {
  margin-right: 83.33333333%;
  margin-left: 0;
}
.ant-col-xs-push-21.ant-col-rtl {
  right: 87.5%;
  left: auto;
}
.ant-col-xs-pull-21.ant-col-rtl {
  right: auto;
  left: 87.5%;
}
.ant-col-xs-offset-21.ant-col-rtl {
  margin-right: 87.5%;
  margin-left: 0;
}
.ant-col-xs-push-22.ant-col-rtl {
  right: 91.66666667%;
  left: auto;
}
.ant-col-xs-pull-22.ant-col-rtl {
  right: auto;
  left: 91.66666667%;
}
.ant-col-xs-offset-22.ant-col-rtl {
  margin-right: 91.66666667%;
  margin-left: 0;
}
.ant-col-xs-push-23.ant-col-rtl {
  right: 95.83333333%;
  left: auto;
}
.ant-col-xs-pull-23.ant-col-rtl {
  right: auto;
  left: 95.83333333%;
}
.ant-col-xs-offset-23.ant-col-rtl {
  margin-right: 95.83333333%;
  margin-left: 0;
}
.ant-col-xs-push-24.ant-col-rtl {
  right: 100%;
  left: auto;
}
.ant-col-xs-pull-24.ant-col-rtl {
  right: auto;
  left: 100%;
}
.ant-col-xs-offset-24.ant-col-rtl {
  margin-right: 100%;
  margin-left: 0;
}
@media (min-width: 576px) {
  .ant-col-sm-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-sm-push-24 {
    left: 100%;
  }
  .ant-col-sm-pull-24 {
    right: 100%;
  }
  .ant-col-sm-offset-24 {
    margin-left: 100%;
  }
  .ant-col-sm-order-24 {
    order: 24;
  }
  .ant-col-sm-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-sm-push-23 {
    left: 95.83333333%;
  }
  .ant-col-sm-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-sm-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-sm-order-23 {
    order: 23;
  }
  .ant-col-sm-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-sm-push-22 {
    left: 91.66666667%;
  }
  .ant-col-sm-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-sm-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-sm-order-22 {
    order: 22;
  }
  .ant-col-sm-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-sm-push-21 {
    left: 87.5%;
  }
  .ant-col-sm-pull-21 {
    right: 87.5%;
  }
  .ant-col-sm-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-sm-order-21 {
    order: 21;
  }
  .ant-col-sm-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-sm-push-20 {
    left: 83.33333333%;
  }
  .ant-col-sm-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-sm-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-sm-order-20 {
    order: 20;
  }
  .ant-col-sm-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-sm-push-19 {
    left: 79.16666667%;
  }
  .ant-col-sm-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-sm-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-sm-order-19 {
    order: 19;
  }
  .ant-col-sm-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-sm-push-18 {
    left: 75%;
  }
  .ant-col-sm-pull-18 {
    right: 75%;
  }
  .ant-col-sm-offset-18 {
    margin-left: 75%;
  }
  .ant-col-sm-order-18 {
    order: 18;
  }
  .ant-col-sm-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-sm-push-17 {
    left: 70.83333333%;
  }
  .ant-col-sm-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-sm-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-sm-order-17 {
    order: 17;
  }
  .ant-col-sm-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-sm-push-16 {
    left: 66.66666667%;
  }
  .ant-col-sm-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-sm-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-sm-order-16 {
    order: 16;
  }
  .ant-col-sm-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-sm-push-15 {
    left: 62.5%;
  }
  .ant-col-sm-pull-15 {
    right: 62.5%;
  }
  .ant-col-sm-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-sm-order-15 {
    order: 15;
  }
  .ant-col-sm-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-sm-push-14 {
    left: 58.33333333%;
  }
  .ant-col-sm-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-sm-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-sm-order-14 {
    order: 14;
  }
  .ant-col-sm-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-sm-push-13 {
    left: 54.16666667%;
  }
  .ant-col-sm-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-sm-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-sm-order-13 {
    order: 13;
  }
  .ant-col-sm-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-sm-push-12 {
    left: 50%;
  }
  .ant-col-sm-pull-12 {
    right: 50%;
  }
  .ant-col-sm-offset-12 {
    margin-left: 50%;
  }
  .ant-col-sm-order-12 {
    order: 12;
  }
  .ant-col-sm-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-sm-push-11 {
    left: 45.83333333%;
  }
  .ant-col-sm-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-sm-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-sm-order-11 {
    order: 11;
  }
  .ant-col-sm-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-sm-push-10 {
    left: 41.66666667%;
  }
  .ant-col-sm-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-sm-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-sm-order-10 {
    order: 10;
  }
  .ant-col-sm-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-sm-push-9 {
    left: 37.5%;
  }
  .ant-col-sm-pull-9 {
    right: 37.5%;
  }
  .ant-col-sm-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-sm-order-9 {
    order: 9;
  }
  .ant-col-sm-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-sm-push-8 {
    left: 33.33333333%;
  }
  .ant-col-sm-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-sm-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-sm-order-8 {
    order: 8;
  }
  .ant-col-sm-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-sm-push-7 {
    left: 29.16666667%;
  }
  .ant-col-sm-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-sm-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-sm-order-7 {
    order: 7;
  }
  .ant-col-sm-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-sm-push-6 {
    left: 25%;
  }
  .ant-col-sm-pull-6 {
    right: 25%;
  }
  .ant-col-sm-offset-6 {
    margin-left: 25%;
  }
  .ant-col-sm-order-6 {
    order: 6;
  }
  .ant-col-sm-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-sm-push-5 {
    left: 20.83333333%;
  }
  .ant-col-sm-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-sm-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-sm-order-5 {
    order: 5;
  }
  .ant-col-sm-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-sm-push-4 {
    left: 16.66666667%;
  }
  .ant-col-sm-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-sm-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-sm-order-4 {
    order: 4;
  }
  .ant-col-sm-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-sm-push-3 {
    left: 12.5%;
  }
  .ant-col-sm-pull-3 {
    right: 12.5%;
  }
  .ant-col-sm-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-sm-order-3 {
    order: 3;
  }
  .ant-col-sm-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-sm-push-2 {
    left: 8.33333333%;
  }
  .ant-col-sm-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-sm-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-sm-order-2 {
    order: 2;
  }
  .ant-col-sm-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-sm-push-1 {
    left: 4.16666667%;
  }
  .ant-col-sm-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-sm-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-sm-order-1 {
    order: 1;
  }
  .ant-col-sm-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-sm-push-0 {
    left: auto;
  }
  .ant-col-sm-pull-0 {
    right: auto;
  }
  .ant-col-sm-offset-0 {
    margin-left: 0;
  }
  .ant-col-sm-order-0 {
    order: 0;
  }
  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-sm-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-sm-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-sm-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ant-col-sm-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .ant-col-sm-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .ant-col-sm-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .ant-col-sm-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .ant-col-sm-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .ant-col-sm-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .ant-col-sm-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .ant-col-sm-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .ant-col-sm-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .ant-col-sm-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .ant-col-sm-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .ant-col-sm-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }
  .ant-col-sm-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }
  .ant-col-sm-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .ant-col-sm-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .ant-col-sm-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .ant-col-sm-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .ant-col-sm-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .ant-col-sm-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .ant-col-sm-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .ant-col-sm-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .ant-col-sm-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .ant-col-sm-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .ant-col-sm-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .ant-col-sm-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .ant-col-sm-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }
  .ant-col-sm-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }
  .ant-col-sm-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .ant-col-sm-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .ant-col-sm-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .ant-col-sm-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .ant-col-sm-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .ant-col-sm-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .ant-col-sm-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .ant-col-sm-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .ant-col-sm-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .ant-col-sm-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .ant-col-sm-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .ant-col-sm-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .ant-col-sm-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }
  .ant-col-sm-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }
  .ant-col-sm-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .ant-col-sm-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .ant-col-sm-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .ant-col-sm-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .ant-col-sm-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .ant-col-sm-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .ant-col-sm-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .ant-col-sm-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .ant-col-sm-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .ant-col-sm-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .ant-col-sm-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .ant-col-sm-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .ant-col-sm-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .ant-col-sm-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .ant-col-sm-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }
  .ant-col-sm-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }
  .ant-col-sm-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
@media (min-width: 768px) {
  .ant-col-md-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-md-push-24 {
    left: 100%;
  }
  .ant-col-md-pull-24 {
    right: 100%;
  }
  .ant-col-md-offset-24 {
    margin-left: 100%;
  }
  .ant-col-md-order-24 {
    order: 24;
  }
  .ant-col-md-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-md-push-23 {
    left: 95.83333333%;
  }
  .ant-col-md-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-md-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-md-order-23 {
    order: 23;
  }
  .ant-col-md-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-md-push-22 {
    left: 91.66666667%;
  }
  .ant-col-md-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-md-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-md-order-22 {
    order: 22;
  }
  .ant-col-md-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-md-push-21 {
    left: 87.5%;
  }
  .ant-col-md-pull-21 {
    right: 87.5%;
  }
  .ant-col-md-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-md-order-21 {
    order: 21;
  }
  .ant-col-md-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-md-push-20 {
    left: 83.33333333%;
  }
  .ant-col-md-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-md-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-md-order-20 {
    order: 20;
  }
  .ant-col-md-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-md-push-19 {
    left: 79.16666667%;
  }
  .ant-col-md-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-md-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-md-order-19 {
    order: 19;
  }
  .ant-col-md-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-md-push-18 {
    left: 75%;
  }
  .ant-col-md-pull-18 {
    right: 75%;
  }
  .ant-col-md-offset-18 {
    margin-left: 75%;
  }
  .ant-col-md-order-18 {
    order: 18;
  }
  .ant-col-md-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-md-push-17 {
    left: 70.83333333%;
  }
  .ant-col-md-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-md-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-md-order-17 {
    order: 17;
  }
  .ant-col-md-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-md-push-16 {
    left: 66.66666667%;
  }
  .ant-col-md-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-md-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-md-order-16 {
    order: 16;
  }
  .ant-col-md-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-md-push-15 {
    left: 62.5%;
  }
  .ant-col-md-pull-15 {
    right: 62.5%;
  }
  .ant-col-md-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-md-order-15 {
    order: 15;
  }
  .ant-col-md-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-md-push-14 {
    left: 58.33333333%;
  }
  .ant-col-md-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-md-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-md-order-14 {
    order: 14;
  }
  .ant-col-md-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-md-push-13 {
    left: 54.16666667%;
  }
  .ant-col-md-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-md-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-md-order-13 {
    order: 13;
  }
  .ant-col-md-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-md-push-12 {
    left: 50%;
  }
  .ant-col-md-pull-12 {
    right: 50%;
  }
  .ant-col-md-offset-12 {
    margin-left: 50%;
  }
  .ant-col-md-order-12 {
    order: 12;
  }
  .ant-col-md-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-md-push-11 {
    left: 45.83333333%;
  }
  .ant-col-md-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-md-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-md-order-11 {
    order: 11;
  }
  .ant-col-md-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-md-push-10 {
    left: 41.66666667%;
  }
  .ant-col-md-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-md-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-md-order-10 {
    order: 10;
  }
  .ant-col-md-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-md-push-9 {
    left: 37.5%;
  }
  .ant-col-md-pull-9 {
    right: 37.5%;
  }
  .ant-col-md-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-md-order-9 {
    order: 9;
  }
  .ant-col-md-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-md-push-8 {
    left: 33.33333333%;
  }
  .ant-col-md-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-md-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-md-order-8 {
    order: 8;
  }
  .ant-col-md-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-md-push-7 {
    left: 29.16666667%;
  }
  .ant-col-md-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-md-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-md-order-7 {
    order: 7;
  }
  .ant-col-md-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-md-push-6 {
    left: 25%;
  }
  .ant-col-md-pull-6 {
    right: 25%;
  }
  .ant-col-md-offset-6 {
    margin-left: 25%;
  }
  .ant-col-md-order-6 {
    order: 6;
  }
  .ant-col-md-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-md-push-5 {
    left: 20.83333333%;
  }
  .ant-col-md-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-md-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-md-order-5 {
    order: 5;
  }
  .ant-col-md-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-md-push-4 {
    left: 16.66666667%;
  }
  .ant-col-md-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-md-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-md-order-4 {
    order: 4;
  }
  .ant-col-md-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-md-push-3 {
    left: 12.5%;
  }
  .ant-col-md-pull-3 {
    right: 12.5%;
  }
  .ant-col-md-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-md-order-3 {
    order: 3;
  }
  .ant-col-md-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-md-push-2 {
    left: 8.33333333%;
  }
  .ant-col-md-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-md-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-md-order-2 {
    order: 2;
  }
  .ant-col-md-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-md-push-1 {
    left: 4.16666667%;
  }
  .ant-col-md-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-md-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-md-order-1 {
    order: 1;
  }
  .ant-col-md-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-md-push-0 {
    left: auto;
  }
  .ant-col-md-pull-0 {
    right: auto;
  }
  .ant-col-md-offset-0 {
    margin-left: 0;
  }
  .ant-col-md-order-0 {
    order: 0;
  }
  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-md-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-md-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-md-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ant-col-md-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .ant-col-md-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .ant-col-md-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .ant-col-md-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .ant-col-md-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .ant-col-md-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .ant-col-md-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .ant-col-md-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .ant-col-md-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .ant-col-md-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .ant-col-md-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .ant-col-md-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .ant-col-md-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .ant-col-md-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .ant-col-md-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .ant-col-md-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }
  .ant-col-md-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }
  .ant-col-md-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .ant-col-md-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .ant-col-md-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .ant-col-md-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .ant-col-md-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .ant-col-md-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .ant-col-md-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .ant-col-md-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .ant-col-md-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .ant-col-md-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .ant-col-md-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .ant-col-md-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .ant-col-md-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .ant-col-md-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .ant-col-md-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .ant-col-md-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .ant-col-md-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }
  .ant-col-md-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }
  .ant-col-md-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .ant-col-md-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .ant-col-md-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .ant-col-md-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .ant-col-md-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .ant-col-md-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .ant-col-md-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .ant-col-md-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .ant-col-md-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .ant-col-md-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .ant-col-md-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .ant-col-md-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .ant-col-md-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .ant-col-md-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .ant-col-md-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .ant-col-md-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .ant-col-md-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }
  .ant-col-md-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }
  .ant-col-md-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .ant-col-md-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .ant-col-md-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .ant-col-md-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .ant-col-md-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .ant-col-md-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .ant-col-md-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .ant-col-md-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .ant-col-md-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .ant-col-md-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .ant-col-md-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .ant-col-md-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .ant-col-md-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .ant-col-md-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .ant-col-md-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .ant-col-md-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .ant-col-md-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }
  .ant-col-md-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }
  .ant-col-md-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
@media (min-width: 992px) {
  .ant-col-lg-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-lg-push-24 {
    left: 100%;
  }
  .ant-col-lg-pull-24 {
    right: 100%;
  }
  .ant-col-lg-offset-24 {
    margin-left: 100%;
  }
  .ant-col-lg-order-24 {
    order: 24;
  }
  .ant-col-lg-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-lg-push-23 {
    left: 95.83333333%;
  }
  .ant-col-lg-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-lg-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-lg-order-23 {
    order: 23;
  }
  .ant-col-lg-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-lg-push-22 {
    left: 91.66666667%;
  }
  .ant-col-lg-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-lg-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-lg-order-22 {
    order: 22;
  }
  .ant-col-lg-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-lg-push-21 {
    left: 87.5%;
  }
  .ant-col-lg-pull-21 {
    right: 87.5%;
  }
  .ant-col-lg-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-lg-order-21 {
    order: 21;
  }
  .ant-col-lg-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-lg-push-20 {
    left: 83.33333333%;
  }
  .ant-col-lg-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-lg-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-lg-order-20 {
    order: 20;
  }
  .ant-col-lg-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-lg-push-19 {
    left: 79.16666667%;
  }
  .ant-col-lg-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-lg-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-lg-order-19 {
    order: 19;
  }
  .ant-col-lg-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-lg-push-18 {
    left: 75%;
  }
  .ant-col-lg-pull-18 {
    right: 75%;
  }
  .ant-col-lg-offset-18 {
    margin-left: 75%;
  }
  .ant-col-lg-order-18 {
    order: 18;
  }
  .ant-col-lg-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-lg-push-17 {
    left: 70.83333333%;
  }
  .ant-col-lg-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-lg-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-lg-order-17 {
    order: 17;
  }
  .ant-col-lg-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-lg-push-16 {
    left: 66.66666667%;
  }
  .ant-col-lg-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-lg-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-lg-order-16 {
    order: 16;
  }
  .ant-col-lg-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-lg-push-15 {
    left: 62.5%;
  }
  .ant-col-lg-pull-15 {
    right: 62.5%;
  }
  .ant-col-lg-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-lg-order-15 {
    order: 15;
  }
  .ant-col-lg-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-lg-push-14 {
    left: 58.33333333%;
  }
  .ant-col-lg-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-lg-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-lg-order-14 {
    order: 14;
  }
  .ant-col-lg-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-lg-push-13 {
    left: 54.16666667%;
  }
  .ant-col-lg-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-lg-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-lg-order-13 {
    order: 13;
  }
  .ant-col-lg-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-lg-push-12 {
    left: 50%;
  }
  .ant-col-lg-pull-12 {
    right: 50%;
  }
  .ant-col-lg-offset-12 {
    margin-left: 50%;
  }
  .ant-col-lg-order-12 {
    order: 12;
  }
  .ant-col-lg-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-lg-push-11 {
    left: 45.83333333%;
  }
  .ant-col-lg-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-lg-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-lg-order-11 {
    order: 11;
  }
  .ant-col-lg-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-lg-push-10 {
    left: 41.66666667%;
  }
  .ant-col-lg-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-lg-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-lg-order-10 {
    order: 10;
  }
  .ant-col-lg-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-lg-push-9 {
    left: 37.5%;
  }
  .ant-col-lg-pull-9 {
    right: 37.5%;
  }
  .ant-col-lg-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-lg-order-9 {
    order: 9;
  }
  .ant-col-lg-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-lg-push-8 {
    left: 33.33333333%;
  }
  .ant-col-lg-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-lg-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-lg-order-8 {
    order: 8;
  }
  .ant-col-lg-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-lg-push-7 {
    left: 29.16666667%;
  }
  .ant-col-lg-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-lg-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-lg-order-7 {
    order: 7;
  }
  .ant-col-lg-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-lg-push-6 {
    left: 25%;
  }
  .ant-col-lg-pull-6 {
    right: 25%;
  }
  .ant-col-lg-offset-6 {
    margin-left: 25%;
  }
  .ant-col-lg-order-6 {
    order: 6;
  }
  .ant-col-lg-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-lg-push-5 {
    left: 20.83333333%;
  }
  .ant-col-lg-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-lg-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-lg-order-5 {
    order: 5;
  }
  .ant-col-lg-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-lg-push-4 {
    left: 16.66666667%;
  }
  .ant-col-lg-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-lg-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-lg-order-4 {
    order: 4;
  }
  .ant-col-lg-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-lg-push-3 {
    left: 12.5%;
  }
  .ant-col-lg-pull-3 {
    right: 12.5%;
  }
  .ant-col-lg-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-lg-order-3 {
    order: 3;
  }
  .ant-col-lg-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-lg-push-2 {
    left: 8.33333333%;
  }
  .ant-col-lg-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-lg-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-lg-order-2 {
    order: 2;
  }
  .ant-col-lg-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-lg-push-1 {
    left: 4.16666667%;
  }
  .ant-col-lg-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-lg-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-lg-order-1 {
    order: 1;
  }
  .ant-col-lg-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-lg-push-0 {
    left: auto;
  }
  .ant-col-lg-pull-0 {
    right: auto;
  }
  .ant-col-lg-offset-0 {
    margin-left: 0;
  }
  .ant-col-lg-order-0 {
    order: 0;
  }
  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-lg-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-lg-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-lg-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ant-col-lg-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .ant-col-lg-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .ant-col-lg-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .ant-col-lg-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .ant-col-lg-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .ant-col-lg-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .ant-col-lg-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .ant-col-lg-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .ant-col-lg-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .ant-col-lg-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .ant-col-lg-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .ant-col-lg-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }
  .ant-col-lg-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }
  .ant-col-lg-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .ant-col-lg-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .ant-col-lg-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .ant-col-lg-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .ant-col-lg-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .ant-col-lg-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .ant-col-lg-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .ant-col-lg-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .ant-col-lg-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .ant-col-lg-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .ant-col-lg-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .ant-col-lg-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .ant-col-lg-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }
  .ant-col-lg-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }
  .ant-col-lg-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .ant-col-lg-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .ant-col-lg-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .ant-col-lg-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .ant-col-lg-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .ant-col-lg-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .ant-col-lg-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .ant-col-lg-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .ant-col-lg-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .ant-col-lg-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .ant-col-lg-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .ant-col-lg-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .ant-col-lg-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }
  .ant-col-lg-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }
  .ant-col-lg-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .ant-col-lg-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .ant-col-lg-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .ant-col-lg-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .ant-col-lg-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .ant-col-lg-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .ant-col-lg-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .ant-col-lg-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .ant-col-lg-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .ant-col-lg-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .ant-col-lg-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .ant-col-lg-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .ant-col-lg-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .ant-col-lg-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .ant-col-lg-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }
  .ant-col-lg-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }
  .ant-col-lg-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
@media (min-width: 1200px) {
  .ant-col-xl-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-xl-push-24 {
    left: 100%;
  }
  .ant-col-xl-pull-24 {
    right: 100%;
  }
  .ant-col-xl-offset-24 {
    margin-left: 100%;
  }
  .ant-col-xl-order-24 {
    order: 24;
  }
  .ant-col-xl-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-xl-push-23 {
    left: 95.83333333%;
  }
  .ant-col-xl-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-xl-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-xl-order-23 {
    order: 23;
  }
  .ant-col-xl-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-xl-push-22 {
    left: 91.66666667%;
  }
  .ant-col-xl-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-xl-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-xl-order-22 {
    order: 22;
  }
  .ant-col-xl-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-xl-push-21 {
    left: 87.5%;
  }
  .ant-col-xl-pull-21 {
    right: 87.5%;
  }
  .ant-col-xl-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-xl-order-21 {
    order: 21;
  }
  .ant-col-xl-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-xl-push-20 {
    left: 83.33333333%;
  }
  .ant-col-xl-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-xl-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-xl-order-20 {
    order: 20;
  }
  .ant-col-xl-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-xl-push-19 {
    left: 79.16666667%;
  }
  .ant-col-xl-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-xl-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-xl-order-19 {
    order: 19;
  }
  .ant-col-xl-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-xl-push-18 {
    left: 75%;
  }
  .ant-col-xl-pull-18 {
    right: 75%;
  }
  .ant-col-xl-offset-18 {
    margin-left: 75%;
  }
  .ant-col-xl-order-18 {
    order: 18;
  }
  .ant-col-xl-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-xl-push-17 {
    left: 70.83333333%;
  }
  .ant-col-xl-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-xl-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-xl-order-17 {
    order: 17;
  }
  .ant-col-xl-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-xl-push-16 {
    left: 66.66666667%;
  }
  .ant-col-xl-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-xl-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-xl-order-16 {
    order: 16;
  }
  .ant-col-xl-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-xl-push-15 {
    left: 62.5%;
  }
  .ant-col-xl-pull-15 {
    right: 62.5%;
  }
  .ant-col-xl-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-xl-order-15 {
    order: 15;
  }
  .ant-col-xl-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-xl-push-14 {
    left: 58.33333333%;
  }
  .ant-col-xl-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-xl-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-xl-order-14 {
    order: 14;
  }
  .ant-col-xl-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-xl-push-13 {
    left: 54.16666667%;
  }
  .ant-col-xl-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-xl-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-xl-order-13 {
    order: 13;
  }
  .ant-col-xl-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-xl-push-12 {
    left: 50%;
  }
  .ant-col-xl-pull-12 {
    right: 50%;
  }
  .ant-col-xl-offset-12 {
    margin-left: 50%;
  }
  .ant-col-xl-order-12 {
    order: 12;
  }
  .ant-col-xl-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-xl-push-11 {
    left: 45.83333333%;
  }
  .ant-col-xl-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-xl-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-xl-order-11 {
    order: 11;
  }
  .ant-col-xl-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-xl-push-10 {
    left: 41.66666667%;
  }
  .ant-col-xl-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-xl-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-xl-order-10 {
    order: 10;
  }
  .ant-col-xl-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-xl-push-9 {
    left: 37.5%;
  }
  .ant-col-xl-pull-9 {
    right: 37.5%;
  }
  .ant-col-xl-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-xl-order-9 {
    order: 9;
  }
  .ant-col-xl-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-xl-push-8 {
    left: 33.33333333%;
  }
  .ant-col-xl-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-xl-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-xl-order-8 {
    order: 8;
  }
  .ant-col-xl-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-xl-push-7 {
    left: 29.16666667%;
  }
  .ant-col-xl-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-xl-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-xl-order-7 {
    order: 7;
  }
  .ant-col-xl-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-xl-push-6 {
    left: 25%;
  }
  .ant-col-xl-pull-6 {
    right: 25%;
  }
  .ant-col-xl-offset-6 {
    margin-left: 25%;
  }
  .ant-col-xl-order-6 {
    order: 6;
  }
  .ant-col-xl-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-xl-push-5 {
    left: 20.83333333%;
  }
  .ant-col-xl-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-xl-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-xl-order-5 {
    order: 5;
  }
  .ant-col-xl-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-xl-push-4 {
    left: 16.66666667%;
  }
  .ant-col-xl-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-xl-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-xl-order-4 {
    order: 4;
  }
  .ant-col-xl-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-xl-push-3 {
    left: 12.5%;
  }
  .ant-col-xl-pull-3 {
    right: 12.5%;
  }
  .ant-col-xl-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-xl-order-3 {
    order: 3;
  }
  .ant-col-xl-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-xl-push-2 {
    left: 8.33333333%;
  }
  .ant-col-xl-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-xl-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-xl-order-2 {
    order: 2;
  }
  .ant-col-xl-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-xl-push-1 {
    left: 4.16666667%;
  }
  .ant-col-xl-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-xl-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-xl-order-1 {
    order: 1;
  }
  .ant-col-xl-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-xl-push-0 {
    left: auto;
  }
  .ant-col-xl-pull-0 {
    right: auto;
  }
  .ant-col-xl-offset-0 {
    margin-left: 0;
  }
  .ant-col-xl-order-0 {
    order: 0;
  }
  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-xl-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-xl-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-xl-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ant-col-xl-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .ant-col-xl-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .ant-col-xl-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .ant-col-xl-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .ant-col-xl-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .ant-col-xl-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .ant-col-xl-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .ant-col-xl-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .ant-col-xl-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .ant-col-xl-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .ant-col-xl-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .ant-col-xl-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }
  .ant-col-xl-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }
  .ant-col-xl-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .ant-col-xl-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .ant-col-xl-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .ant-col-xl-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .ant-col-xl-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .ant-col-xl-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .ant-col-xl-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .ant-col-xl-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .ant-col-xl-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .ant-col-xl-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .ant-col-xl-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .ant-col-xl-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .ant-col-xl-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }
  .ant-col-xl-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }
  .ant-col-xl-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .ant-col-xl-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .ant-col-xl-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .ant-col-xl-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .ant-col-xl-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .ant-col-xl-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .ant-col-xl-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .ant-col-xl-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .ant-col-xl-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .ant-col-xl-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .ant-col-xl-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .ant-col-xl-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .ant-col-xl-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }
  .ant-col-xl-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }
  .ant-col-xl-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .ant-col-xl-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .ant-col-xl-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .ant-col-xl-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .ant-col-xl-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .ant-col-xl-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .ant-col-xl-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .ant-col-xl-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .ant-col-xl-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .ant-col-xl-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .ant-col-xl-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .ant-col-xl-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .ant-col-xl-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .ant-col-xl-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .ant-col-xl-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }
  .ant-col-xl-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }
  .ant-col-xl-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
@media (min-width: 1600px) {
  .ant-col-xxl-24 {
    display: block;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ant-col-xxl-push-24 {
    left: 100%;
  }
  .ant-col-xxl-pull-24 {
    right: 100%;
  }
  .ant-col-xxl-offset-24 {
    margin-left: 100%;
  }
  .ant-col-xxl-order-24 {
    order: 24;
  }
  .ant-col-xxl-23 {
    display: block;
    flex: 0 0 95.83333333%;
    max-width: 95.83333333%;
  }
  .ant-col-xxl-push-23 {
    left: 95.83333333%;
  }
  .ant-col-xxl-pull-23 {
    right: 95.83333333%;
  }
  .ant-col-xxl-offset-23 {
    margin-left: 95.83333333%;
  }
  .ant-col-xxl-order-23 {
    order: 23;
  }
  .ant-col-xxl-22 {
    display: block;
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }
  .ant-col-xxl-push-22 {
    left: 91.66666667%;
  }
  .ant-col-xxl-pull-22 {
    right: 91.66666667%;
  }
  .ant-col-xxl-offset-22 {
    margin-left: 91.66666667%;
  }
  .ant-col-xxl-order-22 {
    order: 22;
  }
  .ant-col-xxl-21 {
    display: block;
    flex: 0 0 87.5%;
    max-width: 87.5%;
  }
  .ant-col-xxl-push-21 {
    left: 87.5%;
  }
  .ant-col-xxl-pull-21 {
    right: 87.5%;
  }
  .ant-col-xxl-offset-21 {
    margin-left: 87.5%;
  }
  .ant-col-xxl-order-21 {
    order: 21;
  }
  .ant-col-xxl-20 {
    display: block;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }
  .ant-col-xxl-push-20 {
    left: 83.33333333%;
  }
  .ant-col-xxl-pull-20 {
    right: 83.33333333%;
  }
  .ant-col-xxl-offset-20 {
    margin-left: 83.33333333%;
  }
  .ant-col-xxl-order-20 {
    order: 20;
  }
  .ant-col-xxl-19 {
    display: block;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .ant-col-xxl-push-19 {
    left: 79.16666667%;
  }
  .ant-col-xxl-pull-19 {
    right: 79.16666667%;
  }
  .ant-col-xxl-offset-19 {
    margin-left: 79.16666667%;
  }
  .ant-col-xxl-order-19 {
    order: 19;
  }
  .ant-col-xxl-18 {
    display: block;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .ant-col-xxl-push-18 {
    left: 75%;
  }
  .ant-col-xxl-pull-18 {
    right: 75%;
  }
  .ant-col-xxl-offset-18 {
    margin-left: 75%;
  }
  .ant-col-xxl-order-18 {
    order: 18;
  }
  .ant-col-xxl-17 {
    display: block;
    flex: 0 0 70.83333333%;
    max-width: 70.83333333%;
  }
  .ant-col-xxl-push-17 {
    left: 70.83333333%;
  }
  .ant-col-xxl-pull-17 {
    right: 70.83333333%;
  }
  .ant-col-xxl-offset-17 {
    margin-left: 70.83333333%;
  }
  .ant-col-xxl-order-17 {
    order: 17;
  }
  .ant-col-xxl-16 {
    display: block;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .ant-col-xxl-push-16 {
    left: 66.66666667%;
  }
  .ant-col-xxl-pull-16 {
    right: 66.66666667%;
  }
  .ant-col-xxl-offset-16 {
    margin-left: 66.66666667%;
  }
  .ant-col-xxl-order-16 {
    order: 16;
  }
  .ant-col-xxl-15 {
    display: block;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .ant-col-xxl-push-15 {
    left: 62.5%;
  }
  .ant-col-xxl-pull-15 {
    right: 62.5%;
  }
  .ant-col-xxl-offset-15 {
    margin-left: 62.5%;
  }
  .ant-col-xxl-order-15 {
    order: 15;
  }
  .ant-col-xxl-14 {
    display: block;
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }
  .ant-col-xxl-push-14 {
    left: 58.33333333%;
  }
  .ant-col-xxl-pull-14 {
    right: 58.33333333%;
  }
  .ant-col-xxl-offset-14 {
    margin-left: 58.33333333%;
  }
  .ant-col-xxl-order-14 {
    order: 14;
  }
  .ant-col-xxl-13 {
    display: block;
    flex: 0 0 54.16666667%;
    max-width: 54.16666667%;
  }
  .ant-col-xxl-push-13 {
    left: 54.16666667%;
  }
  .ant-col-xxl-pull-13 {
    right: 54.16666667%;
  }
  .ant-col-xxl-offset-13 {
    margin-left: 54.16666667%;
  }
  .ant-col-xxl-order-13 {
    order: 13;
  }
  .ant-col-xxl-12 {
    display: block;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ant-col-xxl-push-12 {
    left: 50%;
  }
  .ant-col-xxl-pull-12 {
    right: 50%;
  }
  .ant-col-xxl-offset-12 {
    margin-left: 50%;
  }
  .ant-col-xxl-order-12 {
    order: 12;
  }
  .ant-col-xxl-11 {
    display: block;
    flex: 0 0 45.83333333%;
    max-width: 45.83333333%;
  }
  .ant-col-xxl-push-11 {
    left: 45.83333333%;
  }
  .ant-col-xxl-pull-11 {
    right: 45.83333333%;
  }
  .ant-col-xxl-offset-11 {
    margin-left: 45.83333333%;
  }
  .ant-col-xxl-order-11 {
    order: 11;
  }
  .ant-col-xxl-10 {
    display: block;
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }
  .ant-col-xxl-push-10 {
    left: 41.66666667%;
  }
  .ant-col-xxl-pull-10 {
    right: 41.66666667%;
  }
  .ant-col-xxl-offset-10 {
    margin-left: 41.66666667%;
  }
  .ant-col-xxl-order-10 {
    order: 10;
  }
  .ant-col-xxl-9 {
    display: block;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .ant-col-xxl-push-9 {
    left: 37.5%;
  }
  .ant-col-xxl-pull-9 {
    right: 37.5%;
  }
  .ant-col-xxl-offset-9 {
    margin-left: 37.5%;
  }
  .ant-col-xxl-order-9 {
    order: 9;
  }
  .ant-col-xxl-8 {
    display: block;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .ant-col-xxl-push-8 {
    left: 33.33333333%;
  }
  .ant-col-xxl-pull-8 {
    right: 33.33333333%;
  }
  .ant-col-xxl-offset-8 {
    margin-left: 33.33333333%;
  }
  .ant-col-xxl-order-8 {
    order: 8;
  }
  .ant-col-xxl-7 {
    display: block;
    flex: 0 0 29.16666667%;
    max-width: 29.16666667%;
  }
  .ant-col-xxl-push-7 {
    left: 29.16666667%;
  }
  .ant-col-xxl-pull-7 {
    right: 29.16666667%;
  }
  .ant-col-xxl-offset-7 {
    margin-left: 29.16666667%;
  }
  .ant-col-xxl-order-7 {
    order: 7;
  }
  .ant-col-xxl-6 {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .ant-col-xxl-push-6 {
    left: 25%;
  }
  .ant-col-xxl-pull-6 {
    right: 25%;
  }
  .ant-col-xxl-offset-6 {
    margin-left: 25%;
  }
  .ant-col-xxl-order-6 {
    order: 6;
  }
  .ant-col-xxl-5 {
    display: block;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .ant-col-xxl-push-5 {
    left: 20.83333333%;
  }
  .ant-col-xxl-pull-5 {
    right: 20.83333333%;
  }
  .ant-col-xxl-offset-5 {
    margin-left: 20.83333333%;
  }
  .ant-col-xxl-order-5 {
    order: 5;
  }
  .ant-col-xxl-4 {
    display: block;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }
  .ant-col-xxl-push-4 {
    left: 16.66666667%;
  }
  .ant-col-xxl-pull-4 {
    right: 16.66666667%;
  }
  .ant-col-xxl-offset-4 {
    margin-left: 16.66666667%;
  }
  .ant-col-xxl-order-4 {
    order: 4;
  }
  .ant-col-xxl-3 {
    display: block;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .ant-col-xxl-push-3 {
    left: 12.5%;
  }
  .ant-col-xxl-pull-3 {
    right: 12.5%;
  }
  .ant-col-xxl-offset-3 {
    margin-left: 12.5%;
  }
  .ant-col-xxl-order-3 {
    order: 3;
  }
  .ant-col-xxl-2 {
    display: block;
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }
  .ant-col-xxl-push-2 {
    left: 8.33333333%;
  }
  .ant-col-xxl-pull-2 {
    right: 8.33333333%;
  }
  .ant-col-xxl-offset-2 {
    margin-left: 8.33333333%;
  }
  .ant-col-xxl-order-2 {
    order: 2;
  }
  .ant-col-xxl-1 {
    display: block;
    flex: 0 0 4.16666667%;
    max-width: 4.16666667%;
  }
  .ant-col-xxl-push-1 {
    left: 4.16666667%;
  }
  .ant-col-xxl-pull-1 {
    right: 4.16666667%;
  }
  .ant-col-xxl-offset-1 {
    margin-left: 4.16666667%;
  }
  .ant-col-xxl-order-1 {
    order: 1;
  }
  .ant-col-xxl-0 {
    display: none;
  }
  .ant-col-push-0 {
    left: auto;
  }
  .ant-col-pull-0 {
    right: auto;
  }
  .ant-col-xxl-push-0 {
    left: auto;
  }
  .ant-col-xxl-pull-0 {
    right: auto;
  }
  .ant-col-xxl-offset-0 {
    margin-left: 0;
  }
  .ant-col-xxl-order-0 {
    order: 0;
  }
  .ant-col-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-xxl-push-0.ant-col-rtl {
    right: auto;
  }
  .ant-col-xxl-pull-0.ant-col-rtl {
    left: auto;
  }
  .ant-col-xxl-offset-0.ant-col-rtl {
    margin-right: 0;
  }
  .ant-col-xxl-push-1.ant-col-rtl {
    right: 4.16666667%;
    left: auto;
  }
  .ant-col-xxl-pull-1.ant-col-rtl {
    right: auto;
    left: 4.16666667%;
  }
  .ant-col-xxl-offset-1.ant-col-rtl {
    margin-right: 4.16666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-2.ant-col-rtl {
    right: 8.33333333%;
    left: auto;
  }
  .ant-col-xxl-pull-2.ant-col-rtl {
    right: auto;
    left: 8.33333333%;
  }
  .ant-col-xxl-offset-2.ant-col-rtl {
    margin-right: 8.33333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-3.ant-col-rtl {
    right: 12.5%;
    left: auto;
  }
  .ant-col-xxl-pull-3.ant-col-rtl {
    right: auto;
    left: 12.5%;
  }
  .ant-col-xxl-offset-3.ant-col-rtl {
    margin-right: 12.5%;
    margin-left: 0;
  }
  .ant-col-xxl-push-4.ant-col-rtl {
    right: 16.66666667%;
    left: auto;
  }
  .ant-col-xxl-pull-4.ant-col-rtl {
    right: auto;
    left: 16.66666667%;
  }
  .ant-col-xxl-offset-4.ant-col-rtl {
    margin-right: 16.66666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-5.ant-col-rtl {
    right: 20.83333333%;
    left: auto;
  }
  .ant-col-xxl-pull-5.ant-col-rtl {
    right: auto;
    left: 20.83333333%;
  }
  .ant-col-xxl-offset-5.ant-col-rtl {
    margin-right: 20.83333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-6.ant-col-rtl {
    right: 25%;
    left: auto;
  }
  .ant-col-xxl-pull-6.ant-col-rtl {
    right: auto;
    left: 25%;
  }
  .ant-col-xxl-offset-6.ant-col-rtl {
    margin-right: 25%;
    margin-left: 0;
  }
  .ant-col-xxl-push-7.ant-col-rtl {
    right: 29.16666667%;
    left: auto;
  }
  .ant-col-xxl-pull-7.ant-col-rtl {
    right: auto;
    left: 29.16666667%;
  }
  .ant-col-xxl-offset-7.ant-col-rtl {
    margin-right: 29.16666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-8.ant-col-rtl {
    right: 33.33333333%;
    left: auto;
  }
  .ant-col-xxl-pull-8.ant-col-rtl {
    right: auto;
    left: 33.33333333%;
  }
  .ant-col-xxl-offset-8.ant-col-rtl {
    margin-right: 33.33333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-9.ant-col-rtl {
    right: 37.5%;
    left: auto;
  }
  .ant-col-xxl-pull-9.ant-col-rtl {
    right: auto;
    left: 37.5%;
  }
  .ant-col-xxl-offset-9.ant-col-rtl {
    margin-right: 37.5%;
    margin-left: 0;
  }
  .ant-col-xxl-push-10.ant-col-rtl {
    right: 41.66666667%;
    left: auto;
  }
  .ant-col-xxl-pull-10.ant-col-rtl {
    right: auto;
    left: 41.66666667%;
  }
  .ant-col-xxl-offset-10.ant-col-rtl {
    margin-right: 41.66666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-11.ant-col-rtl {
    right: 45.83333333%;
    left: auto;
  }
  .ant-col-xxl-pull-11.ant-col-rtl {
    right: auto;
    left: 45.83333333%;
  }
  .ant-col-xxl-offset-11.ant-col-rtl {
    margin-right: 45.83333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-12.ant-col-rtl {
    right: 50%;
    left: auto;
  }
  .ant-col-xxl-pull-12.ant-col-rtl {
    right: auto;
    left: 50%;
  }
  .ant-col-xxl-offset-12.ant-col-rtl {
    margin-right: 50%;
    margin-left: 0;
  }
  .ant-col-xxl-push-13.ant-col-rtl {
    right: 54.16666667%;
    left: auto;
  }
  .ant-col-xxl-pull-13.ant-col-rtl {
    right: auto;
    left: 54.16666667%;
  }
  .ant-col-xxl-offset-13.ant-col-rtl {
    margin-right: 54.16666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-14.ant-col-rtl {
    right: 58.33333333%;
    left: auto;
  }
  .ant-col-xxl-pull-14.ant-col-rtl {
    right: auto;
    left: 58.33333333%;
  }
  .ant-col-xxl-offset-14.ant-col-rtl {
    margin-right: 58.33333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-15.ant-col-rtl {
    right: 62.5%;
    left: auto;
  }
  .ant-col-xxl-pull-15.ant-col-rtl {
    right: auto;
    left: 62.5%;
  }
  .ant-col-xxl-offset-15.ant-col-rtl {
    margin-right: 62.5%;
    margin-left: 0;
  }
  .ant-col-xxl-push-16.ant-col-rtl {
    right: 66.66666667%;
    left: auto;
  }
  .ant-col-xxl-pull-16.ant-col-rtl {
    right: auto;
    left: 66.66666667%;
  }
  .ant-col-xxl-offset-16.ant-col-rtl {
    margin-right: 66.66666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-17.ant-col-rtl {
    right: 70.83333333%;
    left: auto;
  }
  .ant-col-xxl-pull-17.ant-col-rtl {
    right: auto;
    left: 70.83333333%;
  }
  .ant-col-xxl-offset-17.ant-col-rtl {
    margin-right: 70.83333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-18.ant-col-rtl {
    right: 75%;
    left: auto;
  }
  .ant-col-xxl-pull-18.ant-col-rtl {
    right: auto;
    left: 75%;
  }
  .ant-col-xxl-offset-18.ant-col-rtl {
    margin-right: 75%;
    margin-left: 0;
  }
  .ant-col-xxl-push-19.ant-col-rtl {
    right: 79.16666667%;
    left: auto;
  }
  .ant-col-xxl-pull-19.ant-col-rtl {
    right: auto;
    left: 79.16666667%;
  }
  .ant-col-xxl-offset-19.ant-col-rtl {
    margin-right: 79.16666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-20.ant-col-rtl {
    right: 83.33333333%;
    left: auto;
  }
  .ant-col-xxl-pull-20.ant-col-rtl {
    right: auto;
    left: 83.33333333%;
  }
  .ant-col-xxl-offset-20.ant-col-rtl {
    margin-right: 83.33333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-21.ant-col-rtl {
    right: 87.5%;
    left: auto;
  }
  .ant-col-xxl-pull-21.ant-col-rtl {
    right: auto;
    left: 87.5%;
  }
  .ant-col-xxl-offset-21.ant-col-rtl {
    margin-right: 87.5%;
    margin-left: 0;
  }
  .ant-col-xxl-push-22.ant-col-rtl {
    right: 91.66666667%;
    left: auto;
  }
  .ant-col-xxl-pull-22.ant-col-rtl {
    right: auto;
    left: 91.66666667%;
  }
  .ant-col-xxl-offset-22.ant-col-rtl {
    margin-right: 91.66666667%;
    margin-left: 0;
  }
  .ant-col-xxl-push-23.ant-col-rtl {
    right: 95.83333333%;
    left: auto;
  }
  .ant-col-xxl-pull-23.ant-col-rtl {
    right: auto;
    left: 95.83333333%;
  }
  .ant-col-xxl-offset-23.ant-col-rtl {
    margin-right: 95.83333333%;
    margin-left: 0;
  }
  .ant-col-xxl-push-24.ant-col-rtl {
    right: 100%;
    left: auto;
  }
  .ant-col-xxl-pull-24.ant-col-rtl {
    right: auto;
    left: 100%;
  }
  .ant-col-xxl-offset-24.ant-col-rtl {
    margin-right: 100%;
    margin-left: 0;
  }
}
.ant-row-rtl {
  direction: rtl;
}
.ant-image {
  position: relative;
  display: inline-block;
}
.ant-image-img {
  display: block;
  width: 100%;
  height: auto;
}
.ant-image-img-placeholder {
  background-color: #f5f5f5;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTQuNSAyLjVoLTEzQS41LjUgMCAwIDAgMSAzdjEwYS41LjUgMCAwIDAgLjUuNWgxM2EuNS41IDAgMCAwIC41LS41VjNhLjUuNSAwIDAgMC0uNS0uNXpNNS4yODEgNC43NWExIDEgMCAwIDEgMCAyIDEgMSAwIDAgMSAwLTJ6bTguMDMgNi44M2EuMTI3LjEyNyAwIDAgMS0uMDgxLjAzSDIuNzY5YS4xMjUuMTI1IDAgMCAxLS4wOTYtLjIwN2wyLjY2MS0zLjE1NmEuMTI2LjEyNiAwIDAgMSAuMTc3LS4wMTZsLjAxNi4wMTZMNy4wOCAxMC4wOWwyLjQ3LTIuOTNhLjEyNi4xMjYgMCAwIDEgLjE3Ny0uMDE2bC4wMTUuMDE2IDMuNTg4IDQuMjQ0YS4xMjcuMTI3IDAgMCAxLS4wMi4xNzV6IiBmaWxsPSIjOEM4QzhDIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48L3N2Zz4=);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 30%;
}
.ant-image-mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s;
}
.ant-image-mask-info .anticon {
  margin-inline-end: 4px;
}
.ant-image-mask:hover {
  opacity: 1;
}
.ant-image-placeholder {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.ant-image-preview {
  pointer-events: none;
  height: 100%;
  text-align: center;
}
.ant-image-preview.zoom-enter,
.ant-image-preview.zoom-appear {
  transform: none;
  opacity: 0;
  animation-duration: 0.3s;
  user-select: none;
}
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}
.ant-image-preview-mask-hidden {
  display: none;
}
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch;
}
.ant-image-preview-body {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}
.ant-image-preview-img {
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
  transform: scale3d(1, 1, 1);
  cursor: grab;
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  user-select: none;
  pointer-events: auto;
}
.ant-image-preview-img-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
}
.ant-image-preview-img-wrapper::before {
  display: inline-block;
  width: 1px;
  height: 50%;
  margin-right: -1px;
  content: '';
}
.ant-image-preview-moving .ant-image-preview-img {
  cursor: grabbing;
}
.ant-image-preview-moving .ant-image-preview-img-wrapper {
  transition-duration: 0s;
}
.ant-image-preview-wrap {
  z-index: 1080;
}
.ant-image-preview-operations {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  font-feature-settings: 'tnum';
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  width: 100%;
  color: rgba(255, 255, 255, 0.85);
  list-style: none;
  background: rgba(0, 0, 0, 0.1);
  pointer-events: auto;
}
.ant-image-preview-operations-operation {
  margin-left: 12px;
  padding: 12px;
  cursor: pointer;
}
.ant-image-preview-operations-operation-disabled {
  color: rgba(255, 255, 255, 0.25);
  pointer-events: none;
}
.ant-image-preview-operations-operation:last-of-type {
  margin-left: 0;
}
.ant-image-preview-operations-icon {
  font-size: 18px;
}
.ant-image-preview-switch-left,
.ant-image-preview-switch-right {
  position: absolute;
  top: 50%;
  right: 10px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  margin-top: -22px;
  color: rgba(255, 255, 255, 0.85);
  background: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  cursor: pointer;
  pointer-events: auto;
}
.ant-image-preview-switch-left-disabled,
.ant-image-preview-switch-right-disabled {
  color: rgba(255, 255, 255, 0.25);
  cursor: not-allowed;
}
.ant-image-preview-switch-left-disabled > .anticon,
.ant-image-preview-switch-right-disabled > .anticon {
  cursor: not-allowed;
}
.ant-image-preview-switch-left > .anticon,
.ant-image-preview-switch-right > .anticon {
  font-size: 18px;
}
.ant-image-preview-switch-left {
  left: 10px;
}
.ant-image-preview-switch-right {
  right: 10px;
}
.ant-input-affix-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #AAAAAA;
  border-radius: 20px;
  transition: all 0.3s;
  display: inline-flex;
}
.ant-input-affix-wrapper::-moz-placeholder {
  opacity: 1;
}
.ant-input-affix-wrapper::placeholder {
  color: #bfbfbf;
}
.ant-input-affix-wrapper:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input-affix-wrapper:hover {
  border-color: #0d522a;
  border-right-width: 1px !important;
}
.ant-input-rtl .ant-input-affix-wrapper:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: #0d522a;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(0, 68, 31, 0.2);
}
.ant-input-rtl .ant-input-affix-wrapper:focus,
.ant-input-rtl .ant-input-affix-wrapper-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-affix-wrapper-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-affix-wrapper-disabled:hover {
  border-color: #AAAAAA;
  border-right-width: 1px !important;
}
.ant-input-affix-wrapper[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-affix-wrapper[disabled]:hover {
  border-color: #AAAAAA;
  border-right-width: 1px !important;
}
.ant-input-affix-wrapper-borderless,
.ant-input-affix-wrapper-borderless:hover,
.ant-input-affix-wrapper-borderless:focus,
.ant-input-affix-wrapper-borderless-focused,
.ant-input-affix-wrapper-borderless-disabled,
.ant-input-affix-wrapper-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ant-input-affix-wrapper {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-input-affix-wrapper-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}
.ant-input-affix-wrapper-sm {
  padding: 0px 7px;
}
.ant-input-affix-wrapper-rtl {
  direction: rtl;
}
.ant-input-affix-wrapper:hover {
  border-color: #0d522a;
  border-right-width: 1px !important;
  z-index: 1;
}
.ant-input-rtl .ant-input-affix-wrapper:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-search-with-button .ant-input-affix-wrapper:hover {
  z-index: 0;
}
.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper:focus {
  z-index: 1;
}
.ant-input-affix-wrapper-disabled .ant-input[disabled] {
  background: transparent;
}
.ant-input-affix-wrapper > input.ant-input {
  padding: 0;
  border: none;
  outline: none;
}
.ant-input-affix-wrapper > input.ant-input:focus {
  box-shadow: none;
}
.ant-input-affix-wrapper::before {
  width: 0;
  visibility: hidden;
  content: '\a0';
}
.ant-input-prefix,
.ant-input-suffix {
  display: flex;
  flex: none;
  align-items: center;
}
.ant-input-prefix {
  margin-right: 4px;
}
.ant-input-suffix {
  margin-left: 4px;
}
.ant-input-clear-icon {
  margin: 0 4px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  vertical-align: -1px;
  cursor: pointer;
  transition: color 0.3s;
}
.ant-input-clear-icon:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-input-clear-icon:active {
  color: rgba(0, 0, 0, 0.85);
}
.ant-input-clear-icon-hidden {
  visibility: hidden;
}
.ant-input-clear-icon:last-child {
  margin-right: 0;
}
.ant-input-affix-wrapper-textarea-with-clear-btn {
  padding: 0 !important;
  border: 0 !important;
}
.ant-input-affix-wrapper-textarea-with-clear-btn .ant-input-clear-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;
}
.ant-input {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #AAAAAA;
  border-radius: 20px;
  transition: all 0.3s;
}
.ant-input::-moz-placeholder {
  opacity: 1;
}
.ant-input::placeholder {
  color: #bfbfbf;
}
.ant-input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input:hover {
  border-color: #0d522a;
  border-right-width: 1px !important;
}
.ant-input-rtl .ant-input:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input:focus,
.ant-input-focused {
  border-color: #0d522a;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(0, 68, 31, 0.2);
}
.ant-input-rtl .ant-input:focus,
.ant-input-rtl .ant-input-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-disabled:hover {
  border-color: #AAAAAA;
  border-right-width: 1px !important;
}
.ant-input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input[disabled]:hover {
  border-color: #AAAAAA;
  border-right-width: 1px !important;
}
.ant-input-borderless,
.ant-input-borderless:hover,
.ant-input-borderless:focus,
.ant-input-borderless-focused,
.ant-input-borderless-disabled,
.ant-input-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ant-input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-input-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}
.ant-input-sm {
  padding: 0px 7px;
}
.ant-input-rtl {
  direction: rtl;
}
.ant-input-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}
.ant-input-group[class*='col-'] {
  float: none;
  padding-right: 0;
  padding-left: 0;
}
.ant-input-group > [class*='col-'] {
  padding-right: 8px;
}
.ant-input-group > [class*='col-']:last-child {
  padding-right: 0;
}
.ant-input-group-addon,
.ant-input-group-wrap,
.ant-input-group > .ant-input {
  display: table-cell;
}
.ant-input-group-addon:not(:first-child):not(:last-child),
.ant-input-group-wrap:not(:first-child):not(:last-child),
.ant-input-group > .ant-input:not(:first-child):not(:last-child) {
  border-radius: 0;
}
.ant-input-group-addon,
.ant-input-group-wrap {
  width: 1px;
  white-space: nowrap;
  vertical-align: middle;
}
.ant-input-group-wrap > * {
  display: block !important;
}
.ant-input-group .ant-input {
  float: left;
  width: 100%;
  margin-bottom: 0;
  text-align: inherit;
}
.ant-input-group .ant-input:focus {
  z-index: 1;
  border-right-width: 1px;
}
.ant-input-group .ant-input:hover {
  z-index: 1;
  border-right-width: 1px;
}
.ant-input-search-with-button .ant-input-group .ant-input:hover {
  z-index: 0;
}
.ant-input-group-addon {
  position: relative;
  padding: 0 11px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  background-color: #fafafa;
  border: 1px solid #AAAAAA;
  border-radius: 20px;
  transition: all 0.3s;
}
.ant-input-group-addon .ant-select {
  margin: -5px -11px;
}
.ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  background-color: inherit;
  border: 1px solid transparent;
  box-shadow: none;
}
.ant-input-group-addon .ant-select-open .ant-select-selector,
.ant-input-group-addon .ant-select-focused .ant-select-selector {
  color: #00441F;
}
.ant-input-group > .ant-input:first-child,
.ant-input-group-addon:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-group > .ant-input:first-child .ant-select .ant-select-selector,
.ant-input-group-addon:first-child .ant-select .ant-select-selector {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-group > .ant-input-affix-wrapper:not(:first-child) .ant-input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-group-addon:first-child {
  border-right: 0;
}
.ant-input-group-addon:last-child {
  border-left: 0;
}
.ant-input-group > .ant-input:last-child,
.ant-input-group-addon:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-group > .ant-input:last-child .ant-select .ant-select-selector,
.ant-input-group-addon:last-child .ant-select .ant-select-selector {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-group-lg .ant-input,
.ant-input-group-lg > .ant-input-group-addon {
  padding: 6.5px 11px;
  font-size: 16px;
}
.ant-input-group-sm .ant-input,
.ant-input-group-sm > .ant-input-group-addon {
  padding: 0px 7px;
}
.ant-input-group-lg .ant-select-single .ant-select-selector {
  height: 40px;
}
.ant-input-group-sm .ant-select-single .ant-select-selector {
  height: 24px;
}
.ant-input-group .ant-input-affix-wrapper:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.ant-input-group.ant-input-group-compact {
  display: block;
}
.ant-input-group.ant-input-group-compact::before {
  display: table;
  content: '';
}
.ant-input-group.ant-input-group-compact::after {
  display: table;
  clear: both;
  content: '';
}
.ant-input-group.ant-input-group-compact::before {
  display: table;
  content: '';
}
.ant-input-group.ant-input-group-compact::after {
  display: table;
  clear: both;
  content: '';
}
.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child),
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child),
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child) {
  border-right-width: 1px;
}
.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):hover,
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):hover,
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):hover {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):focus,
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):focus,
.ant-input-group.ant-input-group-compact > .ant-input:not(:first-child):not(:last-child):focus {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > * {
  display: inline-block;
  float: none;
  vertical-align: top;
  border-radius: 0;
}
.ant-input-group.ant-input-group-compact > .ant-input-affix-wrapper {
  display: inline-flex;
}
.ant-input-group.ant-input-group-compact > .ant-picker-range {
  display: inline-flex;
}
.ant-input-group.ant-input-group-compact > *:not(:last-child) {
  margin-right: -1px;
  border-right-width: 1px;
}
.ant-input-group.ant-input-group-compact .ant-input {
  float: none;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input,
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input {
  border-right-width: 1px;
  border-radius: 0;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector:hover,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:hover,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:hover,
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input:hover {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-selector:focus,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input:focus,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker .ant-input:focus,
.ant-input-group.ant-input-group-compact > .ant-input-group-wrapper .ant-input:focus {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > .ant-select-focused {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > .ant-select > .ant-select-arrow {
  z-index: 1;
}
.ant-input-group.ant-input-group-compact > *:first-child,
.ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.ant-input-group.ant-input-group-compact > *:last-child,
.ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input {
  border-right-width: 1px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete .ant-input {
  vertical-align: top;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper + .ant-input-group-wrapper {
  margin-left: -1px;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper + .ant-input-group-wrapper .ant-input-affix-wrapper {
  border-radius: 0;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search > .ant-input-group > .ant-input-group-addon > .ant-input-search-button {
  border-radius: 0;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search > .ant-input-group > .ant-input {
  border-radius: 20px 0 0 20px;
}
.ant-input-group > .ant-input-rtl:first-child,
.ant-input-group-rtl .ant-input-group-addon:first-child {
  border-radius: 0 20px 20px 0;
}
.ant-input-group-rtl .ant-input-group-addon:first-child {
  border-right: 1px solid #AAAAAA;
  border-left: 0;
}
.ant-input-group-rtl .ant-input-group-addon:last-child {
  border-right: 0;
  border-left: 1px solid #AAAAAA;
}
.ant-input-group-rtl.ant-input-group > .ant-input:last-child,
.ant-input-group-rtl.ant-input-group-addon:last-child {
  border-radius: 20px 0 0 20px;
}
.ant-input-group-rtl.ant-input-group .ant-input-affix-wrapper:not(:first-child) {
  border-radius: 20px 0 0 20px;
}
.ant-input-group-rtl.ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-radius: 0 20px 20px 0;
}
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:not(:last-child) {
  margin-right: 0;
  margin-left: -1px;
  border-left-width: 1px;
}
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:first-child,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input {
  border-radius: 0 20px 20px 0;
}
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > *:last-child,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select:last-child > .ant-select-selector,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:last-child .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker:last-child .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact > .ant-cascader-picker-focused:last-child .ant-input {
  border-left-width: 1px;
  border-radius: 20px 0 0 20px;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper-rtl + .ant-input-group-wrapper-rtl {
  margin-right: -1px;
  margin-left: 0;
}
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper-rtl:not(:last-child).ant-input-search > .ant-input-group > .ant-input {
  border-radius: 0 20px 20px 0;
}
.ant-input-group-wrapper {
  display: inline-block;
  width: 100%;
  text-align: start;
  vertical-align: top;
}
.ant-input-password-icon {
  color: rgba(0, 0, 0, 0.45);
  cursor: pointer;
  transition: all 0.3s;
}
.ant-input-password-icon:hover {
  color: rgba(0, 0, 0, 0.85);
}
.ant-input[type='color'] {
  height: 32px;
}
.ant-input[type='color'].ant-input-lg {
  height: 40px;
}
.ant-input[type='color'].ant-input-sm {
  height: 24px;
  padding-top: 3px;
  padding-bottom: 3px;
}
.ant-input-textarea-show-count::after {
  display: block;
  color: rgba(0, 0, 0, 0.45);
  text-align: right;
  content: attr(data-count);
}
.ant-input-search .ant-input:hover,
.ant-input-search .ant-input:focus {
  border-color: #0d522a;
}
.ant-input-search .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary),
.ant-input-search .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
  border-left-color: #0d522a;
}
.ant-input-search .ant-input-affix-wrapper {
  border-radius: 0;
}
.ant-input-search .ant-input-lg {
  line-height: 1.5713;
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child {
  left: -1px;
  padding: 0;
  border: 0;
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
  padding-top: 0;
  padding-bottom: 0;
  border-radius: 0 20px 20px 0;
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary) {
  color: rgba(0, 0, 0, 0.45);
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary).ant-btn-loading::before {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.ant-input-search-button {
  height: 32px;
}
.ant-input-search-button:hover,
.ant-input-search-button:focus {
  z-index: 1;
}
.ant-input-search-large .ant-input-search-button {
  height: 40px;
}
.ant-input-search-small .ant-input-search-button {
  height: 24px;
}
.ant-input-group-wrapper-rtl {
  direction: rtl;
}
.ant-input-group-rtl {
  direction: rtl;
}
.ant-input-affix-wrapper.ant-input-affix-wrapper-rtl > input.ant-input {
  border: none;
  outline: none;
}
.ant-input-affix-wrapper-rtl .ant-input-prefix {
  margin: 0 0 0 4px;
}
.ant-input-affix-wrapper-rtl .ant-input-suffix {
  margin: 0 4px 0 0;
}
.ant-input-textarea-rtl {
  direction: rtl;
}
.ant-input-textarea-rtl.ant-input-textarea-show-count::after {
  text-align: left;
}
.ant-input-affix-wrapper-rtl .ant-input-clear-icon:last-child {
  margin-right: 4px;
  margin-left: 0;
}
.ant-input-affix-wrapper-rtl .ant-input-clear-icon {
  right: auto;
  left: 8px;
}
.ant-input-search-rtl {
  direction: rtl;
}
.ant-input-search-rtl .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary),
.ant-input-search-rtl .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
  border-right-color: #0d522a;
  border-left-color: #d9d9d9;
}
.ant-input-search-rtl > .ant-input-group > .ant-input-affix-wrapper:hover,
.ant-input-search-rtl > .ant-input-group > .ant-input-affix-wrapper-focused {
  border-right-color: #0d522a;
}
.ant-input-search-rtl > .ant-input-group > .ant-input-group-addon {
  right: -1px;
  left: auto;
}
.ant-input-search-rtl > .ant-input-group > .ant-input-group-addon .ant-input-search-button {
  border-radius: 20px 0 0 20px;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ant-input {
    height: 32px;
  }
  .ant-input-lg {
    height: 40px;
  }
  .ant-input-sm {
    height: 24px;
  }
  .ant-input-affix-wrapper > input.ant-input {
    height: auto;
  }
}
.ant-input-number {
  box-sizing: border-box;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #AAAAAA;
  transition: all 0.3s;
  display: inline-block;
  width: 90px;
  margin: 0;
  padding: 0;
  border: 1px solid #d9d9d9;
  border-radius: 20px;
}
.ant-input-number::-moz-placeholder {
  opacity: 1;
}
.ant-input-number::placeholder {
  color: #bfbfbf;
}
.ant-input-number:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input-number:hover {
  border-color: #0d522a;
  border-right-width: 1px !important;
}
.ant-input-rtl .ant-input-number:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-number:focus,
.ant-input-number-focused {
  border-color: #0d522a;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(0, 68, 31, 0.2);
}
.ant-input-rtl .ant-input-number:focus,
.ant-input-rtl .ant-input-number-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-number-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-number-disabled:hover {
  border-color: #AAAAAA;
  border-right-width: 1px !important;
}
.ant-input-number[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-number[disabled]:hover {
  border-color: #AAAAAA;
  border-right-width: 1px !important;
}
.ant-input-number-borderless,
.ant-input-number-borderless:hover,
.ant-input-number-borderless:focus,
.ant-input-number-borderless-focused,
.ant-input-number-borderless-disabled,
.ant-input-number-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ant-input-number {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-input-number-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}
.ant-input-number-sm {
  padding: 0px 7px;
}
.ant-input-number-rtl {
  direction: rtl;
}
.ant-input-number-handler {
  position: relative;
  display: block;
  width: 100%;
  height: 50%;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.45);
  font-weight: bold;
  line-height: 0;
  text-align: center;
  transition: all 0.1s linear;
}
.ant-input-number-handler:active {
  background: #f4f4f4;
}
.ant-input-number-handler:hover .ant-input-number-handler-up-inner,
.ant-input-number-handler:hover .ant-input-number-handler-down-inner {
  color: #0d522a;
}
.ant-input-number-handler-up-inner,
.ant-input-number-handler-down-inner {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  right: 4px;
  width: 12px;
  height: 12px;
  color: rgba(0, 0, 0, 0.45);
  line-height: 12px;
  transition: all 0.1s linear;
  user-select: none;
}
.ant-input-number-handler-up-inner > *,
.ant-input-number-handler-down-inner > * {
  line-height: 1;
}
.ant-input-number-handler-up-inner svg,
.ant-input-number-handler-down-inner svg {
  display: inline-block;
}
.ant-input-number-handler-up-inner::before,
.ant-input-number-handler-down-inner::before {
  display: none;
}
.ant-input-number-handler-up-inner .ant-input-number-handler-up-inner-icon,
.ant-input-number-handler-up-inner .ant-input-number-handler-down-inner-icon,
.ant-input-number-handler-down-inner .ant-input-number-handler-up-inner-icon,
.ant-input-number-handler-down-inner .ant-input-number-handler-down-inner-icon {
  display: block;
}
.ant-input-number:hover {
  border-color: #0d522a;
  border-right-width: 1px !important;
}
.ant-input-number:hover + .ant-form-item-children-icon {
  opacity: 0;
  transition: opacity 0.24s linear 0.24s;
}
.ant-input-number-focused {
  border-color: #0d522a;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(0, 68, 31, 0.2);
}
.ant-input-rtl .ant-input-number-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-input-number-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-input-number-disabled:hover {
  border-color: #AAAAAA;
  border-right-width: 1px !important;
}
.ant-input-number-disabled .ant-input-number-input {
  cursor: not-allowed;
}
.ant-input-number-disabled .ant-input-number-handler-wrap {
  display: none;
}
.ant-input-number-readonly .ant-input-number-handler-wrap {
  display: none;
}
.ant-input-number-input {
  width: 100%;
  height: 30px;
  padding: 0 11px;
  text-align: left;
  background-color: transparent;
  border: 0;
  border-radius: 20px;
  outline: 0;
  transition: all 0.3s linear;
  -moz-appearance: textfield !important;
}
.ant-input-number-input::-moz-placeholder {
  opacity: 1;
}
.ant-input-number-input::placeholder {
  color: #bfbfbf;
}
.ant-input-number-input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-input-number-input[type='number']::-webkit-inner-spin-button,
.ant-input-number-input[type='number']::-webkit-outer-spin-button {
  margin: 0;
  -webkit-appearance: none;
}
.ant-input-number-lg {
  padding: 0;
  font-size: 16px;
}
.ant-input-number-lg input {
  height: 38px;
}
.ant-input-number-sm {
  padding: 0;
}
.ant-input-number-sm input {
  height: 22px;
  padding: 0 7px;
}
.ant-input-number-handler-wrap {
  position: absolute;
  top: 0;
  right: 0;
  width: 22px;
  height: 100%;
  background: #fff;
  border-left: 1px solid #d9d9d9;
  border-radius: 0 20px 20px 0;
  opacity: 0;
  transition: opacity 0.24s linear 0.1s;
}
.ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-up-inner,
.ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-down-inner {
  min-width: auto;
  margin-right: 0;
  font-size: 7px;
}
.ant-input-number-handler-wrap:hover .ant-input-number-handler {
  height: 40%;
}
.ant-input-number:hover .ant-input-number-handler-wrap {
  opacity: 1;
}
.ant-input-number-handler-up {
  border-top-right-radius: 20px;
  cursor: pointer;
}
.ant-input-number-handler-up-inner {
  top: 50%;
  margin-top: -5px;
  text-align: center;
}
.ant-input-number-handler-up:hover {
  height: 60% !important;
}
.ant-input-number-handler-down {
  top: 0;
  border-top: 1px solid #d9d9d9;
  border-bottom-right-radius: 20px;
  cursor: pointer;
}
.ant-input-number-handler-down-inner {
  top: 50%;
  text-align: center;
  transform: translateY(-50%);
}
.ant-input-number-handler-down:hover {
  height: 60% !important;
}
.ant-input-number-handler-up-disabled,
.ant-input-number-handler-down-disabled {
  cursor: not-allowed;
}
.ant-input-number-handler-up-disabled:hover .ant-input-number-handler-up-inner,
.ant-input-number-handler-down-disabled:hover .ant-input-number-handler-down-inner {
  color: rgba(0, 0, 0, 0.25);
}
.ant-input-number-rtl {
  direction: rtl;
}
.ant-input-number-rtl .ant-input-number-handler-wrap {
  right: auto;
  left: 0;
  border-right: 1px solid #d9d9d9;
  border-left: 0;
  border-radius: 20px 0 0 20px;
}
.ant-input-number-rtl .ant-input-number-input {
  direction: ltr;
  text-align: right;
}
.ant-layout {
  display: flex;
  flex: auto;
  flex-direction: column;
  /* fix firefox can't set height smaller than content on flex item */
  min-height: 0;
  background: #f0f2f5;
}
.ant-layout,
.ant-layout * {
  box-sizing: border-box;
}
.ant-layout.ant-layout-has-sider {
  flex-direction: row;
}
.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content {
  width: 0;
}
.ant-layout-header,
.ant-layout-footer {
  flex: 0 0 auto;
}
.ant-layout-header {
  height: 64px;
  padding: 0 50px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 64px;
  background: #001529;
}
.ant-layout-footer {
  padding: 24px 50px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  background: #f0f2f5;
}
.ant-layout-content {
  flex: auto;
  /* fix firefox can't set height smaller than content on flex item */
  min-height: 0;
}
.ant-layout-sider {
  position: relative;
  /* fix firefox can't set width smaller than content on flex item */
  min-width: 0;
  background: #001529;
  transition: all 0.2s;
}
.ant-layout-sider-children {
  height: 100%;
  margin-top: -0.1px;
  padding-top: 0.1px;
}
.ant-layout-sider-has-trigger {
  padding-bottom: 48px;
}
.ant-layout-sider-right {
  order: 1;
}
.ant-layout-sider-trigger {
  position: fixed;
  bottom: 0;
  z-index: 1;
  height: 48px;
  color: #fff;
  line-height: 48px;
  text-align: center;
  background: #002140;
  cursor: pointer;
  transition: all 0.2s;
}
.ant-layout-sider-zero-width > * {
  overflow: hidden;
}
.ant-layout-sider-zero-width-trigger {
  position: absolute;
  top: 64px;
  right: -36px;
  z-index: 1;
  width: 36px;
  height: 42px;
  color: #fff;
  font-size: 18px;
  line-height: 42px;
  text-align: center;
  background: #001529;
  border-radius: 0 20px 20px 0;
  cursor: pointer;
  transition: background 0.3s ease;
}
.ant-layout-sider-zero-width-trigger::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  transition: all 0.3s;
  content: '';
}
.ant-layout-sider-zero-width-trigger:hover::after {
  background: rgba(255, 255, 255, 0.1);
}
.ant-layout-sider-zero-width-trigger-right {
  left: -36px;
  border-radius: 20px 0 0 20px;
}
.ant-layout-sider-light {
  background: #fff;
}
.ant-layout-sider-light .ant-layout-sider-trigger {
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
}
.ant-layout-sider-light .ant-layout-sider-zero-width-trigger {
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
}
.ant-layout-rtl {
  direction: rtl;
}
.ant-list {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
}
.ant-list * {
  outline: none;
}
.ant-list-pagination {
  margin-top: 24px;
  text-align: right;
}
.ant-list-pagination .ant-pagination-options {
  text-align: left;
}
.ant-list-more {
  margin-top: 12px;
  text-align: center;
}
.ant-list-more button {
  padding-right: 32px;
  padding-left: 32px;
}
.ant-list-spin {
  min-height: 40px;
  text-align: center;
}
.ant-list-empty-text {
  padding: 16px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  text-align: center;
}
.ant-list-items {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  color: rgba(0, 0, 0, 0.85);
}
.ant-list-item-meta {
  display: flex;
  flex: 1;
  align-items: flex-start;
  max-width: 100%;
}
.ant-list-item-meta-avatar {
  margin-right: 16px;
}
.ant-list-item-meta-content {
  flex: 1 0;
  width: 0;
  color: rgba(0, 0, 0, 0.85);
}
.ant-list-item-meta-title {
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
}
.ant-list-item-meta-title > a {
  color: rgba(0, 0, 0, 0.85);
  transition: all 0.3s;
}
.ant-list-item-meta-title > a:hover {
  color: #00441F;
}
.ant-list-item-meta-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
}
.ant-list-item-action {
  flex: 0 0 auto;
  margin-left: 48px;
  padding: 0;
  font-size: 0;
  list-style: none;
}
.ant-list-item-action > li {
  position: relative;
  display: inline-block;
  padding: 0 8px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
  text-align: center;
}
.ant-list-item-action > li:first-child {
  padding-left: 0;
}
.ant-list-item-action-split {
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 14px;
  margin-top: -7px;
  background-color: #f0f0f0;
}
.ant-list-header {
  background: transparent;
}
.ant-list-footer {
  background: transparent;
}
.ant-list-header,
.ant-list-footer {
  padding-top: 12px;
  padding-bottom: 12px;
}
.ant-list-empty {
  padding: 16px 0;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  text-align: center;
}
.ant-list-split .ant-list-item {
  border-bottom: 1px solid #f0f0f0;
}
.ant-list-split .ant-list-item:last-child {
  border-bottom: none;
}
.ant-list-split .ant-list-header {
  border-bottom: 1px solid #f0f0f0;
}
.ant-list-split.ant-list-empty .ant-list-footer {
  border-top: 1px solid #f0f0f0;
}
.ant-list-loading .ant-list-spin-nested-loading {
  min-height: 32px;
}
.ant-list-split.ant-list-something-after-last-item .ant-spin-container > .ant-list-items > .ant-list-item:last-child {
  border-bottom: 1px solid #f0f0f0;
}
.ant-list-lg .ant-list-item {
  padding: 16px 24px;
}
.ant-list-sm .ant-list-item {
  padding: 8px 16px;
}
.ant-list-vertical .ant-list-item {
  align-items: initial;
}
.ant-list-vertical .ant-list-item-main {
  display: block;
  flex: 1;
}
.ant-list-vertical .ant-list-item-extra {
  margin-left: 40px;
}
.ant-list-vertical .ant-list-item-meta {
  margin-bottom: 16px;
}
.ant-list-vertical .ant-list-item-meta-title {
  margin-bottom: 12px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 24px;
}
.ant-list-vertical .ant-list-item-action {
  margin-top: 16px;
  margin-left: auto;
}
.ant-list-vertical .ant-list-item-action > li {
  padding: 0 16px;
}
.ant-list-vertical .ant-list-item-action > li:first-child {
  padding-left: 0;
}
.ant-list-grid .ant-col > .ant-list-item {
  display: block;
  max-width: 100%;
  margin-bottom: 16px;
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: none;
}
.ant-list-item-no-flex {
  display: block;
}
.ant-list:not(.ant-list-vertical) .ant-list-item-no-flex .ant-list-item-action {
  float: right;
}
.ant-list-bordered {
  border: 1px solid #d9d9d9;
  border-radius: 20px;
}
.ant-list-bordered .ant-list-header {
  padding-right: 24px;
  padding-left: 24px;
}
.ant-list-bordered .ant-list-footer {
  padding-right: 24px;
  padding-left: 24px;
}
.ant-list-bordered .ant-list-item {
  padding-right: 24px;
  padding-left: 24px;
}
.ant-list-bordered .ant-list-pagination {
  margin: 16px 24px;
}
.ant-list-bordered.ant-list-sm .ant-list-item {
  padding: 8px 16px;
}
.ant-list-bordered.ant-list-sm .ant-list-header,
.ant-list-bordered.ant-list-sm .ant-list-footer {
  padding: 8px 16px;
}
.ant-list-bordered.ant-list-lg .ant-list-item {
  padding: 16px 24px;
}
.ant-list-bordered.ant-list-lg .ant-list-header,
.ant-list-bordered.ant-list-lg .ant-list-footer {
  padding: 16px 24px;
}
@media screen and (max-width: 768px) {
  .ant-list-item-action {
    margin-left: 24px;
  }
  .ant-list-vertical .ant-list-item-extra {
    margin-left: 24px;
  }
}
@media screen and (max-width: 576px) {
  .ant-list-item {
    flex-wrap: wrap;
  }
  .ant-list-item-action {
    margin-left: 12px;
  }
  .ant-list-vertical .ant-list-item {
    flex-wrap: wrap-reverse;
  }
  .ant-list-vertical .ant-list-item-main {
    min-width: 220px;
  }
  .ant-list-vertical .ant-list-item-extra {
    margin: auto auto 16px;
  }
}
.ant-list-rtl {
  direction: rtl;
  text-align: right;
}
.ant-list-rtl .ReactVirtualized__List .ant-list-item {
  direction: rtl;
}
.ant-list-rtl .ant-list-pagination {
  text-align: left;
}
.ant-list-rtl .ant-list-item-meta-avatar {
  margin-right: 0;
  margin-left: 16px;
}
.ant-list-rtl .ant-list-item-action {
  margin-right: 48px;
  margin-left: 0;
}
.ant-list.ant-list-rtl .ant-list-item-action > li:first-child {
  padding-right: 0;
  padding-left: 16px;
}
.ant-list-rtl .ant-list-item-action-split {
  right: auto;
  left: 0;
}
.ant-list-rtl.ant-list-vertical .ant-list-item-extra {
  margin-right: 40px;
  margin-left: 0;
}
.ant-list-rtl.ant-list-vertical .ant-list-item-action {
  margin-right: auto;
}
.ant-list-rtl .ant-list-vertical .ant-list-item-action > li:first-child {
  padding-right: 0;
  padding-left: 16px;
}
.ant-list-rtl .ant-list:not(.ant-list-vertical) .ant-list-item-no-flex .ant-list-item-action {
  float: left;
}
@media screen and (max-width: 768px) {
  .ant-list-rtl .ant-list-item-action {
    margin-right: 24px;
    margin-left: 0;
  }
  .ant-list-rtl .ant-list-vertical .ant-list-item-extra {
    margin-right: 24px;
    margin-left: 0;
  }
}
@media screen and (max-width: 576px) {
  .ant-list-rtl .ant-list-item-action {
    margin-right: 22px;
    margin-left: 0;
  }
  .ant-list-rtl.ant-list-vertical .ant-list-item-extra {
    margin: auto auto 16px;
  }
}
.ant-mentions {
  box-sizing: border-box;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #AAAAAA;
  border-radius: 20px;
  transition: all 0.3s;
  position: relative;
  display: inline-block;
  height: auto;
  padding: 0;
  overflow: hidden;
  line-height: 1.5715;
  white-space: pre-wrap;
  vertical-align: bottom;
}
.ant-mentions::-moz-placeholder {
  opacity: 1;
}
.ant-mentions::placeholder {
  color: #bfbfbf;
}
.ant-mentions:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-mentions:hover {
  border-color: #0d522a;
  border-right-width: 1px !important;
}
.ant-input-rtl .ant-mentions:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-mentions:focus,
.ant-mentions-focused {
  border-color: #0d522a;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(0, 68, 31, 0.2);
}
.ant-input-rtl .ant-mentions:focus,
.ant-input-rtl .ant-mentions-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-mentions-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-mentions-disabled:hover {
  border-color: #AAAAAA;
  border-right-width: 1px !important;
}
.ant-mentions[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-mentions[disabled]:hover {
  border-color: #AAAAAA;
  border-right-width: 1px !important;
}
.ant-mentions-borderless,
.ant-mentions-borderless:hover,
.ant-mentions-borderless:focus,
.ant-mentions-borderless-focused,
.ant-mentions-borderless-disabled,
.ant-mentions-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ant-mentions {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-mentions-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}
.ant-mentions-sm {
  padding: 0px 7px;
}
.ant-mentions-rtl {
  direction: rtl;
}
.ant-mentions-disabled > textarea {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-mentions-disabled > textarea:hover {
  border-color: #AAAAAA;
  border-right-width: 1px !important;
}
.ant-mentions-focused {
  border-color: #0d522a;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(0, 68, 31, 0.2);
}
.ant-input-rtl .ant-mentions-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-mentions > textarea,
.ant-mentions-measure {
  min-height: 30px;
  margin: 0;
  padding: 4px 11px;
  overflow: inherit;
  overflow-x: hidden;
  overflow-y: auto;
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
  font-style: inherit;
  font-variant: inherit;
  font-size-adjust: inherit;
  font-stretch: inherit;
  line-height: inherit;
  direction: inherit;
  letter-spacing: inherit;
  white-space: inherit;
  text-align: inherit;
  vertical-align: top;
  word-wrap: break-word;
  word-break: inherit;
  tab-size: inherit;
}
.ant-mentions > textarea {
  width: 100%;
  border: none;
  outline: none;
  resize: none;
}
.ant-mentions > textarea::-moz-placeholder {
  opacity: 1;
}
.ant-mentions > textarea::placeholder {
  color: #bfbfbf;
}
.ant-mentions > textarea:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-mentions-measure {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  color: transparent;
  pointer-events: none;
}
.ant-mentions-measure > span {
  display: inline-block;
  min-height: 1em;
}
.ant-mentions-dropdown {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  box-sizing: border-box;
  font-size: 14px;
  font-variant: initial;
  background-color: #fff;
  border-radius: 20px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-mentions-dropdown-hidden {
  display: none;
}
.ant-mentions-dropdown-menu {
  max-height: 250px;
  margin-bottom: 0;
  padding-left: 0;
  overflow: auto;
  list-style: none;
  outline: none;
}
.ant-mentions-dropdown-menu-item {
  position: relative;
  display: block;
  min-width: 100px;
  padding: 5px 12px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  line-height: 1.5715;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: background 0.3s ease;
}
.ant-mentions-dropdown-menu-item:hover {
  background-color: #f5f5f5;
}
.ant-mentions-dropdown-menu-item:first-child {
  border-radius: 20px 20px 0 0;
}
.ant-mentions-dropdown-menu-item:last-child {
  border-radius: 0 0 20px 20px;
}
.ant-mentions-dropdown-menu-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-mentions-dropdown-menu-item-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #fff;
  cursor: not-allowed;
}
.ant-mentions-dropdown-menu-item-selected {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  background-color: #fafafa;
}
.ant-mentions-dropdown-menu-item-active {
  background-color: #f5f5f5;
}
.ant-mentions-rtl {
  direction: rtl;
}
.ant-menu-item-danger.ant-menu-item {
  color: #DD4E4E;
}
.ant-menu-item-danger.ant-menu-item:hover,
.ant-menu-item-danger.ant-menu-item-active {
  color: #DD4E4E;
}
.ant-menu-item-danger.ant-menu-item:active {
  background: #fff1f0;
}
.ant-menu-item-danger.ant-menu-item-selected {
  color: #DD4E4E;
}
.ant-menu-item-danger.ant-menu-item-selected > a,
.ant-menu-item-danger.ant-menu-item-selected > a:hover {
  color: #DD4E4E;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
  background-color: #fff1f0;
}
.ant-menu-inline .ant-menu-item-danger.ant-menu-item::after {
  border-right-color: #DD4E4E;
}
.ant-menu-dark .ant-menu-item-danger.ant-menu-item,
.ant-menu-dark .ant-menu-item-danger.ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-danger.ant-menu-item > a {
  color: #DD4E4E;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
  color: #fff;
  background-color: #DD4E4E;
}
.ant-menu {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-variant: tabular-nums;
  line-height: 1.5715;
  font-feature-settings: 'tnum';
  margin-bottom: 0;
  padding-left: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 0;
  text-align: left;
  list-style: none;
  background: #fff;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  transition: background 0.3s, width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
}
.ant-menu::before {
  display: table;
  content: '';
}
.ant-menu::after {
  display: table;
  clear: both;
  content: '';
}
.ant-menu::before {
  display: table;
  content: '';
}
.ant-menu::after {
  display: table;
  clear: both;
  content: '';
}
.ant-menu ul,
.ant-menu ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-menu-hidden {
  display: none;
}
.ant-menu-item-group-title {
  height: 1.5715;
  padding: 8px 16px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
  transition: all 0.3s;
}
.ant-menu-horizontal .ant-menu-submenu {
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu,
.ant-menu-submenu-inline {
  transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu-selected {
  color: #00441F;
}
.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: #77857b;
}
.ant-menu-submenu .ant-menu-sub {
  cursor: initial;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-item a {
  color: rgba(0, 0, 0, 0.85);
}
.ant-menu-item a:hover {
  color: #00441F;
}
.ant-menu-item a::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: transparent;
  content: '';
}
.ant-menu-item > .ant-badge a {
  color: rgba(0, 0, 0, 0.85);
}
.ant-menu-item > .ant-badge a:hover {
  color: #00441F;
}
.ant-menu-item-divider {
  height: 1px;
  overflow: hidden;
  line-height: 0;
  background-color: #f0f0f0;
}
.ant-menu-item:hover,
.ant-menu-item-active,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover {
  color: #00441F;
}
.ant-menu-horizontal .ant-menu-item,
.ant-menu-horizontal .ant-menu-submenu {
  margin-top: -1px;
}
.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-horizontal > .ant-menu-item-active,
.ant-menu-horizontal > .ant-menu-submenu .ant-menu-submenu-title:hover {
  background-color: transparent;
}
.ant-menu-item-selected {
  color: #00441F;
}
.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
  color: #00441F;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #77857b;
}
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 1px solid #f0f0f0;
}
.ant-menu-vertical-right {
  border-left: 1px solid #f0f0f0;
}
.ant-menu-vertical.ant-menu-sub,
.ant-menu-vertical-left.ant-menu-sub,
.ant-menu-vertical-right.ant-menu-sub {
  min-width: 160px;
  max-height: calc(100vh - 100px);
  padding: 0;
  overflow: hidden;
  border-right: 0;
}
.ant-menu-vertical.ant-menu-sub:not([class*='-active']),
.ant-menu-vertical-left.ant-menu-sub:not([class*='-active']),
.ant-menu-vertical-right.ant-menu-sub:not([class*='-active']) {
  overflow-x: hidden;
  overflow-y: auto;
}
.ant-menu-vertical.ant-menu-sub .ant-menu-item,
.ant-menu-vertical-left.ant-menu-sub .ant-menu-item,
.ant-menu-vertical-right.ant-menu-sub .ant-menu-item {
  left: 0;
  margin-left: 0;
  border-right: 0;
}
.ant-menu-vertical.ant-menu-sub .ant-menu-item::after,
.ant-menu-vertical-left.ant-menu-sub .ant-menu-item::after,
.ant-menu-vertical-right.ant-menu-sub .ant-menu-item::after {
  border-right: 0;
}
.ant-menu-vertical.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical-left.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical-right.ant-menu-sub > .ant-menu-item,
.ant-menu-vertical.ant-menu-sub > .ant-menu-submenu,
.ant-menu-vertical-left.ant-menu-sub > .ant-menu-submenu,
.ant-menu-vertical-right.ant-menu-sub > .ant-menu-submenu {
  transform-origin: 0 0;
}
.ant-menu-horizontal.ant-menu-sub {
  min-width: 114px;
}
.ant-menu-horizontal .ant-menu-item,
.ant-menu-horizontal .ant-menu-submenu-title {
  transition: border-color 0.3s, background 0.3s;
}
.ant-menu-item,
.ant-menu-submenu-title {
  position: relative;
  display: block;
  margin: 0;
  padding: 0 20px;
  white-space: nowrap;
  cursor: pointer;
  transition: border-color 0.3s, background 0.3s, padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-item .ant-menu-item-icon,
.ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-item .anticon,
.ant-menu-submenu-title .anticon {
  min-width: 14px;
  margin-right: 10px;
  font-size: 14px;
  transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s;
}
.ant-menu-item .ant-menu-item-icon + span,
.ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-menu-item .anticon + span,
.ant-menu-submenu-title .anticon + span {
  opacity: 1;
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s;
}
.ant-menu-item.ant-menu-item-only-child > .anticon,
.ant-menu-submenu-title.ant-menu-item-only-child > .anticon,
.ant-menu-item.ant-menu-item-only-child > .ant-menu-item-icon,
.ant-menu-submenu-title.ant-menu-item-only-child > .ant-menu-item-icon {
  margin-right: 0;
}
.ant-menu > .ant-menu-item-divider {
  height: 1px;
  margin: 1px 0;
  padding: 0;
  overflow: hidden;
  line-height: 0;
  background-color: #f0f0f0;
}
.ant-menu-submenu-popup {
  position: absolute;
  z-index: 1050;
  background: transparent;
  border-radius: 20px;
  box-shadow: none;
  transform-origin: 0 0;
}
.ant-menu-submenu-popup::before {
  position: absolute;
  top: -7px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0.0001;
  content: ' ';
}
.ant-menu-submenu-placement-rightTop::before {
  top: 0;
  left: -7px;
}
.ant-menu-submenu > .ant-menu {
  background-color: #fff;
  border-radius: 20px;
}
.ant-menu-submenu > .ant-menu-submenu-title::after {
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu-popup > .ant-menu {
  background-color: #fff;
}
.ant-menu-submenu-expand-icon,
.ant-menu-submenu-arrow {
  position: absolute;
  top: 50%;
  right: 16px;
  width: 10px;
  color: rgba(0, 0, 0, 0.85);
  transform: translateY(-50%);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-submenu-arrow::before,
.ant-menu-submenu-arrow::after {
  position: absolute;
  width: 6px;
  height: 1.5px;
  background-color: currentColor;
  border-radius: 2px;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: '';
}
.ant-menu-submenu-arrow::before {
  transform: rotate(45deg) translateY(-2.5px);
}
.ant-menu-submenu-arrow::after {
  transform: rotate(-45deg) translateY(2.5px);
}
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon,
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: #00441F;
}
.ant-menu-submenu-inline .ant-menu-submenu-arrow::before {
  transform: rotate(-45deg) translateX(2.5px);
}
.ant-menu-submenu-inline .ant-menu-submenu-arrow::after {
  transform: rotate(45deg) translateX(-2.5px);
}
.ant-menu-submenu-horizontal .ant-menu-submenu-arrow {
  display: none;
}
.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  transform: translateY(-2px);
}
.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
  transform: rotate(-45deg) translateX(-2.5px);
}
.ant-menu-submenu-open.ant-menu-submenu-inline > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before {
  transform: rotate(45deg) translateX(2.5px);
}
.ant-menu-vertical .ant-menu-submenu-selected,
.ant-menu-vertical-left .ant-menu-submenu-selected,
.ant-menu-vertical-right .ant-menu-submenu-selected {
  color: #00441F;
}
.ant-menu-horizontal {
  line-height: 46px;
  border: 0;
  border-bottom: 1px solid #f0f0f0;
  box-shadow: none;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
  margin: 0 20px;
  margin-top: -1px;
  margin-bottom: 0;
  padding: 0 20px;
  padding-right: 0;
  padding-left: 0;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  color: #00441F;
  border-bottom: 2px solid #00441F;
}
.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  position: relative;
  top: 1px;
  display: inline-block;
  vertical-align: bottom;
  border-bottom: 2px solid transparent;
}
.ant-menu-horizontal > .ant-menu-submenu > .ant-menu-submenu-title {
  padding: 0;
}
.ant-menu-horizontal > .ant-menu-item a {
  color: rgba(0, 0, 0, 0.85);
}
.ant-menu-horizontal > .ant-menu-item a:hover {
  color: #00441F;
}
.ant-menu-horizontal > .ant-menu-item a::before {
  bottom: -2px;
}
.ant-menu-horizontal > .ant-menu-item-selected a {
  color: #00441F;
}
.ant-menu-horizontal::after {
  display: block;
  clear: both;
  height: 0;
  content: '\20';
}
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item {
  position: relative;
}
.ant-menu-vertical .ant-menu-item::after,
.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-inline .ant-menu-item::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border-right: 3px solid #00441F;
  transform: scaleY(0.0001);
  opacity: 0;
  transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
  content: '';
}
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-inline .ant-menu-submenu-title {
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 0 16px;
  overflow: hidden;
  line-height: 40px;
  text-overflow: ellipsis;
}
.ant-menu-vertical .ant-menu-submenu,
.ant-menu-vertical-left .ant-menu-submenu,
.ant-menu-vertical-right .ant-menu-submenu,
.ant-menu-inline .ant-menu-submenu {
  padding-bottom: 0.02px;
}
.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 8px;
}
.ant-menu-vertical > .ant-menu-item,
.ant-menu-vertical-left > .ant-menu-item,
.ant-menu-vertical-right > .ant-menu-item,
.ant-menu-inline > .ant-menu-item,
.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-left > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-vertical-right > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 40px;
  line-height: 40px;
}
.ant-menu-vertical .ant-menu-submenu-title {
  padding-right: 34px;
}
.ant-menu-inline {
  width: 100%;
}
.ant-menu-inline .ant-menu-selected::after,
.ant-menu-inline .ant-menu-item-selected::after {
  transform: scaleY(1);
  opacity: 1;
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
  width: calc(100% + 1px);
}
.ant-menu-inline .ant-menu-submenu-title {
  padding-right: 34px;
}
.ant-menu-inline-collapsed {
  width: 80px;
}
.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  left: 0;
  padding: 0 calc(50% - 16px / 2);
  text-overflow: clip;
}
.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-submenu-arrow,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-submenu-arrow,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  display: none;
}
.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-item-icon,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-inline-collapsed > .ant-menu-item .anticon,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon {
  margin: 0;
  font-size: 16px;
  line-height: 40px;
}
.ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item-icon + span,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .ant-menu-item-icon + span,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-menu-inline-collapsed > .ant-menu-item .anticon + span,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon + span,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon + span {
  display: inline-block;
  max-width: 0;
  opacity: 0;
}
.ant-menu-inline-collapsed .ant-menu-item-icon,
.ant-menu-inline-collapsed .anticon {
  display: inline-block;
}
.ant-menu-inline-collapsed-tooltip {
  pointer-events: none;
}
.ant-menu-inline-collapsed-tooltip .ant-menu-item-icon,
.ant-menu-inline-collapsed-tooltip .anticon {
  display: none;
}
.ant-menu-inline-collapsed-tooltip a {
  color: rgba(255, 255, 255, 0.85);
}
.ant-menu-inline-collapsed .ant-menu-item-group-title {
  padding-right: 4px;
  padding-left: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-menu-item-group-list {
  margin: 0;
  padding: 0;
}
.ant-menu-item-group-list .ant-menu-item,
.ant-menu-item-group-list .ant-menu-submenu-title {
  padding: 0 16px 0 28px;
}
.ant-menu-root.ant-menu-vertical,
.ant-menu-root.ant-menu-vertical-left,
.ant-menu-root.ant-menu-vertical-right,
.ant-menu-root.ant-menu-inline {
  box-shadow: none;
}
.ant-menu-root.ant-menu-inline-collapsed .ant-menu-item > .ant-menu-inline-collapsed-noicon,
.ant-menu-root.ant-menu-inline-collapsed .ant-menu-submenu .ant-menu-submenu-title > .ant-menu-inline-collapsed-noicon {
  font-size: 16px;
  text-align: center;
}
.ant-menu-sub.ant-menu-inline {
  padding: 0;
  border: 0;
  border-radius: 0;
  box-shadow: none;
}
.ant-menu-sub.ant-menu-inline > .ant-menu-item,
.ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 40px;
  line-height: 40px;
  list-style-position: inside;
  list-style-type: disc;
}
.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
  padding-left: 32px;
}
.ant-menu-item-disabled,
.ant-menu-submenu-disabled {
  color: rgba(0, 0, 0, 0.25) !important;
  background: none;
  border-color: transparent !important;
  cursor: not-allowed;
}
.ant-menu-item-disabled a,
.ant-menu-submenu-disabled a {
  color: rgba(0, 0, 0, 0.25) !important;
  pointer-events: none;
}
.ant-menu-item-disabled > .ant-menu-submenu-title,
.ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: rgba(0, 0, 0, 0.25) !important;
  cursor: not-allowed;
}
.ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
  background: rgba(0, 0, 0, 0.25) !important;
}
.ant-layout-header .ant-menu {
  line-height: inherit;
}
.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  color: rgba(255, 255, 255, 0.65);
  background: #001529;
}
.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow {
  opacity: 0.45;
  transition: all 0.3s;
}
.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  background: #fff;
}
.ant-menu-dark.ant-menu-submenu-popup {
  background: transparent;
}
.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #000c17;
}
.ant-menu-dark.ant-menu-horizontal {
  border-bottom: 0;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item,
.ant-menu-dark.ant-menu-horizontal > .ant-menu-submenu {
  top: 0;
  margin-top: 0;
  padding: 0 20px;
  border-color: #001529;
  border-bottom: 0;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
  background-color: #00441F;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item > a::before {
  bottom: 0;
}
.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item > a,
.ant-menu-dark .ant-menu-item > span > a {
  color: rgba(255, 255, 255, 0.65);
}
.ant-menu-dark.ant-menu-inline,
.ant-menu-dark.ant-menu-vertical,
.ant-menu-dark.ant-menu-vertical-left,
.ant-menu-dark.ant-menu-vertical-right {
  border-right: 0;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-vertical .ant-menu-item,
.ant-menu-dark.ant-menu-vertical-left .ant-menu-item,
.ant-menu-dark.ant-menu-vertical-right .ant-menu-item {
  left: 0;
  margin-left: 0;
  border-right: 0;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-dark.ant-menu-vertical-right .ant-menu-item::after {
  border-right: 0;
}
.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
  width: 100%;
}
.ant-menu-dark .ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-active,
.ant-menu-dark .ant-menu-submenu-active,
.ant-menu-dark .ant-menu-submenu-open,
.ant-menu-dark .ant-menu-submenu-selected,
.ant-menu-dark .ant-menu-submenu-title:hover {
  color: #fff;
  background-color: transparent;
}
.ant-menu-dark .ant-menu-item:hover > a,
.ant-menu-dark .ant-menu-item-active > a,
.ant-menu-dark .ant-menu-submenu-active > a,
.ant-menu-dark .ant-menu-submenu-open > a,
.ant-menu-dark .ant-menu-submenu-selected > a,
.ant-menu-dark .ant-menu-submenu-title:hover > a,
.ant-menu-dark .ant-menu-item:hover > span > a,
.ant-menu-dark .ant-menu-item-active > span > a,
.ant-menu-dark .ant-menu-submenu-active > span > a,
.ant-menu-dark .ant-menu-submenu-open > span > a,
.ant-menu-dark .ant-menu-submenu-selected > span > a,
.ant-menu-dark .ant-menu-submenu-title:hover > span > a {
  color: #fff;
}
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow {
  opacity: 1;
}
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-open > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-selected > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-title:hover > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow::before {
  background: #fff;
}
.ant-menu-dark .ant-menu-item:hover {
  background-color: transparent;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #00441F;
}
.ant-menu-dark .ant-menu-item-selected {
  color: #fff;
  border-right: 0;
}
.ant-menu-dark .ant-menu-item-selected::after {
  border-right: 0;
}
.ant-menu-dark .ant-menu-item-selected > a,
.ant-menu-dark .ant-menu-item-selected > span > a,
.ant-menu-dark .ant-menu-item-selected > a:hover,
.ant-menu-dark .ant-menu-item-selected > span > a:hover {
  color: #fff;
}
.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon,
.ant-menu-dark .ant-menu-item-selected .anticon {
  color: #fff;
}
.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon + span,
.ant-menu-dark .ant-menu-item-selected .anticon + span {
  color: #fff;
}
.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: #00441F;
}
.ant-menu-dark .ant-menu-item-disabled,
.ant-menu-dark .ant-menu-submenu-disabled,
.ant-menu-dark .ant-menu-item-disabled > a,
.ant-menu-dark .ant-menu-submenu-disabled > a,
.ant-menu-dark .ant-menu-item-disabled > span > a,
.ant-menu-dark .ant-menu-submenu-disabled > span > a {
  color: rgba(255, 255, 255, 0.35) !important;
  opacity: 0.8;
}
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title {
  color: rgba(255, 255, 255, 0.35) !important;
}
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::before,
.ant-menu-dark .ant-menu-item-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after,
.ant-menu-dark .ant-menu-submenu-disabled > .ant-menu-submenu-title > .ant-menu-submenu-arrow::after {
  background: rgba(255, 255, 255, 0.35) !important;
}
.ant-menu.ant-menu-rtl {
  direction: rtl;
  text-align: right;
}
.ant-menu-rtl .ant-menu-item-group-title {
  text-align: right;
}
.ant-menu-rtl.ant-menu-inline,
.ant-menu-rtl.ant-menu-vertical {
  border-right: none;
  border-left: 1px solid #f0f0f0;
}
.ant-menu-rtl.ant-menu-dark.ant-menu-inline,
.ant-menu-rtl.ant-menu-dark.ant-menu-vertical {
  border-left: none;
}
.ant-menu-rtl.ant-menu-vertical.ant-menu-sub > .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-left.ant-menu-sub > .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-right.ant-menu-sub > .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical.ant-menu-sub > .ant-menu-submenu,
.ant-menu-rtl.ant-menu-vertical-left.ant-menu-sub > .ant-menu-submenu,
.ant-menu-rtl.ant-menu-vertical-right.ant-menu-sub > .ant-menu-submenu {
  transform-origin: top right;
}
.ant-menu-rtl .ant-menu-item .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-item .anticon,
.ant-menu-rtl .ant-menu-submenu-title .anticon {
  margin-right: auto;
  margin-left: 10px;
}
.ant-menu-rtl .ant-menu-item.ant-menu-item-only-child > .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-submenu-title.ant-menu-item-only-child > .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-item.ant-menu-item-only-child > .anticon,
.ant-menu-rtl .ant-menu-submenu-title.ant-menu-item-only-child > .anticon {
  margin-left: 0;
}
.ant-menu-submenu-rtl.ant-menu-submenu-popup {
  transform-origin: 100% 0;
}
.ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-rtl .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
  right: auto;
  left: 16px;
}
.ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
  transform: rotate(-45deg) translateY(-2px);
}
.ant-menu-rtl .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-rtl .ant-menu-submenu-vertical-left > .ant-menu-submenu-title .ant-menu-submenu-arrow::after,
.ant-menu-rtl .ant-menu-submenu-vertical-right > .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
  transform: rotate(45deg) translateY(2px);
}
.ant-menu-rtl.ant-menu-vertical .ant-menu-item::after,
.ant-menu-rtl.ant-menu-vertical-left .ant-menu-item::after,
.ant-menu-rtl.ant-menu-vertical-right .ant-menu-item::after,
.ant-menu-rtl.ant-menu-inline .ant-menu-item::after {
  right: auto;
  left: 0;
}
.ant-menu-rtl.ant-menu-vertical .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-left .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-right .ant-menu-item,
.ant-menu-rtl.ant-menu-inline .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-rtl.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-rtl.ant-menu-vertical-right .ant-menu-submenu-title,
.ant-menu-rtl.ant-menu-inline .ant-menu-submenu-title {
  text-align: right;
}
.ant-menu-rtl.ant-menu-inline .ant-menu-submenu-title {
  padding-right: 0;
  padding-left: 34px;
}
.ant-menu-rtl.ant-menu-vertical .ant-menu-submenu-title {
  padding-right: 16px;
  padding-left: 34px;
}
.ant-menu-rtl.ant-menu-inline-collapsed.ant-menu-vertical .ant-menu-submenu-title {
  padding: 0 calc(50% - 16px / 2);
}
.ant-menu-rtl .ant-menu-item-group-list .ant-menu-item,
.ant-menu-rtl .ant-menu-item-group-list .ant-menu-submenu-title {
  padding: 0 28px 0 16px;
}
.ant-menu-sub.ant-menu-inline {
  border: 0;
}
.ant-menu-rtl.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
  padding-right: 32px;
  padding-left: 0;
}
.ant-message {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: fixed;
  top: 8px;
  left: 0;
  z-index: 1010;
  width: 100%;
  pointer-events: none;
}
.ant-message-notice {
  padding: 8px;
  text-align: center;
}
.ant-message-notice-content {
  display: inline-block;
  padding: 10px 16px;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: all;
}
.ant-message-success .anticon {
  color: #79be39;
}
.ant-message-error .anticon {
  color: #DD4E4E;
}
.ant-message-warning .anticon {
  color: #FFC107;
}
.ant-message-info .anticon,
.ant-message-loading .anticon {
  color: #00441F;
}
.ant-message .anticon {
  position: relative;
  top: 1px;
  margin-right: 8px;
  font-size: 16px;
}
.ant-message-notice.move-up-leave.move-up-leave-active {
  animation-name: MessageMoveOut;
  animation-duration: 0.3s;
}
@keyframes MessageMoveOut {
  0% {
    max-height: 150px;
    padding: 8px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    padding: 0;
    opacity: 0;
  }
}
.ant-message-rtl {
  direction: rtl;
}
.ant-message-rtl span {
  direction: rtl;
}
.ant-message-rtl .anticon {
  margin-right: 0;
  margin-left: 8px;
}
.ant-modal {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  pointer-events: none;
  position: relative;
  top: 100px;
  width: auto;
  max-width: calc(100vw - 32px);
  margin: 0 auto;
  padding-bottom: 24px;
}
.ant-modal.zoom-enter,
.ant-modal.zoom-appear {
  transform: none;
  opacity: 0;
  animation-duration: 0.3s;
  user-select: none;
}
.ant-modal-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}
.ant-modal-mask-hidden {
  display: none;
}
.ant-modal-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
  -webkit-overflow-scrolling: touch;
}
.ant-modal-wrap {
  z-index: 1000;
}
.ant-modal-title {
  margin: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  word-wrap: break-word;
}
.ant-modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-radius: 20px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: auto;
}
.ant-modal-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  line-height: 1;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  transition: color 0.3s;
}
.ant-modal-close-x {
  display: block;
  width: 56px;
  height: 56px;
  font-size: 16px;
  font-style: normal;
  line-height: 56px;
  text-align: center;
  text-transform: none;
  text-rendering: auto;
}
.ant-modal-close:focus,
.ant-modal-close:hover {
  color: rgba(0, 0, 0, 0.75);
  text-decoration: none;
}
.ant-modal-header {
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 20px 20px 0 0;
}
.ant-modal-body {
  padding: 24px;
  font-size: 14px;
  line-height: 1.5715;
  word-wrap: break-word;
}
.ant-modal-footer {
  padding: 10px 16px;
  text-align: right;
  background: transparent;
  border-top: 1px solid #f0f0f0;
  border-radius: 0 0 20px 20px;
}
.ant-modal-footer button + button {
  margin-bottom: 0;
  margin-left: 8px;
}
.ant-modal-open {
  overflow: hidden;
}
.ant-modal-centered {
  text-align: center;
}
.ant-modal-centered::before {
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  content: '';
}
.ant-modal-centered .ant-modal {
  top: 0;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}
@media (max-width: 767px) {
  .ant-modal {
    max-width: calc(100vw - 16px);
    margin: 8px auto;
  }
  .ant-modal-centered .ant-modal {
    flex: 1;
  }
}
.ant-modal-confirm .ant-modal-header {
  display: none;
}
.ant-modal-confirm .ant-modal-body {
  padding: 32px 32px 24px;
}
.ant-modal-confirm-body-wrapper::before {
  display: table;
  content: '';
}
.ant-modal-confirm-body-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.ant-modal-confirm-body-wrapper::before {
  display: table;
  content: '';
}
.ant-modal-confirm-body-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.ant-modal-confirm-body .ant-modal-confirm-title {
  display: block;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
}
.ant-modal-confirm-body .ant-modal-confirm-content {
  margin-top: 8px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
.ant-modal-confirm-body > .anticon {
  float: left;
  margin-right: 16px;
  font-size: 22px;
}
.ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
  margin-left: 38px;
}
.ant-modal-confirm .ant-modal-confirm-btns {
  float: right;
  margin-top: 24px;
}
.ant-modal-confirm .ant-modal-confirm-btns button + button {
  margin-bottom: 0;
  margin-left: 8px;
}
.ant-modal-confirm-error .ant-modal-confirm-body > .anticon {
  color: #DD4E4E;
}
.ant-modal-confirm-warning .ant-modal-confirm-body > .anticon,
.ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
  color: #FFC107;
}
.ant-modal-confirm-info .ant-modal-confirm-body > .anticon {
  color: #00441F;
}
.ant-modal-confirm-success .ant-modal-confirm-body > .anticon {
  color: #79be39;
}
.ant-modal-wrap-rtl {
  direction: rtl;
}
.ant-modal-wrap-rtl .ant-modal-close {
  right: initial;
  left: 0;
}
.ant-modal-wrap-rtl .ant-modal-footer {
  text-align: left;
}
.ant-modal-wrap-rtl .ant-modal-footer button + button {
  margin-right: 8px;
  margin-left: 0;
}
.ant-modal-wrap-rtl .ant-modal-confirm-body {
  direction: rtl;
}
.ant-modal-wrap-rtl .ant-modal-confirm-body > .anticon {
  float: right;
  margin-right: 0;
  margin-left: 16px;
}
.ant-modal-wrap-rtl .ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
  margin-right: 38px;
  margin-left: 0;
}
.ant-modal-wrap-rtl .ant-modal-confirm-btns {
  float: left;
}
.ant-modal-wrap-rtl .ant-modal-confirm-btns button + button {
  margin-right: 8px;
  margin-left: 0;
}
.ant-modal-wrap-rtl.ant-modal-centered .ant-modal {
  text-align: right;
}
.ant-notification {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: fixed;
  z-index: 1010;
  margin-right: 24px;
}
.ant-notification-topLeft,
.ant-notification-bottomLeft {
  margin-right: 0;
  margin-left: 24px;
}
.ant-notification-topLeft .ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-bottomLeft .ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-topLeft .ant-notification-fade-appear.ant-notification-fade-appear-active,
.ant-notification-bottomLeft .ant-notification-fade-appear.ant-notification-fade-appear-active {
  animation-name: NotificationLeftFadeIn;
}
.ant-notification-close-icon {
  font-size: 14px;
  cursor: pointer;
}
.ant-notification-hook-holder,
.ant-notification-notice {
  position: relative;
  width: 384px;
  max-width: calc(100vw - 24px * 2);
  margin-bottom: 16px;
  margin-left: auto;
  overflow: hidden;
  word-wrap: break-word;
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-notification-topLeft .ant-notification-hook-holder,
.ant-notification-topLeft .ant-notification-notice,
.ant-notification-bottomLeft .ant-notification-hook-holder,
.ant-notification-bottomLeft .ant-notification-notice {
  margin-right: auto;
  margin-left: 0;
}
.ant-notification-hook-holder > .ant-notification-notice {
  margin-bottom: 0;
  box-shadow: none;
}
.ant-notification-notice {
  padding: 16px 24px;
  line-height: 1.5715;
}
.ant-notification-notice-message {
  margin-bottom: 8px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 24px;
}
.ant-notification-notice-message-single-line-auto-margin {
  display: block;
  width: calc(384px - 24px * 2 - 24px - 48px - 100%);
  max-width: 4px;
  background-color: transparent;
  pointer-events: none;
}
.ant-notification-notice-message-single-line-auto-margin::before {
  display: block;
  content: '';
}
.ant-notification-notice-description {
  font-size: 14px;
}
.ant-notification-notice-closable .ant-notification-notice-message {
  padding-right: 24px;
}
.ant-notification-notice-with-icon .ant-notification-notice-message {
  margin-bottom: 4px;
  margin-left: 48px;
  font-size: 16px;
}
.ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-left: 48px;
  font-size: 14px;
}
.ant-notification-notice-icon {
  position: absolute;
  margin-left: 4px;
  font-size: 24px;
  line-height: 24px;
}
.anticon.ant-notification-notice-icon-success {
  color: #79be39;
}
.anticon.ant-notification-notice-icon-info {
  color: #00441F;
}
.anticon.ant-notification-notice-icon-warning {
  color: #FFC107;
}
.anticon.ant-notification-notice-icon-error {
  color: #DD4E4E;
}
.ant-notification-notice-close {
  position: absolute;
  top: 16px;
  right: 22px;
  color: rgba(0, 0, 0, 0.45);
  outline: none;
}
.ant-notification-notice-close:hover {
  color: rgba(0, 0, 0, 0.67);
}
.ant-notification-notice-btn {
  float: right;
  margin-top: 16px;
}
.ant-notification .notification-fade-effect {
  animation-duration: 0.24s;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-fill-mode: both;
}
.ant-notification-fade-enter,
.ant-notification-fade-appear {
  opacity: 0;
  animation-duration: 0.24s;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-fill-mode: both;
  animation-play-state: paused;
}
.ant-notification-fade-leave {
  animation-duration: 0.24s;
  animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  animation-fill-mode: both;
  animation-duration: 0.2s;
  animation-play-state: paused;
}
.ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-fade-appear.ant-notification-fade-appear-active {
  animation-name: NotificationFadeIn;
  animation-play-state: running;
}
.ant-notification-fade-leave.ant-notification-fade-leave-active {
  animation-name: NotificationFadeOut;
  animation-play-state: running;
}
@keyframes NotificationFadeIn {
  0% {
    left: 384px;
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}
@keyframes NotificationLeftFadeIn {
  0% {
    right: 384px;
    opacity: 0;
  }
  100% {
    right: 0;
    opacity: 1;
  }
}
@keyframes NotificationFadeOut {
  0% {
    max-height: 150px;
    margin-bottom: 16px;
    opacity: 1;
  }
  100% {
    max-height: 0;
    margin-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 0;
  }
}
.ant-notification-rtl {
  direction: rtl;
}
.ant-notification-rtl .ant-notification-notice-closable .ant-notification-notice-message {
  padding-right: 0;
  padding-left: 24px;
}
.ant-notification-rtl .ant-notification-notice-with-icon .ant-notification-notice-message {
  margin-right: 48px;
  margin-left: 0;
}
.ant-notification-rtl .ant-notification-notice-with-icon .ant-notification-notice-description {
  margin-right: 48px;
  margin-left: 0;
}
.ant-notification-rtl .ant-notification-notice-icon {
  margin-right: 4px;
  margin-left: 0;
}
.ant-notification-rtl .ant-notification-notice-close {
  right: auto;
  left: 22px;
}
.ant-notification-rtl .ant-notification-notice-btn {
  float: left;
}
.ant-page-header {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  padding: 16px 24px;
  background-color: #fff;
}
.ant-page-header-ghost {
  background-color: inherit;
}
.ant-page-header.has-breadcrumb {
  padding-top: 12px;
}
.ant-page-header.has-footer {
  padding-bottom: 0;
}
.ant-page-header-back {
  margin-right: 16px;
  font-size: 16px;
  line-height: 1;
}
.ant-page-header-back-button {
  color: #00441F;
  text-decoration: none;
  outline: none;
  transition: color 0.3s;
  color: #000;
  cursor: pointer;
}
.ant-page-header-back-button:focus,
.ant-page-header-back-button:hover {
  color: #0d522a;
}
.ant-page-header-back-button:active {
  color: #001f0f;
}
.ant-page-header .ant-divider-vertical {
  height: 14px;
  margin: 0 12px;
  vertical-align: middle;
}
.ant-breadcrumb + .ant-page-header-heading {
  margin-top: 8px;
}
.ant-page-header-heading {
  display: flex;
  justify-content: space-between;
}
.ant-page-header-heading-left {
  display: flex;
  align-items: center;
  margin: 4px 0;
  overflow: hidden;
}
.ant-page-header-heading-title {
  margin-right: 12px;
  margin-bottom: 0;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-page-header-heading .ant-avatar {
  margin-right: 12px;
}
.ant-page-header-heading-sub-title {
  margin-right: 12px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.5715;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-page-header-heading-extra {
  margin: 4px 0;
  white-space: nowrap;
}
.ant-page-header-heading-extra > * {
  margin-left: 12px;
  white-space: unset;
}
.ant-page-header-heading-extra > *:first-child {
  margin-left: 0;
}
.ant-page-header-content {
  padding-top: 12px;
}
.ant-page-header-footer {
  margin-top: 16px;
}
.ant-page-header-footer .ant-tabs > .ant-tabs-nav {
  margin: 0;
}
.ant-page-header-footer .ant-tabs > .ant-tabs-nav::before {
  border: none;
}
.ant-page-header-footer .ant-tabs .ant-tabs-tab {
  padding: 8px 0;
  font-size: 16px;
}
.ant-page-header-compact .ant-page-header-heading {
  flex-wrap: wrap;
}
.ant-page-header-rtl {
  direction: rtl;
}
.ant-page-header-rtl .ant-page-header-back {
  float: right;
  margin-right: 0;
  margin-left: 16px;
}
.ant-page-header-rtl .ant-page-header-heading-title {
  margin-right: 0;
  margin-left: 12px;
}
.ant-page-header-rtl .ant-page-header-heading .ant-avatar {
  margin-right: 0;
  margin-left: 12px;
}
.ant-page-header-rtl .ant-page-header-heading-sub-title {
  float: right;
  margin-right: 0;
  margin-left: 12px;
}
.ant-page-header-rtl .ant-page-header-heading-tags {
  float: right;
}
.ant-page-header-rtl .ant-page-header-heading-extra {
  float: left;
}
.ant-page-header-rtl .ant-page-header-heading-extra > * {
  margin-right: 12px;
  margin-left: 0;
}
.ant-page-header-rtl .ant-page-header-heading-extra > *:first-child {
  margin-right: 0;
}
.ant-page-header-rtl .ant-page-header-footer .ant-tabs-bar .ant-tabs-nav {
  float: right;
}
.ant-pagination {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
}
.ant-pagination ul,
.ant-pagination ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-pagination::after {
  display: block;
  clear: both;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  content: ' ';
}
.ant-pagination-total-text {
  display: inline-block;
  height: 32px;
  margin-right: 8px;
  line-height: 30px;
  vertical-align: middle;
}
.ant-pagination-item {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  margin-right: 8px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  line-height: 30px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 20px;
  outline: 0;
  cursor: pointer;
  user-select: none;
}
.ant-pagination-item a {
  display: block;
  padding: 0 6px;
  color: rgba(0, 0, 0, 0.85);
  transition: none;
}
.ant-pagination-item a:hover {
  text-decoration: none;
}
.ant-pagination-item:focus,
.ant-pagination-item:hover {
  border-color: #00441F;
  transition: all 0.3s;
}
.ant-pagination-item:focus a,
.ant-pagination-item:hover a {
  color: #00441F;
}
.ant-pagination-item-active {
  font-weight: 500;
  background: #fff;
  border-color: #00441F;
}
.ant-pagination-item-active a {
  color: #00441F;
}
.ant-pagination-item-active:focus,
.ant-pagination-item-active:hover {
  border-color: #0d522a;
}
.ant-pagination-item-active:focus a,
.ant-pagination-item-active:hover a {
  color: #0d522a;
}
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  outline: 0;
}
.ant-pagination-jump-prev .ant-pagination-item-container,
.ant-pagination-jump-next .ant-pagination-item-container {
  position: relative;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: #00441F;
  font-size: 12px;
  letter-spacing: -1px;
  opacity: 0;
  transition: all 0.2s;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon-svg,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon-svg {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  margin: auto;
  color: rgba(0, 0, 0, 0.25);
  font-family: Arial, Helvetica, sans-serif;
  letter-spacing: 2px;
  text-align: center;
  text-indent: 0.13em;
  opacity: 1;
  transition: all 0.2s;
}
.ant-pagination-jump-prev:focus .ant-pagination-item-link-icon,
.ant-pagination-jump-next:focus .ant-pagination-item-link-icon,
.ant-pagination-jump-prev:hover .ant-pagination-item-link-icon,
.ant-pagination-jump-next:hover .ant-pagination-item-link-icon {
  opacity: 1;
}
.ant-pagination-jump-prev:focus .ant-pagination-item-ellipsis,
.ant-pagination-jump-next:focus .ant-pagination-item-ellipsis,
.ant-pagination-jump-prev:hover .ant-pagination-item-ellipsis,
.ant-pagination-jump-next:hover .ant-pagination-item-ellipsis {
  opacity: 0;
}
.ant-pagination-prev,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  margin-right: 8px;
}
.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next {
  display: inline-block;
  min-width: 32px;
  height: 32px;
  color: rgba(0, 0, 0, 0.85);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  line-height: 32px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-pagination-prev,
.ant-pagination-next {
  font-family: Arial, Helvetica, sans-serif;
  outline: 0;
}
.ant-pagination-prev button,
.ant-pagination-next button {
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  user-select: none;
}
.ant-pagination-prev:hover button,
.ant-pagination-next:hover button {
  border-color: #0d522a;
}
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0;
  font-size: 12px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 20px;
  outline: none;
  transition: all 0.3s;
}
.ant-pagination-prev:focus .ant-pagination-item-link,
.ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  color: #00441F;
  border-color: #00441F;
}
.ant-pagination-disabled,
.ant-pagination-disabled:hover,
.ant-pagination-disabled:focus {
  cursor: not-allowed;
}
.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link,
.ant-pagination-disabled:focus .ant-pagination-item-link {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-pagination-slash {
  margin: 0 10px 0 5px;
}
.ant-pagination-options {
  display: inline-block;
  margin-left: 16px;
  vertical-align: middle;
}
@media all and (-ms-high-contrast: none) {
  .ant-pagination-options *::-ms-backdrop,
  .ant-pagination-options {
    vertical-align: top;
  }
}
.ant-pagination-options-size-changer.ant-select {
  display: inline-block;
  width: auto;
}
.ant-pagination-options-quick-jumper {
  display: inline-block;
  height: 32px;
  margin-left: 8px;
  line-height: 32px;
  vertical-align: top;
}
.ant-pagination-options-quick-jumper input {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #AAAAAA;
  border-radius: 20px;
  transition: all 0.3s;
  width: 50px;
  margin: 0 8px;
}
.ant-pagination-options-quick-jumper input::-moz-placeholder {
  opacity: 1;
}
.ant-pagination-options-quick-jumper input::placeholder {
  color: #bfbfbf;
}
.ant-pagination-options-quick-jumper input:placeholder-shown {
  text-overflow: ellipsis;
}
.ant-pagination-options-quick-jumper input:hover {
  border-color: #0d522a;
  border-right-width: 1px !important;
}
.ant-input-rtl .ant-pagination-options-quick-jumper input:hover {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-pagination-options-quick-jumper input:focus,
.ant-pagination-options-quick-jumper input-focused {
  border-color: #0d522a;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(0, 68, 31, 0.2);
}
.ant-input-rtl .ant-pagination-options-quick-jumper input:focus,
.ant-input-rtl .ant-pagination-options-quick-jumper input-focused {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-pagination-options-quick-jumper input-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-pagination-options-quick-jumper input-disabled:hover {
  border-color: #AAAAAA;
  border-right-width: 1px !important;
}
.ant-pagination-options-quick-jumper input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
  opacity: 1;
}
.ant-pagination-options-quick-jumper input[disabled]:hover {
  border-color: #AAAAAA;
  border-right-width: 1px !important;
}
.ant-pagination-options-quick-jumper input-borderless,
.ant-pagination-options-quick-jumper input-borderless:hover,
.ant-pagination-options-quick-jumper input-borderless:focus,
.ant-pagination-options-quick-jumper input-borderless-focused,
.ant-pagination-options-quick-jumper input-borderless-disabled,
.ant-pagination-options-quick-jumper input-borderless[disabled] {
  background-color: transparent;
  border: none;
  box-shadow: none;
}
textarea.ant-pagination-options-quick-jumper input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5715;
  vertical-align: bottom;
  transition: all 0.3s, height 0s;
}
.ant-pagination-options-quick-jumper input-lg {
  padding: 6.5px 11px;
  font-size: 16px;
}
.ant-pagination-options-quick-jumper input-sm {
  padding: 0px 7px;
}
.ant-pagination-options-quick-jumper input-rtl {
  direction: rtl;
}
.ant-pagination-simple .ant-pagination-prev,
.ant-pagination-simple .ant-pagination-next {
  height: 24px;
  line-height: 24px;
  vertical-align: top;
}
.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-simple .ant-pagination-next .ant-pagination-item-link {
  height: 24px;
  background-color: transparent;
  border: 0;
}
.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link::after,
.ant-pagination-simple .ant-pagination-next .ant-pagination-item-link::after {
  height: 24px;
  line-height: 24px;
}
.ant-pagination-simple .ant-pagination-simple-pager {
  display: inline-block;
  height: 24px;
  margin-right: 8px;
}
.ant-pagination-simple .ant-pagination-simple-pager input {
  box-sizing: border-box;
  height: 100%;
  margin-right: 8px;
  padding: 0 6px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 20px;
  outline: none;
  transition: border-color 0.3s;
}
.ant-pagination-simple .ant-pagination-simple-pager input:hover {
  border-color: #00441F;
}
.ant-pagination-simple .ant-pagination-simple-pager input[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-pagination.mini .ant-pagination-total-text,
.ant-pagination.mini .ant-pagination-simple-pager {
  height: 24px;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-item {
  min-width: 24px;
  height: 24px;
  margin: 0;
  line-height: 22px;
}
.ant-pagination.mini .ant-pagination-item:not(.ant-pagination-item-active) {
  background: transparent;
  border-color: transparent;
}
.ant-pagination.mini .ant-pagination-prev,
.ant-pagination.mini .ant-pagination-next {
  min-width: 24px;
  height: 24px;
  margin: 0;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination.mini .ant-pagination-next .ant-pagination-item-link {
  background: transparent;
  border-color: transparent;
}
.ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link::after,
.ant-pagination.mini .ant-pagination-next .ant-pagination-item-link::after {
  height: 24px;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-jump-prev,
.ant-pagination.mini .ant-pagination-jump-next {
  height: 24px;
  margin-right: 0;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-options {
  margin-left: 2px;
}
.ant-pagination.mini .ant-pagination-options-size-changer {
  top: 0px;
}
.ant-pagination.mini .ant-pagination-options-quick-jumper {
  height: 24px;
  line-height: 24px;
}
.ant-pagination.mini .ant-pagination-options-quick-jumper input {
  padding: 0px 7px;
  width: 44px;
}
.ant-pagination.ant-pagination-disabled {
  cursor: not-allowed;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item {
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item a {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  border: none;
  cursor: not-allowed;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-active {
  background: #dbdbdb;
  border-color: transparent;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-active a {
  color: #fff;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-link {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-pagination-simple.ant-pagination.ant-pagination-disabled .ant-pagination-item-link {
  background: transparent;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-link-icon {
  opacity: 0;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-item-ellipsis {
  opacity: 1;
}
.ant-pagination.ant-pagination-disabled .ant-pagination-simple-pager {
  color: rgba(0, 0, 0, 0.25);
}
@media only screen and (max-width: 992px) {
  .ant-pagination-item-after-jump-prev,
  .ant-pagination-item-before-jump-next {
    display: none;
  }
}
@media only screen and (max-width: 576px) {
  .ant-pagination-options {
    display: none;
  }
}
.ant-pagination-rtl .ant-pagination-total-text {
  margin-right: 0;
  margin-left: 8px;
}
.ant-pagination-rtl .ant-pagination-item,
.ant-pagination-rtl .ant-pagination-prev,
.ant-pagination-rtl .ant-pagination-jump-prev,
.ant-pagination-rtl .ant-pagination-jump-next {
  margin-right: 0;
  margin-left: 8px;
}
.ant-pagination-rtl .ant-pagination-slash {
  margin: 0 5px 0 10px;
}
.ant-pagination-rtl .ant-pagination-options {
  margin-right: 16px;
  margin-left: 0;
}
.ant-pagination-rtl .ant-pagination-options .ant-pagination-options-size-changer.ant-select {
  margin-right: 0;
  margin-left: 8px;
}
.ant-pagination-rtl .ant-pagination-options .ant-pagination-options-quick-jumper {
  margin-left: 0;
}
.ant-pagination-rtl.ant-pagination-simple .ant-pagination-simple-pager {
  margin-right: 0;
  margin-left: 8px;
}
.ant-pagination-rtl.ant-pagination-simple .ant-pagination-simple-pager input {
  margin-right: 0;
  margin-left: 8px;
}
.ant-pagination-rtl.ant-pagination.mini .ant-pagination-options {
  margin-right: 2px;
  margin-left: 0;
}
.ant-popconfirm {
  z-index: 1060;
}
.ant-popover {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1030;
  font-weight: normal;
  white-space: normal;
  text-align: left;
  cursor: auto;
  user-select: text;
}
.ant-popover::after {
  position: absolute;
  background: rgba(255, 255, 255, 0.01);
  content: '';
}
.ant-popover-hidden {
  display: none;
}
.ant-popover-placement-top,
.ant-popover-placement-topLeft,
.ant-popover-placement-topRight {
  padding-bottom: 10px;
}
.ant-popover-placement-right,
.ant-popover-placement-rightTop,
.ant-popover-placement-rightBottom {
  padding-left: 10px;
}
.ant-popover-placement-bottom,
.ant-popover-placement-bottomLeft,
.ant-popover-placement-bottomRight {
  padding-top: 10px;
}
.ant-popover-placement-left,
.ant-popover-placement-leftTop,
.ant-popover-placement-leftBottom {
  padding-right: 10px;
}
.ant-popover-inner {
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 20px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15) \9;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ant-popover {
    /* IE10+ */
  }
  .ant-popover-inner {
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  }
}
.ant-popover-title {
  min-width: 177px;
  min-height: 32px;
  margin: 0;
  padding: 5px 16px 4px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  border-bottom: 1px solid #f0f0f0;
}
.ant-popover-inner-content {
  padding: 12px 16px;
  color: rgba(0, 0, 0, 0.85);
}
.ant-popover-message {
  position: relative;
  padding: 4px 0 12px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
.ant-popover-message > .anticon {
  position: absolute;
  top: 8.0005px;
  color: #FFC107;
  font-size: 14px;
}
.ant-popover-message-title {
  padding-left: 22px;
}
.ant-popover-buttons {
  margin-bottom: 4px;
  text-align: right;
}
.ant-popover-buttons button {
  margin-left: 8px;
}
.ant-popover-arrow {
  position: absolute;
  display: block;
  width: 8.48528137px;
  height: 8.48528137px;
  background: transparent;
  border-style: solid;
  border-width: 4.24264069px;
  transform: rotate(45deg);
}
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow {
  bottom: 6.2px;
  border-top-color: transparent;
  border-right-color: #fff;
  border-bottom-color: #fff;
  border-left-color: transparent;
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
}
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow {
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}
.ant-popover-placement-topLeft > .ant-popover-content > .ant-popover-arrow {
  left: 16px;
}
.ant-popover-placement-topRight > .ant-popover-content > .ant-popover-arrow {
  right: 16px;
}
.ant-popover-placement-right > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-rightBottom > .ant-popover-content > .ant-popover-arrow {
  left: 6px;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: #fff;
  border-left-color: #fff;
  box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
}
.ant-popover-placement-right > .ant-popover-content > .ant-popover-arrow {
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
}
.ant-popover-placement-rightTop > .ant-popover-content > .ant-popover-arrow {
  top: 12px;
}
.ant-popover-placement-rightBottom > .ant-popover-content > .ant-popover-arrow {
  bottom: 12px;
}
.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
  top: 6px;
  border-top-color: #fff;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: #fff;
  box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);
}
.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow {
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
}
.ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow {
  left: 16px;
}
.ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
  right: 16px;
}
.ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-leftTop > .ant-popover-content > .ant-popover-arrow,
.ant-popover-placement-leftBottom > .ant-popover-content > .ant-popover-arrow {
  right: 6px;
  border-top-color: #fff;
  border-right-color: #fff;
  border-bottom-color: transparent;
  border-left-color: transparent;
  box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
}
.ant-popover-placement-left > .ant-popover-content > .ant-popover-arrow {
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
}
.ant-popover-placement-leftTop > .ant-popover-content > .ant-popover-arrow {
  top: 12px;
}
.ant-popover-placement-leftBottom > .ant-popover-content > .ant-popover-arrow {
  bottom: 12px;
}
.ant-popover-rtl {
  direction: rtl;
  text-align: right;
}
.ant-popover-rtl .ant-popover-message-title {
  padding-right: 22px;
  padding-left: 16px;
}
.ant-popover-rtl .ant-popover-buttons {
  text-align: left;
}
.ant-popover-rtl .ant-popover-buttons button {
  margin-right: 8px;
  margin-left: 0;
}
.ant-progress {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
}
.ant-progress-line {
  position: relative;
  width: 100%;
  font-size: 14px;
}
.ant-progress-steps {
  display: inline-block;
}
.ant-progress-steps-outer {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.ant-progress-steps-item {
  flex-shrink: 0;
  min-width: 2px;
  margin-right: 2px;
  background: #f3f3f3;
  transition: all 0.3s;
}
.ant-progress-steps-item-active {
  background: #1890ff;
}
.ant-progress-small.ant-progress-line,
.ant-progress-small.ant-progress-line .ant-progress-text .anticon {
  font-size: 12px;
}
.ant-progress-outer {
  display: inline-block;
  width: 100%;
  margin-right: 0;
  padding-right: 0;
}
.ant-progress-show-info .ant-progress-outer {
  margin-right: calc(-2em - 8px);
  padding-right: calc(2em + 8px);
}
.ant-progress-inner {
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  vertical-align: middle;
  background-color: #f5f5f5;
  border-radius: 100px;
}
.ant-progress-circle-trail {
  stroke: #f5f5f5;
}
.ant-progress-circle-path {
  animation: ant-progress-appear 0.3s;
}
.ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #1890ff;
}
.ant-progress-success-bg,
.ant-progress-bg {
  position: relative;
  background-color: #1890ff;
  border-radius: 100px;
  transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
}
.ant-progress-success-bg {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #79be39;
}
.ant-progress-text {
  display: inline-block;
  width: 2em;
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 1em;
  line-height: 1;
  white-space: nowrap;
  text-align: left;
  vertical-align: middle;
  word-break: normal;
}
.ant-progress-text .anticon {
  font-size: 14px;
}
.ant-progress-status-active .ant-progress-bg::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  border-radius: 10px;
  opacity: 0;
  animation: ant-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite;
  content: '';
}
.ant-progress-status-exception .ant-progress-bg {
  background-color: #DD4E4E;
}
.ant-progress-status-exception .ant-progress-text {
  color: #DD4E4E;
}
.ant-progress-status-exception .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #DD4E4E;
}
.ant-progress-status-success .ant-progress-bg {
  background-color: #79be39;
}
.ant-progress-status-success .ant-progress-text {
  color: #79be39;
}
.ant-progress-status-success .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #79be39;
}
.ant-progress-circle .ant-progress-inner {
  position: relative;
  line-height: 1;
  background-color: transparent;
}
.ant-progress-circle .ant-progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 1em;
  line-height: 1;
  white-space: normal;
  text-align: center;
  transform: translate(-50%, -50%);
}
.ant-progress-circle .ant-progress-text .anticon {
  font-size: 1.16666667em;
}
.ant-progress-circle.ant-progress-status-exception .ant-progress-text {
  color: #DD4E4E;
}
.ant-progress-circle.ant-progress-status-success .ant-progress-text {
  color: #79be39;
}
@keyframes ant-progress-active {
  0% {
    width: 0;
    opacity: 0.1;
  }
  20% {
    width: 0;
    opacity: 0.5;
  }
  100% {
    width: 100%;
    opacity: 0;
  }
}
.ant-progress-rtl {
  direction: rtl;
}
.ant-progress-rtl.ant-progress-show-info .ant-progress-outer {
  margin-right: 0;
  margin-left: calc(-2em - 8px);
  padding-right: 0;
  padding-left: calc(2em + 8px);
}
.ant-progress-rtl .ant-progress-success-bg {
  right: 0;
  left: auto;
}
.ant-progress-rtl.ant-progress-line .ant-progress-text,
.ant-progress-rtl.ant-progress-steps .ant-progress-text {
  margin-right: 8px;
  margin-left: 0;
  text-align: right;
}
.ant-radio-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
  font-size: 0;
  line-height: unset;
}
.ant-radio-group .ant-badge-count {
  z-index: 1;
}
.ant-radio-group > .ant-badge:not(:first-child) > .ant-radio-button-wrapper {
  border-left: none;
}
.ant-radio-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  margin-right: 8px;
  white-space: nowrap;
  cursor: pointer;
}
.ant-radio {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  top: 0px;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: text-bottom;
  outline: none;
  cursor: pointer;
}
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #00441F;
}
.ant-radio-input:focus + .ant-radio-inner {
  box-shadow: 0 0 0 3px rgba(0, 68, 31, 0.08);
}
.ant-radio-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #00441F;
  border-radius: 50%;
  visibility: hidden;
  animation: antRadioEffect 0.36s ease-in-out;
  animation-fill-mode: both;
  content: '';
}
.ant-radio:hover::after,
.ant-radio-wrapper:hover .ant-radio::after {
  visibility: visible;
}
.ant-radio-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  background-color: #00441F;
  border-color: #d9d9d9;
  border-style: solid;
  border-width: 1px;
  border-radius: 50%;
  transition: all 0.3s;
}
.ant-radio-inner::after {
  position: absolute;
  top: 3px;
  left: 3px;
  display: table;
  width: 8px;
  height: 8px;
  background-color: #00441F;
  border-top: 0;
  border-left: 0;
  border-radius: 8px;
  transform: scale(0);
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  content: ' ';
}
.ant-radio-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
}
.ant-radio-checked .ant-radio-inner {
  border-color: #00441F;
}
.ant-radio-checked .ant-radio-inner::after {
  transform: scale(1);
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-radio-disabled {
  cursor: not-allowed;
}
.ant-radio-disabled .ant-radio-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
  cursor: not-allowed;
}
.ant-radio-disabled .ant-radio-inner::after {
  background-color: rgba(0, 0, 0, 0.2);
}
.ant-radio-disabled .ant-radio-input {
  cursor: not-allowed;
}
.ant-radio-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
span.ant-radio + * {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-radio-button-wrapper {
  position: relative;
  display: inline-block;
  height: 32px;
  margin: 0;
  padding: 0 15px;
  color: #414141;
  font-size: 14px;
  line-height: 30px;
  background: #00441F;
  border: 1px solid #d9d9d9;
  border-top-width: 1.02px;
  border-left-width: 0;
  cursor: pointer;
  transition: color 0.3s, background 0.3s, border-color 0.3s, box-shadow 0.3s;
}
.ant-radio-button-wrapper a {
  color: #414141;
}
.ant-radio-button-wrapper > .ant-radio-button {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}
.ant-radio-group-large .ant-radio-button-wrapper {
  height: 40px;
  font-size: 16px;
  line-height: 38px;
}
.ant-radio-group-small .ant-radio-button-wrapper {
  height: 24px;
  padding: 0 7px;
  line-height: 22px;
}
.ant-radio-button-wrapper:not(:first-child)::before {
  position: absolute;
  top: -1px;
  left: -1px;
  display: block;
  box-sizing: content-box;
  width: 1px;
  height: 100%;
  padding: 1px 0;
  background-color: #d9d9d9;
  transition: background-color 0.3s;
  content: '';
}
.ant-radio-button-wrapper:first-child {
  border-left: 1px solid #d9d9d9;
  border-radius: 20px 0 0 20px;
}
.ant-radio-button-wrapper:last-child {
  border-radius: 0 20px 20px 0;
}
.ant-radio-button-wrapper:first-child:last-child {
  border-radius: 20px;
}
.ant-radio-button-wrapper:hover {
  position: relative;
  color: #00441F;
}
.ant-radio-button-wrapper:focus-within {
  box-shadow: 0 0 0 3px rgba(0, 68, 31, 0.08);
}
.ant-radio-button-wrapper .ant-radio-inner,
.ant-radio-button-wrapper input[type='checkbox'],
.ant-radio-button-wrapper input[type='radio'] {
  width: 0;
  height: 0;
  opacity: 0;
  pointer-events: none;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 1;
  color: #00441F;
  background: #00441F;
  border-color: #00441F;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #00441F;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
  border-color: #00441F;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #0d522a;
  border-color: #0d522a;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
  background-color: #0d522a;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  color: #001f0f;
  border-color: #001f0f;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active::before {
  background-color: #001f0f;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: 0 0 0 3px rgba(0, 68, 31, 0.08);
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff;
  background: #00441F;
  border-color: #00441F;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #fff;
  background: #0d522a;
  border-color: #0d522a;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
  color: #fff;
  background: #001f0f;
  border-color: #001f0f;
}
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: 0 0 0 3px rgba(0, 68, 31, 0.08);
}
.ant-radio-button-wrapper-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-radio-button-wrapper-disabled:first-child,
.ant-radio-button-wrapper-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
}
.ant-radio-button-wrapper-disabled:first-child {
  border-left-color: #d9d9d9;
}
.ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
  color: rgba(0, 0, 0, 0.25);
  background-color: #e6e6e6;
  border-color: #d9d9d9;
  box-shadow: none;
}
@keyframes antRadioEffect {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.6);
    opacity: 0;
  }
}
.ant-radio-group.ant-radio-group-rtl {
  direction: rtl;
}
.ant-radio-wrapper.ant-radio-wrapper-rtl {
  margin-right: 0;
  margin-left: 8px;
  direction: rtl;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl {
  border-right-width: 0;
  border-left-width: 1px;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:not(:first-child)::before {
  right: -1px;
  left: 0;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:first-child {
  border-right: 1px solid #d9d9d9;
  border-radius: 0 20px 20px 0;
}
.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
  border-right-color: #0d522a;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:last-child {
  border-radius: 20px 0 0 20px;
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper-disabled:first-child {
  border-right-color: #d9d9d9;
}
.ant-rate {
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  font-feature-settings: 'tnum';
  display: inline-block;
  margin: 0;
  padding: 0;
  color: #fadb14;
  font-size: 20px;
  line-height: unset;
  list-style: none;
  outline: none;
}
.ant-rate-disabled .ant-rate-star {
  cursor: default;
}
.ant-rate-disabled .ant-rate-star:hover {
  transform: scale(1);
}
.ant-rate-star {
  position: relative;
  display: inline-block;
  color: inherit;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-rate-star:not(:last-child) {
  margin-right: 8px;
}
.ant-rate-star > div:focus {
  outline: 0;
}
.ant-rate-star > div:hover,
.ant-rate-star > div:focus {
  transform: scale(1.1);
}
.ant-rate-star-first,
.ant-rate-star-second {
  color: #f0f0f0;
  transition: all 0.3s;
  user-select: none;
}
.ant-rate-star-first .anticon,
.ant-rate-star-second .anticon {
  vertical-align: middle;
}
.ant-rate-star-first {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  overflow: hidden;
  opacity: 0;
}
.ant-rate-star-half .ant-rate-star-first,
.ant-rate-star-half .ant-rate-star-second {
  opacity: 1;
}
.ant-rate-star-half .ant-rate-star-first,
.ant-rate-star-full .ant-rate-star-second {
  color: inherit;
}
.ant-rate-text {
  display: inline-block;
  margin: 0 8px;
  font-size: 14px;
}
.ant-rate-rtl {
  direction: rtl;
}
.ant-rate-rtl .ant-rate-star:not(:last-child) {
  margin-right: 0;
  margin-left: 8px;
}
.ant-rate-rtl .ant-rate-star-first {
  right: 0;
  left: auto;
}
.ant-result {
  padding: 48px 32px;
}
.ant-result-success .ant-result-icon > .anticon {
  color: #79be39;
}
.ant-result-error .ant-result-icon > .anticon {
  color: #DD4E4E;
}
.ant-result-info .ant-result-icon > .anticon {
  color: #00441F;
}
.ant-result-warning .ant-result-icon > .anticon {
  color: #FFC107;
}
.ant-result-image {
  width: 250px;
  height: 295px;
  margin: auto;
}
.ant-result-icon {
  margin-bottom: 24px;
  text-align: center;
}
.ant-result-icon > .anticon {
  font-size: 72px;
}
.ant-result-title {
  color: rgba(0, 0, 0, 0.85);
  font-size: 24px;
  line-height: 1.8;
  text-align: center;
}
.ant-result-subtitle {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
  line-height: 1.6;
  text-align: center;
}
.ant-result-extra {
  margin: 24px 0 0 0;
  text-align: center;
}
.ant-result-extra > * {
  margin-right: 8px;
}
.ant-result-extra > *:last-child {
  margin-right: 0;
}
.ant-result-content {
  margin-top: 24px;
  padding: 24px 40px;
  background-color: #fafafa;
}
.ant-result-rtl {
  direction: rtl;
}
.ant-result-rtl .ant-result-extra > * {
  margin-right: 0;
  margin-left: 8px;
}
.ant-result-rtl .ant-result-extra > *:last-child {
  margin-left: 0;
}
.ant-select-single .ant-select-selector {
  display: flex;
}
.ant-select-single .ant-select-selector .ant-select-selection-search {
  position: absolute;
  top: 0;
  right: 11px;
  bottom: 0;
  left: 11px;
}
.ant-select-single .ant-select-selector .ant-select-selection-search-input {
  width: 100%;
}
.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  padding: 0;
  line-height: 30px;
  transition: all 0.3s;
}
@supports (-moz-appearance: meterbar) {
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 30px;
  }
}
.ant-select-single .ant-select-selector .ant-select-selection-item {
  position: relative;
  user-select: none;
}
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  pointer-events: none;
}
.ant-select-single .ant-select-selector::after,
.ant-select-single .ant-select-selector .ant-select-selection-item::after,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder::after {
  display: inline-block;
  width: 0;
  visibility: hidden;
  content: '\a0';
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  right: 25px;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 18px;
}
.ant-select-single.ant-select-open .ant-select-selection-item {
  color: #bfbfbf;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 100%;
  height: 32px;
  padding: 0 11px;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 30px;
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector::after {
  line-height: 30px;
}
.ant-select-single.ant-select-customize-input .ant-select-selector::after {
  display: none;
}
.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-search {
  position: static;
  width: 100%;
}
.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-placeholder {
  position: absolute;
  right: 0;
  left: 0;
  padding: 0 11px;
}
.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-placeholder::after {
  display: none;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  height: 40px;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector::after,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
  line-height: 38px;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
  height: 38px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
  height: 24px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector::after,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder {
  line-height: 22px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
  height: 22px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selection-search {
  right: 7px;
  left: 7px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 7px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-search {
  right: 28px;
}
.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 21px;
}
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 11px;
}
/**
 * Do not merge `height` & `line-height` under style with `selection` & `search`,
 * since chrome may update to redesign with its align logic.
 */
.ant-select-selection-overflow {
  position: relative;
  display: flex;
  flex: auto;
  flex-wrap: wrap;
  max-width: 100%;
}
.ant-select-selection-overflow-item {
  flex: none;
  max-width: 100%;
}
.ant-select-multiple .ant-select-selector {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 1px 4px;
}
.ant-select-show-search.ant-select-multiple .ant-select-selector {
  cursor: text;
}
.ant-select-disabled.ant-select-multiple .ant-select-selector {
  background: #f5f5f5;
  cursor: not-allowed;
}
.ant-select-multiple .ant-select-selector::after {
  display: inline-block;
  width: 0;
  margin: 2px 0;
  line-height: 24px;
  content: '\a0';
}
.ant-select-multiple.ant-select-show-arrow .ant-select-selector,
.ant-select-multiple.ant-select-allow-clear .ant-select-selector {
  padding-right: 24px;
}
.ant-select-multiple .ant-select-selection-item {
  position: relative;
  display: flex;
  flex: none;
  box-sizing: border-box;
  max-width: 100%;
  height: 24px;
  margin-top: 2px;
  margin-inline-end: 4px;
  margin-bottom: 2px;
  padding-inline-start: 8px;
  padding-inline-end: 4px;
  line-height: 22px;
  background: #f5f5f5;
  border: 1px solid #f0f0f0;
  border-radius: 20px;
  cursor: default;
  transition: font-size 0.3s, line-height 0.3s, height 0.3s;
  user-select: none;
}
.ant-select-disabled.ant-select-multiple .ant-select-selection-item {
  color: #bfbfbf;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.ant-select-multiple .ant-select-selection-item-content {
  display: inline-block;
  margin-right: 4px;
  overflow: hidden;
  white-space: pre;
  text-overflow: ellipsis;
}
.ant-select-multiple .ant-select-selection-item-remove {
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-align: center;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
  color: rgba(0, 0, 0, 0.45);
  font-weight: bold;
  font-size: 10px;
  line-height: inherit;
  cursor: pointer;
}
.ant-select-multiple .ant-select-selection-item-remove > * {
  line-height: 1;
}
.ant-select-multiple .ant-select-selection-item-remove svg {
  display: inline-block;
}
.ant-select-multiple .ant-select-selection-item-remove::before {
  display: none;
}
.ant-select-multiple .ant-select-selection-item-remove .ant-select-multiple .ant-select-selection-item-remove-icon {
  display: block;
}
.ant-select-multiple .ant-select-selection-item-remove > .anticon {
  vertical-align: -0.2em;
}
.ant-select-multiple .ant-select-selection-item-remove:hover {
  color: rgba(0, 0, 0, 0.75);
}
.ant-select-multiple .ant-select-selection-overflow-item + .ant-select-selection-overflow-item .ant-select-selection-search {
  margin-inline-start: 0;
}
.ant-select-multiple .ant-select-selection-search {
  position: relative;
  max-width: 100%;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-inline-start: 7px;
}
.ant-select-multiple .ant-select-selection-search-input,
.ant-select-multiple .ant-select-selection-search-mirror {
  height: 24px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  line-height: 24px;
  transition: all 0.3s;
}
.ant-select-multiple .ant-select-selection-search-input {
  width: 100%;
  min-width: 4.1px;
}
.ant-select-multiple .ant-select-selection-search-mirror {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  white-space: pre;
  visibility: hidden;
}
.ant-select-multiple .ant-select-selection-placeholder {
  position: absolute;
  top: 50%;
  right: 11px;
  left: 11px;
  transform: translateY(-50%);
  transition: all 0.3s;
}
.ant-select-multiple.ant-select-lg .ant-select-selector::after {
  line-height: 32px;
}
.ant-select-multiple.ant-select-lg .ant-select-selection-item {
  height: 32px;
  line-height: 30px;
}
.ant-select-multiple.ant-select-lg .ant-select-selection-search {
  height: 33px;
  line-height: 33px;
}
.ant-select-multiple.ant-select-lg .ant-select-selection-search-input,
.ant-select-multiple.ant-select-lg .ant-select-selection-search-mirror {
  height: 32px;
  line-height: 30px;
}
.ant-select-multiple.ant-select-sm .ant-select-selector::after {
  line-height: 16px;
}
.ant-select-multiple.ant-select-sm .ant-select-selection-item {
  height: 16px;
  line-height: 14px;
}
.ant-select-multiple.ant-select-sm .ant-select-selection-search {
  height: 17px;
  line-height: 17px;
}
.ant-select-multiple.ant-select-sm .ant-select-selection-search-input,
.ant-select-multiple.ant-select-sm .ant-select-selection-search-mirror {
  height: 16px;
  line-height: 14px;
}
.ant-select-multiple.ant-select-sm .ant-select-selection-placeholder {
  left: 7px;
}
.ant-select-multiple.ant-select-sm .ant-select-selection-search:first-child .ant-select-selection-search-input {
  margin-left: 3px;
}
.ant-select-multiple.ant-select-lg .ant-select-selection-item {
  height: 32px;
  line-height: 32px;
}
.ant-select-disabled .ant-select-selection-item-remove {
  display: none;
}
/* Reset search input style */
.ant-select {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 20px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: pointer;
}
.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  cursor: text;
}
.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: auto;
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #0d522a;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(0, 68, 31, 0.2);
}
.ant-input-rtl .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  cursor: not-allowed;
}
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: not-allowed;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  margin: 0;
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  appearance: none;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input::-webkit-search-cancel-button {
  display: none;
  -webkit-appearance: none;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #0d522a;
  border-right-width: 1px !important;
}
.ant-input-rtl .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-right-width: 0;
  border-left-width: 1px !important;
}
.ant-select-selection-item {
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
@media all and (-ms-high-contrast: none) {
  .ant-select-selection-item *::-ms-backdrop,
  .ant-select-selection-item {
    flex: auto;
  }
}
.ant-select-selection-placeholder {
  flex: 1;
  overflow: hidden;
  color: #bfbfbf;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
@media all and (-ms-high-contrast: none) {
  .ant-select-selection-placeholder *::-ms-backdrop,
  .ant-select-selection-placeholder {
    flex: auto;
  }
}
.ant-select-arrow {
  display: inline-block;
  color: inherit;
  font-style: normal;
  line-height: 0;
  text-transform: none;
  vertical-align: -0.125em;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 53%;
  right: 11px;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  line-height: 1;
  text-align: center;
  pointer-events: none;
}
.ant-select-arrow > * {
  line-height: 1;
}
.ant-select-arrow svg {
  display: inline-block;
}
.ant-select-arrow::before {
  display: none;
}
.ant-select-arrow .ant-select-arrow-icon {
  display: block;
}
.ant-select-arrow .anticon {
  vertical-align: top;
  transition: transform 0.3s;
}
.ant-select-arrow .anticon > svg {
  vertical-align: top;
}
.ant-select-arrow .anticon:not(.ant-select-suffix) {
  pointer-events: auto;
}
.ant-select-disabled .ant-select-arrow {
  cursor: not-allowed;
}
.ant-select-clear {
  position: absolute;
  top: 50%;
  right: 11px;
  z-index: 1;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: -6px;
  color: rgba(0, 0, 0, 0.25);
  font-size: 12px;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  background: #fff;
  cursor: pointer;
  opacity: 0;
  transition: color 0.3s ease, opacity 0.15s ease;
  text-rendering: auto;
}
.ant-select-clear::before {
  display: block;
}
.ant-select-clear:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-select:hover .ant-select-clear {
  opacity: 1;
}
.ant-select-dropdown {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  box-sizing: border-box;
  padding: 4px 0;
  overflow: hidden;
  font-size: 14px;
  font-variant: initial;
  background-color: #fff;
  border-radius: 20px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-bottomLeft,
.ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-bottomLeft {
  animation-name: antSlideUpIn;
}
.ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-topLeft,
.ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-topLeft {
  animation-name: antSlideDownIn;
}
.ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-bottomLeft {
  animation-name: antSlideUpOut;
}
.ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-topLeft {
  animation-name: antSlideDownOut;
}
.ant-select-dropdown-hidden {
  display: none;
}
.ant-select-dropdown-empty {
  color: rgba(0, 0, 0, 0.25);
}
.ant-select-item-empty {
  position: relative;
  display: block;
  min-height: 32px;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.25);
}
.ant-select-item {
  position: relative;
  display: block;
  min-height: 32px;
  padding: 5px 12px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  transition: background 0.3s ease;
}
.ant-select-item-group {
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  cursor: default;
}
.ant-select-item-option {
  display: flex;
}
.ant-select-item-option-content {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-select-item-option-state {
  flex: none;
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #f5f5f5;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  background-color: #77857b;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
  color: #00441F;
}
.ant-select-item-option-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-select-item-option-grouped {
  padding-left: 24px;
}
.ant-select-lg {
  font-size: 16px;
}
.ant-select-borderless .ant-select-selector {
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}
.ant-select-rtl {
  direction: rtl;
}
.ant-select-rtl .ant-select-arrow {
  right: initial;
  left: 11px;
}
.ant-select-rtl .ant-select-clear {
  right: initial;
  left: 11px;
}
.ant-select-dropdown-rtl {
  direction: rtl;
}
.ant-select-dropdown-rtl .ant-select-item-option-grouped {
  padding-right: 24px;
  padding-left: 12px;
}
.ant-select-rtl.ant-select-multiple.ant-select-show-arrow .ant-select-selector,
.ant-select-rtl.ant-select-multiple.ant-select-allow-clear .ant-select-selector {
  padding-right: 4px;
  padding-left: 24px;
}
.ant-select-rtl.ant-select-multiple .ant-select-selection-item {
  text-align: right;
}
.ant-select-rtl.ant-select-multiple .ant-select-selection-item-content {
  margin-right: 0;
  margin-left: 4px;
  text-align: right;
}
.ant-select-rtl.ant-select-multiple .ant-select-selection-search-mirror {
  right: 0;
  left: auto;
}
.ant-select-rtl.ant-select-multiple .ant-select-selection-search:first-child > .ant-select-selection-search-input {
  margin-right: 5px !important;
  margin-left: 0 !important;
}
.ant-select-rtl.ant-select-multiple .ant-select-selection-placeholder {
  right: 11px;
  left: auto;
}
.ant-select-rtl.ant-select-multiple.ant-select-sm .ant-select-selection-placeholder {
  right: 7px;
}
.ant-select-rtl.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-rtl.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  right: 0;
  left: 9px;
  text-align: right;
}
.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
  right: 11px;
  left: 25px;
}
.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 0;
  padding-left: 18px;
}
.ant-select-rtl.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding: 0 11px;
}
.ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-search {
  right: 0;
}
.ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item,
.ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-placeholder {
  padding-right: 0;
  padding-left: 21px;
}
.ant-skeleton {
  display: table;
  width: 100%;
}
.ant-skeleton-header {
  display: table-cell;
  padding-right: 16px;
  vertical-align: top;
}
.ant-skeleton-header .ant-skeleton-avatar {
  display: inline-block;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  width: 32px;
  height: 32px;
  line-height: 32px;
}
.ant-skeleton-header .ant-skeleton-avatar.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-header .ant-skeleton-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
}
.ant-skeleton-header .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-header .ant-skeleton-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
}
.ant-skeleton-header .ant-skeleton-avatar-sm.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-content {
  display: table-cell;
  width: 100%;
  vertical-align: top;
}
.ant-skeleton-content .ant-skeleton-title {
  width: 100%;
  height: 16px;
  margin-top: 16px;
  background: rgba(190, 190, 190, 0.2);
  border-radius: 4px;
}
.ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
  margin-top: 24px;
}
.ant-skeleton-content .ant-skeleton-paragraph {
  padding: 0;
}
.ant-skeleton-content .ant-skeleton-paragraph > li {
  width: 100%;
  height: 16px;
  list-style: none;
  background: rgba(190, 190, 190, 0.2);
  border-radius: 4px;
}
.ant-skeleton-content .ant-skeleton-paragraph > li:last-child:not(:first-child):not(:nth-child(2)) {
  width: 61%;
}
.ant-skeleton-content .ant-skeleton-paragraph > li + li {
  margin-top: 16px;
}
.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title {
  margin-top: 12px;
}
.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
  margin-top: 28px;
}
.ant-skeleton-round .ant-skeleton-content .ant-skeleton-title,
.ant-skeleton-round .ant-skeleton-content .ant-skeleton-paragraph > li {
  border-radius: 100px;
}
.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title,
.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph > li {
  background: linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(129, 129, 129, 0.24) 37%, rgba(190, 190, 190, 0.2) 63%);
  background-size: 400% 100%;
  animation: ant-skeleton-loading 1.4s ease infinite;
}
.ant-skeleton.ant-skeleton-active .ant-skeleton-avatar {
  background: linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(129, 129, 129, 0.24) 37%, rgba(190, 190, 190, 0.2) 63%);
  background-size: 400% 100%;
  animation: ant-skeleton-loading 1.4s ease infinite;
}
.ant-skeleton.ant-skeleton-active .ant-skeleton-button {
  background: linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(129, 129, 129, 0.24) 37%, rgba(190, 190, 190, 0.2) 63%);
  background-size: 400% 100%;
  animation: ant-skeleton-loading 1.4s ease infinite;
}
.ant-skeleton.ant-skeleton-active .ant-skeleton-input {
  background: linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(129, 129, 129, 0.24) 37%, rgba(190, 190, 190, 0.2) 63%);
  background-size: 400% 100%;
  animation: ant-skeleton-loading 1.4s ease infinite;
}
.ant-skeleton.ant-skeleton-active .ant-skeleton-image {
  background: linear-gradient(90deg, rgba(190, 190, 190, 0.2) 25%, rgba(129, 129, 129, 0.24) 37%, rgba(190, 190, 190, 0.2) 63%);
  background-size: 400% 100%;
  animation: ant-skeleton-loading 1.4s ease infinite;
}
.ant-skeleton-element {
  display: inline-block;
  width: auto;
}
.ant-skeleton-element .ant-skeleton-button {
  display: inline-block;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  border-radius: 20px;
  width: 64px;
  height: 32px;
  line-height: 32px;
}
.ant-skeleton-element .ant-skeleton-button.ant-skeleton-button-circle {
  width: 32px;
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-button.ant-skeleton-button-round {
  border-radius: 32px;
}
.ant-skeleton-element .ant-skeleton-button-lg {
  width: 80px;
  height: 40px;
  line-height: 40px;
}
.ant-skeleton-element .ant-skeleton-button-lg.ant-skeleton-button-circle {
  width: 40px;
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-button-lg.ant-skeleton-button-round {
  border-radius: 40px;
}
.ant-skeleton-element .ant-skeleton-button-sm {
  width: 48px;
  height: 24px;
  line-height: 24px;
}
.ant-skeleton-element .ant-skeleton-button-sm.ant-skeleton-button-circle {
  width: 24px;
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-button-sm.ant-skeleton-button-round {
  border-radius: 24px;
}
.ant-skeleton-element .ant-skeleton-avatar {
  display: inline-block;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  width: 32px;
  height: 32px;
  line-height: 32px;
}
.ant-skeleton-element .ant-skeleton-avatar.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-avatar-lg {
  width: 40px;
  height: 40px;
  line-height: 40px;
}
.ant-skeleton-element .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-avatar-sm {
  width: 24px;
  height: 24px;
  line-height: 24px;
}
.ant-skeleton-element .ant-skeleton-avatar-sm.ant-skeleton-avatar-circle {
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-input {
  display: inline-block;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  width: 100%;
  height: 32px;
  line-height: 32px;
}
.ant-skeleton-element .ant-skeleton-input-lg {
  width: 100%;
  height: 40px;
  line-height: 40px;
}
.ant-skeleton-element .ant-skeleton-input-sm {
  width: 100%;
  height: 24px;
  line-height: 24px;
}
.ant-skeleton-element .ant-skeleton-image {
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: top;
  background: rgba(190, 190, 190, 0.2);
  width: 96px;
  height: 96px;
  line-height: 96px;
}
.ant-skeleton-element .ant-skeleton-image.ant-skeleton-image-circle {
  border-radius: 50%;
}
.ant-skeleton-element .ant-skeleton-image-path {
  fill: #bfbfbf;
}
.ant-skeleton-element .ant-skeleton-image-svg {
  width: 48px;
  height: 48px;
  line-height: 48px;
  max-width: 192px;
  max-height: 192px;
}
.ant-skeleton-element .ant-skeleton-image-svg.ant-skeleton-image-circle {
  border-radius: 50%;
}
@keyframes ant-skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
.ant-skeleton-rtl {
  direction: rtl;
}
.ant-skeleton-rtl .ant-skeleton-header {
  padding-right: 0;
  padding-left: 16px;
}
.ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title,
.ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph > li {
  animation-name: ant-skeleton-loading-rtl;
}
.ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-avatar {
  animation-name: ant-skeleton-loading-rtl;
}
@keyframes ant-skeleton-loading-rtl {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}
.ant-slider {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  height: 12px;
  margin: 10px 6px 10px;
  padding: 4px 0;
  cursor: pointer;
  touch-action: none;
}
.ant-slider-vertical {
  width: 12px;
  height: 100%;
  margin: 6px 10px;
  padding: 0 4px;
}
.ant-slider-vertical .ant-slider-rail {
  width: 4px;
  height: 100%;
}
.ant-slider-vertical .ant-slider-track {
  width: 4px;
}
.ant-slider-vertical .ant-slider-handle {
  margin-top: -6px;
  margin-left: -5px;
}
.ant-slider-vertical .ant-slider-mark {
  top: 0;
  left: 12px;
  width: 18px;
  height: 100%;
}
.ant-slider-vertical .ant-slider-mark-text {
  left: 4px;
  white-space: nowrap;
}
.ant-slider-vertical .ant-slider-step {
  width: 4px;
  height: 100%;
}
.ant-slider-vertical .ant-slider-dot {
  top: auto;
  left: 2px;
  margin-bottom: -4px;
}
.ant-slider-tooltip .ant-tooltip-inner {
  min-width: unset;
}
.ant-slider-rtl.ant-slider-vertical .ant-slider-handle {
  margin-right: -5px;
  margin-left: 0;
}
.ant-slider-rtl.ant-slider-vertical .ant-slider-mark {
  right: 12px;
  left: auto;
}
.ant-slider-rtl.ant-slider-vertical .ant-slider-mark-text {
  right: 4px;
  left: auto;
}
.ant-slider-rtl.ant-slider-vertical .ant-slider-dot {
  right: 2px;
  left: auto;
}
.ant-slider-with-marks {
  margin-bottom: 28px;
}
.ant-slider-rail {
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #f5f5f5;
  border-radius: 20px;
  transition: background-color 0.3s;
}
.ant-slider-track {
  position: absolute;
  height: 4px;
  background-color: #336b47;
  border-radius: 20px;
  transition: background-color 0.3s;
}
.ant-slider-handle {
  position: absolute;
  width: 14px;
  height: 14px;
  margin-top: -5px;
  background-color: #fff;
  border: solid 2px #336b47;
  border-radius: 50%;
  box-shadow: 0;
  cursor: pointer;
  transition: border-color 0.3s, box-shadow 0.6s, transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}
.ant-slider-handle-dragging.ant-slider-handle-dragging.ant-slider-handle-dragging {
  border-color: #33694c;
  box-shadow: 0 0 0 5px rgba(0, 68, 31, 0.12);
}
.ant-slider-handle:focus {
  border-color: #33694c;
  outline: none;
  box-shadow: 0 0 0 5px rgba(0, 68, 31, 0.12);
}
.ant-slider-handle.ant-tooltip-open {
  border-color: #00441F;
}
.ant-slider:hover .ant-slider-rail {
  background-color: #e1e1e1;
}
.ant-slider:hover .ant-slider-track {
  background-color: #1e5e37;
}
.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  border-color: #1e5e37;
}
.ant-slider-mark {
  position: absolute;
  top: 14px;
  left: 0;
  width: 100%;
  font-size: 14px;
}
.ant-slider-mark-text {
  position: absolute;
  display: inline-block;
  color: rgba(0, 0, 0, 0.45);
  text-align: center;
  word-break: keep-all;
  cursor: pointer;
  user-select: none;
}
.ant-slider-mark-text-active {
  color: rgba(0, 0, 0, 0.85);
}
.ant-slider-step {
  position: absolute;
  width: 100%;
  height: 4px;
  background: transparent;
}
.ant-slider-dot {
  position: absolute;
  top: -2px;
  width: 8px;
  height: 8px;
  margin-left: -4px;
  background-color: #fff;
  border: 2px solid #f0f0f0;
  border-radius: 50%;
  cursor: pointer;
}
.ant-slider-dot:first-child {
  margin-left: -4px;
}
.ant-slider-dot:last-child {
  margin-left: -4px;
}
.ant-slider-dot-active {
  border-color: #80a28f;
}
.ant-slider-disabled {
  cursor: not-allowed;
}
.ant-slider-disabled .ant-slider-track {
  background-color: rgba(0, 0, 0, 0.25) !important;
}
.ant-slider-disabled .ant-slider-handle,
.ant-slider-disabled .ant-slider-dot {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.25) !important;
  box-shadow: none;
  cursor: not-allowed;
}
.ant-slider-disabled .ant-slider-mark-text,
.ant-slider-disabled .ant-slider-dot {
  cursor: not-allowed !important;
}
.ant-slider-rtl {
  direction: rtl;
}
.ant-slider-rtl .ant-slider-mark {
  right: 0;
  left: auto;
}
.ant-slider-rtl .ant-slider-dot {
  margin-right: -4px;
  margin-left: 0;
}
.ant-slider-rtl .ant-slider-dot:first-child {
  margin-right: -4px;
  margin-left: 0;
}
.ant-slider-rtl .ant-slider-dot:last-child {
  margin-right: -4px;
  margin-left: 0;
}
.ant-space {
  display: inline-flex;
}
.ant-space-vertical {
  flex-direction: column;
}
.ant-space-align-center {
  align-items: center;
}
.ant-space-align-start {
  align-items: flex-start;
}
.ant-space-align-end {
  align-items: flex-end;
}
.ant-space-align-baseline {
  align-items: baseline;
}
.ant-space-item:empty {
  display: none;
}
.ant-space-rtl {
  direction: rtl;
}
.ant-spin {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  display: none;
  color: #00441F;
  text-align: center;
  vertical-align: middle;
  opacity: 0;
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-spin-spinning {
  position: static;
  display: inline-block;
  opacity: 1;
}
.ant-spin-nested-loading {
  position: relative;
}
.ant-spin-nested-loading > div > .ant-spin {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  display: block;
  width: 100%;
  height: 100%;
  max-height: 400px;
}
.ant-spin-nested-loading > div > .ant-spin .ant-spin-dot {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -10px;
}
.ant-spin-nested-loading > div > .ant-spin .ant-spin-text {
  position: absolute;
  top: 50%;
  width: 100%;
  padding-top: 5px;
  text-shadow: 0 1px 2px #fff;
}
.ant-spin-nested-loading > div > .ant-spin.ant-spin-show-text .ant-spin-dot {
  margin-top: -20px;
}
.ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-dot {
  margin: -7px;
}
.ant-spin-nested-loading > div > .ant-spin-sm .ant-spin-text {
  padding-top: 2px;
}
.ant-spin-nested-loading > div > .ant-spin-sm.ant-spin-show-text .ant-spin-dot {
  margin-top: -17px;
}
.ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-dot {
  margin: -16px;
}
.ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-text {
  padding-top: 11px;
}
.ant-spin-nested-loading > div > .ant-spin-lg.ant-spin-show-text .ant-spin-dot {
  margin-top: -26px;
}
.ant-spin-container {
  position: relative;
  transition: opacity 0.3s;
}
.ant-spin-container::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  display: none \9;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0;
  transition: all 0.3s;
  content: '';
  pointer-events: none;
}
.ant-spin-blur {
  clear: both;
  overflow: hidden;
  opacity: 0.5;
  user-select: none;
  pointer-events: none;
}
.ant-spin-blur::after {
  opacity: 0.4;
  pointer-events: auto;
}
.ant-spin-tip {
  color: rgba(0, 0, 0, 0.45);
}
.ant-spin-dot {
  position: relative;
  display: inline-block;
  font-size: 20px;
  width: 1em;
  height: 1em;
}
.ant-spin-dot-item {
  position: absolute;
  display: block;
  width: 9px;
  height: 9px;
  background-color: #00441F;
  border-radius: 100%;
  transform: scale(0.75);
  transform-origin: 50% 50%;
  opacity: 0.3;
  animation: antSpinMove 1s infinite linear alternate;
}
.ant-spin-dot-item:nth-child(1) {
  top: 0;
  left: 0;
}
.ant-spin-dot-item:nth-child(2) {
  top: 0;
  right: 0;
  animation-delay: 0.4s;
}
.ant-spin-dot-item:nth-child(3) {
  right: 0;
  bottom: 0;
  animation-delay: 0.8s;
}
.ant-spin-dot-item:nth-child(4) {
  bottom: 0;
  left: 0;
  animation-delay: 1.2s;
}
.ant-spin-dot-spin {
  transform: rotate(45deg);
  animation: antRotate 1.2s infinite linear;
}
.ant-spin-sm .ant-spin-dot {
  font-size: 14px;
}
.ant-spin-sm .ant-spin-dot i {
  width: 6px;
  height: 6px;
}
.ant-spin-lg .ant-spin-dot {
  font-size: 32px;
}
.ant-spin-lg .ant-spin-dot i {
  width: 14px;
  height: 14px;
}
.ant-spin.ant-spin-show-text .ant-spin-text {
  display: block;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ */
  .ant-spin-blur {
    background: #fff;
    opacity: 0.5;
  }
}
@keyframes antSpinMove {
  to {
    opacity: 1;
  }
}
@keyframes antRotate {
  to {
    transform: rotate(405deg);
  }
}
.ant-spin-rtl {
  direction: rtl;
}
.ant-spin-rtl .ant-spin-dot-spin {
  transform: rotate(-45deg);
  animation-name: antRotateRtl;
}
@keyframes antRotateRtl {
  to {
    transform: rotate(-405deg);
  }
}
.ant-statistic {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
}
.ant-statistic-title {
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-statistic-content {
  color: rgba(0, 0, 0, 0.85);
  font-size: 24px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}
.ant-statistic-content-value {
  display: inline-block;
  direction: ltr;
}
.ant-statistic-content-prefix,
.ant-statistic-content-suffix {
  display: inline-block;
}
.ant-statistic-content-prefix {
  margin-right: 4px;
}
.ant-statistic-content-suffix {
  margin-left: 4px;
}
.ant-statistic-rtl {
  direction: rtl;
}
.ant-statistic-rtl .ant-statistic-content-prefix {
  margin-right: 0;
  margin-left: 4px;
}
.ant-statistic-rtl .ant-statistic-content-suffix {
  margin-right: 4px;
  margin-left: 0;
}
.ant-steps {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: flex;
  width: 100%;
  font-size: 0;
  text-align: initial;
}
.ant-steps-item {
  position: relative;
  display: inline-block;
  flex: 1;
  overflow: hidden;
  vertical-align: top;
}
.ant-steps-item-container {
  outline: none;
}
.ant-steps-item:last-child {
  flex: none;
}
.ant-steps-item:last-child > .ant-steps-item-container > .ant-steps-item-tail,
.ant-steps-item:last-child > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  display: none;
}
.ant-steps-item-icon,
.ant-steps-item-content {
  display: inline-block;
  vertical-align: top;
}
.ant-steps-item-icon {
  width: 32px;
  height: 32px;
  margin: 0 8px 0 0;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  line-height: 32px;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 32px;
  transition: background-color 0.3s, border-color 0.3s;
}
.ant-steps-item-icon .ant-steps-icon {
  position: relative;
  top: -1px;
  color: #00441F;
  line-height: 1;
}
.ant-steps-item-tail {
  position: absolute;
  top: 12px;
  left: 0;
  width: 100%;
  padding: 0 10px;
}
.ant-steps-item-tail::after {
  display: inline-block;
  width: 100%;
  height: 1px;
  background: #f0f0f0;
  border-radius: 1px;
  transition: background 0.3s;
  content: '';
}
.ant-steps-item-title {
  position: relative;
  display: inline-block;
  padding-right: 16px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  line-height: 32px;
}
.ant-steps-item-title::after {
  position: absolute;
  top: 16px;
  left: 100%;
  display: block;
  width: 9999px;
  height: 1px;
  background: #f0f0f0;
  content: '';
}
.ant-steps-item-subtitle {
  display: inline;
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: normal;
  font-size: 14px;
}
.ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-steps-item-wait .ant-steps-item-icon {
  background-color: #fff;
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: rgba(0, 0, 0, 0.25);
}
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: rgba(0, 0, 0, 0.25);
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.45);
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #f0f0f0;
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
}
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #f0f0f0;
}
.ant-steps-item-process .ant-steps-item-icon {
  background-color: #fff;
  border-color: #00441F;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #00441F;
}
.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #00441F;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.85);
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #f0f0f0;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.85);
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #f0f0f0;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: #00441F;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon .ant-steps-icon {
  color: #fff;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-title {
  font-weight: 500;
}
.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #fff;
  border-color: #00441F;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #00441F;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #00441F;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: rgba(0, 0, 0, 0.85);
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #00441F;
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
}
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #00441F;
}
.ant-steps-item-error .ant-steps-item-icon {
  background-color: #fff;
  border-color: #DD4E4E;
}
.ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon {
  color: #DD4E4E;
}
.ant-steps-item-error .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  background: #DD4E4E;
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #DD4E4E;
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: #f0f0f0;
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: #DD4E4E;
}
.ant-steps-item-error > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: #f0f0f0;
}
.ant-steps-item.ant-steps-next-error .ant-steps-item-title::after {
  background: #DD4E4E;
}
.ant-steps-item-disabled {
  cursor: not-allowed;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] {
  cursor: pointer;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-title,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-subtitle,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-description,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button'] .ant-steps-item-icon .ant-steps-icon {
  transition: color 0.3s;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-title,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-subtitle,
.ant-steps .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-description {
  color: #00441F;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon {
  border-color: #00441F;
}
.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process) > .ant-steps-item-container[role='button']:hover .ant-steps-item-icon .ant-steps-icon {
  color: #00441F;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-left: 16px;
  white-space: nowrap;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
  padding-left: 0;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child .ant-steps-item-title {
  padding-right: 0;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-tail {
  display: none;
}
.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-description {
  max-width: 140px;
  white-space: normal;
}
.ant-steps-item-custom > .ant-steps-item-container > .ant-steps-item-icon {
  height: auto;
  background: none;
  border: 0;
}
.ant-steps-item-custom > .ant-steps-item-container > .ant-steps-item-icon > .ant-steps-icon {
  top: 0px;
  left: 0.5px;
  width: 32px;
  height: 32px;
  font-size: 24px;
  line-height: 32px;
}
.ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
  color: #00441F;
}
.ant-steps:not(.ant-steps-vertical) .ant-steps-item-custom .ant-steps-item-icon {
  width: auto;
  background: none;
}
.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-left: 12px;
}
.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
  padding-left: 0;
}
.ant-steps-small .ant-steps-item-icon {
  width: 24px;
  height: 24px;
  margin: 0 8px 0 0;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
  border-radius: 24px;
}
.ant-steps-small .ant-steps-item-title {
  padding-right: 12px;
  font-size: 14px;
  line-height: 24px;
}
.ant-steps-small .ant-steps-item-title::after {
  top: 12px;
}
.ant-steps-small .ant-steps-item-description {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-steps-small .ant-steps-item-tail {
  top: 8px;
}
.ant-steps-small .ant-steps-item-custom .ant-steps-item-icon {
  width: inherit;
  height: inherit;
  line-height: inherit;
  background: none;
  border: 0;
  border-radius: 0;
}
.ant-steps-small .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
  font-size: 24px;
  line-height: 24px;
  transform: none;
}
.ant-steps-vertical {
  display: flex;
  flex-direction: column;
}
.ant-steps-vertical > .ant-steps-item {
  display: block;
  flex: 1 0 auto;
  padding-left: 0;
  overflow: visible;
}
.ant-steps-vertical > .ant-steps-item .ant-steps-item-icon {
  float: left;
  margin-right: 16px;
}
.ant-steps-vertical > .ant-steps-item .ant-steps-item-content {
  display: block;
  min-height: 48px;
  overflow: hidden;
}
.ant-steps-vertical > .ant-steps-item .ant-steps-item-title {
  line-height: 32px;
}
.ant-steps-vertical > .ant-steps-item .ant-steps-item-description {
  padding-bottom: 12px;
}
.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  position: absolute;
  top: 0;
  left: 16px;
  width: 1px;
  height: 100%;
  padding: 38px 0 6px;
}
.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail::after {
  width: 1px;
  height: 100%;
}
.ant-steps-vertical > .ant-steps-item:not(:last-child) > .ant-steps-item-container > .ant-steps-item-tail {
  display: block;
}
.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  display: none;
}
.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-tail {
  position: absolute;
  top: 0;
  left: 12px;
  padding: 30px 0 6px;
}
.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-title {
  line-height: 24px;
}
.ant-steps-label-vertical .ant-steps-item {
  overflow: visible;
}
.ant-steps-label-vertical .ant-steps-item-tail {
  margin-left: 58px;
  padding: 3.5px 24px;
}
.ant-steps-label-vertical .ant-steps-item-content {
  display: block;
  width: 116px;
  margin-top: 8px;
  text-align: center;
}
.ant-steps-label-vertical .ant-steps-item-icon {
  display: inline-block;
  margin-left: 42px;
}
.ant-steps-label-vertical .ant-steps-item-title {
  padding-right: 0;
  padding-left: 0;
}
.ant-steps-label-vertical .ant-steps-item-title::after {
  display: none;
}
.ant-steps-label-vertical .ant-steps-item-subtitle {
  display: block;
  margin-bottom: 4px;
  margin-left: 0;
  line-height: 1.5715;
}
.ant-steps-label-vertical.ant-steps-small:not(.ant-steps-dot) .ant-steps-item-icon {
  margin-left: 46px;
}
.ant-steps-dot .ant-steps-item-title,
.ant-steps-dot.ant-steps-small .ant-steps-item-title {
  line-height: 1.5715;
}
.ant-steps-dot .ant-steps-item-tail,
.ant-steps-dot.ant-steps-small .ant-steps-item-tail {
  top: 2px;
  width: 100%;
  margin: 0 0 0 70px;
  padding: 0;
}
.ant-steps-dot .ant-steps-item-tail::after,
.ant-steps-dot.ant-steps-small .ant-steps-item-tail::after {
  width: calc(100% - 20px);
  height: 3px;
  margin-left: 12px;
}
.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item:first-child .ant-steps-icon-dot {
  left: 2px;
}
.ant-steps-dot .ant-steps-item-icon,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
  width: 8px;
  height: 8px;
  margin-left: 67px;
  padding-right: 0;
  line-height: 8px;
  background: transparent;
  border: 0;
}
.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  border-radius: 100px;
  transition: all 0.3s;
  /* expand hover area */
}
.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot::after,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot::after {
  position: absolute;
  top: -12px;
  left: -26px;
  width: 60px;
  height: 32px;
  background: rgba(0, 0, 0, 0.001);
  content: '';
}
.ant-steps-dot .ant-steps-item-content,
.ant-steps-dot.ant-steps-small .ant-steps-item-content {
  width: 140px;
}
.ant-steps-dot .ant-steps-item-process .ant-steps-item-icon,
.ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-item-icon {
  position: relative;
  top: -1px;
  width: 10px;
  height: 10px;
  line-height: 10px;
  background: none;
}
.ant-steps-dot .ant-steps-item-process .ant-steps-icon:first-child .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-icon:first-child .ant-steps-icon-dot {
  left: 0;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
  margin-top: 8px;
  margin-left: 0;
  background: none;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  top: 2px;
  left: -9px;
  margin: 0;
  padding: 22px 0 4px;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
  left: 0;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item-content {
  width: inherit;
}
.ant-steps-vertical.ant-steps-dot .ant-steps-item-process .ant-steps-item-container .ant-steps-item-icon .ant-steps-icon-dot {
  left: -2px;
}
.ant-steps-navigation {
  padding-top: 12px;
}
.ant-steps-navigation.ant-steps-small .ant-steps-item-container {
  margin-left: -12px;
}
.ant-steps-navigation .ant-steps-item {
  overflow: visible;
  text-align: center;
}
.ant-steps-navigation .ant-steps-item-container {
  display: inline-block;
  height: 100%;
  margin-left: -16px;
  padding-bottom: 12px;
  text-align: left;
  transition: opacity 0.3s;
}
.ant-steps-navigation .ant-steps-item-container .ant-steps-item-content {
  max-width: auto;
}
.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title {
  max-width: 100%;
  padding-right: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title::after {
  display: none;
}
.ant-steps-navigation .ant-steps-item:not(.ant-steps-item-active) .ant-steps-item-container[role='button'] {
  cursor: pointer;
}
.ant-steps-navigation .ant-steps-item:not(.ant-steps-item-active) .ant-steps-item-container[role='button']:hover {
  opacity: 0.85;
}
.ant-steps-navigation .ant-steps-item:last-child {
  flex: 1;
}
.ant-steps-navigation .ant-steps-item:last-child::after {
  display: none;
}
.ant-steps-navigation .ant-steps-item::after {
  position: absolute;
  top: 50%;
  left: 100%;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-top: -14px;
  margin-left: -2px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-bottom: none;
  border-left: none;
  transform: rotate(45deg);
  content: '';
}
.ant-steps-navigation .ant-steps-item::before {
  position: absolute;
  bottom: 0;
  left: 50%;
  display: inline-block;
  width: 0;
  height: 2px;
  background-color: #00441F;
  transition: width 0.3s, left 0.3s;
  transition-timing-function: ease-out;
  content: '';
}
.ant-steps-navigation .ant-steps-item.ant-steps-item-active::before {
  left: 0;
  width: 100%;
}
.ant-steps-navigation.ant-steps-vertical > .ant-steps-item {
  margin-right: 0 !important;
}
.ant-steps-navigation.ant-steps-vertical > .ant-steps-item::before {
  display: none;
}
.ant-steps-navigation.ant-steps-vertical > .ant-steps-item.ant-steps-item-active::before {
  top: 0;
  right: 0;
  left: unset;
  display: block;
  width: 3px;
  height: calc(100% - 24px);
}
.ant-steps-navigation.ant-steps-vertical > .ant-steps-item::after {
  position: relative;
  top: -2px;
  left: 50%;
  display: block;
  width: 8px;
  height: 8px;
  margin-bottom: 8px;
  text-align: center;
  transform: rotate(135deg);
}
.ant-steps-navigation.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  visibility: hidden;
}
.ant-steps-rtl {
  direction: rtl;
}
.ant-steps.ant-steps-rtl .ant-steps-item-icon {
  margin-right: 0;
  margin-left: 8px;
}
.ant-steps-rtl .ant-steps-item-tail {
  right: 0;
  left: auto;
}
.ant-steps-rtl .ant-steps-item-title {
  padding-right: 0;
  padding-left: 16px;
}
.ant-steps-rtl .ant-steps-item-title::after {
  right: 100%;
  left: auto;
}
.ant-steps-rtl.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-right: 16px;
  padding-left: 0;
}
.ant-steps-rtl.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
  padding-right: 0;
}
.ant-steps-rtl.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child .ant-steps-item-title {
  padding-left: 0;
}
.ant-steps-rtl .ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
  right: 0.5px;
  left: auto;
}
.ant-steps-rtl.ant-steps-navigation.ant-steps-small .ant-steps-item-container {
  margin-right: -12px;
  margin-left: 0;
}
.ant-steps-rtl.ant-steps-navigation .ant-steps-item-container {
  margin-right: -16px;
  margin-left: 0;
  text-align: right;
}
.ant-steps-rtl.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title {
  padding-left: 0;
}
.ant-steps-rtl.ant-steps-navigation .ant-steps-item::after {
  right: 100%;
  left: auto;
  margin-right: -2px;
  margin-left: 0;
  transform: rotate(225deg);
}
.ant-steps-rtl.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
  padding-right: 12px;
  padding-left: 0;
}
.ant-steps-rtl.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
  padding-right: 0;
}
.ant-steps-rtl.ant-steps-small .ant-steps-item-title {
  padding-right: 0;
  padding-left: 12px;
}
.ant-steps-rtl.ant-steps-vertical > .ant-steps-item .ant-steps-item-icon {
  float: right;
  margin-right: 0;
  margin-left: 16px;
}
.ant-steps-rtl.ant-steps-vertical > .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  right: 16px;
  left: auto;
}
.ant-steps-rtl.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-tail {
  right: 12px;
  left: auto;
}
.ant-steps-rtl.ant-steps-label-vertical .ant-steps-item-title {
  padding-left: 0;
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item-tail,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-tail {
  margin: 0 70px 0 0;
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item-tail::after,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-tail::after {
  margin-right: 12px;
  margin-left: 0;
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item:first-child .ant-steps-icon-dot {
  right: 2px;
  left: auto;
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item-icon,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
  margin-right: 67px;
  margin-left: 0;
}
.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
  /* expand hover area */
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
  float: right;
}
.ant-steps-rtl.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot::after,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot::after {
  right: -26px;
  left: auto;
}
.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
  margin-right: 0;
  margin-left: 16px;
}
.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item > .ant-steps-item-container > .ant-steps-item-tail {
  right: -9px;
  left: auto;
}
.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
  right: 0;
  left: auto;
}
.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item-process .ant-steps-icon-dot {
  right: -2px;
  left: auto;
}
.ant-steps-with-progress .ant-steps-item {
  padding-top: 4px;
}
.ant-steps-with-progress .ant-steps-item .ant-steps-item-tail {
  top: 4px !important;
}
.ant-steps-with-progress .ant-steps-item-icon {
  position: relative;
}
.ant-steps-with-progress .ant-steps-item-icon .ant-progress {
  position: absolute;
  top: -5px;
  right: -5px;
  bottom: -5px;
  left: -5px;
}
.ant-switch {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  min-width: 44px;
  height: 22px;
  line-height: 22px;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0.25);
  border: 0;
  border-radius: 100px;
  cursor: pointer;
  transition: all 0.2s;
  user-select: none;
}
.ant-switch:focus {
  outline: 0;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
}
.ant-switch-checked:focus {
  box-shadow: 0 0 0 2px rgba(0, 68, 31, 0.2);
}
.ant-switch:focus:hover {
  box-shadow: none;
}
.ant-switch-checked {
  background-color: #00441F;
}
.ant-switch-loading,
.ant-switch-disabled {
  cursor: not-allowed;
  opacity: 0.4;
}
.ant-switch-loading *,
.ant-switch-disabled * {
  box-shadow: none;
  cursor: not-allowed;
}
.ant-switch-inner {
  display: block;
  margin: 0 7px 0 25px;
  color: #fff;
  font-size: 12px;
  transition: margin 0.2s;
}
.ant-switch-checked .ant-switch-inner {
  margin: 0 25px 0 7px;
}
.ant-switch-handle {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 18px;
  height: 18px;
  transition: all 0.2s ease-in-out;
}
.ant-switch-handle::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  border-radius: 9px;
  box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
  transition: all 0.2s ease-in-out;
  content: '';
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 18px - 2px);
}
.ant-switch:not(.ant-switch-disabled):active .ant-switch-handle::before {
  right: -30%;
  left: 0;
}
.ant-switch:not(.ant-switch-disabled):active.ant-switch-checked .ant-switch-handle::before {
  right: 0;
  left: -30%;
}
.ant-switch-loading-icon {
  position: relative;
  top: 2px;
  color: rgba(0, 0, 0, 0.65);
  vertical-align: top;
}
.ant-switch-checked .ant-switch-loading-icon {
  color: #00441F;
}
.ant-switch-small {
  min-width: 28px;
  height: 16px;
  line-height: 16px;
}
.ant-switch-small .ant-switch-inner {
  margin: 0 5px 0 18px;
  font-size: 12px;
}
.ant-switch-small .ant-switch-handle {
  width: 12px;
  height: 12px;
}
.ant-switch-small .ant-switch-loading-icon {
  top: 1.5px;
  font-size: 9px;
}
.ant-switch-small.ant-switch-checked .ant-switch-inner {
  margin: 0 18px 0 5px;
}
.ant-switch-small.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 12px - 2px);
}
.ant-switch-rtl {
  direction: rtl;
}
.ant-switch-rtl .ant-switch-inner {
  margin: 0 25px 0 7px;
}
.ant-switch-rtl .ant-switch-handle {
  right: 2px;
  left: auto;
}
.ant-switch-rtl:not(.ant-switch-rtl-disabled):active .ant-switch-handle::before {
  right: 0;
  left: -30%;
}
.ant-switch-rtl:not(.ant-switch-rtl-disabled):active.ant-switch-checked .ant-switch-handle::before {
  right: -30%;
  left: 0;
}
.ant-switch-rtl.ant-switch-checked .ant-switch-inner {
  margin: 0 7px 0 25px;
}
.ant-switch-rtl.ant-switch-checked .ant-switch-handle {
  right: calc(100% - 18px - 2px);
}
.ant-switch-rtl.ant-switch-small.ant-switch-checked .ant-switch-handle {
  right: calc(100% - 12px - 2px);
}
.ant-table.ant-table-middle {
  font-size: 14px;
}
.ant-table.ant-table-middle .ant-table-title,
.ant-table.ant-table-middle .ant-table-footer,
.ant-table.ant-table-middle .ant-table-thead > tr > th,
.ant-table.ant-table-middle .ant-table-tbody > tr > td,
.ant-table.ant-table-middle tfoot > tr > th,
.ant-table.ant-table-middle tfoot > tr > td {
  padding: 12px 8px;
}
.ant-table.ant-table-middle .ant-table-thead th.ant-table-column-has-sorters {
  padding: 0;
}
.ant-table.ant-table-middle .ant-table-thead .ant-table-filter-column {
  margin: -12px -8px;
}
.ant-table.ant-table-middle .ant-table-thead .ant-table-filter-column-title {
  padding: 12px 2.3em 12px 8px;
}
.ant-table.ant-table-middle .ant-table-thead .ant-table-column-sorters {
  padding: 12px 8px;
}
.ant-table.ant-table-middle .ant-table-expanded-row-fixed {
  margin: -12px -8px;
}
.ant-table.ant-table-middle .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
  margin: -12px -8px -12px 25px;
}
.ant-table.ant-table-small {
  font-size: 14px;
}
.ant-table.ant-table-small .ant-table-title,
.ant-table.ant-table-small .ant-table-footer,
.ant-table.ant-table-small .ant-table-thead > tr > th,
.ant-table.ant-table-small .ant-table-tbody > tr > td,
.ant-table.ant-table-small tfoot > tr > th,
.ant-table.ant-table-small tfoot > tr > td {
  padding: 8px 8px;
}
.ant-table.ant-table-small .ant-table-thead th.ant-table-column-has-sorters {
  padding: 0;
}
.ant-table.ant-table-small .ant-table-thead .ant-table-filter-column {
  margin: -8px -8px;
}
.ant-table.ant-table-small .ant-table-thead .ant-table-filter-column-title {
  padding: 8px 2.3em 8px 8px;
}
.ant-table.ant-table-small .ant-table-thead .ant-table-column-sorters {
  padding: 8px 8px;
}
.ant-table.ant-table-small .ant-table-expanded-row-fixed {
  margin: -8px -8px;
}
.ant-table.ant-table-small .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
  margin: -8px -8px -8px 25px;
}
.ant-table-small .ant-table-thead > tr > th {
  background-color: #fafafa;
}
.ant-table-small .ant-table-selection-column {
  width: 46px;
  min-width: 46px;
}
.ant-table.ant-table-bordered > .ant-table-title {
  border: 1px solid #f0f0f0;
  border-bottom: 0;
}
.ant-table.ant-table-bordered > .ant-table-container {
  border: 1px solid #f0f0f0;
  border-right: 0;
  border-bottom: 0;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > td {
  border-right: 1px solid #f0f0f0;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr:not(:last-child) > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr:not(:last-child) > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr:not(:last-child) > th {
  border-bottom: 1px solid #f0f0f0;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > .ant-table-cell-fix-right-first::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > .ant-table-cell-fix-right-first::after {
  border-right: 1px solid #f0f0f0;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed {
  margin: -16px -17px;
}
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td > .ant-table-expanded-row-fixed::after,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed::after {
  position: absolute;
  top: 0;
  right: 1px;
  bottom: 0;
  border-right: 1px solid #f0f0f0;
  content: '';
}
.ant-table.ant-table-bordered.ant-table-scroll-horizontal > .ant-table-container > .ant-table-body > table > tbody > tr.ant-table-expanded-row > td,
.ant-table.ant-table-bordered.ant-table-scroll-horizontal > .ant-table-container > .ant-table-body > table > tbody > tr.ant-table-placeholder > td {
  border-right: 0;
}
.ant-table.ant-table-bordered.ant-table-middle > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered.ant-table-middle > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed {
  margin: -12px -9px;
}
.ant-table.ant-table-bordered.ant-table-small > .ant-table-container > .ant-table-content > table > tbody > tr > td > .ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered.ant-table-small > .ant-table-container > .ant-table-body > table > tbody > tr > td > .ant-table-expanded-row-fixed {
  margin: -8px -9px;
}
.ant-table.ant-table-bordered > .ant-table-footer {
  border: 1px solid #f0f0f0;
  border-top: 0;
}
.ant-table-cell .ant-table-container:first-child {
  border-top: 0;
}
.ant-table-cell-scrollbar {
  box-shadow: 0 1px 0 1px #fafafa;
}
.ant-table-wrapper {
  clear: both;
  max-width: 100%;
}
.ant-table-wrapper::before {
  display: table;
  content: '';
}
.ant-table-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.ant-table-wrapper::before {
  display: table;
  content: '';
}
.ant-table-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.ant-table {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  font-size: 14px;
  background: #fff;
  border-radius: 20px;
}
.ant-table table {
  width: 100%;
  text-align: left;
  border-radius: 20px 20px 0 0;
  border-collapse: separate;
  border-spacing: 0;
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  position: relative;
  padding: 16px 16px;
  overflow-wrap: break-word;
}
.ant-table-cell-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all;
}
.ant-table-cell-ellipsis.ant-table-cell-fix-left-last,
.ant-table-cell-ellipsis.ant-table-cell-fix-right-first {
  overflow: visible;
}
.ant-table-cell-ellipsis.ant-table-cell-fix-left-last .ant-table-cell-content,
.ant-table-cell-ellipsis.ant-table-cell-fix-right-first .ant-table-cell-content {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ant-table-title {
  padding: 16px 16px;
}
.ant-table-footer {
  padding: 16px 16px;
  color: rgba(0, 0, 0, 0.85);
  background: #fafafa;
}
.ant-table-thead > tr > th {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  text-align: left;
  background: #fafafa;
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.3s ease;
}
.ant-table-thead > tr > th[colspan]:not([colspan='1']) {
  text-align: center;
}
.ant-table-thead > tr:not(:last-child) > th[colspan] {
  border-bottom: 0;
}
.ant-table-tbody > tr > td {
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.3s;
}
.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table {
  margin: -16px -16px -16px 33px;
}
.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td {
  border-bottom: 0;
}
.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:first-child,
.ant-table-tbody > tr > td > .ant-table-wrapper:only-child .ant-table-tbody > tr:last-child > td:last-child {
  border-radius: 0;
}
.ant-table-tbody > tr.ant-table-row:hover > td {
  background: #fafafa;
}
.ant-table-tbody > tr.ant-table-row-selected > td {
  background: #77857b;
  border-color: rgba(0, 0, 0, 0.03);
}
.ant-table-tbody > tr.ant-table-row-selected:hover > td {
  background: #728076;
}
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  border-bottom: 1px solid #f0f0f0;
}
.ant-table-pagination.ant-pagination {
  margin: 16px 0;
}
.ant-table-pagination {
  display: flex;
}
.ant-table-pagination-left {
  justify-content: flex-start;
}
.ant-table-pagination-center {
  justify-content: center;
}
.ant-table-pagination-right {
  justify-content: flex-end;
}
.ant-table-thead th.ant-table-column-has-sorters {
  padding: 0;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-table-thead th.ant-table-column-has-sorters:hover {
  background: #f2f2f2;
}
.ant-table-thead th.ant-table-column-has-sorters:hover .ant-table-filter-trigger-container {
  background: #f7f7f7;
}
.ant-table-thead th.ant-table-column-sort {
  background: #f5f5f5;
}
td.ant-table-column-sort {
  background: #fafafa;
}
.ant-table-column-sorters-with-tooltip {
  display: inline-block;
  width: 100%;
}
.ant-table-column-sorters {
  display: inline-flex;
  align-items: center;
  padding: 16px 16px;
}
.ant-table-column-sorter {
  margin-top: 0.15em;
  margin-bottom: -0.15em;
  margin-left: 8px;
  color: #bfbfbf;
}
.ant-table-column-sorter-full {
  margin-top: -0.2em;
  margin-bottom: 0;
}
.ant-table-column-sorter-inner {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}
.ant-table-column-sorter-up,
.ant-table-column-sorter-down {
  font-size: 11px;
}
.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
  color: #00441F;
}
.ant-table-column-sorter-up + .ant-table-column-sorter-down {
  margin-top: -0.3em;
}
.ant-table-filter-column {
  display: flex;
  align-items: center;
  margin: -16px -16px;
}
.ant-table-filter-column-title {
  flex: auto;
  padding: 16px 2.3em 16px 16px;
}
.ant-table-thead tr th.ant-table-column-has-sorters .ant-table-filter-column {
  margin: 0;
}
.ant-table-thead tr th.ant-table-column-has-sorters .ant-table-filter-column-title {
  padding: 0 2.3em 0 0;
}
.ant-table-filter-trigger-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex: none;
  align-items: stretch;
  align-self: stretch;
  cursor: pointer;
  transition: background-color 0.3s;
}
.ant-table-filter-trigger-container-open,
.ant-table-filter-trigger-container:hover,
.ant-table-thead th.ant-table-column-has-sorters:hover .ant-table-filter-trigger-container:hover {
  background: #e5e5e5;
}
.ant-table-filter-trigger {
  display: block;
  width: 2.3em;
  color: #bfbfbf;
  font-size: 12px;
  transition: color 0.3s;
}
.ant-table-filter-trigger .anticon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ant-table-filter-trigger-container-open .ant-table-filter-trigger,
.ant-table-filter-trigger:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-table-filter-trigger.active {
  color: #00441F;
}
.ant-table-filter-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  min-width: 120px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-table-filter-dropdown .ant-dropdown-menu {
  max-height: 264px;
  overflow-x: hidden;
  border: 0;
  box-shadow: none;
}
.ant-table-filter-dropdown-submenu > ul {
  max-height: calc(100vh - 130px);
  overflow-x: hidden;
  overflow-y: auto;
}
.ant-table-filter-dropdown .ant-checkbox-wrapper + span,
.ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span {
  padding-left: 8px;
}
.ant-table-filter-dropdown-btns {
  display: flex;
  justify-content: space-between;
  padding: 7px 8px 7px 3px;
  overflow: hidden;
  background-color: inherit;
  border-top: 1px solid #f0f0f0;
}
.ant-table .ant-table-selection-col {
  width: 32px;
}
table tr th.ant-table-selection-column,
table tr td.ant-table-selection-column {
  padding-right: 8px;
  padding-left: 8px;
  text-align: center;
}
table tr th.ant-table-selection-column .ant-radio-wrapper,
table tr td.ant-table-selection-column .ant-radio-wrapper {
  margin-right: 0;
}
.ant-table-selection {
  position: relative;
  display: inline-flex;
  flex-direction: column;
}
.ant-table-selection-extra {
  position: absolute;
  top: 0;
  z-index: 1;
  cursor: pointer;
  transition: all 0.3s;
  margin-inline-start: 100%;
  padding-inline-start: 4px;
}
.ant-table-selection-extra .anticon {
  color: #bfbfbf;
  font-size: 10px;
}
.ant-table-selection-extra .anticon:hover {
  color: #a6a6a6;
}
.ant-table-expand-icon-col {
  width: 48px;
}
.ant-table-row-expand-icon-cell {
  text-align: center;
}
.ant-table-row-indent {
  float: left;
  height: 1px;
}
.ant-table-row-expand-icon {
  color: #00441F;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s;
  position: relative;
  display: inline-flex;
  float: left;
  box-sizing: border-box;
  width: 17px;
  height: 17px;
  padding: 0;
  color: inherit;
  line-height: 17px;
  background: #fff;
  border: 1px solid #f0f0f0;
  border-radius: 20px;
  outline: none;
  transition: all 0.3s;
  user-select: none;
}
.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover {
  color: #0d522a;
}
.ant-table-row-expand-icon:active {
  color: #001f0f;
}
.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover,
.ant-table-row-expand-icon:active {
  border-color: currentColor;
}
.ant-table-row-expand-icon::before,
.ant-table-row-expand-icon::after {
  position: absolute;
  background: currentColor;
  transition: transform 0.3s ease-out;
  content: '';
}
.ant-table-row-expand-icon::before {
  top: 7px;
  right: 3px;
  left: 3px;
  height: 1px;
}
.ant-table-row-expand-icon::after {
  top: 3px;
  bottom: 3px;
  left: 7px;
  width: 1px;
  transform: rotate(90deg);
}
.ant-table-row-expand-icon-collapsed::before {
  transform: rotate(-180deg);
}
.ant-table-row-expand-icon-collapsed::after {
  transform: rotate(0deg);
}
.ant-table-row-expand-icon-spaced {
  background: transparent;
  border: 0;
  visibility: hidden;
}
.ant-table-row-expand-icon-spaced::before,
.ant-table-row-expand-icon-spaced::after {
  display: none;
  content: none;
}
.ant-table-row-indent + .ant-table-row-expand-icon {
  margin-top: 2.5005px;
  margin-right: 8px;
}
tr.ant-table-expanded-row > td,
tr.ant-table-expanded-row:hover > td {
  background: #fbfbfb;
}
tr.ant-table-expanded-row .ant-descriptions-view table {
  width: auto;
}
.ant-table .ant-table-expanded-row-fixed {
  position: relative;
  margin: -16px -16px;
  padding: 16px 16px;
}
.ant-table-tbody > tr.ant-table-placeholder {
  text-align: center;
}
.ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
  color: rgba(0, 0, 0, 0.25);
}
.ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background: #fff;
}
.ant-table-cell-fix-left,
.ant-table-cell-fix-right {
  position: -webkit-sticky !important;
  position: sticky !important;
  z-index: 2;
  background: #fff;
}
.ant-table-cell-fix-left-first::after,
.ant-table-cell-fix-left-last::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: -1px;
  width: 30px;
  transform: translateX(100%);
  transition: box-shadow 0.3s;
  content: '';
  pointer-events: none;
}
.ant-table-cell-fix-right-first::after,
.ant-table-cell-fix-right-last::after {
  position: absolute;
  top: 0;
  bottom: -1px;
  left: 0;
  width: 30px;
  transform: translateX(-100%);
  transition: box-shadow 0.3s;
  content: '';
  pointer-events: none;
}
.ant-table .ant-table-container::before,
.ant-table .ant-table-container::after {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  width: 30px;
  transition: box-shadow 0.3s;
  content: '';
  pointer-events: none;
}
.ant-table .ant-table-container::before {
  left: 0;
}
.ant-table .ant-table-container::after {
  right: 0;
}
.ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container {
  position: relative;
}
.ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container::before {
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.ant-table-ping-left .ant-table-cell-fix-left-first::after,
.ant-table-ping-left .ant-table-cell-fix-left-last::after {
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container {
  position: relative;
}
.ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container::after {
  box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.ant-table-ping-right .ant-table-cell-fix-right-first::after,
.ant-table-ping-right .ant-table-cell-fix-right-last::after {
  box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15);
}
.ant-table-sticky-header {
  position: sticky;
  z-index: calc(2 + 1);
}
.ant-table-sticky-scroll {
  position: sticky;
  bottom: 0;
  z-index: calc(2 + 1);
  display: flex;
  align-items: center;
  background: #ffffff;
  border-top: 1px solid #f0f0f0;
  opacity: 0.6;
}
.ant-table-sticky-scroll:hover {
  transform-origin: center bottom;
}
.ant-table-sticky-scroll-bar {
  height: 8px;
  background-color: rgba(0, 0, 0, 0.35);
  border-radius: 4px;
}
.ant-table-sticky-scroll-bar:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
.ant-table-sticky-scroll-bar-active {
  background-color: rgba(0, 0, 0, 0.8);
}
@media all and (-ms-high-contrast: none) {
  .ant-table-ping-left .ant-table-cell-fix-left-last::after {
    box-shadow: none !important;
  }
  .ant-table-ping-right .ant-table-cell-fix-right-first::after {
    box-shadow: none !important;
  }
}
.ant-table {
  /* title + table */
  /* table */
  /* table + footer */
}
.ant-table-title {
  border-radius: 20px 20px 0 0;
}
.ant-table-title + .ant-table-container {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.ant-table-title + .ant-table-container table > thead > tr:first-child th:first-child {
  border-radius: 0;
}
.ant-table-title + .ant-table-container table > thead > tr:first-child th:last-child {
  border-radius: 0;
}
.ant-table-container {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 20px;
}
.ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 20px;
}
.ant-table-footer {
  border-radius: 0 0 20px 20px;
}
.ant-table-wrapper-rtl {
  direction: rtl;
}
.ant-table-rtl {
  direction: rtl;
}
.ant-table-wrapper-rtl .ant-table table {
  text-align: right;
}
.ant-table-wrapper-rtl .ant-table-thead > tr > th[colspan]:not([colspan='1']) {
  text-align: center;
}
.ant-table-wrapper-rtl .ant-table-thead > tr > th {
  text-align: right;
}
.ant-table-tbody > tr .ant-table-wrapper:only-child .ant-table.ant-table-rtl {
  margin: -16px 33px -16px -16px;
}
.ant-table-wrapper.ant-table-wrapper-rtl .ant-table-pagination-left {
  justify-content: flex-end;
}
.ant-table-wrapper.ant-table-wrapper-rtl .ant-table-pagination-right {
  justify-content: flex-start;
}
.ant-table-wrapper-rtl .ant-table-column-sorter {
  margin-right: 8px;
  margin-left: 0;
}
.ant-table-wrapper-rtl .ant-table-filter-column-title {
  padding: 16px 16px 16px 2.3em;
}
.ant-table-rtl .ant-table-thead tr th.ant-table-column-has-sorters .ant-table-filter-column-title {
  padding: 0 0 0 2.3em;
}
.ant-table-wrapper-rtl .ant-table-filter-trigger-container {
  right: auto;
  left: 0;
}
.ant-dropdown-rtl .ant-table-filter-dropdown .ant-checkbox-wrapper + span,
.ant-dropdown-rtl .ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span,
.ant-dropdown-menu-submenu-rtl.ant-table-filter-dropdown .ant-checkbox-wrapper + span,
.ant-dropdown-menu-submenu-rtl.ant-table-filter-dropdown-submenu .ant-checkbox-wrapper + span {
  padding-right: 8px;
  padding-left: 0;
}
.ant-table-wrapper-rtl .ant-table-selection {
  text-align: center;
}
.ant-table-wrapper-rtl .ant-table-row-indent {
  float: right;
}
.ant-table-wrapper-rtl .ant-table-row-expand-icon {
  float: right;
}
.ant-table-wrapper-rtl .ant-table-row-indent + .ant-table-row-expand-icon {
  margin-right: 0;
  margin-left: 8px;
}
.ant-table-wrapper-rtl .ant-table-row-expand-icon::after {
  transform: rotate(-90deg);
}
.ant-table-wrapper-rtl .ant-table-row-expand-icon-collapsed::before {
  transform: rotate(180deg);
}
.ant-table-wrapper-rtl .ant-table-row-expand-icon-collapsed::after {
  transform: rotate(0deg);
}
.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
  padding: 8px 0;
  font-size: 14px;
}
.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
  padding: 16px 0;
  font-size: 16px;
}
.ant-tabs-card.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
  padding: 6px 16px;
}
.ant-tabs-card.ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
  padding: 7px 16px 6px;
}
.ant-tabs-rtl {
  direction: rtl;
}
.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab {
  margin: 0 0 0 32px;
}
.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab:last-of-type {
  margin-left: 0;
}
.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab .anticon {
  margin-right: 0;
  margin-left: 12px;
}
.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-remove {
  margin-right: 8px;
  margin-left: -4px;
}
.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-remove .anticon {
  margin: 0;
}
.ant-tabs-rtl.ant-tabs-left > .ant-tabs-nav {
  order: 1;
}
.ant-tabs-rtl.ant-tabs-left > .ant-tabs-content-holder {
  order: 0;
}
.ant-tabs-rtl.ant-tabs-right > .ant-tabs-nav {
  order: 0;
}
.ant-tabs-rtl.ant-tabs-right > .ant-tabs-content-holder {
  order: 1;
}
.ant-tabs-rtl.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type),
.ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type),
.ant-tabs-rtl.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type),
.ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type) {
  margin-right: 0;
  margin-left: 2px;
}
.ant-tabs-dropdown-rtl {
  direction: rtl;
}
.ant-tabs-dropdown-rtl .ant-tabs-dropdown-menu-item {
  text-align: right;
}
.ant-tabs-top,
.ant-tabs-bottom {
  flex-direction: column;
}
.ant-tabs-top > .ant-tabs-nav,
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-top > div > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  margin: 0 0 16px 0;
}
.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  position: absolute;
  right: 0;
  left: 0;
  border-bottom: 1px solid #f0f0f0;
  content: '';
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar {
  height: 2px;
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar-animated {
  transition: width 0.3s, left 0.3s, right 0.3s;
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  top: 0;
  bottom: 0;
  width: 30px;
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::before {
  left: 0;
  box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.08);
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  right: 0;
  box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.08);
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left::before {
  opacity: 1;
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after,
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right::after {
  opacity: 1;
}
.ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before {
  bottom: 0;
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar {
  bottom: 0;
}
.ant-tabs-bottom > .ant-tabs-nav,
.ant-tabs-bottom > div > .ant-tabs-nav {
  order: 1;
  margin-top: 16px;
  margin-bottom: 0;
}
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  top: 0;
}
.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-ink-bar {
  top: 0;
}
.ant-tabs-bottom > .ant-tabs-content-holder,
.ant-tabs-bottom > div > .ant-tabs-content-holder {
  order: 0;
}
.ant-tabs-left > .ant-tabs-nav,
.ant-tabs-right > .ant-tabs-nav,
.ant-tabs-left > div > .ant-tabs-nav,
.ant-tabs-right > div > .ant-tabs-nav {
  flex-direction: column;
  min-width: 50px;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab {
  margin: 0 0 16px 0;
  padding: 8px 24px;
  text-align: center;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab:last-of-type,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab:last-of-type,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab:last-of-type,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab:last-of-type {
  margin-bottom: 0;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: normal;
  text-shadow: 0 0 0.25px #001f0f;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  flex-direction: column;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  right: 0;
  left: 0;
  height: 30px;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::before {
  top: 0;
  box-shadow: inset 0 10px 8px -8px rgba(0, 0, 0, 0.08);
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  bottom: 0;
  box-shadow: inset 0 -10px 8px -8px rgba(0, 0, 0, 0.08);
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top::before {
  opacity: 1;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom::after {
  opacity: 1;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-ink-bar {
  width: 2px;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-ink-bar-animated {
  transition: height 0.3s, top 0.3s;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-left > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs-right > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-nav-operations {
  flex-direction: column;
}
.ant-tabs-left > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-ink-bar {
  right: 0;
}
.ant-tabs-left > .ant-tabs-content-holder,
.ant-tabs-left > div > .ant-tabs-content-holder {
  margin-left: -1px;
  border-left: 1px solid #f0f0f0;
}
.ant-tabs-left > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane,
.ant-tabs-left > div > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
  padding-left: 24px;
}
.ant-tabs-right > .ant-tabs-nav,
.ant-tabs-right > div > .ant-tabs-nav {
  order: 1;
}
.ant-tabs-right > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-ink-bar {
  left: 0;
}
.ant-tabs-right > .ant-tabs-content-holder,
.ant-tabs-right > div > .ant-tabs-content-holder {
  order: 0;
  margin-right: -1px;
  border-right: 1px solid #f0f0f0;
}
.ant-tabs-right > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane,
.ant-tabs-right > div > .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane {
  padding-right: 24px;
}
.ant-tabs-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  top: -9999px;
  left: -9999px;
  z-index: 1050;
  display: block;
}
.ant-tabs-dropdown-hidden {
  display: none;
}
.ant-tabs-dropdown-menu {
  max-height: 200px;
  margin: 0;
  padding: 4px 0;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: left;
  list-style-type: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 20px;
  outline: none;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-tabs-dropdown-menu-item {
  min-width: 120px;
  margin: 0;
  padding: 5px 12px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-tabs-dropdown-menu-item:hover {
  background: #f5f5f5;
}
.ant-tabs-dropdown-menu-item-disabled,
.ant-tabs-dropdown-menu-item-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  cursor: not-allowed;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  margin: 0;
  padding: 8px 16px;
  background: #fafafa;
  border: 1px solid #f0f0f0;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
  color: #00441F;
  background: #fff;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-ink-bar {
  visibility: hidden;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type),
.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type),
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type),
.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type) {
  margin-right: 2px;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 20px 20px 0 0;
}
.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-bottom-color: #fff;
}
.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 0 0 20px 20px;
}
.ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-bottom > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-top-color: #fff;
}
.ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type),
.ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type),
.ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type),
.ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab:not(:last-of-type) {
  margin-bottom: 2px;
}
.ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 20px 0 0 20px;
}
.ant-tabs-card.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-left > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-right-color: #fff;
}
.ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 0 20px 20px 0;
}
.ant-tabs-card.ant-tabs-right > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-right > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-left-color: #fff;
}
.ant-tabs {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: flex;
  overflow: hidden;
}
.ant-tabs > .ant-tabs-nav,
.ant-tabs > div > .ant-tabs-nav {
  position: relative;
  display: flex;
  flex: none;
  align-items: center;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap {
  position: relative;
  display: inline-block;
  display: flex;
  flex: auto;
  align-self: stretch;
  overflow: hidden;
  white-space: nowrap;
  transform: translate(0);
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap::before,
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap::after,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap::after {
  position: absolute;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
  content: '';
  pointer-events: none;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
  position: relative;
  display: flex;
  transition: transform 0.3s;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations {
  display: flex;
  align-self: stretch;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations-hidden,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-operations-hidden {
  position: absolute;
  visibility: hidden;
  pointer-events: none;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-more,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-more {
  position: relative;
  padding: 8px 16px;
  background: transparent;
  border: 0;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-more::after,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-more::after {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 5px;
  transform: translateY(100%);
  content: '';
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add {
  min-width: 40px;
  padding: 0 8px;
  background: #fafafa;
  border: 1px solid #f0f0f0;
  border-radius: 20px 20px 0 0;
  outline: none;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:hover,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:hover {
  color: #0d522a;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:active,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:active,
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-add:focus,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-add:focus {
  color: #001f0f;
}
.ant-tabs-extra-content {
  flex: none;
}
.ant-tabs-centered > .ant-tabs-nav .ant-tabs-nav-wrap:not([class*='ant-tabs-nav-wrap-ping']),
.ant-tabs-centered > div > .ant-tabs-nav .ant-tabs-nav-wrap:not([class*='ant-tabs-nav-wrap-ping']) {
  justify-content: center;
}
.ant-tabs-ink-bar {
  position: absolute;
  background: #00441F;
  pointer-events: none;
}
.ant-tabs-tab {
  position: relative;
  display: inline-flex;
  align-items: center;
  margin: 0 32px 0 0;
  padding: 12px 0;
  font-size: 14px;
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
}
.ant-tabs-tab:last-of-type {
  margin-right: 0;
  margin-left: 0;
}
.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
  color: #001f0f;
}
.ant-tabs-tab-btn {
  outline: none;
  transition: all 0.3s;
}
.ant-tabs-tab-remove {
  flex: none;
  margin-right: -4px;
  margin-left: 8px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-tabs-tab-remove:hover {
  color: rgba(0, 0, 0, 0.85);
}
.ant-tabs-tab:hover {
  color: #0d522a;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #00441F;
  font-weight: 500;
}
.ant-tabs-tab.ant-tabs-tab-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-btn:focus,
.ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-remove:focus,
.ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-btn:active,
.ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-remove:active {
  color: rgba(0, 0, 0, 0.25);
}
.ant-tabs-tab .ant-tabs-tab-remove .anticon {
  margin: 0;
}
.ant-tabs-tab .anticon {
  margin-right: 12px;
}
.ant-tabs-content {
  display: flex;
  width: 100%;
}
.ant-tabs-content-holder {
  flex: auto;
  min-width: 0;
  min-height: 0;
}
.ant-tabs-content-animated {
  transition: margin 0.3s;
}
.ant-tabs-tabpane {
  flex: none;
  width: 100%;
  outline: none;
}
.ant-tag {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
  height: auto;
  margin-right: 8px;
  padding: 0 7px;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 20px;
  opacity: 1;
  transition: all 0.3s;
}
.ant-tag:hover {
  opacity: 0.85;
}
.ant-tag,
.ant-tag a,
.ant-tag a:hover {
  color: rgba(0, 0, 0, 0.85);
}
.ant-tag > a:first-child:last-child {
  display: inline-block;
  margin: 0 -8px;
  padding: 0 8px;
}
.ant-tag-close-icon {
  margin-left: 3px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 10px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-tag-close-icon:hover {
  color: rgba(0, 0, 0, 0.85);
}
.ant-tag-has-color {
  border-color: transparent;
}
.ant-tag-has-color,
.ant-tag-has-color a,
.ant-tag-has-color a:hover,
.ant-tag-has-color .anticon-close,
.ant-tag-has-color .anticon-close:hover {
  color: #fff;
}
.ant-tag-checkable {
  background-color: transparent;
  border-color: transparent;
  cursor: pointer;
}
.ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
  color: #00441F;
}
.ant-tag-checkable:active,
.ant-tag-checkable-checked {
  color: #fff;
}
.ant-tag-checkable-checked {
  background-color: #00441F;
}
.ant-tag-checkable:active {
  background-color: #001f0f;
}
.ant-tag-hidden {
  display: none;
}
.ant-tag-pink {
  color: #eb2f96;
  background: #fff0f6;
  border-color: #ffadd2;
}
.ant-tag-pink-inverse {
  color: #fff;
  background: #eb2f96;
  border-color: #eb2f96;
}
.ant-tag-magenta {
  color: #eb2f96;
  background: #fff0f6;
  border-color: #ffadd2;
}
.ant-tag-magenta-inverse {
  color: #fff;
  background: #eb2f96;
  border-color: #eb2f96;
}
.ant-tag-red {
  color: #f5222d;
  background: #fff1f0;
  border-color: #ffa39e;
}
.ant-tag-red-inverse {
  color: #fff;
  background: #f5222d;
  border-color: #f5222d;
}
.ant-tag-volcano {
  color: #fa541c;
  background: #fff2e8;
  border-color: #ffbb96;
}
.ant-tag-volcano-inverse {
  color: #fff;
  background: #fa541c;
  border-color: #fa541c;
}
.ant-tag-orange {
  color: #fa8c16;
  background: #fff7e6;
  border-color: #ffd591;
}
.ant-tag-orange-inverse {
  color: #fff;
  background: #fa8c16;
  border-color: #fa8c16;
}
.ant-tag-yellow {
  color: #fadb14;
  background: #feffe6;
  border-color: #fffb8f;
}
.ant-tag-yellow-inverse {
  color: #fff;
  background: #fadb14;
  border-color: #fadb14;
}
.ant-tag-gold {
  color: #faad14;
  background: #fffbe6;
  border-color: #ffe58f;
}
.ant-tag-gold-inverse {
  color: #fff;
  background: #faad14;
  border-color: #faad14;
}
.ant-tag-cyan {
  color: #13c2c2;
  background: #e6fffb;
  border-color: #87e8de;
}
.ant-tag-cyan-inverse {
  color: #fff;
  background: #13c2c2;
  border-color: #13c2c2;
}
.ant-tag-lime {
  color: #a0d911;
  background: #fcffe6;
  border-color: #eaff8f;
}
.ant-tag-lime-inverse {
  color: #fff;
  background: #a0d911;
  border-color: #a0d911;
}
.ant-tag-green {
  color: #52c41a;
  background: #f6ffed;
  border-color: #b7eb8f;
}
.ant-tag-green-inverse {
  color: #fff;
  background: #52c41a;
  border-color: #52c41a;
}
.ant-tag-blue {
  color: #1890ff;
  background: #e6f7ff;
  border-color: #91d5ff;
}
.ant-tag-blue-inverse {
  color: #fff;
  background: #1890ff;
  border-color: #1890ff;
}
.ant-tag-geekblue {
  color: #2f54eb;
  background: #f0f5ff;
  border-color: #adc6ff;
}
.ant-tag-geekblue-inverse {
  color: #fff;
  background: #2f54eb;
  border-color: #2f54eb;
}
.ant-tag-purple {
  color: #722ed1;
  background: #f9f0ff;
  border-color: #d3adf7;
}
.ant-tag-purple-inverse {
  color: #fff;
  background: #722ed1;
  border-color: #722ed1;
}
.ant-tag-success {
  color: #52c41a;
  background: #f6ffed;
  border-color: #b7eb8f;
}
.ant-tag-processing {
  color: #1890ff;
  background: #e6f7ff;
  border-color: #91d5ff;
}
.ant-tag-error {
  color: #f5222d;
  background: #fff1f0;
  border-color: #ffa39e;
}
.ant-tag-warning {
  color: #fa8c16;
  background: #fff7e6;
  border-color: #ffd591;
}
.ant-tag > .anticon + span,
.ant-tag > span + .anticon {
  margin-left: 7px;
}
.ant-tag.ant-tag-rtl {
  margin-right: 0;
  margin-left: 8px;
  direction: rtl;
  text-align: right;
}
.ant-tag-rtl .ant-tag-close-icon {
  margin-right: 3px;
  margin-left: 0;
}
.ant-tag-rtl.ant-tag > .anticon + span,
.ant-tag-rtl.ant-tag > span + .anticon {
  margin-right: 7px;
  margin-left: 0;
}
.ant-timeline {
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  font-feature-settings: 'tnum';
  margin: 0;
  padding: 0;
  list-style: none;
}
.ant-timeline-item {
  position: relative;
  margin: 0;
  padding-bottom: 20px;
  font-size: 14px;
  list-style: none;
}
.ant-timeline-item-tail {
  position: absolute;
  top: 10px;
  left: 4px;
  height: calc(100% - 10px);
  border-left: 2px solid #f0f0f0;
}
.ant-timeline-item-pending .ant-timeline-item-head {
  font-size: 12px;
  background-color: transparent;
}
.ant-timeline-item-pending .ant-timeline-item-tail {
  display: none;
}
.ant-timeline-item-head {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #fff;
  border: 2px solid transparent;
  border-radius: 100px;
}
.ant-timeline-item-head-blue {
  color: #00441F;
  border-color: #00441F;
}
.ant-timeline-item-head-red {
  color: #DD4E4E;
  border-color: #DD4E4E;
}
.ant-timeline-item-head-green {
  color: #79be39;
  border-color: #79be39;
}
.ant-timeline-item-head-gray {
  color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-timeline-item-head-custom {
  position: absolute;
  top: 5.5px;
  left: 5px;
  width: auto;
  height: auto;
  margin-top: 0;
  padding: 3px 1px;
  line-height: 1;
  text-align: center;
  border: 0;
  border-radius: 0;
  transform: translate(-50%, -50%);
}
.ant-timeline-item-content {
  position: relative;
  top: -7.001px;
  margin: 0 0 0 26px;
  word-break: break-word;
}
.ant-timeline-item-last > .ant-timeline-item-tail {
  display: none;
}
.ant-timeline-item-last > .ant-timeline-item-content {
  min-height: 48px;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-tail,
.ant-timeline.ant-timeline-right .ant-timeline-item-tail,
.ant-timeline.ant-timeline-label .ant-timeline-item-tail,
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline.ant-timeline-right .ant-timeline-item-head,
.ant-timeline.ant-timeline-label .ant-timeline-item-head,
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
  left: 50%;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline.ant-timeline-right .ant-timeline-item-head,
.ant-timeline.ant-timeline-label .ant-timeline-item-head {
  margin-left: -4px;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
  margin-left: 1px;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content {
  left: calc(50% - 4px);
  width: calc(50% - 14px);
  text-align: left;
}
.ant-timeline.ant-timeline-alternate .ant-timeline-item-right .ant-timeline-item-content,
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content,
.ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-content {
  width: calc(50% - 12px);
  margin: 0;
  text-align: right;
}
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-tail,
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head,
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head-custom {
  left: calc(100% - 4px - 2px);
}
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content {
  width: calc(100% - 18px);
}
.ant-timeline.ant-timeline-pending .ant-timeline-item-last .ant-timeline-item-tail {
  display: block;
  height: calc(100% - 14px);
  border-left: 2px dotted #f0f0f0;
}
.ant-timeline.ant-timeline-reverse .ant-timeline-item-last .ant-timeline-item-tail {
  display: none;
}
.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-tail {
  top: 15px;
  display: block;
  height: calc(100% - 15px);
  border-left: 2px dotted #f0f0f0;
}
.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-content {
  min-height: 48px;
}
.ant-timeline.ant-timeline-label .ant-timeline-item-label {
  position: absolute;
  top: -7.001px;
  width: calc(50% - 12px);
  text-align: right;
}
.ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-label {
  left: calc(50% + 14px);
  width: calc(50% - 14px);
  text-align: left;
}
.ant-timeline-rtl {
  direction: rtl;
}
.ant-timeline-rtl .ant-timeline-item-tail {
  right: 4px;
  left: auto;
  border-right: 2px solid #f0f0f0;
  border-left: none;
}
.ant-timeline-rtl .ant-timeline-item-head-custom {
  right: 5px;
  left: auto;
  transform: translate(50%, -50%);
}
.ant-timeline-rtl .ant-timeline-item-content {
  margin: 0 18px 0 0;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-tail,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-tail,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-tail,
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
  right: 50%;
  left: auto;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head {
  margin-right: -4px;
  margin-left: 0;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom {
  margin-right: 1px;
  margin-left: 0;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content {
  right: calc(50% - 4px);
  left: auto;
  text-align: right;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-right .ant-timeline-item-content,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-content {
  text-align: left;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-tail,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head-custom {
  right: 0;
  left: auto;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content {
  width: 100%;
  margin-right: 18px;
  text-align: right;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-pending .ant-timeline-item-last .ant-timeline-item-tail {
  border-right: 2px dotted #f0f0f0;
  border-left: none;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-tail {
  border-right: 2px dotted #f0f0f0;
  border-left: none;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-label {
  text-align: left;
}
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-label {
  right: calc(50% + 14px);
  text-align: right;
}
.ant-tooltip {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: absolute;
  z-index: 1070;
  display: block;
  max-width: 250px;
  visibility: visible;
}
.ant-tooltip-hidden {
  display: none;
}
.ant-tooltip-placement-top,
.ant-tooltip-placement-topLeft,
.ant-tooltip-placement-topRight {
  padding-bottom: 8px;
}
.ant-tooltip-placement-right,
.ant-tooltip-placement-rightTop,
.ant-tooltip-placement-rightBottom {
  padding-left: 8px;
}
.ant-tooltip-placement-bottom,
.ant-tooltip-placement-bottomLeft,
.ant-tooltip-placement-bottomRight {
  padding-top: 8px;
}
.ant-tooltip-placement-left,
.ant-tooltip-placement-leftTop,
.ant-tooltip-placement-leftBottom {
  padding-right: 8px;
}
.ant-tooltip-inner {
  min-width: 30px;
  min-height: 32px;
  padding: 6px 8px;
  color: #fff;
  text-align: left;
  text-decoration: none;
  word-wrap: break-word;
  background-color: rgba(0, 0, 0, 0.75);
  border-radius: 20px;
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-tooltip-arrow {
  position: absolute;
  display: block;
  width: 13.07106781px;
  height: 13.07106781px;
  overflow: hidden;
  background: transparent;
  pointer-events: none;
}
.ant-tooltip-arrow-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 5px;
  height: 5px;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.75);
  content: '';
  pointer-events: auto;
}
.ant-tooltip-placement-top .ant-tooltip-arrow,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow,
.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  bottom: -5.07106781px;
}
.ant-tooltip-placement-top .ant-tooltip-arrow-content,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow-content,
.ant-tooltip-placement-topRight .ant-tooltip-arrow-content {
  box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateY(-6.53553391px) rotate(45deg);
}
.ant-tooltip-placement-top .ant-tooltip-arrow {
  left: 50%;
  transform: translateX(-50%);
}
.ant-tooltip-placement-topLeft .ant-tooltip-arrow {
  left: 13px;
}
.ant-tooltip-placement-topRight .ant-tooltip-arrow {
  right: 13px;
}
.ant-tooltip-placement-right .ant-tooltip-arrow,
.ant-tooltip-placement-rightTop .ant-tooltip-arrow,
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  left: -5.07106781px;
}
.ant-tooltip-placement-right .ant-tooltip-arrow-content,
.ant-tooltip-placement-rightTop .ant-tooltip-arrow-content,
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow-content {
  box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
  transform: translateX(6.53553391px) rotate(45deg);
}
.ant-tooltip-placement-right .ant-tooltip-arrow {
  top: 50%;
  transform: translateY(-50%);
}
.ant-tooltip-placement-rightTop .ant-tooltip-arrow {
  top: 5px;
}
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
  bottom: 5px;
}
.ant-tooltip-placement-left .ant-tooltip-arrow,
.ant-tooltip-placement-leftTop .ant-tooltip-arrow,
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  right: -5.07106781px;
}
.ant-tooltip-placement-left .ant-tooltip-arrow-content,
.ant-tooltip-placement-leftTop .ant-tooltip-arrow-content,
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow-content {
  box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
  transform: translateX(-6.53553391px) rotate(45deg);
}
.ant-tooltip-placement-left .ant-tooltip-arrow {
  top: 50%;
  transform: translateY(-50%);
}
.ant-tooltip-placement-leftTop .ant-tooltip-arrow {
  top: 5px;
}
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
  bottom: 5px;
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  top: -5.07106781px;
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow-content,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow-content,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow-content {
  box-shadow: -3px -3px 7px rgba(0, 0, 0, 0.07);
  transform: translateY(6.53553391px) rotate(45deg);
}
.ant-tooltip-placement-bottom .ant-tooltip-arrow {
  left: 50%;
  transform: translateX(-50%);
}
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow {
  left: 13px;
}
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  right: 13px;
}
.ant-tooltip-pink .ant-tooltip-inner {
  background-color: #eb2f96;
}
.ant-tooltip-pink .ant-tooltip-arrow-content {
  background-color: #eb2f96;
}
.ant-tooltip-magenta .ant-tooltip-inner {
  background-color: #eb2f96;
}
.ant-tooltip-magenta .ant-tooltip-arrow-content {
  background-color: #eb2f96;
}
.ant-tooltip-red .ant-tooltip-inner {
  background-color: #f5222d;
}
.ant-tooltip-red .ant-tooltip-arrow-content {
  background-color: #f5222d;
}
.ant-tooltip-volcano .ant-tooltip-inner {
  background-color: #fa541c;
}
.ant-tooltip-volcano .ant-tooltip-arrow-content {
  background-color: #fa541c;
}
.ant-tooltip-orange .ant-tooltip-inner {
  background-color: #fa8c16;
}
.ant-tooltip-orange .ant-tooltip-arrow-content {
  background-color: #fa8c16;
}
.ant-tooltip-yellow .ant-tooltip-inner {
  background-color: #fadb14;
}
.ant-tooltip-yellow .ant-tooltip-arrow-content {
  background-color: #fadb14;
}
.ant-tooltip-gold .ant-tooltip-inner {
  background-color: #faad14;
}
.ant-tooltip-gold .ant-tooltip-arrow-content {
  background-color: #faad14;
}
.ant-tooltip-cyan .ant-tooltip-inner {
  background-color: #13c2c2;
}
.ant-tooltip-cyan .ant-tooltip-arrow-content {
  background-color: #13c2c2;
}
.ant-tooltip-lime .ant-tooltip-inner {
  background-color: #a0d911;
}
.ant-tooltip-lime .ant-tooltip-arrow-content {
  background-color: #a0d911;
}
.ant-tooltip-green .ant-tooltip-inner {
  background-color: #52c41a;
}
.ant-tooltip-green .ant-tooltip-arrow-content {
  background-color: #52c41a;
}
.ant-tooltip-blue .ant-tooltip-inner {
  background-color: #1890ff;
}
.ant-tooltip-blue .ant-tooltip-arrow-content {
  background-color: #1890ff;
}
.ant-tooltip-geekblue .ant-tooltip-inner {
  background-color: #2f54eb;
}
.ant-tooltip-geekblue .ant-tooltip-arrow-content {
  background-color: #2f54eb;
}
.ant-tooltip-purple .ant-tooltip-inner {
  background-color: #722ed1;
}
.ant-tooltip-purple .ant-tooltip-arrow-content {
  background-color: #722ed1;
}
.ant-tooltip-rtl {
  direction: rtl;
}
.ant-tooltip-rtl .ant-tooltip-inner {
  text-align: right;
}
.ant-transfer-customize-list .ant-transfer-list {
  flex: 1 1 50%;
  width: auto;
  height: auto;
  min-height: 200px;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small {
  border: 0;
  border-radius: 0;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th {
  background: #fafafa;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small > .ant-table-content .ant-table-row:last-child td {
  border-bottom: 1px solid #f0f0f0;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-small .ant-table-body {
  margin: 0;
}
.ant-transfer-customize-list .ant-table-wrapper .ant-table-pagination.ant-pagination {
  margin: 16px 0 4px;
}
.ant-transfer-customize-list .ant-input[disabled] {
  background-color: transparent;
}
.ant-transfer {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  display: flex;
  align-items: stretch;
}
.ant-transfer-disabled .ant-transfer-list {
  background: #f5f5f5;
}
.ant-transfer-list {
  display: flex;
  flex-direction: column;
  width: 180px;
  height: 200px;
  border: 1px solid #d9d9d9;
  border-radius: 20px;
}
.ant-transfer-list-with-pagination {
  width: 250px;
  height: auto;
}
.ant-transfer-list-search {
  padding-right: 24px;
  padding-left: 8px;
}
.ant-transfer-list-search-action {
  position: absolute;
  top: 12px;
  right: 12px;
  bottom: 12px;
  width: 28px;
  color: rgba(0, 0, 0, 0.25);
  line-height: 32px;
  text-align: center;
}
.ant-transfer-list-search-action .anticon {
  color: rgba(0, 0, 0, 0.25);
  transition: all 0.3s;
}
.ant-transfer-list-search-action .anticon:hover {
  color: rgba(0, 0, 0, 0.45);
}
span.ant-transfer-list-search-action {
  pointer-events: none;
}
.ant-transfer-list-header {
  display: flex;
  flex: none;
  align-items: center;
  height: 40px;
  padding: 8px 12px 9px;
  color: rgba(0, 0, 0, 0.85);
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 20px 20px 0 0;
}
.ant-transfer-list-header > *:not(:last-child) {
  margin-right: 4px;
}
.ant-transfer-list-header > * {
  flex: none;
}
.ant-transfer-list-header-title {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-align: right;
  text-overflow: ellipsis;
}
.ant-transfer-list-header-dropdown {
  font-size: 10px;
  transform: translateY(10%);
  cursor: pointer;
}
.ant-transfer-list-header-dropdown[disabled] {
  cursor: not-allowed;
}
.ant-transfer-list-body {
  display: flex;
  flex: auto;
  flex-direction: column;
  overflow: hidden;
  font-size: 14px;
}
.ant-transfer-list-body-search-wrapper {
  position: relative;
  flex: none;
  padding: 12px;
}
.ant-transfer-list-content {
  flex: auto;
  margin: 0;
  padding: 0;
  overflow: auto;
  list-style: none;
}
.ant-transfer-list-content-item {
  display: flex;
  align-items: center;
  min-height: 32px;
  padding: 6px 12px;
  overflow: hidden;
  line-height: 20px;
  transition: all 0.3s;
}
.ant-transfer-list-content-item > *:not(:last-child) {
  margin-right: 8px;
}
.ant-transfer-list-content-item > * {
  flex: none;
}
.ant-transfer-list-content-item-text {
  flex: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-transfer-list-content-item-remove {
  color: #00441F;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  position: relative;
  color: #d9d9d9;
}
.ant-transfer-list-content-item-remove:focus,
.ant-transfer-list-content-item-remove:hover {
  color: #0d522a;
}
.ant-transfer-list-content-item-remove:active {
  color: #001f0f;
}
.ant-transfer-list-content-item-remove::after {
  position: absolute;
  top: -6px;
  right: -50%;
  bottom: -6px;
  left: -50%;
  content: '';
}
.ant-transfer-list-content-item-remove:hover {
  color: #0d522a;
}
.ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
  background-color: #f5f5f5;
  cursor: pointer;
}
.ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled).ant-transfer-list-content-item-checked:hover {
  background-color: #728076;
}
.ant-transfer-list-content-show-remove .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
  background: transparent;
  cursor: default;
}
.ant-transfer-list-content-item-checked {
  background-color: #77857b;
}
.ant-transfer-list-content-item-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-transfer-list-pagination {
  padding: 8px 0;
  text-align: right;
  border-top: 1px solid #f0f0f0;
}
.ant-transfer-list-body-not-found {
  flex: none;
  width: 100%;
  margin: auto 0;
  color: rgba(0, 0, 0, 0.25);
  text-align: center;
}
.ant-transfer-list-footer {
  border-top: 1px solid #f0f0f0;
}
.ant-transfer-operation {
  display: flex;
  flex: none;
  flex-direction: column;
  align-self: center;
  margin: 0 8px;
  overflow: hidden;
  vertical-align: middle;
}
.ant-transfer-operation .ant-btn {
  display: block;
}
.ant-transfer-operation .ant-btn:first-child {
  margin-bottom: 4px;
}
.ant-transfer-operation .ant-btn .anticon {
  font-size: 12px;
}
.ant-transfer .ant-empty-image {
  max-height: -2px;
}
.ant-transfer-rtl {
  direction: rtl;
}
.ant-transfer-rtl .ant-transfer-list-search {
  padding-right: 8px;
  padding-left: 24px;
}
.ant-transfer-rtl .ant-transfer-list-search-action {
  right: auto;
  left: 12px;
}
.ant-transfer-rtl .ant-transfer-list-header > *:not(:last-child) {
  margin-right: 0;
  margin-left: 4px;
}
.ant-transfer-rtl .ant-transfer-list-header {
  right: 0;
  left: auto;
}
.ant-transfer-rtl .ant-transfer-list-header-title {
  text-align: left;
}
.ant-transfer-rtl .ant-transfer-list-content-item > *:not(:last-child) {
  margin-right: 0;
  margin-left: 8px;
}
.ant-transfer-rtl .ant-transfer-list-pagination {
  text-align: left;
}
.ant-transfer-rtl .ant-transfer-list-footer {
  right: 0;
  left: auto;
}
.ant-tree-treenode-leaf-last .ant-tree-switcher-leaf-line::before {
  top: auto !important;
  bottom: auto !important;
  height: 14px !important;
}
.ant-tree.ant-tree-directory .ant-tree-treenode {
  position: relative;
}
.ant-tree.ant-tree-directory .ant-tree-treenode::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 4px;
  left: 0;
  transition: background-color 0.3s;
  content: '';
  pointer-events: none;
}
.ant-tree.ant-tree-directory .ant-tree-treenode:hover::before {
  background: #f5f5f5;
}
.ant-tree.ant-tree-directory .ant-tree-treenode > * {
  z-index: 1;
}
.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-switcher {
  transition: color 0.3s;
}
.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper {
  border-radius: 0;
  user-select: none;
}
.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper:hover {
  background: transparent;
}
.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper.ant-tree-node-selected {
  color: #fff;
  background: transparent;
}
.ant-tree.ant-tree-directory .ant-tree-treenode-selected:hover::before,
.ant-tree.ant-tree-directory .ant-tree-treenode-selected::before {
  background: #00441F;
}
.ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-switcher {
  color: #fff;
}
.ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-node-content-wrapper {
  color: #fff;
  background: transparent;
}
.ant-tree-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  top: -0.09em;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  outline: none;
  cursor: pointer;
}
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox-input:focus + .ant-tree-checkbox-inner {
  border-color: #00441F;
}
.ant-tree-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #00441F;
  border-radius: 20px;
  visibility: hidden;
  animation: antCheckboxEffect 0.36s ease-in-out;
  animation-fill-mode: backwards;
  content: '';
}
.ant-tree-checkbox:hover::after,
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox::after {
  visibility: visible;
}
.ant-tree-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  direction: ltr;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 20px;
  border-collapse: separate;
  transition: all 0.3s;
}
.ant-tree-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 22%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
.ant-tree-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
  background-color: #00441F;
  border-color: #00441F;
}
.ant-tree-checkbox-disabled {
  cursor: not-allowed;
}
.ant-tree-checkbox-disabled.ant-tree-checkbox-checked .ant-tree-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  animation-name: none;
}
.ant-tree-checkbox-disabled .ant-tree-checkbox-input {
  cursor: not-allowed;
}
.ant-tree-checkbox-disabled .ant-tree-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}
.ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  animation-name: none;
}
.ant-tree-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-tree-checkbox-disabled:hover::after,
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-disabled::after {
  visibility: hidden;
}
.ant-tree-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
  line-height: unset;
  cursor: pointer;
}
.ant-tree-checkbox-wrapper.ant-tree-checkbox-wrapper-disabled {
  cursor: not-allowed;
}
.ant-tree-checkbox-wrapper + .ant-tree-checkbox-wrapper {
  margin-left: 8px;
}
.ant-tree-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-tree-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
}
.ant-tree-checkbox-group-item {
  display: inline-block;
  margin-right: 8px;
}
.ant-tree-checkbox-group-item:last-child {
  margin-right: 0;
}
.ant-tree-checkbox-group-item + .ant-tree-checkbox-group-item {
  margin-left: 0;
}
.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}
.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #00441F;
  border: 0;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
.ant-tree-checkbox-indeterminate.ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-tree-checkbox-rtl {
  direction: rtl;
}
.ant-tree-checkbox-group-rtl .ant-tree-checkbox-group-item {
  margin-right: 0;
  margin-left: 8px;
}
.ant-tree-checkbox-group-rtl .ant-tree-checkbox-group-item:last-child {
  margin-left: 0 !important;
}
.ant-tree-checkbox-group-rtl .ant-tree-checkbox-group-item + .ant-tree-checkbox-group-item {
  margin-left: 8px;
}
.ant-tree {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  background: #fff;
  border-radius: 20px;
  transition: background-color 0.3s;
}
.ant-tree-focused:not(:hover):not(.ant-tree-active-focused) {
  background: #77857b;
}
.ant-tree-list-holder-inner {
  align-items: flex-start;
}
.ant-tree.ant-tree-block-node .ant-tree-list-holder-inner {
  align-items: stretch;
}
.ant-tree.ant-tree-block-node .ant-tree-list-holder-inner .ant-tree-node-content-wrapper {
  flex: auto;
}
.ant-tree .ant-tree-treenode {
  display: flex;
  align-items: flex-start;
  padding: 0 0 4px 0;
  outline: none;
}
.ant-tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper:hover {
  background: transparent;
}
.ant-tree .ant-tree-treenode-active .ant-tree-node-content-wrapper {
  background: #f5f5f5;
}
.ant-tree .ant-tree-treenode.filter-node .ant-tree-title {
  color: #d4380d;
  font-weight: 500;
}
.ant-tree-indent {
  align-self: stretch;
  white-space: nowrap;
  user-select: none;
}
.ant-tree-indent-unit {
  display: inline-block;
  width: 24px;
}
.ant-tree-switcher {
  position: relative;
  flex: none;
  align-self: stretch;
  width: 24px;
  margin: 0;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  user-select: none;
}
.ant-tree-switcher .ant-tree-switcher-icon,
.ant-tree-switcher .ant-select-tree-switcher-icon {
  display: inline-block;
  font-size: 10px;
  vertical-align: baseline;
}
.ant-tree-switcher .ant-tree-switcher-icon svg,
.ant-tree-switcher .ant-select-tree-switcher-icon svg {
  transition: transform 0.3s;
}
.ant-tree-switcher-noop {
  cursor: default;
}
.ant-tree-switcher_close .ant-tree-switcher-icon svg {
  transform: rotate(-90deg);
}
.ant-tree-switcher-loading-icon {
  color: #00441F;
}
.ant-tree-switcher-leaf-line {
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 100%;
  height: 100%;
}
.ant-tree-switcher-leaf-line::before {
  position: absolute;
  top: 0;
  bottom: -4px;
  margin-left: -1px;
  border-left: 1px solid #f5f5f5;
  content: ' ';
}
.ant-tree-switcher-leaf-line::after {
  position: absolute;
  width: 10px;
  height: 14px;
  margin-left: -1px;
  border-bottom: 1px solid #f5f5f5;
  content: ' ';
}
.ant-tree-checkbox {
  top: initial;
  margin: 4px 8px 0 0;
}
.ant-tree .ant-tree-node-content-wrapper {
  position: relative;
  z-index: auto;
  min-height: 24px;
  margin: 0;
  padding: 0 4px;
  color: inherit;
  line-height: 24px;
  background: transparent;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s, border 0s, line-height 0s, box-shadow 0s;
}
.ant-tree .ant-tree-node-content-wrapper:hover {
  background-color: #f5f5f5;
}
.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: #4d785a;
}
.ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  vertical-align: top;
}
.ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle:empty {
  display: none;
}
.ant-tree-unselectable .ant-tree-node-content-wrapper:hover {
  background-color: transparent;
}
.ant-tree-node-content-wrapper[draggable='true'] {
  line-height: 24px;
  user-select: none;
}
.ant-tree-node-content-wrapper[draggable='true'] .ant-tree-drop-indicator {
  position: absolute;
  z-index: 1;
  height: 2px;
  background-color: #00441F;
  border-radius: 1px;
  pointer-events: none;
}
.ant-tree-node-content-wrapper[draggable='true'] .ant-tree-drop-indicator::after {
  position: absolute;
  top: -3px;
  left: -6px;
  width: 8px;
  height: 8px;
  background-color: transparent;
  border: 2px solid #00441F;
  border-radius: 50%;
  content: '';
}
.ant-tree .ant-tree-treenode.drop-container > [draggable] {
  box-shadow: 0 0 0 2px #00441F;
}
.ant-tree-show-line .ant-tree-indent-unit {
  position: relative;
  height: 100%;
}
.ant-tree-show-line .ant-tree-indent-unit::before {
  position: absolute;
  top: 0;
  right: 12px;
  bottom: -4px;
  border-right: 1px solid #d9d9d9;
  content: '';
}
.ant-tree-show-line .ant-tree-indent-unit-end::before {
  display: none;
}
.ant-tree-show-line .ant-tree-switcher {
  background: #fff;
}
.ant-tree-show-line .ant-tree-switcher-line-icon {
  vertical-align: -0.225em;
}
.ant-tree-rtl {
  direction: rtl;
}
.ant-tree-rtl .ant-tree-node-content-wrapper[draggable='true'] .ant-tree-drop-indicator::after {
  right: -6px;
  left: unset;
}
.ant-tree .ant-tree-treenode-rtl {
  direction: rtl;
}
.ant-tree-rtl .ant-tree-switcher_close .ant-tree-switcher-icon svg {
  transform: rotate(90deg);
}
.ant-tree-rtl.ant-tree-show-line .ant-tree-indent-unit::before {
  right: auto;
  left: -13px;
  border-right: none;
  border-left: 1px solid #d9d9d9;
}
.ant-tree-rtl.ant-tree-checkbox {
  margin: 4px 0 0 8px;
}
.ant-tree-select-dropdown-rtl .ant-select-tree-checkbox {
  margin: 4px 0 0 8px;
}
.ant-select-tree-checkbox {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  position: relative;
  top: -0.09em;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  outline: none;
  cursor: pointer;
}
.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox:hover .ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox-input:focus + .ant-select-tree-checkbox-inner {
  border-color: #00441F;
}
.ant-select-tree-checkbox-checked::after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 1px solid #00441F;
  border-radius: 20px;
  visibility: hidden;
  animation: antCheckboxEffect 0.36s ease-in-out;
  animation-fill-mode: backwards;
  content: '';
}
.ant-select-tree-checkbox:hover::after,
.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox::after {
  visibility: visible;
}
.ant-select-tree-checkbox-inner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 16px;
  height: 16px;
  direction: ltr;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 20px;
  border-collapse: separate;
  transition: all 0.3s;
}
.ant-select-tree-checkbox-inner::after {
  position: absolute;
  top: 50%;
  left: 22%;
  display: table;
  width: 5.71428571px;
  height: 9.14285714px;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(0) translate(-50%, -50%);
  opacity: 0;
  transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
  content: ' ';
}
.ant-select-tree-checkbox-input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  transform: rotate(45deg) scale(1) translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
  content: ' ';
}
.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
  background-color: #00441F;
  border-color: #00441F;
}
.ant-select-tree-checkbox-disabled {
  cursor: not-allowed;
}
.ant-select-tree-checkbox-disabled.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner::after {
  border-color: rgba(0, 0, 0, 0.25);
  animation-name: none;
}
.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-input {
  cursor: not-allowed;
}
.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner {
  background-color: #f5f5f5;
  border-color: #d9d9d9 !important;
}
.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner::after {
  border-color: #f5f5f5;
  border-collapse: separate;
  animation-name: none;
}
.ant-select-tree-checkbox-disabled + span {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-select-tree-checkbox-disabled:hover::after,
.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-disabled::after {
  visibility: hidden;
}
.ant-select-tree-checkbox-wrapper {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
  line-height: unset;
  cursor: pointer;
}
.ant-select-tree-checkbox-wrapper.ant-select-tree-checkbox-wrapper-disabled {
  cursor: not-allowed;
}
.ant-select-tree-checkbox-wrapper + .ant-select-tree-checkbox-wrapper {
  margin-left: 8px;
}
.ant-select-tree-checkbox + span {
  padding-right: 8px;
  padding-left: 8px;
}
.ant-select-tree-checkbox-group {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  display: inline-block;
}
.ant-select-tree-checkbox-group-item {
  display: inline-block;
  margin-right: 8px;
}
.ant-select-tree-checkbox-group-item:last-child {
  margin-right: 0;
}
.ant-select-tree-checkbox-group-item + .ant-select-tree-checkbox-group-item {
  margin-left: 0;
}
.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner {
  background-color: #fff;
  border-color: #d9d9d9;
}
.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner::after {
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  background-color: #00441F;
  border: 0;
  transform: translate(-50%, -50%) scale(1);
  opacity: 1;
  content: ' ';
}
.ant-select-tree-checkbox-indeterminate.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner::after {
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-select-tree-checkbox-rtl {
  direction: rtl;
}
.ant-select-tree-checkbox-group-rtl .ant-select-tree-checkbox-group-item {
  margin-right: 0;
  margin-left: 8px;
}
.ant-select-tree-checkbox-group-rtl .ant-select-tree-checkbox-group-item:last-child {
  margin-left: 0 !important;
}
.ant-select-tree-checkbox-group-rtl .ant-select-tree-checkbox-group-item + .ant-select-tree-checkbox-group-item {
  margin-left: 8px;
}
.ant-tree-select-dropdown {
  padding: 8px 4px 0;
}
.ant-tree-select-dropdown-rtl {
  direction: rtl;
}
.ant-tree-select-dropdown .ant-select-tree {
  border-radius: 0;
}
.ant-tree-select-dropdown .ant-select-tree-list-holder-inner {
  align-items: stretch;
}
.ant-tree-select-dropdown .ant-select-tree-list-holder-inner .ant-select-tree-treenode {
  padding-bottom: 8px;
}
.ant-tree-select-dropdown .ant-select-tree-list-holder-inner .ant-select-tree-treenode .ant-select-tree-node-content-wrapper {
  flex: auto;
}
.ant-select-tree {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  background: #fff;
  border-radius: 20px;
  transition: background-color 0.3s;
}
.ant-select-tree-focused:not(:hover):not(.ant-select-tree-active-focused) {
  background: #77857b;
}
.ant-select-tree-list-holder-inner {
  align-items: flex-start;
}
.ant-select-tree.ant-select-tree-block-node .ant-select-tree-list-holder-inner {
  align-items: stretch;
}
.ant-select-tree.ant-select-tree-block-node .ant-select-tree-list-holder-inner .ant-select-tree-node-content-wrapper {
  flex: auto;
}
.ant-select-tree .ant-select-tree-treenode {
  display: flex;
  align-items: flex-start;
  padding: 0 0 4px 0;
  outline: none;
}
.ant-select-tree .ant-select-tree-treenode-disabled .ant-select-tree-node-content-wrapper {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
.ant-select-tree .ant-select-tree-treenode-disabled .ant-select-tree-node-content-wrapper:hover {
  background: transparent;
}
.ant-select-tree .ant-select-tree-treenode-active .ant-select-tree-node-content-wrapper {
  background: #f5f5f5;
}
.ant-select-tree .ant-select-tree-treenode.filter-node .ant-select-tree-title {
  color: #d4380d;
  font-weight: 500;
}
.ant-select-tree-indent {
  align-self: stretch;
  white-space: nowrap;
  user-select: none;
}
.ant-select-tree-indent-unit {
  display: inline-block;
  width: 24px;
}
.ant-select-tree-switcher {
  position: relative;
  flex: none;
  align-self: stretch;
  width: 24px;
  margin: 0;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  user-select: none;
}
.ant-select-tree-switcher .ant-tree-switcher-icon,
.ant-select-tree-switcher .ant-select-tree-switcher-icon {
  display: inline-block;
  font-size: 10px;
  vertical-align: baseline;
}
.ant-select-tree-switcher .ant-tree-switcher-icon svg,
.ant-select-tree-switcher .ant-select-tree-switcher-icon svg {
  transition: transform 0.3s;
}
.ant-select-tree-switcher-noop {
  cursor: default;
}
.ant-select-tree-switcher_close .ant-select-tree-switcher-icon svg {
  transform: rotate(-90deg);
}
.ant-select-tree-switcher-loading-icon {
  color: #00441F;
}
.ant-select-tree-switcher-leaf-line {
  position: relative;
  z-index: 1;
  display: inline-block;
  width: 100%;
  height: 100%;
}
.ant-select-tree-switcher-leaf-line::before {
  position: absolute;
  top: 0;
  bottom: -4px;
  margin-left: -1px;
  border-left: 1px solid #f5f5f5;
  content: ' ';
}
.ant-select-tree-switcher-leaf-line::after {
  position: absolute;
  width: 10px;
  height: 14px;
  margin-left: -1px;
  border-bottom: 1px solid #f5f5f5;
  content: ' ';
}
.ant-select-tree-checkbox {
  top: initial;
  margin: 4px 8px 0 0;
}
.ant-select-tree .ant-select-tree-node-content-wrapper {
  position: relative;
  z-index: auto;
  min-height: 24px;
  margin: 0;
  padding: 0 4px;
  color: inherit;
  line-height: 24px;
  background: transparent;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.3s, border 0s, line-height 0s, box-shadow 0s;
}
.ant-select-tree .ant-select-tree-node-content-wrapper:hover {
  background-color: #f5f5f5;
}
.ant-select-tree .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
  background-color: #4d785a;
}
.ant-select-tree .ant-select-tree-node-content-wrapper .ant-select-tree-iconEle {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  vertical-align: top;
}
.ant-select-tree .ant-select-tree-node-content-wrapper .ant-select-tree-iconEle:empty {
  display: none;
}
.ant-select-tree-unselectable .ant-select-tree-node-content-wrapper:hover {
  background-color: transparent;
}
.ant-select-tree-node-content-wrapper[draggable='true'] {
  line-height: 24px;
  user-select: none;
}
.ant-select-tree-node-content-wrapper[draggable='true'] .ant-tree-drop-indicator {
  position: absolute;
  z-index: 1;
  height: 2px;
  background-color: #00441F;
  border-radius: 1px;
  pointer-events: none;
}
.ant-select-tree-node-content-wrapper[draggable='true'] .ant-tree-drop-indicator::after {
  position: absolute;
  top: -3px;
  left: -6px;
  width: 8px;
  height: 8px;
  background-color: transparent;
  border: 2px solid #00441F;
  border-radius: 50%;
  content: '';
}
.ant-select-tree .ant-select-tree-treenode.drop-container > [draggable] {
  box-shadow: 0 0 0 2px #00441F;
}
.ant-select-tree-show-line .ant-select-tree-indent-unit {
  position: relative;
  height: 100%;
}
.ant-select-tree-show-line .ant-select-tree-indent-unit::before {
  position: absolute;
  top: 0;
  right: 12px;
  bottom: -4px;
  border-right: 1px solid #d9d9d9;
  content: '';
}
.ant-select-tree-show-line .ant-select-tree-indent-unit-end::before {
  display: none;
}
.ant-select-tree-show-line .ant-select-tree-switcher {
  background: #fff;
}
.ant-select-tree-show-line .ant-select-tree-switcher-line-icon {
  vertical-align: -0.225em;
}
.ant-tree-select-dropdown-rtl .ant-select-tree .ant-select-tree-switcher_close .ant-select-tree-switcher-icon svg {
  transform: rotate(90deg);
}
.ant-tree-select-dropdown-rtl .ant-select-tree .ant-select-tree-switcher-loading-icon {
  transform: scaleY(-1);
}
.ant-typography {
  color: rgba(0, 0, 0, 0.85);
  overflow-wrap: break-word;
}
.ant-typography.ant-typography-secondary {
  color: rgba(0, 0, 0, 0.45);
}
.ant-typography.ant-typography-success {
  color: #79be39;
}
.ant-typography.ant-typography-warning {
  color: #FFC107;
}
.ant-typography.ant-typography-danger {
  color: #DD4E4E;
}
a.ant-typography.ant-typography-danger:active,
a.ant-typography.ant-typography-danger:focus,
a.ant-typography.ant-typography-danger:hover {
  color: #eb7b78;
}
.ant-typography.ant-typography-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
  user-select: none;
}
div.ant-typography,
.ant-typography p {
  margin-bottom: 1em;
}
h1.ant-typography,
.ant-typography h1 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 38px;
  line-height: 1.23;
}
h2.ant-typography,
.ant-typography h2 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 30px;
  line-height: 1.35;
}
h3.ant-typography,
.ant-typography h3 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 24px;
  line-height: 1.35;
}
h4.ant-typography,
.ant-typography h4 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 20px;
  line-height: 1.4;
}
h5.ant-typography,
.ant-typography h5 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
}
.ant-typography + h1.ant-typography,
.ant-typography + h2.ant-typography,
.ant-typography + h3.ant-typography,
.ant-typography + h4.ant-typography,
.ant-typography + h5.ant-typography {
  margin-top: 1.2em;
}
.ant-typography div + h1,
.ant-typography ul + h1,
.ant-typography li + h1,
.ant-typography p + h1,
.ant-typography h1 + h1,
.ant-typography h2 + h1,
.ant-typography h3 + h1,
.ant-typography h4 + h1,
.ant-typography h5 + h1,
.ant-typography div + h2,
.ant-typography ul + h2,
.ant-typography li + h2,
.ant-typography p + h2,
.ant-typography h1 + h2,
.ant-typography h2 + h2,
.ant-typography h3 + h2,
.ant-typography h4 + h2,
.ant-typography h5 + h2,
.ant-typography div + h3,
.ant-typography ul + h3,
.ant-typography li + h3,
.ant-typography p + h3,
.ant-typography h1 + h3,
.ant-typography h2 + h3,
.ant-typography h3 + h3,
.ant-typography h4 + h3,
.ant-typography h5 + h3,
.ant-typography div + h4,
.ant-typography ul + h4,
.ant-typography li + h4,
.ant-typography p + h4,
.ant-typography h1 + h4,
.ant-typography h2 + h4,
.ant-typography h3 + h4,
.ant-typography h4 + h4,
.ant-typography h5 + h4,
.ant-typography div + h5,
.ant-typography ul + h5,
.ant-typography li + h5,
.ant-typography p + h5,
.ant-typography h1 + h5,
.ant-typography h2 + h5,
.ant-typography h3 + h5,
.ant-typography h4 + h5,
.ant-typography h5 + h5 {
  margin-top: 1.2em;
}
a.ant-typography-ellipsis,
span.ant-typography-ellipsis {
  display: inline-block;
}
a.ant-typography,
.ant-typography a {
  color: #00441F;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  text-decoration: none;
}
a.ant-typography:focus,
.ant-typography a:focus,
a.ant-typography:hover,
.ant-typography a:hover {
  color: #0d522a;
}
a.ant-typography:active,
.ant-typography a:active {
  color: #001f0f;
}
a.ant-typography:active,
.ant-typography a:active,
a.ant-typography:hover,
.ant-typography a:hover {
  text-decoration: none;
}
a.ant-typography[disabled],
.ant-typography a[disabled],
a.ant-typography.ant-typography-disabled,
.ant-typography a.ant-typography-disabled {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
a.ant-typography[disabled]:active,
.ant-typography a[disabled]:active,
a.ant-typography.ant-typography-disabled:active,
.ant-typography a.ant-typography-disabled:active,
a.ant-typography[disabled]:hover,
.ant-typography a[disabled]:hover,
a.ant-typography.ant-typography-disabled:hover,
.ant-typography a.ant-typography-disabled:hover {
  color: rgba(0, 0, 0, 0.25);
}
a.ant-typography[disabled]:active,
.ant-typography a[disabled]:active,
a.ant-typography.ant-typography-disabled:active,
.ant-typography a.ant-typography-disabled:active {
  pointer-events: none;
}
.ant-typography code {
  margin: 0 0.2em;
  padding: 0.2em 0.4em 0.1em;
  font-size: 85%;
  background: rgba(150, 150, 150, 0.1);
  border: 1px solid rgba(100, 100, 100, 0.2);
  border-radius: 3px;
}
.ant-typography kbd {
  margin: 0 0.2em;
  padding: 0.15em 0.4em 0.1em;
  font-size: 90%;
  background: rgba(150, 150, 150, 0.06);
  border: 1px solid rgba(100, 100, 100, 0.2);
  border-bottom-width: 2px;
  border-radius: 3px;
}
.ant-typography mark {
  padding: 0;
  background-color: #ffe58f;
}
.ant-typography u,
.ant-typography ins {
  text-decoration: underline;
  text-decoration-skip-ink: auto;
}
.ant-typography s,
.ant-typography del {
  text-decoration: line-through;
}
.ant-typography strong {
  font-weight: 600;
}
.ant-typography-expand,
.ant-typography-edit,
.ant-typography-copy {
  color: #00441F;
  text-decoration: none;
  outline: none;
  cursor: pointer;
  transition: color 0.3s;
  margin-left: 4px;
}
.ant-typography-expand:focus,
.ant-typography-edit:focus,
.ant-typography-copy:focus,
.ant-typography-expand:hover,
.ant-typography-edit:hover,
.ant-typography-copy:hover {
  color: #0d522a;
}
.ant-typography-expand:active,
.ant-typography-edit:active,
.ant-typography-copy:active {
  color: #001f0f;
}
.ant-typography-copy-success,
.ant-typography-copy-success:hover,
.ant-typography-copy-success:focus {
  color: #79be39;
}
.ant-typography-edit-content {
  position: relative;
}
div.ant-typography-edit-content {
  left: -12px;
  margin-top: -5px;
  margin-bottom: calc(1em - 4px - 1px);
}
.ant-typography-edit-content-confirm {
  position: absolute;
  right: 10px;
  bottom: 8px;
  color: rgba(0, 0, 0, 0.45);
  pointer-events: none;
}
.ant-typography-edit-content textarea {
  -moz-transition: none;
}
.ant-typography ul,
.ant-typography ol {
  margin: 0 0 1em 0;
  padding: 0;
}
.ant-typography ul li,
.ant-typography ol li {
  margin: 0 0 0 20px;
  padding: 0 0 0 4px;
}
.ant-typography ul {
  list-style-type: circle;
}
.ant-typography ul ul {
  list-style-type: disc;
}
.ant-typography ol {
  list-style-type: decimal;
}
.ant-typography pre,
.ant-typography blockquote {
  margin: 1em 0;
}
.ant-typography pre {
  padding: 0.4em 0.6em;
  white-space: pre-wrap;
  word-wrap: break-word;
  background: rgba(150, 150, 150, 0.1);
  border: 1px solid rgba(100, 100, 100, 0.2);
  border-radius: 3px;
}
.ant-typography pre code {
  display: inline;
  margin: 0;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
  background: transparent;
  border: 0;
}
.ant-typography blockquote {
  padding: 0 0 0 0.6em;
  border-left: 4px solid rgba(100, 100, 100, 0.2);
  opacity: 0.85;
}
.ant-typography-ellipsis-single-line {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
a.ant-typography-ellipsis-single-line,
span.ant-typography-ellipsis-single-line {
  vertical-align: bottom;
}
.ant-typography-ellipsis-multiple-line {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /*! autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.ant-typography-rtl {
  direction: rtl;
}
.ant-typography-rtl .ant-typography-expand,
.ant-typography-rtl .ant-typography-edit,
.ant-typography-rtl .ant-typography-copy {
  margin-right: 4px;
  margin-left: 0;
}
.ant-typography-rtl .ant-typography-expand {
  float: left;
}
div.ant-typography-edit-content.ant-typography-rtl {
  right: -12px;
  left: auto;
}
.ant-typography-rtl .ant-typography-edit-content-confirm {
  right: auto;
  left: 10px;
}
.ant-typography-rtl.ant-typography ul li,
.ant-typography-rtl.ant-typography ol li {
  margin: 0 20px 0 0;
  padding: 0 4px 0 0;
}
.ant-upload {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  outline: 0;
}
.ant-upload p {
  margin: 0;
}
.ant-upload-btn {
  display: block;
  width: 100%;
  outline: none;
}
.ant-upload input[type='file'] {
  cursor: pointer;
}
.ant-upload.ant-upload-select {
  display: inline-block;
}
.ant-upload.ant-upload-disabled {
  cursor: not-allowed;
}
.ant-upload.ant-upload-select-picture-card {
  width: 104px;
  height: 104px;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 20px;
  cursor: pointer;
  transition: border-color 0.3s;
}
.ant-upload.ant-upload-select-picture-card > .ant-upload {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}
.ant-upload.ant-upload-select-picture-card:hover {
  border-color: #00441F;
}
.ant-upload-disabled.ant-upload.ant-upload-select-picture-card:hover {
  border-color: #d9d9d9;
}
.ant-upload.ant-upload-drag {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  background: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 20px;
  cursor: pointer;
  transition: border-color 0.3s;
}
.ant-upload.ant-upload-drag .ant-upload {
  padding: 16px 0;
}
.ant-upload.ant-upload-drag.ant-upload-drag-hover:not(.ant-upload-disabled) {
  border-color: #001f0f;
}
.ant-upload.ant-upload-drag.ant-upload-disabled {
  cursor: not-allowed;
}
.ant-upload.ant-upload-drag .ant-upload-btn {
  display: table;
  height: 100%;
}
.ant-upload.ant-upload-drag .ant-upload-drag-container {
  display: table-cell;
  vertical-align: middle;
}
.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
  border-color: #0d522a;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon {
  margin-bottom: 20px;
}
.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
  color: #0d522a;
  font-size: 48px;
}
.ant-upload.ant-upload-drag p.ant-upload-text {
  margin: 0 0 4px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
}
.ant-upload.ant-upload-drag p.ant-upload-hint {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-upload.ant-upload-drag .anticon-plus {
  color: rgba(0, 0, 0, 0.25);
  font-size: 30px;
  transition: all 0.3s;
}
.ant-upload.ant-upload-drag .anticon-plus:hover {
  color: rgba(0, 0, 0, 0.45);
}
.ant-upload.ant-upload-drag:hover .anticon-plus {
  color: rgba(0, 0, 0, 0.45);
}
.ant-upload-picture-card-wrapper {
  display: inline-block;
  width: 100%;
}
.ant-upload-picture-card-wrapper::before {
  display: table;
  content: '';
}
.ant-upload-picture-card-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.ant-upload-picture-card-wrapper::before {
  display: table;
  content: '';
}
.ant-upload-picture-card-wrapper::after {
  display: table;
  clear: both;
  content: '';
}
.ant-upload-list {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum';
  line-height: 1.5715;
}
.ant-upload-list::before {
  display: table;
  content: '';
}
.ant-upload-list::after {
  display: table;
  clear: both;
  content: '';
}
.ant-upload-list::before {
  display: table;
  content: '';
}
.ant-upload-list::after {
  display: table;
  clear: both;
  content: '';
}
.ant-upload-list-item {
  position: relative;
  height: 22.001px;
  margin-top: 8px;
  font-size: 14px;
}
.ant-upload-list-item-name {
  display: inline-block;
  width: 100%;
  padding-left: 22px;
  overflow: hidden;
  line-height: 1.5715;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-upload-list-item-card-actions {
  position: absolute;
  right: 0;
}
.ant-upload-list-item-card-actions-btn {
  opacity: 0;
}
.ant-upload-list-item-card-actions-btn.ant-btn-sm {
  height: 20px;
  line-height: 1;
}
.ant-upload-list-item-card-actions.picture {
  top: 22px;
  line-height: 0;
}
.ant-upload-list-item-card-actions-btn:focus,
.ant-upload-list-item-card-actions.picture .ant-upload-list-item-card-actions-btn {
  opacity: 1;
}
.ant-upload-list-item-card-actions .anticon {
  color: rgba(0, 0, 0, 0.45);
}
.ant-upload-list-item-info {
  height: 100%;
  padding: 0 4px;
  transition: background-color 0.3s;
}
.ant-upload-list-item-info > span {
  display: block;
  width: 100%;
  height: 100%;
}
.ant-upload-list-item-info .anticon-loading .anticon,
.ant-upload-list-item-info .ant-upload-text-icon .anticon {
  position: absolute;
  top: 5px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.ant-upload-list-item .anticon-close {
  position: absolute;
  top: 6px;
  right: 4px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 10px;
  line-height: 0;
  cursor: pointer;
  opacity: 0;
  transition: all 0.3s;
}
.ant-upload-list-item .anticon-close:hover {
  color: rgba(0, 0, 0, 0.85);
}
.ant-upload-list-item:hover .ant-upload-list-item-info {
  background-color: #f5f5f5;
}
.ant-upload-list-item:hover .anticon-close {
  opacity: 1;
}
.ant-upload-list-item:hover .ant-upload-list-item-card-actions-btn {
  opacity: 1;
}
.ant-upload-list-item-error,
.ant-upload-list-item-error .ant-upload-text-icon > .anticon,
.ant-upload-list-item-error .ant-upload-list-item-name {
  color: #DD4E4E;
}
.ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon {
  color: #DD4E4E;
}
.ant-upload-list-item-error .ant-upload-list-item-card-actions-btn {
  opacity: 1;
}
.ant-upload-list-item-progress {
  position: absolute;
  bottom: -12px;
  width: 100%;
  padding-left: 26px;
  font-size: 14px;
  line-height: 0;
}
.ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
  position: relative;
  height: 66px;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 20px;
}
.ant-upload-list-picture .ant-upload-list-item:hover,
.ant-upload-list-picture-card .ant-upload-list-item:hover {
  background: transparent;
}
.ant-upload-list-picture .ant-upload-list-item-error,
.ant-upload-list-picture-card .ant-upload-list-item-error {
  border-color: #DD4E4E;
}
.ant-upload-list-picture .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item-info {
  padding: 0;
}
.ant-upload-list-picture .ant-upload-list-item:hover .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info {
  background: transparent;
}
.ant-upload-list-picture .ant-upload-list-item-uploading,
.ant-upload-list-picture-card .ant-upload-list-item-uploading {
  border-style: dashed;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  width: 48px;
  height: 48px;
  line-height: 54px;
  text-align: center;
  opacity: 0.8;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail .anticon,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail .anticon {
  font-size: 26px;
}
.ant-upload-list-picture .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#e6f7ff'],
.ant-upload-list-picture-card .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#e6f7ff'] {
  fill: #fff2f0;
}
.ant-upload-list-picture .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#1890ff'],
.ant-upload-list-picture-card .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#1890ff'] {
  fill: #DD4E4E;
}
.ant-upload-list-picture .ant-upload-list-item-icon,
.ant-upload-list-picture-card .ant-upload-list-item-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 26px;
  transform: translate(-50%, -50%);
}
.ant-upload-list-picture .ant-upload-list-item-icon .anticon,
.ant-upload-list-picture-card .ant-upload-list-item-icon .anticon {
  font-size: 26px;
}
.ant-upload-list-picture .ant-upload-list-item-image,
.ant-upload-list-picture-card .ant-upload-list-item-image {
  max-width: 100%;
}
.ant-upload-list-picture .ant-upload-list-item-thumbnail img,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  display: block;
  width: 48px;
  height: 48px;
  overflow: hidden;
}
.ant-upload-list-picture .ant-upload-list-item-name,
.ant-upload-list-picture-card .ant-upload-list-item-name {
  display: inline-block;
  box-sizing: border-box;
  max-width: 100%;
  margin: 0 0 0 8px;
  padding-right: 8px;
  padding-left: 48px;
  overflow: hidden;
  line-height: 44px;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.3s;
}
.ant-upload-list-picture .ant-upload-list-item-uploading .ant-upload-list-item-name,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-name {
  line-height: 28px;
}
.ant-upload-list-picture .ant-upload-list-item-progress,
.ant-upload-list-picture-card .ant-upload-list-item-progress {
  bottom: 14px;
  width: calc(100% - 24px);
  margin-top: 0;
  padding-left: 56px;
}
.ant-upload-list-picture .anticon-close,
.ant-upload-list-picture-card .anticon-close {
  position: absolute;
  top: 8px;
  right: 8px;
  line-height: 1;
  opacity: 1;
}
.ant-upload-list-picture-card-container {
  display: inline-block;
  width: 104px;
  height: 104px;
  margin: 0 8px 8px 0;
  vertical-align: top;
}
.ant-upload-list-picture-card.ant-upload-list::after {
  display: none;
}
.ant-upload-list-picture-card .ant-upload-list-item {
  height: 100%;
  margin: 0;
}
.ant-upload-list-picture-card .ant-upload-list-item-info {
  position: relative;
  height: 100%;
  overflow: hidden;
}
.ant-upload-list-picture-card .ant-upload-list-item-info::before {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: all 0.3s;
  content: ' ';
}
.ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info::before {
  opacity: 1;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  white-space: nowrap;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all 0.3s;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete {
  z-index: 10;
  width: 16px;
  margin: 0 4px;
  color: rgba(255, 255, 255, 0.85);
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s;
}
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye:hover,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download:hover,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete:hover {
  color: #fff;
}
.ant-upload-list-picture-card .ant-upload-list-item-info:hover + .ant-upload-list-item-actions,
.ant-upload-list-picture-card .ant-upload-list-item-actions:hover {
  opacity: 1;
}
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  position: static;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.ant-upload-list-picture-card .ant-upload-list-item-name {
  display: none;
  margin: 8px 0 0;
  padding: 0;
  line-height: 1.5715;
  text-align: center;
}
.ant-upload-list-picture-card .ant-upload-list-item-file + .ant-upload-list-item-name {
  position: absolute;
  bottom: 10px;
  display: block;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading.ant-upload-list-item {
  background-color: #fafafa;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info {
  height: auto;
}
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info::before,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-eye,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-delete {
  display: none;
}
.ant-upload-list-picture-card .ant-upload-list-item-progress {
  bottom: 32px;
  width: calc(100% - 14px);
  padding-left: 0;
}
.ant-upload-list-text-container,
.ant-upload-list-picture-container {
  transition: opacity 0.3s, height 0.3s;
}
.ant-upload-list-text-container::before,
.ant-upload-list-picture-container::before {
  display: table;
  width: 0;
  height: 0;
  content: '';
}
.ant-upload-list-text-container .ant-upload-span,
.ant-upload-list-picture-container .ant-upload-span {
  display: block;
  flex: auto;
}
.ant-upload-list-text .ant-upload-span,
.ant-upload-list-picture .ant-upload-span {
  display: flex;
  align-items: center;
}
.ant-upload-list-text .ant-upload-span > *,
.ant-upload-list-picture .ant-upload-span > * {
  flex: none;
}
.ant-upload-list-text .ant-upload-list-item-name,
.ant-upload-list-picture .ant-upload-list-item-name {
  flex: auto;
  padding: 0 8px;
}
.ant-upload-list-text .ant-upload-list-item-card-actions,
.ant-upload-list-picture .ant-upload-list-item-card-actions {
  position: static;
}
.ant-upload-list-text .ant-upload-text-icon .anticon {
  position: static;
}
.ant-upload-list .ant-upload-animate-inline-appear,
.ant-upload-list .ant-upload-animate-inline-enter,
.ant-upload-list .ant-upload-animate-inline-leave {
  animation-duration: 0.3s;
  animation-fill-mode: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.ant-upload-list .ant-upload-animate-inline-appear,
.ant-upload-list .ant-upload-animate-inline-enter {
  animation-name: uploadAnimateInlineIn;
}
.ant-upload-list .ant-upload-animate-inline-leave {
  animation-name: uploadAnimateInlineOut;
}
@keyframes uploadAnimateInlineIn {
  from {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
@keyframes uploadAnimateInlineOut {
  to {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
}
.ant-upload-rtl {
  direction: rtl;
}
.ant-upload-rtl.ant-upload.ant-upload-select-picture-card {
  margin-right: auto;
  margin-left: 8px;
}
.ant-upload-list-rtl {
  direction: rtl;
}
.ant-upload-list-rtl .ant-upload-list-item-list-type-text:hover .ant-upload-list-item-name-icon-count-1 {
  padding-right: 22px;
  padding-left: 14px;
}
.ant-upload-list-rtl .ant-upload-list-item-list-type-text:hover .ant-upload-list-item-name-icon-count-2 {
  padding-right: 22px;
  padding-left: 28px;
}
.ant-upload-list-rtl .ant-upload-list-item-name {
  padding-right: 22px;
  padding-left: 0;
}
.ant-upload-list-rtl .ant-upload-list-item-name-icon-count-1 {
  padding-left: 14px;
}
.ant-upload-list-rtl .ant-upload-list-item-card-actions {
  right: auto;
  left: 0;
}
.ant-upload-list-rtl .ant-upload-list-item-card-actions .anticon {
  padding-right: 0;
  padding-left: 5px;
}
.ant-upload-list-rtl .ant-upload-list-item-info {
  padding: 0 4px 0 12px;
}
.ant-upload-list-rtl .ant-upload-list-item .anticon-close {
  right: auto;
  left: 4px;
}
.ant-upload-list-rtl .ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon {
  padding-right: 0;
  padding-left: 5px;
}
.ant-upload-list-rtl .ant-upload-list-item-progress {
  padding-right: 26px;
  padding-left: 0;
}
.ant-upload-list-picture .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item-info {
  padding: 0;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-thumbnail,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  right: 8px;
  left: auto;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-icon,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-icon {
  right: 50%;
  left: auto;
  transform: translate(50%, -50%);
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name {
  margin: 0 8px 0 0;
  padding-right: 48px;
  padding-left: 8px;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name-icon-count-1,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-1 {
  padding-right: 48px;
  padding-left: 18px;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name-icon-count-2,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-2 {
  padding-right: 48px;
  padding-left: 36px;
}
.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-progress,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-progress {
  padding-right: 0;
  padding-left: 0;
}
.ant-upload-list-rtl.ant-upload-list-picture .anticon-close,
.ant-upload-list-rtl.ant-upload-list-picture-card .anticon-close {
  right: auto;
  left: 8px;
}
.ant-upload-list-rtl .ant-upload-list-picture-card-container {
  margin: 0 0 8px 8px;
}
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-actions {
  right: 50%;
  left: auto;
  transform: translate(50%, -50%);
}
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-file + .ant-upload-list-item-name {
  margin: 8px 0 0;
  padding: 0;
}
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v29/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq6R8aX8.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v29/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Uw-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v29/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvr6Ew-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v29/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCs16Ew-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v29/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Ew-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v29/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtZ6Ew-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v29/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCu170w-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v29/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCuM70w-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v29/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvr70w-.ttf) format('truetype');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/montserrat/v29/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvC70w-.ttf) format('truetype');
}
/* ----------------------------------------------
 * Generated by Animista on 2021-1-20 17:44:15
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation rotate-scale-up
 * ----------------------------------------
 */
.rotate-scale-up {
  -webkit-animation: rotate-scale-up 0.65s linear infinite both;
  animation: rotate-scale-up 0.65s linear infinite both;
}
@-webkit-keyframes rotate-scale-up {
  0% {
    -webkit-transform: scale(1) rotateZ(0);
    transform: scale(1) rotateZ(0);
  }
  50% {
    -webkit-transform: scale(2) rotateZ(180deg);
    transform: scale(2) rotateZ(180deg);
  }
  100% {
    -webkit-transform: scale(1) rotateZ(360deg);
    transform: scale(1) rotateZ(360deg);
  }
}
@keyframes rotate-scale-up {
  0% {
    -webkit-transform: scale(1) rotateZ(0);
    transform: scale(1) rotateZ(0);
  }
  50% {
    -webkit-transform: scale(2) rotateZ(180deg);
    transform: scale(2) rotateZ(180deg);
  }
  100% {
    -webkit-transform: scale(1) rotateZ(360deg);
    transform: scale(1) rotateZ(360deg);
  }
}
@keyframes reviewButtonAnimation {
  0% {
    right: -150px;
    opacity: 0.7;
  }
  100% {
    right: 20px;
    opacity: 1;
  }
}
@keyframes reviewButtonAnimationInvisible {
  0% {
    right: 0px;
    opacity: 1;
  }
  100% {
    right: -150px;
    opacity: 0;
  }
}
.scale-in-hor-left {
  -webkit-animation: scale-in-hor-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: scale-in-hor-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@-webkit-keyframes scale-in-hor-left {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes scale-in-hor-left {
  0% {
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
    transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes textAnimation {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.slit-in-vertical {
  -webkit-animation: slit-in-vertical 0.3s linear both;
  animation: slit-in-vertical 0.3s linear both;
}
@-webkit-keyframes slit-in-vertical {
  0% {
    -webkit-transform: translateZ(-800px) rotateY(90deg);
    transform: translateZ(-800px) rotateY(90deg);
    opacity: 0;
  }
  54% {
    -webkit-transform: translateZ(-160px) rotateY(87deg);
    transform: translateZ(-160px) rotateY(87deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(0) rotateY(0);
    transform: translateZ(0) rotateY(0);
  }
}
@keyframes slit-in-vertical {
  0% {
    -webkit-transform: translateZ(-800px) rotateY(90deg);
    transform: translateZ(-800px) rotateY(90deg);
    opacity: 0;
  }
  54% {
    -webkit-transform: translateZ(-160px) rotateY(87deg);
    transform: translateZ(-160px) rotateY(87deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(0) rotateY(0);
    transform: translateZ(0) rotateY(0);
  }
}
.jello-horizontal {
  -webkit-animation: jello-horizontal 0.9s both;
  animation: jello-horizontal 0.9s both;
}
@-webkit-keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
.scale-up-hor-right {
  -webkit-animation: scale-up-hor-right 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: scale-up-hor-right 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
@-webkit-keyframes scale-up-hor-right {
  0% {
    -webkit-transform: scaleX(0.4);
    transform: scaleX(0.4);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }
}
@keyframes scale-up-hor-right {
  0% {
    -webkit-transform: scaleX(0.4);
    transform: scaleX(0.4);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }
}
.slide-in-right {
  -webkit-animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
.form-section-title {
  text-transform: uppercase;
  position: relative;
  display: inline-block;
}
.form-section-title--primary {
  font-size: 1.1rem;
  color: #00441F !important;
}
.form-section-title--primary::after {
  content: '';
  width: 45px;
  height: 3px;
  border-radius: 2px;
  position: absolute;
  bottom: -5px;
  left: 0;
  background-color: #E0E5CF;
}
.form-section-title--secondary {
  font-size: 0.7rem;
  text-transform: none;
}
/* border */
.b-0 {
  border: none;
}
.half-opacity {
  opacity: 0.5;
}
/* gap */
.gap-2 {
  gap: 10px;
}
.overflow-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  display: block;
}
/* margin */
.m-0 {
  margin: 0px !important;
}
.m-1 {
  margin: 5px !important;
}
.m-2 {
  margin: 10px !important;
}
.m-3 {
  margin: 15px !important;
}
.m-4 {
  margin: 20px !important;
}
.m-5 {
  margin: 25px !important;
}
.m-6 {
  margin: 30px !important;
}
.mt-0 {
  margin-top: 0px !important;
}
.mt-1 {
  margin-top: 5px !important;
}
.mt-2 {
  margin-top: 10px !important;
}
.mt-3 {
  margin-top: 15px !important;
}
.mt-4 {
  margin-top: 20px !important;
}
.mt-5 {
  margin-top: 25px !important;
}
.mt-6 {
  margin-top: 30px !important;
}
.mt-7 {
  margin-top: 35px !important;
}
.mt-8 {
  margin-top: 40px !important;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.mb-1 {
  margin-bottom: 5px !important;
}
.mb-2 {
  margin-bottom: 10px !important;
}
.mb-3 {
  margin-bottom: 15px !important;
}
.mb-4 {
  margin-bottom: 20px !important;
}
.mb-5 {
  margin-bottom: 25px !important;
}
.mb-6 {
  margin-bottom: 30px !important;
}
.mb-7 {
  margin-bottom: 35px !important;
}
.mb-8 {
  margin-bottom: 40px !important;
}
.ml-0 {
  margin-left: 0px !important;
}
.ml-1 {
  margin-left: 5px !important;
}
.ml-2 {
  margin-left: 10px !important;
}
.ml-3 {
  margin-left: 15px !important;
}
.ml-4 {
  margin-left: 20px !important;
}
.ml-5 {
  margin-left: 25px !important;
}
.ml-6 {
  margin-left: 30px !important;
}
.mr-0 {
  margin-right: 0px !important;
}
.mr-1 {
  margin-right: 5px !important;
}
.mr-2 {
  margin-right: 10px !important;
}
.mr-3 {
  margin-right: 15px !important;
}
.mr-4 {
  margin-right: 20px !important;
}
.mr-5 {
  margin-right: 25px !important;
}
.mr-6 {
  margin-right: 30px !important;
}
.mx-0 {
  margin: 0 0px !important;
}
.mx-1 {
  margin: 0 5px !important;
}
.mx-2 {
  margin: 0 10px !important;
}
.mx-3 {
  margin: 0 15px !important;
}
.mx-4 {
  margin: 0 20px !important;
}
.mx-5 {
  margin: 0 25px !important;
}
.mx-6 {
  margin: 0 30px !important;
}
.my-0 {
  margin: 0px 0 !important;
}
.my-1 {
  margin: 5px 0 !important;
}
.my-2 {
  margin: 10px 0 !important;
}
.my-3 {
  margin: 15px 0 !important;
}
.my-4 {
  margin: 20px 0 !important;
}
.my-5 {
  margin: 25px 0 !important;
}
.my-6 {
  margin: 30px 0 !important;
}
.m-auto {
  margin: auto !important;
}
@media screen and (max-width: 768px) {
  .m-md-0 {
    margin: 0px !important;
  }
  .m-md-1 {
    margin: 5px !important;
  }
  .m-md-2 {
    margin: 10px !important;
  }
  .m-md-3 {
    margin: 15px !important;
  }
  .m-md-4 {
    margin: 20px !important;
  }
  .m-md-5 {
    margin: 25px !important;
  }
  .m-md-6 {
    margin: 30px !important;
  }
  .mt-md-0 {
    margin-top: 0px !important;
  }
  .mt-md-1 {
    margin-top: 5px !important;
  }
  .mt-md-2 {
    margin-top: 10px !important;
  }
  .mt-md-3 {
    margin-top: 15px !important;
  }
  .mt-md-4 {
    margin-top: 20px !important;
  }
  .mt-md-5 {
    margin-top: 25px !important;
  }
  .mt-md-6 {
    margin-top: 30px !important;
  }
  .mb-md-0 {
    margin-bottom: 0px !important;
  }
  .mb-md-1 {
    margin-bottom: 5px !important;
  }
  .mb-md-2 {
    margin-bottom: 10px !important;
  }
  .mb-md-3 {
    margin-bottom: 15px !important;
  }
  .mb-md-4 {
    margin-bottom: 20px !important;
  }
  .mb-md-5 {
    margin-bottom: 25px !important;
  }
  .mb-md-6 {
    margin-bottom: 30px !important;
  }
  .ml-md-0 {
    margin-left: 0px !important;
  }
  .ml-md-1 {
    margin-left: 5px !important;
  }
  .ml-md-2 {
    margin-left: 10px !important;
  }
  .ml-md-3 {
    margin-left: 15px !important;
  }
  .ml-md-4 {
    margin-left: 20px !important;
  }
  .ml-md-5 {
    margin-left: 25px !important;
  }
  .ml-md-6 {
    margin-left: 30px !important;
  }
  .mr-md-0 {
    margin-right: 0px !important;
  }
  .mr-md-1 {
    margin-right: 5px !important;
  }
  .mr-md-2 {
    margin-right: 10px !important;
  }
  .mr-md-3 {
    margin-right: 15px !important;
  }
  .mr-md-4 {
    margin-right: 20px !important;
  }
  .mr-md-5 {
    margin-right: 25px !important;
  }
  .mr-md-6 {
    margin-right: 30px !important;
  }
  .mx-md-0 {
    margin: 0 0px !important;
  }
  .mx-md-1 {
    margin: 0 5px !important;
  }
  .mx-md-2 {
    margin: 0 10px !important;
  }
  .mx-md-3 {
    margin: 0 15px !important;
  }
  .mx-md-4 {
    margin: 0 20px !important;
  }
  .mx-md-5 {
    margin: 0 25px !important;
  }
  .mx-md-6 {
    margin: 0 30px !important;
  }
  .my-md-0 {
    margin: 0px 0 !important;
  }
  .my-md-1 {
    margin: 5px 0 !important;
  }
  .my-md-2 {
    margin: 10px 0 !important;
  }
  .my-md-3 {
    margin: 15px 0 !important;
  }
  .my-md-4 {
    margin: 20px 0 !important;
  }
  .my-md-5 {
    margin: 25px 0 !important;
  }
  .my-md-6 {
    margin: 30px 0 !important;
  }
}
/* padding */
.p-0 {
  padding: 0px !important;
}
.p-1 {
  padding: 5px !important;
}
.p-2 {
  padding: 10px !important;
}
.p-3 {
  padding: 15px !important;
}
.p-4 {
  padding: 20px !important;
}
.p-5 {
  padding: 25px !important;
}
.p-6 {
  padding: 30px !important;
}
.pt-0 {
  padding-top: 0px !important;
}
.pt-1 {
  padding-top: 5px !important;
}
.pt-2 {
  padding-top: 10px !important;
}
.pt-3 {
  padding-top: 15px !important;
}
.pt-4 {
  padding-top: 20px !important;
}
.pt-5 {
  padding-top: 25px !important;
}
.pt-6 {
  padding-top: 30px !important;
}
.pb-0 {
  padding-bottom: 0px !important;
}
.pb-1 {
  padding-bottom: 5px !important;
}
.pb-2 {
  padding-bottom: 10px !important;
}
.pb-3 {
  padding-bottom: 15px !important;
}
.pb-4 {
  padding-bottom: 20px !important;
}
.pb-5 {
  padding-bottom: 25px !important;
}
.pb-6 {
  padding-bottom: 30px !important;
}
.pl-0 {
  padding-left: 0px !important;
}
.pl-1 {
  padding-left: 5px !important;
}
.pl-2 {
  padding-left: 10px !important;
}
.pl-3 {
  padding-left: 15px !important;
}
.pl-4 {
  padding-left: 20px !important;
}
.pl-5 {
  padding-left: 25px !important;
}
.pl-6 {
  padding-left: 30px !important;
}
.pr-0 {
  padding-right: 0px !important;
}
.pr-1 {
  padding-right: 5px !important;
}
.pr-2 {
  padding-right: 10px !important;
}
.pr-3 {
  padding-right: 15px !important;
}
.pr-4 {
  padding-right: 20px !important;
}
.pr-5 {
  padding-right: 25px !important;
}
.pr-6 {
  padding-right: 30px !important;
}
.px-0 {
  padding: 0 0px !important;
}
.px-1 {
  padding: 0 5px !important;
}
.px-2 {
  padding: 0 10px !important;
}
.px-3 {
  padding: 0 15px !important;
}
.px-4 {
  padding: 0 20px !important;
}
.px-5 {
  padding: 0 25px !important;
}
.px-6 {
  padding: 0 30px !important;
}
.py-0 {
  padding: 0px 0 !important;
}
.py-1 {
  padding: 5px 0 !important;
}
.py-2 {
  padding: 10px 0 !important;
}
.py-3 {
  padding: 15px 0 !important;
}
.py-4 {
  padding: 20px 0 !important;
}
.py-5 {
  padding: 25px 0 !important;
}
.py-6 {
  padding: 30px 0 !important;
}
@media screen and (max-width: 768px) {
  .p-md-0 {
    padding: 0px !important;
  }
  .p-md-1 {
    padding: 5px !important;
  }
  .p-md-2 {
    padding: 10px !important;
  }
  .p-md-3 {
    padding: 15px !important;
  }
  .p-md-4 {
    padding: 20px !important;
  }
  .p-md-5 {
    padding: 25px !important;
  }
  .p-md-6 {
    padding: 30px !important;
  }
  .pt-md-0 {
    padding-top: 0px !important;
  }
  .pt-md-1 {
    padding-top: 5px !important;
  }
  .pt-md-2 {
    padding-top: 10px !important;
  }
  .pt-md-3 {
    padding-top: 15px !important;
  }
  .pt-md-4 {
    padding-top: 20px !important;
  }
  .pt-md-5 {
    padding-top: 25px !important;
  }
  .pt-md-6 {
    padding-top: 30px !important;
  }
  .pb-md-0 {
    padding-bottom: 0px !important;
  }
  .pb-md-1 {
    padding-bottom: 5px !important;
  }
  .pb-md-2 {
    padding-bottom: 10px !important;
  }
  .pb-md-3 {
    padding-bottom: 15px !important;
  }
  .pb-md-4 {
    padding-bottom: 20px !important;
  }
  .pb-md-5 {
    padding-bottom: 25px !important;
  }
  .pb-md-6 {
    padding-bottom: 30px !important;
  }
  .pl-md-0 {
    padding-left: 0px !important;
  }
  .pl-md-1 {
    padding-left: 5px !important;
  }
  .pl-md-2 {
    padding-left: 10px !important;
  }
  .pl-md-3 {
    padding-left: 15px !important;
  }
  .pl-md-4 {
    padding-left: 20px !important;
  }
  .pl-md-5 {
    padding-left: 25px !important;
  }
  .pl-md-6 {
    padding-left: 30px !important;
  }
  .pr-md-0 {
    padding-right: 0px !important;
  }
  .pr-md-1 {
    padding-right: 5px !important;
  }
  .pr-md-2 {
    padding-right: 10px !important;
  }
  .pr-md-3 {
    padding-right: 15px !important;
  }
  .pr-md-4 {
    padding-right: 20px !important;
  }
  .pr-md-5 {
    padding-right: 25px !important;
  }
  .pr-md-6 {
    padding-right: 30px !important;
  }
  .px-md-0 {
    padding: 0 0px !important;
  }
  .px-md-1 {
    padding: 0 5px !important;
  }
  .px-md-2 {
    padding: 0 10px !important;
  }
  .px-md-3 {
    padding: 0 15px !important;
  }
  .px-md-4 {
    padding: 0 20px !important;
  }
  .px-md-5 {
    padding: 0 25px !important;
  }
  .px-md-6 {
    padding: 0 30px !important;
  }
  .py-md-0 {
    padding: 0px 0 !important;
  }
  .py-md-1 {
    padding: 5px 0 !important;
  }
  .py-md-2 {
    padding: 10px 0 !important;
  }
  .py-md-3 {
    padding: 15px 0 !important;
  }
  .py-md-4 {
    padding: 20px 0 !important;
  }
  .py-md-5 {
    padding: 25px 0 !important;
  }
  .py-md-6 {
    padding: 30px 0 !important;
  }
}
.m-3-children div {
  margin-bottom: 4px;
}
.center {
  text-align: center;
}
.label-remove-before label::before {
  content: none !important;
}
.no-wrap {
  white-space: nowrap;
}
body {
  padding-right: 0 !important;
  font-family: 'Montserrat', sans-serif !important;
}
.user-select {
  user-select: none;
}
.ant-input-search-button {
  background-color: #00441F !important;
  color: white !important;
}
.unset-white-space {
  white-space: unset !important;
}
.no-wrap-white-space {
  white-space: nowrap !important;
}
.column-link {
  text-decoration: underline !important;
  color: #00CF7B !important;
  font-weight: 500 !important;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  margin-bottom: 0;
}
@media (max-width: 1440px) {
  .column-link {
    white-space: nowrap;
    font-size: 10px;
  }
}
.ant-radio-inner {
  background-color: transparent;
}
#root {
  min-height: 100vh;
}
p {
  margin: 0;
}
.ant-picker {
  width: 100%;
}
.modal-config .ant-modal-content {
  z-index: 999;
  max-height: calc(100vh - 100px);
  padding-bottom: 0;
}
.modal-config .ant-modal-title {
  white-space: nowrap;
  overflow: hidden;
  margin-right: 50px;
  position: relative;
}
.modal-config .ant-modal-body {
  max-height: calc(100vh - 155px);
  overflow-y: auto;
}
.modal-config--w-30 {
  width: 30vw !important;
}
.modal-config--w-50 {
  width: 50vw !important;
}
.modal-config--w-75 {
  width: 75vw !important;
}
@media (max-width: 800px) {
  .modal-config {
    width: 100vw !important;
  }
}
.modal-config-radius .ant-modal-content {
  border-radius: 20px;
}
.modal-config-radius .ant-modal-content .ant-modal-header {
  border-radius: 20px 20px 0 0;
}
.rounded-radio {
  text-align: center;
}
.rounded-radio :first-child {
  border-radius: 30px 0 0 30px;
  width: 50%;
}
@media (max-width: 380px) {
  .rounded-radio :first-child {
    font-size: 12px;
  }
}
.rounded-radio :last-child {
  border-radius: 0 30px 30px 0;
  width: 50%;
}
@media (max-width: 380px) {
  .rounded-radio :last-child {
    font-size: 12px;
  }
}
.rounded-radio.ant-radio-group-large .ant-radio-button-wrapper {
  height: 40px !important;
  line-height: 38px !important;
}
.rounded-radio .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child,
.rounded-radio .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  border: none;
}
.rounded-radio .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  background-color: #00441F !important;
  color: #fff !important;
  font-weight: 700;
}
.rounded-radio .ant-radio-button-wrapper {
  background-color: #fff !important;
  color: #00441F !important;
  font-weight: 700;
}
.container {
  padding: 15px;
  margin-right: 0;
  margin-top: 2px;
  margin-bottom: 2px;
  overflow-y: auto;
  min-height: calc(100vh);
}
.container--custom-height {
  background-color: #E0E5CF;
  min-height: calc(100vh - 118px);
}
@media only screen and (max-width: 1200px) {
  .container--custom-height {
    min-height: calc(100vh - 148px);
  }
}
@media only screen and (max-width: 576px) {
  .container--custom-height {
    min-height: calc(100vh - 144px);
  }
}
.container--centered-width {
  max-width: 1280px;
  margin: auto;
}
.content-container {
  min-height: 100vh;
  height: 100%;
}
.bg-starting-status-color {
  background-color: #cacaca;
}
.bg-analysis-status-color {
  background-color: #00A8E2;
}
.bg-remaking-status-color {
  background-color: #00A8E2;
}
.bg-refused-status-color {
  background-color: #DD4E4E;
}
.bg-reproved-status-color {
  background-color: #DD4E4E;
}
.bg-scanned-status-color {
  background-color: #D7CE01;
}
.bg-approved-status-color {
  background-color: #00CF7B;
}
.bg-white-status-color {
  background-color: #fff;
}
.bg-default-status-color {
  background-color: #f5f5f5;
}
.react-code-input input[type='tel'] {
  font-family: none;
}
.react-code-input input[type='tel']:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.react-code-input input[type='tel']:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.pdf-container canvas {
  width: 100% !important;
  height: 100% !important;
}
.react-pdf__Document {
  display: flex;
  justify-content: center;
}
.modal-document-image {
  width: 50vw;
  margin-top: 25px;
}
.card-style {
  background: #fff;
  border: 1px solid #c2bebe;
}
.card-style h2 {
  font-weight: bolder;
  text-transform: capitalize;
}
.proposal-container-card {
  background: #fff;
  border-radius: 20px;
  padding: 1rem;
}
.proposal-container-card h2 {
  font-weight: bolder;
  text-transform: capitalize;
}
.proposal-container-card__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}
@media only screen and (max-width: 576px) {
  .proposal-container-card__header {
    flex-direction: column;
    gap: 15px;
  }
}
.ant-drawer-body {
  scroll-behavior: smooth;
}
@media only screen and (max-width: 576px) {
  .ant-drawer-body {
    padding: 1.2rem;
  }
}
.step-renders-container {
  position: relative;
}
.step-renders-container .go-to-review-button {
  position: fixed;
  right: 20px;
  bottom: 71px;
  z-index: 30;
  display: flex;
  align-items: center;
  transition: 0.2s;
  animation: reviewButtonAnimation 0.5s;
}
.step-renders-container .go-to-review-button p {
  display: block;
  margin: 0 5px 0 0;
}
.step-renders-container .go-to-review-button:hover {
  filter: brightness(0.95);
}
.step-renders-container .go-to-review-button-invisible {
  position: fixed;
  right: -150px;
  bottom: 71px;
  opacity: 0;
  background-color: #00CF7B;
  color: #fff;
  animation: reviewButtonAnimationInvisible 0.3s;
}
.card-style-border-radius {
  border-radius: 5px;
}
.fade-in-animation {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-timing-function: ease-in;
  animation-duration: 200ms;
}
.modal-term {
  height: 50vh;
  overflow: auto;
}
.fixed-header {
  position: fixed;
  top: 64px;
  z-index: 9;
  width: 89.5%;
  padding: 10px;
  border-bottom: 1px solid #c2bebe;
  transition: padding-bottom 0.25s ease-in, padding-top 0.25s ease-in, background-color 0.25s ease-in;
  margin-left: -30px;
  padding-left: 30px;
}
.fixed-header--collapsed {
  width: 95.7%;
}
.fixed-header h1,
.fixed-header span {
  margin-bottom: 0 !important;
}
@media only screen and (max-width: 1200px) {
  .fixed-header {
    top: 90px;
  }
}
@media only screen and (max-width: 768px) {
  .fixed-header {
    top: 90px;
    width: 76.5%;
  }
  .fixed-header--collapsed {
    width: 91.5%;
  }
  .sidebar-size {
    max-height: calc(100vh - 104px);
  }
}
@media only screen and (max-width: 576px) {
  .fixed-header {
    top: 90px;
    width: calc(100% + 10.5px);
  }
}
@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@media only screen and (max-width: 576px) {
  .ant-layout-content {
    margin: 2px;
  }
}
.icon-copy span:first-of-type {
  color: black;
}
.departament-title {
  margin-bottom: 0;
  padding-bottom: 0;
  text-transform: 'capitalize';
}
.linked-backoffices-list {
  text-align: left;
  margin-left: 40px;
}
.department-image-documents {
  width: 100%;
  max-height: 90px;
}
.line-chart-layout {
  padding-right: 20px;
}
.col-text-h4 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 20px;
  line-height: 1.4;
}
.col-text-h5 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
}
@media (min-width: 1200px) and (max-width: 1592px) {
  .col-text-h4 {
    font-size: 14px;
  }
  .col-text-h5 {
    margin-top: 5px;
    font-size: 12px;
  }
  .col-icons {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
  }
}
@media (min-width: 1200px) {
  .pie-chart-layout {
    padding: 0 25px 0 25px;
  }
}
@media (min-width: 320px) and (max-width: 1199px) {
  .pie-chart {
    margin-top: 20px;
  }
  .line-chart-layout {
    padding-right: 0;
  }
  .col-icons {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
  }
}
@media (min-width: 928px) and (max-width: 1200px) {
  .col-text-h5 {
    margin-top: 5px;
    font-size: 9px;
  }
  .col-text-h4 {
    font-size: 10px;
  }
}
@media (min-width: 701px) and (max-width: 927px) {
  .col-text-h5 {
    margin-top: 5px;
    font-size: 7.5px;
  }
  .col-text-h4 {
    font-size: 10px;
  }
}
@media (min-width: 320px) and (max-width: 700px) {
  .col-text-h5 {
    margin-top: 5px;
    font-size: 5px;
  }
  .col-text-h4 {
    font-size: 8px;
  }
  .col-icons {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;
  }
}
.line-chart-layout {
  padding-right: 20px;
}
.col-text-h4 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 20px;
  line-height: 1.4;
}
.col-text-h5 {
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
}
@media (min-width: 1200px) and (max-width: 1592px) {
  .col-text-h4 {
    font-size: 14px;
  }
  .col-text-h5 {
    margin-top: 5px;
    font-size: 12px;
  }
  .col-icons {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
  }
}
@media (min-width: 1200px) {
  .pie-chart-layout {
    padding: 0 25px 0 25px;
  }
}
@media (min-width: 320px) and (max-width: 1199px) {
  .pie-chart {
    margin-top: 20px;
  }
  .line-chart-layout {
    padding-right: 0;
  }
  .col-icons {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
  }
}
@media (min-width: 928px) and (max-width: 1200px) {
  .col-text-h5 {
    margin-top: 5px;
    font-size: 9px;
  }
  .col-text-h4 {
    font-size: 10px;
  }
}
@media (min-width: 701px) and (max-width: 927px) {
  .col-text-h5 {
    margin-top: 5px;
    font-size: 7.5px;
  }
  .col-text-h4 {
    font-size: 10px;
  }
}
@media (min-width: 320px) and (max-width: 700px) {
  .col-text-h5 {
    margin-top: 5px;
    font-size: 5px;
  }
  .col-text-h4 {
    font-size: 8px;
  }
  .col-icons {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;
  }
}
@media screen and (max-width: 1366px) {
  .department-image-documents {
    width: 100%;
    max-height: 75px;
  }
}
.img-responsive-tiny {
  width: 150px;
}
.paragraph-feedback {
  position: absolute;
  white-space: nowrap;
  bottom: 0;
  left: 0;
  color: #ff5533;
  margin-left: 11px;
  margin-bottom: 5px;
}
@media (max-width: 991px) {
  .paragraph-feedback {
    position: static;
    margin-left: 0px;
    margin-bottom: 0px;
  }
}
@media (max-width: 768px) {
  .paragraph-feedback {
    white-space: pre-wrap;
    position: static;
  }
}
.deliberar-button {
  margin-right: 5px;
  font-size: 12.5px !important;
  max-height: 28px;
}
.no-hover:hover {
  background: transparent !important;
}
.fixed-hover {
  height: 2em;
  border-radius: 25px;
  transition: background-color 0.2;
}
.fixed-hover:hover {
  background: rgba(0, 0, 0, 0.018);
}
.action-svgs-class {
  height: 16px;
  width: 16px;
  filter: brightness(0) invert(1);
  display: flex;
}
.lgpd-page {
  max-width: 800px;
  min-height: 80vh;
}
.reproval-plus-icon {
  color: #00CF7B !important;
  font-size: 30px;
}
.swal2-popup {
  border-radius: 20px !important;
}
.swal2-title {
  color: #00441F !important;
}
div.swal2-content #swal2-content {
  color: #00441F !important;
}
.swal2-styled.swal2-confirm {
  background-color: #00441F !important;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 30px;
  height: 40px;
  padding: 5px 11px;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  margin-top: 3px !important;
}
.ant-select-multiple.ant-select-show-search .ant-select-selector {
  height: auto !important;
}
.ant-input {
  border-radius: 30px;
  height: 40px;
}
.ant-input-affix-wrapper {
  border-radius: 30px;
  height: 40px;
}
.ant-input-affix-wrapper .ant-input {
  height: 30px;
  margin: auto;
}
.ant-input-affix-wrapper .ant-input:-webkit-autofill {
  background-color: none !important;
}
.searchble-focus-select .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 36px;
}
.ant-input-search .ant-input-group-addon {
  position: relative;
  padding: 0 11px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  background-color: none;
  transition: all 0.3s;
  border-top-right-radius: 25px !important;
  border-bottom-right-radius: 25px !important;
}
.ant-input-search .ant-input-search-button {
  width: 40px !important;
}
.pricing-inputs .ant-input {
  border-radius: 5px;
  height: 30px;
  padding-left: 0 0 0 5px;
}
.pricing-inputs .ant-input-number {
  border-radius: 5px;
  height: 30px;
  padding: 0 0 0 5px;
}
.pricing-inputs .ant-row .ant-row {
  margin-bottom: 0 !important;
}
.pricing-table-headers {
  background-color: #00441F;
  padding: 0 5px;
  color: white;
  border-radius: 5px;
  min-height: 25px;
  border-width: 0;
  margin-bottom: 2px;
}
.ant-picker {
  border-radius: 30px !important;
  height: 40px;
}
.ant-input-number {
  border-radius: 30px;
  height: 40px;
  padding: 5px 11px;
}
.ant-btn {
  border-radius: 30px !important;
  height: 40px !important;
}
.button {
  background-color: #79be39;
}
.swal-container-scroll {
  max-height: 60vh;
  overflow-y: scroll;
}
@media only screen and (max-width: 576px) {
  .swal-container-scroll {
    max-height: 70vh;
  }
}
* {
  --red-status: #DD4E4E;
  --blue-status: #00A8E2;
  --green-status: #00CF7B;
  --orange-status: #FF7A40;
  --warning-status: #FFC107;
  --primary-color: #00441F;
  --secondary-color: #E0E5CF;
  --disable-color: rgba(0, 0, 0, 0.25);
  --starting-status-color: #cacaca;
  --analysis-status-color: #00A8E2;
  --refused-status-color: #DD4E4E;
  --scanned-status-color: #D7CE01;
  --approved-status-color: #00CF7B;
  --remaking-status-color: #FF7A40;
  --canceled-status-color: #949292;
  --gradient-primary-color-5: #feffed;
  --status-client-ativo: #00CF7B;
  --status-client-desligado: #DD4E4E;
  --status-default: #00441F;
  --payment-type-ted: #E48F2B;
  --payment-type-boleto: #133C66;
  --light-gray: #f2f2f2;
  --light-gray-4: #d9d9d9;
  --white: #fff;
}
.auth-layout__header-title {
  font-size: 18px;
  color: #00441F;
  font-weight: bold;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  word-wrap: break-word;
}
.auth-layout__content {
  overflow-x: hidden;
  position: relative;
  background-color: var(--secondary-color);
}
.auth-layout__warning-button {
  font-weight: bold;
  border: none;
  background-color: #FFEE82;
  padding: 0 20px;
}
.auth-layout__full-view-height {
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;
}
.auth-layout__layout-container {
  height: 90vh;
}
.auth-layout__full-height {
  height: 100%;
}
.auth-layout__fallback-spin {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.unauth-layout__icon-container {
  margin-bottom: 30px;
  text-align: center;
}
.unauth-layout__icon-container svg {
  max-width: 250px;
  max-height: 250px;
}
.unauth-layout__title {
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: bold;
}
.unauth-layout__text {
  font-size: 16px;
}
.unauth-layout__text--2 {
  font-size: 14px;
}
.unauth-layout__text-container {
  margin-bottom: 30px;
  max-width: 500px;
}
.unauth-layout__button-container {
  text-align: center;
}
.unauth-layout__link {
  text-decoration: underline !important;
}
.unauth-upload-background {
  min-height: 100vh;
  background-color: #E0E5CF;
  background-repeat: no-repeat;
  background-size: cover;
}
.container-unauth {
  background-color: #00441F;
}
.container-unauth img.unauth-dots {
  -drag: none;
  user-select: none;
  width: 25%;
  -webkit-user-drag: none;
}
.container-unauth img.unauth-dots:first-child {
  transform: rotate(180deg);
}
@media (max-width: 1024px) {
  .container-unauth img.unauth-dots {
    display: none;
  }
}
@media screen and (min-width: 768px) {
  .container-unauth {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
  }
}
.full-height-unauth {
  height: 100vh;
  overflow-y: auto;
}
.unauth-upload-photo {
  margin-top: 8px;
  width: 138px;
  color: #E0E5CF;
}
.unauth-reset-password-text {
  text-align: center;
  font-weight: 500;
  color: #00441F;
  margin-bottom: 10px;
}
.unauth-header {
  width: 100%;
  padding: 20px 0 20px 10em;
  background-color: #E0E5CF;
  position: relative;
  display: flex;
  flex-direction: row;
}
@media screen and (max-width: 1100px) {
  .unauth-header {
    display: none;
  }
}
.unauth-header-title {
  font-size: 64px;
  margin-bottom: 0;
  color: white;
  z-index: 2;
}
.unauth-header-shadow {
  font-size: 8rem;
  margin: 0;
  color: rgba(0, 0, 0, 0.2);
  font-weight: 700;
  position: absolute;
  top: -0.4em;
  left: 2.5em;
  z-index: 1;
}
.signin-image-logo {
  width: 200px;
  margin: 32px 0;
  object-fit: contain;
  user-select: none;
  -drag: none;
}
#root .unauth-form .ant-form-item {
  margin-bottom: 16px;
}
.unauth-bottom-label {
  font-size: 14px;
  line-height: 16.8px;
  font-weight: 700;
}
@media screen and (max-width: 992px) {
  .image-place {
    display: none;
  }
}
@media screen and (min-width: 992px) {
  .image-place {
    flex: 3;
    border: 2px solid white;
    -webkit-box-shadow: 5px 5px 30px -1px rgba(255, 255, 255, 0.12);
    box-shadow: 5px 5px 30px -1px rgba(255, 255, 255, 0.12);
    background-image: linear-gradient(to left bottom, #9FC519, #46B452, #009C74, #008181, #006476);
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-origin: content-box;
    margin: 20px;
    border-radius: 20px;
  }
  .text-unauth-container {
    background-color: black;
    opacity: 0.4;
    border-radius: 20px;
  }
  .text-unauth-image {
    position: absolute;
    color: white;
    z-index: 10;
    height: 100%;
    font-size: 150px;
    width: 100%;
  }
  .unauth-images {
    position: relative;
    width: 100%;
    height: 100%;
    top: -15%;
    left: -15%;
    opacity: 0.3;
  }
  .explain-image-unauth {
    text-align: start;
    color: #E0E5CF;
    height: 100%;
    padding: 30px 0;
    border-radius: 20px;
  }
}
.unauth-simulator-date {
  min-height: 50px;
  font-size: 16px;
}
.unauth-simulator-date input {
  text-align: left;
}
.unauth-simulator-date .ant-input {
  min-height: 40px !important;
}
.unauth-simulator-date .ant-input::placeholder {
  color: #00441F !important;
}
.unauth-simulator-date .ant-select-selector {
  min-height: 50px;
  padding: 9px 11px !important;
}
.unauth-simulator-date .ant-select-selector .ant-select-selection-search-input {
  min-height: 40px;
  text-align: left;
}
.unauth-simulator-date:hover {
  border-color: #00441F !important;
}
.unauth-inputs-center {
  min-height: 50px;
  font-size: 16px;
}
.unauth-inputs-center .ant-input {
  min-height: 40px !important;
}
.unauth-inputs-center .ant-select-selector {
  min-height: 50px;
  padding: 9px 11px !important;
}
.unauth-inputs-center .ant-select-selector .ant-select-selection-search-input {
  min-height: 45px;
  text-align: left;
}
.unauth-inputs-center .ant-select-selector .ant-select-selection-item {
  text-align: left;
}
.unauth-inputs-center .ant-select-selector .ant-select-selection-placeholder {
  color: #00441F !important;
  text-align: left;
}
.unauth-inputs-center:hover {
  border-color: #00441F !important;
}
.ant-select.unauth-inputs-center.ant-select-single.ant-select-show-arrow.ant-select-show-search :hover {
  border-color: #00441F !important;
}
.unauth-inputs {
  min-height: 50px;
  font-size: 16px;
  border-color: #00441F;
}
.unauth-inputs .ant-form-item-has-error {
  border-color: #DD4E4E !important;
}
.unauth-inputs .ant-select-arrow > span {
  color: #00CF7B;
}
.unauth-inputs .ant-input {
  min-height: 40px !important;
  border-color: #E0E5CF !important;
}
.unauth-inputs .ant-select-selector {
  min-height: 50px;
  padding: 9px 11px !important;
  border-color: #00441F !important;
}
.unauth-inputs .ant-select-selector .ant-select-selection-search-input {
  min-height: 45px;
}
.unauth-inputs .ant-input-suffix .anticon-calendar {
  color: #00441F !important;
}
.unauth-inputs .ant-input-suffix .anticon-eye-invisible {
  color: #00441F;
}
.unauth-inputs .ant-input-suffix .anticon-eye {
  color: #00441F;
}
.unauth-signup-border .anticon.anticon-calendar {
  color: #E0E5CF !important;
}
.unauth-form .ant-form-item-control-input-content .ant-input-affix-wrapper:focus,
.unauth-form .ant-form-item-control-input-content .ant-input-affix-wrapper-focused {
  border-color: #00441F !important;
  box-shadow: 0px 0px 0px 2px rgba(15, 64, 111, 0.1);
}
.unauth-form-border .ant-select.unauth-inputs.ant-select-single.ant-select-show-arrow :hover {
  border-color: #00441F !important;
}
.unauth-form-border .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #00441F !important;
  box-shadow: 0px 0px 0px 2px rgba(15, 64, 111, 0.1);
}
.unauth-inputs:hover,
.unauth-inputs:focus {
  border-color: #00441F !important;
}
.unauth-inputs:focus {
  border-color: #00441F !important;
  box-shadow: 0px 0px 0px 2px rgba(15, 64, 111, 0.1);
}
.unauth-buttons {
  min-height: 50px;
  text-transform: uppercase;
  margin-bottom: 16px;
}
.unauth-buttons span {
  color: white !important;
  font-size: 16px;
  font-weight: 700;
}
.unauth-buttons-ghost {
  text-transform: uppercase;
  min-height: 50px;
  border: 1px solid #00441F;
  color: #00441F;
  font-size: 16px;
  font-weight: 700;
}
.unauth-oidc {
  text-transform: uppercase;
  color: #f7931e !important;
  font-weight: bolder!important;
  text-decoration: underline;
  display: flex;
  align-items: center;
  justify-content: center;
}
.unauth-oidc:hover {
  opacity: 0.5;
}
.unauth-signup-label {
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
}
@media screen and (min-width: 1000px) {
  .unauth-signup-border {
    min-width: 850px;
    max-width: 980px;
    border-radius: 5px;
    background-color: #E0E5CF;
    padding: 35px 250px 35px 250px;
  }
}
@media screen and (min-width: 768px) {
  .unauth-signup-border {
    border-radius: 5px;
    background-color: #E0E5CF;
    padding: 25px;
  }
}
@media screen and (min-width: 320px) {
  .unauth-signup-border {
    width: 100%;
    background-color: #E0E5CF;
  }
}
@media screen and (min-width: 768px) {
  .container-unauth aside.form-place {
    padding: 20px;
  }
}
@media screen and (max-width: 768px) {
  .container-unauth aside.form-place {
    padding: 0;
  }
}
.unauth-form-border {
  border-radius: 20px;
  padding: 25px;
  width: 567px;
  background-color: #E0E5CF;
}
@media screen and (max-width: 1260px) {
  .unauth-form-border {
    border-radius: 20px;
    background-color: #E0E5CF;
  }
}
@media screen and (max-width: 1000px) {
  .unauth-form-border {
    border-radius: 20px;
    background-color: #E0E5CF;
  }
}
@media screen and (max-width: 768px) {
  .unauth-form-border {
    width: 100%;
    border-radius: 20px;
    background-color: #E0E5CF;
  }
}
@media screen and (max-width: 425px) {
  .unauth-form-border {
    width: 100%;
    border-radius: 20px;
    background-color: #E0E5CF;
  }
}
.unauth-list-selfie {
  list-style-type: '- ';
  padding-left: 0;
}
.form-place {
  padding: 20px;
  min-height: 445px;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 1200px) {
  .form-place {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.unauth-signup-border .ant-checkbox .ant-checkbox-inner,
.request-cnd-checkbox .ant-checkbox .ant-checkbox-inner {
  width: 25px;
  height: 25px;
  border-color: #00441F;
  border-radius: 6px;
}
.checkbox-lgpd .ant-checkbox .ant-checkbox-inner {
  width: 25px;
  height: 25px;
  border-color: #00441F;
  border-radius: 6px;
}
.unauth-signup-border .ant-checkbox-disabled .ant-checkbox-inner,
.checkbox-lgpd .ant-checkbox-disabled .ant-checkbox-inner {
  width: 25px;
  height: 25px;
  border-radius: 6px;
}
.unauth-signup-border .ant-checkbox-checked .ant-checkbox-inner,
.request-cnd-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  width: 25px;
  height: 25px;
  border-radius: 6px;
  border-color: #00441F;
}
.checkbox-lgpd .ant-checkbox-checked .ant-checkbox-inner {
  width: 25px;
  height: 25px;
  border-radius: 6px;
  border-color: #00441F;
}
.unauth-signup-border .ant-checkbox.ant-checkbox-checked:hover,
.checkbox-lgpd .ant-checkbox.ant-checkbox-checked:hover {
  border-radius: 6px !important;
}
.unauth-signup-border .ant-checkbox-inner::after,
.checkbox-lgpd .ant-checkbox-inner::after {
  width: 7px;
  height: 15px;
}
.checkbox-lgpd .ant-checkbox.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #00441F !important;
}
.checkbox-lgpd .ant-checkbox-checked::after {
  border-color: #00441F !important;
  border-radius: 6px;
}
.unauth-signup-border .ant-checkbox-checked::after {
  border-radius: 6px;
}
.unauth-terms-label {
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;
  color: #00441F;
}
.unauth-terms-label a {
  text-decoration: underline;
}
.signup-form-margin {
  margin: 0 10%;
}
.avatar-uploader > .ant-upload {
  width: 170px;
  height: 170px;
  border: 1px solid #E0E5CF;
  max-height: 100%;
}
.ant-upload.ant-upload-select-picture-card:hover {
  border-color: #E0E5CF !important;
}
.unauth-divider {
  margin: 0 !important;
}
.unauth-divider .ant-divider-inner-text {
  color: #00441F;
  font-weight: bold;
}
.simulator-full-page {
  background-color: rgba(245, 247, 249, 0.95);
  min-height: 100vh;
  max-height: 100%;
}
@media (max-width: 425px) {
  .simulator-full-page {
    background-image: url('/assets/img/bg-banner-cgi.png');
    background-size: contain;
    background-repeat: no-repeat;
  }
}
@media (min-width: 426px) {
  .simulator-full-page {
    background-image: url('/assets/img/bg-banner-cgi-notebook.png');
    background-size: contain;
    background-repeat: no-repeat;
  }
}
@media (min-width: 1024px) {
  .simulator-full-page {
    background-image: url('/assets/img/bg-banner-cgi-desktop.png');
    background-size: contain;
    background-repeat: no-repeat;
  }
}
@media (min-width: 1920px) {
  .simulator-full-page {
    background-image: url('/assets/img/bg-banner-cgi-desktop.png');
    background-size: cover;
    background-repeat: no-repeat;
  }
}
@media (max-width: 1024px) {
  .simulator-full-page {
    height: 100%;
  }
}
.simulator-wrapper {
  border: '1px solid grey';
  display: flex;
  padding: 13px 150px;
}
@media (max-width: 1400px) and (min-width: 1200px) {
  .simulator-wrapper {
    padding: 13px 150px;
  }
}
@media (max-width: 1200px) {
  .simulator-wrapper {
    justify-content: center;
    padding: 0 10px;
    justify-content: flex-start;
    flex-direction: column;
  }
}
.simulator-wrapper .ant-form-vertical .ant-form-item-label {
  padding-bottom: 3px;
}
.simulator-wrapper .ant-form-item {
  margin-bottom: 16px;
}
.simulator-left-side-block-text {
  max-width: 500px;
  text-align: left;
  align-self: flex-start;
  margin-top: 65px;
}
@media (max-width: 1200px) {
  .simulator-left-side-block-text {
    max-width: 400px;
    margin-top: 30px;
    align-self: flex-start;
  }
}
@media (max-width: 500px) {
  .simulator-left-side-block-text {
    width: 100%;
    margin-top: 30px;
    align-self: flex-start;
  }
}
@media (max-width: 375px) {
  .simulator-left-side-block-text {
    margin-top: 30px;
    width: 100%;
  }
}
.simulator-main-text {
  font-family: Montserrat;
  font-weight: 500;
  font-style: normal;
  margin-bottom: 32px;
  margin-top: 64px;
  font-size: clamp(2.75rem, 5vw, 5rem);
  line-height: 48px;
  letter-spacing: 2px;
  color: white;
}
@media (min-width: 1200px) {
  .simulator-main-text {
    line-height: 75px;
  }
}
@media (max-width: 1200px) {
  .simulator-main-text {
    margin-top: 32px;
  }
}
@media (max-width: 320px) {
  .simulator-main-text {
    margin-bottom: 20px;
  }
}
.simulator-description-text {
  font-family: Montserrat-Regular, -apple-system, BlinkMacSystemFont, Segoe UI, Montserrat, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  color: white;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 1px;
}
@media (max-width: 320px) {
  .simulator-description-text {
    margin-bottom: 12px;
  }
}
.simulator-layout-link-client {
  border: 1.6px solid #00441F;
  border-radius: 50px;
  padding: 10px 45px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 900;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF !important;
}
.simulator-layout-link-client:hover {
  color: #00441F !important;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after,
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after,
.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
  background-color: transparent;
  border-bottom: solid;
  border-color: #00441F !important;
  border-bottom-width: 1.5px;
  margin-left: -15px;
}
.full-screen-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
  background-color: var(--secondary-color);
}
.full-screen-loading__img {
  width: 280px;
}
.full-screen-loading__card {
  background-color: #f2f2f2;
  width: 500px;
  border-radius: 20px;
  padding: 1rem;
}
@media only screen and (max-width: 576px) {
  .full-screen-loading__card {
    width: 100%;
  }
}
.full-screen-loading__card--flex-centered {
  display: flex;
  align-items: center;
}
.full-screen-loading__description {
  font-size: 20px;
}
.full-screen-loading__sub-title {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 15px;
  color: var(--primary-color);
}
.full-screen-loading__logo-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
}
.full-screen-error {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  flex-direction: column;
  background-color: var(--secondary-color);
}
.full-screen-error__img {
  width: 160px;
  margin-bottom: 20px;
}
.full-screen-error__card {
  max-width: 500px;
  border-radius: 20px;
  background-color: #f2f2f2;
  padding: 1rem;
}
.full-screen-lgpd {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--secondary-color);
  color: var(--primary-color);
  flex-direction: column;
  font-size: 14px;
  min-height: 100vh;
  padding: 1rem;
}
@media only screen and (max-width: 480px) {
  .full-screen-lgpd {
    justify-content: flex-start;
    height: unset;
  }
}
.full-screen-lgpd__container {
  display: flex;
  flex-direction: inherit;
  gap: 10px;
  max-width: 1280px;
  width: 100%;
}
.full-screen-lgpd__header {
  color: var(--primary-color);
  font-size: 18px;
  font-weight: bold;
}
#root .signup-checkbox .ant-checkbox-inner {
  border: 1px solid #00441F;
  width: 24px;
  height: 24px;
  border-radius: 5px;
}
#root .signup-checkbox .ant-checkbox-inner::after {
  left: 26%;
  top: 45%;
  width: 7px;
  height: 15px;
}
#root .signup-layout__header {
  display: flex;
  align-items: center;
  background-color: #E0E5CF;
  height: 60px;
}
#root .signup-layout__header div {
  padding: 0 30px;
  margin-left: 20px;
}
@media (max-width: 1024px) {
  #root .signup-layout__header {
    justify-content: center;
  }
  #root .signup-layout__header div {
    padding: 0;
    margin-left: 0;
  }
}
#root .signup-layout__logo {
  width: 180px;
  margin-top: 5px;
  object-fit: contain;
  user-select: none;
}
#root .signup-layout__container {
  padding: 2rem 0;
  max-width: clamp(28rem, 28%, 28%);
  margin: auto;
}
@media only screen and (max-width: 768px) {
  #root .signup-layout__container {
    padding: 1rem 1rem;
  }
}
.w-25 {
  width: 25%;
}
.w-50 {
  width: 50%;
}
.w-75 {
  width: 75% !important;
}
.w-100 {
  width: 100%;
}
.h-50 {
  height: 50%;
}
.h-100 {
  height: 100%;
}
@media screen and (max-width: 768px) {
  .w-md-100 {
    width: 100%;
  }
  .w-md-75 {
    width: 75%;
  }
  .w-md-50 {
    width: 50%;
  }
}
.flex {
  display: flex !important;
}
.flex-column {
  flex-direction: column;
}
.flex-row {
  flex-direction: row;
}
.flex-space-between {
  justify-content: space-between !important;
}
.f-row {
  flex-direction: row;
}
.d-block {
  display: block !important;
}
.display-none {
  display: none !important;
}
.display-block {
  display: block !important;
}
.display-inline-block {
  display: inline-block !important;
}
.display-inline {
  display: inline !important;
}
.flex-justify-center {
  justify-content: center;
}
.flex-justify-start {
  justify-content: flex-start;
}
.flex-justify-end {
  justify-content: flex-end;
}
.flex-justify-between {
  justify-content: space-between;
}
.flex-justify-around {
  justify-content: space-around;
}
.flex-justify-evenly {
  justify-content: space-evenly;
}
.pointer {
  cursor: pointer;
}
.not-allowed {
  cursor: not-allowed;
}
.flex-align-center {
  align-items: center;
}
.flex-align-start {
  align-items: flex-start;
}
.flex-self-align-end {
  align-self: flex-end;
}
.flex-align-baseline {
  align-items: baseline;
}
.flex-align-end {
  align-items: flex-end;
}
.flex-vertical-center {
  display: flex;
  align-items: center;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.flex-3 {
  flex: 3;
}
@media screen and (max-width: 1400px) {
  .flex-lg-column {
    flex-direction: column;
  }
}
@media screen and (max-width: 768px) {
  .flex-md-column {
    flex-direction: column;
  }
}
.position-relative {
  position: relative;
}
.grid {
  display: grid;
}
.grid-column-5 {
  grid-template-columns: repeat(5, 200px);
}
.home-card-svg {
  width: 180px;
  height: 120px;
}
.title-home {
  font-size: 1.2rem;
  font-weight: 100;
  letter-spacing: 3px;
}
.padding-divisor {
  padding-top: 80px;
}
@media (max-width: 768px) {
  .label-user-level {
    display: none;
  }
}
.color-white {
  color: #fff !important;
}
.color-danger {
  color: #DD4E4E;
}
.color-warning {
  color: #FFC107 !important;
}
.color-black {
  color: #000;
}
.color-tertiary {
  color: #00CF7B !important;
}
.color-secondary {
  color: #E0E5CF !important;
}
.color-analysis {
  color: #00A8E2 !important;
}
.color-refused {
  color: #DD4E4E !important;
}
.color-canceled {
  color: #949292 !important;
}
.color-approved {
  color: #00CF7B !important;
}
.color-updated {
  color: #FFBF00 !important;
}
.color-ligth-blue-dash {
  color: #56D9FE !important;
}
.color-gray {
  color: #65737A !important;
}
.color-primary {
  color: #00441F !important;
}
.underline {
  text-decoration: underline;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.bold {
  font-weight: bold;
}
.uppercase {
  text-transform: uppercase;
}
.link-style {
  text-decoration: underline !important;
  color: #00441F;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
}
.primary-color {
  color: #00441F !important;
}
.font-500 {
  font-weight: 500 !important;
}
.font-400 {
  font-weight: 400 !important;
}
.font-size-12 {
  font-size: 12px !important;
}
.font-size-10 {
  font-size: 10px !important;
}
.font-size-15 {
  font-size: 15px !important;
}
.font-size-20 {
  font-size: 20px !important;
}
.font-size-25 {
  font-size: 25px !important;
}
.cursor-pointer {
  cursor: pointer;
}
.text-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.capitalize {
  text-transform: capitalize;
}
.word-break-all {
  word-break: break-all;
}
.proposal-history-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: 'Montserrat';
  width: 100%;
  background-color: white;
  position: relative;
}
.proposal-history-wrapper {
  position: relative;
  overflow: hidden;
  font-family: Montserrat;
}
.revision-timeline label {
  font-size: 16px;
}
.revision-timeline .tox-tinymce {
  border: 0;
}
.step-lead {
  padding: 4px;
  border-radius: 2px;
  height: 20px;
  font-size: 14px;
  color: #000;
  background: rgba(14, 192, 7, 0.2);
}
.success-lead {
  border: 1px solid #00CF7B;
  background-color: #00CF7B;
  border-radius: 2px;
  padding: 5px;
}
.lead-history-item-tail .ant-timeline-item-tail {
  border-left: 2px solid #cccccc;
}
.lead-history-item-tail .ant-timeline-item-content .timeline-commentary-item,
.lead-history-item-tail .ant-timeline-item-content .timeline-message-item {
  overflow: auto;
}
.lead-history-item-tail .ant-timeline-item-left .ant-timeline-item-content .timeline-commentary-item,
.lead-history-item-tail .ant-timeline-item-left .ant-timeline-item-content .timeline-message-item {
  border-left: 2px solid;
  padding: 20px;
}
.lead-history-item-tail .ant-timeline-item-right .ant-timeline-item-content .timeline-commentary-item,
.lead-history-item-tail .ant-timeline-item-right .ant-timeline-item-content .timeline-message-item {
  border-right: 2px solid;
  padding: 20px;
}
.lead-history-item-tail .ant-timeline-item-last .step-review__timeline-item {
  padding: 0;
}
.leads-drawer-search input {
  height: 40px !important;
}
.left-border-message {
  border-left: 1px solid red !important;
}
.rigth-border-message {
  border-right: 1px solid blue !important;
}
.department-card__progress-bar-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
@media only screen and (max-width: 1200px) {
  .department-card__progress-bar-container {
    display: block;
  }
}
.department-card__documents-download {
  max-height: 35px;
}
.department-card__zip-buttons {
  display: flex;
}
@media only screen and (max-width: 1200px) {
  .department-card__zip-buttons {
    margin: 1rem 0;
    padding-left: 0;
  }
}
.department-card__zip-button-left {
  height: 35px !important;
  background-color: 'red';
  border-radius: 20px 0 0 20px !important;
  border-right: 0;
}
.department-card__zip-button-right {
  height: 35px !important;
  border-radius: 0 20px 20px 0 !important;
}
.department-card-status-text {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  font-size: 18px;
  color: #00441F;
}
.department-card-title {
  color: #00441F !important;
  text-transform: uppercase !important;
  font-weight: 700 !important;
  font-family: Montserrat !important;
  font-size: 1.4rem !important;
  line-height: 31px !important;
  display: flex;
  align-items: center !important;
}
.department-steps-collapse .ant-collapse-header {
  color: #00441F !important;
  font-weight: 500 !important;
}
.department-steps-collapse .ant-collapse-header span {
  color: #00CF7B !important;
}
.icon-container {
  display: flex;
  justify-content: center;
  padding-top: 10px;
}
.divider-style {
  margin-top: 0;
  padding-top: 0;
  margin-bottom: 20px;
  border-width: 2px;
  background-color: rgba(97, 97, 97, 0.7);
}
.department-card-title-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
@media (max-width: 1024px) {
  .department-card-title-container {
    display: block;
    min-width: 0px;
  }
}
@media (max-width: 768px) {
  .department-card-title-container {
    display: contents;
    min-width: 0px;
  }
}
.department-card-sla-container {
  justify-self: flex-end;
  width: max-content;
}
.step-reservation {
  position: absolute;
  top: -8px;
  right: -4px;
  color: #FF7A40;
}
.sla-info {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}
@media (max-width: 1320px) {
  .sla-info {
    font-size: 10px;
  }
}
@media (max-width: 1024px) {
  .sla-info {
    justify-content: flex-start;
  }
}
@media (max-width: 768px) {
  .sla-info {
    justify-content: flex-start;
  }
}
.department-card-step-font {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 23px;
}
.progress-bar {
  width: 395px;
  margin-right: 70px;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 520px) {
  .progress-bar {
    display: flex;
    align-items: center;
    flex-direction: column !important;
  }
  .department-card-status-text {
    font-size: 0.9rem !important;
  }
  .department-card-step-font {
    font-size: 13px !important;
  }
  .department-card-title {
    font-size: 1.2rem !important;
  }
}
.statistic-simulation-card-secondary {
  padding: 10px 15px;
}
.statistic-simulation-card-secondary .ant-statistic-content-value {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 900;
  font-size: 22px;
  line-height: 26px;
  color: #E0E5CF;
}
@media (max-width: 1385px) {
  .statistic-simulation-card-secondary {
    padding: 10px 7px;
  }
  .statistic-simulation-card-secondary .ant-statistic-title {
    font-size: 12px;
  }
  .statistic-simulation-card-secondary .ant-statistic-content-value {
    font-size: 18px;
  }
}
@media (max-width: 1125px) {
  .statistic-simulation-card-secondary .ant-statistic-title {
    font-size: 12px;
    white-space: nowrap !important;
  }
  .statistic-simulation-card-secondary .ant-statistic-content-value {
    font-size: 14px;
  }
}
.statistic-simulation-card-primary {
  padding: 10px 15px;
}
.statistic-simulation-card-primary .ant-statistic-content-value {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  color: #00441F;
}
.statistic-simulation-card-primary .ant-statistic-title {
  font-weight: 500;
  color: #00441F;
}
@media (max-width: 1385px) {
  .statistic-simulation-card-primary {
    padding: 10px 7px;
  }
  .statistic-simulation-card-primary .ant-statistic-title {
    font-size: 12px;
  }
  .statistic-simulation-card-primary .ant-statistic-content-value {
    font-size: 18px;
  }
}
@media (max-width: 1125px) {
  .statistic-simulation-card-primary .ant-statistic-title {
    font-size: 12px;
    white-space: nowrap !important;
  }
  .statistic-simulation-card-primary .ant-statistic-content-value {
    font-size: 14px;
  }
}
.simulator-tooltip-h1 {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0;
  color: black;
}
.user-details__lgpd-modal {
  height: 80%;
  overflow: auto;
  padding-bottom: 0;
}
.user-details__info-skeleton {
  width: 80%;
  height: 400px;
  align-items: center;
  background-color: #EAEDE1;
  border-radius: 15px;
}
.user-details__perfil {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.user-details__perfil-img-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
  border-radius: 50%;
  overflow: hidden;
  height: 250px;
  background-color: #EAEDE1;
  font-size: 40px;
  box-shadow: 3px 3px 10px 2px #6d6d6d;
}
.user-details__products-tags {
  display: flex;
  align-items: center;
}
.user-details__products-tag {
  align-self: center;
}
.user-details__perfil-name-container {
  background-color: var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
  size: border-box;
  color: #fff;
  padding: 10px 60px;
  border-radius: 15px;
  box-shadow: 3px 3px 10px 2px #6d6d6d;
  transform: translateY(-50%);
}
@media (max-width: 1024px) {
  .user-details__perfil-name-container {
    padding: 10px 40px;
  }
}
.user-details__perfil-name {
  font-weight: bold;
  font-size: 1.2em;
  text-align: center;
}
@media (max-width: 1024px) {
  .user-details__perfil-name {
    font-size: 1em;
  }
}
.user-details__perfil-team-admim-container {
  display: flex;
  justify-content: space-around;
  width: 400px;
}
@media (max-width: 1024px) {
  .user-details__perfil-team-admim-container {
    flex-direction: column;
    align-items: center;
    width: 50%;
  }
}
.user-details__perfil-team-admim-container-loading {
  background-color: #EAEDE1;
  border-radius: 15px;
  height: 40px;
}
.user-details__perfil-team-adm {
  color: var(--primary-color);
  font-size: 1.2em;
  font-weight: bold;
}
@media (max-width: 1024px) {
  .user-details__perfil-team-adm {
    font-size: 1em;
  }
}
.user-details__info {
  display: flex;
  justify-content: center;
  margin-bottom: 4em;
}
.user-details__info-tabs {
  width: 80%;
}
.user-details__info-tabs-header {
  color: var(--primary-color);
  font-weight: bold;
  font-size: 1.2em;
}
.user-details__info-tabs-content {
  display: flex;
  width: 100%;
  border-bottom: solid 1px var(--primary-color);
}
@media (max-width: 1024px) {
  .user-details__info-tabs-content {
    flex-direction: column;
  }
}
.user-details__info-tabs-label {
  display: inline-block;
  width: 50%;
  font-size: 1.2em;
  color: var(--primary-color);
}
@media (max-width: 1024px) {
  .user-details__info-tabs-label {
    display: block;
    width: 100%;
  }
}
.user-details__info-tabs-information {
  color: var(--primary-color);
  font-size: 1.2em;
  width: 50%;
}
@media (max-width: 1024px) {
  .user-details__info-tabs-information {
    font-size: 1em;
    width: 100%;
  }
}
.user-details__tables {
  display: flex;
  justify-content: center;
  width: 100%;
}
.user-details__table-tabs {
  width: 80%;
}
.user-details__perfil-switch-loading {
  width: 100px;
  height: 30px;
  background-color: #EAEDE1;
  border-radius: 15px;
}
.user-details__perfil-cancel-button {
  background-color: #DD4E4E !important;
  color: white !important;
  height: 24px !important;
  line-height: 0 !important;
  margin-bottom: 15px;
}
.user-details__perfil-cancel-button:focus,
.user-details__perfil-cancel-button:hover {
  border: 1px solid #DD4E4E !important;
}
.user-details-permissions-list__checkbox-list {
  padding: 5px;
  display: flex;
  flex-direction: column;
}
.user-details-permissions-list__permission-description {
  margin-left: 24px;
}
.introduction-main-text {
  font-size: 3.2rem;
  font-family: sans-serif;
}
.introduction-secondary-text {
  font-size: 2.5rem;
}
.introduction-main-width-white {
  max-width: 750px;
  text-shadow: 2px 2px black;
}
.introduction-main-card {
  padding: 3rem 5rem;
  display: flex;
  position: relative;
}
@media screen and (max-width: 576px) {
  .introduction-main-card {
    padding: 2rem;
    display: block;
  }
}
@media screen and (min-width: 768px) {
  .introduction-main-card::after {
    content: "";
    width: 70%;
    height: 100%;
    position: absolute;
    border-left: 2px solid #C2BEBE;
    border-bottom: 2px solid #C2BEBE;
    background-image: linear-gradient(to right bottom, #79be39, #46B452);
    border-bottom-left-radius: 115%;
    top: 0;
    right: 0;
    z-index: 0;
    -webkit-animation: scale-up-hor-right 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: scale-up-hor-right 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }
}
.introduction-first-half {
  flex: 1;
  max-width: 400px;
  z-index: 10;
}
@media screen and (max-width: 576px) {
  .introduction-first-half {
    padding: 10px;
    max-width: auto;
  }
}
.introduction-second-half {
  flex: 2;
  z-index: 10;
}
.introduction-second-half-cards {
  flex: 2;
  z-index: 10;
  justify-content: space-evenly;
}
@media screen and (max-width: 576px) {
  .introduction-second-half-cards {
    display: block;
  }
}
.introduction-second-half-cards .introduction-main-text {
  font-size: 3.2rem;
  font-family: sans-serif;
}
.introduction-second-half-cards .introduction-secondary-text {
  font-size: 2.5rem;
}
.introduction-second-half-cards .introduction-main-width-white {
  max-width: 750px;
  text-shadow: 2px 2px black;
}
.introduction-second-half-cards .introduction-main-card {
  padding: 3rem 5rem;
  display: flex;
  position: relative;
}
@media screen and (max-width: 576px) {
  .introduction-second-half-cards .introduction-main-card {
    padding: 2rem;
    display: block;
  }
}
@media screen and (min-width: 768px) {
  .introduction-second-half-cards .introduction-main-card::after {
    content: "";
    width: 70%;
    height: 100%;
    position: absolute;
    border-left: 2px solid #C2BEBE;
    border-bottom: 2px solid #C2BEBE;
    background-image: linear-gradient(to right bottom, #79be39, #46B452);
    border-bottom-left-radius: 115%;
    top: 0;
    right: 0;
    z-index: 0;
    -webkit-animation: scale-up-hor-right 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: scale-up-hor-right 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }
}
.introduction-second-half-cards .introduction-first-half {
  flex: 1;
  max-width: 400px;
  z-index: 10;
}
@media screen and (max-width: 576px) {
  .introduction-second-half-cards .introduction-first-half {
    padding: 10px;
    max-width: auto;
  }
}
.introduction-second-half-cards .introduction-second-half {
  flex: 2;
  z-index: 10;
}
.introduction-second-half-cards .introduction-second-half-cards {
  flex: 2;
  z-index: 10;
  justify-content: space-evenly;
}
@media screen and (max-width: 576px) {
  .introduction-second-half-cards .introduction-second-half-cards {
    display: block;
  }
}
.introduction-second-half-cards .introduction-card {
  box-sizing: border-box;
  min-height: 150px;
  padding: 30px 45px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.introduction-second-half-cards .introduction-card:hover {
  border: 1px solid #00441F;
  box-shadow: 0 8px 16px 0 rgba(170, 187, 187, 0.5);
  transition: 0.25s;
}
@media screen and (max-width: 576px) {
  .introduction-second-half-cards .introduction-card {
    display: block;
    max-width: 100%;
    margin-bottom: 30px;
  }
}
.introduction-second-half-cards .introduction-card-margin {
  margin-left: 5rem;
}
.introduction-second-half-cards .introduction-image {
  padding-left: 3rem;
  min-width: 400px;
  min-height: 450px;
}
.introduction-second-half-cards .introduction-icon {
  height: 150px;
}
@media screen and (max-width: 1366px) {
  .introduction-second-half-cards .introduction-image {
    padding-left: 0;
  }
}
@media screen and (max-width: 1024px) {
  .introduction-second-half-cards .introduction-secondary-text {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .introduction-second-half-cards .introduction-second-half {
    display: none;
  }
}
.introduction-second-half-cards .introduction-main-text {
  font-size: 3.2rem;
  font-family: sans-serif;
}
.introduction-second-half-cards .introduction-secondary-text {
  font-size: 2.5rem;
}
.introduction-second-half-cards .introduction-main-width-white {
  max-width: 750px;
  text-shadow: 2px 2px black;
}
.introduction-second-half-cards .introduction-main-card {
  padding: 3rem 5rem;
  display: flex;
  position: relative;
}
@media screen and (max-width: 576px) {
  .introduction-second-half-cards .introduction-main-card {
    padding: 2rem;
    display: block;
  }
}
@media screen and (min-width: 768px) {
  .introduction-second-half-cards .introduction-main-card::after {
    content: "";
    width: 70%;
    height: 100%;
    position: absolute;
    border-left: 2px solid #C2BEBE;
    border-bottom: 2px solid #C2BEBE;
    background-image: linear-gradient(to right bottom, #79be39, #46B452);
    border-bottom-left-radius: 115%;
    top: 0;
    right: 0;
    z-index: 0;
    -webkit-animation: scale-up-hor-right 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: scale-up-hor-right 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }
}
.introduction-second-half-cards .introduction-first-half {
  flex: 1;
  max-width: 400px;
  z-index: 10;
}
@media screen and (max-width: 576px) {
  .introduction-second-half-cards .introduction-first-half {
    padding: 10px;
    max-width: auto;
  }
}
.introduction-second-half-cards .introduction-second-half {
  flex: 2;
  z-index: 10;
}
.introduction-second-half-cards .introduction-second-half-cards {
  flex: 2;
  z-index: 10;
  justify-content: space-evenly;
}
@media screen and (max-width: 576px) {
  .introduction-second-half-cards .introduction-second-half-cards {
    display: block;
  }
}
.introduction-second-half-cards .introduction-card {
  box-sizing: border-box;
  min-height: 150px;
  padding: 30px 45px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.introduction-second-half-cards .introduction-card:hover {
  border: 1px solid #00441F;
  box-shadow: 0 8px 16px 0 rgba(170, 187, 187, 0.5);
  transition: 0.25s;
}
@media screen and (max-width: 576px) {
  .introduction-second-half-cards .introduction-card {
    display: block;
    max-width: 100%;
    margin-bottom: 30px;
  }
}
.introduction-second-half-cards .introduction-card-margin {
  margin-left: 5rem;
}
.introduction-second-half-cards .introduction-image {
  padding-left: 3rem;
  min-width: 400px;
  min-height: 450px;
}
.introduction-second-half-cards .introduction-icon {
  height: 150px;
}
@media screen and (max-width: 1366px) {
  .introduction-second-half-cards .introduction-image {
    padding-left: 0;
  }
}
@media screen and (max-width: 1024px) {
  .introduction-second-half-cards .introduction-secondary-text {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .introduction-second-half-cards .introduction-second-half {
    display: none;
  }
}
.introduction-second-half-cards .jello-horizontal {
  -webkit-animation: jello-horizontal 0.9s both;
  animation: jello-horizontal 0.9s both;
}
@-webkit-keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
.introduction-second-half-cards .scale-up-hor-right {
  -webkit-animation: scale-up-hor-right 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: scale-up-hor-right 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
@-webkit-keyframes scale-up-hor-right {
  0% {
    -webkit-transform: scaleX(0.4);
    transform: scaleX(0.4);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }
}
@keyframes scale-up-hor-right {
  0% {
    -webkit-transform: scaleX(0.4);
    transform: scaleX(0.4);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }
}
.introduction-second-half-cards .slide-in-right {
  -webkit-animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
.introduction-card {
  box-sizing: border-box;
  min-height: 150px;
  padding: 30px 45px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.introduction-card:hover {
  border: 1px solid #00441F;
  box-shadow: 0 8px 16px 0 rgba(170, 187, 187, 0.5);
  transition: 0.25s;
}
@media screen and (max-width: 576px) {
  .introduction-card {
    display: block;
    max-width: 100%;
    margin-bottom: 30px;
  }
}
.introduction-card-margin {
  margin-left: 5rem;
}
.introduction-image {
  padding-left: 3rem;
  min-width: 400px;
  min-height: 450px;
}
.introduction-icon {
  height: 150px;
}
@media screen and (max-width: 1366px) {
  .introduction-image {
    padding-left: 0;
  }
}
@media screen and (max-width: 1024px) {
  .introduction-secondary-text {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .introduction-second-half {
    display: none;
  }
}
.jello-horizontal {
  -webkit-animation: jello-horizontal 0.9s both;
  animation: jello-horizontal 0.9s both;
}
@-webkit-keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes jello-horizontal {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1);
  }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1);
  }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1);
  }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1);
  }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
.scale-up-hor-right {
  -webkit-animation: scale-up-hor-right 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: scale-up-hor-right 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
@-webkit-keyframes scale-up-hor-right {
  0% {
    -webkit-transform: scaleX(0.4);
    transform: scaleX(0.4);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }
}
@keyframes scale-up-hor-right {
  0% {
    -webkit-transform: scaleX(0.4);
    transform: scaleX(0.4);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }
  100% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
  }
}
.slide-in-right {
  -webkit-animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
.avaliation-card__form {
  display: flex;
  align-items: flex-end;
  gap: 0.5rem;
}
.avaliation-card__item {
  position: relative;
}
.avaliation-card__checkbox {
  z-index: 2;
}
.unauth-proposal {
  background-color: #E0E5CF;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
.proposal-validity-modal__header {
  display: flex;
  align-items: center;
  padding-bottom: 0.5rem;
}
.proposal-validity-modal__title {
  font-weight: 600;
}
.proposal-validity-modal__content {
  margin: 0.5rem 0 1rem 0;
}
.zip-documents-log-modal__text-container {
  margin-bottom: 2.5rem;
}
.zip-documents-log-modal__title {
  margin-bottom: 0;
}
.zip-documents-log-modal__link {
  text-decoration: underline;
}
.zip-documents-log-modal__description {
  margin-bottom: 0.75rem;
}
.zip-documents-log-modal__icon-container {
  border-radius: 50%;
  padding: 5px;
  width: 25px;
  height: 25px;
}
.zip-documents-log-modal__icon-container svg {
  color: white;
}
.zip-documents-log-modal__error {
  color: var(--red-status);
}
.zip-documents-log-modal__help-text {
  max-width: 43.75rem;
}
.zip-documents-log-modal__help-title {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 2px;
}
.zip-documents-download-modal__title {
  font-size: 1.3rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
}
@media only screen and (max-width: 576px) {
  .zip-documents-download-modal__buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.zip-documents-download-modal__direct-download {
  margin-right: 0.5rem;
}
@media only screen and (max-width: 576px) {
  .zip-documents-download-modal__direct-download {
    margin-right: 0;
    margin-bottom: 0.5rem;
  }
}
.department-select {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: space-between;
  margin-bottom: 10px;
}
.department-select__filters {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  justify-content: space-between;
  gap: 10px;
}
@media only screen and (max-width: 768px) {
  .department-select__filters {
    display: flex;
    flex-direction: column;
  }
}
.department-select__icon-container {
  background: #c3ffdf;
  border-radius: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 5px;
  width: min-content;
}
.department-select__filters-buttons {
  display: flex;
  gap: 10px;
}
.department-select__button {
  border-color: #00441F;
}
@media only screen and (max-width: 768px) {
  .department-select__button {
    width: 100%;
  }
}
.department-select__menu-button {
  border-radius: 0 !important;
  border-bottom: 0;
  width: 100%;
}
.department-select__menu-item {
  margin: 0 !important;
  padding: 0 !important;
}
.department-select__menu-item:first-child {
  border-radius: 12px 12px 0 0 !important;
}
.department-select__menu-item:last-child {
  border-radius: 0 0 12px 12px !important;
}
.department-select__menu {
  border-radius: 12px;
}
.department-select__status-select {
  width: 200px;
}
.department-select__status-select .ant-select-selector {
  background: #00441F !important;
  border: 0 !important;
  color: #fff !important;
  text-align: center !important;
}
.department-select__status-select .ant-select-selector .ant-select-selection-placeholder {
  color: #fff !important;
}
.department-select__status-select .ant-select-arrow {
  color: #fff !important;
}
@media only screen and (max-width: 768px) {
  .department-select__status-select {
    width: 100% !important;
  }
}
.department-select__status-color {
  border-radius: 100%;
  display: block;
  height: 8px;
  width: 8px;
}
.department-select__status-label {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 5px;
}
.select-level-modal .ant-modal-body {
  padding: 30px;
  max-height: 80vh;
  overflow: auto;
}
.select-level-modal__icon-container {
  margin-bottom: 30px;
  text-align: center;
}
.select-level-modal__icon-container svg {
  width: 160px;
}
.select-level-modal__text-container {
  margin-bottom: 30px;
  text-align: center;
}
.select-level-modal__title {
  font-size: 24px;
  margin-bottom: 5px;
  font-weight: bold;
}
@media only screen and (max-width: 576px) {
  .select-level-modal__title {
    font-size: 18px;
  }
}
.select-level-modal__subtitle {
  font-size: 16px;
  color: #5B5B5B;
}
@media only screen and (max-width: 576px) {
  .select-level-modal__subtitle {
    font-size: 12px;
  }
}
.select-level {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.select-level__button {
  display: flex;
  gap: 15px;
  text-align: left;
  border-radius: 20px;
  background-color: #fff;
  outline: none;
  border: 1px solid #DFDFDF;
  padding: 10px;
  cursor: pointer;
  transition: all 0.2s;
}
.select-level__button:hover {
  background-color: #f2f2f2;
}
.select-level__level {
  font-weight: bold;
  color: var(--primary-color);
}
.select-level__icon-container {
  display: flex;
}
.select-level__icon-container svg {
  width: 42px;
  height: 42px;
  color: var(--primary-color);
}
.select-level__icon {
  align-self: center;
  flex: 1;
  text-align: right;
}
.card-pricing-hover:hover {
  transition: 0.7s;
  border: 1px solid #00441F;
  -webkit-box-shadow: 0px 0px 22px 4px rgba(0, 0, 0, 0.13);
  box-shadow: 0px 0px 22px 4px rgba(0, 0, 0, 0.13);
}
.pricing-products-card {
  width: 120px;
  height: 120px;
}
.operation-limit__container--fill {
  max-width: 100%;
}
.operation-limit__title {
  color: #000000;
  font-weight: 700;
  font-size: 1rem;
}
.operation-limit__value-title {
  text-align: left;
}
.operation-limit__max-value-label {
  color: #65737a !important;
  font-size: 10px !important;
}
.operation-limit__skeleton {
  width: 100%!important;
}
.review_form {
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100% !important;
}
.review_form__infos {
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
}
.review_form__infos__item {
  color: #00441F;
  font-weight: bold;
}
.review_form__inputs {
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 3rem;
  width: 100%;
}
.review_form__inputs__item {
  margin-right: 10px;
  width: 100%;
}
.review_form__reason-placement {
  display: flex !important;
  flex-direction: row;
  width: 100% !important;
  overflow-wrap: break-word;
}
.review_form__reason {
  width: 100% !important;
  margin-top: 0.5rem;
  margin-left: 5px;
  overflow-wrap: break-word;
}
.review_form__label {
  color: #00441F;
  font-weight: bold;
}
.review_form__button {
  margin-top: 2rem;
}
.fee-drawer .ant-drawer-content-wrapper {
  width: 35% !important;
}
@media (max-width: 992px) {
  .fee-drawer .ant-drawer-content-wrapper {
    width: 75% !important;
  }
}
@media (max-width: 576px) {
  .fee-drawer .ant-drawer-content-wrapper {
    width: 100% !important;
  }
}
.client-card-list__header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 30px;
}
.client-card-list__header-tittle {
  color: var(--primary-color);
  display: block;
  font-weight: bold;
  border-bottom: var(--primary-color) 1px solid;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.client-card-list__header-search {
  width: 80%;
}
@media (max-width: 1200px) {
  .client-card-list__header-search {
    width: 100%;
  }
}
.client-card-list__header-paragraph {
  font-size: 1.5em;
  margin-bottom: 0;
  color: var(--primary-color);
  margin-bottom: 30px;
}
@media (max-width: 768px) {
  .client-card-list__header-paragraph {
    font-size: 1.2em;
    max-width: 100%;
    text-align: center;
  }
}
.client-card-list__clients-list-grid {
  margin-top: 40px;
  width: 80%;
  display: grid;
  gap: 25px;
  grid-template-columns: repeat(auto-fill, calc(25% - 20px)) calc(25% - 25px);
}
@media (max-width: 1200px) {
  .client-card-list__clients-list-grid {
    grid-template-columns: repeat(auto-fill, calc(50% - 20px)) calc(50% - 25px);
    width: 100%;
  }
}
@media (max-width: 768px) {
  .client-card-list__clients-list-grid {
    grid-template-columns: 100%;
  }
}
.client-card-list__clients-link:hover {
  transition: 0.3s ease-in-out;
  filter: brightness(90%);
}
.client-card-list__clients-card {
  transition: 200ms;
  width: 100%;
  height: 19rem;
  border-radius: 33px;
  box-shadow: 3px 3px 10px 2px #929292;
}
.client-card-list__clients-card:hover {
  transition: 0.2s ease-in-out;
  transform: scale(1.02);
}
@media (max-width: 768px) {
  .client-card-list__clients-card {
    height: 19rem;
  }
}
.client-card-list__clients-name {
  color: var(--primary-color);
  font-weight: 800;
}
.client-card-list__clients-name--skeleton {
  background-color: #e2e2e2;
  height: 25px;
  width: 100%;
  border-radius: 12px;
}
.client-card-list__clients-selfie {
  max-width: 100%;
  object-fit: cover;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
  border-radius: 33px 33px 0px 0px !important;
}
.client-card-list__clients-selfie--skeleton {
  background-color: #dadada;
  width: 100%;
  height: 200px;
  border-radius: 33px 33px 0px 0px !important;
}
.client-card-list__clients-cpf--skeleton {
  background-color: #dadada;
  height: 25px;
  width: 70%;
  border-radius: 12px;
}
.client-card-list__pagination {
  width: 80%;
  display: flex;
  justify-content: center;
}
@media (max-width: 1200px) {
  .client-card-list__pagination {
    width: 100%;
  }
}
.change-email__title {
  font-weight: bold;
  text-align: center;
  color: #00441F;
}
.change-email__icon {
  font-size: 3rem;
  color: #00441F;
}
.change-email__header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 1.5rem;
}
.change-email__content {
  margin: 0 2rem;
}
.change-email__subtitle {
  font-size: 1.1rem;
  text-align: center;
}
.change-email__current-email {
  font-weight: bold;
}
.change-email__token-content {
  margin: 1rem;
}
.change-phone__title {
  font-weight: bold;
  text-align: center;
  color: #00441F;
}
.change-phone__icon {
  font-size: 3rem;
  color: #00441F;
}
.change-phone__header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 1.5rem;
}
.change-phone__content {
  margin: 0 2rem;
}
.change-phone__subtitle {
  font-size: 1.1rem;
  text-align: center;
}
.change-phone__current-phone {
  font-weight: bold;
}
.change-phone__token-content {
  margin: 1rem;
}
.profile {
  position: relative;
}
.profile__selfie {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  align-items: center;
}
.profile__info {
  border-left: 1px solid #ddd;
  padding: 1rem 0 1rem 1.5rem;
  display: flex;
  flex-direction: column;
}
.profile__section-title {
  color: #00441F;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
}
.profile__buttons {
  display: flex;
  justify-content: center;
}
.profile__change-email-button {
  margin: 0 0.5rem;
}
.profile__change-telephone-button {
  margin: 0.5rem;
}
.profile__avatar > .ant-upload {
  display: flex;
  justify-content: center;
  width: 170px;
  height: 170px;
  border: 1px solid #ddd;
  border-radius: 50%;
  align-items: center;
}
.profile__avatar > .ant-upload span {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.profile__avatar img {
  cursor: pointer;
  border-radius: 50%;
}
@media only screen and (max-width: 992px) {
  .profile__info {
    border: none;
    padding: 0;
  }
}
.client-form__fields {
  margin-top: 10px;
  padding: 1.5rem;
  padding-bottom: 0;
}
.client-form__bottom-divider {
  margin: 0.5rem 0;
}
.client-form__footer {
  padding: 0.5rem 0;
  display: flex;
  justify-content: space-between;
  gap: 1em;
  flex-wrap: wrap;
}
.qualification-container__area--textarea {
  margin-top: 20px;
}
.qualification-container__area--textarea:empty::before {
  content: attr(data-placeholder);
  color: grey;
}
.wrapper-single-line-table {
  border: 2px solid #E0E5CF;
  border-radius: 16px;
}
.wrapper-single-line-table div {
  border-top: 1px solid #E0E5CF;
  padding: 5px;
}
.wrapper-single-line-table div:first-child {
  border-top: transparent;
  border-radius: 16px;
}
.wrapper-single-line-table div:last-child {
  border-bottom: transparent;
  border-radius: 0 0 8px 8px;
}
.wrapper-single-line-table div:nth-child(even) {
  background: #E0E5CF;
}
.wrapper-single-line-table div:nth-child(odd) {
  background: #FFF;
}
.single-line-simulation-title {
  margin-left: 25px;
  margin-top: 15px;
  margin-bottom: 15px;
  padding-left: 10px;
  position: relative;
}
.single-line-simulation-title:before {
  content: "";
  width: 20px;
  height: 2px;
  background-color: #00441F;
  position: absolute;
  bottom: 50%;
  right: 100%;
}
.simulator-resumes-titles {
  font-family: Montserrat;
  font-size: 16px;
  line-height: 33.6px;
  color: #00441F;
  text-transform: uppercase;
  font-weight: 700;
}
.simulator-resume-sub-titles {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
}
.simulator-label {
  color: #00441F;
  font-size: 15px !important;
  font-weight: 700;
}
.simulator-table__modified-padding table td {
  padding: 4px 16px !important;
}
.simulator-table__modified-padding th {
  line-height: 0 !important;
}
.simulator-table .ant-table-pagination-right {
  align-items: center !important;
}
.simulator-table table:first-of-type thead th {
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  text-transform: uppercase;
  color: white;
  background-color: #00441F;
}
.simulator-table table:first-of-type tbody tr {
  color: #00441F;
  font-weight: 700;
}
.simulator-table table:first-of-type tbody tr:nth-child(even) {
  background: #fff;
}
.simulator-table table:first-of-type tbody tr:nth-child(odd) {
  background: #E0E5CF;
}
.simulator-title {
  font-family: Montserrat;
  font-size: 28px;
  line-height: 33.6px;
  color: #00441F;
  text-transform: uppercase;
  font-weight: 700;
}
.simulator-title-baseline {
  font-family: Montserrat;
  font-size: 28px;
  line-height: 33.6px;
  color: #00441F;
  font-weight: 700;
}
.simulator-calculation-radio-button {
  background: #00441F !important;
  min-height: 38px !important;
  font-size: 18px !important;
  color: white !important;
}
.simulator-calculation-radio-button:hover,
.simulator-calculation-radio-button:focus {
  border-color: #00441F !important;
}
.simulator-specialist-radio-button {
  min-height: 38px !important;
  font-size: 18px !important;
}
.simulator-specialist-radio-button:hover {
  border-color: #00441F !important;
  color: #00441F !important;
}
.simulator-informations {
  font-size: 18px;
  line-height: 21.5px;
  text-transform: uppercase;
  margin-bottom: 24px;
  padding-bottom: 0;
  border-bottom: 0;
  width: 100%;
}
.simulator-informations:hover {
  color: #002b13;
}
@media (max-width: 1000px) {
  .simulator-modal {
    width: 100vw !important;
  }
}
.simulator-modal .ant-modal-close-x {
  border-radius: 50%;
  border: 2px solid #00441F;
  color: #00441F;
  width: 48px;
  height: 48px;
  margin: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.simulator-result-label {
  color: #00441F;
  font-size: 15px !important;
  font-weight: 600;
}
.simulator-input {
  height: 50px !important;
  font-size: 16px;
}
.simulator-input .ant-input-number-input-wrap input {
  text-align: center;
}
.simulator-input .ant-input-number-input-wrap .ant-input-number-input {
  height: 38px;
  font-size: 16px;
}
.simulator-input::placeholder {
  color: #00441F;
}
.simulator-input:hover,
.simulator-input:focus {
  border-color: #00441F !important;
  box-shadow: 0px 0px 0px 2px rgba(15, 64, 111, 0.1);
}
.simulator-input.ant-input-number-focused {
  border-color: #00441F !important;
  box-shadow: 0px 0px 0px 2px rgba(15, 64, 111, 0.1);
}
.simulator-inputs .simulator-calculation-button,
.simulator-inputs .simulator-specialist-button {
  font-weight: bold;
}
.simulator-calculation-button {
  background: #00441F !important;
  min-height: 50px !important;
  font-size: 18px !important;
  color: white !important;
}
.simulator-calculation-button:hover,
.simulator-calculation-button:visited,
.simulator-calculation-button:focus {
  border-color: #E0E5CF !important;
}
.simulator-radio {
  display: flex;
  justify-content: center;
  text-align: center;
}
.simulator-radio .ant-radio-wrapper {
  display: flex;
  align-items: center;
}
.simulator-radio .ant-radio-inner {
  height: 25px !important;
  width: 25px !important;
  border-color: #E0E5CF !important;
}
.simulator-radio .ant-radio-inner:hover,
.simulator-radio .ant-radio-inner:focus {
  border-color: #E0E5CF !important;
}
.simulator-radio .ant-radio-checked .ant-radio-inner::after {
  height: 15px !important;
  width: 15px !important;
  background-color: #E0E5CF !important;
  transition: 1ms;
  right: 4px !important;
  bottom: 4px !important;
  top: 4px !important;
  left: 4px !important;
}
.simulator-radio :first-child {
  border-radius: 30px 0 0 30px;
  width: 50%;
}
@media (max-width: 380px) {
  .simulator-radio :first-child {
    font-size: 12px;
  }
}
.simulator-radio :last-child {
  border-radius: 0 30px 30px 0;
  width: 50%;
}
@media (max-width: 380px) {
  .simulator-radio :last-child {
    font-size: 12px;
  }
}
.simulator-radio.ant-radio-group-large .ant-radio-button-wrapper {
  height: 50px !important;
  line-height: 48px !important;
  transition: border-color 0.3s, box-shadow 0.3s !important;
  transition-duration: 0.3s, 0.3s, 0.3s, 0.3s;
  transition-timing-function: ease, ease, ease, ease;
  transition-delay: 0s, 0s, 0s, 0s;
  transition-property: color, background, border-color, box-shadow;
}
.simulator-radio .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child,
.simulator-radio .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  border: none;
}
.simulator-radio .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  background-color: #00441F !important;
  color: #fff !important;
  font-weight: 700;
}
.simulator-radio .ant-radio-button-wrapper {
  background-color: #fff !important;
  color: #00441F !important;
  font-weight: 700;
}
.simulator-specialist-button {
  min-height: 50px !important;
  font-size: 18px !important;
  border-color: #00441F !important;
  border-width: 2px !important;
}
.simulator-specialist-button:hover {
  border-color: #E0E5CF !important;
}
.simulation-tag-input {
  background-color: #FFF4CE;
  color: #00441F !important;
  border-radius: 10px;
  padding: 0px 10px;
  position: absolute;
  font-size: 0.75rem;
  transform: translateY(-50%);
  top: 50%;
  left: 50%;
  margin-bottom: 0px;
}
@media (max-width: 768px) {
  .simulation-tag-input {
    transform: translate(-50%, -50%);
    top: 0px;
    left: 50%;
    white-space: nowrap;
  }
}
.simulation-amortization-tag::after {
  content: 'MAIS UTILIZADA';
  transform: translateX(-50%);
  border-radius: 10px;
  padding: 0 16px;
  line-height: 1rem;
  background-color: #E0E5CF;
  color: #00441F !important;
  font-size: 0.75rem !important;
  position: absolute;
  top: -7.5px;
  left: 50%;
  white-space: pre;
}
@media (max-width: 768px) {
  .simulation-amortization-tag::after {
    display: none;
  }
}
.form-slider-simulator .ant-form-item-label,
.amortizationType-radio .ant-form-item-label {
  text-align: center !important;
}
.simulation-info {
  cursor: pointer;
}
.simulator-deadline-increase {
  font-size: 47.5px;
  color: #00441F !important;
}
.simulator-tooltip-paragraph {
  font-size: 14px;
  color: #3b3d43;
}
.simulator-tooltip-paragraph:last-child {
  margin-bottom: 0;
}
.simulator-card-fields .ant-select.unauth-inputs.ant-select-single.ant-select-show-arrow :hover {
  border-color: #E0E5CF !important;
}
.simulator-card-fields .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #00441F !important;
  box-shadow: 0px 0px 0px 2px rgba(15, 64, 111, 0.1);
}
.simulator-card-loading {
  background-color: rgba(26, 26, 26, 0.36);
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999999;
  pointer-events: none;
}
.simulator-card-fields .ant-form-item-control-input-content .ant-input-affix-wrapper:focus,
.simulator-card-fields .ant-form-item-control-input-content .ant-input-affix-wrapper-focused {
  border-color: #00441F !important;
  box-shadow: 0px 0px 0px 2px rgba(15, 64, 111, 0.1);
}
.simulator-card-fields {
  background-color: white;
  padding: 25px;
  width: 468px;
  border-radius: 3px;
  height: fit-content;
}
@media (max-width: 1024px) {
  .simulator-card-fields {
    width: 100%;
  }
}
.simulator-radio-itens {
  width: 65%;
  display: flex;
  flex-direction: row;
  justify-content: center !important;
}
.agx-report {
  width: 100%;
  gap: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.agx-report__card {
  background-color: #fff;
  border-radius: 20px;
  padding: 2rem 2rem 1rem 2rem;
  gap: 20px;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media only screen and (max-width: 1200px) {
  .agx-report__card {
    justify-content: center;
    width: 75%;
  }
}
@media only screen and (max-width: 768px) {
  .agx-report__card {
    width: 100%;
  }
}
.agx-report__image-container {
  background-color: #00CF7B;
  padding: 15px;
  border-radius: 20px;
}
@media only screen and (max-width: 1200px) {
  .agx-report__image-container {
    display: none;
  }
}
.agx-report__filter-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.agx-report__filter-title {
  font-size: 20px;
  margin-bottom: 20px;
}
.agx-report__radio {
  margin-bottom: 10px;
}
.agx-report__radio--width {
  width: 50%;
}
@media only screen and (max-width: 1200px) {
  .agx-report__radio--width {
    width: 75%;
  }
}
@media only screen and (max-width: 768px) {
  .agx-report__radio--width {
    width: 100%;
  }
}
.agx-report__picker--width {
  width: 50% !important;
}
@media only screen and (max-width: 1200px) {
  .agx-report__picker--width {
    width: 75% !important;
  }
}
@media only screen and (max-width: 768px) {
  .agx-report__picker--width {
    width: 100% !important;
  }
}
.agx-report__text-container {
  width: 16.625rem;
  display: flex;
  flex-direction: column;
}
.agx-report__text-container h1 {
  margin: 0;
}
.agx-report__text-container .ant-collapse-item:last-child {
  border: 0;
}
@media only screen and (max-width: 1200px) {
  .agx-report__text-container {
    width: none;
    justify-content: center;
    align-items: center;
  }
  .agx-report__text-container p {
    text-align: center;
  }
}
.agx-report__select-container {
  display: flex;
  flex-direction: column;
}
.agx-report__icon {
  color: #fff;
  font-size: 4rem;
}
.grecaptcha-modal {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: center;
  padding: 1rem;
  text-align: center;
}
.grecaptcha-modal__img {
  height: 80px;
}
.grecaptcha-modal__title {
  font-size: 1.2rem;
  font-weight: bold;
}
.grecaptcha-modal__description {
  font-size: 0.9rem;
}
.g-recaptcha-bubble-arrow + div {
  position: fixed !important;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}
.signin__cookies-button {
  background-color: transparent;
  border: none !important;
  color: var(--primary-color) !important;
  cursor: pointer;
  display: block !important;
  font-size: 0.8rem;
  margin: auto;
  text-align: center;
  text-decoration: underline;
}
.signin__cookies-button:hover {
  background-color: transparent !important;
  opacity: 0.6;
}
.signin__basic-swam-html p {
  margin-bottom: 10px;
}
.signin__basic-swam-html a {
  color: var(--analysis-status-color);
  text-decoration: underline;
}
.signup-button {
  min-height: 50px;
  text-transform: uppercase;
}
.signup-button span {
  color: white !important;
  font-size: 16px;
  font-weight: 700;
}
.confirmation-email-modal .ant-modal-content {
  border-radius: 20px !important;
}
.confirmation-email-modal .ant-modal-close-x {
  color: #00441F;
  font-size: 25px;
}
.confirmation-email-modal__title-container {
  margin-bottom: 20px;
  text-align: center;
}
.confirmation-email-modal__content-container {
  background: #f2f2f2;
  margin: 20px 0;
  padding: 10px;
}
.confirmation-email-modal__title {
  font-weight: bold;
  color: #00441F;
  margin-bottom: 8px;
}
.confirmation-email-modal__enter-link {
  font-size: 14px;
}
.confirmation-email-modal__title {
  font-size: 28px;
  color: #00441F !important;
}
.confirmation-email-modal__text {
  font-size: 16px;
  color: #00441F !important;
  width: 65%;
}
.confirmation-email-modal__link {
  color: #00441F !important;
  font-weight: bold;
  text-decoration: underline !important;
}
.confirmation-email-modal__button {
  width: 25%;
}
.confirmation-email-modal__button--confirm {
  background: #00441F !important;
  min-height: 50px !important;
  font-size: 18px !important;
  color: white !important;
  margin-right: 10px;
}
.confirmation-email-modal__button--cancel {
  min-height: 50px !important;
  font-size: 18px !important;
  border-color: #00441F !important;
  border-width: 2px !important;
  margin-left: 10px;
}
.confirmation-email-modal__button span {
  font-weight: 700;
}
.pre-signup {
  margin: auto;
  text-align: center;
  padding: 1rem;
}
@media only screen and (max-width: 768px) {
  .pre-signup {
    max-width: 100%;
    padding: 0.5rem;
  }
}
.pre-signup__title {
  font-size: 28px;
  margin-bottom: 3rem;
  color: #00441F;
}
@media only screen and (max-width: 768px) {
  .pre-signup__title {
    font-size: 20px;
    margin-bottom: 2rem;
  }
}
.pre-signup__text-container {
  margin-bottom: 2.5rem;
}
.pre-signup__text {
  font-size: 16px;
  text-align: left;
  color: #00441F;
}
@media only screen and (max-width: 768px) {
  .pre-signup__text {
    font-size: 14px;
  }
}
.pre-signup__link {
  color: #00441F;
  font-weight: 700;
  text-decoration: underline !important;
}
.pre-signup__button {
  min-height: 50px;
  font-size: 18px;
  min-width: 200px;
}
@media only screen and (max-width: 768px) {
  .pre-signup__button {
    min-width: 50%;
  }
}
.signup__title {
  color: #00441F;
  font-weight: bold;
  font-size: 1.6rem;
  margin-bottom: 1.8rem;
}
@media only screen and (max-width: 576px) {
  .signup__title {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
}
.signup__sub-title {
  color: #00441F;
  font-weight: bold;
  font-size: 1.4rem;
  margin-bottom: 1.2rem;
}
@media only screen and (max-width: 576px) {
  .signup__sub-title {
    font-size: 1rem;
    margin-bottom: 0.8rem;
  }
}
.signup__onidata-help {
  display: flex;
  align-items: flex-start;
}
.signup__warning-first-acess-corban {
  background-color: #f2f2f2;
  padding: 1rem;
  border-radius: 20px;
}
.signup__onidata-icon {
  margin-top: 0.5rem;
  margin-right: 0.5rem;
  font-size: 26px;
  color: #00441F;
  opacity: 0.6;
}
@media only screen and (max-width: 576px) {
  .signup__onidata-icon {
    display: none;
  }
}
.signup__link {
  text-decoration: underline !important;
  font-weight: bold;
}
.signup__link:hover {
  text-decoration: underline;
}
.signup__already-have-account {
  font-size: 18px;
  line-height: 21.6px;
}
.credit-modal-wrapper {
  padding: 0;
  margin: 0;
  width: 100%;
}
@media (max-width: 768px) {
  .credit-modal-wrapper {
    width: 100% !important;
  }
}
.credit-approve-modal-title {
  position: relative;
  display: inline-block;
  margin-bottom: 0;
  text-align: center;
}
.credit-approve-modal-title:after {
  content: '';
  margin-top: 10px;
  margin-bottom: 15px;
  display: block;
  margin-left: 50%;
  width: 50%;
  height: 2px;
  left: 50%;
  background-color: #00441F;
  transform: translate(-50%, -50%);
}
.credit-modal-layout {
  width: 50vw !important;
}
.credit-modal-layout .ant-modal-header {
  display: none;
}
.credit-modal-layout .ant-modal-content {
  border-radius: 20px;
}
.credit-modal-layout .ant-modal-close-x {
  color: #00441F;
  font-size: 25px;
}
@media (max-width: 768px) {
  .credit-modal-layout {
    width: 90vw !important;
  }
}
.credit-modal-title {
  font-family: Montserrat;
  font-size: 28px;
  font-weight: 700;
  line-height: 33.6px;
  color: #00441F;
}
.credit-modal-description {
  color: #00441F;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 1em !important;
}
.credit-modal-description-margin {
  margin-bottom: 34px !important;
}
.credit-base-button {
  background: #00441F !important;
  min-height: 50px !important;
  font-size: 18px !important;
  color: white !important;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
  word-break: normal;
}
.credit-base-button:hover,
.credit-base-button:visited,
.credit-base-button:focus {
  border-color: #E0E5CF !important;
}
.credit-ghost-button {
  min-height: 50px !important;
  font-size: 18px !important;
  color: #00441F !important;
  border: 1px solid #00441F !important;
  word-break: normal;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
}
.credit-ghost-button:hover,
.credit-ghost-button:visited,
.credit-ghost-button:focus {
  border-color: #00441F !important;
}
.credit-modal-progress {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #00441F;
  margin: 40px 0 24px 0;
}
.credit-choose-step-render-contionals {
  background-color: transparent !important;
  overflow: auto;
}
.internal-change-email__icon {
  height: 10rem;
}
.internal-change-email__loading {
  font-size: 5rem;
  color: #00441F;
}
.verify-email {
  max-width: clamp(43rem, 30%, 30%) !important;
  min-height: calc(100vh - 128px);
  justify-content: center;
}
@media only screen and (max-width: 576px) {
  .verify-email {
    max-width: 100%;
    justify-content: space-evenly;
  }
}
@media only screen and (max-width: 576px) {
  .verify-email__title {
    font-size: 22px !important;
  }
}
@media only screen and (max-width: 576px) {
  .verify-email__result-text {
    font-size: 16px !important;
  }
}
@media only screen and (max-width: 576px) {
  .verify-email__succes-image {
    max-height: 200px;
  }
}
.verify-email__error-image {
  min-height: 300px;
  width: 100%;
}
@media only screen and (max-width: 576px) {
  .verify-email__error-image {
    max-height: 200px;
    min-height: initial;
  }
}
.verify-email__arrow-down {
  font-size: 2rem;
  color: #00441F;
  margin: 1rem;
}
.verify-email__instruction {
  text-align: center;
  font-size: 1.2rem;
}
.verify-email__try-again-button {
  border-color: #DD4E4E;
  color: #DD4E4E;
  transition: all 0.2s ease-in-out;
}
.verify-email__try-again-button:hover {
  color: #fff;
  background-color: #DD4E4E;
  border-color: #DD4E4E;
}
@media (min-width: 1200px) {
  .progress-card {
    padding: 0 20px;
  }
}
@media (max-width: 1200px) {
  .progress-card {
    padding: 20px 0;
  }
}
.dashboard-areas-card {
  border-radius: 15px;
  background-color: white;
  -webkit-box-shadow: 0px 3px 6px #272D3B33;
  box-shadow: 0px 3px 6px #272D3B33;
}
.dashboard-areas-card .ant-progress-circle-gradient {
  width: 80px !important;
  height: 80px !important;
  font-size: 15px !important;
}
.mlm-proposals-border-align {
  margin-left: 25px;
  margin-right: 25px;
}
@media (max-width: 1280px) {
  .mlm-proposals-border-align {
    margin-left: 14px;
  }
}
@media (max-width: 1199px) {
  .mlm-proposals-border-align {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .mlm-card-padding {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .mlm-margin-proposal {
    margin-top: 15px;
  }
  .dashboard-border-right,
  .dashboard-border-left,
  .dashboard-border-bottom,
  .dashboard-border-top {
    border: 0 !important;
  }
}
.dashboard-border-top {
  border-top: 1px solid;
  border-color: rgba(192, 192, 192, 0.4);
}
.dashboard-border-bottom {
  border-bottom: 1px solid;
  border-color: rgba(192, 192, 192, 0.4);
}
.dashboard-border-left {
  border-left: 1px solid;
  border-color: rgba(192, 192, 192, 0.4);
}
.dashboard-border-right {
  border-right: 1px solid;
  border-color: rgba(0, 0, 0, 0.5);
}
.mlm-product-select {
  padding: 7.5px;
}
@media (max-width: 991px) {
  .mlm-product-select {
    padding: 0 !important;
  }
}
@media (max-width: 1024px) {
  .dashboard-border-right,
  .dashboard-border-left,
  .dashboard-border-bottom,
  .dashboard-border-top {
    border: 0;
  }
  .mlm-proposals-border-align {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .mlm-card-padding {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .mlm-margin-proposal {
    margin-top: 15px;
  }
}
.mlm-fitlers {
  margin-bottom: 15px !important;
}
.dashboard-status-card {
  border-radius: 20px;
  padding: 10px 20px;
  background-color: white !important;
  -webkit-box-shadow: 0px 3px 6px #272D3B33;
  box-shadow: 0px 3px 6px #272D3B33;
}
.dashboard-status-card__proposal-history {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dashboard-status-card-square {
  padding: 10px 20px;
  min-height: 145px;
  background-color: white !important;
  -webkit-box-shadow: 0px 3px 6px #272D3B33;
  box-shadow: 0px 3px 6px #272D3B33;
}
@media only screen and (min-width: 992px) {
  .dashboard-status-card-square .ant-col {
    padding: 1rem !important;
  }
  .dashboard-status-card-square .ant-col:nth-child(-n+3) {
    border-bottom: 1.2px solid #cbcfdb !important;
  }
  .dashboard-status-card-square .ant-col:nth-child(1),
  .dashboard-status-card-square .ant-col:nth-child(2),
  .dashboard-status-card-square .ant-col:nth-child(4) {
    border-right: 1.2px solid #cbcfdb !important;
  }
}
@media only screen and (min-width: 1200px) {
  .dashboard-status-card-square .ant-col {
    padding: 0 15px !important;
  }
  .dashboard-status-card-square .ant-col:nth-child(-n+4):not(:last-child) {
    border-right: 1.2px solid #cbcfdb !important;
    border-bottom: none !important;
  }
}
.dashboard-status-card-height {
  font-family: 'Montserrat', sans-serif;
  min-height: 165px;
}
.dashboard-status-card-half {
  min-height: 123.5px;
}
.dashboard-card-title {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600 !important;
  font-size: 1.2em !important;
  color: #00441F !important;
}
.dashboard-card-title-areas {
  font-family: 'Montserrat', sans-serif;
  font-weight: normal !important;
  font-size: 1.18em !important;
  color: #00441F !important;
}
@media (min-width: 1024px) {
  .dashboard-card-title-areas {
    word-break: break-word;
  }
}
.rotate-360-vertical-fwd {
  -webkit-animation: rotate-90-vertical-fwd 4s linear infinite;
  animation: rotate-90-vertical-fwd 4s linear infinite;
}
@-webkit-keyframes rotate-90-vertical-fwd {
  0% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
  }
  100% {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}
@keyframes rotate-90-vertical-fwd {
  0% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
  }
  100% {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}
.dashboard-leads-funnel {
  width: 120px;
  height: 120px;
  position: relative;
  transform-style: preserve-3d;
  clip-path: polygon(50% 100%, 0 0, 100% 0);
}
.dashboard-leads-funnel-generate {
  position: absolute;
  border-top: 1px solid white;
  clip-path: polygon(50% 100%, 0 0, 100% 0);
  bottom: 0;
}
.dashboard-card-title-letter-spacing {
  font-family: 'Montserrat', sans-serif;
  color: #00441F !important;
  text-transform: uppercase;
  font-size: 1.1em !important;
  font-weight: bold !important;
  letter-spacing: 3px;
}
.dashboard-card-number {
  margin: 0 !important;
  font-size: 1.8em !important;
  font-family: 'Montserrat', sans-serif;
}
.dashboard-icon-size {
  font-size: 3.5em;
}
.dashboard-icon-size-mini {
  font-size: 2.5em;
}
.dashboard-status-card-main-text {
  margin-bottom: 3px;
  text-transform: uppercase;
}
.dashboard-status-card-title {
  font-size: 1.4rem;
  margin-bottom: 3px;
  text-transform: uppercase;
}
@media (max-width: 1281px) {
  .dashboard-status-card-title {
    font-size: 1.2rem;
  }
}
.button-send-divulgation {
  text-transform: uppercase;
  margin-top: 30px;
  width: 25%;
}
.divulgation-card {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}
.divulgation-icon {
  font-size: 20px;
  color: '';
}
.divulgation-download {
  border: 1px solid #00CF7B;
  border-radius: 15px;
  background-color: #fff;
}
.divulgation-download .divulgation-icon {
  font-size: 18px;
  color: #00CF7B;
}
.divulgation-download:hover {
  transition: 200ms;
  background-color: #00CF7B;
}
.divulgation-download:hover .divulgation-icon {
  color: #fff;
}
.divulgation-edit {
  border: 1px solid #00A8E2;
  border-radius: 15px;
  margin: 0 10px;
  background-color: #fff;
}
.divulgation-edit .divulgation-icon {
  font-size: 18px;
  color: #00A8E2;
}
.divulgation-edit:hover {
  transition: 200ms;
  background-color: #00A8E2;
}
.divulgation-edit:hover .divulgation-icon {
  color: #fff;
}
.divulgation-delete {
  border: 1px solid #DD4E4E;
  border-radius: 15px;
  background-color: #fff;
}
.divulgation-delete .divulgation-icon {
  font-size: 18px;
  color: #DD4E4E;
}
.divulgation-delete:hover {
  transition: 200ms;
  background-color: #DD4E4E;
}
.divulgation-delete:hover .divulgation-icon {
  color: #fff;
}
@media (max-width: 1275px) {
  .divulgation-card {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 1135px) {
  .divulgation-card {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 769px) {
  .divulgation-card {
    grid-template-columns: repeat(1, 1fr);
  }
}
.docs-nav {
  overflow-y: scroll;
  position: fixed;
  bottom: 0;
  right: 0;
  height: calc(100vh - 64px);
  background-color: #FAFAFA;
  padding: 1rem;
  width: 25%;
}
@media only screen and (max-width: 576px) {
  .docs-nav {
    display: none;
  }
}
.docs-nav--show {
  display: block;
  bottom: 0;
  right: 0;
  width: 100%;
  height: calc(100vh - 104px);
}
.docs-nav__topic-title {
  padding: 0.3rem 0.5rem !important;
  border-radius: 10px !important;
  height: 0.5rem !important;
}
.docs-nav__topic-title:hover {
  background-color: #f2f2f2;
}
.docs-nav--active {
  background-color: #f2f2f2;
  border-radius: 10px;
  padding: 0.3rem 0.5rem;
}
.docs-nav li {
  list-style: none;
}
.docs {
  padding: 0 1rem;
}
.docs__main-content {
  display: flex;
}
.docs__show-nav {
  position: fixed;
  bottom: 5rem;
  z-index: 200;
  right: 10%;
}
.docs__header {
  font-weight: bolder;
  color: #00441F;
  font-size: 2rem;
}
.docs__show-nav {
  display: none;
}
@media only screen and (max-width: 576px) {
  .docs__show-nav {
    display: block;
  }
}
.docs__container {
  width: 70%;
  padding: 1rem;
}
@media only screen and (max-width: 576px) {
  .docs__container {
    width: 100%;
  }
}
.docs__container h2,
.docs__container h1 {
  color: #00441F;
  font-weight: 600;
  font-size: 1.5rem;
}
.docs__container h2 {
  padding-top: 0.5rem;
  font-size: 1.2rem;
}
.chat-header__error-message {
  color: var(--red-status);
}
.chat-header__input-skeleton {
  min-width: 9.375rem;
  height: 1.25rem !important;
  border-radius: 0.3125rem !important;
}
.generic-chat {
  display: flex;
  height: calc(calc(100vh - 64px) - 64px - 15px * 2 - 38px - 8px * 2);
  margin: auto 0;
  border-radius: 20px;
}
.generic-chat__create-chat {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 10px;
}
.generic-chat__new-message {
  font-size: 15px !important;
  font-weight: bold;
  color: var(--primary-color);
  margin: 0;
  padding: 0;
  text-align: center;
}
.generic-chat__plus-icon {
  transition: 200ms;
  text-align: center;
  background: #fff;
  height: 25px;
  width: 25px;
  border-radius: 5px;
}
.generic-chat__plus-icon svg {
  margin-bottom: -1px;
  color: var(--primary-color);
  font-size: 17px;
}
.generic-chat__plus-icon:hover,
.generic-chat__plus-icon:focus {
  cursor: pointer;
  opacity: 0.8;
}
.generic-chat__search {
  width: 100%;
  margin-bottom: 15px;
}
.generic-chat__search-input {
  margin-bottom: 10px;
}
.generic-chat__search-input .ant-input {
  z-index: 0 !important;
  width: calc(100% + 45px);
  border-radius: 12px !important;
}
.generic-chat__search-input .ant-input-group-addon {
  background: transparent !important;
}
.generic-chat__search-input .ant-input-group-addon .ant-btn,
.generic-chat__search-input .ant-input-group-addon .ant-btn-icon-only,
.generic-chat__search-input .ant-input-group-addon .ant-input-search-button {
  border: none !important;
  background: transparent !important;
}
.generic-chat__search-input .ant-input-group-addon .ant-btn svg,
.generic-chat__search-input .ant-input-group-addon .ant-btn-icon-only svg,
.generic-chat__search-input .ant-input-group-addon .ant-input-search-button svg {
  z-index: 15;
  color: #484C52;
}
.generic-chat__search-input .ant-input-group-addon .ant-btn svg:hover,
.generic-chat__search-input .ant-input-group-addon .ant-btn-icon-only svg:hover,
.generic-chat__search-input .ant-input-group-addon .ant-input-search-button svg:hover {
  color: var(--primary-color);
}
.generic-chat__search-input .ant-input-search-button:hover {
  background-color: transparent !important;
}
.generic-chat__modal-chat {
  margin-top: -20px;
}
.generic-chat > .messenger__aside {
  position: relative;
}
.generic-chat__aside-new-message {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 16;
  background-color: #fff;
  border-radius: 25px 0 0 25px;
  height: 100%;
  width: 0;
  overflow: auto;
  overflow-x: hidden;
}
.generic-chat__aside-new-message--active {
  width: 100%;
}
.generic-chat__new-message-info {
  width: 100%;
  margin: 24px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.generic-chat__new-message-info p {
  margin-bottom: 0;
  margin-left: 10px;
}
.generic-chat__new-message-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.generic-chat__new-message-loading {
  margin-bottom: 10px;
  padding-left: 15px;
  width: 100%;
}
.generic-chat__new-message-loading span {
  width: 100% !important;
}
.generic-chat__new-message-select {
  width: 100%;
}
.generic-chat__new-message-submit {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.generic-chat__communication-header {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.generic-chat__communication-header--prefix,
.generic-chat__communication-header--suffix {
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 576px) {
  .generic-chat__communication-header--avatar {
    width: 40px;
    height: 40px;
  }
}
.generic-chat__user-info {
  display: flex;
  flex-direction: column;
}
.generic-chat__user-info p {
  margin: 0;
  line-height: 1;
}
.generic-chat__user-info--members {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
}
.generic-chat__user-info--reason {
  display: flex;
  align-items: center;
  gap: 5px;
}
@media only screen and (max-width: 576px) {
  .generic-chat__user-info--name,
  .generic-chat__user-info--reason,
  .generic-chat__user-info--members {
    font-size: 12px;
  }
}
.generic-chat__user-info--name {
  font-weight: bold;
}
.generic-chat__close-chat {
  margin-right: 15px;
  font-size: 24px;
  cursor: pointer;
}
.generic-chat__members-list {
  margin-bottom: 10px;
  font-weight: bold;
  position: relative;
}
.generic-chat__members-list::after {
  content: '';
  background-color: var(--primary-color);
  width: 20px;
  height: 2px;
  position: absolute;
  bottom: 0;
  left: 0;
}
.generic-chat__header {
  display: flex;
  justify-content: center;
  align-items: center;
}
.generic-chat__header-avatar {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  border: 1px solid #fff;
}
@media only screen and (max-width: 576px) {
  .generic-chat__header-avatar {
    display: none;
  }
}
.generic-chat__header-info {
  display: flex;
  padding: 1rem;
  flex-direction: column;
}
.generic-chat__header-members {
  font-size: 0.8rem;
}
.generic-chat__header-user {
  font-size: 1rem;
}
.generic-chat__header-user--helpdesk {
  cursor: pointer;
}
.generic-chat__header-back-to-list {
  display: none;
}
@media only screen and (max-width: 992px) {
  .generic-chat__header-back-to-list {
    display: inline;
  }
}
.generic-chat__end-chat-icon {
  display: none;
  color: #00441F;
  font-size: 2rem;
}
@media only screen and (max-width: 576px) {
  .generic-chat__end-chat-icon {
    display: inline;
  }
}
.generic-chat__modal-buttons {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
@media only screen and (max-width: 576px) {
  .generic-chat__end-chat {
    display: none;
  }
}
.generic-chat__user-info-main {
  display: flex;
  align-items: center;
  justify-content: center;
}
.generic-chat__user-info-container {
  padding: 0 1rem;
}
.generic-chat__user-info-icon {
  padding: 0.5rem;
}
.generic-chat .messenger__messages-send__input::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.generic-chat .messenger__messages-send__input::-webkit-scrollbar-thumb {
  background-color: #dddddd;
  border-radius: 2px !important;
}
.generic-chat .messenger__messages-send__input::-webkit-scrollbar-thumb:hover {
  background-color: #bbb;
}
.generic-chat .messenger__aside-list::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.generic-chat .messenger__aside-list::-webkit-scrollbar-thumb {
  background-color: #334555;
  border-radius: 2px !important;
}
.generic-chat .messenger__aside-list::-webkit-scrollbar-thumb:hover {
  background-color: #24303A;
}
.generic-chat .messenger__messages-container::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
.generic-chat .messenger__messages-container::-webkit-scrollbar-thumb {
  background-color: #bbb;
  border-radius: 2px !important;
}
.generic-chat .messenger__messages-container::-webkit-scrollbar-thumb:hover {
  background-color: #999;
}
#root .department-section-skeleton__image {
  width: 75px;
  height: 75px;
  border-radius: 10px;
  margin-top: 0.5rem;
}
#root .department-section {
  display: flex;
}
#root .department-section__content {
  flex: 1;
}
#root .department-section__image-wrapper {
  flex: 0 0 200px;
  text-align: center;
}
@media only screen and (max-width: 1200px) {
  #root .department-section__image-wrapper {
    display: none;
  }
}
.department-info-skeleton__title {
  width: min(200px, 100%);
  border-radius: 4px;
  margin-bottom: 5px;
}
.department-info-skeleton__progress-bar-text {
  width: min(200px, 100%);
  border-radius: 4px;
}
.department-info-skeleton__progress-bar-text,
.department-info-skeleton__progress-bar-text .ant-skeleton-input {
  height: 16px;
}
#root .department-info__title {
  color: #00441F;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1.6rem;
  margin-bottom: 0;
  line-height: 30px;
}
#root .department-info__divider {
  margin-top: 0;
  padding-top: 0;
  margin-bottom: 20px;
  border-width: 2px;
  background-color: rgba(97, 97, 97, 0.7);
}
#root .department-info__progress-bar-wrapper {
  display: flex;
  gap: 35px;
}
@media only screen and (max-width: 992px) {
  #root .department-info__progress-bar-wrapper {
    display: block;
    gap: 20px;
  }
}
#root .department-info__progress-bar-text {
  color: #00441F;
  font-weight: 500;
  font-style: normal;
  line-height: 22px;
  font-size: 18px;
  margin-bottom: 0;
}
#root .department-info__progress-bar {
  max-width: 320px;
}
#root .department-info__steps-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.partner-step-button {
  display: flex;
  align-items: center;
  background: transparent;
  outline: none;
  border-radius: 20px;
  border: none;
}
.partner-step-button--enabled {
  cursor: pointer;
}
.partner-step-button--enabled:focus-visible {
  outline: 2px solid #00A8E2;
  outline-offset: -2px;
}
.partner-step-button--enabled:hover > p {
  background-color: #f2f2f2;
}
.partner-step-button__icon {
  margin: 0;
  display: flex;
  position: relative;
  z-index: 1;
}
.partner-step-button__icon svg {
  width: 26px;
  height: 26px;
}
@media only screen and (max-width: 992px) {
  .partner-step-button__icon svg {
    width: 22px;
    height: 22px;
  }
}
.partner-step-button__icon::after {
  content: '';
  position: absolute;
  background-color: #b5b5b5;
  z-index: 0;
  left: 50%;
  top: 100%;
  width: 1px;
  height: 15px;
}
@media only screen and (max-width: 992px) {
  .partner-step-button__icon::after {
    height: 12px;
  }
}
.partner-step-button__name {
  color: #00441F;
  font-size: 0.9rem;
  font-weight: 500;
  border-radius: 20px;
  padding: 0.3rem;
  padding-left: 0.5rem;
  margin-bottom: 0;
}
@media only screen and (max-width: 992px) {
  .partner-step-button__name {
    padding: 0.2rem;
  }
}
@media only screen and (max-width: 992px) {
  .partner-step-button__name {
    font-size: 0.8rem;
  }
}
@media only screen and (max-width: 576px) {
  .partner-step-button__name {
    display: flex;
    flex-wrap: wrap;
  }
}
@media only screen and (max-width: 480px) {
  .partner-step-button__name {
    text-align: start;
  }
}
.partners-flag {
  padding: 1px 2px;
  border-radius: 4px;
  font-size: 0.75rem;
  margin-top: 1px;
  margin-right: 8px;
  margin-bottom: 0px;
  margin-left: 8px;
  color: #fff;
  min-width: 80px;
  max-width: auto;
}
@media only screen and (max-width: 576px) {
  .partners-flag {
    font-size: 0.6rem;
    min-width: 45px;
    max-width: auto;
  }
}
.partners-flag--signature {
  border: 1px solid #00CF7B;
  background-color: #00CF7B;
}
@media only screen and (max-width: 576px) {
  .partners-flag--signature {
    margin-left: 0;
  }
}
.partners-flag--required-step {
  border: 1px solid #E5444E;
  background-color: #E5444E;
}
.partners-tooltip {
  align-self: center;
  color: #E5444E;
  cursor: pointer;
}
:root .partner-step-skeleton {
  display: flex;
  gap: 0.5em;
}
.bacenStep__text {
  position: relative !important;
}
.bacenStep__image {
  width: 40%;
  opacity: 0.35;
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
}
.bacenStep__agreementText {
  font-size: 12px;
}
.form-lgpd__document-upload {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}
.form-lgpd__upload-label {
  margin-bottom: 8px;
}
.review-step {
  margin-top: 15px;
}
@media screen and (max-width: 768px) {
  .review-step {
    padding: 10px;
  }
}
.partner-step {
  display: flex;
  align-items: center;
}
.partner-step:not(:last-child) {
  margin-bottom: 1rem;
}
.partner-step:last-child figure::after {
  content: unset;
}
.choose-minuta__skeleton {
  width: 100% !important;
  margin: 0.5rem 0;
}
.choose-minuta__skeleton > ant-skeleton-button ant-skeleton-button-square {
  width: 100% !important;
}
.partner-line {
  padding: 5px;
  background-color: white;
  border-radius: 20px;
}
.partner-line__caption {
  margin: 15px 0px;
}
.review-description {
  width: 100% !important;
  overflow-wrap: break-word;
}
.review-description__content {
  display: flex;
  flex-direction: column;
}
.review-description__text {
  font-size: 14px;
  font-weight: 500;
}
.review-description__text table {
  width: 100%;
  padding: 5px;
}
.review-description__text td {
  border: 1px solid;
  min-width: 40px;
  padding: 3px;
}
.display-errors-modal__see-errors {
  cursor: pointer;
}
.display-errors-modal__title {
  font-weight: bold;
  color: #00441F;
}
.loading-notary-fees-bar__card {
  padding: 2rem;
  border-radius: 20px;
  background-color: white;
}
.notary-fees__card {
  background-color: white;
  border-radius: 20px;
  padding: 1rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.notary-fees__buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
@media only screen and (max-width: 992px) {
  .notary-fees__buttons {
    width: 100%;
  }
}
@media only screen and (max-width: 992px) {
  .notary-fees__buttons-top {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.notary-fees__title {
  padding: 1rem;
}
.notary-fees__buttons-bottom {
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.notary-fees__card-title {
  font-weight: bold;
  color: #00441F;
}
.notary-fees__icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.notary-fees__icon-bg {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 8.5rem;
  width: 8.5rem;
  border-radius: 12px;
  font-size: 5.6rem;
  background-color: rgba(0, 68, 31, 0.25);
  opacity: 1;
}
@media only screen and (max-width: 992px) {
  .notary-fees__icon-bg {
    display: none;
  }
}
@media only screen and (max-width: 992px) {
  .notary-fees__card-mobile {
    margin-top: 1rem;
  }
}
.notary-fees__download-icon {
  color: #00441F;
  font-size: 1.5rem;
}
.notary-fees__upload-list-item {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
}
.notary-fees__upload-list-item-delete {
  padding: 1rem;
  border-radius: 10px;
  padding: 0.5rem;
}
.notary-fees__upload-list-item-delete:hover {
  background-color: #f2f2f2;
  cursor: pointer;
}
.settings-card {
  background-color: white;
  padding: 2rem;
  border-radius: 20px;
}
.settings-card__title {
  font-weight: bold;
  color: #00441F;
  font-size: 1.5rem;
}
.settings-card__button-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
}
.settings-card__header-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 768px) {
  .settings-card__header-content {
    flex-direction: column;
    text-align: center;
  }
}
.settings-card__header-icon {
  font-size: 4rem;
  color: #00441F;
  padding: 0 4rem 0 2rem;
}
@media only screen and (max-width: 768px) {
  .settings-card__header-icon {
    padding: 1rem 0;
  }
}
.internal-review-settings .tox {
  border-radius: 20px !important;
  border: 1px solid #d9d9d9 !important;
  color: rgba(0, 0, 0, 0.85);
}
.internal-review-settings__empty {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
}
.internal-review-settings__display-text {
  background-color: #f2f2f2;
  padding: 1rem;
  border-radius: 20px;
  list-style-position: inside;
}
.internal-review-settings__text-header {
  display: flex;
  justify-content: space-between;
}
.internal-review-settings__skeleton {
  height: 300px !important;
  width: 100% !important;
  border-radius: 20px;
}
.internal-review-settings__last-modification {
  font-size: 0.7rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.internal-review-settings__history-button {
  width: fit-content;
  margin-top: 0.5rem;
}
.internal-review-settings__footer {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
}
.internal-review-settings__header-title {
  max-width: 80%;
}
.internal-review-settings__deleted {
  color: var(--red-status) !important;
}
.sort-configuration__primary-focus {
  font-weight: bold;
  color: #00441F;
  margin: 0;
}
.sort-configuration__value-label {
  margin: 1rem 0;
}
.sort-configuration__value-show {
  background-color: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border-radius: 30px;
  margin-top: 0.5rem;
}
.sort-configuration__value-container {
  margin-bottom: 1rem;
}
.sort-configuration__radio {
  width: 100%;
}
.sort-configuration__radio .ant-radio-wrapper {
  margin: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
}
.sort-configuration__loading-input .ant-skeleton-button,
.sort-configuration__loading-input .ant-skeleton {
  width: 100%!important;
  display: flex;
  height: 45px!important;
  margin-bottom: 1rem!important;
}
.sort-configuration__radio-item {
  padding: 1rem 0;
}
.proposal-filter__rerequest {
  align-self: flex-end !important;
}
@media only screen and (max-width: 992px) {
  .proposal-filter__rerequest {
    align-self: center !important;
  }
}
.proposal-filter__created-date .ant-form-item-label {
  padding: 0.1rem !important;
}
.proposal-report-modal .ant-modal-content {
  border-radius: 20px !important;
}
.proposal-report-modal__title {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 20px;
}
.proposal-report-modal__subtitle {
  font-size: 18px;
  margin-bottom: 0px;
}
.proposal-report-modal__text {
  font-size: 14px;
}
.proposal-report-modal__ul-list {
  list-style: none;
  padding-left: 10px;
}
.proposal-report-modal__button-content {
  display: flex;
  justify-content: flex-end;
}
.proposal-report-modal__month-filter {
  margin-bottom: 20px;
}
.proposal-report-modal__warning {
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
}
.proposal-report-modal__icon-content {
  margin-top: 15px;
}
.proposal-report-modal__icon-content svg {
  font-size: 25px;
}
.proposal-report-modal__tip-body {
  background-color: #f2f2f2;
  padding: 10px;
  margin-bottom: 20px;
}
.proposal-list-search__filters {
  justify-content: center !important;
}
.proposal-list-search__container {
  text-align: center;
  margin: 40px 0;
}
@media only screen and (max-width: 992px) {
  .proposal-list-search__container {
    margin: 20px 0;
  }
}
.proposal-list-search__title {
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 3px;
  text-decoration: uppercase;
}
.proposal-list-search__description {
  font-size: 14px;
  margin-bottom: 25px;
}
.proposal-list-search__input {
  border-left: none;
  border-radius: 0 20px 20px 0 !important;
  padding-left: 0;
  width: 500px !important;
  box-shadow: none !important;
  opacity: 1 !important;
}
@media only screen and (max-width: 992px) {
  .proposal-list-search__input {
    width: 100% !important;
  }
}
.proposal-list-search__input .ant-btn.ant-input-search-button {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
.proposal-list-search__input .ant-input-wrapper.ant-input-group .ant-input-group-addon:first-child {
  border-radius: 25px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.proposal-list-search__input .ant-checkbox + span {
  padding-right: 0;
}
.proposal-list-search__svg-wrapper {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 50vh;
}
@media only screen and (max-width: 576px) {
  .proposal-list-search__svg-wrapper {
    height: 50vh;
  }
}
.proposal-list-search__search-svg-container {
  height: 200px;
  margin-bottom: 20px;
}
@media only screen and (max-width: 576px) {
  .proposal-list-search__search-svg-container {
    height: 200px;
  }
}
.show-backoffices__title {
  font-weight: bold;
  color: #00441F;
}
.show-backoffices__list {
  margin: 0.5rem;
  background-color: #f2f2f2;
  border-radius: 20px;
  padding: 1rem;
  list-style-position: inside;
}
.population-limit__value {
  display: flex;
  align-items: flex-end;
}
.population-limit__title {
  display: flex;
  justify-content: space-between;
}
.email-config__config-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.email-config__banner-title {
  color: #00441F;
  width: 40%;
}
.email-config__card {
  background-color: white;
  border-radius: 20px;
  padding: 1.5rem;
}
.email-config__title {
  font-weight: bold;
  color: #00441F;
}
.email-config__logs-table-header {
  color: #00441F;
  border-bottom: 1px solid #00441F;
}
.email-config__logs-table-header-item {
  padding: 0.5rem 0;
}
.email-config__logs-table-header-first-item {
  width: 15%;
}
.email-config__logs-table-item {
  width: fit-content;
  white-space: nowrap;
  padding: 0.2rem 0.5rem;
  border-radius: 10px;
}
.email-config__logs-table-property {
  margin: 1rem 0.5rem;
  background-color: #f2f2f2;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.email-config__logs-table-new {
  background-color: rgba(0, 207, 123, 0.19);
}
.email-config__logs-table-old {
  background-color: rgba(255, 106, 106, 0.25);
}
.email-config__form-container {
  display: flex;
  justify-content: center;
}
.email-config__form {
  width: 80%;
}
@media (max-width: 1024px) {
  .email-config__form {
    width: 100%;
  }
}
.email-config__input-label {
  color: #00441F;
  font-weight: bold;
}
.email-config__title-header {
  display: flex;
  justify-content: left;
  padding-bottom: 1rem;
}
.email-config__warning-smtp {
  background-color: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}
.email-config__warning-smtp-icon {
  color: #2196f3;
  padding: 0 0.5rem;
}
.email-config__config-not-finished {
  display: flex;
  align-items: center;
  justify-content: center;
}
.email-config__config-not-finished-icon {
  font-size: 3rem;
  padding: 0 2rem;
  color: #FFC107;
}
.email-config__old-config-column {
  padding: 0 2rem;
}
@media only screen and (max-width: 992px) {
  .email-config__old-config-column {
    display: none;
  }
}
@media only screen and (max-width: 992px) {
  .email-config__mobile-hide {
    display: none;
  }
}
.email-config__title {
  display: flex;
  justify-content: space-between;
}
.SLA-status-circle {
  min-width: 20px;
  min-height: 20px;
  border-radius: 50%;
}
.backoffice-products-button__icon {
  background-color: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  padding: 0;
  margin: 0;
  font: inherit;
  outline: none;
  width: 25px;
  height: 25px;
}
.access-manager-details__detail-wrapper {
  border-bottom: 1px solid black;
}
.access-manager-details__detail-wrapper__label {
  font-size: 0.8rem;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
.access-manager-details__detail-wrapper__value {
  font-size: 0.9rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
.access-manager-details__products-tags-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}
.access-manager-details__tag {
  font-size: 0.5rem;
}
.access-manager-details__collapse {
  background: transparent;
  border: 0px;
}
.access-manager-details__collapse-panel {
  background-color: white;
  border-radius: 14px !important;
  margin-bottom: 8px;
}
.access-manager-client__svg-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
  flex-direction: column;
  gap: 20px;
}
@media only screen and (max-width: 576px) {
  .access-manager-client__svg-wrapper {
    height: 30%;
  }
}
@media only screen and (max-width: 576px) {
  .access-manager-client__search-svg-container {
    height: 50%;
  }
}
.back-office-details__header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
@media only screen and (max-width: 1200px) {
  .back-office-details__header {
    flex-direction: column;
    margin-bottom: 10px;
  }
}
.back-office-details__header-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
@media only screen and (max-width: 1200px) {
  .back-office-details__header-info {
    width: 80%;
  }
}
@media only screen and (max-width: 576px) {
  .back-office-details__header-info {
    width: 65%;
  }
}
@media only screen and (max-width: 1200px) {
  .back-office-details__header-info-container {
    flex-direction: row;
    align-items: center;
    max-width: 100%;
    margin-bottom: 10px;
  }
}
@media only screen and (max-width: 576px) {
  .back-office-details__header-info-container {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
}
.back-office-details__initial-container {
  margin-right: 20px;
  align-items: center;
}
@media only screen and (max-width: 576px) {
  .back-office-details__initial-container {
    margin-right: 0 !important;
    margin-bottom: 10px !important;
  }
}
.back-office-details__header-name-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media only screen and (max-width: 1200px) {
  .back-office-details__header-name-container {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media only screen and (max-width: 576px) {
  .back-office-details__header-name-container {
    align-items: center;
  }
}
.back-office-details__header-name {
  color: #00441F;
  font-size: 1.5rem;
  font-weight: bold;
  margin-right: 20px;
  overflow: hidden;
  display: inline-block;
  word-break: break-all;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
}
@media only screen and (max-width: 1200px) {
  .back-office-details__header-name {
    margin-right: 10px;
    margin-bottom: 5px !important;
  }
}
@media only screen and (max-width: 576px) {
  .back-office-details__header-name {
    margin-right: 0px !important;
    font-size: 1.2rem;
    text-align: center;
  }
}
.back-office-details__header-tags {
  display: flex;
  flex-direction: row;
  gap: 10px;
  white-space: nowrap;
}
@media only screen and (max-width: 1200px) {
  .back-office-details__header-tags {
    margin-bottom: 10px !important;
  }
}
.back-office-details__header-id {
  margin-bottom: 10px;
}
@media only screen and (max-width: 576px) {
  .back-office-details__header-id {
    text-align: center;
  }
}
.back-office-details__header-buttons {
  justify-content: end;
  align-items: center;
  height: 100%;
  gap: 10px;
  flex-wrap: wrap;
}
@media only screen and (max-width: 1200px) {
  .back-office-details__header-buttons {
    margin-bottom: 20px;
    justify-content: center;
  }
}
@media only screen and (max-width: 576px) {
  .back-office-details__header-buttons {
    flex-direction: column;
  }
}
@media only screen and (max-width: 1200px) {
  .back-office-details__button {
    width: 100%;
  }
}
.dynamic-color-button,
.dynamic-color-button:focus,
.dynamic-color-button:focus-visible,
.dynamic-color-button:hover {
  background: #DD4E4E;
  border-color: #DD4E4E;
  color: white;
}
.dynamic-color-button:focus-visible,
.dynamic-color-button:hover {
  filter: opacity(0.8);
}
.button-red,
.button-red:focus,
.button-red:focus-visible,
.button-red:hover {
  background: #DD4E4E;
  border-color: #DD4E4E;
}
.button-red:focus-visible {
  filter: brightness(75%);
}
.button-green,
.button-green:focus,
.button-green:focus-visible,
.button-green:hover {
  background: var(--status-client-ativo);
  border-color: var(--status-client-ativo);
}
.ecp-card {
  background-color: #fff;
  border-radius: 20px;
  padding: 2rem 2rem 1rem 2rem;
}
.ecp-card .client-form__fields {
  padding: 0px !important;
}
@media only screen and (max-width: 992px) {
  .ecp-card {
    padding: 1rem 1rem 0.5rem 1rem;
  }
}
.details-item {
  width: 100%;
  height: 100%;
  padding: 15px 0 3px 0;
  border-bottom: 1px solid #a8a8a8;
}
.details-item__label {
  font-weight: bold;
  font-size: 0.75rem;
  color: #00441F;
}
.details-item__skeleton .ant-skeleton-title {
  margin: 12px 0 5px 0;
  height: 18px;
}
.details-item__skeleton .ant-skeleton-paragraph {
  display: none;
}
.details-item__value {
  word-break: break-all;
  padding-top: 10px;
  font-size: 1rem;
  color: #00441F;
}
@media only screen and (max-width: 576px) {
  .details-item__value {
    font-size: 1rem;
  }
}
.details-item-section-title {
  font-weight: bold;
  font-size: 1rem;
  color: #00441F;
}
.ecp-input-share {
  padding: 10px;
  border-radius: 20px;
  background-color: #f2f2f2;
  display: flex;
  align-items: center;
  gap: 10px;
}
.ecp-input-share__button {
  cursor: pointer;
  outline: none;
  border: none;
  background: transparent;
}
.ecp-input-share__icon {
  font-size: 30px;
}
@media only screen and (max-width: 480px) {
  .ecp-input-share__icon {
    display: none;
  }
}
.ecp-input-share__icon--send {
  display: block;
}
.ecp-invite__medium-margin {
  margin-bottom: 1.65rem;
}
.ecp-invite__header {
  margin-bottom: 1.375rem;
}
.ecp-invite__title {
  font-size: 1.4rem;
  margin-bottom: 0;
}
.ecp-invite__container {
  display: flex;
  gap: 15px;
}
.ecp-invite__description {
  font-size: 0.85rem;
  color: #5B5B5B;
}
.ecp-invite__description--header {
  max-width: 80%;
}
@media only screen and (max-width: 480px) {
  .ecp-invite__description--qr-code {
    margin-bottom: 0.85rem;
  }
}
.ecp-invite__share-link {
  width: 100%;
  overflow-x: hidden;
  overflow: hidden;
  display: inline-block;
  word-break: break-all;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ecp-invite__link-wrapper {
  display: inline-block;
  height: 43px;
  padding: 10px;
  width: 100%;
  overflow: hidden;
  border-radius: 20px;
  background-color: #f2f2f2;
}
@media only screen and (max-width: 480px) {
  .ecp-invite__qr-code {
    margin-bottom: 0.85rem;
  }
}
.ecp-invite__button {
  height: 43px !important;
  border-radius: 15px !important;
}
.ecp-invite__button--qrcode {
  height: 40px !important;
  min-width: 125px;
}
.ecp-invite__qr-container {
  display: flex;
  gap: 15px;
}
@media only screen and (max-width: 480px) {
  .ecp-invite__qr-container {
    display: block;
  }
}
.ecp-invite__qr-code-texts-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.ecp-token-header__title {
  font-weight: bold;
  text-align: center;
  font-size: 1.9rem;
  line-height: 1.9rem;
}
.ecp-token-header__icon-container {
  margin: 30px 0;
}
.ecp-token-header__icon-container svg {
  height: 150px;
  width: 100%;
}
@media only screen and (max-width: 576px) {
  .ecp-token-header__icon-container svg {
    height: 100px;
  }
}
.ecp-token-header__info-text {
  text-align: center;
  color: #00441F;
  font-size: 1rem;
}
.iframe-pdf {
  position: relative;
  width: 100%;
}
.iframe-pdf__iframe {
  border: none;
  width: 100%;
  transition: opacity 0.3s ease-in-out;
}
.iframe-pdf__iframe--loading {
  opacity: 0;
}
.iframe-pdf__spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
  font-size: 2rem;
  color: var(--primary-color);
}
.buttons-node__button-group {
  display: flex;
  justify-content: space-between;
}
.buttons-node__go-back-button {
  border-color: #00441F;
  color: #00441F;
  transition: all 0.4s ease-in-out;
}
.buttons-node__go-back-button:hover {
  background-color: #00441F;
  color: #fff;
}
.letter-avatar {
  border: 2px solid #00441F;
  border-radius: 50%;
  width: 65px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  font-weight: 600;
  color: #00441F;
}
@media only screen and (max-width: 576px) {
  .letter-avatar {
    display: none;
  }
}
.letter-avatar--filled {
  background-color: #00441F;
  color: #fff;
  font-weight: normal;
}
.letter-avatar--medium {
  width: 40px;
  min-width: 40px;
  height: 40px;
  font-size: 1rem;
}
.letter-avatar--extra-large {
  width: 160px;
  min-width: 160px;
  height: 160px;
  font-size: 5rem;
}
.simulation-form {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  width: 100%;
}
.simulation-form__header {
  width: 100%;
  flex-grow: 1;
  padding: 2rem;
}
@media only screen and (max-width: 576px) {
  .simulation-form__header {
    padding: 1rem;
  }
}
.simulation-form__form {
  flex-grow: 1;
  width: 100%;
}
.simulation-form__value-section {
  padding: 2rem;
}
@media only screen and (max-width: 576px) {
  .simulation-form__value-section {
    padding: 1rem;
  }
}
.simulation-form__input-label {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 1rem;
}
.simulation-form__secure-display {
  display: flex;
  align-items: center;
  justify-content: center;
}
.simulation-form__antd-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.simulation-form__card {
  color: var(--primary-color);
  display: flex;
  flex-direction: column;
  gap: 1em;
}
.simulation-form__card--max-width {
  max-width: 600px;
  margin: 0 auto;
}
.simulation-form__sub-title {
  color: var(--primary-color);
  font-weight: bold;
  margin: 0;
}
.simulation-form__input {
  text-align: center;
  margin-bottom: 2em;
}
.simulation-form__input .ant-form-item {
  margin: 0;
}
.simulation-form__input .ant-form-item input {
  text-align: center;
}
.simulation-form__input--card-centered {
  background-color: #f2f2f2;
  padding: 0.5rem;
  border-radius: 20px;
}
.simulation-form__input--card-centered .ant-form-item-label {
  text-align: center !important;
}
.simulation-form__select-option {
  text-align: center;
  width: 97%;
}
.simulation-form label {
  color: var(--primary-color);
  font-weight: 600;
}
.simulation-form__slider-label {
  display: flex;
  flex-direction: column;
  color: var(--primary-color);
}
.simulation-form__slider-label:last-child {
  text-align: right;
}
.simulation-form__input-hide {
  display: none;
}
.details-table-line {
  width: 100%;
}
.details-table-line tr {
  border-bottom: 1px solid var(--primary-color);
  height: 40px;
}
.details-table-line td:last-child {
  white-space: nowrap;
  text-align: right;
}
.details-table-line .ant-skeleton-element {
  width: 30px !important;
}
@media only screen and (max-width: 576px) {
  .details-table-line {
    font-size: 0.75rem;
  }
}
.value-card__info-card {
  background-color: #fff;
  padding: 1.5rem;
  border-radius: 15px;
  text-align: center;
}
.value-card__info-label {
  color: #5B5B5B;
}
.value-card__info-value {
  font-weight: bold;
}
.value-card__skeleton--center .ant-skeleton-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.value-card__skeleton--end .ant-skeleton-content {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.skeleton-input {
  border-radius: 40px;
}
.radio-group-square .ant-radio-button-wrapper {
  background-color: #f2f2f2;
}
.radio-group-square .ant-radio-button-wrapper {
  height: 40px;
  line-height: 38px;
}
.radio-group-square .ant-radio-button-wrapper:first-child {
  border-radius: 4px 0 0 4px;
}
.radio-group-square .ant-radio-button-wrapper:last-child {
  border-radius: 0px 4px 4px 0px;
}
.progress-queue-card {
  -webkit-box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.13);
  -moz-box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.13);
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.13);
  animation: showProgressQueue 0.3s cubic-bezier(0.43, -0.01, 0.32, 1.01);
  overflow-y: hidden;
  width: 500px;
  max-height: 700px;
  min-height: 100px;
}
@media only screen and (max-width: 576px) {
  .progress-queue-card {
    max-width: 80vw;
  }
}
.progress-queue-card__header {
  background-color: #00441F;
  color: #fff !important;
}
.progress-queue-card__header-button {
  background-color: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
}
.progress-queue-card .ant-collapse-content-box {
  padding: 0px 40px 20px !important;
}
.progress-queue-card .ant-collapse-borderless {
  background-color: #fff;
}
.progress-queue-card__show-button {
  transform: rotateX(0deg);
}
.progress-queue-card__message {
  word-break: break-all;
}
.progress-queue-card__body {
  max-height: 70vh;
  overflow-y: auto;
}
.progress-queue-card__body::-webkit-scrollbar {
  width: 5px;
  margin-left: 10px;
  margin-right: 10px;
}
.progress-queue-card__body::-webkit-scrollbar-track {
  background: #fff;
  height: 20px;
  border-radius: 20px ;
}
.progress-queue-card__body::-webkit-scrollbar-thumb {
  background: #c8c8c8;
  border-radius: 10px;
}
.progress-queue-card__body::-webkit-scrollbar-thumb:hover {
  background: #9e9e9e;
}
@keyframes showProgressQueue {
  0% {
    opacity: 0;
    transform: translatex(450px);
  }
  100% {
    opacity: 1;
    transform: translatex(0);
  }
}
.progress-queue {
  position: fixed;
  z-index: 11;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 85vh;
  align-items: flex-end;
  overflow-y: auto;
  width: 600px;
}
@media only screen and (max-width: 576px) {
  .progress-queue {
    max-width: 85vw;
  }
}
.progress-queue::-webkit-scrollbar {
  width: 10px;
  margin-left: 10px;
  margin-right: 10px;
}
.progress-queue::-webkit-scrollbar-track {
  background: #fff;
  height: 20px;
  border-radius: 20px ;
}
.progress-queue::-webkit-scrollbar-thumb {
  background: #c8c8c8;
  border-radius: 10px;
}
.progress-queue::-webkit-scrollbar-thumb:hover {
  background: #9e9e9e;
}
.button-green-ghost {
  border-color: #00441F;
  color: #00441F;
  transition: 0.3s all ease-in-out;
}
.button-green-ghost:hover {
  background-color: #00441F;
  color: #fff;
}
.info-card {
  background-color: #f2f2f2;
  padding: 1rem;
  border-radius: 20px;
}
.info-card__loading {
  height: 100px !important;
}
.info-card__title {
  color: #00441F;
  font-size: 0.8rem;
}
.info-card__info {
  color: #00441F;
}
.info-card__info .ant-skeleton-paragraph {
  margin: 0;
}
.info-card__info .ant-skeleton-paragraph li {
  height: 25px;
}
.info-card__info--error {
  color: var(--red-status);
}
.info-card__info--error .anticon {
  color: var(--red-status);
  margin-left: 5px;
}
.simulation-validate-token {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  width: 100%;
}
.simulation-validate-token__title {
  color: var(--primary-color);
  font-weight: bold;
  margin: 0;
}
.simulation-validate-token__header {
  padding-bottom: 2rem ;
}
.simulation-validate-token__container {
  justify-content: center ;
  display: flex;
}
.simulation-validate-token__content {
  width: 500px;
}
.simulation-validate-token__svg svg {
  max-width: 200px;
  height: auto;
  padding-bottom: 2rem;
}
.simulation-validate-token__icon {
  margin: auto;
  max-width: 90%;
}
.simulation-validate-token__button-group {
  display: flex;
  flex-direction: column;
  gap: 1em;
  width: 100%;
}
.simulation-validate-token__button-group > * {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
}
.simulation-validate-token__secondary-button {
  background: transparent!important;
  border-color: var(--primary-color);
  color: var(--primary-color);
}
.simulation-validate-token__secondary-button:hover,
.simulation-validate-token__secondary-button:focus-visible {
  filter: opacity(0.75);
}
.simulation-validate-token__form {
  display: flex;
  flex-direction: column;
}
.simulation-validate-token__button {
  margin-left: auto;
}
.simulation-validate-token__bottom {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.simulation-validate-token__method-display {
  background: #f2f2f2;
  border-radius: 20px;
  padding: 1rem;
  color: #00441F;
  font-weight: 700;
}
.proposal-emitter {
  padding: 2rem;
  display: grid;
  grid-template-columns: 2fr 8fr;
}
.proposal-emitter__icon {
  font-size: 5rem;
  color: #00441F;
}
.proposal-emitter__title h3 {
  color: #00441F;
  font-size: 1.5rem;
  font-weight: bold;
}
.proposal-emitter__section {
  margin-bottom: 1rem;
}
@media only screen and (max-width: 768px) {
  .proposal-emitter {
    grid-template-columns: 1fr;
  }
  .proposal-emitter__title h3 {
    padding: 1rem 0;
    text-align: center;
  }
}
.proposal-demission {
  padding: 3rem;
}
.proposal-demission__icon {
  font-size: 5rem;
  color: #2ECC71;
  margin-bottom: 1rem;
}
.proposal-antecipation {
  display: grid;
  padding: 2rem;
  grid-template-columns: 2fr 8fr;
}
.proposal-antecipation__icon {
  font-size: 5rem;
  color: #00441F;
}
.proposal-antecipation__title h3 {
  color: #00441F;
  font-size: 1.5rem;
  font-weight: bold;
}
.proposal-antecipation__section-title {
  font-weight: bold;
  font-size: 1rem;
  color: #00441F;
}
@media only screen and (max-width: 768px) {
  .proposal-antecipation {
    overflow: scroll;
    grid-template-columns: 1fr;
  }
  .proposal-antecipation__title h3 {
    padding: 1rem 0;
    text-align: center;
  }
}
.proposal-debit-balance {
  padding: 2rem;
  display: grid;
  grid-template-columns: 2fr 8fr;
}
.proposal-debit-balance__icon {
  font-size: 5rem;
  color: #00441F;
}
.proposal-debit-balance__title {
  display: grid;
  grid-template-columns: 8fr 4fr;
}
.proposal-debit-balance__date-container {
  position: relative;
}
.proposal-debit-balance__title h3 {
  color: #00441F;
  font-size: 1.5rem;
  font-weight: bold;
}
.proposal-debit-balance__section-title {
  font-weight: bold;
  font-size: 1rem;
  color: #00441F;
}
.proposal-debit-balance__display {
  display: flex;
  margin-bottom: 1rem;
}
.proposal-debit-balance__display-item {
  border: 1px solid #00441F;
  color: #00441F;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  flex-direction: column;
}
.proposal-debit-balance__display-item:nth-child(2) {
  margin: 0 1rem;
}
.proposal-debit-balance__display-item span {
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
}
.proposal-debit-balance__table {
  width: 100%;
}
.proposal-debit-balance__row {
  display: grid;
  grid-template-columns: 20fr 4fr;
}
.proposal-debit-balance__row:not(:first-child) {
  border-bottom: 1px solid #00441F;
  padding: 1rem 0;
}
.proposal-debit-balance__row th {
  color: #00441F;
}
.proposal-debit-balance__empty {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}
.proposal-debit-balance__exclamation-icon {
  padding-bottom: 0.5rem;
  font-size: 1.5rem;
  color: #00441F;
}
.proposal-debit-balance__skeleton-container {
  display: flex;
  margin-bottom: 1rem;
}
.proposal-debit-balance__display-skeleton {
  height: 2rem;
  margin: 0.5rem;
  width: 6rem;
}
@media only screen and (max-width: 768px) {
  .proposal-debit-balance {
    grid-template-columns: 1fr;
    overflow: scroll;
  }
  .proposal-debit-balance__title {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }
  .proposal-debit-balance__title h3 {
    padding: 1rem 0;
    text-align: center;
  }
  .proposal-debit-balance__display {
    flex-direction: column;
  }
  .proposal-debit-balance__display-item {
    width: 100%;
  }
  .proposal-debit-balance__display-item:nth-child(2) {
    margin: 0.5rem 0;
  }
  .proposal-debit-balance__table {
    overflow: scroll !important;
    margin-left: 1rem;
  }
  .proposal-debit-balance__row {
    white-space: nowrap;
    grid-gap: 1rem;
  }
  .proposal-debit-balance__skeleton-container {
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .proposal-debit-balance__display-skeleton {
    width: 100%;
  }
}
.proposal-bill {
  padding: 2rem;
  display: grid;
  grid-template-columns: 2fr 8fr;
}
.proposal-bill__icon {
  font-size: 5rem;
  color: #00441F;
}
.proposal-bill__title h3 {
  color: #00441F;
  font-size: 1.5rem;
  font-weight: bold;
}
.proposal-bill__table {
  width: 100%;
}
.proposal-bill__row {
  display: grid;
  grid-template-columns: 8fr 4fr 4fr 4fr;
}
.proposal-bill__row:not(:first-child) {
  border-bottom: 1px solid #00441F;
  padding: 1rem 0;
}
.proposal-bill__row th {
  color: #00441F;
}
.proposal-bill__empty {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
}
.proposal-bill__exclamation-icon {
  padding-bottom: 0.5rem;
  font-size: 1.5rem;
  color: #00441F;
}
@media only screen and (max-width: 768px) {
  .proposal-bill {
    overflow: scroll;
    grid-template-columns: 1fr;
  }
  .proposal-bill__title h3 {
    padding: 1rem 0;
    text-align: center;
  }
  .proposal-bill__table {
    overflow: scroll !important;
    margin-left: 1rem;
  }
  .proposal-bill__row {
    white-space: nowrap;
    grid-gap: 1rem;
  }
}
.proposal-due {
  padding: 2rem;
  display: grid;
  grid-template-columns: 2fr 8fr;
}
.proposal-due__icon {
  font-size: 5rem;
  color: #00441F;
}
.proposal-due__title h3 {
  color: #00441F;
  font-size: 1.5rem;
  font-weight: bold;
}
.proposal-due__section-title {
  font-weight: bold;
  font-size: 1rem;
  color: #00441F;
}
.proposal-due__table {
  width: 100%;
}
.proposal-due__row {
  display: grid;
  grid-template-columns: 12fr 4fr 4fr 4fr;
}
.proposal-due__row:not(:first-child) {
  border-bottom: 1px solid #00441F;
  padding: 1rem 0;
}
.proposal-due__row th {
  color: #00441F;
}
.proposal-due__empty {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}
.proposal-due__exclamation-icon {
  padding-bottom: 0.5rem;
  font-size: 1.5rem;
  color: #00441F;
}
@media only screen and (max-width: 768px) {
  .proposal-due {
    grid-template-columns: 1fr;
    overflow: scroll;
  }
  .proposal-due__title h3 {
    padding: 1rem 0;
    text-align: center;
  }
  .proposal-due__table {
    overflow: scroll !important;
    margin-left: 1rem;
    width: 120%;
  }
  .proposal-due__row {
    white-space: nowrap;
    grid-gap: 1rem;
  }
}
.proposal-contract {
  padding: 2rem;
  display: grid;
  grid-template-columns: 2fr 8fr;
}
.proposal-contract__icon {
  font-size: 5rem;
  color: #00441F;
}
.proposal-contract__title h3 {
  color: #00441F;
  font-size: 1.5rem;
  font-weight: bold;
}
.proposal-contract__section {
  margin-bottom: 1rem;
}
.proposal-contract__renegociation-button-container {
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 768px) {
  .proposal-contract__renegociation-button-container {
    font-size: 0.9rem;
    justify-content: space-between;
  }
}
@media only screen and (max-width: 768px) {
  .proposal-contract__only-contract {
    font-size: 1rem;
  }
}
.proposal-contract__renegociation-button {
  height: 30px !important;
  margin-left: 1rem;
}
.proposal-contract__renegociation-contract {
  background-color: #f2f2f2;
  padding: 0.3rem;
  border-radius: 10px;
  margin: 0.2rem !important;
  white-space: nowrap;
}
@media only screen and (max-width: 768px) {
  .proposal-contract__renegociation-contract {
    justify-content: center;
  }
}
@media only screen and (max-width: 768px) {
  .proposal-contract__renegociation-contracts-container {
    justify-content: center;
  }
}
@media only screen and (max-width: 768px) {
  .proposal-contract {
    grid-template-columns: 1fr;
  }
  .proposal-contract__title h3 {
    padding: 1rem 0;
    text-align: center;
  }
}
#root .proposal-line {
  display: flex;
  flex-direction: column;
}
@media only screen and (max-width: 576px) {
  #root .proposal-line {
    align-items: center;
  }
}
#root .proposal-line__widget {
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.proposal-history {
  padding: 2rem;
  padding-top: 2.35rem;
}
.proposal-history .ant-timeline-item {
  padding-bottom: 40px;
}
@media only screen and (max-width: 576px) {
  .proposal-history .ant-timeline-item {
    padding-bottom: 25px;
  }
}
.proposal-history .ant-timeline-item-right .ant-timeline-item-content {
  padding-right: 1em;
}
.proposal-history .ant-timeline-item-last {
  padding-bottom: 0;
}
@media only screen and (max-width: 576px) {
  .proposal-history {
    padding: 1rem;
    padding-top: 1.35rem;
  }
}
.proposal-details > *:not(:last-child) {
  margin-bottom: 1rem;
}
@media only screen and (max-width: 576px) {
  .proposal-details__tabs .ant-tabs-nav {
    padding-inline: 1em;
  }
}
@media only screen and (max-width: 576px) {
  .proposal-details__captions {
    margin-inline: auto;
  }
}
.proposal-details .proposal-details__card {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  padding: 2rem;
}
.proposal-details__client-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1em;
  width: 100%;
}
.proposal-details__client-data {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.proposal-details__client-name {
  font-size: 1.145rem;
}
.proposal-details__client-buttons {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  width: min(300px, 100%);
  font-weight: 600;
}
@media only screen and (max-width: 576px) {
  .proposal-details__client-buttons {
    margin: auto;
  }
}
.proposal-details__client-buttons > * {
  flex: 1 0 0px;
}
@media only screen and (max-width: 576px) {
  .proposal-details .ecp-card {
    padding: 0;
    overflow: hidden;
  }
}
.proposal-create {
  display: flex;
  flex-direction: column;
  gap: 2.5em;
  align-items: center;
}
.proposal-create .center-content-width {
  width: min(100%, 550px);
  margin: auto;
}
.proposal-create .ant-steps-item-wait .ant-steps-item-icon,
.proposal-create .ant-steps-item-finish .ant-steps-item-icon {
  background: transparent;
  border-color: var(--primary-color);
}
.proposal-create .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: var(--primary-color);
}
.proposal-create .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: var(--primary-color);
}
.client-proposal-list__card {
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  margin-bottom: 1rem;
  cursor: pointer;
}
.client-proposal-list__side {
  background-color: #00441F;
  border-radius: 10px 0 0 10px;
  color: #fff;
  width: 6rem;
  padding: 1.5rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.client-proposal-list__content {
  display: flex;
  flex-grow: 4;
  justify-content: center;
  flex-direction: column;
  margin: 1rem;
}
.client-proposal-list__title {
  color: #00441F;
  font-weight: bold;
}
.client-proposal-list__title-list {
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.client-proposal-list__results {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.client-proposal-list__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}
.client-proposal-list__new {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.3rem;
}
.client-proposal-list__new img {
  width: 30%;
  padding: 0.5rem;
}
.client-proposal-list__skeleton {
  display: flex;
  padding: 0 2rem;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 992px) {
  .client-proposal-list__card {
    flex-direction: column;
  }
  .client-proposal-list__side {
    width: 100%;
    height: 1rem;
    border-radius: 10px 10px 0 0;
  }
  .client-proposal-list__content {
    flex-direction: row;
    margin-bottom: 0;
  }
  .client-proposal-list__titleSimulation {
    font-size: 1.2rem;
  }
  .client-proposal-list__titleList {
    text-align: right;
    padding-right: 1rem;
  }
  .client-proposal-list__new {
    flex-direction: column;
  }
  .client-proposal-list__new img {
    width: 40%;
  }
  .client-proposal-list__icon {
    padding: 0;
    padding: 1rem;
  }
  .client-proposal-list__skeleton {
    padding: 2rem;
  }
}
html #root .proposal-unauth-contract-wrapper {
  max-width: 1000px;
}
.proposal-unauth-contract__gap {
  display: flex;
  flex-direction: column;
  gap: 2em;
}
.proposal-unauth-contract__buttons {
  flex-direction: row-reverse;
  max-width: 600px;
  margin: auto;
  width: 100%;
}
.proposal-unauth-contract__buttons > * {
  flex-grow: 1;
  min-width: 280px;
}
.summary-proposal .summary-proposal__box {
  padding: 4rem;
  width: 100%;
}
@media only screen and (max-width: 992px) {
  .summary-proposal .summary-proposal__box {
    width: 100%;
    padding: 2rem;
  }
}
.summary-proposal__container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 1em;
}
.summary-proposal__item-container {
  width: 33%;
  display: inline-block;
}
@media only screen and (max-width: 992px) {
  .summary-proposal__item-container {
    width: 50%;
  }
}
@media only screen and (max-width: 576px) {
  .summary-proposal__item-container {
    width: 100%;
  }
}
.proposal-list-search-ecp__container {
  display: flex;
  min-width: 500px;
  margin-bottom: 30px;
}
.proposal-list-search-ecp__select {
  width: 150px;
}
.proposal-list-search-ecp__select .ant-select-selector {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.proposal-list-search-ecp__input {
  border-left: none;
  border-radius: 0 !important;
  padding-left: 0;
  box-shadow: none !important;
  opacity: 1 !important;
}
@media only screen and (max-width: 992px) {
  .proposal-list-search-ecp__input {
    width: 100% !important;
  }
}
.proposal-list-search-ecp__input .ant-btn.ant-input-search-button {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
.proposal-list-search-ecp__input .ant-input-wrapper.ant-input-group .ant-input-group-addon:first-child {
  border-radius: 0 !important;
}
.proposal-list-search-ecp__input .ant-input-group > .ant-input:first-child,
.proposal-list-search-ecp__input .ant-input-group-addon:first-child {
  border-radius: 0 !important;
}
.proposal-list-search-ecp__input .ant-checkbox + span {
  padding-right: 0;
}
.proposal-filters__date {
  font-size: 0.8rem;
}
@media only screen and (max-width: 576px) {
  .proposal-filters__date {
    text-align: center;
  }
}
.contract-form-content__title {
  font-size: 1rem;
  margin-bottom: 15px;
}
.bar-label {
  display: flex;
  justify-content: space-between;
  padding: 0 0.1em;
  font-weight: 500;
}
.limit-bar {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.limit-bar .ant-progress-bg {
  position: relative;
  height: 2.5rem !important;
}
.limit-bar .ant-progress-bg:before {
  content: '';
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  background: var(--primary-color);
  border-radius: 100px;
}
.limit-bar .bar-label {
  color: #000000;
}
.limit-details__h3 {
  color: var(--primary-color);
  font-size: 1rem;
  font-weight: 600;
  margin: 0;
}
.limit-details__expand-icon {
  margin-top: 1.5px;
  color: var(--primary-color);
  left: 0 !important;
}
.limit-details__table tr {
  border-bottom: 1px solid var(--primary-color);
  height: 40px;
}
.limit-details__table td:last-child {
  white-space: nowrap;
  text-align: right;
}
.limit-details__table .ant-skeleton-element {
  width: 30px !important;
}
@media only screen and (max-width: 576px) {
  .limit-details__table {
    font-size: 0.75rem;
  }
}
@media only screen and (max-width: 576px) {
  .limit-details {
    font-size: 0.8em;
  }
}
.limit-details__panel .ant-collapse-header {
  padding-left: 25px !important;
}
.limit-details__panel .ant-collapse-content-box {
  padding: 0 !important;
}
.range-label {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  font-size: 0.9rem;
  gap: 1.5em;
  text-align: center;
}
@media only screen and (max-width: 480px) {
  .range-label {
    font-size: 0.8rem;
  }
}
.range-label__item:first-child {
  text-align: left;
  margin-right: auto;
}
.range-label__item:last-child {
  text-align: right;
  margin-left: auto;
}
.range-label__label {
  white-space: nowrap;
}
.range-label__value {
  font-size: 1.05eem;
  font-weight: 600;
}
.slider-input__slider {
  margin: 1rem 2rem 3rem 2rem;
}
.proposals-details-department-skeleton__title {
  width: min(200px, 100%);
  border-radius: 4px;
}
.proposals-details-department-skeleton__progress-bar-text {
  width: min(200px, 100%);
  border-radius: 4px;
}
.proposals-details-department-skeleton__progress-bar-text,
.proposals-details-department-skeleton__progress-bar-text .ant-skeleton-input {
  height: 16px;
}
#root .proposals-details-department__progress-bar-wrapper {
  display: flex;
  gap: 35px;
}
@media only screen and (max-width: 992px) {
  #root .proposals-details-department__progress-bar-wrapper {
    display: block;
    gap: 20px;
  }
}
@media only screen and (max-width: 576px) {
  #root .proposals-details-department__progress-bar-wrapper {
    padding: 0 1em;
  }
}
#root .proposals-details-department__progress-bar-text {
  color: #00441F;
  font-weight: 500;
}
#root .proposals-details-department__progress-bar {
  max-width: 320px;
}
#root .proposals-details-department__steps-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.contract-signature-helper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-self: center;
}
@media only screen and (max-width: 576px) {
  .contract-signature-helper {
    padding: 1rem;
  }
}
.contract-signature-helper__outter-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: wrap-reverse;
}
.contract-signature-helper__header {
  display: flex;
  flex-direction: column;
}
.contract-signature-helper__title {
  font-weight: bold;
  font-size: 1.3rem;
  margin-bottom: 10px;
  color: var(--primary-color);
}
.contract-signature-helper__icon {
  font-size: 72px;
  color: var(--primary-color);
  margin-right: 2.5vw;
}
.contract-signature-helper__button {
  margin-left: auto;
}
.proposal-history-button {
  height: unset !important;
  border: none !important;
  font-size: 1.75rem;
  padding: 5px;
}
@media only screen and (max-width: 576px) {
  .proposal-history-button {
    font-size: 1.35rem;
    margin-left: auto;
  }
}
html body .proposal-history-item {
  font-size: 0.9rem;
}
@media only screen and (max-width: 576px) {
  html body .proposal-history-item {
    font-size: 0.9rem;
  }
}
html body .proposal-history-item__title {
  font-weight: 600;
  margin-bottom: 0;
  line-height: normal;
}
@media only screen and (max-width: 576px) {
  html body .proposal-history-item__title {
    font-size: 0.8rem;
  }
}
html body .proposal-history-item__sub-title {
  margin-bottom: 6px;
}
@media only screen and (max-width: 576px) {
  html body .proposal-history-item__sub-title {
    font-size: 0.8rem;
  }
}
html body .proposal-history-item__description {
  text-align: justify;
}
@media only screen and (max-width: 576px) {
  html body .proposal-history-item__description {
    font-size: 0.6rem;
  }
}
html body .proposal-history-item__footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  font-weight: 600;
}
html body .proposal-history-item__user {
  min-height: 44px;
  text-align: right;
}
html body .proposal-history-item__user-name--unauth {
  color: #65737A;
}
html body .proposal-history-item__datetime {
  color: var(--primary-color);
}
.proposal-line-widget {
  width: 100%;
}
@media only screen and (max-width: 576px) {
  .proposal-line-widget {
    width: auto;
  }
}
.proposal-line-widget--history {
  max-width: 986px;
  margin-left: auto;
  width: 100%;
}
@media only screen and (max-width: 576px) {
  .proposal-line-widget--history {
    gap: 0;
  }
}
.proposal-line-widget__content {
  border: 1px solid #d9d9d9;
  border-radius: 20px;
}
@media only screen and (max-width: 576px) {
  .proposal-line-widget__content {
    border-right: none;
    border-left: none;
    border-radius: 0px;
  }
}
.proposal-line-widget__history-header {
  display: flex;
  gap: 1.5rem;
  flex-wrap: wrap;
  justify-content: flex-end;
}
@media only screen and (max-width: 576px) {
  .proposal-line-widget__history-header {
    flex-wrap: wrap-reverse;
    padding: 0 1em;
  }
}
.proposal-line-widget__history-header--only-buttons {
  justify-content: flex-end;
}
.proposal-line-widget__title {
  font-size: 1.5rem;
  font-weight: 600;
  padding: 11px;
  color: #00441F;
  border-bottom: 1px solid #00441F;
}
@media only screen and (max-width: 992px) {
  .proposal-line-widget__title {
    font-size: 1.2rem;
  }
}
.proposal-line-widget__subtitle {
  font-size: 1rem;
  margin: 0;
}
.proposal-line-widget__buttons-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
}
.proposal-line-widget__history-button {
  margin-left: auto;
  border-color: var(--primary-color);
  color: var(--primary-color);
  font-weight: 600;
  display: flex;
  align-items: center;
  min-width: 215px;
  justify-content: space-between;
}
.proposal-line-widget__history-button span {
  line-height: 0;
}
.proposal-line-widget__history-button-icon {
  font-size: 1.2rem;
}
#root .proposals-details-resume {
  display: flex;
}
#root .proposals-details-resume__title {
  color: #00441F;
  font-size: 1.5rem;
  padding: 11px;
  border-bottom: 1px solid #00441F;
}
@media only screen and (max-width: 992px) {
  #root .proposals-details-resume__title {
    font-size: 1.2rem;
  }
}
#root .proposals-details-resume__anchor {
  color: #00CF7B;
  text-decoration: underline;
}
#root .proposals-details-resume__line {
  padding: 10px;
  border-bottom: 1px solid #00441F;
  display: flex;
  justify-content: space-between;
}
#root .proposals-details-resume__line-title {
  color: #00441F;
  font-size: 1rem;
}
@media only screen and (max-width: 992px) {
  #root .proposals-details-resume__line-title {
    font-size: 0.75rem;
  }
}
#root .proposals-details-resume__line-value {
  color: #00441F;
  font-weight: bold;
}
@media only screen and (max-width: 992px) {
  #root .proposals-details-resume__line-value {
    font-size: 0.75rem;
  }
}
#root .proposals-details-section-skeleton__image {
  width: 75px;
  height: 75px;
  border-radius: 10px;
  margin-top: 0.5rem;
}
#root .proposals-details-section {
  display: flex;
}
#root .proposals-details-section .ant-collapse-header:focus-within {
  outline: 2px solid #00A8E2;
  outline-offset: -2px;
}
#root .proposals-details-section__content {
  flex: 1;
}
#root .proposals-details-section__image-wrapper {
  flex: 0 0 200px;
  text-align: center;
}
@media only screen and (max-width: 1200px) {
  #root .proposals-details-section__image-wrapper {
    display: none;
  }
}
.proposal-contract-pdf {
  padding: 0 2rem;
}
@media only screen and (max-width: 576px) {
  .proposal-contract-pdf {
    padding: 0;
  }
}
.proposal-contract-pdf__title {
  color: #00441F;
  font-weight: bold;
  border-bottom: 1px solid #00441F;
  padding-bottom: 10px;
  font-size: 1.2rem;
}
.proposal-contract-pdf__pdf {
  height: 47vh;
}
.proposal-contract-pdf__pdf--only-view {
  height: 68vh;
}
@media only screen and (max-width: 576px) {
  .proposal-contract-pdf__pdf--only-view {
    height: 40vh;
  }
}
.proposal-contract-pdf__download-button-container {
  margin-top: 20px;
  border-top: 1px solid #00441F;
  padding: 1rem 0;
}
.proposal-contract-pdf__download-button {
  padding-left: 0;
  text-align: start;
  font-weight: bold;
}
.proposal-contract-pdf__localization {
  margin: 1rem;
  background-color: #f2f2f2;
  border-radius: 30px;
  padding: 1rem;
}
.proposal-contract-pdf__localization-item {
  padding: 0 1rem;
  display: flex;
  justify-content: space-between;
}
.proposal-contract-pdf__localization-title {
  color: #00441F;
  font-weight: bold;
}
.proposal-step-button {
  display: flex;
  align-items: center;
}
.proposal-step-button__button {
  display: flex;
  align-items: center;
  background: transparent;
  outline: none;
  border-radius: 20px;
  border: none;
}
.proposal-step-button__button--enabled {
  cursor: pointer;
}
.proposal-step-button__button--enabled:focus-visible {
  outline: 2px solid #00A8E2;
  outline-offset: -2px;
}
.proposal-step-button__button--enabled:hover > p {
  background-color: #f2f2f2;
  display: flex;
}
.proposal-step-button__icon {
  margin: 0;
  display: flex;
  position: relative;
  z-index: 1;
}
.proposal-step-button__icon svg {
  width: 32px;
  background-color: #fff;
  height: 32px;
}
@media only screen and (max-width: 992px) {
  .proposal-step-button__icon svg {
    width: 28px;
    height: 28px;
  }
}
.proposal-step-button__icon::after {
  content: '';
  position: absolute;
  background-color: #b5b5b5;
  z-index: 0;
  left: 50%;
  top: 100%;
  width: 1px;
  height: 3rem;
}
@media only screen and (max-width: 992px) {
  .proposal-step-button__icon::after {
    height: 12px;
  }
}
.proposal-step-button__name {
  color: #00441F;
  font-size: 1rem;
  font-weight: 400;
  border-radius: 20px;
  padding: 0.3rem;
  padding-left: 0.5rem;
}
@media only screen and (max-width: 992px) {
  .proposal-step-button__name {
    padding: 0.2rem;
  }
}
@media only screen and (max-width: 992px) {
  .proposal-step-button__name {
    font-size: 0.8rem;
  }
}
.proposal-step-button__asterisk {
  color: var(--red-status);
  font-weight: bold;
  font-size: 1rem;
}
.resend-documental {
  margin-bottom: 40px;
}
.resend-documental__link {
  padding: 0;
  height: fit-content !important;
}
.resend-documental__link * {
  text-decoration: underline!important;
}
.client-instructions__instructions-container {
  display: flex;
  align-items: center;
  background-color: #f2f2f2;
  padding: 1rem;
  color: #00441F;
  border-radius: 20px;
  margin-top: 1rem;
}
.client-instructions__skeleton-title {
  width: 40%!important;
  margin-bottom: 0.5rem;
  border-radius: 5px;
}
.client-instructions__skeleton-card {
  border-radius: 20px;
  height: 5rem!important;
}
.client-instructions__skeletons {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}
.has-pendencies-warning {
  background-color: #f2f2f2;
  padding: 1rem;
  border-radius: 20px;
  align-self: flex-end;
  width: calc(100% - 200px);
  margin: 2rem 0;
}
@media only screen and (max-width: 576px) {
  .has-pendencies-warning {
    margin: 1rem 0;
    padding: 0.8rem;
  }
}
@media only screen and (max-width: 1200px) {
  .has-pendencies-warning {
    width: 100%;
  }
}
.has-pendencies-warning__title {
  font-weight: bold;
  color: #00441F;
  margin: 0!important;
}
@media only screen and (max-width: 576px) {
  .has-pendencies-warning__title {
    font-size: 0.9rem;
  }
}
@media only screen and (max-width: 576px) {
  .has-pendencies-warning__button {
    margin-top: 5px;
    max-height: 30px;
    font-size: 0.8rem;
  }
}
.has-pendencies-warning__description {
  color: #00441F;
  margin-bottom: 0.5rem;
}
@media only screen and (max-width: 576px) {
  .has-pendencies-warning__description {
    font-size: 0.7rem;
  }
}
.has-pendencies-warning__instructions {
  font-size: 0.8rem;
}
.has-pendencies-warning__skeleton-container {
  align-self: flex-end;
  width: calc(100% - 200px);
  margin: 1rem 0 ;
}
@media only screen and (max-width: 1200px) {
  .has-pendencies-warning__skeleton-container {
    width: 100%;
  }
}
.has-pendencies-warning__skeleton {
  height: 5rem!important;
  width: 100%!important;
}
.has-pendencies-warning__text {
  width: 100%;
}
@media only screen and (max-width: 768px) {
  .has-pendencies-warning__text {
    flex-direction: column;
  }
}
.has-pendencies-warning__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media only screen and (max-width: 768px) {
  .has-pendencies-warning__content {
    flex-direction: column;
  }
}
.onidata-status-error {
  background-color: #f2f2f2;
  padding: 1rem;
  border-radius: 20px;
  display: flex;
  align-items: center;
  width: calc(100% - 200px);
  align-self: flex-end;
}
@media only screen and (max-width: 1200px) {
  .onidata-status-error {
    flex-direction: column;
    margin: 0 1rem!important;
    width: 100%;
    align-self: center;
  }
}
@media only screen and (max-width: 576px) {
  .onidata-status-error {
    flex-direction: column;
    width: 90%!important;
    align-self: center;
  }
}
.onidata-status-error__json {
  margin-bottom: 0!important;
  white-space: normal;
  text-align: center;
}
.onidata-status-error__icon {
  font-size: 2rem;
  color: #DD4E4E;
}
@media only screen and (max-width: 1200px) {
  .onidata-status-error__icon {
    margin: 1rem 0;
  }
}
.onidata-status-error__skeleton {
  height: 4rem!important;
  border-radius: 20px;
  width: calc(100% - 200px);
  align-self: flex-end;
}
@media only screen and (max-width: 1200px) {
  .onidata-status-error__skeleton {
    width: 100%;
  }
}
@media only screen and (max-width: 576px) {
  .onidata-status-error__skeleton {
    flex-direction: column;
    width: 90%!important;
    align-self: center;
  }
}
.proposal-radio-filter .ant-radio-group.ant-radio-group-outline {
  width: 100%;
}
.text-ellipsis {
  text-overflow: ellipsis;
  width: 100%;
  overflow-x: hidden;
  display: inline-block;
  word-break: break-all;
  white-space: nowrap;
}
.parcel-antecipation-list__header {
  display: flex;
  justify-content: space-between;
}
.parcel-antecipation-list__header--centered {
  align-items: center;
}
@media only screen and (max-width: 768px) {
  .parcel-antecipation-list__header {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
.parcel-antecipation-list__label {
  display: block;
  font-size: 1.1rem;
}
@media only screen and (max-width: 768px) {
  .parcel-antecipation-list__label {
    display: flex;
    font-size: 1rem;
    text-align: center;
    margin-bottom: 1rem;
  }
}
.parcel-antecipation-list__title {
  color: #00441F;
  font-weight: bold;
}
.parcel-antecipation-list__scroll {
  display: flex;
  width: min-content;
  align-items: center;
}
@media only screen and (max-width: 768px) {
  .parcel-antecipation-list__button {
    margin-bottom: 2rem;
  }
}
.parcel-antecipation-list__loading {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
}
.parcel-antecipation-list__skeleton-item {
  padding: 1rem 0;
  border-bottom: 1px solid rgba(190, 190, 190, 0.2);
}
.parcel-antecipation-list__table-title-item {
  color: #00441F;
  white-space: nowrap;
  font-weight: bold;
}
.parcel-antecipation-list__table-title-item--right {
  text-align: right;
}
.parcel-antecipation-list__status-item {
  display: flex;
}
@media only screen and (max-width: 768px) {
  .parcel-antecipation-list__status-item {
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.parcel-antecipation-list__status {
  width: 20px;
  height: 20px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 1rem;
}
.parcel-antecipation-list__table-row-item {
  padding: 1rem 0;
  border-bottom: 1px solid #00441F;
}
.parcel-antecipation-list__table {
  overflow-x: scroll;
  min-width: 100%;
}
@media only screen and (max-width: 768px) {
  .parcel-antecipation-list__table {
    min-width: 130%;
  }
}
.parcel-antecipation-list__table th,
.parcel-antecipation-list__table td {
  padding: 1rem 0;
}
@media only screen and (max-width: 768px) {
  .parcel-antecipation-list__table th,
  .parcel-antecipation-list__table td {
    padding: 1rem 0.5rem;
  }
}
.parcel-antecipation-list__table-row {
  border-bottom: 1px solid #00441F;
}
.parcel-antecipation-list__value-item {
  text-align: right;
}
.drop-down-button {
  color: #00CF7B;
  user-select: none;
}
.drop-down-button__icon {
  transition: transform 0.24s;
}
#root .ecp-signup-form__verification-input-container {
  padding: 1rem;
  border-radius: 20px;
  background-color: #E0E5CF;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
}
#root .ecp-signup-form__verification-input-container .vi__wrapper {
  height: 60px;
}
#root .ecp-signup-form__verification-input {
  width: 80%;
  margin: auto;
}
#root .ecp-signup-form__verification-input * {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  font-family: inherit;
  height: 60px;
  font-family: Montserrat !important;
  border-color: #00441F;
  border-radius: 20px;
}
@media only screen and (max-width: 576px) {
  #root .ecp-signup-form__verification-input {
    width: 100%;
  }
}
#root .ecp-signup-form__password-help-container {
  margin: 1.35rem 0;
}
#root .ecp-signup-form__password-help-text {
  font-size: 1.05rem;
}
.forgot-password-onidata__container {
  display: flex;
  flex-direction: column;
  padding: 2rem 0;
}
@media only screen and (max-width: 576px) {
  .forgot-password-onidata__container {
    padding: 1rem;
  }
}
.forgot-password-onidata__info-text {
  text-align: center;
  margin-bottom: 30px;
  color: #00441F;
  font-size: 1rem;
}
.forgot-password-onidata__info-text--password {
  font-size: 0.8rem;
}
.forgot-password-onidata__form {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.forgot-password-onidata__sent-token {
  color: #919191;
}
.signup-client-lgpd__pdf {
  height: 60vh;
}
@media only screen and (min-width: 1600px) {
  .signup-client-lgpd__pdf {
    height: 70vh;
  }
}
.ecp-lgpd {
  text-align: center;
}
.ecp-lgpd__pdf {
  height: 70vh;
}
@media only screen and (max-width: 480px) {
  .ecp-lgpd__pdf {
    height: 70vh;
  }
}
@media only screen and (min-width: 1600px) {
  .ecp-lgpd__pdf {
    height: 75vh;
  }
}
.ecp-lgpd__button {
  min-width: 15rem;
}
.ecp-lgpd__button--logout {
  background: var(--secondary-color);
  border: 2px solid var(--primary-color);
  color: var(--primary-color);
}
.ecp-lgpd__button--logout:hover {
  color: #fff;
}
@media only screen and (max-width: 576px) {
  .ecp-lgpd__button {
    min-width: 60%;
  }
}
.ecp-lgpd__signature-title {
  font-size: 1.1rem;
}
.ecp-lgpd .ant-form-item {
  margin-bottom: 0;
}
.ecp-lgpd__button-container {
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: center;
}
.portability-create {
  display: flex;
  flex-direction: column;
  gap: 2.5em;
  align-items: center;
}
.portability-create .center-content-width {
  margin: auto;
}
.portability-create__sub-title {
  color: var(--primary-color);
  font-weight: bold;
  margin: 0;
  font-size: 1.3rem;
}
.portability-create .ant-steps-item-wait .ant-steps-item-icon,
.portability-create .ant-steps-item-finish .ant-steps-item-icon {
  background: transparent;
  border-color: var(--primary-color);
}
.portability-create .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: var(--primary-color);
}
.portability-create .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: var(--primary-color);
}
.portability-data__card {
  padding: 2rem !important;
}
@media only screen and (max-width: 576px) {
  .portability-data__card {
    padding: 1rem;
  }
}
.portability-data__details tr {
  border-bottom: 1px solid var(--primary-color);
  height: 40px;
}
.portability-data__details td:last-child {
  white-space: nowrap;
  text-align: right;
}
.portability-data__details .ant-skeleton-element {
  width: 30px !important;
}
@media only screen and (max-width: 576px) {
  .portability-data__details {
    font-size: 0.75rem;
  }
}
.accept-portability-modal__title {
  font-size: 1.2rem;
}
.accept-portability-modal__decline-icon {
  font-size: 70px;
  color: #DD4E4E;
}
@media only screen and (max-width: 576px) {
  .accept-portability-modal__back-button {
    margin-bottom: 10px;
  }
}
.accept-portability-modal__decline-button {
  background-color: #DD4E4E;
  border-color: #DD4E4E !important;
  transition: all 0.2s ease-in-out;
}
.accept-portability-modal__decline-button:hover {
  background-color: transparent;
  color: #DD4E4E;
}
.portability-accept__contract-container {
  padding: 0 2rem;
}
@media only screen and (max-width: 576px) {
  .portability-accept__contract-container {
    padding: 0;
  }
}
.portability-accept__title {
  color: #00441F;
  font-weight: bold;
  border-bottom: 1px solid #00441F;
  padding-bottom: 10px;
  font-size: 1.2rem;
}
.portability-accept__title--terms {
  font-size: 1rem;
}
.portability-accept__pdf {
  height: 60vh;
}
.portability-accept__inputs-container {
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
@media only screen and (max-width: 576px) {
  .portability-accept__inputs-container {
    padding: 0;
  }
}
.portability-accept__download-button-container {
  margin-top: 20px;
  border-top: 1px solid #00441F;
  padding: 1rem 0;
}
.portability-accept__download-button {
  padding-left: 0;
  text-align: start;
  font-weight: bold;
}
.portability-accept__decline-button {
  border-color: #00441F;
  color: #00441F;
  transition: all 0.2s ease-in-out;
}
.portability-accept__decline-button:hover {
  color: #fff;
  background-color: #00441F;
}
@media only screen and (max-width: 1200px) {
  .portability-accept__decline-button {
    margin-bottom: 10px;
  }
}
.portability {
  padding: 3rem 10% !important;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
@media only screen and (max-width: 1200px) {
  .portability {
    flex-direction: column;
    padding: 3rem !important;
  }
}
@media only screen and (max-width: 576px) {
  .portability {
    padding: 2rem !important;
  }
}
.portability__info-container {
  display: flex;
  flex-direction: column;
  width: 45%;
  margin-right: 5%;
}
@media only screen and (max-width: 1200px) {
  .portability__info-container {
    flex-direction: column;
    width: 100%;
    margin-right: 0;
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 576px) {
  .portability__info-container {
    margin-bottom: 10px;
  }
}
.portability__title {
  font-weight: bold;
  color: #00441F;
  font-size: 1.5rem;
}
.portability__vantages-list {
  padding-left: 16px;
}
.portability__svg-container {
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 1200px) {
  .portability__buttom {
    width: 100%;
  }
}
.renegotiation-create {
  display: flex;
  flex-direction: column;
  gap: 2.5em;
  align-items: center;
}
.renegotiation-create .center-content-width {
  margin: auto;
}
.renegotiation-create__sub-title {
  color: var(--primary-color);
  font-weight: bold;
  margin: 0;
  font-size: 1.3rem;
}
.renegotiation-create__card {
  padding: 2rem !important;
  width: 100%;
}
@media only screen and (max-width: 576px) {
  .renegotiation-create__card {
    padding: 1rem;
  }
}
.renegotiation-create__simulation-form {
  color: var(--primary-color);
  display: flex;
  flex-direction: column;
  grid-gap: 1em;
  gap: 1em;
}
.renegotiation-create__debit-balance-value {
  cursor: pointer;
  font-weight: bold;
  color: var(--red-status);
  transition: all 0.2s ease-in-out;
}
.renegotiation-create__debit-balance-value:hover {
  color: #000;
}
.renegotiation-create .ant-steps-item-wait .ant-steps-item-icon,
.renegotiation-create .ant-steps-item-finish .ant-steps-item-icon {
  background: transparent;
  border-color: var(--primary-color);
}
.renegotiation-create .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: var(--primary-color);
}
.renegotiation-create .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: var(--primary-color);
}
.renegotiation-create .ant-radio-button-wrapper {
  background: #f2f2f2 !important;
  border-color: #f2f2f2;
  overflow: hidden;
  font-size: 0.9rem;
}
.renegotiation-create .ant-radio-button-checked {
  background-color: #00441F;
}
.debit-balance-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}
.debit-balance-modal__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.debit-balance-modal__container--total {
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem;
  background-color: #f0f0f0;
  border-radius: 15px;
}
.debit-balance-modal__line {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
  color: #5B5B5B;
  padding: 0 1rem;
}
.debit-balance-modal__label-total {
  font-size: 1rem;
}
.debit-balance-modal__value-total {
  font-size: 1rem;
  color: var(--red-status);
}
.renegotiation-simulation__warning-title {
  font-size: 1.05rem;
  text-transform: uppercase;
  margin-bottom: 0.1rem;
}
.renegotiation-simulation__warning-card {
  margin-top: 1.5625rem;
  align-items: center;
}
.renegotiation-simulation__warning-card .ant-alert-with-description .ant-alert-message {
  margin-bottom: 2px;
}
.renegotiation-simulation__warning-icon svg {
  font-size: 35px;
}
.renegotiation {
  padding: 3em 10% !important;
  margin-bottom: 25px;
}
.renegotiation__title {
  font-weight: bold;
  color: #00441F;
  font-size: 1.5rem;
  margin: 0;
}
.configuration-side-bar {
  max-width: 200px;
  min-width: 200px;
  border-right: 1px solid #f2f2f2;
  padding-right: 25px;
  overflow: auto;
}
.configuration-side-bar::-webkit-scrollbar {
  background: #f2f2f2 !important;
  width: 5px;
  height: 5px;
}
.configuration-side-bar::-webkit-scrollbar-thumb {
  background: #a8a8a8;
  border-radius: 20px;
  transition: all 0.2s ease-in-out;
}
.configuration-side-bar::-webkit-scrollbar-thumb:hover {
  background: #005e2b;
}
@media only screen and (max-width: 1200px) {
  .configuration-side-bar {
    width: 100%;
    border-right: transparent;
    max-width: none;
    padding-right: 0px;
  }
}
.configuration-side-bar__item {
  padding: 0.5rem;
  border: none;
  background-color: transparent;
  text-align: start;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border-radius: 10px;
}
.configuration-side-bar__item--selected {
  background-color: #f2f2f2;
}
.configuration-side-bar__item:hover {
  background-color: #f2f2f2;
}
.configuration-side-bar__collapse-title {
  font-size: 1rem;
  font-weight: bold;
  color: #00441F;
}
.configuration-side-bar__collapse-body {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 30px;
  transition: all 0.2s ease-out;
}
.configuration-side-bar__collapse-body--collapsed {
  min-height: 0px;
  transition: all 0.2s ease-out;
}
.configuration-side-bar__collapse-body > * {
  width: 100%;
}
.configuration-side-bar__collapse-icon {
  transition: transform 0.2s ease-out;
  transform: rotate(-180deg);
}
.configuration-side-bar__collapse-icon--collapsed {
  transform: rotate(0);
}
.configuration-fields {
  padding-left: 25px;
  padding-right: 25px;
  overflow: auto;
}
.configuration-fields::-webkit-scrollbar {
  background: #f2f2f2 !important;
  width: 5px;
  height: 5px;
}
.configuration-fields::-webkit-scrollbar-thumb {
  background: #a8a8a8;
  border-radius: 20px;
  transition: all 0.2s ease-in-out;
}
.configuration-fields::-webkit-scrollbar-thumb:hover {
  background: #005e2b;
}
@media only screen and (max-width: 1200px) {
  .configuration-fields {
    padding: 0;
  }
}
.configuration-fields__skeleton-button .ant-skeleton-button {
  width: 100%;
}
.configuration {
  height: 80vh;
}
@media only screen and (max-width: 1200px) {
  .configuration {
    flex-direction: column;
    height: 100%;
  }
}
.configuration__table .ant-table-thead th {
  background: transparent !important;
  color: #000 !important;
  border-bottom: transparent !important;
}
.configuration__table .ant-table-container {
  overflow: auto;
}
.configuration__table .ant-table-content {
  min-width: 600px;
}
.configuration__add-button {
  border-color: #00441F;
  color: #00441F;
}
.configuration__add-button:hover {
  background-color: #00441F;
  color: #fff;
}
.rh-transfers-buttons__save-button {
  border-color: #00441F;
  color: #00441F;
  transition: all 0.2s ease-in-out;
}
.rh-transfers-buttons__save-button:hover {
  background-color: #00441F;
  color: #fff;
}
@media only screen and (max-width: 1200px) {
  .rh-transfers-header__header-container {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }
}
@media only screen and (max-width: 576px) {
  .rh-transfers-header__value-title {
    margin-top: 1.5rem;
  }
}
.rh-transfers-header__download-button {
  display: flex;
  align-items: center;
  border: 1px solid #00441F;
  color: #00441F;
}
.rh-transfers-header__download-button:hover {
  background-color: #00441F;
  color: #fff;
}
@media only screen and (max-width: 1200px) {
  .rh-transfers-header__download-button {
    width: 100%;
    justify-content: center;
  }
}
.rh-transfers-header__cards-container {
  gap: 10px;
}
@media only screen and (max-width: 576px) {
  .rh-transfers-header__cards-container {
    flex-direction: column;
  }
}
.rh-transfers-header__info-texts {
  margin-left: 20px;
  text-align: start;
}
@media only screen and (max-width: 1200px) {
  .rh-transfers-header__progress-card {
    width: 100%;
  }
}
@media only screen and (max-width: 576px) {
  .rh-transfers-header__progress-card {
    justify-content: space-between;
  }
}
.rh-transfers-header__value-card {
  min-width: 150px;
}
@media only screen and (max-width: 1200px) {
  .rh-transfers-header__value-card {
    width: 50%;
  }
}
.rh-transfers-submited__value-section {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 2em;
}
.rh-transfers-submited__icon svg {
  width: 100px;
  height: 100px;
  color: #00441F;
}
.rh-transfers-submited__value {
  color: #00441F;
  font-size: 1.2rem;
  font-weight: bold;
}
.rh-transfers-submited__sub-title {
  text-align: start;
  font-size: 1rem;
  font-weight: bold;
}
.rh-transfers-detailing__data-picker-container {
  width: 150px;
}
.rh-transfers-detailing__table .ant-form-item {
  margin-bottom: 0px;
}
.rh-filters .ant-radio-group.ant-radio-group-outline {
  width: 100%;
}
.rh-covenant-select__table-header {
  background-color: #00441F;
  color: #fff;
  width: 100%;
  border-radius: 20px;
}
.rh-covenant-select__table-title-item {
  height: 60px;
}
.rh-covenant-select__table-title-item:first-child {
  -webkit-border-top-left-radius: 20px;
  -webkit-border-bottom-left-radius: 20px;
  -moz-border-radius-topleft: 20px;
  -moz-border-radius-bottomleft: 20px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.rh-covenant-select__table-title-item:last-child {
  -webkit-border-top-right-radius: 20px;
  -webkit-border-bottom-right-radius: 20px;
  -moz-border-radius-topright: 20px;
  -moz-border-radius-bottomright: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.rh-covenant-select__covenant-row {
  background: #fff;
  border-radius: 20px;
  height: 60px;
  margin-top: 1rem;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.rh-covenant-select__covenant-row:hover {
  background-color: #f2f2f2;
}
.rh-covenant-select__table-container {
  overflow: auto;
}
.rh-covenant-select__table-container::-webkit-scrollbar {
  background: #E0E5CF !important;
  width: 10px;
  height: 10px;
}
.rh-covenant-select__table-container::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 20px;
}
.rh-covenant-select__table-container::-webkit-scrollbar-thumb:hover {
  background: #005e2b;
}
.rh-covenant-select__table {
  border-collapse: separate;
  text-indent: initial;
  border-spacing: 0px 10px;
  min-width: 1200px;
}
.rh-covenant-select__row-item:first-child {
  -webkit-border-top-left-radius: 20px;
  -webkit-border-bottom-left-radius: 20px;
  -moz-border-radius-topleft: 20px;
  -moz-border-radius-bottomleft: 20px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.rh-covenant-select__row-item:last-child {
  -webkit-border-top-right-radius: 20px;
  -webkit-border-bottom-right-radius: 20px;
  -moz-border-radius-topright: 20px;
  -moz-border-radius-bottomright: 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.rh-covenant-select__row-item-skeleton {
  border-radius: 20px;
  overflow: auto;
}
.rh-covenant-select__error-container {
  min-width: 1200px;
}
.RHList__covenants {
  display: flex;
  align-items: center;
}
.RHList__see-more-covenants {
  cursor: pointer;
  color: #2196f3;
}
.RHList-Covenants {
  width: 100%;
  max-height: 400px;
  overflow: scroll;
  font-size: 13;
}
.RHList-Covenants__title {
  text-align: center;
  font-weight: bold;
}
.RHList-Covenants__description {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  color: #00441F;
  font-weight: bold;
}
.RHList-Covenants__rh-title {
  display: flex;
  padding: 0 1rem;
  border-radius: 10px;
  flex-direction: column;
}
.RHList-Covenants__name {
  background: #f2f2f2;
  padding: 0 0.5rem;
  border-radius: 5px;
  justify-content: center;
  width: fit-content;
  align-items: center;
  display: flex;
  margin: 0.3rem 0;
}
.RHList-Covenants__column-title {
  color: #00441F;
}
.RHList-Covenants__data {
  font-weight: 400;
  font-size: 13px;
  padding: 0.5rem 0;
  border-bottom: 1px solid #00441F;
}
.rh-details__button-wrapper {
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 576px) {
  .rh-details__button-wrapper {
    flex-direction: column;
  }
}
.rh-details__title {
  font-weight: bold;
  font-size: 1.2rem;
}
.rh-details__button {
  min-width: 200px;
}
.rh-details__section {
  margin-bottom: 2rem;
}
.rh-details__section .ant-typography {
  font-size: 1.5rem !important;
}
@media only screen and (max-width: 576px) {
  .rh-details__section .ant-typography {
    font-size: 1.25rem !important;
  }
}
.rh-details__permissions-value {
  color: #00441F;
}
.RH-tansfer-save-modal__table-container {
  max-width: 800px;
  overflow-x: auto;
  border-radius: 5px;
  padding: 10px;
}
.RH-tansfer-save-modal__table {
  width: 100%;
  border-collapse: collapse;
  overflow-x: auto;
  border-radius: 10px!important;
}
.RH-tansfer-save-modal__header {
  font-weight: bolder;
  padding-bottom: 0.5rem;
  color: #00441F;
}
.RH-tansfer-save-modal__info {
  border-radius: 10px;
  width: 100%;
  align-items: center;
  margin: 1rem 0;
  padding: 0.2rem 0;
}
.RH-tansfer-save-modal__item-old {
  background-color: rgba(255, 106, 106, 0.25);
  padding: 0.5rem;
  border-radius: 10px;
}
.RH-tansfer-save-modal__item-new {
  background-color: rgba(0, 207, 123, 0.19);
  padding: 0.5rem;
  border-radius: 10px;
}
.RH-tansfer-save-modal__column {
  border: 1px solid #DFDFDF;
  padding: 8px;
  text-align: left;
  white-space: nowrap;
}
.RH-tansfer-save-modal__button {
  margin-top: 2rem;
}
.lgpd-access__warning {
  background-color: #f2f2f2;
  border-radius: 10px;
  margin-bottom: 1rem;
  padding: 0.5rem;
}
.lgpd-access__warning-icon {
  color: #FFC107;
}
.lgpd-access__svg {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}
.lgpd-access__info-text {
  color: #00441F;
  font-size: 1.2rem;
  margin-bottom: 30px;
}
.lgpd-access__upload-list-preview {
  border-radius: 10px;
  padding: 0.5rem;
  word-break: break-all;
}
.lgpd-access__upload-list-preview:hover {
  background-color: #f2f2f2;
  cursor: pointer;
}
.lgpd-access__upload-list-item {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
}
.request-list-filter__error-type {
  border-top: 1px #e2e2e2 solid;
  padding: 1rem 0;
}
.request-list-filter__buttons {
  display: flex;
}
@media only screen and (max-width: 576px) {
  .request-list-filter__buttons {
    flex-direction: column;
  }
}
.request-list-filter__range-picker {
  margin-right: 1rem;
}
@media only screen and (max-width: 576px) {
  .request-list-filter__range-picker {
    margin-bottom: 0.5rem;
    margin-right: 0rem;
  }
}
.total-card {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 15px;
  gap: 1rem;
}
.total-card__icon-container {
  min-width: 40px;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 68, 31, 0.2);
  border-radius: 5px;
}
.total-card__info-label {
  color: #5B5B5B;
}
.total-card__default-color {
  background: rgba(0, 68, 31, 0.2);
}
.total-card__error-color {
  background: rgba(221, 78, 78, 0.2);
}
.total-card__succes-color {
  background: rgba(0, 207, 123, 0.2);
}
.log-list .ant-radio-button-wrapper {
  background: none !important;
  overflow: hidden;
}
.log-list .ant-radio-button-checked {
  background-color: #00441F;
}
.log-list__table {
  font-family: monospace;
}
.log-list__small {
  font-size: 0.8rem;
}
.log-list__formatted {
  max-width: 15rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.log-list__column {
  align-items: center;
  justify-content: center;
  display: flex;
  width: 100%;
}
.log-list__average-container {
  display: flex;
  gap: 20px;
  height: 100%;
  border: 1px solid black;
  border-radius: 15px;
  align-items: center;
  justify-content: space-around;
  padding: 0.5rem;
}
.log-list__average-data {
  align-items: center;
}
@media only screen and (max-width: 576px) {
  .log-list__average-data-title {
    font-size: 0.7rem;
  }
}
.logs-menu__header {
  display: flex;
  align-items: center;
  padding: 2rem 0;
}
@media only screen and (max-width: 768px) {
  .logs-menu__header {
    display: none;
  }
}
.logs-menu__title {
  font-weight: bold;
  font-size: 2rem;
  color: #00441F;
}
.logs-menu__text {
  max-width: 80%;
}
.logs-menu__description {
  padding-left: 3rem;
}
.json-container-diff {
  background: inherit;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  padding: 10px;
}
@media only screen and (max-width: 992px) {
  .json-container-diff--proposal {
    grid-template-columns: none;
    grid-template-rows: repeat(3, 1fr);
  }
}
.json-item {
  background-color: #fff;
  border: 1px solid #00441F;
  border-radius: 12px;
  font-size: 12px;
  margin: 0;
  max-height: 40rem;
  overflow: scroll;
  overflow-x: scroll;
  padding: 10px;
  text-align: left;
  font-weight: bold;
}
.json-item pre {
  margin: 0;
}
.json-item--header {
  font-weight: bold;
  font-family: 'Montserrat';
}
.json-item--error {
  color: var(--red-status);
}
.json-item--loading {
  align-items: center;
  display: flex;
  height: 20rem;
  justify-content: center;
}
.logs-onidata-filters-select {
  width: 100%;
}
#root .onidata-logs-details__list--load-button {
  border-radius: 20px 20px 0 0;
}
#root .onidata-logs-details__list--load-button .ant-collapse-item:last-child {
  border-radius: 0;
}
#root .onidata-logs-details__button.ant-btn-primary {
  border-radius: 0 0 20px 20px !important;
  width: 100%;
}
#root .onidata-logs-details__header {
  align-items: center;
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  margin-bottom: 2rem;
}
#root .onidata-logs-details__range-picker {
  max-width: 300px;
}
#root .onidata-logs-details__status-select {
  width: 15rem;
}
.onidata-queues-modal {
  width: 100%;
  max-height: 400px;
  overflow: scroll;
  font-size: 13;
}
.onidata-queues-modal__title {
  text-align: center;
  font-weight: bold;
}
.onidata-queues-modal__description {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  color: #00441F;
  font-weight: bold;
}
.onidata-queues-modal__name {
  background: #f2f2f2;
  padding: 0 0.5rem;
  border-radius: 5px;
  justify-content: center;
  width: fit-content;
  align-items: center;
  display: flex;
  margin: 0.3rem 0;
}
.onidata-queues-modal__column-title {
  color: #00441F;
}
.onidata-queues-modal__data {
  font-weight: 400;
  font-size: 13px;
  padding: 0.5rem 0;
  border-bottom: 1px solid #00441F;
}
.onidata-queues-modal__see-more {
  cursor: pointer;
  color: #2196f3;
}
.security-modal__title {
  text-align: center;
  font-size: 1.5rem;
  color: #00441F;
}
.security-modal__container-list {
  display: flex;
  justify-content: center;
}
.security-modal__list {
  width: 100%;
}
.security-modal__list-item {
  background: #f2f2f2;
  color: #00441F;
  margin: 1rem;
  border-radius: 10px;
  padding: 0.5rem;
  list-style: none;
}
.download-modal__title {
  text-align: center;
  font-size: 1.5rem;
  color: #00441F;
}
.download-modal__container-list {
  display: flex;
  justify-content: center;
}
.download-modal__list {
  width: 100%;
}
.download-modal__list-item {
  background: #f2f2f2;
  color: #00441F;
  margin: 1rem;
  border-radius: 10px;
  padding: 0.5rem;
  list-style: none;
}
.proposal-step-button-with-history {
  width: 100%;
}
.proposal-step-button-with-history__content {
  display: flex;
  gap: 1.5rem;
  align-items: center;
}
@media only screen and (max-width: 576px) {
  .proposal-step-button-with-history__content {
    flex-wrap: wrap;
  }
}
.proposal-step-button-with-history__children {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
@media only screen and (max-width: 576px) {
  .proposal-step-button-with-history__children {
    flex-wrap: wrap;
  }
}
.proposal-step-button-with-history__container {
  border: 1px solid #d9d9d9;
  background-color: white;
  z-index: 200;
  position: relative;
  border-radius: 20px;
  top: 10px;
}
@media only screen and (max-width: 576px) {
  .proposal-step-button-with-history__container {
    border: none;
    top: 5px;
  }
}
.proposal-step-button-with-history__button {
  max-height: 0px;
  max-width: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 576px) {
  .proposal-step-button-with-history__button {
    max-height: unset;
    max-width: unset;
    margin-left: auto;
  }
}
.proposal-step-button-with-history__open-button {
  font-size: small;
  margin-left: 2.4rem;
}
.proposal-step-skeleton {
  display: flex;
  gap: 0.5em;
}
.accept-contract-modal__title {
  font-size: 1.2rem;
}
.accept-contract-modal__decline-icon {
  font-size: 70px;
  color: #DD4E4E;
}
@media only screen and (max-width: 576px) {
  .accept-contract-modal__back-button {
    margin-bottom: 10px;
  }
}
.accept-contract-modal__decline-button {
  background-color: #DD4E4E;
  border-color: #DD4E4E !important;
  transition: all 0.2s ease-in-out;
}
.accept-contract-modal__decline-button:hover {
  background-color: transparent;
  color: #DD4E4E;
}
.accept-contract__title--terms {
  font-size: 1rem;
}
.accept-contract__inputs-container {
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
@media only screen and (max-width: 576px) {
  .accept-contract__inputs-container {
    padding: 0;
  }
}
.accept-contract__decline-button {
  border-color: #00441F;
  color: #00441F;
  transition: all 0.2s ease-in-out;
}
.accept-contract__decline-button:hover {
  color: #fff;
  background-color: #00441F;
}
@media only screen and (max-width: 1200px) {
  .accept-contract__decline-button {
    margin-bottom: 10px;
  }
}
.documental-step__logs-container {
  margin-bottom: 1rem;
}
.documental-step__container {
  margin-bottom: 2.5rem;
}
.info-card__total-card {
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: space-between;
}
.info-card__total-content {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 70%;
  height: 100%;
}
.info-card__title {
  font-size: 1rem;
}
@media only screen and (max-width: 576px) {
  .info-card__title {
    font-size: 0.8rem;
  }
}
.info-card__value {
  font-size: 1.1rem;
  font-weight: bold;
  color: #00441F;
}
@media only screen and (max-width: 576px) {
  .info-card__value {
    font-size: 1rem;
  }
}
.info-card__icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.info-card__icon svg {
  height: 80%;
  width: 100%;
  color: #00441F;
}
.progress-card__title {
  font-size: 1.3rem;
  font-weight: bold;
  color: #00441F;
}
.progress-card__content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.progress-card__data {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  margin-right: 10px;
}
.progress-card__sub-title {
  font-size: 0.9rem;
}
.progress-card__value {
  font-weight: bold;
  font-size: 0.9rem;
}
.progress-card__no-parcels-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 70%;
  justify-content: space-between;
}
.progress-card__no-parcels-svg {
  font-size: 40px;
  color: #00441F;
}
.progress-card__no-Parcels-text {
  text-align: center;
}
.score-form__info-text {
  color: #00441F;
}
.score-form__form-list--card {
  display: flex;
  flex-direction: row;
}
@media only screen and (max-width: 1200px) {
  .score-form__form-list--card {
    flex-direction: column;
  }
}
.score-form__form-list--input-container {
  width: 94%;
  margin-right: 1%;
  background-color: #fff;
  padding: 2em;
  border-radius: 30px;
}
@media only screen and (max-width: 1200px) {
  .score-form__form-list--input-container {
    width: 100%;
  }
}
.score-form__form-list--remove-button {
  width: 5%;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}
@media only screen and (max-width: 1200px) {
  .score-form__form-list--remove-button {
    width: 100%;
    margin-top: 20px;
    padding: 0.5rem;
  }
}
.score-form__form-list--remove-button:hover {
  background-color: var(--refused-status-color);
  color: #fff;
}
.score-form__form-list--add-button {
  border: 1px solid #00441F;
  color: #00441F;
}
.score-form__form-list--add-button:hover {
  background-color: #00441F;
  color: #fff;
}
.score-form__form-list--sub-tittle {
  font-size: 1rem;
  font-weight: bold;
}
.score__titles {
  font-weight: bold;
  color: #00441F;
}
.score .ecp-card {
  height: 100%;
  padding: 2rem;
}
.score .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #00441F;
}
.score-step__collapse-panel .ant-collapse-header {
  font-size: 1.2rem;
  color: #00441F;
  font-weight: bold;
}
.upload-step-section__info-container {
  display: flex;
  gap: 2rem;
  margin-bottom: 1.5rem;
}
.upload-step-section__skeleton-container {
  display: block;
}
.upload-step-section__files-container .ant-upload-list {
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(auto-fill, minmax(12.5rem, 1fr));
  margin-top: 8px;
}
.upload-step-section__files-container .ant-upload-list::before {
  display: none;
}
.upload-step-section__files-container .ant-upload-list-picture-container {
  width: 100%;
}
.upload-step-section__files-container .ant-upload-list-item {
  margin-top: 0;
}
.upload-step-section__skeleton-button-container {
  margin-bottom: 8px;
}
.upload-step-section__skeleton-container {
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(auto-fill, minmax(12.5rem, 1fr));
}
.upload-step-section__skeleton-button {
  max-width: 11.125rem;
}
.upload-step-section__skeleton-document {
  min-height: 4.125rem;
  border-radius: 1.25rem;
}
.upload-step-section__error-container {
  text-align: center;
}
.upload-step-section__icon {
  color: var(--blue-status);
  font-size: 3rem;
  margin-top: 1rem;
}
.upload-step-section__list {
  list-style-position: inside;
}
.upload-step-section__text-container {
  display: block;
}
.upload-step-section__subtitle {
  color: #000000 !important;
  margin-bottom: 10px;
}
.documental-process__validate {
  display: flex;
  justify-content: space-between;
}
.documental-process__process {
  padding: 1rem;
  color: #00441F;
  display: grid;
  grid-template-columns: minmax(200px, 1fr) minmax(200px, 1fr) minmax(200px, 1fr);
  grid-template-rows: auto;
  gap: 10px;
  padding: 0.5rem 0;
}
.documental-process__process-header {
  font-weight: bold;
}
.documental-process__process-container {
  max-width: 100%;
  border-radius: 10px !important;
  overflow-x: auto;
  background-color: #f2f2f2;
  padding: 1rem 0;
  margin: 40px 0;
}
.documental-process__title-container {
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 576px) {
  .documental-process__title-container {
    display: block;
    text-align: center;
    margin-bottom: 1rem;
  }
}
.documental-process__description {
  margin: 0 !important;
}
.documental-process__email-age {
  padding: 0.5rem;
  border-radius: 10px;
}
.documental-process__email-age--green {
  background-color: rgba(0, 207, 123, 0.19);
}
.documental-process__email-age--red {
  background-color: rgba(255, 106, 106, 0.25);
}
.documental-process__email-age--yellow {
  background-color: rgba(255, 240, 106, 0.53);
}
@media only screen and (max-width: 576px) {
  .documental-process__reports-button {
    margin-top: 2rem;
  }
}
.proposal-step {
  display: flex;
  align-items: center;
}
.proposal-step:not(:last-child) {
  margin-bottom: 1.25rem;
}
.proposal-step:last-child figure::after {
  content: unset;
}
.proposal-step__content {
  display: block;
}
.proposal-step__content-title {
  color: #00441F;
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0.1rem;
}
.proposal-step__content-subtitle {
  color: #00441F;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 0.1rem;
}
.proposal-step__content-description {
  font-size: 0.9rem;
  margin-bottom: 1rem;
}
.reports__status {
  display: flex;
  align-items: center;
  justify-content: center;
}
.reports__error-info {
  color: #2196f3;
}
.modal-product__see-more {
  cursor: pointer;
  color: #2196f3;
}
.modal-product__content {
  padding: 0 0.5rem 0.5rem 0.5rem;
  display: flex;
  align-items: center;
}
.modal-product__header {
  display: flex;
  align-items: center;
}
.master-list__products {
  display: flex;
  align-items: center;
}
.onidata-credentials__form-container {
  background: #fff;
  border-radius: 20px;
  display: flex;
  flex: 1 1 1;
  flex-direction: row;
  gap: 1.5rem;
  margin: 0 auto;
  margin-bottom: 1rem;
  padding: 1.5rem;
}
.onidata-credentials__form-container .ant-card-body {
  width: 100%;
}
@media only screen and (max-width: 768px) {
  .onidata-credentials__form-container {
    flex-direction: column;
  }
}
.onidata-credentials__card {
  margin-bottom: 2rem;
}
@media only screen and (max-width: 768px) {
  .onidata-credentials__card {
    margin-bottom: 1rem;
  }
}
.onidata-credentials__title {
  font-size: 1.4rem;
}
@media only screen and (max-width: 768px) {
  .onidata-credentials__title {
    font-size: 1.2rem;
  }
}
.onidata-credentials__icon-text-container {
  display: flex;
  align-items: flex-start;
  gap: 8rem;
}
@media only screen and (max-width: 768px) {
  .onidata-credentials__icon-text-container {
    display: block;
    gap: 1rem;
  }
}
.onidata-credentials__icon {
  flex: 1;
  margin-top: 1.5rem;
}
@media only screen and (max-width: 768px) {
  .onidata-credentials__icon {
    margin-top: 0rem;
  }
}
.onidata-credentials__text-form-container {
  flex: 0 0 60%;
}
.onidata-credentials__text-container {
  margin-bottom: 2rem;
}
.onidata-credentials__form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 10px;
  justify-content: center;
}
.onidata-credentials__filter {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.75rem;
  width: 100%;
}
.onidata-credentials__help-text {
  align-items: flex-start;
  display: flex;
  gap: 10px;
  max-width: 50%;
}
@media only screen and (max-width: 768px) {
  .onidata-credentials__help-text {
    max-width: unset;
  }
  .onidata-credentials__help-text svg {
    display: none;
  }
}
.unauth-upload-file__container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.unauth-upload-file__instructions {
  background-color: #f2f2f2;
  border-radius: 20px;
  padding: 1rem ;
  margin: 1rem 0 ;
}
.unauth-upload-file__list {
  list-style-position: inside;
  margin: 0;
}
.unauth-upload-file__title {
  font-weight: bold;
  color: #00441F;
}
.unauth-upload-file__card {
  margin: 1rem;
  padding: 2rem;
  width: 900px;
}
@media only screen and (max-width: 768px) {
  .unauth-upload-file__card {
    width: 95%;
    padding: 1rem;
  }
}
.unauth-upload-file__icon {
  color: #00CF7B;
  font-size: 2rem;
  padding-bottom: 0.5rem;
}
.unauth-upload-file__sent {
  padding: 3rem 2rem;
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
}
.unauth-upload-file__hide-upload {
  display: none;
}
.unauth-upload-file__skeleton-document {
  min-height: 4.125rem;
  border-radius: 1.25rem;
}
.unauth-upload-file__upload-svg {
  height: 11rem;
}
@media only screen and (max-width: 768px) {
  .unauth-upload-file__upload-svg {
    display: none;
  }
}
.unauth-upload-file__sent-svg {
  height: 12rem;
  width: fit-content;
}
.suspects-list__card {
  background-color: white;
  padding: 1rem;
  display: flex;
  border-radius: 20px;
  align-items: center;
  justify-content: space-between;
}
.suspects-list__empty {
  justify-content: center;
}
.suspects-list__content {
  display: flex;
  align-items: center;
}
.suspects-list__check {
  padding: 0.5rem;
  background-color: #00CF7B;
  color: white;
  border-radius: 50%;
  font-size: 1.5rem;
  font-weight: bolder;
}
.suspects-list__filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.suspects-list__user {
  font-size: 1.2rem;
  font-weight: bolder;
}
.suspects-list__identifier {
  font-weight: bolder;
  color: #00441F;
  padding: 0 !important;
  margin: 0 !important;
}
.suspects-list__delete {
  color: #DD4E4E;
  font-size: 1.5rem;
  cursor: pointer;
}
.suspects-list__list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
}
@media only screen and (max-width: 768px) {
  .suspects-list__list {
    grid-template-columns: repeat(1, 1fr);
  }
}
.suspects-list__header {
  display: flex;
  background-color: white;
  border-radius: 20px;
  align-items: center;
  margin-bottom: 1rem;
  justify-content: space-between;
  padding: 2rem;
}
@media only screen and (max-width: 768px) {
  .suspects-list__header {
    flex-direction: column;
  }
}
.suspects-list__header svg {
  min-width: 150px!important;
  min-height: 150px !important;
  margin-right: 1rem;
}
@media only screen and (max-width: 768px) {
  .suspects-list__header svg {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .suspects-list__add-user {
    margin-top: 1rem;
  }
}
.suspects-list__input-search {
  width: 30%;
}
@media only screen and (max-width: 768px) {
  .suspects-list__input-search {
    width: 100%;
  }
}
.warning-text__alert-title {
  color: rgba(0, 0, 0, 0.85);
  font-size: 1.3rem;
  margin-bottom: 0.1rem;
}
.warning-text__alert-icon {
  font-size: 35px;
  margin-top: 10px;
}
@media only screen and (max-width: 576px) {
  .warning-text__alert-icon {
    display: none;
  }
}
.warning-text__alert-description {
  margin-bottom: 0.5rem;
}
.warning-text__contact-info {
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
}
.omnichannel__action {
  font-size: 1.5rem;
  margin-left: 0.15rem;
  margin-right: 0.15rem;
  padding: 0.25rem;
  cursor: pointer;
}
.omnichannel__action--disabled {
  cursor: not-allowed;
  color: var(--disabled-color) !important;
}
div.find-user-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  margin: auto;
  padding: 4rem;
  width: min(35.8rem, 100%);
  min-height: 35.8rem;
}
@media only screen and (max-width: 576px) {
  div.find-user-card {
    padding: 1rem;
    min-height: unset;
  }
}
.find-user-description {
  font-size: 1.15rem;
  line-height: 1.22;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}
@media only screen and (max-width: 576px) {
  .find-user-description {
    gap: 1rem;
    font-size: 1rem;
  }
}
.find-user-link {
  display: flex;
  gap: 1em;
  padding: 1em;
}
.find-user-link .anticon {
  display: flex;
  align-items: center;
}
.find-user-link-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}
.find-user-title {
  font-weight: 600;
  color: var(--primary-color);
  margin: 0 auto 0 auto;
  text-align: center;
}
.find-user-information {
  background-color: #f2f2f2;
  border-radius: 20px;
  width: 100%;
}
.find-user-information__title {
  color: #00441F;
  font-weight: bold;
}
.find-user-information__item-title {
  font-weight: bold;
}
.find-user-information__panel {
  border-bottom: none !important;
}
.find-user-cpf__input {
  width: min(335px, 100%);
}
.find-user-cpf__input button {
  border-radius: 0px 20px 20px 0px !important;
}
.find-user-cpf__input {
  width: min(335px, 100%);
}
.find-user-cpf__input button {
  border-radius: 0px 20px 20px 0px !important;
}
.find-user-covenant-select__description {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.find-user-covenant-select__description-icon {
  margin-bottom: 1rem;
}
.find-user-covenant-select__input {
  width: min(335px, 100%);
}
.find-user-covenant-select__input button {
  margin-top: 0.5rem;
  width: min(335px, 100%);
}
.intl-input-group {
  display: flex;
  align-items: center;
  justify-content: center;
}
.intl-input-group__money {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.intl-input-group__percentage {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.intl-input-group__suffix {
  width: 40px;
  height: 40px;
  background-color: #DFDFDF;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: #919191;
  border: 1px solid #d9d9d9;
}
.intl-input-group__preffix-money {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.intl-input-group__suffix-percentage {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.intl-input-group__footer {
  display: flex;
  font-size: 0.75rem;
  justify-content: space-between;
  margin-top: 0.5rem;
  width: 100%;
  filter: opacity(60%);
}
.intl-input-group__footer--only-max {
  justify-content: flex-end;
}
.drawer-filters__button.ant-btn-ghost {
  border-color: var(--primary-color);
  color: var(--primary-color);
  background: #fff;
  font-weight: 600;
  position: relative;
}
.drawer-filters__button.ant-btn-ghost:hover {
  background: #f2f2f2;
}
.drawer-filters__button.ant-btn-ghost[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
}
.drawer-filters__drawer .ant-drawer-body {
  padding: 0;
  padding-bottom: 50px;
}
.drawer-filters__drawer .ant-drawer-header {
  border-width: 2px;
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.drawer-filters__drawer .ant-drawer-title {
  font-weight: bold;
}
.drawer-filters__drawer .ant-drawer-close {
  padding: 0px;
  position: static;
}
.drawer-filters__drawer .ant-form-item,
.drawer-filters__drawer .ant-checkbox-group {
  margin: 0;
  width: 100%;
}
.drawer-filters__drawer .ant-checkbox {
  top: unset;
}
.drawer-filters__drawer .anticon {
  display: flex;
  align-items: center;
}
.drawer-filters__drawer .ant-form-item-control-input {
  min-height: unset!important;
}
.drawer-filters__drawer .ant-radio-wrapper,
.drawer-filters__drawer .ant-checkbox-wrapper {
  margin: 0;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
}
.drawer-filters__drawer .ant-radio-wrapper {
  padding: 1em 1.25em;
}
.drawer-filters__drawer label:not(label .ant-checkbox-wrapper) {
  user-select: none;
  min-width: 100%;
}
.drawer-filters__drawer p {
  margin: 0;
}
.drawer-filters__drawer .ant-radio-wrapper > span,
.drawer-filters__drawer .ant-checkbox + span {
  padding: 0;
}
.drawer-filters__button.ant-btn-ghost > .drawer-filters__badge-count {
  position: absolute!important;
  top: -0.5em;
  right: -0.5em;
  pointer-events: none;
}
.drawer-filters__button.ant-btn-ghost > .drawer-filters__badge-count .ant-badge-count {
  background-color: var(--primary-color);
  color: #fff;
  box-shadow: 0 0 0 0.5px var(--primary-color) inset;
  border-radius: 100%;
  line-height: 25px;
  height: 25px;
  width: 25px;
  padding: 0px;
  font-weight: bold;
  font-size: 13px;
}
.drawer-filters__selected-filters {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--secondary-color);
  padding: 0.5em 1em;
  font-size: 0.9em;
}
.drawer-filters__button-clear {
  padding: 0;
  display: inline;
  height: unset!important;
  border: none;
  line-height: 1;
  font-weight: 600;
}
.drawer-filters__button-clear:focus-visible {
  outline: 1px solid var(--primary-color);
}
.drawer-filters__line {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  padding: 1em;
  border-bottom: 1px solid var(--secondary-color);
  cursor: pointer;
}
.drawer-filters__line:focus-within {
  background: #e6e6e6;
}
.drawer-filters__line-collapsable {
  align-items: center;
  width: 100%;
}
.drawer-filters__line-collapsable-header {
  transition: background 0.3s;
}
.drawer-filters__line-collapsable-header:hover,
.drawer-filters__line-collapsable-header:focus-visible {
  background: var(--secondary-color);
}
.drawer-filters__line-collapsable-icon {
  transition: transform 0.2s ease-out;
  transform: rotate(-180deg);
}
.drawer-filters__line-collapsable-icon--collapsed {
  transform: rotate(0);
}
.drawer-filters__line-collapsable-content {
  display: flex;
  flex-direction: column;
  background: #f9f9f9;
}
.drawer-filters__line-collapsable-content--collapsed {
  display: none;
}
.drawer-filters__line-collapsable-content > * {
  width: 100%;
}
.drawer-filters__line-collapsable-item {
  display: flex;
  justify-content: space-between;
  padding: 1em 1.25em;
}
.drawer-filters__line-collapsable-item:focus-within {
  background: #e6e6e6;
}
.drawer-filters__line-collapsable-item:last-child {
  border-bottom: 1px solid var(--secondary-color);
}
.drawer-filters__button-search {
  position: absolute;
  z-index: 100;
  top: 95%;
  right: 5%;
}
.crivo-status-circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
.crivo-subtitle {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.product-item-svg {
  width: 40px;
  height: 40px;
}
.product-item__item-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}
.product-item__item-name {
  font-weight: bold;
}
.product-item__item-image {
  flex: 0 0 40px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: auto;
}
.product-item__item-image--backgroud {
  background-color: gray;
}
.product-item__item {
  text-align: start;
  flex: 1;
  max-width: 100%;
}
.product-item__item-subtitle {
  font-weight: bold;
  margin-bottom: 10px;
}
.product-item__item-subtitle:last-of-type {
  margin-top: 10px;
}
.product-item__item-description {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 70%;
}
.product-item-button {
  padding: 16px;
  text-align: start;
  position: relative;
  transition: all 0.4s;
  background-color: #fff;
  width: 100%;
  border: none;
  border-radius: 20px;
  outline: none;
  cursor: pointer;
  border: 1px solid #f2f2f2;
}
.product-item-button--secondary {
  border: 1px solid var(--primary-color);
}
.product-item-button__text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 70%;
}
.product-item-button--active,
.product-item-button:hover {
  background-color: #f2f2f2;
}
.product-item-button--active.product-item-button--secondary,
.product-item-button:hover.product-item-button--secondary {
  background-color: var(--primary-color);
  color: #fff;
}
.product-item-button--active.product-item-button--secondary h5,
.product-item-button:hover.product-item-button--secondary h5,
.product-item-button--active.product-item-button--secondary svg,
.product-item-button:hover.product-item-button--secondary svg,
.product-item-button--active.product-item-button--secondary svg path,
.product-item-button:hover.product-item-button--secondary svg path {
  color: #fff;
  fill: #fff;
}
.product-item-button--active.product-item-button--secondary .ant-spin-dot-item,
.product-item-button:hover.product-item-button--secondary .ant-spin-dot-item {
  background-color: #fff;
}
.product-item-button__icon {
  position: absolute;
  top: 50%;
  right: 16px;
  font-size: 30px;
  transform: translateY(-50%);
}
.product-item-button__icon > svg {
  color: #00441F;
}
.products-dropdown__menu {
  max-width: 400px;
  padding: 1rem;
}
@media only screen and (max-width: 576px) {
  .products-dropdown__menu {
    max-width: 80vw;
  }
}
.products-dropdown__item-subtitle {
  font-weight: bold;
  font-size: 1rem;
}
.products-dropdown__body > div:not(:last-child) {
  margin-bottom: 10px;
}
@media only screen and (max-width: 768px) {
  .products-dropdown__current-product {
    color: #000000d9 !important;
    font-size: 14px;
    font-weight: 700;
  }
}
.select-products-sidebar__menu {
  width: 250px;
  padding: 15px;
  box-shadow: 7px 6px 5px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}
.select-products-sidebar__loading-icon {
  font-size: 30px;
}
.select-products-sidebar__loading-icon .ant-spin-dot-item {
  background-color: white;
}
@media only screen and (max-width: 480px) {
  .select-products-sidebar {
    padding-left: 3rem;
  }
}
.server-table-filter {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 8px;
}
@media only screen and (max-width: 576px) {
  .server-table-filter {
    flex-direction: row!important;
  }
}
@media only screen and (max-width: 992px) {
  .server-table-filter {
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .server-table-filter > * {
    width: 100%;
  }
  .server-table-filter .search-with-tool-tip {
    justify-content: center;
    flex-direction: column;
    width: 100%;
  }
  .server-table-filter .drawer-filters {
    width: 100%;
  }
  .server-table-filter button {
    width: 100%;
  }
}
button .ant-pagination-item-link {
  padding-right: 5px;
}
.table-filter button {
  border-radius: 0px 20px 20px 0px !important;
}
.ant-table-thead {
  height: 38px;
}
.server-table .ant-table-thead th {
  background-color: transparent;
}
.server-table__updateTime {
  font-size: 14px;
}
.server-table-expand-image {
  height: 40px;
  width: 40px !important;
  object-fit: scale-down;
  border-radius: 50%;
}
.server-table__color-text-total {
  color: var(--primary-color);
}
.server-table__limits-pagination {
  margin: 0 5px;
}
.server-table__check-icon {
  color: var(--primary-color);
  margin-left: 5px;
}
.server-table__pagination {
  margin-top: 15px;
}
.server-table__pagination .ant-pagination-options {
  display: none;
}
@media only screen and (max-width: 992px) {
  .switch-responsive-layout {
    margin-left: 5px;
    margin-bottom: 5px;
  }
}
@media only screen and (max-width: 768px) {
  .switch-responsive-layout {
    margin-bottom: 0px;
  }
}
.server-table-switch-filter {
  transform: translateY(30px);
}
@media only screen and (max-width: 1200px) {
  .server-table-switch-filter {
    transform: translateY(0);
  }
}
.image-cell {
  padding-left: 10px !important;
}
.switch-input {
  width: 90px;
  white-space: nowrap;
}
.caption-h1 {
  font-weight: bold;
  font-size: 16px;
  display: flex;
  justify-content: flex-end;
}
.caption {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  flex: 1;
}
.caption__container {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
}
@media only screen and (max-width: 1200px) {
  .caption__container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.caption__text {
  font-size: 14px;
}
@media only screen and (max-width: 1200px) {
  .caption__text {
    font-size: 7px;
  }
}
.steps-container .ant-steps-item.ant-steps-item-wait {
  padding: 0;
}
.steps-container .ant-steps-item.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: #00441F !important;
}
.steps-container .ant-steps-item.ant-steps-item-wait .ant-steps-item-icon {
  border-color: #00441F !important;
}
.steps-container .ant-steps-item-finish.ant-steps-item {
  padding: 0 !important;
}
.steps-container .ant-steps-item-icon {
  margin: 0;
}
.steps-container .ant-steps-item-finish .ant-steps-item-icon {
  background-color: #00441F !important;
  border-color: #00441F !important;
  margin: 0;
  padding: 0;
}
.steps-container .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #fff !important;
}
.ant-steps-item.ant-steps-item-process.steps-simulator.ant-steps-item-active {
  padding: 0 !important;
}
.steps-simulator .ant-steps-item-icon {
  background-color: #fff !important;
  border-color: #00441F !important;
  margin: 0;
  padding: 0;
}
.steps-simulator .ant-steps-item-icon .ant-steps-icon {
  color: #00441F !important;
}
.wrapper-action-button {
  display: inline-flex;
  justify-content: center;
}
.wrapper-action-button .action-button-label {
  padding-right: 0;
  align-self: center;
}
.wrapper-action-button:hover {
  background-color: #00441F;
  cursor: pointer;
}
.action-icon-button {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #00441F;
}
.action-container {
  margin-left: 20px;
}
@media (max-width: 1024px) {
  .action-container {
    margin-left: 0px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
@media (max-width: 768px) {
  .action-container {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
.request-cnd-checkbox .ant-checkbox-wrapper {
  font-size: 15px !important;
}
.cnd-request-buttons {
  width: auto;
}
@media (min-width: 1440px) {
  .cnd-request-buttons {
    width: 25%;
  }
}
.contact-button {
  height: 50px !important;
  background-color: #25d366 !important;
  box-shadow: 2px 2px 3px #999 !important;
  border: 0 !important;
  position: fixed !important;
  right: 20px !important;
  bottom: 10px !important;
  width: 50px !important;
  z-index: 11 !important;
}
.contact-button p {
  animation: textAnimation 0.5s;
  font-size: 18px !important;
  line-height: 37.5px !important;
}
.contact-button:hover {
  width: 150px !important;
  color: white !important;
}
#up-contact-button {
  bottom: 90px !important;
}
#user-contact-support {
  bottom: 50px !important;
}
.tab-contract .ant-tabs-tab {
  border-color: #E0E5CF !important;
  color: #E0E5CF !important;
  border-radius: 15px 15px 0px 0px !important;
  border-bottom-color: #fff;
}
.tab-contract .ant-tabs-tab.ant-tabs-tab-active {
  border-color: #00441F !important;
  border-bottom-color: #FFF;
}
.tab-contract .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: 700;
}
.card-container {
  padding: 5px;
  box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.2);
}
.tab-contract .ant-tabs-nav {
  margin: 0px !important;
}
.form-contract__title {
  font-size: 1.1rem;
  text-transform: uppercase;
  color: #E0E5CF !important;
  position: relative;
  display: inline-block;
}
.form-contract__title::after {
  content: '';
  width: 45px;
  height: 3px;
  border-radius: 2px;
  position: absolute;
  bottom: -5px;
  left: 0;
  background-color: #00441F;
}
.final-contract__container-signature {
  margin-top: 35px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (min-width: 768px) {
  .watermark-container {
    transform: translate(-10%, 0) skewY(-30deg) !important;
  }
}
.contract-container {
  margin-bottom: 15px;
  flex-direction: column;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contract-container .signature-input {
  border: 1px black solid;
}
.contract-container__input {
  margin-top: 35px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.contract-editable {
  border: 1px solid black;
  margin-bottom: 10px;
  border-radius: 8px;
  outline: none;
}
.contract-editable--bold,
.contract-editable--italic {
  width: 28px;
  height: 28px;
  font-family: cursive;
  border: none;
  font-size: 18px;
  background-color: transparent;
  border-radius: 4px;
  transition: 300ms;
  margin-bottom: 2px;
  cursor: pointer;
}
.contract-editable--bold:hover,
.contract-editable--italic:hover {
  background-color: #A3D8ED;
}
.contract-editable--active {
  background-color: #A3D8ED;
}
.contract-editable--bold {
  margin-right: 5px;
}
.infobanc-skeleton {
  width: 300px !important;
  height: 60px !important;
}
.infobanc {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.infobanc__panel .ant-collapse-header {
  color: #E0E5CF !important;
}
.open-areas-card {
  background-color: #005e2b;
  color: #fff;
  border-radius: 4px;
  user-select: none;
  text-align: center;
}
.add-linked-button {
  height: 0px !important;
}
.skeleton-backoffices {
  width: 400px !important;
  height: 20px;
}
@media (max-width: 500px) {
  .skeleton-backoffices {
    width: 200px !important;
  }
}
.date-input {
  position: relative;
}
.date-input__calendar {
  visibility: hidden;
  width: 0 ;
  height: 40px !important;
  padding: 0 !important;
  border: 0 !important;
  position: absolute !important;
  left: 0px;
  top: 0px;
}
.date-input__calendar .ant-picker-input {
  display: none;
}
.date-input__datepicker {
  z-index: 10;
}
.document-preview-pop .ant-notification-notice-btn {
  float: left;
}
.documents-view-content {
  display: grid !important;
  grid-template-columns: repeat(7, 1fr) !important;
  justify-content: center !important;
  gap: 20px !important;
}
.documents-view-content__image {
  width: 150px;
  height: 150px;
  border-radius: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  object-fit: cover;
}
.documents-view-content__image:hover {
  opacity: 0.5;
  cursor: pointer;
}
.documents-view-content__document-card {
  width: 150px !important;
  height: 150px !important;
  border: 1px solid #00441F;
  border-radius: 15px;
  padding: 15px;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
}
.documents-view-content__modal {
  display: flex;
  justify-content: center;
  z-index: 9999;
}
.documents-view-content__modal .ant-modal-body {
  height: 90vh;
  padding: 3.5rem 1.5rem 1.5rem;
}
.file-pdf-filled {
  font-size: 35px;
}
.decode-uri {
  text-align: center;
  font-size: 12px;
  cursor: pointer;
}
.decode-uri-name {
  text-align: center;
  cursor: pointer;
}
.animation-image {
  z-index: 999;
  color: #fff;
  position: absolute;
}
.drawer-title {
  width: 400px;
}
.card-width {
  width: 850px;
}
.pulsate-fwd {
  -webkit-animation: pulsate-fwd 2s ease-in-out infinite both;
  animation: pulsate-fwd 2s ease-in-out infinite both;
}
/* ----------------------------------------------
 * Generated by Animista on 2021-5-28 13:52:5
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation pulsate-fwd
 * ----------------------------------------
 */
@-webkit-keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes pulsate-fwd {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.custom-drawer .ant-drawer-footer {
  border-top: 1px solid #e0e0e0;
  display: block;
}
.custom-drawer .ant-drawer-footer :first-child {
  float: left;
}
.custom-drawer .ant-drawer-footer :last-child {
  float: right;
}
.shake-lr {
  -webkit-animation: shake-lr 6s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite alternate both;
  animation: shake-lr 6s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite alternate both;
}
@-webkit-keyframes shake-lr {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  10% {
    -webkit-transform: rotate(8deg);
    transform: rotate(8deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(10deg);
    transform: rotate(10deg);
  }
  80% {
    -webkit-transform: rotate(-8deg);
    transform: rotate(-8deg);
  }
  90% {
    -webkit-transform: rotate(8deg);
    transform: rotate(8deg);
  }
}
@keyframes shake-lr {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  10% {
    -webkit-transform: rotate(8deg);
    transform: rotate(8deg);
  }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(10deg);
    transform: rotate(10deg);
  }
  80% {
    -webkit-transform: rotate(-8deg);
    transform: rotate(-8deg);
  }
  90% {
    -webkit-transform: rotate(8deg);
    transform: rotate(8deg);
  }
}
.footer {
  background: #000;
  color: #fff;
  height: 54px;
  text-align: center;
  border-top: 0.3px solid #000;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 768px) {
  .footer {
    font-size: 0.7rem;
  }
}
.upload-list-inline .ant-upload-list-item {
  float: left;
  width: 200px;
  margin-right: 8px;
  cursor: pointer;
}
.upload-list-inline .ant-upload-list-item-card-actions span {
  transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 400ms;
}
.upload-list-inline .ant-upload-list-item-card-actions:hover span {
  color: red;
}
.upload-img-size {
  width: 100%;
  max-height: calc(100vh - 20vh);
  object-fit: scale-down;
}
.upload-button-size-explain {
  border: 1px solid #00441F;
  border-radius: 5px;
  min-height: 40px;
}
.upload-list-inline [class*='-upload-list-rtl'] .ant-upload-list-item {
  float: right;
}
.upload-skeleton {
  width: 200px !important;
  height: 60px !important;
}
.upload-skeleton:nth-child(2) {
  margin-left: 10px;
}
.upload-skeleton:nth-child(3) {
  margin-left: 10px;
}
@media (max-width: 1115px) {
  .upload-skeleton:nth-child(3) {
    display: none;
  }
}
@media (max-width: 765px) {
  .upload-skeleton {
    width: 160px !important;
  }
  .upload-skeleton:nth-child(3) {
    display: none;
  }
}
@media (max-width: 630px) {
  .upload-skeleton:nth-child(2) {
    display: none;
  }
}
@media (max-width: 576px) {
  .upload-skeleton {
    width: 200px !important;
  }
  .upload-skeleton:nth-child(2) {
    display: inline;
  }
}
@media (max-width: 457px) {
  .upload-skeleton {
    width: 200px !important;
  }
  .upload-skeleton:nth-child(2) {
    display: none;
  }
}
.simulation-card-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.simulation-card-header-title {
  display: flex;
  flex-direction: row;
}
@media only screen and (max-width: 768px) {
  .simulation-card-header-title {
    flex-direction: column;
  }
}
.simulation-card-header-expiration-date {
  display: flex;
  flex-direction: row;
}
.proposal-flag {
  padding: 1px 2px;
  border-radius: 4px;
  font-size: 14px;
  margin-top: 1px;
  margin-right: 5px;
  margin-bottom: 0px;
  color: #fff;
}
.proposal-flag--crivo {
  min-width: 120px;
  text-align: center;
}
.proposal-flag--signature {
  min-width: 80px;
  max-width: auto;
  border: 1px solid #00CF7B;
  background-color: #00CF7B;
}
.proposal-flag--exclusive-tax {
  margin-left: 10px;
  border: 1px solid #FF7A40;
  background-color: #FF7A40;
  height: 28px;
}
@media (max-width: 476px) {
  .proposal-flag--exclusive-tax {
    margin-left: 10px;
  }
}
@media (max-width: 375px) {
  .proposal-flag--exclusive-tax {
    font-size: 12px;
    height: 22px;
  }
}
@media (max-width: 320px) {
  .proposal-flag--exclusive-tax {
    display: block;
    flex-wrap: wrap;
    width: 55px !important;
    height: 35px;
    font-size: 10px;
  }
}
.proposal-flag--exclusive-revised-tax {
  margin-left: 10px;
  border: 1px solid #00CF7B;
  background-color: #00CF7B;
  height: 28px;
}
@media (max-width: 476px) {
  .proposal-flag--exclusive-revised-tax {
    margin-left: 10px;
  }
}
@media (max-width: 375px) {
  .proposal-flag--exclusive-revised-tax {
    font-size: 12px;
    height: 22px;
  }
}
@media (max-width: 320px) {
  .proposal-flag--exclusive-revised-tax {
    display: block;
    flex-wrap: wrap;
    width: 55px !important;
    height: 35px;
    font-size: 10px;
  }
}
@media (max-width: 425px) {
  .cnd-tag {
    white-space: break-spaces !important;
  }
}
.appendice-size {
  margin-top: -5px;
  width: 2px;
  background-color: #818181;
  height: 15px !important;
  margin-left: 12px !important;
}
@media (max-width: 991px) {
  .appendice-size {
    height: calc(100% - 35px) !important;
  }
}
.responsive-cols-step {
  display: flex;
}
@media (max-width: 991px) {
  .responsive-cols-step {
    width: 100%;
    display: block;
  }
}
.card-simulation {
  display: flex;
  flex-wrap: wrap;
}
@media screen and (max-width: 999px) {
  .card-simulation {
    border: 0 !important;
    flex-direction: column;
  }
}
.loading-simulation-card {
  width: 800px;
}
@media screen and (max-width: 880px) {
  .loading-simulation-card {
    width: 550px;
  }
}
@media screen and (max-width: 635px) {
  .loading-simulation-card {
    width: 320px;
  }
}
@media screen and (max-width: 395px) {
  .loading-simulation-card {
    width: 210px;
  }
}
.card-form-commission {
  border: 1px solid #00441F;
  border-radius: 10px;
}
.card-form-commission form:first-of-type div:last-child {
  margin-bottom: 0;
}
.simulation-card-review-modal {
  width: 100%;
}
.simulation-card-review-modal__img {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.simulation-card-review-modal__img img {
  width: 5rem;
  height: 5rem;
}
.simulation-card-review-modal__label {
  font-size: 1rem;
  font-weight: bold;
}
.simulation-card-icons {
  margin-left: 10px;
  cursor: pointer;
  width: 35px;
}
@media only screen and (max-width: 768px) {
  .simulation-card-icons {
    margin-left: unset;
  }
}
.alienating-card {
  border-radius: 5px;
  border: 2px solid #E48F2B !important;
}
.alienating-title {
  background: #E48F2B;
  text-align: center;
  color: white;
}
.minuta-preview-step__section-title {
  font-size: bold;
  font-size: 20px;
}
.minuta-preview-step__signer-section {
  background: #f2f2f2;
  padding: 8px;
  border-radius: 8px;
}
.form-contract-clausulas table {
  width: 100%;
  padding: 5px;
}
.form-contract-clausulas td {
  border: 1px solid;
  min-width: 40px;
  padding: 3px;
}
.space-between {
  display: flex;
  justify-content: space-between !important;
}
.form-contract-none {
  display: none !important;
}
.form-contract-clausulas {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
}
.form-contract-clausulas span {
  margin-right: 5px;
}
.form-contract-clausulas p {
  margin: 0;
}
.form-contract__skeleton {
  width: 100% !important;
  margin-bottom: 5px;
}
.form-contract__collapse-active {
  background-color: #00CF7B;
  padding: 2px 5px;
  border-radius: 4px;
}
.form-contract__collapse-desactive {
  background-color: #DD4E4E;
  padding: 2px 5px;
  border-radius: 4px;
  color: white;
}
.valuation-date-formdate {
  margin-top: 30px;
}
.read-only-formdate-input {
  background-color: #f3f3f3;
}
@media (max-width: 1200px) {
  .email-wrapper-formdate,
  .phone-wrapper-formdate {
    margin-top: 15px;
  }
}
.add-income-container {
  height: 55px;
  margin: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}
.add-button-container {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.add-button-circle {
  width: 25px;
  height: 25px;
  background-color: white;
  display: flex;
  padding: 2px;
  position: relative;
}
.add-button-circle:hover {
  cursor: pointer;
}
.plus-sign {
  font-size: 26px;
  color: #9FC519;
  top: -12px;
  left: 1px;
  position: relative;
}
.plus-sign:hover {
  color: #1f6b33;
}
.form-user-checkbox .ant-form-item-control {
  border-bottom: 1px solid #d9d9d9;
  padding: 4px 4px 4px 15px;
  height: 40px;
}
.form-without-margin-last-form-item .ant-form-item:last-child {
  margin-bottom: 0 !important;
}
.table-form-user-data thead {
  background-color: #2e74b5;
}
.table-form-user-data thead th {
  color: white;
  text-align: center;
  font-size: 11px;
  border: 1px solid black;
  border-collapse: collapse;
}
.table-form-user-data table,
.table-form-user-data td {
  font-size: 11px;
  color: black;
  border: 1px solid black;
  border-collapse: collapse;
}
.cell {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 1.5em;
  font-size: 1em;
  border: 0.5px solid #b8cce4;
  color: #1f497d !important;
  font-weight: 700;
}
.cell p {
  font-size: 0.75em !important;
}
.cell .ant-input {
  text-align: center !important;
  outline: none !important;
  border: none !important;
  color: #1f497d !important;
  font-weight: 700 !important;
  border-radius: 0  !important;
  font-size: 0.75em !important;
  height: 1em !important;
}
.cell .ant-input:focus {
  outline: none !important;
  border: none !important;
}
.cell .ant-input::-webkit-outer-spin-button,
.cell .ant-input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}
.cell .ant-select {
  text-align: center !important;
  outline: none !important;
  border: none !important;
  color: #1f497d !important;
  font-weight: 700 !important;
  border-radius: 0  !important;
  width: 100% !important;
}
.cell .ant-input-number {
  text-align: center !important;
  outline: none !important;
  border: none !important;
  color: #1f497d !important;
  font-weight: 700 !important;
  border-radius: 0  !important;
  width: 100% !important;
  font-size: 0.75em !important;
  height: 1.5em !important;
  padding: 0;
}
.cell .ant-input-number .ant-input-number-handler-wrap {
  display: none;
}
.cell .ant-input-number .ant-input-number-input {
  text-align: center !important;
  outline: none !important;
  border: none !important;
  color: #1f497d !important;
  font-weight: 700 !important;
  border-radius: 0  !important;
  font-size: 1em !important;
  height: 1em !important;
  width: 100% !important;
}
.cell .ant-select-selector {
  text-align: center !important;
  outline: none !important;
  border: none !important;
  color: #1f497d !important;
  font-weight: 700 !important;
  border-radius: 0  !important;
  font-size: 0.75em !important;
  padding: 0 !important;
  height: 1.5em !important;
  width: 100% !important;
}
.cell .ant-select-selector:focus {
  outline: none !important;
  border: none !important;
}
.cell .ant-select-selector::-webkit-outer-spin-button,
.cell .ant-select-selector::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}
.cell .ant-select-selection-item {
  line-height: inherit !important;
  height: 1.5em !important;
}
.cell .ant-select-single {
  height: 1em !important;
}
.cell p {
  margin: 0px !important;
}
.cell-primary {
  background-color: #1f497d;
  color: #FFF;
  font-weight: bolder;
  justify-content: center;
  display: flex;
  flex-direction: row;
}
.cell-secundary {
  background-color: #dbe5f1;
  color: #1f497d;
  font-weight: 700;
}
.cell-secundary .ant-input {
  background-color: #dbe5f1 !important;
  color: #1f497d !important;
}
.spreadsheet {
  border: 0.5px solid #b8cce4;
  padding-top: 2em !important;
  padding-bottom: 2em !important;
  text-align: center;
}
.cell-value {
  justify-content: flex-end !important;
  border: none !important;
  padding-right: 1em ;
}
.cell-value .ant-input {
  text-align: end !important;
  outline: none !important;
  border: none !important;
  color: #1f497d !important;
  font-weight: 700 !important;
  font-size: 1em !important;
  height: 1.5em !important;
}
.cell-text {
  padding-left: 1em ;
  justify-content: flex-start !important;
  border: none !important;
}
.cell-title {
  padding-left: 1em ;
  justify-content: flex-start !important;
  text-decoration: underline;
}
.cell-value-final {
  padding-right: 1em ;
  justify-content: flex-end !important;
  text-decoration: underline;
}
.cell-value-final .ant-input {
  padding-right: 1em !important;
  justify-content: flex-end !important;
  text-decoration: underline !important;
}
.spreadsheet-cr-model {
  padding-top: 2em !important;
}
.spreadsheet-limit {
  max-height: calc(15 * 1.5em);
  overflow-y: auto;
}
.spreadsheet-limit::-webkit-scrollbar {
  width: 5px;
}
.spreadsheet-limit::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}
.spreadsheet-limit::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
.resendLink-title {
  font-size: 16px;
}
.share-button {
  height: auto !important;
}
.share-button .ant-btn {
  height: auto !important;
}
.share-button--small {
  height: 28px !important;
  font-size: 11.5px;
}
.share-steps-button {
  margin-right: 5px;
  font-size: 12.5px !important;
  max-height: 28px;
}
@keyframes showMenu {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.icon {
  font-size: 20px;
  color: #000;
}
.notification-icon {
  font-size: 20px;
  color: #fff !important;
  position: relative;
}
.notification-icon--with-notifications::after {
  content: attr(data-count);
  position: absolute;
  background-color: var(--red-status);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  top: 0;
  right: 0;
  font-size: 13px;
  transform: translate(50%, -50%);
  padding-top: 9px;
}
.mobile-icon {
  font-size: 25px;
  color: #000 !important;
}
.content {
  width: 300px;
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
  background: #fff;
  margin-top: 20px;
  padding: 0 5px;
  border-radius: 4px;
}
@media (max-width: 1024px) {
  .content {
    margin-top: 30px;
  }
}
.context {
  position: relative;
  height: 100%;
  width: 100%;
}
.paragraph {
  position: relative;
}
.navbar-header {
  line-height: 0;
  background-color: #00441F;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
@media only screen and (max-width: 768px) {
  .navbar-header {
    padding-right: 0;
    padding-left: 0;
  }
}
@media only screen and (max-width: 1200px) {
  .navbar-header {
    min-height: 90px;
  }
}
.navbar-header__communication {
  cursor: pointer;
  margin-bottom: 0;
}
.navbar-image {
  width: 125px !important;
  margin-bottom: 5px;
}
.userInfo-level::after {
  content: " -";
  margin-right: 5px;
}
@media (max-width: 1024px) {
  .userInfo-level {
    display: none;
  }
  .userInfo-level::after {
    content: "";
    margin-right: 0;
  }
}
.row-navbar {
  max-height: 64px;
  width: 100%;
}
@media only screen and (max-width: 1200px) {
  .row-navbar {
    min-height: 90px;
  }
}
@media only screen and (max-width: 768px) {
  .row-navbar {
    padding-right: 1rem;
  }
}
.logo-alignment {
  align-items: center;
}
.logo-alignment > div {
  margin-right: 10px;
}
@media only screen and (max-width: 1200px) {
  .logo-alignment {
    transform: translateX(36px);
    margin-right: 0;
    flex-direction: column;
    align-items: flex-start;
  }
}
.invisible-col {
  display: none;
}
@media only screen and (max-width: 768px) {
  .invisible-col {
    display: flex;
    max-width: 85px;
  }
}
.alignment-items-dropdown {
  display: flex;
  border-radius: 3px;
}
.alignment-items-dropdown:hover {
  cursor: pointer;
}
.alignment-items-dropdown:hover svg {
  color: #00441F;
  cursor: pointer;
}
.alignment-items-dropdown:hover > p {
  color: #00441F;
  cursor: pointer;
}
.alignment-items-dropdown:not(:last-child) {
  border-radius: 0;
  border-bottom: 1px solid #bbbbbb;
}
.alignment-items-dropdown p {
  font-weight: bold;
  margin: 0px;
}
.dropdown-icons-menu {
  padding: 20px;
  margin: 7px 0px;
  min-width: 240px;
  max-width: 90vw;
}
.dropdown-icons-menu__product-dropdown {
  display: none;
}
@media only screen and (max-width: 768px) {
  .dropdown-icons-menu__product-dropdown {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
  }
}
.dropdown-icons-menu__user-name {
  width: 160px;
}
@media only screen and (max-width: 768px) {
  .navbar-product-dropdown {
    display: none;
  }
}
.expand-menu-icons {
  height: 35px;
  width: 35px;
  background: #d8d8d8;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: opacity 0.2s;
}
.expand-menu-icons:hover {
  opacity: 0.9;
  cursor: pointer;
}
.expand-menu-icons:hover svg {
  transition: color 0.2s;
  color: #00441F;
  cursor: pointer;
}
@media (max-width: 1024px) {
  .expand-menu-icons {
    top: -5px;
  }
}
@media (max-width: 380px) {
  .expand-menu-icons {
    height: 30px;
    width: 30px;
  }
}
.navbar-notification {
  position: relative;
}
.navbar-notification section {
  display: flex;
  align-items: center;
  transition: color 0.2s;
  width: 100%;
}
.navbar-notification section:hover {
  cursor: pointer;
}
.navbar-notification section:hover svg {
  color: #fff;
  cursor: pointer;
}
.navbar-notification section:hover > p {
  color: #00CF7B;
  cursor: pointer;
}
.ant-notification.ant-notification-topRight div:not([class]) .ant-notification-notice.proposal-notification.ant-notification-notice-closable {
  border-radius: 5px;
  border-left: #00CF7B 8px solid;
}
.ant-notification.ant-notification-topRight .proposal-notification .ant-notification-notice-message {
  color: #00CF7B;
  font-weight: 800;
}
.ant-notification.ant-notification-topRight .proposal-notification .ant-notification-notice-message--error {
  color: var(--red-status) !important;
}
.ant-notification.ant-notification-topRight .proposal-notification .ant-notification-notice__title {
  margin-left: 5px;
}
.ant-notification.ant-notification-topRight .proposal-notification .ant-notification-notice__title--error {
  color: var(--red-status) !important;
}
.ant-notification.ant-notification-topRight .proposal-notification .ant-notification-notice__description {
  display: flex;
  flex-direction: column;
  color: #227353;
}
.ant-notification.ant-notification-topRight .proposal-notification .ant-notification-notice__description--error {
  color: var(--red-status) !important;
}
.ant-notification.ant-notification-topRight .proposal-notification .ant-notification-notice__details {
  color: #00CF7B;
  text-decoration: underline;
  margin-left: 0.5px;
}
.ant-notification.ant-notification-topRight .proposal-notification .ant-notification-notice__details--error {
  color: var(--red-status) !important;
}
.ant-notification.ant-notification-topRight .proposal-notification--error {
  border-left: var(--red-status) 8px solid !important;
}
.notification__notification-read {
  width: max-content;
  border: 1px solid #00441F;
  color: #00441F;
  padding: 0 1rem;
  border-radius: 30px;
  margin: 0.5rem;
}
.notification__pagination {
  margin-top: 1rem;
}
.notification__divider {
  height: 55vh;
}
@media (max-width: 1024px) {
  .notification__divider {
    display: none;
  }
}
.notification__content {
  height: 55vh;
  display: flex;
  width: 100%;
  max-width: 68%;
}
@media (max-width: 1024px) {
  .notification__content {
    max-width: none;
  }
}
.notification__title {
  text-transform: uppercase;
  color: #00441F !important;
}
@media (max-width: 600px) {
  .notification__title {
    font-size: 20px !important;
  }
}
.notification__scroll {
  height: calc(100% - 60px);
  max-height: calc(100% - 60px);
  overflow-y: auto;
}
.notification__scroll-title {
  height: 100%;
  min-height: 150px;
  max-height: calc(100vh - 620px);
  overflow-y: auto;
}
.notification__expanded-modal {
  height: 55vh;
  display: flex;
  gap: 1em;
}
.notification__center-message {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 1024px) {
  .notification__header-tabs {
    background: white;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
.notification__modal-header {
  display: flex;
  align-items: center;
}
.notification__new {
  padding-left: 1rem;
}
.notification__new-item {
  margin-left: 0.5rem;
  border: 1px solid #00CF7B;
  color: #00CF7B;
  padding: 0 0.5rem;
  font-size: 0.8rem;
  justify-content: center;
  border-radius: 20px;
}
.notification__nav {
  width: 100%;
}
@media (min-width: 1024px) {
  .notification__nav {
    max-width: 350px;
  }
}
.notification__card {
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 5px;
  width: 100%;
}
.notification__card:not(:last-child) {
  border-bottom: 1px solid black;
}
@media (max-width: 1024px) {
  .notification__card {
    margin: 0.3rem auto;
    max-width: 500px;
  }
}
.notification__title-card {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 2px;
  color: #00441F !important;
}
.notification__header {
  margin-top: 2px;
  margin-bottom: 5px;
}
.notification__modal {
  width: 90vw !important;
  max-width: 900px;
}
.notification__modal .ant-modal-content {
  width: 100% !important;
}
.notification__date-card {
  font-weight: 500;
}
.notification__relative-icon {
  position: relative;
  width: auto !important;
}
.notification__bx-shadow {
  box-shadow: -4px 4px 4px 1px rgba(0, 0, 0, 0.1);
}
@media (max-width: 1024px) {
  .notification__mobile-hide {
    display: none !important;
  }
}
.notification__redirect-proposal-button {
  padding: 0 !important;
}
.notification__redirect-proposal-button span {
  text-decoration: underline;
}
.notification__text-padding {
  overflow-wrap: break-word;
  padding: 0 10px;
  cursor: pointer;
}
.notification__goBack-button {
  height: 100%;
}
@media (min-width: 1025px) {
  .notification__goBack-button {
    display: none;
  }
}
.unauth-content {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.unauth-upload-header {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #E0E5CF;
}
.unauth-logo-file-upload {
  max-width: 200px;
  max-height: 68px;
  padding: 5px;
}
.messages {
  background-color: #fff;
  max-width: 950px;
  width: 100%;
  border-radius: 15px;
  margin: 30px 0;
  padding: 15px;
  -webkit-box-shadow: 0px 3px 6px #272D3B33;
  box-shadow: 0px 3px 6px #272D3B33;
}
@media screen and (max-width: 965px) {
  .messages {
    width: 75%;
  }
}
@media screen and (max-width: 680px) {
  .messages {
    width: 100%;
    margin-top: 0;
    margin-bottom: 30px;
    border-radius: 0 0 8px 8px ;
  }
}
.messages-content-header {
  display: flex;
  align-items: center;
  justify-content: center;
}
.messages-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.messages-content-documents {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 95%;
  box-shadow: 0 3px 6px rgb(39 45 2.95%);
}
.messages-content-documents .card-style.p-3.flex.flex-column.flex-justify-center.flex-align-center.image-card {
  margin-left: 5px;
}
@media screen and (max-width: 520px) {
  .messages-content-documents {
    flex-direction: column;
  }
  .messages-content-documents .card-style.p-3.flex.flex-column.flex-justify-center.flex-align-center.image-card {
    margin-left: 0;
  }
}
.messages-content-info {
  font-size: 30px;
  margin: 0;
}
.document-container {
  background-color: #fff;
  width: 100%;
  border-radius: 15px;
  -webkit-box-shadow: 0px 3px 6px #272D3B33;
  box-shadow: 0px 3px 6px #272D3B33;
}
.document-grid {
  display: grid;
  grid-template-columns: 470px 470px;
  justify-content: center;
  gap: 18px;
}
@media screen and (max-width: 965px) {
  .document-grid {
    grid-template-columns: 470px;
  }
}
@media screen and (max-width: 482px) {
  .document-grid {
    grid-template-columns: 340px;
  }
}
@media screen and (max-width: 342px) {
  .document-grid {
    grid-template-columns: 320px;
  }
}
.document-upload-dashed {
  width: 75%;
  border: 2px dashed #00441F;
  border-radius: 15px;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
}
.document-upload-dashed .ant-upload-list.ant-upload-list-picture {
  margin-bottom: 10px;
}
.documents-submit {
  width: 100%;
  display: flex;
  justify-content: center;
}
.documents-submit-button {
  width: 300px;
  min-height: 50px;
  text-transform: uppercase;
  background-color: #00CF7B !important;
  border: none !important;
  color: white !important;
  -webkit-box-shadow: 0px 3px 6px #272D3B33 !important;
  box-shadow: 0px 3px 6px #272D3B33 !important;
  font-weight: 500 !important;
}
.documents-submit-button:hover {
  transform: translateY(-5px);
}
.documents-list-item {
  margin-bottom: 25px;
}
.document-title {
  font-size: 1.4em;
  font-weight: 600;
  margin-bottom: 0;
}
@media screen and (max-width: 600px) {
  .document-title {
    font-size: 1em;
    white-space: normal;
  }
}
.documents-done-indicator {
  font-size: 1.4em;
}
.documents-sigCanvas {
  border: solid 1px black;
  width: 90%;
}
.image-animate {
  opacity: 0.7;
}
.rotate-phone {
  -webkit-animation: rotate-phone 2s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite;
  animation: rotate-phone 2s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite;
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}
@-webkit-keyframes rotate-phone {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
  }
  50% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
  }
  90% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
  }
  100% {
    opacity: 0;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
  }
}
@keyframes rotate-phone {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: bottom 100%;
    transform-origin: bottom 100%;
  }
  50% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: bottom 100%;
    transform-origin: bottom 100%;
    opacity: 1;
  }
  90% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: bottom 100%;
    transform-origin: bottom 100%;
    opacity: 0.9;
  }
  91% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: bottom 100%;
    transform-origin: bottom 100%;
    opacity: 0;
  }
  100% {
    opacity: 0;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: bottom 100%;
    transform-origin: bottom 100%;
  }
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.antd-base-table {
  width: 100% !important;
  overflow-x: scroll;
}
.antd-base-table .ant-table-container {
  max-width: 100% !important;
}
.antd-base-table table:first-of-type {
  font-size: 12px;
}
.antd-base-table table:first-of-type thead {
  position: relative;
}
.antd-base-table table:first-of-type thead th {
  position: sticky;
  top: 0;
  z-index: 1;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  text-transform: uppercase;
  color: white;
  padding: 5px !important;
  background-color: #00441F;
}
.antd-base-table table:first-of-type thead th .ant-table-column-sorters {
  padding: 0px 0px !important;
  min-height: 28px;
}
.antd-base-table table:first-of-type thead th :hover {
  color: #00441F;
}
.antd-base-table table:first-of-type tbody tr td {
  padding: 9.5px 5px !important;
}
.antd-base-table table:first-of-type tbody tr td:first-child {
  text-align: center;
}
.antd-base-table table:first-of-type tbody tr:nth-child(even) {
  background: #f6f8fa;
}
.antd-base-table table:first-of-type tbody tr:nth-child(odd) {
  background: #FFF;
}
button .ant-pagination-item-link {
  padding-right: 5px;
}
.table-filter button {
  border-radius: 0px 20px 20px 0px !important;
}
.table-filter .ant-input-group-addon {
  border-radius: 25px 0 0 25px;
}
.table-filter__select {
  width: 150px !important;
}
.server-table-filters {
  position: relative;
}
.server-table-filters .ant-form-item {
  margin-bottom: 0;
}
.server-table-filters .ant-col.ant-form-item-label {
  padding-bottom: 0 !important;
}
@media only screen and (min-width: 886px) {
  .server-table-filters__overlay {
    position: fixed;
    z-index: 49;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
  }
  .server-table-filters__overlay--text-container {
    position: relative;
    height: 100%;
    width: 100%;
  }
  .server-table-filters__overlay--text {
    position: absolute;
    color: white !important;
    font-style: italic;
    font-weight: 500 !important;
    top: 10%;
    left: 45%;
    user-select: none;
  }
  .server-table-filters__overlay--icon {
    margin-right: 20px;
  }
}
@media only screen and (max-width: 885px) {
  .server-table-filters__overlay {
    z-index: 0 !important;
    display: none;
  }
}
.server-table-filters__layout-primary {
  margin-right: 1rem;
  max-width: 500px;
  flex: 1 1 100px;
}
@media only screen and (max-width: 768px) {
  .server-table-filters__layout-primary {
    margin-right: 0rem;
    margin-bottom: 10px;
  }
}
.server-table-filters__layout-primary .ant-input {
  min-width: 100px;
  margin-right: 0.5rem;
}
@media only screen and (min-width: 886px) {
  .server-table-filters__grid-expanded {
    transition: padding-bottom 1s linear;
    position: absolute;
    top: 110%;
    padding: 0px 7.5px 7.5px 7.5px;
    z-index: 51;
    border-radius: 5px 0px 5px 5px;
    margin-left: 0px !important;
    width: 100%;
    background-color: white;
    animation: fadein 200ms;
    border: 1px solid #00441F;
  }
}
@media only screen and (max-width: 885px) {
  .server-table-filters__grid-expanded {
    position: static;
    margin-left: 0px !important;
    width: 100%;
    align-items: flex-end;
    justify-content: space-between;
  }
}
@media only screen and (max-width: 1021px) {
  .server-table-filters__grid-expanded {
    top: 110%;
  }
}
.server-table-filters__layout-grid-expanded {
  flex: 0 1 33.3% !important;
}
@media only screen and (max-width: 885px) {
  .server-table-filters__layout-grid-expanded {
    flex: 0 1 50% !important;
  }
}
@media (max-width: 767px) {
  .server-table-filters__layout-grid-expanded {
    flex: 1 100% !important;
  }
}
.server-table-filters__justify-group-second-col {
  justify-content: flex-end;
  flex-wrap: nowrap;
}
@media only screen and (max-width: 885px) {
  .server-table-filters__justify-group-second-col {
    justify-content: flex-start;
  }
}
@media only screen and (max-width: 1200px) {
  .server-table-filters__justify-group-second-col {
    margin-top: 0.5rem;
  }
}
.server-table-filters__dropdown button {
  border-radius: 20px 20px 20px 20px;
}
.server-table-filters__dropdown:hover {
  transition: 0.2s;
  cursor: pointer;
}
.server-table-filters__dropdown:hover .ant-badge-count {
  background-color: #005326 !important;
}
@media only screen and (max-width: 885px) {
  .server-table-filters__dropdown {
    display: none;
  }
}
.server-table-filters__button-actived button {
  transition: border-radius 260ms;
  border-radius: 20px 20px 0px 0px;
  border-bottom: none;
  margin-bottom: -6px;
  height: 45px;
  box-shadow: none;
  background-color: #fff;
}
.server-table-filters__button-more {
  z-index: 51;
  position: relative;
  border: 1px solid #00441F;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
  outline: none;
  touch-action: manipulation;
  padding: 4px 15px;
  font-size: 14px;
  background-color: #F0F2F5;
  transition: border-radius 260ms;
  height: 40px;
}
.server-table-filters__button-more:hover,
.server-table-filters__button-more:focus {
  border-color: #007736;
}
.server-table-filters__button-more__disabled {
  background-color: #F4F5F4;
  border: 1px solid #D8D9D8;
}
.server-table-filters__button-more__disabled:hover {
  background-color: #F4F5F4;
  border: 1px solid #D8D9D8;
}
.server-table-filters__badge-count {
  position: absolute;
  top: -16px;
  right: -20px;
}
.server-table-filters__badge-count .ant-badge-count {
  background-color: #00441F;
  color: #fff;
  box-shadow: 0 0 0 0.5px #00441F inset;
  border-radius: 100%;
  line-height: 25px;
  height: 25px;
  width: 25px;
  padding: 0px;
  font-weight: bold;
  font-size: 13px;
}
@media only screen and (max-width: 885px) {
  .server-table-filters__width-button-sm {
    width: 100%;
  }
}
@media only screen and (max-width: 885px) {
  .server-table-filters__mobile-full {
    width: 100%;
  }
}
.server-table-filters__more-options-button-alone > button {
  background-color: #00441F;
  color: #fff;
}
.server-table-filters__more-options-button-alone > button:hover,
.server-table-filters__more-options-button-alone > button:focus {
  background-color: #004e24 !important;
  color: #fff !important;
}
.server-table-filters__more-options-dropdown {
  position: relative;
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.server-table-filters__more-options-dropdown button {
  border-radius: 20px 20px 20px 20px;
}
.server-table-filters__more-options-dropdown section svg {
  color: #fff !important;
  cursor: pointer;
}
.server-table-filters__more-options-dropdown section > div {
  color: #fff !important;
  cursor: pointer;
}
@media only screen and (max-width: 885px) {
  .server-table-filters__more-options-dropdown {
    width: 100%;
  }
}
.server-table-filters__button-options-actived button {
  transition: border-radius 260ms;
  transition: background-color 250ms;
  border-radius: 20px 20px 0px 0px;
  border-bottom: none;
  box-shadow: none;
  border: 1px solid #00441F;
  background-color: #fff;
}
.server-table-filters__button-options-actived button section svg {
  transition: 110ms;
  color: #00441F !important;
  cursor: pointer;
}
.server-table-filters__button-options-actived button section > div {
  transition: 110ms;
  color: #00441F !important;
  cursor: pointer;
}
.server-table-filters__button-options-actived button:hover,
.server-table-filters__button-options-actived button:focus {
  background-color: #fff;
}
.server-table-filters__grid-more-buttons {
  position: absolute;
  top: 45px;
  padding: 0px 7.5px 7.5px 7.5px;
  z-index: 55;
  border-radius: 0px 0px 5px 5px;
  border: 1px solid #00441F;
  border-top: none;
  margin-left: 0px !important;
  width: 250px;
  background-color: white;
  animation: fadein 200ms;
}
@media only screen and (max-width: 885px) {
  .server-table-filters__grid-more-buttons {
    position: static;
    width: 100%;
    margin: 0px;
  }
}
.server-table-filters__grid-more-buttons-new-server-table {
  position: absolute;
  top: 110%;
  left: 0;
  margin: 0;
}
.server-table-filters__grid-more-buttons-new-server-table button {
  border: none;
}
.server-table-filters__button-options-actived-new-server-table {
  position: relative;
}
.server-table-filters__divider-options {
  padding: 0px !important;
  margin: 0px;
}
@media only screen and (max-width: 885px) {
  .server-table-filters__divider-options {
    z-index: 100;
    margin-top: 7.5px !important;
  }
}
.server-table-filters__section-relative {
  position: relative;
}
.server-table-filters__icon-plus-less {
  font-size: 16px;
  margin-top: 1px;
  align-self: center;
  padding-left: 2px;
  padding-right: 2px;
}
.server-table-filters__icon-plus-less svg {
  color: #00441F !important;
  cursor: pointer;
}
.server-table-filters__icon-plus-less:hover {
  transition: 0.2s;
  cursor: pointer;
}
.server-table-filters__icon-plus-less:hover svg {
  color: #005e2b !important;
}
.server-table-filters__icon-plus-less__disabled svg {
  color: #CDCDCD !important;
  cursor: pointer;
}
.server-table-filters__icon-plus-less__disabled:hover {
  transition: 0.2s;
  cursor: pointer;
}
.server-table-filters__icon-plus-less__disabled:hover svg {
  color: #CDCDCD !important;
}
.server-table-filters__text-more-filters {
  font-weight: bold;
  font-size: 15px;
  color: #00441F !important;
  cursor: pointer;
}
.server-table-filters__text-more-filters:hover {
  transition: 0.2s;
  cursor: pointer;
}
.server-table-filters__text-more-filters:hover > div {
  color: #005e2b !important;
}
.server-table-filters__text-more-filters__disabled {
  color: #CDCDCD !important;
}
.server-table-filters__text-more-filters__disabled:hover {
  transition: 0.2s;
  cursor: pointer;
}
.server-table-filters__text-more-filters__disabled:hover div {
  color: #CDCDCD !important;
}
.server-table-filters__more-options-text {
  font-size: 15px;
}
.server-table-filters__section-layout {
  display: flex;
  justify-content: center;
}
.server-table-filters__more-options-button {
  width: 250px;
  position: relative;
  border: 1px solid transparent;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  user-select: none;
  outline: none;
  touch-action: manipulation;
  padding: 4px 15px;
  font-size: 14px;
  background-color: #00441F;
  transition: border-radius 260ms;
  height: 40px;
}
.server-table-filters__more-options-button:hover,
.server-table-filters__more-options-button:focus {
  background-color: #005326;
}
.server-table-filters__more-options-button__disabled {
  background-color: #F4F5F4;
  border: 1px solid #D8D9D8;
  cursor: not-allowed;
}
.server-table-filters__more-options-button__disabled:hover,
.server-table-filters__more-options-button__disabled:focus {
  background-color: #F4F5F4;
  border: 1px solid #D8D9D8;
}
@media only screen and (max-width: 885px) {
  .server-table-filters__more-options-button {
    width: 100% !important;
    z-index: 0 !important;
  }
}
.server-table-filters__icon-button-model {
  font-size: 16px;
  margin-top: 1px;
}
.server-table-filters__max-width-button {
  min-width: 170px;
}
@media only screen and (max-width: 885px) {
  .server-table-filters__max-width-button {
    width: 100%;
  }
}
.server-table-filters__text-button {
  width: 100%;
}
.server-table-filters__text-button:hover,
.server-table-filters__text-button:focus {
  width: 100%;
  background-color: #aed10f1e !important;
  color: #00441F !important;
}
.server-table__scroll-style ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.server-table__scroll-style ::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.server-table__scroll-style ::-webkit-scrollbar-thumb {
  background: #C8C8C8;
  border-radius: 20px;
}
.server-table__scroll-style ::-webkit-scrollbar-thumb:hover {
  background: #9e9e9e;
}
.server-table__pagination .ant-pagination-options {
  display: none !important;
}
.server-table__tag-totals {
  font-size: 12px;
  height: 32px;
  align-self: center;
  border: 1px solid #00441F;
  text-transform: uppercase;
  background-color: white;
  border-radius: 3px;
  padding: 5px;
}
.server-table__color-black {
  color: black;
}
.server-table__limits-pagination {
  margin: 0 5px;
}
.switch-responsive-layout {
  margin-bottom: 5px;
}
@media only screen and (max-width: 885px) {
  .switch-responsive-layout {
    margin-left: 5px;
  }
}
@media (max-width: 767px) {
  .switch-responsive-layout {
    margin-bottom: 0px;
  }
}
@media (min-width: 1200px) {
  .server-table-switch-filter {
    transform: translateY(30px);
  }
}
@media (max-width: 1440px) {
  .table-name {
    white-space: nowrap;
    font-size: 10px;
  }
}
.ant-layout-sider {
  background: #fff;
  color: #000;
}
.ant-layout-sider-trigger {
  position: relative;
  background: #fff;
  color: #000;
}
.ant-layout-sider-children {
  position: absolute;
  margin-right: 5px;
  height: 100%;
  width: 100%;
}
.ant-layout-sider-children ::-webkit-scrollbar {
  width: 5px;
  margin-left: 10px;
  margin-right: 10px;
}
.ant-layout-sider-children ::-webkit-scrollbar-track {
  background: #fff;
  height: 20px;
  border-radius: 20px ;
}
.ant-layout-sider-children ::-webkit-scrollbar-thumb {
  background: #c8c8c8;
  border-radius: 10px;
}
.ant-layout-sider-children ::-webkit-scrollbar-thumb:hover {
  background: #9e9e9e;
}
@media only screen and (max-width: 576px) {
  .menu-sidebar {
    transition: none;
  }
}
.menu-sidebar .ant-menu-item:active,
.menu-sidebar .ant-menu-submenu-title:active {
  background-color: rgba(0, 207, 123, 0.2) !important;
}
.menu-sidebar .ant-menu-item-selected {
  color: #00441F !important;
  background-color: rgba(0, 207, 123, 0.2) !important;
}
.menu-sidebar .ant-menu-item-selected::after {
  border-right: 3px solid #00CF7B !important;
}
@media (max-width: 575px) {
  .menu-sidebar {
    padding-top: 5px !important;
  }
}
.sidebar-size * {
  transition: none!important;
  animation: none!important;
}
.sidebar-size *::after {
  transition: none!important;
  animation: none!important;
}
.sidebar-size *::before {
  transition: none!important;
  animation: none!important;
}
.ant-layout-sider,
.ant-menu,
.ant-layout-sider-trigger {
  transition: all 0.3s !important;
}
@media only screen and (max-width: 576px) {
  .ant-layout-sider,
  .ant-menu,
  .ant-layout-sider-trigger {
    transition: none !important;
  }
}
.cnd-review__expiration {
  margin-bottom: 3rem;
}
.cnd-review__expiration-error-message {
  color: var(--red-status);
}
.cnd-review__expiration-title {
  color: #00441F;
  font-weight: bold;
  font-size: 1.2rem;
}
.cnd-review__expiration-description {
  margin-bottom: 1.5rem;
}
.cnd-review__expiration-date-container {
  display: flex;
  align-items: center;
  background-color: #f2f2f2;
  border-radius: 20px;
  padding: 1rem;
}
.cnd-review__expiration-date-container .ant-form-item {
  margin-bottom: 0;
}
.cnd-review__expiration-edit-date-container {
  background-color: #f2f2f2;
  border-radius: 20px;
  padding: 1rem;
}
.cnd-review__expiration-edit-date-container .ant-form-item {
  margin-bottom: 0;
}
.cnd-review__expiration-buttons-container {
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;
}
.cnd-review__expiration-save-button {
  align-self: flex-end;
}
.cnd-review__expiration-icon {
  font-size: 1.1rem;
  margin-left: 0.5rem;
  cursor: pointer;
}
.cnd-review__expiration-icon--green {
  color: var(--green-status);
}
.cnd-review__expiration-icon--primary-color {
  color: #00441F;
}
.cnd-review__expiration-icon--text {
  display: inline-block;
  cursor: auto;
}
.review-form__form-item {
  flex-direction: column;
}
.review-form__form-item .ant-form-item-label {
  text-align: left;
}
.review-backoffice-history__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 0.8rem;
}
.review-backoffice-history__action {
  color: var(--primary-color);
  cursor: pointer;
  padding: 0.2rem 0rem;
  border-radius: 1rem;
}
@media only screen and (max-width: 576px) {
  .review-backoffice-history__action {
    font-size: 0.8rem;
  }
}
.review-backoffice-history__action:hover {
  text-decoration: underline;
  font-weight: 500;
}
.review-step-history {
  padding: 1rem 1.25rem;
  border-radius: 20px;
  background-color: #f2f2f2;
  text-align: left;
}
.review-step-history__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
@media only screen and (max-width: 992px) {
  .review-step-history__header {
    flex-direction: column;
  }
}
.review-step-history__tags-container {
  margin-bottom: 0.8rem;
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.5rem;
  align-items: center;
}
.review-step-history__modified-info {
  color: var(--primary-color);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 0.7rem;
  flex-wrap: wrap;
}
@media only screen and (max-width: 992px) {
  .review-step-history__modified-info {
    flex-direction: row;
    align-items: flex-start;
    column-gap: 0.5rem;
  }
}
.review-step-history__title-message {
  font-size: 0.85rem;
  font-weight: bold;
  display: block;
  margin: 0rem 0 0.5rem;
}
@media only screen and (max-width: 992px) {
  .review-step-history__title-message {
    margin-top: 0.9rem;
  }
}
.review-step-history__commentary {
  padding: 0.2rem 0 !important;
  margin-bottom: 0.8rem;
  font-size: 0.85rem;
  border-right: none !important;
  border-left: none !important;
}
.review-step-history__new-item-properties {
  font-size: 0.8rem;
}
.review-step-history__new-item-properties--value {
  font-weight: 600;
}
.review-step-history__new-item-separator {
  margin: 0 0.5rem;
}
.review-step-history__share-status-link {
  font-size: 0.7rem;
  text-align: center;
  border-radius: 0.5rem;
  color: #E0E5CF;
  white-space: unset !important;
  padding: 0.5rem;
  border: 1px solid var(--primary-color);
  background-color: rgba(0, 68, 31, 0.1);
}
.review-step-history__share-status-expires {
  font-size: 0.7rem;
  font-weight: bold;
}
.review-step-history__change-item {
  font-size: 0.8rem;
  margin: 0;
  padding: 0.1rem;
  transition: 0.2s all ease-in-out;
}
.review-step-history__change-item:hover {
  background-color: #DFDFDF;
  font-weight: 500;
}
.review-step-history__proposal-history-change-container {
  background-color: #DFDFDF;
  border-radius: 0.5rem;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
}
.review-step-history__proposal-history-change-text,
.review-step-history__proposal-history-change-link {
  font-size: 0.8rem;
  font-weight: 600;
  text-align: center;
}
.review-step-history__proposal-history-change-link {
  color: var(--primary-color);
  cursor: pointer;
  display: block;
  margin-top: 0.5rem;
}
.review-step-history__proposal-history-change-link:hover {
  text-decoration: underline;
}
.review-step-history .ant-collapse-borderless {
  margin-bottom: 0.5rem;
}
.review-step-history .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-header {
  padding-left: 1.2rem;
  font-weight: bold;
  font-size: 0.9rem;
  border-bottom: 1px solid var(--light-gray-4);
  padding-bottom: 0.2rem;
  padding-top: 0.5rem;
  margin-bottom: 0;
}
.review-step-history .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-header > .ant-collapse-arrow {
  left: 0;
  padding-top: 0.5rem;
}
.review-step-history .ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box {
  padding: 0.6rem 0 0;
}
.review-step-history td {
  border: 1px solid;
  min-width: 40px;
}
@-webkit-keyframes slide-in-fwd-center {
  0% {
    -webkit-transform: translateZ(-1400px);
    transform: translateZ(-1400px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes slide-in-fwd-center {
  0% {
    -webkit-transform: translateZ(-1400px);
    transform: translateZ(-1400px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
.can-review-wrapper {
  border: 2px solid #00A8E2;
  border-radius: 5px;
}
.review-backoffice-card-title {
  color: #00441F !important;
  font-weight: 700 !important;
  font-family: Montserrat !important;
  font-size: 1.5rem !important;
  line-height: 20px !important;
}
.review-label {
  color: #65737a;
  font-size: 14px !important;
  font-weight: 500;
}
.review-backoffice-card__histories-container {
  margin-bottom: 2rem;
}
.review-backoffice-history-card {
  border: 1px solid #E0E5CF;
  border-radius: 5px;
}
.slide-in-fwd-center {
  -webkit-animation: slide-in-fwd-center 1000ms steps(1000, end) infinite alternate both;
  animation: slide-in-fwd-center 1000ms steps(1000, end) infinite alternate both;
}
.review-backoffice-expired-proposal {
  background-color: #f2f2f2;
  padding: 1rem;
  border-radius: 20px;
}
.review-backoffice-expired-proposal__title {
  font-size: 1.3rem;
}
.egi-step__section-title {
  color: #00441F;
  font-weight: bold;
}
@media only screen and (max-width: 576px) {
  .egi-step__section-title {
    font-size: 1.2rem;
  }
}
.review__rich .tox {
  border-radius: 20px!important;
  border: #d9d9d9d9 1px solid;
  color: rgba(0, 0, 0, 0.85);
}
.request-cnd__panel .ant-collapse-header {
  color: #00441F !important;
}
.request-cnd__panel .ant-collapse-content-box {
  padding: 0 20px !important;
}
.request-cnd__list {
  list-style-type: none;
  padding: 0 20px;
}
.request-cnd__list li {
  margin: 5px 0;
}
@page {
  size: auto;
  margin: 5mm;
}
#cet-template,
#print-template {
  display: none;
}
.cet-container__w-10 {
  width: 10%;
}
.cet-container__cet-result {
  width: 15%;
  border: 3px solid black;
  text-align: center;
}
.cet-container__proponente {
  border-top: 1px solid;
}
.cet-container__text {
  max-width: 780px;
}
.cet-container__text p {
  margin-bottom: 0px;
}
@media print {
  body {
    -webkit-print-color-adjust: exact !important;
  }
  @page {
    size: A4 landscape;
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
  }
  #cet-template,
  #print-template {
    display: block;
  }
}
.pdf-container canvas {
  width: 100% !important;
  height: 100% !important;
}
.react-pdf__Document {
  display: flex;
  justify-content: center;
}
.pager {
  display: flex;
  align-items: center;
  justify-content: center;
}
.pager__pagination {
  margin: 0 25px;
}
.pager__download {
  padding: 10px;
  border-radius: 50%;
  background-color: #00441F;
  transition: all 0.2s;
}
.pager__download:hover {
  background-color: #005e2b;
}
.search-filter-by-select {
  min-width: 380px;
}
@media only screen and (max-width: 480px) {
  .search-filter-by-select {
    min-width: 100%;
  }
}
.search-filter-by-select__group {
  display: flex;
  align-items: flex-end;
}
.search-filter-by-select__identifier {
  width: 125px;
}
.search-filter-by-select__identifier .ant-select .ant-select-selector {
  background-color: #f2f2f2;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
}
.search-filter-by-select__search {
  flex: 1;
}
.search-filter-by-select__search .ant-input,
.search-filter-by-select__search .ant-input-affix-wrapper {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.search-filter-by-select__large {
  padding: 0 20px;
}
.search-filter-by-select__large .ant-select-selector {
  min-width: 10rem !important;
  height: 40px !important;
}
.search-filter-by-select__large .ant-input-affix-wrapper {
  padding: 8.1px 11px !important;
}
.search-filter-by-select__borderless .ant-input-group-addon {
  border: none;
}
.search-filter-by-select__borderless .ant-input-affix-wrapper {
  border-color: transparent;
}
.search-filter-by-select__borderless .ant-input-affix-wrapper-focused,
.search-filter-by-select__borderless body .ant-input-affix-wrapper-focused {
  border-color: black !important;
  box-shadow: 0 0 0 1px black !important;
}
.search-filter-by-select__borderless .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector,
.search-filter-by-select__borderless body .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: var(--primary-color) !important;
  box-shadow: 0 0 0 1px var(--primary-color) !important;
}
.search-filter-by-select__borderless .ant-select-selector:hover {
  border-color: black !important;
  box-shadow: 0 0 0 1px black !important;
}
.search-filter-by-select__borderless .ant-input-group {
  box-shadow: 0 3px 5px 1px rgb(0 0 0%);
  border-radius: 30px;
}
.search-identifier-input {
  min-width: 380px;
}
@media only screen and (max-width: 480px) {
  .search-identifier-input {
    min-width: 100%;
  }
}
.search-identifier-input__group {
  display: flex;
  align-items: flex-end;
}
.search-identifier-input__identifier {
  width: 125px;
}
.search-identifier-input__identifier .ant-select .ant-select-selector {
  background-color: #f2f2f2;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
}
.search-identifier-input__search {
  flex: 1;
}
.search-identifier-input__search .ant-input,
.search-identifier-input__search .ant-input-affix-wrapper {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 100%;
}
.search-identifier-input__search-button {
  height: 35px !important;
}
.search-identifier-input__large {
  padding: 0 20px;
}
.search-identifier-input__large .ant-select-selector {
  min-width: 10rem !important;
  height: 40px !important;
}
.search-identifier-input__large .ant-input-affix-wrapper {
  padding: 8.1px 11px !important;
}
.search-identifier-input__borderless .ant-input-group-addon {
  border: none;
}
.search-identifier-input__borderless .ant-input-affix-wrapper {
  border-color: transparent;
}
.search-identifier-input__borderless .ant-input-affix-wrapper-focused,
.search-identifier-input__borderless body .ant-input-affix-wrapper-focused {
  border-color: black !important;
  box-shadow: 0 0 0 1px black !important;
}
.search-identifier-input__borderless .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector,
.search-identifier-input__borderless body .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: var(--primary-color) !important;
  box-shadow: 0 0 0 1px var(--primary-color) !important;
}
.search-identifier-input__borderless .ant-select-selector:hover {
  border-color: black !important;
  box-shadow: 0 0 0 1px black !important;
}
.search-identifier-input__borderless .ant-input-group {
  box-shadow: 0 3px 5px 1px rgb(0 0 0%);
  border-radius: 30px;
}
.search {
  width: max(300px, 100%);
}
@media (max-width: 784px) {
  .search {
    width: max(150px, 100%);
  }
}
.search-with-tool-tip {
  display: flex;
  flex-direction: row;
  gap: 5px;
}
.tooltip-custom {
  display: flex;
  gap: 5px;
  align-items: center;
}
.verification-input {
  background: none;
  width: 100% !important;
}
.verification-input .vi__character--default {
  border: none;
  font-size: 1.3rem;
  line-height: 50px;
  color: black;
  background-color: #efefef;
  margin-left: 6%;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  box-sizing: border-box;
  border-radius: 10px;
  font-family: Montserrat;
  font-weight: bold;
}
@media only screen and (max-width: 576px) {
  .verification-input .vi__character--default {
    margin-left: 10px;
  }
}
.verification-input .vi__character--inactive--default {
  color: transparent;
}
.limiter-table button .ant-pagination-item-link {
  padding-right: 5px;
}
.limiter-table .ant-form-item {
  margin-bottom: 0px;
}
.limiter-table .table-filter button {
  border-radius: 0px 20px 20px 0px !important;
  padding-left: 8px;
  padding-right: 8px;
}
.limiter-table .ant-table table,
.limiter-table .ant-table {
  border-radius: 10px 0px 0px 0px;
}
.limiter-table .ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 10px !important;
}
.limiter-table .ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 0 !important;
}
.limiter-table div#server-table {
  max-height: calc(calc(calc(100vh - 64px) - 15px * 2 - 38px - 70px) - (32px + 8px) - 8px);
  overflow: auto;
}
@media only screen and (max-width: 992px) {
  .limiter-table div#server-table {
    max-height: none;
    height: auto;
  }
}
.limiter-table .antd-base-table.server-table--deparment-row div#server-table {
  max-height: calc(calc(calc(calc(100vh - 64px) - 15px * 2 - 38px - 70px) - (32px + 8px) - 8px) - 50px);
}
@media only screen and (max-width: 992px) {
  .limiter-table .antd-base-table.server-table--deparment-row div#server-table {
    max-height: none;
    height: auto;
  }
}
.limiter-table .ant-table-wrapper.antd-base-table {
  overflow: hidden;
}
.limiter-table ::-webkit-scrollbar-corner {
  background-color: #E0E5CF !important;
}
.limiter-table .ant-form.ant-form-vertical {
  margin-bottom: 8px;
}
.limiter-table #server-table::-webkit-scrollbar {
  background: #E0E5CF !important;
  width: 10px;
  height: 10px;
}
.limiter-table #server-table::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 20px;
}
.limiter-table #server-table::-webkit-scrollbar-thumb:hover {
  background: #005e2b;
}
@media only screen and (max-width: 768px) {
  .limiter-table .server-table {
    max-height: 0px;
  }
}
.limiter-table .server-table__updateTime {
  font-size: 14px;
}
.limiter-table .server-table-expand-image {
  height: 40px;
  width: 40px !important;
  object-fit: scale-down;
  border-radius: 50%;
}
.limiter-table .server-table__color-text-total {
  color: var(--primary-color);
}
.limiter-table .server-table__limits-pagination {
  margin: 0 5px;
}
.limiter-table .server-table__check-icon {
  color: var(--primary-color);
  margin-left: 5px;
}
.limiter-table .server-table__pagination {
  height: 32px;
  margin-top: 8px;
}
.limiter-table .server-table__pagination .ant-pagination-options {
  display: none;
}
@media only screen and (max-width: 992px) {
  .limiter-table .switch-responsive-layout {
    margin-left: 5px;
    margin-bottom: 5px;
  }
}
@media only screen and (max-width: 768px) {
  .limiter-table .switch-responsive-layout {
    margin-bottom: 0px;
  }
}
.limiter-table .server-table-switch-filter {
  transform: translateY(30px);
}
@media only screen and (max-width: 1200px) {
  .limiter-table .server-table-switch-filter {
    transform: translateY(0);
  }
}
.limiter-table .image-cell {
  padding-left: 10px !important;
}
.limiter-table .switch-input {
  width: 90px;
  white-space: nowrap;
}
.limiter-table a.link-details-corban {
  color: #00CF7B;
  text-decoration: underline;
}
.swal-error {
  width: 100%;
  text-align: left;
  overflow: auto;
  max-height: max(30vh, 200px);
  padding: 0 1.6em;
}
.swal-error-list {
  max-height: 50vh;
  overflow-y: auto;
  overflow-x: hidden;
  list-style: none;
  text-align: start;
  font-size: 0.9rem;
}
@media only screen and (max-width: 576px) {
  .swal-error-list {
    max-height: 60vh;
  }
}
.swal-error-list li {
  margin: 10px 0;
  padding: 7.5px;
  border-radius: 10px;
  background-color: #f2f2f2;
}
.swal-error-wrapper {
  margin-top: 1em;
  position: relative;
}
.swal-error-label {
  font-weight: 600;
  color: var(--primary-color);
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
}
.swal-error-checkbox {
  display: none;
  user-select: none;
}
.swal-error-label {
  text-decoration: underline;
  margin: auto;
}
.swal-error-copy {
  cursor: pointer;
  position: absolute;
  right: calc(7px + 0.5em);
  top: 0.5em;
}
.swal-error-copy img {
  width: 1em;
}
.swal-error-checkbox:not(.swal-error-checkbox:checked) ~ .swal-error-wrapper {
  display: none;
}
.swal-error-checkbox:checked ~ .swal-error-label-on {
  display: none;
}
.swal-error-checkbox:not(.swal-error-checkbox:checked) ~ .swal-error-label-off {
  display: none;
}
.swal-error-footer {
  border: 0;
  font-size: 0.8em;
  padding: 0;
  flex-direction: column;
  margin-top: 0.5em;
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.swal-error-footer ::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
.swal-error-footer ::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.swal-error-footer ::-webkit-scrollbar-thumb {
  background: #888;
}
.swal-error-footer ::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.skeleton-text-skeleton {
  display: flex;
  align-items: center;
}
.skeleton-text-skeleton,
.skeleton-text-skeleton__content {
  width: min(200px, 100%);
}
.skeleton-text-skeleton__content {
  border-radius: 4px;
}
.skeleton-text-skeleton__content,
.skeleton-text-skeleton__content .ant-skeleton-input {
  height: 16px;
}
.error-details {
  margin-bottom: 1rem;
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.error-details__container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.error-details__toggle {
  text-align: center;
  width: 50%;
}
@media only screen and (max-width: 576px) {
  .error-details__toggle {
    width: 70%;
  }
}
.error-details__toggle-content {
  position: relative;
}
.error-details__checkbox {
  display: none;
  user-select: none;
}
.error-details__label {
  font-weight: 600;
  color: var(--primary-color);
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  text-decoration: underline;
  margin: auto;
}
.error-details__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
}
.error-details__checkbox:not(.error-details__checkbox:checked) ~ .error-details__wrapper {
  display: none;
}
.error-details__checkbox:checked ~ .error-details__label-on {
  display: none;
}
.error-details__checkbox:not(.error-details__checkbox:checked) ~ .error-details__label-off {
  display: none;
}
.error-details .error-details__copy {
  cursor: pointer;
  position: absolute;
  right: calc(70px + 0.5em);
  top: 0.5em;
}
@media only screen and (max-width: 576px) {
  .error-details .error-details__copy {
    right: 0;
  }
}
.error-details .error-details__copy img {
  width: 1em;
}
.error-details ::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
.error-details ::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.error-details ::-webkit-scrollbar-thumb {
  background: #888;
}
.error-details ::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.message-little-panel {
  padding: 1rem;
}
.plus-button {
  height: 55px;
  margin: 10px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}
.plus-button__button-container {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}
.plus-button__button-container--background-less {
  background-color: transparent;
}
.plus-button__button-circle {
  width: 25px;
  height: 25px;
  background-color: white;
  display: flex;
  padding: 2px;
  position: relative;
}
.plus-button__button-circle--background-less {
  background-color: transparent;
}
.plus-button__button-circle:hover {
  cursor: pointer;
}
.plus-button__plus-icon {
  font-size: 26px;
  color: #9FC519;
  top: -12px;
  left: 1px;
  position: relative;
}
.plus-button__plus-icon:hover {
  color: #1f6b33;
}
.rerequest-button {
  justify-self: center;
  align-self: center;
  border-radius: 50%;
  padding: 4px 8px;
  width: 40px !important;
  height: 40px !important;
  color: #fff;
}
.rerequest-button:enabled:hover {
  rotate: 180deg;
  color: #00441F;
  background-color: transparent;
}
.rerequest-button--full-width {
  width: 100% !important;
}
.rerequest-button--full-width:enabled:hover {
  rotate: unset;
  color: #00441F;
  background-color: transparent;
}
.navbar-select-level {
  display: flex;
  flex-direction: column;
}
.navbar-select-level__button {
  padding: 15px;
  text-align: start;
  position: relative;
  transition: all 0.4s ease-in-out;
  background-color: #fff;
  width: 100%;
  border: none;
  border-radius: 20px;
  outline: none;
  cursor: pointer;
  min-width: 200px;
  display: flex;
  align-items: center;
}
.navbar-select-level__button:hover {
  background-color: #f2f2f2;
}
.navbar-select-level__button--selected {
  background-color: #f2f2f2;
}
.navbar-select-level__button svg {
  width: 18px;
  height: 18px;
}
.navbar-select-level__display-user-level {
  display: flex;
  flex-direction: column;
}
.navbar-select-level__user-level-name {
  font-weight: 600;
}
.navbar-select-level__user-covenant-name {
  font-size: 0.8rem;
  padding-right: 3rem;
  word-break: break-word;
}
.navbar-select-level__collapsable-icon {
  transition: all 0.2s ease-out;
  transform: rotate(-180deg);
  display: flex;
  align-items: center;
}
.navbar-select-level__collapsable-icon--collapsed {
  transform: rotate(0deg);
}
.navbar-select-level__possible-levels-container {
  display: flex;
  flex-direction: column;
  max-height: 50vh;
  overflow: auto;
  transition: all 0.2s ease-out;
}
.navbar-select-level__possible-levels-container--collapsed {
  display: none;
}
.storage-files__comment-placement {
  display: flex !important;
  flex-direction: row;
  width: 100% !important;
  overflow-wrap: break-word;
}
.storage-files__comment {
  width: 100% !important;
  margin-top: 0.5rem;
  margin-left: 5px;
  overflow-wrap: break-word;
}
#root .filter-type-search {
  display: flex;
}
#root .filter-type-search .ant-dropdown-menu-item-selected {
  border-radius: 20px;
}
#root .filter-type-search .ant-dropdown-menu-item:hover {
  border-radius: 20px;
}
#root .filter-type-search__input {
  border-left: none;
  border-radius: 0 20px 20px 0 !important;
  padding-left: 0;
  width: 300px !important;
  box-shadow: none !important;
  opacity: 1 !important;
}
@media only screen and (max-width: 992px) {
  #root .filter-type-search__input {
    width: 100% !important;
  }
}
#root .filter-type-search__input .ant-btn.ant-input-search-button {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
#root .filter-type-search__input .ant-input-wrapper.ant-input-group .ant-input-group-addon:first-child {
  border-radius: 25px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
#root .filter-type-search__input .ant-checkbox + span {
  padding-right: 0;
}
#root .filter-type-search__disabled {
  cursor: not-allowed;
  background-color: rgba(0, 0, 0, 0.25) !important;
  border: none !important;
}
.transfer-modal__display-new-admin {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.transfer-modal__icon {
  background-color: #00441F;
  color: white;
  padding: 0.5rem;
  font-size: 1.2rem;
  border-radius: 50%;
  margin: 1rem;
}
.transfer-modal__buttons {
  display: flex;
  justify-content: flex-end;
}
.display-terms {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
}
.display-terms__image {
  position: absolute;
  opacity: 0.3;
}
.check-terms-fields__signature {
  background-color: #f2f2f2;
  border-radius: 20px;
  flex-direction: column;
  padding: 1rem;
}
.loading-bar .ant-progress-inner {
  height: 2.3rem !important;
  align-items: center;
  display: flex;
  overflow: visible;
  border-radius: 6px!important;
}
.loading-bar .ant-progress-bg {
  height: 2.3rem !important;
  border-radius: 20px!important;
  background-size: 200% 200% !important;
  border-radius: 6px!important;
  animation: AnimateBG 7s ease infinite;
}
.loading-bar .ant-progress-text {
  position: absolute;
}
@keyframes AnimateBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
html .responsive-modal {
  padding: 0;
  margin: 0;
}
@media only screen and (max-width: 768px) {
  html .responsive-modal {
    max-height: 100vh;
  }
}
html .responsive-modal--lg {
  width: 100%!important;
  max-width: 950px;
}
html .responsive-modal .ant-modal {
  width: 80vw!important;
  height: 80%;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
@media only screen and (max-width: 768px) {
  html .responsive-modal .ant-modal {
    height: 100vh;
    min-width: 100vw;
    border-radius: 0;
  }
}
html .responsive-modal .ant-modal-title {
  font-size: 24px;
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 95%;
}
html .responsive-modal .ant-modal-body {
  max-height: 80vh;
  overflow-y: auto;
}
html .responsive-modal .ant-modal-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 40;
}
html .responsive-modal .ant-modal-content .ant-modal-body {
  overflow: auto;
  flex-grow: 1;
}
@media only screen and (max-width: 576px) {
  html .responsive-modal .ant-modal-footer {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
  }
  html .responsive-modal .ant-modal-footer > * {
    margin: 0!important;
    flex-grow: 1;
  }
}
.communication-unico-modal {
  min-width: 904px;
}
@media only screen and (max-width: 576px) {
  .communication-unico-modal {
    min-width: 100%;
  }
}
.communication-unico-modal .ant-modal-body {
  padding: 12px;
}
.communication-unico-modal__img {
  max-width: 100%;
  border-radius: 20px;
}
.communication-unico-modal__title {
  margin-bottom: 0;
  color: #00441F;
}
.overflow-tooltip * {
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.invalid-fields {
  font-size: 0.8rem;
}
.invalid-fields__item {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
}
.invalid-fields__wrapper {
  text-align: start;
  max-height: 300px;
  max-width: 500px;
  overflow: auto;
  margin-top: 1em;
  position: relative;
  background: #f2f2f2;
  color: #00441F;
  padding: 1rem;
  border-radius: 20px;
}
.invalid-fields__checkbox {
  display: none;
  user-select: none;
}
.invalid-fields__checkbox:not(.invalid-fields__checkbox:checked) ~ .invalid-fields__wrapper {
  display: none;
}
.invalid-fields__checkbox:checked ~ .invalid-fields__label--on {
  display: none;
}
.invalid-fields__checkbox:not(.invalid-fields__checkbox:checked) ~ .invalid-fields__label--off {
  display: none;
}
.invalid-fields__label {
  font-weight: 600;
  color: var(--primary-color);
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  text-decoration: underline;
  margin: auto;
  display: flex;
  justify-content: center;
}
.review-radio__container {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  flex-wrap: wrap;
}
@media only screen and (max-width: 992px) {
  .review-radio__container {
    display: flex;
    flex-direction: column;
    height: max-content;
  }
}
.review-radio__item {
  align-items: center;
  background-color: white;
  border-radius: 20px;
  color: #00441F;
  height: 50px;
  padding: 0.5rem 0.75rem;
  margin-right: 0;
  outline: 1px solid #d9d9d9;
  white-space: normal;
  transform: translateZ(0);
  display: flex;
  justify-content: flex-end;
  flex: 1;
  max-width: 250px;
  min-width: 180px;
}
.review-radio__item--more-than-three {
  min-width: 160px;
  max-width: 100%;
}
@media only screen and (max-width: 1366px) {
  .review-radio__item--more-than-three {
    min-width: 180px;
  }
}
@media only screen and (max-width: 1200px) {
  .review-radio__item--more-than-three {
    min-width: 160px;
  }
}
.review-radio__item--big-label {
  order: 0;
}
@media only screen and (max-width: 1366px) {
  .review-radio__item--big-label {
    order: 5;
  }
}
@media only screen and (max-width: 992px) {
  .review-radio__item--big-label {
    order: 0;
  }
}
.review-radio__item--first {
  order: -1;
}
@media only screen and (max-width: 992px) {
  .review-radio__item {
    justify-content: center;
    max-width: 100%;
  }
}
@media only screen and (max-width: 576px) {
  .review-radio__item {
    justify-content: flex-end;
  }
}
.review-radio__item .ant-radio,
.review-radio__item .ant-radio-checked {
  display: none !important;
}
.review-radio__item--approved:hover {
  outline-color: #00CF7B !important;
}
.review-radio__item--approvedWithRestriction:hover {
  outline-color: #00CF7B !important;
}
.review-radio__item--approvedManually:hover {
  outline-color: #00CF7B !important;
}
.review-radio__item--reproved:hover {
  outline-color: #DD4E4E !important;
}
.review-radio__item--canceled:hover {
  outline-color: #DD4E4E !important;
}
.review-radio__item--remaking:hover {
  outline-color: #FF7A40 !important;
}
.review-radio__item--reservation:hover {
  outline-color: #00CF7B !important;
}
.review-radio__item-active--aproved {
  background-color: #00CF7B;
  color: #fff;
}
.review-radio__select-svg {
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
}
.review-radio__select-svg .gooey {
  filter: url('#goo');
}
.review-radio__select-svg .drop,
.review-radio__select-svg .dropTop,
.review-radio__select-svg .drops {
  opacity: 0;
}
.review-radio__select-svg .toggler {
  cursor: pointer;
}
.review-radio .ant-radio {
  display: none;
}
.review-radio__icon {
  position: absolute;
  font-size: 0.8rem;
  opacity: 0;
  left: 1.5rem !important;
  top: 50%;
  transform: translateY(-50%);
  color: #fff;
}
.review-radio__option-label {
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  width: 115px;
  margin-top: 0.3rem;
  text-align: end;
}
@media only screen and (max-width: 1366px) {
  .review-radio__option-label {
    width: 100%;
  }
}
.step-warning {
  background-color: #f2f2f2;
  padding: 1rem;
  border-radius: 20px;
}
.step-warning__icon {
  font-size: 2.5rem;
  margin-right: 1.5rem;
  color: #00A8E2;
}
.property-form-owner__remove-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px!important;
  height: 30px!important;
  font-size: 0.9rem;
}
@media only screen and (max-width: 768px) {
  .property-form-owner__checkbox .ant-form-item-label {
    display: none;
  }
}
.multi-datepicker {
  display: flex;
}
.multi-datepicker__button {
  padding: 0 11px !important;
  border: 1px solid #d9d9d9;
  border-right: none;
  font-size: 1.3em;
  border-radius: 20px 0 0 20px;
  width: 40px !important;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00441F;
  cursor: pointer;
  box-shadow: none;
}
.multi-datepicker__button:hover,
.multi-datepicker__button:focus {
  border-color: var(--primary-color);
}
.multi-datepicker__button span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22.09px;
  height: 22.09px;
  transition: transform 0.15s ease-in, transform 0.25s ease-in !important;
  transform: rotate(0);
}
.multi-datepicker__button.ant-dropdown-open span {
  transform: rotate(70deg);
}
.multi-datepicker .ant-picker-input {
  padding-left: 10px;
}
.multi-datepicker .ant-dropdown-menu-item-selected {
  border-radius: 20px;
}
.multi-datepicker .ant-dropdown-menu-item:hover {
  border-radius: 20px;
}
.multi-datepicker .ant-picker {
  border-left: none;
  border-radius: 0 20px 20px 0 !important;
  padding-left: 0;
  width: 200px !important;
  box-shadow: none !important;
  opacity: 1 !important;
}
@media only screen and (max-width: 768px) {
  .multi-datepicker .ant-picker {
    width: 100% !important;
  }
}
.multi-datepicker__disabled {
  cursor: not-allowed;
  background-color: rgba(0, 0, 0, 0.25) !important;
  border: none !important;
}
.variables-available__tags-container {
  display: flex;
  flex-wrap: wrap;
  row-gap: 0.5rem;
  column-gap: 0.2rem;
  margin-top: 0.5rem;
}
.variables-available__tag {
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
}
.variables-available__tag:hover {
  transform: scale(1.08);
}
.menu__header {
  display: flex;
  align-items: center;
  padding: 2rem 0;
}
@media only screen and (max-width: 768px) {
  .menu__header {
    display: none;
  }
}
.menu__title {
  font-weight: bold;
  font-size: 2rem;
  color: #00441F;
}
.menu__text {
  max-width: 80%;
}
.menu__description {
  padding-left: 3rem;
}
.menu__empty-card {
  background-color: white;
  padding: 2rem;
  border-radius: 20px;
}
.menu__search {
  display: flex;
  position: relative;
}
.menu__search-icon {
  position: absolute;
  top: 50%;
  right: 1rem;
  transform: translateY(-50%);
  color: #d9d9d9;
}
.menu-card {
  background-color: white;
  border-radius: 20px;
  padding: 1rem 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0;
}
@media only screen and (max-width: 768px) {
  .menu-card {
    flex-direction: column;
    text-align: center;
  }
}
.menu-card__section {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 768px) {
  .menu-card__section {
    flex-direction: column;
    text-align: center;
  }
}
.menu-card__icon > * {
  background-color: #E0E5CF;
  font-size: 1.5rem;
  padding: 1rem;
  border-radius: 50%;
  color: #00441F;
}
.menu-card__title {
  color: #00441F;
  font-weight: bold;
  margin: 1rem;
  text-align: center;
}
@media only screen and (max-width: 768px) {
  .menu-card__title {
    margin: 0;
  }
}
.menu-card__description {
  margin: 1rem;
  text-align: center;
}
@media only screen and (max-width: 768px) {
  .menu-card__description {
    margin: 0.5rem 0;
  }
}
.menu-card__texts {
  max-width: 80%;
}
html .rich-text-editor-inner {
  --font-family: "Montserrat", -apple-system, BlinkMacSystemFont, \'Segoe UI\', Roboto, \'Helvetica Neue\', Arial, \'Noto Sans\', sans-serif, \'Apple Color Emoji\', \'Segoe UI Emoji\', \'Segoe UI Symbol\', \'Noto Color Emoji\';
  --border-radius: 10px;
}
html .rich-text-editor-inner .se-wrapper-inner {
  font-family: var(--font-family);
}
html .rich-text-editor-inner .se-placeholder {
  font-family: var(--font-family);
}
html .rich-text-editor-inner .se-toolbar {
  border-radius: var(--border-radius) var(--border-radius) 0 0;
}
html .rich-text-editor-inner .sun-editor {
  border-radius: var(--border-radius);
}
html .rich-text-editor-inner .se-resizing-bar {
  border-radius: 0 0 var(--border-radius) var(--border-radius);
}
html .rich-text-editor-inner__skeleton span {
  min-height: 300px;
  width: 100% !important;
}
html .rich-text-editor-inner__table td {
  border: 1px solid;
  min-width: 40px;
  width: 100%;
  padding: 5px;
}
.multi-select-modal__input {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
}
.multi-select-modal__info {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 1.5rem 0 0.5rem;
}
@media only screen and (max-width: 768px) {
  .multi-select-modal__info {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
}
.multi-select-modal__quantity {
  cursor: pointer;
  font-size: 0.9rem;
  padding: 0.2rem 1rem;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  border-radius: 100px;
}
.multi-select-modal__quantity:hover {
  background-color: var(--primary-color);
  color: var(--master-text-color);
}
.multi-select-modal__search-options {
  font-size: 0.82rem;
  border-radius: 5px;
}
.multi-select-modal__search-options .ant-checkbox .ant-checkbox-inner {
  border-radius: 5px;
}
.multi-select-modal__options-container {
  background-color: #cccccc;
  border-radius: 0.65rem;
  margin-bottom: 1.5rem;
  height: 350px;
  padding: 0 0.5rem;
}
.multi-select-modal__first-option {
  border-bottom: 1px solid #ffffff1f;
  font-size: 0.82rem;
  padding: 1rem 0rem 0.6rem 0.8rem;
  width: 100%;
}
.multi-select-modal__first-option .ant-checkbox .ant-checkbox-inner {
  border-radius: 5px;
}
.multi-select-modal__checkbox-group {
  column-gap: 0.5rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  max-height: 300px;
  overflow-y: scroll;
  padding: 0.5rem 0 1rem;
}
.multi-select-modal__checkbox-group--option .ant-checkbox .ant-checkbox-inner {
  border-radius: 5px;
}
.multi-select-modal__checkbox-group--option .ant-checkbox-wrapper {
  font-size: 0.82rem;
  padding: 0.5rem 0;
  padding-left: 0.8rem;
  margin-left: 0;
  align-items: center;
}
@media only screen and (max-width: 768px) {
  .multi-select-modal__checkbox-group--option .ant-checkbox-wrapper {
    grid-column-end: span 2;
  }
}
.multi-select-modal__checkbox-group--option .ant-checkbox-wrapper:hover {
  background-color: #dddddd;
}
.multi-select-modal__checkbox-group .ant-checkbox {
  top: 0.6px;
}
.multi-select-modal__checkbox-group--option .ant-checkbox-wrapper > span:last-child {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.multi-select-modal__buttons {
  display: flex;
  justify-content: space-between;
}
.multi-select-modal__empty {
  display: flex;
  width: 100%;
  height: 250px;
  justify-content: center;
  align-items: center;
  grid-column-end: span 2;
}
.multi-select__number-of-selecteds {
  font-size: 0.9rem;
  font-style: italic;
  margin: 0.15rem 0 0;
  text-align: end;
  width: 100%;
}
.pressable {
  background: transparent;
  border: none;
  cursor: pointer;
}
.pressable:hover {
  opacity: 0.7;
}
.loading-spinner {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading-spinner__icon {
  font-size: 2em;
}
.loading-spinner--rich-text {
  height: 350px;
}
.loading-cyclic-texts__title {
  font-size: 1.3rem;
  margin-bottom: 5px;
}
.loading-cyclic-texts__description {
  color: #a8a8a8;
}
.tracking-in-expand {
  -webkit-animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.61, 0.355, 1) both;
  animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.61, 0.355, 1) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2024-9-27 16:20:29
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation tracking-in-expand
 * ----------------------------------------
 */
@-webkit-keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
.error-details-ecpf {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.error-details-ecpf__simulation-whatsapp {
  font-size: 0.9rem;
}
.error-details-ecpf__simulation-title {
  margin-bottom: 10px;
}
.error-details-ecpf__container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0.8rem;
  max-height: 200px;
  overflow-y: auto;
  max-width: 500px;
  min-width: 300px;
}
.error-details-ecpf__container--full-height {
  max-height: unset;
}
@media only screen and (max-width: 576px) {
  .error-details-ecpf__container {
    min-width: none;
  }
}
.error-details-ecpf__container::-webkit-scrollbar {
  background: var(--scroll-bar-color) !important;
  width: 10px;
  height: 10px;
}
.error-details-ecpf__container::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 20px;
}
.error-details-ecpf__container::-webkit-scrollbar-thumb:hover {
  background: #005e2b;
}
.error-details-ecpf__warning-svg {
  width: 500px !important;
  padding-bottom: 2rem;
}
@media only screen and (max-width: 576px) {
  .error-details-ecpf__warning-svg {
    padding-bottom: 1rem;
    width: 300px !important;
  }
}
.error-details-ecpf__item {
  background-color: #f2f2f2;
  border-radius: 10px;
  padding: 1rem 0.7rem;
  color: #000;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;
}
.error-details-ecpf__result {
  padding: 1rem;
}
.error-details-ecpf__message {
  font-size: 0.8rem;
  word-wrap: break-word;
  text-align: start;
  width: 100%;
}
.error-details-ecpf__code {
  font-size: 0.6rem;
  word-wrap: break-word;
  text-align: start;
}
.selfie__button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 150px;
  height: 150px;
  cursor: pointer;
}
.selfie__upload > .ant-upload {
  display: flex;
  justify-content: center;
  width: 170px;
  height: 170px;
  border: 1px solid #ddd;
  border-radius: 10px;
  align-items: center;
  transition: all 0.3s ease;
}
.selfie__upload > .ant-upload:hover {
  border-color: #00CF7B;
  color: var(--primary-color);
}
.selfie__upload > .ant-upload span {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.token-validation__method-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.token-validation__description {
  margin-bottom: 20px;
}
.token-validation__button {
  width: 350px;
  border: 1px solid transparent;
  cursor: pointer;
}
.token-validation__button--email-button {
  background-color: #fff;
  color: #000000;
  border-color: #00441F;
}
.token-validation__submit-button,
.token-validation__back-button {
  width: 100%;
  cursor: pointer;
}
.token-validation__back-button {
  border-color: #00441F;
}
.token-validation__input-container {
  width: 100%;
  gap: 15px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.token-validation__input-label {
  margin-bottom: 8px;
}
.token-validation__header {
  margin-bottom: 20px;
}
.token-validation__title {
  font-size: 24px;
  color: #00441F;
  font-weight: bold;
}
.token-validation__svg {
  margin: 0 auto;
  max-width: 200px;
  height: auto;
  padding-bottom: 2rem;
  display: flex;
  justify-content: center;
}
.token-validation__buttons-container {
  display: flex;
  gap: 15px;
}
.simple-pagination {
  display: flex;
  gap: 8px;
}
.simple-pagination__button {
  max-height: 32px;
  height: 32px;
  width: 32px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.simple-pagination__button:hover,
.simple-pagination__button:active,
.simple-pagination__button:focus {
  background-color: #fff !important;
}
.simple-pagination__page {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 50%;
  height: 32px;
  width: 32px;
}
.find-user {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.find-user__card {
  background-color: #fff;
  border-radius: 20px;
  padding: 2rem;
  width: 100%;
  max-width: 600px;
  margin: 0 auto 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.find-user__title {
  font-weight: 600;
  color: var(--primary-color);
  text-align: center;
  margin-bottom: 0.5rem;
}
.find-user__description {
  text-align: center;
  margin-bottom: 1.5rem;
}
.find-user__image {
  margin-bottom: 1.5rem;
}
.find-user__input {
  width: min(335px, 100%);
}
.find-user__input button {
  border-radius: 0px 20px 20px 0px !important;
}
.find-user__button {
  width: 100%;
  max-width: 600px;
}
.find-user__client-info-container {
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: #dfdfdf56;
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
}
.find-user__client-info-title {
  font-size: 1.25rem;
  color: var(--primary-color);
  font-weight: bold;
  margin-bottom: 0.5rem;
}
.find-user__client-info-details {
  display: flex;
  flex-direction: column;
}
.proposal-flow {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.proposal-card {
  width: 100%;
  height: 100%;
  padding: 1.75rem 2rem 1.25rem;
  background-color: white;
  border-radius: 20px;
}
@media only screen and (max-width: 576px) {
  .proposal-card {
    padding: 1.25rem 1.5rem 1rem;
  }
}
.buttons-step {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
}
.buttons-step--first {
  justify-content: flex-end;
}
.buttons-step__button-back {
  background-color: transparent;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  padding: 0 2rem 0;
}
.buttons-step__button-back:hover,
.buttons-step__button-back:active,
.buttons-step__button-back:focus {
  color: white;
}
.form-title {
  font-size: 1.25rem;
  font-weight: bold;
  color: var(--primary-color);
}
@media only screen and (max-width: 576px) {
  .form-title {
    font-size: 1rem;
  }
}
.simulation-flow-ecpf__tooltip-insurance {
  width: 100%;
  text-align: left;
  background-color: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  padding: 0;
  margin: 0;
  transition: all 0.2s;
}
.simulation-flow-ecpf__tooltip-insurance:hover {
  opacity: 0.8;
}
.simulation-flow-ecpf__tooltip-insurance:focus {
  outline: none;
}
.simulation-flow-ecpf .ant-radio-group .ant-radio-button-wrapper {
  border: 1px solid var(--primary-color);
  background-color: transparent;
}
.simulation-flow-ecpf .ant-radio-group .ant-radio-button-wrapper:first-child {
  border-radius: 8px 0 0 8px;
}
.simulation-flow-ecpf .ant-radio-group .ant-radio-button-wrapper:last-child {
  border-radius: 0 8px 8px 0;
}
.simulation-flow-ecpf .ant-radio-group .ant-radio-button-wrapper-checked {
  background-color: var(--primary-color);
  color: white;
}
.simulation-flow-ecpf .ant-radio-group .ant-radio-button-wrapper-checked:hover {
  color: white;
}
.simulation-flow-ecpf__form-item-meta-calculo {
  display: inline-block;
  width: 100%;
}
@media only screen and (max-width: 576px) {
  .simulation-flow-ecpf__form-item-meta-calculo {
    margin-bottom: 0;
  }
}
@media only screen and (max-width: 576px) {
  .simulation-flow-ecpf__simulate-button {
    width: 100%;
  }
}
.simulation-flow-ecpf__form-item-security {
  display: inline-block;
}
.simulation-flow-ecpf__form-item-security .ant-form-item-label {
  padding: 0;
}
.simulation-flow-ecpf__more-info-btn {
  padding: 0;
  height: auto !important;
}
.simulation-flow-ecpf__card-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.25rem;
}
.simulation-flow-ecpf__card {
  padding: 1rem 1.875rem;
  border-radius: 20px;
  background-color: transparent;
  border: 1px solid var(--primary-color);
  display: inline-block;
  text-align: center;
}
@media only screen and (max-width: 576px) {
  .simulation-flow-ecpf__card {
    flex: 1;
  }
}
.simulation-flow-ecpf__card--installment {
  background-color: var(--primary-color);
}
.simulation-flow-ecpf__card-title,
.simulation-flow-ecpf__card-value {
  color: var(--primary-color);
  margin: 0;
  padding: 0;
  font-weight: bold;
}
.simulation-flow-ecpf__card-title--installment,
.simulation-flow-ecpf__card-value--installment {
  color: white;
}
.simulation-flow-ecpf__card-title {
  font-size: 0.875rem;
}
.simulation-flow-ecpf__card-value {
  font-size: 1.25rem;
}
.simulation-flow-ecpf__loading-card {
  padding: 4rem;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.simulation-flow-ecpf__loading-instruction-svg {
  padding-bottom: 2rem;
}
@media only screen and (max-width: 576px) {
  .simulation-flow-ecpf__loading-instruction-svg svg {
    width: 300px !important;
  }
}
.simulation-flow-ecpf__simulation-limit-container {
  max-width: 600px;
}
.simulation-flow-ecpf__error-container {
  max-width: unset;
}
.simulation-flow-ecpf__insurance {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
}
.simulation-flow-ecpf__insurance label {
  width: 100%;
}
@media only screen and (max-width: 576px) {
  .simulation-flow-ecpf__insurance {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
.simulation-flow-ecpf-min-max {
  display: flex;
  justify-content: space-between;
  color: #a8a8a8;
  font-size: 0.7rem;
}
.simulation-flow-ecpf-min-max .ant-spin {
  line-height: 1 !important;
  font-size: unset;
}
.simulation-flow-ecpf-min-max .ant-spin-sm .ant-spin-dot {
  font-size: 9px;
}
.simulation-flow-ecpf-min-max* {
  display: block;
}
.customer-flow-ecpf__card {
  padding: 40px 20px;
  text-align: center;
}
.customer-flow-ecpf__card-image {
  margin-bottom: 20px;
}
.customer-flow-ecpf__card-content {
  padding: 20px;
  background-color: #f2f2f2;
  border-radius: 20px;
  width: fit-content;
  margin: auto;
}
@media only screen and (max-width: 576px) {
  .customer-flow-ecpf__cnpj-button {
    width: 100%;
  }
}
.customer-flow-ecpf__info-tooltip {
  color: var(--blue-status);
}
.customer-flow-ecpf__info-tooltip svg {
  width: 14px;
}
.token-validation-flow-ecpf {
  margin: auto;
  max-width: 350px;
}
.last-activity-modal-buttons__reason-description-container {
  border-radius: 14px;
  border: 1px solid #DFDFDF;
  padding: 0.8rem;
}
.last-activity-modal-buttons__detail-item {
  width: 100%;
  height: 100%;
  padding: 15px 0 3px 0;
  border-bottom: 1px solid #a8a8a8;
}
.last-activity-modal-buttons__detail-item__label {
  font-weight: bold;
  color: #000000;
}
.last-activity-modal-buttons__detail-item__value {
  word-break: break-all;
  padding-top: 5px;
  font-size: 1rem;
  color: #000000;
}
@media only screen and (max-width: 576px) {
  .last-activity-modal-buttons__detail-item__value {
    font-size: 1rem;
  }
}
.proposal-range-picker-modal__button {
  background-color: white;
  color: #5B5B5B;
  margin-bottom: 8px;
  transition: all 0.2s ease-in-out;
  min-width: 170px;
}
@media only screen and (max-width: 576px) {
  .proposal-range-picker-modal__button {
    min-width: 100%;
  }
}
.proposal-range-picker-modal__button:focus,
.proposal-range-picker-modal__button:active,
.proposal-range-picker-modal__button:visited {
  background-color: white;
}
.proposal-range-picker-modal__button:hover {
  background-color: #d9d9d9;
  color: #000;
}
.proposal-range-picker-modal__range-form-item label {
  width: 100%;
}
.proposal-due-ecpf {
  padding: 2rem;
  display: flex;
  gap: 2rem;
}
.proposal-due-ecpf__content {
  width: 82%;
}
.proposal-due-ecpf__icon {
  font-size: 5rem;
  color: #00441F;
  width: 18%;
}
.proposal-due-ecpf__title h3 {
  color: #00441F;
  font-size: 1.5rem;
  font-weight: bold;
}
@media only screen and (max-width: 768px) {
  .proposal-due-ecpf {
    flex-direction: column;
  }
  .proposal-due-ecpf__icon {
    width: 100%;
  }
  .proposal-due-ecpf__content {
    width: 100%;
  }
  .proposal-due-ecpf__title h3 {
    padding: 1rem 0;
    text-align: center;
  }
}
.proposal-debit-balance-ecpf {
  padding: 2rem;
  display: flex;
  gap: 2rem;
}
.proposal-debit-balance-ecpf__content {
  width: 82%;
}
.proposal-debit-balance-ecpf__icon {
  font-size: 5rem;
  color: #00441F;
  width: 18%;
}
.proposal-debit-balance-ecpf__title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.proposal-debit-balance-ecpf__title h3 {
  color: #00441F;
  font-size: 1.5rem;
  font-weight: bold;
}
.proposal-debit-balance-ecpf__totals {
  display: flex;
  align-items: stretch;
  padding: 1rem;
  gap: 1rem;
  border-radius: 10px;
  border: 1px solid #a8a8a8;
  width: fit-content;
}
@media only screen and (max-width: 576px) {
  .proposal-debit-balance-ecpf__totals {
    flex-direction: column;
    width: 100%;
  }
}
@media only screen and (max-width: 1200px) {
  .proposal-debit-balance-ecpf__totals-container {
    flex-direction: column;
    gap: 1rem;
    align-items: start;
  }
}
.proposal-debit-balance-ecpf__totals-divider {
  width: 1px;
  background-color: #a8a8a8;
}
@media only screen and (max-width: 576px) {
  .proposal-debit-balance-ecpf__totals-divider {
    width: 100%;
    height: 1px;
  }
}
.proposal-debit-balance-ecpf__left-total {
  display: flex;
  align-items: center;
  gap: 5px;
}
@media only screen and (max-width: 576px) {
  .proposal-debit-balance-ecpf__left-total {
    flex-direction: column;
    align-items: start;
  }
}
@media only screen and (max-width: 1200px) {
  .proposal-debit-balance-ecpf__simulate-button {
    width: 100%;
  }
}
@media only screen and (max-width: 1200px) {
  .proposal-debit-balance-ecpf {
    flex-direction: column;
  }
  .proposal-debit-balance-ecpf__icon {
    width: 100%;
  }
  .proposal-debit-balance-ecpf__content {
    width: 100%;
  }
  .proposal-debit-balance-ecpf__title {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    width: 100%;
  }
  .proposal-debit-balance-ecpf__title h3 {
    padding: 1rem 0;
    text-align: center;
  }
}
.proposal-anticipation-modal__due-list {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}
.proposal-anticipation-modal__due {
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  padding: 0.25rem 1rem;
  display: flex;
  gap: 0.5rem;
  border-radius: 5px;
}
.proposal-anticipation-modal__close-icon {
  display: flex;
  align-items: center;
}
.proposal-anticipation-modal__result-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #a8a8a8;
  padding: 0.5rem 0;
}
@media only screen and (max-width: 576px) {
  .proposal-anticipation-modal__result-item {
    flex-direction: column;
  }
}
.proposal-anticipation-modal__results-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}
@media only screen and (max-width: 576px) {
  .proposal-anticipation-modal__results-button {
    flex-direction: column;
  }
}
@media only screen and (max-width: 576px) {
  .proposal-anticipation-modal__button {
    width: 100%;
  }
}
.signature-step-ecpf__steps .ant-steps-item-content {
  padding: 1rem;
  background-color: #f3f3f3;
  border-radius: 10px;
  margin-bottom: 10px;
}
.signature-step-ecpf__steps--loading .ant-steps-item-content {
  background-color: #f3f3f3;
  min-height: 90px !important;
  border-radius: 10px;
  margin-bottom: 10px;
}
.signature-step-ecpf__last-error {
  margin-top: 1rem;
}
.proposal-line-view-ecpf__resume-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}
.proposal-line-view-ecpf__buttons-container {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
}
.renegotiation-simulation-flow-ecpf__value-card {
  padding: 1rem;
}
.organization-ecpf-list .ant-table-row:hover {
  cursor: pointer;
}
.client-details-ecpf__auth-title {
  line-height: 3;
  margin-bottom: 0;
}
@media only screen and (max-width: 576px) {
  .client-details-ecpf__auth-title {
    line-height: 2;
  }
}
.client-details-ecpf__avatar {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  margin-bottom: 50px;
}
@media only screen and (max-width: 768px) {
  .client-details-ecpf__avatar {
    display: none;
  }
}
.client-details-ecpf__square-button {
  border-radius: 12px !important;
}
@media only screen and (max-width: 576px) {
  .client-details-ecpf__edit-button {
    width: 100%;
  }
}
.client-initial-ecpf {
  overflow-y: hidden;
}
.client-initial-ecpf__title {
  animation: revealContent 0.6s ease-in-out;
}
@media only screen and (max-width: 576px) {
  .client-initial-ecpf__title {
    font-size: 1rem !important;
  }
}
@media only screen and (max-width: 1200px) {
  .client-initial-ecpf__title-container {
    width: 100%;
  }
}
.client-initial-ecpf__subtitle {
  animation: revealContent 0.7s ease-in-out;
  font-size: 1rem;
}
@media only screen and (max-width: 1200px) {
  .client-initial-ecpf__header {
    flex-direction: column;
    align-items: start;
  }
}
.client-initial-ecpf__button {
  animation: revealContent 0.8s ease-in-out;
}
@media only screen and (max-width: 576px) {
  .client-initial-ecpf__button {
    width: 100%;
  }
}
.client-initial-ecpf__header-card {
  background-color: #fff;
  padding: 1rem;
  border-radius: 12px;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  animation: revealContent 0.7s ease-in-out;
}
.client-initial-ecpf__header-image {
  background-color: rgba(0, 68, 31, 0.15);
  padding: 0.8rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  color: #00441F;
  font-size: 1.2rem;
}
.client-initial-ecpf__proposal-list {
  display: grid;
  width: 100%;
  gap: 10px;
  grid-template-columns: repeat(auto-fill, calc(25% - 8px));
  animation: revealContent 2s ease-in-out;
}
@media only screen and (max-width: 1200px) {
  .client-initial-ecpf__proposal-list {
    grid-template-columns: repeat(auto-fill, calc(50% - 10px));
  }
}
@media only screen and (max-width: 768px) {
  .client-initial-ecpf__proposal-list {
    grid-template-columns: 1fr;
  }
}
.client-initial-ecpf__advantages-section {
  display: grid;
  width: 100%;
  gap: 10px;
  grid-template-columns: repeat(auto-fill, calc(20% - 8px));
}
@media only screen and (max-width: 1200px) {
  .client-initial-ecpf__advantages-section {
    grid-template-columns: repeat(auto-fill, calc(50% - 10px));
  }
}
@media only screen and (max-width: 576px) {
  .client-initial-ecpf__advantages-section {
    display: flex;
    overflow-x: auto;
  }
}
.client-initial-ecpf__proposal-subtitle {
  animation: revealContent 1.7s ease-in-out;
}
.client-initial-ecpf__proposal-description {
  animation: revealContent 1.8s ease-in-out;
}
.client-initial-ecpf__status-select-container {
  animation: revealContent 1.9s ease-in-out;
}
.client-initial-ecpf__pagination {
  animation: revealContent 2.1s ease-in-out;
}
.client-initial-ecpf__proposal-card-header {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 1rem;
}
.client-initial-ecpf__proposal-card-header-title {
  font-size: 1rem;
}
.client-initial-ecpf__proposal-card-header-subtitle {
  color: #5B5B5B;
  font-size: 0.7rem;
}
.client-initial-ecpf__proposal-card-label {
  font-size: 0.8rem;
  color: #5B5B5B;
}
.client-initial-ecpf__proposal-card-label--end {
  text-align: end;
}
.client-initial-ecpf__proposal-card-value--end {
  text-align: end;
}
.client-initial-ecpf__proposal-card {
  background: white;
  padding: 1rem;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  transition: 0.2s ease-in-out;
}
.client-initial-ecpf__proposal-card--clickable {
  cursor: pointer;
}
.client-initial-ecpf__proposal-card--clickable:hover {
  filter: brightness(96%);
}
.client-initial-ecpf__proposal-card .ant-skeleton-content .ant-skeleton-title + .ant-skeleton-paragraph {
  margin-top: 0 !important;
  margin-bottom: 5px;
}
.client-initial-ecpf__proposal-card .ant-skeleton-content .ant-skeleton-title {
  margin-top: 5px !important;
  margin-bottom: 5px;
}
.client-initial-ecpf__header-card-content {
  width: 50%;
  gap: 1rem;
}
.client-initial-ecpf__card-title {
  margin-bottom: 0;
}
@media only screen and (max-width: 576px) {
  .client-initial-ecpf__card-title {
    font-size: 1.1rem;
  }
}
@media only screen and (max-width: 576px) {
  .client-initial-ecpf__card-description {
    font-size: 0.8rem;
  }
}
.client-initial-ecpf__card-description--footer {
  font-size: 0.8rem;
}
@media only screen and (max-width: 576px) {
  .client-initial-ecpf__card-description--footer {
    font-size: 0.6rem;
  }
}
.client-initial-ecpf__img {
  margin: 25px 0;
}
@media only screen and (max-width: 576px) {
  .client-initial-ecpf__img {
    height: 150px;
    margin: 15px 0;
  }
  .client-initial-ecpf__img svg {
    height: 125px;
  }
}
.client-initial-ecpf__select-status {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
}
.client-initial-ecpf__select-status-option {
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  transition: 0.2s all ease-in-out;
  background-color: #fff;
  border-radius: 40px;
  flex-direction: row;
  padding: 0.5rem 0.8rem;
  color: var(--primary-color);
}
.client-initial-ecpf__select-status-option:hover {
  background-color: var(--primary-color);
  color: #fff;
}
.client-initial-ecpf__select-status-option--selected {
  background-color: var(--primary-color);
  color: #fff;
}
.client-initial-ecpf__select-status-option--disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
@media only screen and (max-width: 576px) {
  .client-initial-ecpf__select-status-option {
    font-size: 12px;
  }
}
.advantage-card {
  background-color: #fff;
  min-height: 215px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 1.2rem;
  transition: all 0.2s ease-in-out;
  gap: 1rem;
}
@media only screen and (max-width: 576px) {
  .advantage-card {
    padding: 0.9rem;
    flex-direction: row;
    min-height: fit-content;
    align-items: center;
    gap: 0.8rem;
    max-width: 220px;
    flex: 0 0 auto;
  }
}
.advantage-card:hover {
  transition: 0.2s ease-in-out;
  transform: scale(1.05);
  filter: brightness(98%);
}
.advantage-card__image {
  height: 65px;
}
@media only screen and (max-width: 576px) {
  .advantage-card__image {
    height: 45px;
  }
}
.advantage-card__content-container {
  display: flex;
  gap: 1rem;
  flex-direction: column;
}
@media only screen and (max-width: 576px) {
  .advantage-card__content-container {
    gap: 0.3rem;
  }
}
.advantage-card__title {
  font-size: 1rem;
  font-weight: bold;
  color: #00441F;
}
@media only screen and (max-width: 576px) {
  .advantage-card__title {
    font-size: 0.8rem;
    text-align: start;
    line-height: 0.9rem;
  }
}
.advantage-card__description {
  font-size: 0.8rem;
  line-height: 0.8rem;
}
@media only screen and (max-width: 576px) {
  .advantage-card__description {
    font-size: 0.7rem;
    text-align: start;
  }
}
.advantage-card--first-card {
  animation: revealContent 0.8s ease-in-out;
}
.advantage-card--second-card {
  animation: revealContent 1s ease-in-out;
}
.advantage-card--third-card {
  animation: revealContent 1.2s ease-in-out;
}
.advantage-card--fourth-card {
  animation: revealContent 1.4s ease-in-out;
}
.advantage-card--fifth-card {
  animation: revealContent 1.6s ease-in-out;
}
@keyframes revealContent {
  0% {
    opacity: 0;
    transform: translateY(80px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.signin-b2c-modal-ecpf__content {
  display: flex;
  gap: 30px;
}
@media only screen and (max-width: 576px) {
  .signin-b2c-modal-ecpf__content {
    gap: 15px;
  }
}
.signin-b2c-modal-ecpf__content > div {
  flex: 1;
}
.signin-b2c-modal-ecpf-card {
  padding: 15px;
  border: 1px solid #DFDFDF;
  border-radius: 20px;
  cursor: pointer;
}
@media only screen and (max-width: 576px) {
  .signin-b2c-modal-ecpf-card {
    padding: 12px;
  }
}
.signin-b2c-modal-ecpf-card:hover {
  background-color: #f2f2f2;
  transition: 0.3s;
}
.signin-b2c-modal-ecpf-card__icon {
  margin-bottom: 12px;
}
.signin-b2c-modal-ecpf-card__icon svg {
  color: #00441F;
  font-size: 2.8rem;
}
@media only screen and (max-width: 576px) {
  .signin-b2c-modal-ecpf-card__icon svg {
    font-size: 1.8rem;
  }
}
.signin-b2c-modal-ecpf-card__title {
  font-size: 1.2rem;
  font-weight: 600;
  color: #00441F;
  margin-bottom: 7px;
}
@media only screen and (max-width: 576px) {
  .signin-b2c-modal-ecpf-card__title {
    font-size: 1rem;
  }
}
.signin-b2c-modal-ecpf-card__description {
  color: rgba(51, 51, 51, 0.85);
  font-size: 0.8rem;
}
@media only screen and (max-width: 576px) {
  .signin-b2c-modal-ecpf-card__description {
    font-size: 0.6rem;
  }
}
.signin-b2c-check-user-ecpf {
  text-align: center;
}
.signin-b2c-check-user-ecpf__title {
  font-size: 1.2rem;
  margin-bottom: 3px;
}
.signin-b2c-check-user-ecpf__loading-svg {
  text-align: center;
  padding-bottom: 2rem;
}
.signin-b2c-check-user-ecpf__loading-svg svg {
  max-width: 300px;
  width: 300px !important;
}
.signin-b2c-check-user-ecpf__spinner {
  margin-bottom: 1rem;
}
.signin-b2c-check-user-ecpf__spinner span {
  font-size: 2.5rem;
}
.proposal-configuration-side-bar {
  max-width: 200px;
  min-width: 200px;
  border-right: 1px solid #f2f2f2;
  padding-right: 25px;
  overflow: auto;
}
.proposal-configuration-side-bar::-webkit-scrollbar {
  background: #f2f2f2 !important;
  width: 5px;
  height: 5px;
}
.proposal-configuration-side-bar::-webkit-scrollbar-thumb {
  background: #a8a8a8;
  border-radius: 20px;
  transition: all 0.2s ease-in-out;
}
.proposal-configuration-side-bar::-webkit-scrollbar-thumb:hover {
  background: #005e2b;
}
@media only screen and (max-width: 1200px) {
  .proposal-configuration-side-bar {
    width: 100%;
    border-right: transparent;
    max-width: none;
    padding-right: 0px;
  }
}
.proposal-configuration-side-bar__item {
  padding: 0.5rem;
  border: none;
  background-color: transparent;
  text-align: start;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  border-radius: 10px;
}
.proposal-configuration-side-bar__item--selected {
  background-color: #f2f2f2;
}
.proposal-configuration-side-bar__item:hover {
  background-color: #f2f2f2;
}
.proposal-configuration-side-bar__collapse-title {
  font-size: 1rem;
  font-weight: bold;
  color: #00441F;
}
.proposal-configuration-side-bar__collapse-body {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 30px;
  transition: all 0.2s ease-out;
}
.proposal-configuration-side-bar__collapse-body--collapsed {
  min-height: 0px;
  transition: all 0.2s ease-out;
}
.proposal-configuration-side-bar__collapse-body > * {
  width: 100%;
}
.proposal-configuration-side-bar__collapse-icon {
  transition: transform 0.2s ease-out;
  transform: rotate(-180deg);
}
.proposal-configuration-side-bar__collapse-icon--collapsed {
  transform: rotate(0);
}
.proposal-configuration-fields {
  padding-left: 25px;
  padding-right: 25px;
  overflow: auto;
}
.proposal-configuration-fields::-webkit-scrollbar {
  background: #f2f2f2 !important;
  width: 5px;
  height: 5px;
}
.proposal-configuration-fields::-webkit-scrollbar-thumb {
  background: #a8a8a8;
  border-radius: 20px;
  transition: all 0.2s ease-in-out;
}
.proposal-configuration-fields::-webkit-scrollbar-thumb:hover {
  background: #005e2b;
}
@media only screen and (max-width: 1200px) {
  .proposal-configuration-fields {
    padding: 0;
  }
}
.proposal-configuration-fields__skeleton-button .ant-skeleton-button {
  width: 100%;
}
.proposal-configuration {
  height: 80vh;
}
@media only screen and (max-width: 1200px) {
  .proposal-configuration {
    flex-direction: column;
    height: 100%;
  }
}
.proposal-configuration__table .ant-table-thead th {
  background: transparent !important;
  color: #000 !important;
  border-bottom: transparent !important;
}
.proposal-configuration__table .ant-table-container {
  overflow: auto;
}
.proposal-configuration__table .ant-table-content {
  min-width: 600px;
}
.proposal-configuration__add-button {
  border-color: #00441F;
  color: #00441F;
}
.proposal-configuration__add-button:hover {
  background-color: #00441F;
  color: #fff;
}
.form-list-buttons {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.form-list-buttons__add {
  background-color: transparent;
  color: var(--primary-color);
}
.form-list-buttons__add:hover,
.form-list-buttons__add:active,
.form-list-buttons__add:focus {
  background-color: transparent;
  color: var(--primary-color);
}
.form-list-buttons__remove {
  background-color: #C73535;
  color: white;
  border: none;
}
.form-list-buttons__remove:hover,
.form-list-buttons__remove:active,
.form-list-buttons__remove:focus {
  background-color: #C73535;
  color: white;
  border: none;
}
.generic-origin-radio-ecpf__input-search {
  padding: 15px;
}
.generic-origin-radio-ecpf__input-search .ant-input-search-button {
  display: flex;
  align-items: center;
  justify-content: center;
}
.generic-origin-radio-ecpf__pagination {
  padding: 15px;
}
.minimalist-table {
  width: 100%;
  border-collapse: collapse;
  min-width: 800px;
}
.minimalist-table__container {
  width: 100%;
  overflow-x: auto;
}
.minimalist-table__container::-webkit-scrollbar {
  background: var(--scroll-bar-color) !important;
  width: 10px;
  height: 10px;
}
.minimalist-table__container::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 20px;
}
.minimalist-table__container::-webkit-scrollbar-thumb:hover {
  background: #005e2b;
}
.minimalist-table__section-title {
  font-weight: bold;
  font-size: 1rem;
  color: #00441F;
}
.minimalist-table__table {
  width: 100%;
}
.minimalist-table__row-header th {
  color: #00441F;
  height: 40px;
  font-weight: 1.2rem;
}
.minimalist-table__row {
  border-bottom: 1px solid #00441F;
  padding: 1rem 0;
  height: 40px;
}
.minimalist-table__item {
  min-width: 100%;
  padding: 5px 10px;
  white-space: nowrap;
}
@media only screen and (max-width: 768px) {
  .proposal-due {
    overflow: scroll;
  }
  .proposal-due__title h3 {
    padding: 1rem 0;
    text-align: center;
  }
  .proposal-due__table {
    overflow: scroll !important;
    margin-left: 1rem;
    width: 120%;
  }
  .proposal-due__row {
    white-space: nowrap;
    grid-gap: 1rem;
  }
}
.options-choose-modal__title {
  font-weight: 700;
  font-size: 1.25rem;
  margin-bottom: 25px;
}
.options-choose-modal__container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.verify-email-succes-svg__first-baloon {
  animation: floatingBaloon 4.5s infinite 1s ease-in-out;
}
.verify-email-succes-svg__second-baloon {
  animation: floatingBaloon 3.5s infinite ease-in-out;
}
.verify-email-succes-svg__third-baloon {
  animation: floatingBaloon 4s infinite 0.5s ease-in-out;
}
@keyframes floatingBaloon {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0);
  }
}
.maintenance-modal__rodobens-logo {
  height: 80px;
}
.maintenance-modal__logo-container {
  margin-bottom: 0;
  text-align: center;
}
.maintenance-modal__base-image-container {
  margin-bottom: 20px;
}
@media only screen and (max-width: 576px) {
  .maintenance-modal__base-image-container {
    margin-bottom: 10px;
  }
}
.maintenance-modal__paragraph {
  font-size: 1.05rem;
  margin-bottom: 30px;
}
@media only screen and (max-width: 576px) {
  .maintenance-modal__paragraph {
    font-size: 1rem;
    margin-bottom: 20px;
  }
}
.maintenance-modal__contact-texts {
  margin-bottom: 50px;
}
@media only screen and (max-width: 576px) {
  .maintenance-modal__contact-texts {
    margin-bottom: 35px;
  }
}
.maintenance-modal__footer {
  text-align: right;
}
.maintenance-modal__footer-ball {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
}
.maintenance-modal__footer-ball:not(:last-child) {
  margin-right: 5px;
}
.maintenance-modal__footer-ball--gray {
  background-color: #e0e5cf;
}
.maintenance-modal__footer-ball--green {
  background-color: #ff6600;
}
.maintenance-modal__footer-ball--orange {
  background-color: #00441f;
}
