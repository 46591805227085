body {
  padding-right: 0 !important;
  font-family: 'Montserrat', sans-serif !important;
}

.user-select{
  user-select: none;
}

.ant-input-search-button {
  background-color: @primary-color !important;
  color: white !important;
}

.unset-white-space {
  white-space: unset !important;
}

.no-wrap-white-space {
  white-space: nowrap !important;
}

.column-link {
  text-decoration: underline !important;
  color: @tertiary-color !important;
  font-weight: 500 !important;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  margin-bottom: 0;

  @media (max-width: 1440px) {
    white-space: nowrap;
    font-size: 10px;
  }
}

.ant-radio-inner {
  background-color: transparent;
}

#root {
  min-height: 100vh;
}

p {
  margin: 0;
}

.ant-picker {
  width: 100%;
}

.modal-config {
  .ant-modal-content {
    z-index: 999;
    max-height: calc(100vh - 100px);
    padding-bottom: 0;
  }

  .ant-modal-title {
    white-space: nowrap;
    overflow: hidden;
    margin-right: 50px;
    position: relative;
  }

  .ant-modal-body {
    max-height: calc(100vh - 155px);
    overflow-y: auto;
  }

  &--w-30 {
    width: 30vw !important;
  }

  &--w-50 {
    width: 50vw !important;
  }

  &--w-75 {
    width: 75vw !important;
  }

  @media (max-width: 800px) {
    width: 100vw !important;
  }
}

.modal-config-radius {
  .ant-modal-content {
    border-radius: 20px;

    .ant-modal-header {
      border-radius: 20px 20px 0 0;
    }
  }
}

.rounded-radio {
  text-align: center;

  & :first-child{
    border-radius: 30px 0 0 30px;
    width: 50%;

    @media (max-width: 380px) {
      font-size: 12px;
    }
  }

  & :last-child {
    border-radius: 0 30px 30px 0;
    width: 50%;

    @media (max-width: 380px) {
      font-size: 12px;
    }
  }

  &.ant-radio-group-large .ant-radio-button-wrapper {
    height: 40px !important;
    line-height: 38px !important;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child,
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    border: none;
  }

  .ant-radio-button-wrapper.ant-radio-button-wrapper-checked{
    background-color: @primary-color !important;
    color: #fff !important;
    font-weight: 700;
  }

  .ant-radio-button-wrapper {
    background-color: #fff !important;
    color: @primary-color !important;
    font-weight: 700;
  }
}

.container {
  padding: @container-custom-padding;
  margin-right: 0;
  margin-top: 2px;
  margin-bottom: 2px;
  overflow-y: auto;
  min-height: calc(100vh);

  &--custom-height {
    background-color: #E0E5CF;
    min-height: @container-custom-height--desktop;

    @media @tab-xl {
      min-height: @container-custom-height--laptop;
    }

    @media @phone-sm {
      min-height: @container-custom-height--mobile;
    }
  }

  &--centered-width {
    max-width: 1280px;
    margin: auto;
  }
}


.content-container {
  min-height: 100vh;
  height: 100%;
}

.bg-starting-status-color{
  background-color: @starting-status-color;
}

.bg-analysis-status-color {
  background-color: @analysis-status-color;
}

.bg-remaking-status-color {
  background-color: @analysis-status-color;
}

.bg-refused-status-color {
  background-color: @refused-status-color;
}

.bg-reproved-status-color {
  background-color: @refused-status-color;
}

.bg-scanned-status-color {
  background-color: @scanned-status-color;
}

.bg-approved-status-color {
  background-color: @approved-status-color;
}

.bg-white-status-color {
  background-color: @white;
}

.bg-default-status-color {
  background-color: @normal-color;
}

.react-code-input input[type='tel'] {
  font-family: none;

  &:first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  &:last-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
}

.pdf-container canvas {
  width: 100% !important;
  height: 100% !important;
}

.react-pdf__Document {
  display: flex;
  justify-content: center;
}

.modal-document-image {
  width: 50vw;
  margin-top: 25px;
}

.card-style {
  background: @white;
  border: 1px solid #c2bebe;

  h2 {
    font-weight: bolder;
    text-transform: capitalize;
  }
}

.proposal-container-card {
  background: @white;
  border-radius: 20px;
  padding: 1rem;

  h2 {
    font-weight: bolder;
    text-transform: capitalize;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    @media @phone-sm {
      flex-direction: column;
      gap: 15px;
    }
  }
}

.ant-drawer-body {
  scroll-behavior: smooth;

  @media @phone-sm {
    padding: 1.2rem;
  }
}

.step-renders-container {
  position: relative;

  .go-to-review-button {
    position: fixed;
    right: 20px;
    bottom: 71px;
    z-index: 30;

    display: flex;
    align-items: center;
    transition: 0.2s;

    p {
      display: block;
      margin: 0 5px 0 0;
    }

    &:hover {
      filter: brightness(0.95);
    }

    animation: reviewButtonAnimation 0.5s;
  }

  .go-to-review-button-invisible {
    position: fixed;
    right: -150px;
    bottom: 71px;
    opacity: 0;
    background-color: @approved-status-color;
    color: #fff;

    animation: reviewButtonAnimationInvisible 0.3s;
  }
}

.card-style-border-radius {
  border-radius: 5px;
}

.fade-in-animation {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-timing-function: ease-in;
  animation-duration: 200ms;
}

.modal-term {
  height: 50vh;
  overflow: auto;
}

.fixed-header {
  position: fixed;
  top: 64px;
  z-index: 9;
  width: 89.5%;
  padding: 10px;
  border-bottom: 1px solid #c2bebe;
  transition: padding-bottom 0.25s ease-in, padding-top 0.25s ease-in, background-color 0.25s ease-in;
  margin-left: -30px;
  padding-left: 30px;

  &--collapsed {
    width: 95.7%;
  }

  & h1,
  & span {
    margin-bottom: 0 !important;
  }

}

@media @tab-xl {
  .fixed-header {
    top: 90px;
  }
}

@media @tab-md {
  .fixed-header {
    top: 90px;
    width: 76.5%;

    &--collapsed {
      width: 91.5%;
    }
  }

  .sidebar-size {
    max-height: calc(100vh - 104px);
  }
}

@media @phone-sm {
  .fixed-header {
    top: 90px;
    width: calc(100% + 10.5px);
  }
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media @phone-sm {
  .ant-layout-content {
    margin: 2px;
  }
}

.icon-copy span:first-of-type {
  color: black;
}

.departament-title {
  margin-bottom: 0;
  padding-bottom: 0;
  text-transform: 'capitalize';
}

.linked-backoffices-list {
  text-align: left;
  margin-left: 40px;
}

.department-image-documents {
  width: 100%;
  max-height: 90px;
}

.line-chart-layout{
  padding-right: 20px;
}

.col-text-h4{
    margin-bottom: 0.5em;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    font-size: 20px;
    line-height: 1.4;
}

.col-text-h5{
    margin-bottom: 0.5em;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5;
}

@media (min-width: 1200px) and (max-width: 1592px){
  .col-text-h4{
    font-size: 14px;
  }

  .col-text-h5{
    margin-top: 5px;
    font-size: 12px;
  }

  .col-icons{
    .flex-full-center();

    width: 25px;
    height: 25px;
  }
}

@media (min-width: 1200px){
  .pie-chart-layout{
    padding: 0 25px 0 25px;
  }
}

@media (min-width: 320px) and (max-width: 1199px){
  .pie-chart{
      margin-top: 20px;
    }

  .line-chart-layout{
    padding-right: 0;
  }

  .col-icons{
    display: flex;
    align-items: center;
    justify-content: center;

    width: 20px;
    height: 20px;
  }
}

@media (min-width: 928px) and (max-width: 1200px){
  .col-text-h5{
    margin-top: 5px;
    font-size: 9px;
  }

  .col-text-h4{
    font-size: 10px;
  }
}

@media (min-width: 701px) and (max-width: 927px){
  .col-text-h5{
    margin-top: 5px;
    font-size: 7.5px;
  }

  .col-text-h4{
    font-size: 10px;
  }
}

@media (min-width: 320px) and (max-width: 700px){
  .col-text-h5{
    margin-top: 5px;
    font-size: 5px;
  }

  .col-text-h4{
    font-size: 8px;
  }

  .col-icons{
    display: flex;
    align-items: center;
    justify-content: center;

    width: 15px;
    height: 15px;
  }
}

.line-chart-layout{
  padding-right: 20px;
}

.col-text-h4{
    margin-bottom: 0.5em;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    font-size: 20px;
    line-height: 1.4;
}

.col-text-h5{
    margin-bottom: 0.5em;
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5;
}

@media (min-width: 1200px) and (max-width: 1592px){
  .col-text-h4{
    font-size: 14px;
  }

  .col-text-h5{
    margin-top: 5px;
    font-size: 12px;
  }

  .col-icons{
    .flex-full-center();

    width: 25px;
    height: 25px;
  }
}

@media (min-width: 1200px){
  .pie-chart-layout{
    padding: 0 25px 0 25px;
  }
}

@media (min-width: 320px) and (max-width: 1199px){
  .pie-chart{
      margin-top: 20px;
    }

  .line-chart-layout{
    padding-right: 0;
  }

  .col-icons{
    display: flex;
    align-items: center;
    justify-content: center;

    width: 20px;
    height: 20px;
  }
}

@media (min-width: 928px) and (max-width: 1200px){
  .col-text-h5{
    margin-top: 5px;
    font-size: 9px;
  }

  .col-text-h4{
    font-size: 10px;
  }
}

@media (min-width: 701px) and (max-width: 927px){
  .col-text-h5{
    margin-top: 5px;
    font-size: 7.5px;
  }

  .col-text-h4{
    font-size: 10px;
  }
}

@media (min-width: 320px) and (max-width: 700px){
  .col-text-h5{
    margin-top: 5px;
    font-size: 5px;
  }

  .col-text-h4{
    font-size: 8px;
  }

  .col-icons{
    .flex-full-center();

    width: 15px;
    height: 15px;
  }
}

@media screen and (max-width: 1366px) {
  .department-image-documents {
    width: 100%;
    max-height: 75px;
  }
}

.img-responsive-tiny {
  width: 150px;
}

.paragraph-feedback {
  position: absolute;
  white-space: nowrap;
  bottom: 0;
  left: 0;
  color: #ff5533;
  margin-left: 11px;
  margin-bottom: 5px;

  @media (max-width: 991px) {
    position: static;
    margin-left: 0px;
    margin-bottom: 0px;
  }

  @media (max-width: 768px) {
    white-space: pre-wrap;
    position: static;
  }
}

.deliberar-button{
  margin-right: 5px;
  font-size: 12.5px !important;
  max-height: 28px;
}

.no-hover {
  &:hover {
    background: transparent !important;
  }
}

.fixed-hover {
  height: 2em;
  border-radius: 25px;
  transition: background-color .2;
  &:hover {
    background: rgba(0, 0, 0, 0.018);
  }
}

.action-svgs-class {
  height: 16px;
  width: 16px;
  filter: brightness(0) invert(1);
  display: flex;
}

.lgpd-page {
  max-width: 800px;
  min-height: 80vh;
}

.reproval-plus-icon {
  color: @tertiary-color !important;
  font-size: 30px;
}

// SWAL MODIFIED STYLES
.swal2-popup {
  border-radius: 20px !important;
}

.swal2-title  {
  color: @primary-color !important;
}

div.swal2-content {
  #swal2-content {
    color: @primary-color !important;
  }
}

.swal2-styled.swal2-confirm {
  background-color: @primary-color !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 30px;
  height: 40px;
  padding: 5px 11px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  margin-top: 3px !important;
}

.ant-select-multiple.ant-select-show-search .ant-select-selector{
  height: auto !important;
}

.ant-input {
  border-radius: 30px;
  height: 40px;
}

.ant-input-affix-wrapper {
  border-radius: 30px;
  height: 40px;

  .ant-input {
    height: 30px;
    margin: auto;
    &:-webkit-autofill {
      background-color: none !important;
    }
  }
}

.searchble-focus-select .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input{
  height: 36px;
}

.ant-input-search {
  .ant-input-group-addon {
    position: relative;
    padding: 0 11px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: normal;
    font-size: 14px;
    text-align: center;
    background-color: #fafafa;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    background-color: none;
    transition: all 0.3s;
    border-top-right-radius: 25px !important;
    border-bottom-right-radius: 25px !important;
  }

  .ant-input-search-button {
    width: 40px !important;
  }
}

.pricing-inputs {
  .ant-input {
    border-radius: 5px;
    height: 30px;
    padding-left: 0 0 0 5px;
  }

  .ant-input-number {
    border-radius: 5px;
    height: 30px;
    padding: 0 0 0 5px;
  }

  .ant-row .ant-row {
    margin-bottom: 0 !important;
  }
}

.pricing-table-headers {
  background-color: @primary-color;
  padding: 0 5px;
  color: white;
  border-radius: 5px;
  min-height: 25px;
  border-width: 0;
  margin-bottom: 2px;
}

.ant-picker {
  border-radius: 30px !important;
  height: 40px;
}

.ant-input-number {
  border-radius: 30px;
  height: 40px;
  padding: 5px 11px;
}

.ant-btn {
  border-radius: 30px !important;
  height: 40px !important;
}

.button {
  background-color: @primary-button;
}

.swal-container-scroll {
  max-height: 60vh;
  overflow-y: scroll;

  @media @phone-sm {
    max-height: 70vh;
  }
}
